import {useEffect, useState} from "react";
import {DeleteColor, GetFlagColorByClient, GetFlagColorByGrid} from "./FlagColor.service";
import useConnections from "../Connections/hooks/useConnections";
import {useAsyncTableData} from "../../CustomTable/hooks";
import {getUrlParams} from "../../../util/browser";
import {toast} from "react-toastify";

const useFlagColor = () => {
  const [modalNew, setModalNew] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [colorEdit, setColorEdit] = useState({});
  const [dataFlagColor, setDataFlagColor] = useState({});

  const initialValues = {
    "name": "",
    "hexCode": "",
  }

  const {
    selectedClient,
    allPractices,
    clientId,
    handleClientSelected,
  } = useConnections();

  const getData = (data) => {
    return GetFlagColorByGrid(selectedClient.clientId, data)
  }

  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    getData,
    {
      ...initialValues,
      ...getUrlParams(Object.keys(initialValues))
    }, null
  )

  useEffect(() => {
    refreshData()
  }, [selectedClient]);

  const getFlags = () => {
    if (selectedClient) {
      const data = GetFlagColorByClient(selectedClient.clientId, {}).then(([status, r]) => {
        if (status) {
          setDataFlagColor(r);
        }
      });
    }
  }

  const Delete = (colorId) => {
    DeleteColor(selectedClient.clientId, colorId).then(([status, r]) => {
      if (status) {
        toast.success(
          `Color was deleted correctly`
        );
        refreshData()
      }
    })
  }
  return {
    modalNew,
    setModalNew,
    dataFlagColor,
    setDataFlagColor,
    selectedClient,
    allPractices,
    clientId,
    handleClientSelected,
    Delete,
    getFlags,
    modalEdit,
    setModalEdit,
    colorEdit,
    setColorEdit,
    dataSource,
    tableReference,
    refreshData,

  }
}

export default useFlagColor