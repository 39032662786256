import CustomTooltip from "../../../../../CustomTooltip";
import {
  FilterItemContent,
  IconWrapper,
  PropertyContent,
  PropertyName,
  PropertyValue,
} from "../styles/BreadcrumbsFilters.styles";
import { COLORS, ICONS } from "../utils";

const FilterItemWithIcon = ({
  iconValue,
  textOperation,
  property,
  propertyValue,
}) => {
  const Icon = ICONS[iconValue];
  const iconColor = COLORS[iconValue];
  let iconWith = "1rem";

  if (["sw", "ew"].includes(iconValue)) {
    iconWith = ".9rem";
  } else if (["c", "nc"].includes(iconValue)) {
    iconWith = "1.5rem";
  }
  const valueFilterProperty = Array.isArray(propertyValue)
    ? propertyValue.join(", ")
    : propertyValue;

  return (
    <li className="breadcrumb-item">
      <CustomTooltip
        message={textOperation}
        customTooltip
        colorTooltip={iconColor}
      >
        <FilterItemContent>
          <IconWrapper iconColor={iconColor} iconWith={iconWith}>
            <Icon />
          </IconWrapper>
          <PropertyContent>
            <PropertyName>{property}</PropertyName>
            <PropertyValue>{valueFilterProperty}</PropertyValue>
          </PropertyContent>
        </FilterItemContent>
      </CustomTooltip>
    </li>
  );
};

export default FilterItemWithIcon;
