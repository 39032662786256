import LoadingOverlay from "react-loading-overlay";
import "./style.css";

const Loader = ({ active, text }) => {
  return (
    <div className={active ? "loaderContainer" : "hideContainer"}>
      <LoadingOverlay
        active={active}
        spinner
        text={text || "Loading..."}
      ></LoadingOverlay>
    </div>
  );
};

export default Loader;
