import {useEffect, useState} from "react";
import * as sharedServices from '../services/index'

const useSharedElements = () => {
  const [templates, setTemplates] = useState([])

  useEffect(() => {
    async function getList(){
      const [status, list] = await sharedServices.getTemplates()
      if (status){
        setTemplates(list.data)
      }
    }

    getList()
  }, []);

  return {
    templates
  }
}

export default useSharedElements