import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@material-ui/core";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TemplateContext } from "../TemplateContext";
import CategoryForm from "./CategoryForm";

const alert = withReactContent(Swal);

const deleteConfirmation = (categoryType, callback) => {
  alert
    .fire({
      title: `Deleting ${categoryType}`,
      text: `Are you sure you want to delete this ${categoryType}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    })
    .then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
};

const OptionButton = ({ handleClose, className, ...props }) => {
  return (
    <button
      className={`d-block btn btn-link  btn-sm ${className}`}
      {...props}
      onClick={(ev) => {
        ev.stopPropagation();
        handleClose(ev);
        props.onClick && props.onClick(ev);
      }}
    />
  );
};

const HeaderOptions = ({
  category,
  parentCategory,
  setEditing,
  canCreateSubcategories,
  addCategory,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    setActiveCategory,
    deleteCategory,
    reorderCategory,
    reorderBookmark,
    setReorder,
    getReorder,
  } = useContext(TemplateContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ev) => {
    ev.stopPropagation();
    setAnchorEl(null);
  };

  const { categoryType, id } = category;

  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        className="user-request"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ overflow: "inherit" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="header-dropdown-options">
          <OptionButton
            handleClose={handleClose}
            onClick={(ev) => {
              setEditing(true);
            }}
          >
            <FontAwesomeIcon icon="edit" /> Edit name and type
          </OptionButton>

          {canCreateSubcategories && (
            <OptionButton
              handleClose={handleClose}
              onClick={(ev) => {
                addCategory();
              }}
            >
              <FontAwesomeIcon icon="plus" /> Add subcategory
            </OptionButton>
          )}

          {category.bookmarks.length > 0 ? (
            <OptionButton
              handleClose={handleClose}
              onClick={(ev) => {
                setReorder({
                  title: `${category.name} - Bookmarks`,
                  data: category.bookmarks,
                  onReorderItem: (item, index) => {
                    // Due to the lack of a third level
                    const parentId = parentCategory
                      ? parentCategory.id
                      : category.id;
                    const childId = parentCategory ? category.id : null;
                    reorderBookmark(index, item.id, childId, parentId);
                  },
                });
              }}
            >
              <FontAwesomeIcon icon="list" /> Arrange bookmarks
            </OptionButton>
          ) : null}

          {category.subCategories.length > 0 ? (
            <OptionButton
              handleClose={handleClose}
              onClick={(ev) => {
                setReorder({
                  title: `${category.name} - Subcategories`,
                  data: category.subCategories,
                  onReorderItem: (item, index) => {
                    reorderCategory(index, category.id, item.id);
                  },
                });
              }}
            >
              <FontAwesomeIcon icon="list" /> Arrange subcategories
            </OptionButton>
          ) : null}

          <hr className="py-1 my-1" />
          <OptionButton
            className=" text-danger"
            handleClose={handleClose}
            onClick={(ev) => {
              deleteConfirmation(categoryType, async () => {
                const res = await deleteCategory(
                  id,
                  categoryType,
                  parentCategory
                );
                if (res) {
                  if (parentCategory) {
                    setActiveCategory(parentCategory, parentCategory);
                  } else {
                    setActiveCategory(null);
                  }
                }
              });
            }}
          >
            <FontAwesomeIcon icon="trash" /> Delete
          </OptionButton>
        </div>
      </Popover>
      <button
        className="d-inline-block btn btn-link p-0"
        onClick={(ev) => {
          ev.stopPropagation();
          handleClick(ev);
        }}
      >
        <FontAwesomeIcon icon="cog" />
      </button>
    </>
  );
};

export default function CategoryHeader({
  clickeableRef,
  category,
  parentCategory,
  addCategory,
  canCreateSubcategories,
  expanded,
}) {
  //TODO {frank} limpiar
  const [editing, setEditing] = useState(false);
  const { updateCategory } = useContext(TemplateContext);
  const [tempData, setTempData] = useState({
    name: category.name,
    type: category.type,
  });
  const [saving, setSaving] = useState(false);

  const { type, name, id } = category;

  return (
    <>
      <div className="pl-2" ref={clickeableRef}>
        {editing && (
          <div className="d-flex align-content-center justify-content-center ">
            <CategoryForm
              name={tempData.name}
              type={tempData.type}
              isSubCategory={parentCategory != null}
              handler={(d) => {
                setTempData(d);
              }}
            />
            {saving && (
              <div className="align-self-center ml-2">
                <FontAwesomeIcon icon="spinner" spin />
              </div>
            )}
            {!saving && (
              <>
                <Button
                  className="px-0 ml-2"
                  variant="link"
                  onClick={async (ev) => {
                    setSaving(true);
                    const result = await updateCategory(
                      id,
                      tempData.name,
                      tempData.type,
                      category.categoryType,
                      parentCategory
                    );
                    if (result) {
                      setSaving(false);
                      setEditing(false);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="check" />
                </Button>
                <Button
                  className="py-0"
                  variant="link"
                  onClick={(ev) => {
                    setEditing(false);
                    setTempData({
                      name: category.name,
                      type: category.type,
                    });
                  }}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>
              </>
            )}
          </div>
        )}
        {!editing && (
          <div>
            <span>
              {name} {canCreateSubcategories && ` - ${type}`}
            </span>
            {!canCreateSubcategories && <small>: {type}</small>}
          </div>
        )}
      </div>
      {expanded ? (
        <div
          style={{
            borderBottom: "dashed 1px",
            fontSize: "2rem",
            color: "#dedede",
            alignSelf: "center",
            flex: 2,
            margin: "0 10px",
            animation: "all 1s",
          }}
        />
      ) : null}

      <HeaderOptions
        parentCategory={parentCategory}
        category={category}
        setEditing={setEditing}
        canCreateSubcategories={canCreateSubcategories}
        addCategory={addCategory}
      />
    </>
  );
}
