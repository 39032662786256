import { Api, decryptApiResponse } from "../config/Api";
import * as moment from "moment";
import { decryptData } from "../config/encryptData";
import { statusAndResponse } from "../util/requestHandler";

export const Donuts = () => {
  function onSuccess(resp) {
    return decryptData(JSON.parse(resp.data.value)).then((info) => {
      let data = [];
      Object.keys(info).forEach((key) => {
        data.push({ name: key, y: resp.data.value[key] });
      });
      return data;
    });
  }

  // resolve([{ name: "Records", y: 0 }]);

  return statusAndResponse(Api.post("/api/iv/transaction/state"), onSuccess);
};

export const ChartTransaction = () => {
  function onSuccess(resp) {
    let dataName = [],
      dataValue = [],
      dataTitle = [];

    return decryptData(JSON.parse(resp.data.value)).then((info) => {
      Object.keys(info).forEach((key) => {
        dataName.push(key);
        dataValue.push(info[key]);
        dataTitle.push({ name: key, y: info[key] });
      });
      return { cat: dataName, val: dataValue, title: dataTitle };
    });
  }

  // resolve({ cat: ["Null"], val: [0], title: ["Null", 0] });

  return statusAndResponse(
    Api.post("/api/iv/transaction/quarterly"),
    onSuccess
  );
};

export const GetStatusPolize = () => {
  function onSuccess(resp) {
    return decryptData(JSON.parse(resp.data.value)).then((info) => {
      let data = [];
      if (info.length > 0) {
        Object.keys(resp.data.value).forEach((key) => {
          data.push({ name: key, y: resp.data.value[key] });
        });
      } else {
        data.push({ name: "No Polize", y: 0 });
      }
      return data;
    });
  }

  return statusAndResponse(Api.post("/api/iv/transaction/state"), onSuccess);
};

export const get_GridRecordsWithMissingCode = (limit = 300, filter = {}) => {
  function onSuccess(resp) {
    return decryptApiResponse(resp);
  }

  return statusAndResponse(
    Api.post("/api/iv/transaction/missingcode/all", { limit, ...filter }),
    onSuccess
  );
};
