import React from "react";
import { Accordion } from "react-bootstrap";
import { AccordionItem } from "../CustomAccordion/index";

const CustomTreeView = ({ data, ...props }) => {
  return (
    <Accordion className="p-3">
      {(data || []).map((item) => {
        if (item.items) {
          return (
            <AccordionItem
              containerClassName="p-2"
              headerProps={props.headerProps || { style: { padding: 0 } }}
              eventKey={item[props.recordId]}
              key={item[props.recordId]}
              renderHeader={() => props.headerElement(props, item)}
              renderBody={() => <CustomTreeView data={item.items} {...props} />}
            />
          );
        } else {
          return (
            <AccordionItem
              containerClassName="p-2"
              customDropDownIcon={props.customDropDownIcon || <i></i>}
              headerProps={props.headerProps || { style: { padding: 0 } }}
              eventKey={item[props.recordId]}
              key={item[props.recordId]}
              empty={true}
              handleClick={() => null}
              renderHeader={() => props.headerElement(props, item)}
            />
          );
        }
      })}
    </Accordion>
  );
};

export default CustomTreeView;
