import { useEffect, useState } from "react";
import {
  FormSelect as Select,
  FormInputText as Text,
  BasicForm,
} from "../../../../components/formControls";
import BasicList from "../../../../components/BasicList";
import { createBookmark } from "../../Request";
import {
  getSectionType,
  getBookmarkType,
  getBookmarkValueType,
  getFieldsForm,
  bookmarkSave,
} from "../../../../services/template";
import { removeBookmark, addBookmark } from "../../Utils";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomModal from "../../../CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MySwal = withReactContent(Swal);

export default function BookmarkForm({ onSave }) {
  const [sectionType, setSectionType] = useState();
  const [valueType, setValueType] = useState();
  const [bookmarkType, setBookmarkType] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalBookmark, setShowModalBookmark] = useState(false);
  const [modalFields, setModalFields] = useState([]);
  const [loadingModalFields, setLoadingModalFields] = useState(false);
  const [dataAccordion, setDataAccordion] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [bookmarkButtons, setBookmarkButtons] = useState();
  const [selectedBookmarkType, setSelectedBookmarkType] = useState();
  const [selectedBookmark, setSelectedBookmarks] = useState();
  const [generalFields, setGeneralFields] = useState({
    name: "",
    description: "",
  });
  const [listOptions, setListOptions] = useState({});
  const [templateId, setTemplateId] = useState("60a7c4d9ee59de7530b02fb0");
  const [loaderIsActive, setLoaderIsActive] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let bookmarks = { ...selectedBookmark };
    setBookmarkButtons(bookmarks);
  }, [selectedBookmark, selectedCategory, selectedSubCategory]);

  useEffect(async () => {
    try {
      let [sst, sectionType] = await getSectionType();
      let valueType = await getBookmarkValueType();
      let bookmarkType = await getBookmarkType();

      setSectionType(formatDataToOption(sectionType));
      setValueType(formatDataToOption(valueType));
      setBookmarkType(formatDataToOption(bookmarkType));

      setLoaded(true);
    } catch (ex) {
      //TODO: ADD notification
    }
  }, []);

  const formatDataToOption = (data) => {
    let options = [];
    data.forEach((t) => {
      options.push({ bookmark: t, label: t });
    });
    return options;
  };

  const handleClickBtnsBookmark = (btn) => {
    let currentBtn = removeBookmark(
      btn,
      selectedBookmark,
      selectedCategory,
      selectedSubCategory
    );
    setSelectedBookmarks(currentBtn);
  };

  const handleUpdateList = (list) => {
    setListOptions(list);
  };

  const handleCloseModalBookmark = () =>
    setShowModalBookmark({ status: false });

  const handleChangeBookmarkType = (e) => {
    let fields = [];
    setModalFields([]);
    setLoadingModalFields(true);
    setSelectedBookmarkType(e.target.value);
    getFieldsForm(e.target.value)
      .then(([status, resp]) => {
        //type o symbol va como select!
        resp.forEach((field) => {
          //TODO Hard codded
          if (field["parameter"] === "type") {
            fields.push({
              control: Select,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                value: "",
                label: field["label"],
                options: formatDataToOption(field["items"]),
              },
            });
            return;
          }

          //TODO Hard codded
          if (field["parameter"] === "symbol") {
            fields.push({
              control: Select,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                value: "",
                label: field["label"],
                options: formatDataToOption(field["items"]),
              },
            });
            return;
          }

          if (field["items"]) {
            fields.push({
              control: BasicList,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                title: field["label"],
                items: field["items"],
                onUpdate: (l) => handleUpdateList(l),
              },
            });
          } else {
            if (e.target.value === "text" && field["parameter"] === "required")
              return;

            fields.push({
              control: Text,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                value: "",
                label: field["label"],
              },
            });
          }
        });
        setLoadingModalFields(false);
        setModalFields(fields);
      })
      .catch((ex) => {
        //TODO: Add handler
      });
  };

  const handleSubmitBookmark = (inputsForm) => {
    let params = {};
    let id = selectedSubCategory ? selectedSubCategory : selectedCategory;
    let type = selectedSubCategory ? "subsection" : "section";
    try {
      params = createBookmark(inputsForm, templateId, id, type);
    } catch (e) {
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please, fill all fields of the form",
      });
      return;
    }

    let t =
      selectedBookmarkType === "check" ? "checkbox" : selectedBookmarkType;

    onSave(params.bookmark, t);
  };

  if (!loaded)
    return (
      <p className="small text-muted">
        Loading, please wait <FontAwesomeIcon icon="spinner" spin />
      </p>
    );

  return (
    <>
      <Select
        id="slBookmarkType"
        disableFirstOption
        label="Bookmark type:"
        options={bookmarkType}
        value=""
        onChange={handleChangeBookmarkType}
      />
      {loadingModalFields && (
        <p className="small text-muted">
          Loading Bookmark data, please wait{" "}
          <FontAwesomeIcon icon="spinner" spin />
        </p>
      )}
      {modalFields.length > 0 && (
        <BasicForm
          //key={shortid.generate()}
          fields={modalFields}
          onSubmit={(e) => handleSubmitBookmark(e, showModalBookmark.type)}
        />
      )}
    </>
  );
}
