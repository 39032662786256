import useFlagColorForm from "./useFlagColorForm";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import React from "react";
import * as Yup from "yup";

const FlagColorForm = (props) => {
  const {
    selectedClient,
    setModal,
    getFlags,
    edit,
    colorEdit,
  } = props

  const {
    initialValues,
    handleSubmit,
    handleSubmitEdit,
    ValidateByColor
  } = useFlagColorForm(selectedClient, setModal, getFlags)

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required('You must write a name'),
    hexCode: Yup.string()
      .required('Select Color'),
    colorPriority: Yup.number("the value is not valid").positive('Color Priority must be greater than zero')
      .required('Color Priority is required')
  });

  return (
    <>
      <Formik
        initialValues={edit ? colorEdit : initialValues}
        enableReinitialize
        validationSchema={ValidateSchema}
        onSubmit={(values) => {
          edit ? handleSubmitEdit(values) : handleSubmit(values)
        }}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Row className="justify-content-md-center">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    label="Name"
                    value={props.values.name}
                    onChange={props.handleChange}
                  />
                  {
                    props.errors.name ? (<div className="text-danger">{props.errors.name}</div>) : ''
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Select Color</Form.Label>
                  <Form.Control
                    type="color"
                    id="hexCode"
                    name="hexCode"
                    title="Select Color"
                    required
                    value={props.values.hexCode}
                    onChange={props.handleChange}
                  />
                  {
                    props.errors.hexCode ? (<div className="text-danger">{"Select Color"}</div>) : ''
                  }
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Color Priority</Form.Label>
                  <Form.Control
                    id="colorPriority"
                    name="colorPriority"
                    label="Color Priority"
                    required
                    value={props.values.colorPriority}
                    defaulValue={props.values.colorPriority}
                    onChange={props.handleChange}
                  />
                  {
                    props.errors.colorPriority ? (<div className="text-danger">{"Value is not Valid"}</div>) : ''
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={4}>
                <Button
                  className="btn btn-success mt-2"
                  type="submit"
                  size="md"
                  block
                >
                  Save
                </Button>
              </Col>
            </Row>
          </form>
        )}

      </Formik>
    </>
  )
}

export default FlagColorForm