import { useEffect, useState } from "react";
import * as services from "../../../../services/missingCode";

const useFiles = (
  id,
  practiceId,
  loadRecordData,
  record = [],
  makeFilterFbd = true,
) => {
  const [filesAreVerified, setFilesAreVerified] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (record.driveFiles) {
      setFiles(
        makeFilterFbd
          ? record.driveFiles.filter((f) => f.type.toLowerCase() !== "fbd")
          : record.driveFiles,
      );
    }
  }, [record]);

  const verifyFiles = async () => {
    return services.verifyFiles(id, practiceId).then(([s, _r]) => {
      if (s) {
        setFilesAreVerified(true);
        loadRecordData();
      }
      return s;
    });
  };

  return {
    files,
    filesAreVerified,
    verifyFiles,
  };
};

export default useFiles;
