import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { chunk } from "../../util/arrays";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (direction, draggableStyle) => {
  const otherProps =
    direction === "horizontal"
      ? {
          width: "24%",
          margin: "0.5%",
        }
      : {};

  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    ...otherProps,
    ...draggableStyle,
  };
};

const getListStyle = (direction) => {
  const otherProps =
    direction === "horizontal"
      ? {
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "flex",
        }
      : {};

  return {
    width: "100%",
    ...otherProps,
  };
};

const DragAndDrop = ({ data, render, direction, className, onReorderItem }) => {
  const [state, setState] = useState({
    items: data,
  });

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const modifiedItem = state.items[result.source.index];
    const to = result.destination.index;

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );

    onReorderItem(modifiedItem, to);
    setState({
      items,
    });
  }

  const RenderElement = render;

  return (
    <div className={className}>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction={direction}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <RenderElement {...item} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragAndDrop;
