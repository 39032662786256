import { Box, Button, Divider, Grid } from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { AuditButton } from "./styles/AuditButton.styles";
import "../../../../styles/index.css";
import { Close, ExpandLess, ExpandMore, LocalPhone } from "@material-ui/icons";
import AircallPhone from "aircall-everywhere";
import { toast } from "react-toastify";
import { useState } from "react";

const HeaderCallCenterVerification = (props) => {
  const [showPhone, setShowPhone] = useState(false);
  const [minimize, setMinimize] = useState(false);

  const {
    itemVerification,
    audit,
    practiceInfoAndBookmarks,
    isLoadingAuditButon,
    selectedPhone,
    handleChangeCarrier,
    selectedCarrier,
    carriers,
    isLoadingPhone,
    record,
  } = props;

  const aircallPhone = new AircallPhone({
    domToLoadPhone: "#phone",
    onLogin: (settings) => {},
    onLogout: () => {},
    integrationToLoad: "zendesk",
    size: "big",
  });

  function onCallPhone() {
    setShowPhone(true);

    aircallPhone.isLoggedIn((response) => {
      if (response) {
        const payload = {
          phone_number: selectedPhone,
        };
        aircallPhone.send("dial_number", payload, (success, data) => {
          if (!success) {
            toast.error("error to load phone.");
          }
        });
      }
    });
  }

  function expand() {
    setMinimize(!minimize);
  }

  const { practiceName, patientName, provider } = itemVerification;
  const labelButton = !record?.audited ? "Audit" : "Unaudit";

  return (
    <>
      <div
        className="container-iframe"
        style={{
          display: showPhone ? "block" : "none",
          height: minimize ? "100px" : "auto",
          width: minimize ? "85px" : "auto",
        }}
      >
        <div
          style={{
            position: "fixed",
            height: "20px",
            width: "79px",
          }}
        >
          <div
            style={{
              position: "absolute",
              overflow: "hidden",
              right: "22px",
              padding: ".5rem",
            }}
          >
            {minimize ? (
              <ExpandMore className="icon" onClick={expand} />
            ) : (
              <ExpandLess className="icon" onClick={expand} />
            )}

            <Close
              className="icon"
              onClick={() => {
                setShowPhone(false);
                setMinimize(false);
              }}
            />
          </div>
        </div>
        <div id="phone"></div>
      </div>

      {!practiceInfoAndBookmarks && (
        <div
          style={{
            padding: "1rem",
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      )}

      {practiceInfoAndBookmarks && (
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                marginBottom: "1rem",
                height: "100%",
              }}
            >
              <h6 style={{ color: "#000" }}>Patient Name: {patientName}</h6>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={1} style={{ alignItems: "center" }}>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <select
                  defaultValue={selectedCarrier}
                  className="form-control"
                  onChange={(e) => handleChangeCarrier(e.target.value)}
                  value={selectedCarrier}
                >
                  {carriers.map(({ _id, name }) => (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  ))}
                </select>
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                {isLoadingPhone ? (
                  <div className="skeleton"></div>
                ) : (
                  <Button
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      letterSpacing: "2px",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={onCallPhone}
                  >
                    {" "}
                    <LocalPhone /> {selectedPhone}{" "}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                marginBottom: "1rem",
                height: "100%",
              }}
            >
              <h6 style={{ color: "#000" }}>Practice Name: {practiceName}</h6>

              {provider.length > 0 && (
                <>
                  <h6 style={{ color: "#000" }}>|</h6>
                  <h6 style={{ color: "#000" }}>Provider: {provider}</h6>
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Box
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <span style={{ color: "#000" }}>
                <strong>Address: </strong> {practiceInfoAndBookmarks.address}
              </span>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Box
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                height: "100%",
              }}
            >
              <span style={{ color: "#000" }}>
                <strong>Tax ID: </strong>
                {practiceInfoAndBookmarks.taxId}
              </span>
              <span style={{ color: "#000" }}>
                <strong>NPI #:</strong> {practiceInfoAndBookmarks.npi}
              </span>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <AuditButton onClick={audit}>
              {isLoadingAuditButon ? (
                <>
                  <Spinner size="sm" animation="border" /> updating{" "}
                </>
              ) : (
                <> {labelButton}</>
              )}
            </AuditButton>
          </Grid>
        </Grid>
      )}
      <Divider style={{ marginTop: "1rem" }} />
    </>
  );
};

export default HeaderCallCenterVerification;
