import * as CryptoJS from 'crypto-js';

export const encryptData = (data) => {
   return new Promise((resolve, reject) => {
      try {
         const NS = "1b671a64-40d5-491e-99b0-da01ff1f3341",
            salt = CryptoJS.lib.WordArray.random(256),
            iterations = 999,
            iv = CryptoJS.lib.WordArray.random(16);

         var key = CryptoJS.PBKDF2(NS, salt, {
            hasher: CryptoJS.algo.SHA512,
            keySize: 64 / 8,
            iterations: iterations
         });

         var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
            iv: iv,
            mode: CryptoJS.mode.CBC
         });

         var transitmessage = { "ciphertext": encrypted.toString(), "salt": salt.toString(), "iv": iv.toString() };

         resolve(transitmessage);
      }
    catch (err) {
            ////console.log(err);
            reject(err)
         }
      })

};

export const decryptData = (encryptedData) => {
   return new Promise((resolve, reject) => {
      try {
      const NS = "1b671a64-40d5-491e-99b0-da01ff1f3341",
         obj_json = encryptedData;
      
         // //console.log("data for decrypt", encryptedData);
         // resolve(true);
         var encrypted = obj_json.ciphertext;
         var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
         var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
         var key = CryptoJS.PBKDF2(NS, salt,
            {
               hasher: CryptoJS.algo.SHA512,
               keySize: 64 / 8, iterations: 999
            });

         var decrypted = CryptoJS.AES.decrypt(encrypted, key,
            { iv: iv });

         let Resp = decrypted.toString(CryptoJS.enc.Utf8);

         resolve(JSON.parse(Resp));
      }
      catch (err) {
         //console.log(err);
         reject(err)
      }
   })

}