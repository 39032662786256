import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { faCalendar } from "@fortawesome/fontawesome-free-solid";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({
  label,
  value,
  onChange,
  dateFormat = "MM/dd/yyyy",
  placeHolder = "MM/DD/YYYY",
}) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <InputGroup className="flex-nowrap">
        <ReactDatePicker
          className={`form-control custom-form-control `}
          dateFormat={dateFormat}
          autoComplete="off"
          placeholderText={placeHolder}
          name="startDate"
          id="startDate"
          showMonthDropdown
          showYearDropdown
          todayButton="Today"
          selected={value}
          onChange={onChange}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

export default DatePicker;
