import styled, { css } from "styled-components";

const headingColor = "#515151";

export const FBDPreviewContainer = styled.div`
  margin-top: 15px;
  ${({ asTemplate }) => {
    if (asTemplate) {
      return css`
        ${BookmarkTitle} {
          font-weight: unset;
          font-size: 1rem;
          color: #919191;
        }

        ${SubSection} {
          margin: 25px 0;
        }

        ${Bookmark} {
          margin: 6px 0;
        }
      `;
    }
  }}
`;

export const SectionContainer = styled.div`
  &:not(:first-child) {
    margin-top: 25px;
  }
`;
export const SectionTitle = styled.h1`
  border-bottom: solid 1px #dedede;
  padding-bottom: 15px;
  margin: 15px 0;
  font-size: 1.8rem;
  color: #0080c2 !important;
  font-weight: unset !important;
`;

export const SubSectionsContainer = styled.div``;

export const SubSection = styled.div`
  margin: 35px 0;
`;

export const SubSectionTitle = styled.h2`
  margin: 15px 0;
  font-size: 1.2rem;
  color: ${headingColor};
  font-weight: unset !important;
`;

export const BookmarksContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
export const Bookmark = styled.div`
  width: 33.3%;
  margin: 12px 0;

  &:not(:nth-child(3n)) {
    padding-right: 10px;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    &:nth-child(odd) {
      padding-right: 10px;
    }
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 10px 0;
    padding: 0;
  }
`;
export const BookmarkTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  display: block;
  color: #6b6b6b;
  /* margin-bottom: 2px; */
`;
export const BookmarkValue = styled.span`
  font-size: 0.85rem;
`;
