import {useEffect, useState} from "react";
import {getClients, getClientWithFilter} from "./services";
import {getPracticeWithFilter, getStateV2} from "../ClientPractice/ClientPractice.service";
import {getTemplates} from "../SharedElements/services";
import {useAsyncTableData} from "../../CustomTable/hooks";
import {getUrlParams} from "../../../util/browser";
import {initialValues} from "../ClientPractice/useClientPractice";

export const initialState = {
  name: '',
  state: '',
  city: '',
};

export function useClients() {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);
  const [clients, setClients] = useState([]);
  const [template, setTemplate] = useState([]);
  const [clientSelect, setClientSelect] = useState("");
  const [stateSelect, setStateSelect] = useState("");
  const [locateState, setLocateState] = useState([]);
  const [myFilters, setMyFilters] = useState({})

  const [state, setState] = useState({loaded: false, saving: false});

  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    getClientWithFilter,
    {
      ...initialValues,
      ...getUrlParams(Object.keys(initialValues))
    }, null
  )

  useEffect(async () => {
      const [dataClient, dataState, dataTemplate] = await Promise.all([
        getClients(),
        getStateV2(),
        loadTemplate()
      ]);
      setClients(dataClient[1]['records'])

      let state = dataState[1]['data']['value'];
      state = state.map(v => ({...v, value: v.id, label: v.name}))
      setLocateState(state)
      setTemplate(dataTemplate)
    },
    []);

  useEffect(async () => {
      if (JSON.stringify(myFilters) === JSON.stringify(initialState) || Object.entries(myFilters).length === 0) {
        const res = await loadClient();
      } else {
        await loadClientWithParam()
      }
    }, [myFilters]
  )

  const loadClientWithParam = async () => {
    const [state, response] = await getClientWithFilter(myFilters);
    if (response['records'][0]) {
      setClients([response['records'][0]])
    } else {
      setClients([])
    }

  }

  const loadClient = async () => {
    const [s, response] = await getClients()
    setClients(response['records'])
  }

  const loadTemplate = async () => {
    const [state, response] = await getTemplates()
    var data = response['data'];
    var tmp = []
    data.map(function (el) {
      var o = Object.assign({}, el);
      tmp.push({value: o._id, label: o.name})
    })
    return tmp
  }

  return {
    clients,
    showModalEdit,
    setShowModalEdit,
    showModal,
    setShowModal,
    locateState,
    clientSelect,
    setClientSelect,
    setMyFilters,
    showModalUser,
    setShowModalUser,
    template,
    setTemplate,
    stateSelect,
    setStateSelect,
    loadClient,
    dataSource,
    tableReference,
    refreshData
  };
}
