import { toast } from "react-toastify";
import { throwOnAxiosError } from "./config/Api";
import * as auth from "./util/auth";
import * as requestHandler from "./util/requestHandler";

const MSG_RE_AUTHENTICATE = "re-authenticate";
const MSG_BLACK_LISTED_TOKEN = "token-blacklisted";
const EXPIRED_TOKEN = "Token has expired and can no longer be refreshed";
const BLACKLISTED_TOKEN = "The token has been blacklisted";

// TODO Invalid token

// Set Request behavior for request wrapped in StatusAndResponse function
export function handleSRRequests(setDisplayLoader, setMustAuthenticate) {
  let willDisplayHandler;

  function displayLoaderOnDelay() {
    requestHandler.setBefore(() => {
      if (!willDisplayHandler)
        willDisplayHandler = setTimeout(() => {
          setDisplayLoader();
        }, 200);
    });

    requestHandler.setAfter(() => {
      clearTimeout(willDisplayHandler);
      willDisplayHandler = false;
      setDisplayLoader(false);
    });
  }

  function handleExceptions() {
    requestHandler.setExceptionMiddleware(({ message }) => {
      if (message === MSG_RE_AUTHENTICATE || message === MSG_BLACK_LISTED_TOKEN)
        return;
      toast.error(message);
    });
  }

  displayLoaderOnDelay();
  handleExceptions();
  requestHandler.setRequestMiddlewares([
    (req) => throwOnAxiosError(req, "Dear user, please try again"),
    (req) => {
      const {
        data: { name, success, message, err, show = false },
      } = req;

      let displayMessage = "Dear user, please try again";

      if (show) {
        displayMessage = err || message;
      }

      if (name && name === "to show" && message) {
        displayMessage = message;
      }

      if (success === false) {
        if (err && err === BLACKLISTED_TOKEN) {
          setMustAuthenticate(true);
          throw new Error(MSG_RE_AUTHENTICATE);
        } else if (err === EXPIRED_TOKEN) {
          throw new Error(MSG_BLACK_LISTED_TOKEN);
        }

        const Terr = new Error(displayMessage);
        Terr.stack = req.data;

        throw Terr;
      }
      return req;
    },
  ]);
}

// Define if the user is logged in and cache his permissions / data
export function onInitialLogin(
  loadMenuAndPermissions,
  _setUserData,
  setAuthLoaded
) {
  auth.cacheLoggedInDataIfExists().then((isLoggedIn) => {
    if (isLoggedIn) {
      loadMenuAndPermissions().then(({ practices }) => {
        const { userId } = auth.getLoggedInData();
        const userData = auth.getLocalData();

        if (userData.userId === userId) {
          _setUserData({ ...userData, practices });
        } else {
          _setUserData({ practices });
        }
        setAuthLoaded(true);
      });
      return;
    }
    setAuthLoaded(true);
  });
}
