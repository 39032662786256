import { ListGroup, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FormInputText as Text } from "../formControls";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import shortid from "shortid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BasicList = (props) => {
  const [items, setItems] = useState(
    props.values && typeof props.values === "object"
      ? Object.keys(props.values).reduce((a, i) => {
          a[i] = props.items;
          return a;
        }, {})
      : {}
  );
  const [inputs, setInputs] = useState({});

  const handleClickAdd = (e) => {
    setItems({
      ...items,
      [shortid.generate()]: inputs,
    });
  };

  const handleClickRemove = (item) => {
    let list = {};
    Object.assign(list, items);
    delete list[item];
    setItems(list);
    props.onChange(
      {
        remove: true,
        item,
      },
      item
    );
  };

  const handleChangeInputs = (e, index, key) => {
    e["index"] = index;
    e["indexKey"] = key;
    props.onChange(e, index);
  };

  useEffect(() => {
    // console.log("a?", props.items);
    setInputs(props.items);
    // if (props.values && typeof props.values === 'object' && Object.keys(props.values).length >0){

    // }
  }, [props.items]);

  return (
    <div className="my-3">
      <Row>
        <Col>{props.title && <label>{props.title}</label>}</Col>
        <Col className="text-right">
          <Button variant="outline-info" onClick={handleClickAdd}>
            <FontAwesomeIcon icon="plus" /> Add
          </Button>
        </Col>
      </Row>
      <ListGroup className="mt-1">
        {items &&
          Object.keys(items).map((key) => {
            const values = props.values || {};

            return (
              <ListGroup.Item className="py-3 " key={key}>
                <Row>
                  {items[key].map((item, index) => {
                    return (
                      <Col key={item.parameter} md={5}>
                        <Text
                          className="mb-0"
                          msg={"Enter a " + item.label}
                          id={item.parameter}
                          value={values[key] ? values[key][item.parameter] : ""}
                          disabled={
                            values[key] &&
                            values[key]["nonEditables"] &&
                            values[key]["nonEditables"].indexOf(
                              item.parameter
                            ) > -1
                          }
                          onError={() => {}}
                          onChange={(e) => handleChangeInputs(e, index, key)}
                        />
                      </Col>
                    );
                  })}
                  <Col>
                    {(!values[key] ||
                      (values[key] && values[key]["deletable"] !== false)) && (
                      <Button
                        variant="link"
                        className="text-danger"
                        onClick={(e) => handleClickRemove(key)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </div>
  );
};

export default BasicList;
