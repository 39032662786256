import styled from "styled-components";
import {ReactComponent as Flag} from "./../../../assets/svg/flag.svg";
import CustomPopover from "../../../../../CustomPopover";
import CustomTooltip from "../../../../../CustomTooltip";

const WrappedFlag = styled.div`

  cursor: ${({canChangeColor}) => canChangeColor ? 'pointer' : 'inherit'};

  &:not(:last-child) {
    margin-right: 1rem;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${({flagColor}) => flagColor};
  }
`

const FlagContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const findColorByHexColor = (hexColor, arrColors) => {
  return arrColors.filter(color => color.hexCode === hexColor)
}

const ColumnFlag = (
  {
    hexCode = '#ADA9A9',
    flagColors = [],
    transactionId,
    saveTransactionColor,
    canChangeColor = false
  }
) => {

  let currentColor = findColorByHexColor(hexCode, flagColors)
  const {name} = currentColor.length > 0 ? currentColor[0] : {name: ''}

  return canChangeColor
    ? (<CustomPopover
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      icon={
        <WrappedFlag flagColor={hexCode}>
          <FlagTooltip
            description={name}
            hexCode={hexCode}
            canChangeColor={canChangeColor}
          />
        </WrappedFlag>
      }
    >
      <FlagContainer>
        {flagColors.map(flag =>
          {
            return(
              <WrappedFlag
                key={flag._id}
                flagColor={flag.hexCode}
                canChangeColor
                onClick={() => saveTransactionColor({transactionId, colorId: flag._id})
                }>
                <FlagTooltip description={flag.name} hexCode={flag.hexCode} />
              </WrappedFlag>
            )
          }
        )}
      </FlagContainer>
    </CustomPopover>)
    : null
}

const FlagTooltip = ({description, hexCode, canChangeColor}) => {

  return description === ''
    ? (
      <WrappedFlag
        flagColor={hexCode}
        canChangeColor={canChangeColor}
      >
        <Flag />
      </WrappedFlag>
    )
    : (
      <CustomTooltip message={description}>
        <WrappedFlag
          flagColor={hexCode}
          canChangeColor={canChangeColor}
        >
          <Flag />
        </WrappedFlag>
      </CustomTooltip>)

}

export default ColumnFlag