import { Api, decryptApiResponse } from "../config/Api";
import { decryptData } from "../config/encryptData";
import { statusAndResponse } from "../util/requestHandler";

export const getAllUsers = (params) => {
  function onSuccess(resp) {
    return decryptApiResponse(resp);
  }

  return statusAndResponse(Api.get("api/v2/users/get/all", {params: params}), onSuccess);
};

export const deleteUser = (id) => {
  return statusAndResponse(
    Api.post("/api/v2/users/delete", {
      userId: id,
    })
  );
};

export const changePassword = (data, id) => {
  return statusAndResponse(Api.post(`/api/users/changepassword/${id}`, data));
};

export const getPracticesByClient = (id) => {
  return statusAndResponse(
    Api.get("api/users/get/all/practices/" + id),
    (resp) => {
      return resp.data.success;
    }
  );
};

export const getMenu = (id) => {
  return statusAndResponse(Api.get("api/users/get/menu"), (resp) => {
    return resp.data.success;
  });
};

export const getTypeUsers = () => {
  return statusAndResponse(Api.get("api/users/get/type"), (resp) => {
    return resp.data.success;
  });
};

export const getClients = (id) => {
  return statusAndResponse(Api.get("api/users/get/all/clients"), (resp) => {
    return resp.data.success;
  });
};

export const getPracticesByUser = (id) => {
  return statusAndResponse(Api.get("api/users/get/practices/" + id), (resp) => {
    return resp.data.success;
  });
};
