import { Form } from "react-bootstrap";
import { STATUSES } from "./transactionfiles.constants";

export default function ElegibilityStatusPicker({
  patientStatus,
  disabled,
  setPatientStatus,
}) {
  return (
    <Form.Group>
      <Form.Label>Eligibility Status</Form.Label>
      <Form.Control
        disabled={disabled}
        required
        id="patientStatus"
        as="select"
        name="patientStatus"
        defaultValue={
          STATUSES.find((x) => x.id === patientStatus) ? patientStatus : ""
        }
        onChange={({ target: { value } }) => {
          setPatientStatus(value);
        }}
      >
        <option value="">
          Please select an option...
        </option>
        {STATUSES.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}
