import { Api, decryptApiResponse,fakeResponse } from "../config/Api";
import { statusAndResponse } from "../util/requestHandler";

export const AUTHENTICATE_URL = "/api/authenticate";

// 1
export async function getPermissions() {
  // const onSuccess = (d) =>
  //   decryptApiResponse(d).then((r) => {
  //     return r || [];
  //   });

  return statusAndResponse(
    Api.get("/api/authz/get/user/permission"),
    decryptApiResponse
  );
  //return statusAndResponse(fakeResponse(fakeData), (r) => r.data.value);
}

export async function getFullMenu() {
  // const onSuccess = (d) => decryptApiResponse(d);  
  // return statusAndResponse(Api.get("/api/security/menu/section"), onSuccess);
  return statusAndResponse(
    Api.get(`/api/authz/system/permission`),
    decryptApiResponse
  );
}

export async function refreshToken() {
  return statusAndResponse(Api.post("/api/refresh"));
}
