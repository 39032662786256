import Select from "react-select";
import useSelectPractices from "./hooks/useSelectPractices";

const SelectPractices = ({onChange, selected,selectRef}) => {
  const {practices} = useSelectPractices();
  return (
    <>
      <label htmlFor="practice">Practice</label>
      <Select
        name='practice'
        onChange={onChange}
        options={practices}
        ref={selectRef}
      />
    </>
  );
}

export default SelectPractices;