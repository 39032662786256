import {useEffect, useState} from "react";
import { getSftpFilesReport} from "../../../AzureFiles/azureFiles.services";

const useSftpFileReport = () => {
  const [date, setDate] = useState(null);
  const [structure, setStructure] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    async function getReportByDate(){
      const [status, report] = await getSftpFilesReport(date);
      if (status) {
        const {data: d, structure: s} = report;
        setData(d);
        setStructure(s);
        console.log("Data")
        console.log(d)

        console.log("Structure")
        console.log(s)
      }
    }

    if (date) {
      getReportByDate();
    }
  }, [date]);


  return {
    date,
    data,
    structure,
    setDate
  }
}

export default useSftpFileReport