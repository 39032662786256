import React from "react";
import FileCreatorListItem from "./FileCreatorListItem";

const FileCreatorList = ({ focusPosition, files, removeFile, updateFile,canDelete }) => {
  return files.map((f, i) => (
    <FileCreatorListItem
      canDelete={canDelete}
      key={f.id}
      focusPosition={focusPosition}
      fileData={f}
      removeFileData={() => removeFile(i)}
      setName={(name) => {
        updateFile(i, { ...f, name });
      }}
      setFile={(file) => {
        updateFile(i, { ...f, file });
      }}
    />
  ));
};

export default FileCreatorList;
