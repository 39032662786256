import {Api, decryptApiResponse} from '../../../config/Api'
import {statusAndResponse} from "../../../util/requestHandler";
import {getDateAsString} from "../../../util/date";

export function getAzureFiles(f) {
  const filter = {...f}

  if (filter.createdStart && filter.createdEnd) {
    filter.createdStart = getDateAsString(new Date(filter.createdStart), 'yyyy-MM-dd', 'YYYY-MM-DD')
    filter.createdEnd = getDateAsString(new Date(filter.createdEnd), 'yyyy-MM-dd', 'YYYY-MM-DD')
  }
  else if(filter.createdStart) {
    filter.created = getDateAsString(filter.createdStart, 'yyyy-MM-dd', 'YYYY-MM-DD')
    delete filter.createdStart
  }

  return statusAndResponse(
    Api.get(`/api/az/files`, {params: filter}),
    decryptApiResponse
  )
}

export function getAzureFilesReport(date){
  const formatDate = getDateAsString(date, 'yyyy-MM-dd', 'YYYY-MM-DD')
  return statusAndResponse(
    Api.get(`/api/az/files/report?date=${formatDate}`),
    decryptApiResponse
  )
}
export function getSftpFilesReport(date){
  const formatDate = getDateAsString(date, 'yyyy-MM-dd', 'YYYY-MM-DD')
  return statusAndResponse(
    Api.get(`api/sftp/files/report?date=${formatDate}`),
    decryptApiResponse
  )
}