import {TemplateWrapped} from "../../Layout";
import CredentialManager from "../CredentialManager";

const CredentialWrapped = () => {
  return(
    <TemplateWrapped
      title='Credentials'
    >
      <div className='panel credential-form'>
        <div className="panel-body">
          <CredentialManager />
        </div>
      </div>
    </TemplateWrapped>
  )
}

export default CredentialWrapped
