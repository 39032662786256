import {saveTransaction, updateTransaction} from "../TransactionForm.service";
import {getLoggedInData} from "../../../../util/auth";
import {useContext, useEffect, useState} from "react";
import moment from "moment";
import {AppContext} from "../../../../App";
import {displayModalMessage} from "../../../../util/displayMessage";

const useTransactionModalForm = (transactionSelect, edit, refreshData) => {
  const appContext = useContext(AppContext);
  const practiceIds = appContext.get("selectedPractices");

  const initialValues = {
    "carrierName": "",
    "apptDate": "",
    "employerName": "",
    "relationship": "",
    "memberId": "",
    "patientId": "",
    "subscriberId": "",
    "patientFirstName": "",
    "patientLastName": "",
    "patientDOB": "",
    "subscriberFirstName": "",
    "subscriberLastName": "",
    "subscriberDOB": "",
    "typeRecord": "",
    "practiceId": ""
  }
  const [data, setData] = useState(edit ? transactionSelect : initialValues);

  useEffect(() => {
    if (edit) {
      let transaction = data
      transaction.apptDate = removeTime(transaction.apptDate)
      transaction.patientDOB = removeTime(transaction.patientDOB)
      transaction.subscriberDOB = removeTime(transaction.subscriberDOB)
      setData(transaction)
    }
  }, [])
  const removeTime = (dateString) => {
    return moment(new Date(dateString)).format('YYYY-MM-DD')
  };

  const onHandleSubmit = (values) => {
    values.userId = getLoggedInData().userId
    values.practiceId = practiceIds[0]
    if (edit) {
      delete values._id
      delete values.tableData
      updateTransaction(values, transactionSelect._id).then(([status, r]) => {

        if (status) {
          displayModalMessage(
            "Transaction Edited Successfully",
            <span>Transaction Edited</span>,
            "success",
            () => {
              // refreshData()
            }
          );
        }
      
      })
    } else {
      saveTransaction(values).then(([status, r]) => {
        if (status) {
          displayModalMessage(
            "Transaction Createed Successfully",
            <span>Transaction Created</span>,
            "success",
            () => {
              // refreshData()
            }
          );
        }

      })
    }
  }
  return {initialValues, onHandleSubmit, data}
}

export default useTransactionModalForm;