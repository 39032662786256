import React from "react";
import UserHelp from "../../../../util/UserHelp";
import { ExistingFileListItem } from "./ExistingFileListItem";

export const ExistingFileList = ({
  readOnly,
  existingFiles,
  updateFile,
  deleteFile,
  downloadFile,
  canDelete,
  canUpdate,
  canDownload
}) => {
  if (existingFiles.length === 0)
    return (
      <div>
        <UserHelp>
          Dear user, currently, this transaction does not have any fields
          attached to it.
        </UserHelp>
      </div>
    );

  return (
    <>
      <div className="row">
        <div className="col">
          <label>File List</label>
        </div>
      </div>
      <div className="row mt-2">
        {existingFiles.map((x, i) => (
          <ExistingFileListItem
            canDelete={canDelete}
            canUpdate={canUpdate}
            canDownload={canDownload}
            readOnly={readOnly}
            downloadFile={() => {
              downloadFile(x.id, x.filename);
            }}
            updateFile={(newFile) => {
              return updateFile(x.id, newFile);
            }}
            deleteFile={() => {
              deleteFile(x.id);
            }}
            key={x.id}
            {...x}
          />
        ))}
      </div>
    </>
  );
};
