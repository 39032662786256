import { Api, decryptApiResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";

export const getPermissions = () => {
  return statusAndResponse(
    Api.get(`/api/authz/system/permission`),
    decryptApiResponse
  );
};

export const getClients = () => {
  return statusAndResponse(
    Api.get(`/api/client/`),
    decryptApiResponse
  );
};

export const getPermissionByClient = (clientId) => {
  return statusAndResponse(
    Api.get(`/api/authz/client/permission`, { params: { clientId: clientId } }),
    decryptApiResponse
  );
};

export const updatePermissionByClient = (data) => {
  return statusAndResponse(
    Api.put(`/api/authz/client/permission`, data),
    decryptApiResponse
  );
};

export const removePermissionByClient = (clientId, permissionId) => {
  return statusAndResponse(
    Api.delete(`/api/authz/client/permission/${clientId}/${permissionId}`),
    decryptApiResponse
  );
};
