const doNothing = (r) => r;

let middlewares = [];
let promises = [];
let exceptionMiddleware = doNothing;
let before = doNothing;
let after = doNothing;
let working = false;

export function setBefore(fn) {
  before = fn;
}

export function setAfter(fn) {
  after = fn;
}

export function setRequestMiddlewares(md) {
  middlewares = md;
}

export function setExceptionMiddleware(md) {
  exceptionMiddleware = md;
}

export async function statusAndResponse(
  request,
  processResult = (r) => r,
  exceptionHandler = exceptionMiddleware,
  runBefore = before,
  runAfter = after
) {
  runBefore();
  promises.push(request);
  return request
    .then(_handleRequest)
    .then(processResult)
    .then(_buildSuccessResponse)
    .catch((ex) => {
      exceptionHandler(ex);
      return _buildErrorResponse(ex);
    })
    .finally(() => {
      waitForEveryRequest().then(runAfter).catch(runAfter);
    });
}

async function waitForEveryRequest() {
  if (!working) {
    working = true;
    let promiseSize = promises.length;
    for (let i = 0; i < promiseSize; i++) {
      await promises[i];
      if (promises[i + 1]) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 200);
        });
        promiseSize = promises.length;
      }
    }
    working = false;
    promises = [];
  }
}

function _handleRequest(req) {
  return [...middlewares].reduce((a, i) => {
    a = i(a);
    return a;
  }, req);
}

function _buildResponse(status, data) {
  return [status, data];
}

function _buildErrorResponse(e) {
  return _buildResponse(false, e.message);
}

function _buildSuccessResponse(message) {
  return _buildResponse(true, message);
}
