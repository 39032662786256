import styled from "styled-components";

export const ContainerAnalitycsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerCircularProgress = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto;
`;
