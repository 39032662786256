import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import {
  subscribe,
  getActionsAsObject,
  getPermissions,
  unsuscribe,
} from "../util/auth";

function usePriveRoute(permissionName) {
  const [reload, setReload] = useState(0);

  useEffect(() => {
    let cleaningUp = false;
    const index = subscribe(() => {
      if (!cleaningUp) setReload((r) => r + 1);
    });
    return function cleanup() {
      cleaningUp = true;
      unsuscribe(index);
    };
  }, []);

  const hasPemissions = getPermissions(permissionName);
  const actions = getActionsAsObject(permissionName);

  const permissions = getPermissions().reduce((a, i) => {
    a[i["name"]] = i.actions.reduce(
      (_a, _i) => ({ ..._a, [_i.name]: true }),
      {}
    );
    return a;
  }, {});

  return [hasPemissions, actions, permissions];
}

export function PrivateRoute({
  element: Component,
  parentPath,
  displayName,
  permissionName,
  render,
  prevLocation,
  ...props
}) {
  const [hasPemissions, actions, permissions] = usePriveRoute(permissionName);

  if (hasPemissions)
    return (
      <Component
        prevLocation={prevLocation}
        actions={actions}
        permissions={permissions}
      />
    );

  return <Navigate to="/" />;
}
