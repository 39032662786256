import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { Api, decryptApiResponse } from "../../../config/Api";
import { displayModalMessage } from "../../../util/displayMessage";
import { statusAndResponse } from "../../../util/requestHandler";
import withSignedUrlVerifier from "../../HOCS/withSignedUrlVerifier";
import Logo from "../../Logo";

function Presentational({ waiting, onClick }) {
  return (
    <div className="p-5 mt-5 d-flex align-items-center justify-content-center">
      <Container className="mt-5">
        <Row>
          <Col md={6} className=" offset-md-3 bg-white p-5">
            <div className="d-flex flex-column text-justify">
              <Logo fill="#0080c0" className="align-self-center mb-3" />
              <p className="my-3">
                Dear user, we have detected a login from a new location, please
                confirm if you want to authorize it.
              </p>

              <div className="d-flex mt-3">
                {!waiting && (
                  <>
                    <button
                      onClick={() => {
                        onClick(true);
                      }}
                      style={{ background: "#0080c0" }}
                      className="btn w-50 btn-lg mr-1 text-white"
                    >
                      Authorize
                    </button>
                    <button
                      onClick={() => {
                        onClick(false);
                      }}
                      className="btn w-50 btn-lg btn-danger"
                    >
                      Block
                    </button>
                  </>
                )}
                {waiting && (
                  <p className="text-muted w-100 text-center mb-0">
                    Please wait <FontAwesomeIcon icon="spinner" spin />
                  </p>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

//Services

async function doAuthorize({ id, trackLoginId, userId, token }) {
  const onSucces = (resp) => {
    return decryptApiResponse(resp).then((resp) => {
      console.log("gustavo desbloqueado", resp);
    });
  };

  return statusAndResponse(
    Api.post("/api/authenticate/allowUser", {
      id,
      trackLoginId,
      userId,
      token,
    }),
    onSucces
  );
}

async function doNotAuthorize({ id, trackLoginId, userId, token }) {
  const onSucces = (resp) => {
    return decryptApiResponse(resp);
  };

  return statusAndResponse(
    Api.post("/api/authenticate/blockUser", {
      id,
      trackLoginId,
      userId,
      token,
    }),
    onSucces
  );
}

// Hook

const initialState = {
  waiting: false,
  done: false,
  errorMessage: "",
  success: false,
  isAuthorized: false,
  isBlocked: false,
};

function useAuthorize({ _id: id, trackLoginId, userId }, params, token) {
  const [state, setState] = useState(initialState);

  async function authorize(proceed) {
    setState({ ...initialState, waiting: true });
    const [status, resp] = proceed
      ? await doAuthorize({ id, trackLoginId, userId, token })
      : await doNotAuthorize({ id, trackLoginId, userId, token });

    let res = {
      ...initialState,
      done: true,
      success: true,
      isAuthorized: proceed,
      isBlocked: !proceed,
    };

    if (!status) {
      res.errorMessage = resp || "Please try again";
      res.success = false;
    }

    setState(res);
  }

  return { state, authorize };
}

function SuccessMessage({ isAuthorized }) {
  if (isAuthorized) {
    return (
      <p>
        Dear user, this location has been allowed, you can close this window.
      </p>
    );
  }

  return (
    <>
      <p>Dear user, your account has been blocked successfully.</p>
      <p>
        Please check your e-mail. We sent a link where you can your password and
        unblock your account.
      </p>
    </>
  );
}

export function Authorize({ params, data, token }) {
  const [redirect, setRedirect] = useState(false);
  const {
    state: { waiting, done, success, isAuthorized, errorMessage },
    authorize,
  } = useAuthorize(data, params, token);

  useEffect(() => {
    if (!redirect && done) {
      if (success) {
        displayModalMessage(
          "Success",
          <SuccessMessage isAuthorized={isAuthorized} />,
          "success",
          () => {
            setRedirect(true);
          }
        );
        return;
      }
      displayModalMessage("Warning", <p>{errorMessage}</p>, "error");
    }
    return () => {};
  }, [done, errorMessage, isAuthorized, redirect, success]);

  if (redirect) {
    return <Navigate to="/" />;
  }

  return <Presentational waiting={waiting} onClick={authorize} />;
}

const Enhanced = withSignedUrlVerifier(
  Authorize,
  "/api/authenticate/authorize"
);

export default Enhanced;
