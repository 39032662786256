import CustomTable from "../../../../../CustomTable";
import {
  ButtonPlus,
  ContainerDetail,
  ContainerItem,
  ContainerData,
  Dot,
  NoDataIcon,
  PracticeName,
  PracticeNameWrapped,
  PracticesContainer,
  PracticeSum,
  PracticeWrapped,
  ReportContainer, IconData, ItemWrapped,
} from "../azureReport.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {GoFile, AiOutlineUpload, IoIosArrowDown, IoIosArrowForward} from "react-icons/all";

const ReportTable = ({data = {}, structure = {}}) => {
  const {rows = []} = structure

  const formatCols = [{
    title: '',
    field: 'pms',
    render: ({pms}) => <span style={{width: '3%'}} className='text-bold'>{pms}</span>
  }]

  const formatRows = Object.keys(rows).map(row => {
    return {
      pms: row
    }
  })

  return (
    <ReportContainer>
      <CustomTable
        className='table-report'
        options={{
          sorting: false,
          paging: false,
          header: false,
        }}
        search={false}
        columns={formatCols}
        data={formatRows}
        detailPanel={(rowData) => {
          const currentRows = rows[rowData.pms]
          return (
            <TableRows
              rows={currentRows}
              cols={Object.keys(data[rowData.pms])}
              formatCols={formatCols}
              pms={rowData.pms}
              data={data}
            />
          )
        }}
      />
    </ReportContainer>
  )
}

const TableRows = (
  {
    rows,
    pms,
    data,
    cols
  }
) => {
  const pmsData = data[pms]

  return (
    <PracticesContainer>
      {cols.map(col => {
          const currentData = pmsData[col]
          const sumSize = Object.keys(currentData).map(d => {
            return isNaN(currentData[d].size) ? 0 : +currentData[d].size
          }).reduce((acc, curr) => acc += curr, 0)
          const numData = Object.keys(currentData).length
          return (
            <Item
              key={col}
              rows={rows}
              col={col}
              numData={numData}
              pmsData={pmsData}
              sumSize={sumSize}
            />
          )
        }
      )}
    </PracticesContainer>
  )
}

const Item = ({rows, numData, col, pmsData}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ItemWrapped>
      <PracticeWrapped key={col}>
        <PracticeNameWrapped>
          <PracticeName>
            <span style={{marginRight: '10px'}}>{col}</span>
          </PracticeName>
          <PracticeSum>{numData}</PracticeSum>
        </PracticeNameWrapped>
        <ButtonPlus onClick={handleClick}>
          {isOpen ? <IoIosArrowDown size='1rem' /> : <IoIosArrowForward size='1rem' />}
        </ButtonPlus>
      </PracticeWrapped>
      <ContainerDetail show={isOpen}>
        {rows.map(row => {
          const currentCol = pmsData[col]
          const rowData = currentCol[row] || {}
          const hasData = currentCol ? currentCol[row] : 0
          return (
            <ContainerItem key={row}>
              {hasData && (
                <ContainerData>
                  <span style={{width: '100px'}}>{row}</span>
                  <div style={{
                    minWidth: '120px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginRight: '.825rem',
                    fontSize: '.8rem'
                  }}>
                    <IconData>
                      <GoFile color={'#ccc'} className='mr-1' />
                      {rowData.lines}
                    </IconData>
                    <IconData>
                      <AiOutlineUpload color={'#ccc'} className='mr-1' />
                      {parseFloat(rowData.size).toFixed(2)}MB
                    </IconData>
                  </div>
                </ContainerData>
              )}
              {hasData
                ? <FontAwesomeIcon color='green' icon={'check'}/>
                : (<ContainerData>{row}<NoDataIcon><Dot/><Dot/><Dot/></NoDataIcon></ContainerData>)
              }
            </ContainerItem>
          )
        })}
      </ContainerDetail>
    </ItemWrapped>
  )
}

export default ReportTable;