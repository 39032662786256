import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import { displayModalConfirmation } from "../../../../util/displayMessage";
import {
  filterAllPracticesFromAClient,
  groupPractices,
  reducePracticesForGetClient,
} from "../connections.middleware";
import { getLoggedInData } from "../../../../util/auth";
import { sortArrayOfObjectsByStringKey } from "../../../../util/arrays";

const useConnections = () => {
  const appData = useContext(AppContext);
  const practices = appData.get("practices") || [];
  const selectedPractices = appData.get("selectedPractices") || [];
  const [selectedClient, setSelectedClient] = useState(null);
  const [allPractices, setAllPractices] = useState([]);
  const [clientId, setClientId] = useState(null);
  const {
    setting: { canUseMultiPractice = true },
  } = getLoggedInData();

  useEffect(() => {
    let obj = groupPractices(practices);

    const _allPractices = Object.values(obj);
    const firstClient = _allPractices[0];

    setAllPractices(sortArrayOfObjectsByStringKey(_allPractices, "clientName"));
    setSelectedClient(firstClient);
  }, []);

  useEffect(() => {
    if (selectedPractices.length > 0) {
      setClientId(getClientIdFromSelectedPractices(selectedPractices[0]));
    } else {
      setClientId(null);
    }
  }, [selectedPractices]);

  const handleClientSelected = (client) => {
    setSelectedClient(client);
  };

  const handleClickPractice = (practice) => {
    if (checkClientId(practice)) {
      updatePractices(practice);
    } else {
      displayModalConfirmation(
        "Can't select",
        <span>
          Dear user, you have selected practices from another client, you can
          uncheck those practices, and select this new one.
        </span>,
        () => {
          appData.set("selectedPractices", [practice]);
        },
      );
    }
  };

  const updatePractices = (practice) => {
    const exist = selectedPractices.includes(practice);
    if (exist) {
      appData.set(
        "selectedPractices",
        selectedPractices.filter((p) => p !== practice),
      );
    } else {
      const _selectedPractices = canUseMultiPractice
        ? [...selectedPractices, practice]
        : [practice];
      appData.set("selectedPractices", _selectedPractices);
    }
  };

  const handleCheckAllPractices = () => {
    const { clientId: id = null } = selectedClient;
    const _practices = filterAllPracticesFromAClient(practices, id);
    appData.set("selectedPractices", _practices);
  };

  const handleUnCheckAllPractices = () => {
    appData.set("selectedPractices", []);
  };

  const checkClientId = (practiceId) => {
    const firstSelectedPractice = selectedPractices
      ? selectedPractices[0]
      : null;
    if (!firstSelectedPractice) return true;

    const clientFromFirstSelected = reducePracticesForGetClient(
      practices,
      firstSelectedPractice,
    );

    const clientFromCurrentPractice = reducePracticesForGetClient(
      practices,
      practiceId,
    );

    return (
      clientFromFirstSelected.clientId === clientFromCurrentPractice.clientId
    );
  };

  const getClientIdFromSelectedPractices = (practiceId) => {
    const client = reducePracticesForGetClient(practices, practiceId);

    return client.clientId || null;
  };

  const checkIfPracticeIsSelected = (practiceId) => {
    if (selectedPractices.includes(practiceId)) return 1;

    return 0;
  };

  return {
    selectedClient,
    allPractices,
    selectedPractices,
    clientId,
    canUseMultiPractice,
    handleClientSelected,
    handleClickPractice,
    handleCheckAllPractices,
    handleUnCheckAllPractices,
    checkIfPracticeIsSelected,
    getClientIdFromSelectedPractices,
  };
};

export default useConnections;
