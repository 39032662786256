import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import usePractices from "../../../../../../../hooks/usePractices";
import {getColorsByClient, setTransactionColor} from "../../../../services";

const useColumnFlag = (refresh) => {
  const [flagColors, setFlagColors] = useState([])
  const {firstSelected, getClientFromFirsPracticeSelected} = usePractices()
  const client = getClientFromFirsPracticeSelected()

  useEffect(() => {
    async function getColors() {
      const [status, colors] = await getColorsByClient(client)
      if (status) {
        setFlagColors(colors.sort((a, b) => a.colorPriority - b.colorPriority))
      }
    }

    getColors()
  }, [firstSelected])

  const saveTransactionColor = async ({transactionId, colorId}) => {
    const [status] = await setTransactionColor(client, transactionId, colorId)
    if (status) {
      toast.success('Color saved')
      refresh()
    }
  }

  return {
    firstSelected,
    client,
    flagColors,
    saveTransactionColor
  }
}

export default useColumnFlag
