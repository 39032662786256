import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../App";
import useConnections from "../../../../Connections/hooks/useConnections";
import { getStatisticsInformation } from "../services/analitycs.service";
import { moduleId } from "../../../../../../constants/moduleId";
import { elementId } from "../../../../../../constants/elementId";

export const useAnalitycs = (filters) => {
  const appData = useContext(AppContext);
  const selectedPractices = appData.get("selectedPractices") || [];
  const practicesList = appData.get("practices") || [];

  const [statistics, setStatistics] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(false);
  const { getClientIdFromSelectedPractices } = useConnections();
  const CONTAINS = "c";
  const handleAction = () => setAction(!action);

  useEffect(() => {
    if (selectedPractices.length > 0) {
      setClientId(getClientIdFromSelectedPractices(selectedPractices[0]));
    } else {
      setClientId(null);
    }
  }, [selectedPractices]);

  useEffect(() => {
    getStatisticFromVerificationStatus();
  }, [selectedPractices, filters]);

  async function getStatisticFromVerificationStatus() {
    await getStatisticsInformation(
      constraintDataToGetStatistics(selectedPractices),
    ).then((statisticResponse) => {
      if (statisticResponse[0]) {
        setStatistics(statisticResponse[1]);
      }
    });
  }

  function constraintDataToGetStatistics(practices, isAllArray = true) {
    const { startDate, endDate, type, filter } = filters;
    const { userId, selectedPractices } = JSON.parse(
      localStorage.getItem("appSettings"),
    );

    return {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      clientId:
        selectedPractices.length > 0
          ? getClientIdFromSelectedPractices(selectedPractices[0])
          : null,
      userId,
      moduleId: moduleId["transaction"],
      elementId: elementId["transaction"],
      filter: constraintFilterObject(JSON.stringify(filter)),
      type,
      practiceId: isAllArray ? selectedPractices : practices,
    };
  }

  function constraintFilterObject(obj) {
    const objectCopy = JSON.parse(obj);
    console.log(obj);
    const newObject = {};
    for (const key in objectCopy) {
      if (
        objectCopy.hasOwnProperty(key) &&
        objectCopy[key].value !== "" &&
        objectCopy[key].operation !== ""
      ) {
        newObject[key] = objectCopy[key];
      }

      if (
        objectCopy.hasOwnProperty(key) &&
        objectCopy[key].operation !== "" &&
        key === "audited"
      ) {
        newObject[key] = objectCopy[key];
        newObject[key].value = newObject[key].operation === CONTAINS;
        newObject[key].operation = "c";
      }
    }
    return newObject;
  }

  function handleShowModal() {
    setShowModal(true);
  }

  return {
    statistics,
    handleAction,
    showModal,
    setShowModal,
    handleShowModal,
    selectedPractices,
    practicesList,
    constraintDataToGetStatistics,
  };
};
