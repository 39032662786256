import React from "react";
import {ActionButtonIcon, ActionButtonStyle} from "./ActionButton.style";

const ActionButton = () => {
  return (
    <>
      <ActionButtonStyle>
        <ActionButtonIcon  margin="0 4px 4px 0px"/>
        <ActionButtonIcon  margin="0 0px 4px 0px"/>
        <ActionButtonIcon  margin="0 4px 0px 0px"/>
        <ActionButtonIcon  margin="0 0px 0px 0px"/>
      </ActionButtonStyle>
    </>
  )
}

export default ActionButton