import {getSftpFilesList} from "./sftp.services";
import TableFiles from "../AzureFiles/components/TableFiles";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const SftpFiles = () => {
  return (
    <TableFiles
      getFiles={getSftpFilesList}
      title={'SFTP Files'}
      tableTitle={'SFTP Files'}
      utc={false}
    />
  );
}

export default withSelectedPractices(SftpFiles);