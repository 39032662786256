import {
  ContainerTable,
  PracticeHeader,
  PracticesSection,
  TableConnectionsWrapper
} from "../styles/ConnectionsStyles";
import PracticeTable from "../PracticesTable";
import ScrollbarWrapper from "../../../../util/Styled/ScrollbarWrapper";

const PracticesContainer = (
  {
    practices,
    handleClickPractice,
    clientIdSelected,
    clientId,
    handleCheckAllPractices,
    handleUnCheckAllPractices,
    checkIfPracticeIsSelected,
    showUncheckAll = true,
    perPage = 10,
    showTitle = true,
    showButtons = false,
    paging = true,
    showScroll = false
  }) => {

  return (
    <PracticesSection>
      <PracticeHeader showScroll={showScroll}>
        <div style={{display: 'flex'}}>
          {showTitle && <h5>Practices of the selected client</h5>}
        </div>

      </PracticeHeader>

      <TableConnectionsWrapper>
        {showScroll
          ? (
            <ScrollbarWrapper height="50vh">
              <PracticeTable
                data={practices}
                checkIfPracticeIsSelected={checkIfPracticeIsSelected}
                handleClickPractice={handleClickPractice}
                perPage={perPage}
                paging={paging}
                showButtons={showButtons}
                showUncheckAll={showUncheckAll}
                clientIdSelected={clientIdSelected}
                clientId={clientId}
                handleCheckAllPractices={handleCheckAllPractices}
                handleUnCheckAllPractices={handleUnCheckAllPractices}
              />
            </ScrollbarWrapper>
          )
          : (
            <ContainerTable>
              <ScrollbarWrapper>
                <PracticeTable
                  data={practices}
                  checkIfPracticeIsSelected={checkIfPracticeIsSelected}
                  handleClickPractice={handleClickPractice}
                  perPage={15}
                  paging={false}
                  showButtons={showButtons}
                  showUncheckAll={showUncheckAll}
                  clientIdSelected={clientIdSelected}
                  clientId={clientId}
                  handleCheckAllPractices={handleCheckAllPractices}
                  handleUnCheckAllPractices={handleUnCheckAllPractices}
                />
              </ScrollbarWrapper>
            </ContainerTable>
          )
        }
      </TableConnectionsWrapper>
    </PracticesSection>
  )
}

export default PracticesContainer;