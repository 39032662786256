import React from "react";
import CustomForm from "../../../CustomForm";
import {
  NoteContainer,
  NotesContainer,
  TextArea,
  Title,
} from "./TransactionDetailsComponents.styles";
import { UpdateButton } from "./TransactionDetailsControls";

export default function Notes({
  aviable,
  notes = "",
  history = "",
  elgCodes = "",
  apptData = "",
  saving,
  onSave,
  updateNotes,
  isAudited,
}) {
  const cleanAppData =
    typeof apptData === "string" &&
    (apptData.trim().toUpperCase().indexOf("EMPTY") !== 0 ||
      apptData.trim() === "-") &&
    apptData.trim().length > 0
      ? apptData
      : "-";

  return (
    <NotesContainer>
      <CustomForm
        data={{ notes, history, elgCodes }}
        onSubmit={(data) => {
          const previousData = {
            previousHistory: history,
            previousElgCodes: elgCodes,
          };
          onSave(data, previousData);
        }}
      >
        <NoteContainer>
          <Title>Appointment Info:</Title> <span> {cleanAppData}</span>
        </NoteContainer>

        {aviable.note ? (
          <NoteContainer>
            <Title>Notes</Title>
            <CustomForm.WrappedInput
              id="notes"
              onChange={(value) => value}
              render={({ onChange, value }) => (
                <TextArea
                  disabled={isAudited ? isAudited : !updateNotes}
                  defaultValue={value}
                  placeholder={"Appointment's notes"}
                  rows="6"
                  onChange={({ target: { value } }) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </NoteContainer>
        ) : null}
        {aviable.history ? (
          <NoteContainer>
            <Title>History</Title>
            <CustomForm.WrappedInput
              id="history"
              onChange={(value) => value}
              render={({ onChange, value }) => (
                <TextArea
                  disabled={isAudited ? isAudited : !updateNotes}
                  defaultValue={value}
                  placeholder={"History's notes"}
                  rows="6"
                  onChange={({ target: { value } }) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </NoteContainer>
        ) : null}
        {aviable.elgCodes ? (
          <NoteContainer>
            <Title>ELG Codes</Title>
            <CustomForm.WrappedInput
              id="elgCodes"
              onChange={(value) => value}
              render={({ onChange, value }) => (
                <TextArea
                  disabled={isAudited ? isAudited : !updateNotes}
                  defaultValue={value}
                  placeholder={"ELG codes"}
                  rows="6"
                  onChange={({ target: { value } }) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </NoteContainer>
        ) : null}
        <div className="text-center">
          {(isAudited ? !isAudited : updateNotes) && (<UpdateButton disabled={saving} />)}
        </div>
      </CustomForm>
    </NotesContainer>
  );
}
