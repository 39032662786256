import { Popover } from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {displayModalConfirmation} from "../../../../../util/displayMessage";

const CredentialHeader = (
  {
    credential,
    clickeableRef,
    addCredential,
    handleDeleteCredentials
  }
) => {
  const {label, id} = credential

  return (
    <div className="pl-2 w-100 d-flex justify-content-between" ref={clickeableRef}>
      <div>
        <h4>{label}</h4>
      </div>
      <HeaderActions
        id={id}
        addCredential={addCredential}
        handleDeleteCredentials={handleDeleteCredentials}
      />
    </div>
  )
}

const HeaderActions = ({id, addCredential, handleDeleteCredentials}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ev) => {
    ev.stopPropagation();
    setAnchorEl(null);
  };

  return(
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ overflow: "inherit" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
       >
        <div className="header-dropdown-options">
          <OptionButton
            handleClose={handleClose}
            onClick={(ev) => {
              addCredential()
            }}
          >
            <FontAwesomeIcon icon="plus" /> Add Credential
          </OptionButton>
          {handleDeleteCredentials && (<OptionButton
            handleClose={handleClose}
            className='text-danger'
            onClick={(ev) => {
              displayModalConfirmation('Delete', <span>Dear user, Are you sure you want to delete all credentials?</span>, () => {
                handleDeleteCredentials()
              })
            }}
          >
            <FontAwesomeIcon icon="trash" /> Clear all Credential
          </OptionButton>)}
        </div>
      </Popover>
      <button
        className="d-inline-block btn btn-link p-0"
        onClick={(ev) => {
          ev.stopPropagation();
          handleClick(ev);
        }}
      >
        <FontAwesomeIcon icon="cog" />
      </button>
    </>
  )
}


const OptionButton = ({ handleClose, className, ...props }) => {
  return (
    <button
      className={`d-block btn btn-link  btn-sm ${className}`}
      {...props}
      onClick={(ev) => {
        ev.stopPropagation();
        handleClose(ev);
        props.onClick && props.onClick(ev);
      }}
    />
  );
};

export default CredentialHeader