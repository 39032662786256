import {Button, Form} from "react-bootstrap";
import React from "react";
import useUserFilters from "./useUserFilters";

const UserFilters = ({setFilters}) => {
  const {filters, handleFilterSubmit, setFilter, handleReset} = useUserFilters(setFilters)

  return(
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleFilterSubmit();
      }}
    >
      <Form.Group className="m-2">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          id="firstName"
          name="firstName"
          value={filters.firstName}
          onChange={({ target: { value } }) => {
            setFilter("firstName", value);
          }}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="m-2">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          id="lastName"
          name="lastName"
          value={filters.lastName}
          onChange={({ target: { value } }) => {
            setFilter("lastName", value);
          }}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="m-2">
        <Form.Label>Email</Form.Label>
        <Form.Control
          id="email"
          name="email"
          value={filters.email}
          onChange={({ target: { value } }) => {
            setFilter("email", value);
          }}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="m-2">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          id="phone"
          name="phone"
          value={filters.phone}
          onChange={({ target: { value } }) => {
            setFilter("phone", value);
          }}
        ></Form.Control>
      </Form.Group>

      <Form.Group className="m-2 text-center">
        <Button
          className="mt-3"
          variant="primary"
          type="submit"
        >
          Apply filters
        </Button>

        <Button
          className="mt-3 ml-1"
          variant="warning"
          type="button"
          onClick={handleReset}
        >
          Reset
        </Button>
      </Form.Group>
    </Form>
  )
}

export default UserFilters