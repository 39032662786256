import fileDownload from "js-file-download";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as middleware from "../middlewares";
import * as service from "../services";

const alert = withReactContent(Swal);

function displayError(title, text) {
  alert.fire({
    icon: "error",
    title,
    text,
  });
}

export default function useTemplate(id = null) {
  const [data, setData] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const loadDataFromId = useCallback(
    async (displayLoader = false) => {
      service
        .getTemplateById(id, displayLoader)
        .then(([status, d]) => {
          if (!status) throw new Error("");
          const tmpData = middleware.templateAsTree(d);
          setData(tmpData);
          setPreviewData(middleware.formatDataForPreview(tmpData));
        })
        .catch((r) => {
          displayError("Warning", "Couldn't load template");
        });
    },
    [id]
  );

  // Initial data load
  useEffect(() => {
    setLoadingData(true);
    loadDataFromId().then(() => {
      setLoadingData(false);
    });
  }, [loadDataFromId]);

  async function updateGeneralData(name, description) {
    return await service
      .updateGeneralData(id, name, description)
      .then(async ([status, r]) => {
        if (status) {
          await loadDataFromId();
        }
        return true;
      })
      .catch((err) => console.log(err));
  }

  async function addCategory(
    { name, type: typeCode, order },
    parentCategory = null
  ) {
    let category = parentCategory ? "Sub category" : "category";
    let type = parentCategory ? "code" : "type";

    if (!name || !typeCode) {
      displayError(
        "Verify fields",
        `Please provide a name and ${type} for the new ${category}`
      );
      return false;
    }

    if (!parentCategory)
      return await service
        .addCategory(id, name, typeCode, order)
        .then(([status, r]) => {
          loadDataFromId();
          return true;
        })
        .catch((err) => console.log(err));

    return await service
      .addSubCategory(id, parentCategory.id, name, typeCode, order)
      .then(([status, r]) => {
        loadDataFromId();
        return true;
      })
      .catch((err) => console.log(err));
  }

  function removeBookmark() {}

  async function addSubCategory(parentCategory, categoryData, order) {
    return addCategory({ ...categoryData, order }, parentCategory);
  }

  async function associateBookmarks(category, bookmarks, existingBookmarks) {
    const newBookmarksOrder = bookmarks.map(
      (el, index) => existingBookmarks.length + index + 1
    );

    return await service
      .associateBookmarks(
        id,
        category.id,
        category.categoryType,
        bookmarks,
        newBookmarksOrder
      )
      .then(([status, r]) => {
        loadDataFromId();
        return true;
      });
  }

  async function searchBookmark(bookMarkName) {
    if (bookMarkName.length < 3) return [];
    return await service.searchBookmark(bookMarkName).then(([status, r]) => {
      const result = status ? r : [];
      return middleware.searchBookmarks(result);
    });
  }

  async function deleteCategory(
    categoryId,
    categoryType,
    parentCategory = null
  ) {
    const [status, result] =
      categoryType === "section"
        ? await service.deleteCategory(id, categoryId)
        : await service.deleteSubCategory(id, categoryId, parentCategory.id);

    if (result) {
      loadDataFromId();
    }

    return result;
  }

  async function getBookmarkById(id) {}

  async function deleteBookmark(bookmarkId, sectionId, subsectionId) {
    const [status, result] = await service.deleteAssociatedBookmark(
      id,
      bookmarkId,
      sectionId,
      subsectionId
    );

    if (status) {
      loadDataFromId();
    }
  }

  async function downloadTemplate() {
    //TODO {frank} define a fileName
    let fileData = await service
      .downloadTemplate(id)
      .then((r) => {
        fileDownload(r.data, data.name.replace(/\s/g, "_") + ".docx");
        return true;
      })
      .catch((e) => {
        displayError("File", "Couldn't download the file, please try again");
      });
  }

  async function updateCategory(
    categoryId,
    name,
    type,
    categoryType,
    parentCategory
  ) {
    const [status, result] =
      categoryType === "section"
        ? await service.updateCategory(id, categoryId, name, type)
        : await service.updateSubCategory(
            id,
            categoryId,
            name,
            type,
            parentCategory.id
          );

    if (status) {
      loadDataFromId();
      return status;
    }
  }

  async function createBookmark(bookmarkType, bookmarkData) {
    const [status, r] = await service.createBookmark(
      bookmarkType,
      bookmarkData
    );

    if (status) {
      loadDataFromId();
    }
    return status;
  }

  async function getAssociatedBookmarkData(
    sectionId,
    sectionType,
    bookmarkId,
    bookMarkType
  ) {
    const [status, response] = await service.getAssociatedBookmarkData(
      id,
      sectionType,
      sectionId,
      bookmarkId,
      bookMarkType
    );

    return response;
  }

  async function updateAssociatedBookmarkData(
    bookmarkId,
    sectionId,
    sectionType,
    bookmarkType,
    data,
    parentCategoryId
  ) {
    if (typeof data.length !== "undefined" && isNaN(data.length)) {
      displayError("Inválid value", 'Field "lenght" must be a numeric value');
      return false;
    }

    if (typeof data.required !== "undefined") {
      data.required = data.required === "true" ? true : false;
    }

    if (sectionType === "section") {
      const [status] = await service.updateAssociatedBookmarkCategory(
        id,
        bookmarkId,
        data,
        sectionId
      );

      if (status) loadDataFromId();

      return status;
    }

    if (sectionType === "subsection") {
      const [status] = await service.updateAssociatedBookmarkSubCategory(
        id,
        bookmarkId,
        data,
        sectionId,
        parentCategoryId
      );

      if (status) loadDataFromId();

      return status;
    }
  }

  async function updateBookmarkConditions(
    bookmarkId,
    sectionId,
    subsectionId,
    conditions
  ) {
    const status = await service.updateAssociatedBookmarkConditions(
      id,
      bookmarkId,
      sectionId,
      subsectionId,
      conditions
    );

    if (status) loadDataFromId();

    return status;
  }

  async function getBookmarkActions() {
    const [status, result] = await service.getBookmarkActions();
    return result;
  }
  async function getBookmarkAffectTypes() {
    const [status, result] = await service.getBookmarkAffectTypes();
    return result;
  }
  async function getBookmarksToAffect(bookmarkId, sectionId, subSectionId) {
    return service
      .getBookmarksToAffect(id, bookmarkId, sectionId, subSectionId)
      .then(([status, d]) => d.map((x) => middleware.bookMarkToAffect(x)));
  }

  async function saveAffectedBookmarks(
    bookmarkId,
    data,
    sectionId,
    subSectionId
  ) {
    return await service
      .updateAssociateBookmarksDependants(
        id,
        bookmarkId,
        data,
        sectionId,
        subSectionId
      )
      .then(([status, r]) => {
        if (status) {
          loadDataFromId();
        }

        return status;
      });
  }

  async function reorderCategory(order, categoryId, subCategoryId) {
    return service.reorderCategory(id, order + 1, subCategoryId, categoryId);
  }
  async function reorderBookmark(
    order,
    bookmarkId,
    categoryOfbm,
    parentCategoryOfbm
  ) {
    service.reorderBookmark(
      id,
      order + 1,
      bookmarkId,
      categoryOfbm,
      parentCategoryOfbm
    );
  }

  return {
    data,
    previewData,
    loadingData,
    associateBookmarks,
    addCategory,
    addSubCategory,
    removeBookmark,
    searchBookmark,
    getBookmarkById,
    updateGeneralData,
    deleteCategory,
    deleteBookmark,
    downloadTemplate,
    updateCategory,
    createBookmark,
    getAssociatedBookmarkData,
    updateAssociatedBookmarkData,
    getBookmarkActions,
    getBookmarkAffectTypes,
    getBookmarksToAffect,
    saveAffectedBookmarks,
    updateBookmarkConditions,
    reorderCategory,
    reorderBookmark,
    loadDataFromId,
  };
}
