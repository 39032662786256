import {ButtonBreadcrumbs} from "../styles/BreadcrumbsFilters.styles";

const FilterItem = ({displayName, filter, onClick}) => {
  return (
    <li className={"breadcrumb-item"}>
      <ButtonBreadcrumbs
        filter={filter}
        onClick={() => onClick(filter)}
      >
        {displayName}
      </ButtonBreadcrumbs>
    </li>
  )
}

export default FilterItem