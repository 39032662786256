import styled from "styled-components";
import {ROW_FONT_SIZE} from "../../utils/constanst";

export const GridText = styled.label` 
  font-size: ${ROW_FONT_SIZE};
  color: black;
  margin-bottom: 0;
`;
export const TextButtonActions = styled.label`
  margin-top: 5px;
  font-size: 0.75rem;
  color: #394a62;
`;

export const ActionButton = styled.span`
  width: 25px;
  height: 24px;
  background-color: #CFECFC;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  flex-wrap: wrap;
`

export const ActionButtonIcon = styled.span`
  width: 6px;
  height: 6px;
  background-color: #6CB4E1;
  margin-bottom: .20rem;
`

export const BlurryText = styled.span`
  color: transparent;
  text-shadow: 0 0 4px #000;
  user-select: none;
`
