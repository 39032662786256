export function Wrapper({ children }) {
  return (
    <div className="login_content">
      <div className="login_box">
        <div className="login_form">
          <div className="logo margin_logo">
            <img src="/logo.svg" alt="" />
          </div>
          <div className="login_form_box">{children}</div>
        </div>
      </div>
    </div>
  );
}
