import {statusAndResponse} from "../../../util/requestHandler";
import {Api, decryptApiResponse} from "../../../config/Api";

const getResponse = r => r;

export const getColumnList = async (gridName) => {
  const [status, res] = await statusAndResponse(Api.get(`/api/appsetting/columns/grid/${gridName}`), getResponse);
  // console.log(res.data);
  return status ? res.data?.value : [];
}

export const getUserColumnConfiguration = async (gridName) => {
  const [status, res] = await statusAndResponse(Api.get(`/api/appsetting/customizeGrid/user/columns`), getResponse);
  if (status) {
    return res.data ?? []
  }
  return [];
}

export const saveUserColumn = async (gridName, columnId) => {
  const route = `/api/appsetting/customizeGrid/user/column`;

  const [status, res] = await statusAndResponse(Api.post(route, {columnId}), getResponse);
  if (status) {
    return res.data?.value?._id
  }
  return false;
}

export const deleteUserColumn = async (gridName, userColumn) => {
  const route = `/api/appsetting/customizeGrid/user/column/${userColumn}`;
  const [status, res] = await statusAndResponse(Api.delete(route), getResponse);
  return status;
}
