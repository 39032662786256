import { FaEye } from "react-icons/fa";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
    justify-content: center;
    align-items: center;
`

const Button = styled.button`
  background-color: transparent;
`

const SeeDetailsEye = ({ record, handleClickDetails }) => {
  return (
    <Div>
      <Button

        onClick={() => {
          handleClickDetails(record);
        }}
      >
        <FaEye size="1.5rem" color="#0080C0" />
      </Button>
    </Div>
  )
}

export default SeeDetailsEye;
