import {Formik, Field, Form, ErrorMessage} from "formik";
import {Col, Row} from "react-bootstrap";
import * as Yup from "yup";

const ResponseForm = ({submit}) => {
  return(
    <Formik
      initialValues={{
        response: ""
      }}
      onSubmit={submit}
      validationSchema={Yup.object().shape({
        response: Yup.string()
          .required("Please enter a message")
      })}
    >
      {({isSubmitting}) => (
        <Form>
          <Row>
            <Col>
              <label htmlFor="response">Message</label>
              <Field
                className='form-control'
                name="response"
                type="text"
                as="textarea"
              />
              <ErrorMessage
                name='response'
                className='text-danger'
                component='span'
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={{span: 4, offset: 4}}>
              <button
                disabled={isSubmitting}
                className="btn btn-primary btn-block"
                type="submit"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default ResponseForm