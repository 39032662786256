import styled from "styled-components";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const ArrangeWrapped = styled.div`
  /*display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;*/
/*  padding: 1rem;
  background-color: #D9D9D9;
  margin: .5rem 0 1rem;
  position: relative;
  transform: none;*/
  margin-bottom: 1rem;
`

const Status = styled.span`
  //min-width: 50px;
  text-align: center;
  list-style-type: none;
  background-color: #fff;
  border-radius: 10px;
  padding: .3rem;
  margin: .625rem;
  display: block;
  position: static;
`

const getItemStyle = (isDragging, draggableStyle) => ({
/*  userSelect: 'none',
  padding: 16,
  margin: `0 16px 0 0`,
  background: isDragging ? 'lightgreen' : 'grey',*/
  background: isDragging ? 'lightgreen' : '#fff',
  position: 'static',
  //transform: 'none',
  ...draggableStyle,
  top: 'auto !important',
  left: 'auto !important',
})

const getListStyle = (isDraggingOver) => ({
  transform: 'none',
  background: isDraggingOver ? 'lightblue' : '#f1f4f5',
  //display: 'flex',
  padding: 8,
  overflow: 'auto',
  borderRadius: '10px'
})

const ArrangeStatus = ({ivStatusArr, onDragEnd}) => {
  return (
    <div style={{transform: 'none', position: 'static'}}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable style={{transform: 'none'}} droppableId={'13123'} direction='vertical'>
          {(provided, snapshot) => (
            <ArrangeWrapped ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
              {ivStatusArr.map((s, i) => <Draggable draggableId={`${s.index}`} index={i} key={s.index}>
                  {(provided, snapshot) => (
                    <Status
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {s.name}
                    </Status>
                  )}
                </Draggable>
              )}
              {provided.placeholder}
            </ArrangeWrapped>
          )}
        </Droppable>
      </DragDropContext>
    </div>)
}

export default ArrangeStatus