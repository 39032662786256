import {useNavigate} from "react-router";
import {Share} from "@material-ui/icons";
import CustomTableList from "../../CustomTableList";
import useSharedElements from "./hooks/useSharedElements";

const SharedElements = () => {
  const history = useNavigate()
  const { templates } = useSharedElements()

  const columns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
  ]
  const actions = [
    {
      icon: () => <Share color="primary" />,
      tooltip: "Share bookmarks",
      onClick: (event, { name, description, _id }) => {
        console.log(_id)
        history(`/share-bookmarks/${_id}`)
        //setDisplayForm({ name, description, _id });
      },
    }
  ]

  return(
    <CustomTableList
      title='Shared Elements'
      columns={columns}
      data={templates}
      actions={actions}
    />
  )
}

export default SharedElements