import _ from 'loadsh'

export const findByIdCatalog = (catalogId, catalogs) => {
  return _.find(catalogs, {_id: catalogId})
}

export const groupByParentId = (array, parent, tree) => {
  tree = typeof tree !== 'undefined' ? tree : []
  parent = typeof parent !== 'undefined' ? parent : {_id: null}

  const children = _.filter(array, (child) => child.parentId === parent._id)
  parent['sections'] = []
  parent['modules'] = []
  parent['actions'] = []
  parent['items'] = []

  if (!_.isEmpty(children)) {
    if (parent._id === null) {
      tree = children
    } else {
      children.forEach(child => {
        const {type} = child
        if (type.toLowerCase() === 'action') {
          parent['actions'].push(child)
        } else if (type.toLowerCase() === 'section') {
          parent['sections'].push(child)
        } else {
          parent['modules'].push(child)
        }
      })
    }

    _.each(children, (child) => groupByParentId(array, child))
  }

  return tree
}