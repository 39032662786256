import {useAsyncTableData} from "../../CustomTable/hooks";
import {useCallback, useContext} from "react";
import {AppContext} from "../../../App";
import {useNavigate} from "react-router-dom";
import {getAllUsers} from "../../../services/users";

const useUsers = () => {
  const appContext = useContext(AppContext);
  const selectedPractices = appContext.get('selectedPractices')

  const history = useNavigate();

/*  const loadData = useCallback(() => {
    getAllUsers().then(([status, resp]) => {
      if (status) setDataTable(resp);
    });
  }, []);*/

  // On initial load
/*  useEffect(() => {
    loadData();
  }, [loadData]);*/

  const {
    tableReference,
    dataSource,
    refreshData
  } = useAsyncTableData(
    getAllUsers,
    {}
  )

  return {
    selectedPractices,
    history,
    tableReference,
    dataSource,
    refreshData
  }
}

export default useUsers