import {statusAndResponse} from "../../../util/requestHandler";
import {Api} from "../../../config/Api";

export const getSummaryReport = (values) => {
  return statusAndResponse(
    Api.get(`/api/report/transaction/summary`, {
      params: values,
      responseType: 'arraybuffer',
      headers: {'Content-Type': 'blob'},
    }),
  );
};