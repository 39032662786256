import { Api, decryptApiResponse } from "../../../../config/Api";
import { statusAndResponse } from "../../../../util/requestHandler";

export const dateFormat = "yyyy-MM-DD";

export function getDashboardData(startDate, endDate, practiceIds, carrierName) {

  return statusAndResponse(
    Api.post("/api/dashboard/graphicsData", {
      startDate,
      endDate,
      practiceIds,
      carrierName,
    }),
    decryptApiResponse
  );
}
