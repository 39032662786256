import {useState} from "react";

const useCustomPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ev) => {
    ev.stopPropagation();
    setAnchorEl(null);
  };

  return {
    anchorEl,
    open,
    handleClose,
    handleClick
  }
}

export default useCustomPopover