import React from "react";
import useCustomPopover from "./hooks/useCustomPopover";
import { Popover } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomPopover = (
  {
    icon = null,
    transformOrigin = {
      vertical: "top",
      horizontal: "right",
    },
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "right",
    }, ...props
  }) => {
  const {
    open,
    anchorEl,
    handleClose,
    handleClick,
  } = useCustomPopover();

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ overflow: "inherit" }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div className="header-dropdown-options">
          {React.Children.map(props.children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { handleClose });
            }
            return child;
          })}
        </div>
      </Popover>
      <button
        className="d-inline-block btn btn-link p-0"
        onClick={(ev) => {
          ev.stopPropagation();
          handleClick(ev);
        }}
      >
        {icon ? icon : <FontAwesomeIcon icon="cog" />}
      </button>
    </>
  );
};

export default CustomPopover;
