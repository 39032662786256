import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import * as services from "../../../../services/missingCode";

import {
  displayModalConfirmation,
} from "../../../../util/displayMessage";
import { AuditQuestion } from "../components/ModalMessages";
import {
  comparePatientInfoData,
  getObjectFromState,
  getRecordType,
  validateRequiredFields,
  requiredFields,
  shouldUpdateSpreadsheetDrive,
} from "../TransactionDetail.utils";
import usePractices from "../../../../hooks/usePractices";

const useTransactionDetail = () => {
  const [data, setData] = useState();
  const [record, setRecord] = useState();
  const [hasPolicy, setHasPolicy] = useState(false);

  const { state } = useLocation();

  let objState = getObjectFromState(state)

  const {
    id,
    overridePermissions,
    practice: practiceId = null,
    practiceName = '',
  } = objState;


  const {
    getSelectedPractice,
    getClientId
  } = usePractices()

  const selectedPractice = getSelectedPractice(practiceId)

  const loadRecordData = useCallback(async () => {
    services.getTransaction(id, practiceId).then(([s, r]) => {
      if (s) {
        setRecord({
          ...r,
          type: getRecordType(r),
        });
      }
    });
  }, [id, practiceId]);

  const loadSpreadSheet = useCallback(
    async () => {
      services.getSpreadsheet(id).then(([status, d]) => {
        if (status) {
          setData({ ...d, type: getRecordType(d) });
          setHasPolicy(!!d.policyType);
        }
      });
    },
    [id, practiceId],
  );

  useEffect(() => {
    if (id) {
      loadRecordData();
      loadSpreadSheet();
    }
  }, [id, loadRecordData]);

  const getMissingFiles = async () => {
    const [status, missingFilesObj] = await services.checkFiles(
      getClientId(practiceId),
      id
    );

    if (!status) throw new Error("Could not check files");

    return Object.entries(missingFilesObj).reduce((acum, [key, fileExists]) => {
      if (!fileExists) {
        acum.push(key);
      }
      return acum;
    }, []);
  };

  const audit = async () => {

    const {audited = false} = record;

    const missing = requiredFields(data)

    if (validateRequiredFields(missing, 'Cannot Audit', !audited)) {
      const doAudit = !record.audited;
      const label = doAudit ? "verified" : "not verified";

      let missingFiles = [];
      const missingFields =
        doAudit && record.type === "FBD" && record.uploadFBD
          ? record.missingRequiredInputQty
          : 0;

      if (doAudit) {
        try {
          missingFiles = await getMissingFiles();
        } catch (error) {
          return;
        }
      }

      displayModalConfirmation(
        "Confirmation",
        <AuditQuestion
          missingFiles={missingFiles}
          missingFieldsQty={missingFields}
          label={label}
        />,
        () => {
          performAudit(doAudit, label);
        }
      );
    }
  };

  const performAudit = (doAudit, label) => {
    services
      .verifyTransaction(record, practiceId, doAudit)
      .then(([status, _r]) => {
        if (!status) {
          return;
        }
        loadRecordData();
        toast.success(`The transaction was set as ${label}`);
      });
  };

  const updateSpreadsheet = (oldData, data) => {
    const dataAux = {
      policyType: null,
      ...data,
      updateSpreadsheetDrive: shouldUpdateSpreadsheetDrive(oldData, data),
    };

    const missing = requiredFields(dataAux)

    if (validateRequiredFields(missing, 'Cannot update')) {
      services
        .updateSpreadsheet({
          ...dataAux,
          practiceId,
          apptDateType: data.ApptDate + "T00:00:00.000Z",
        })
        .then(([status, _r]) => {
          if (!status) {
            return;
          }
          setHasPolicy(true);
          loadRecordData();
          loadSpreadSheet();
          toast.success("Spreadsheet saved");
        });
    }
  };

  const refreshSpreadsheet = async ({ _id: transactionId }) => {
    let clientId = getClientId(practiceId);

    displayModalConfirmation(
      "Confirmation",
      "Do you want to retry the verification?",
      () => {
        services
          .refreshSpreadsheet(transactionId, practiceId, clientId)
          .then(([status, _r]) => {
            if (!status) {
              return;
            }
            loadRecordData();
            toast.success("Verification updated");
          });
      }
    );
  };

  const checkPatientInfo = (fields, callback) => {
    const diff = comparePatientInfoData(fields, data);

    if (diff.length > 0) {
      return displayModalConfirmation(
        "Confirmation",
        <p>
          Dear user, the following{" "}
          {diff.length > 1 ? "fields dont" : "field doesn't"} match with the
          patient info data:
          <br />
          <br />
          <strong>{diff.join(", ")}</strong>
          <br />
          <br />
          Would you like to continue?
        </p>,
        callback
      );
    }
    callback();
  };

  const addComment = async (comment) => {
    const {client = []} = selectedPractice
    const clientId = client.length ? client[0] : {}
    await services.addCommentToTransaction(comment, clientId._id, id)
  }


  return {
    data,
    record,
    audit,
    updateSpreadsheet,
    loadRecordData,
    checkPatientInfo,
    refreshSpreadsheet,
    hasPolicy,
    overridePermissions,
    practiceName,
    id,
    practiceId,
    selectedPractice,
    addComment
  };
};

export default useTransactionDetail;
