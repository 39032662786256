export const operations = [
  {
    name: "Contains",
    value: "c",
  },
  {
    name: "Does not contains",
    value: "nc",
  },
  {
    name: "Is equal to",
    value: "e",
  },
  {
    name: "Is not equal to",
    value: "ne",
  },
  {
    name: "Starts with",
    value: "sw",
  },
  {
    name: "Ends with",
    value: "ew",
  },
  {
    name: "Is empty",
    value: "em",
  },
  {
    name: "Is not empty",
    value: "nem",
  },
];