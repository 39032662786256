import axios from "axios";
import * as authService from "../services/auth";
import * as auth from "../util/auth";
import { decryptData, encryptData } from "./encryptData";
import {toast} from "react-toastify";

// Flag
let refreshing = false;

let middlewares = {
  request: [],
  response: [],
};

export const addMiddleware = (type, fn) => {
  middlewares[type].push(fn);
};

export const removeMiddleware = (type, fn) => {
  middlewares[type] = middlewares[type].filter((x) => x !== fn);
};

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  header: {
    "Content-Type": "application/json",
  },
});

Api.defaults.headers.put["Content-Type"] = "application/json";
Api.defaults.headers.post["Content-Type"] = "application/json";

// Handle Request
Api.interceptors.request.use(async (cfg) => {
  const config = middlewares["request"].reduce((a, el) => {
    return el(a);
  }, cfg);

  _injectBearerToken(config);

  if (process.env.REACT_APP_LOG_REQUEST)
    console.log(config.url, config.data || config.params || "-");

  // Decorate the request
  if ((config.data && config.data instanceof FormData) === false) {
    if (!config._retry) return await _encryptRequest(config);
  }

  return config;
});

// Handle Responses
Api.interceptors.response.use(
  (response) => {
    _logoutIfBlockedOrInvalidToken(response);
    let originalRequest = response.config;

    const willRefreshToken =
      response.data.code === 401 && auth.getToken() && !originalRequest._retry;

    if (willRefreshToken) {
      originalRequest._retry = true;
      return _refreshToken()
        .then((r) => {
          if (!r) throw new Error("");
          return Api.request(originalRequest);
        })
        .catch(() => {
          return fakeResponse(false);
          //          throw new Error("");
        });
    } else if (response.data.code === 400) {
      throw new Error('Dear user, something went wrong with your connection, please try again')
    }

    return response;
  },
  (error) => {
    console.log("ax error", error);
    return error;
  }
);

const _refreshToken = async () => {
  if (!refreshing)
    refreshing = new Promise((resolve, reject) => {
      return authService.refreshToken().then(([status, resp]) => {
        if (!status || resp.data.success === false) {
          resolve(false);
          return;
        }
        auth.updateToken(resp.data.value.token);
        resolve(true);
        refreshing = false;
      });
    }).catch((r) => {
      console.log("gotcha");
      auth.logout()
      window.location.reload();
      return r;
    });

  return refreshing;
};

export const decryptApiResponse = (r) => {
  if (r && r.data && r.data.value) {
    return decryptData(JSON.parse(r.data.value));
  }

  return Promise.reject("");
};

export const getFile = (url, params = {}) =>
  new Promise((resolve, reject) => {
    Api.defaults.headers.get["Content-Type"] = "application/json";
    try {
      Api.get(url, {
        responseType: "blob",
        params,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });

export const fakeResponse = async (
  returnedValue,
  asError = false,
  timer = 300
) => {
  return await new Promise((resolve, reject) => {
    setTimeout(
      () =>
        asError === false
          ? resolve({
              data: {
                success: true,
                value: returnedValue,
              },
            })
          : reject("Error"),
      timer
    );
  });
};

export const throwOnAxiosError = (req, message) => {
  if (typeof req === "object" && req.isAxiosError) {
    throw new Error(message);
  }
  return req;
};

async function _encryptRequest(config) {
  const newData = await encryptData(config.data);
  config.data = newData;
  return config;
}

function _logoutIfBlockedOrInvalidToken(response) {
  const userIsBlocked =
    response.data && response.data.code && response.data.code === 403;

  // Logout if a 403 is recieved response.data.code === 500
  if (userIsBlocked && response.config.url !== authService.AUTHENTICATE_URL) {
    auth.logout();
    window.location.reload();
  }

  if (response.data.code === 401 && response.data.err === "Invalid token") {
    auth.logout();
    window.location.reload();
  }
}

function _injectBearerToken(config) {
  const token = auth.getToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
}
