import {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {AppContext} from "../../../../../../App";
import {getClientFromPracticesSelected, groupPractices} from "../../../connections.middleware";
import {getLoggedInData} from "../../../../../../util/auth";
import Swal from "sweetalert2";

const usePracticesByClientSelected = (closeModal) => {
  const appContext = useContext(AppContext);
  const practices = appContext.get("practices");
  const selectedPractices = appContext.get("selectedPractices");
  const showPracticeModal = appContext.get("showPracticeModal");
  const {setting: {canUseMultiPractice = true}} = getLoggedInData();
  const [activePractices, setActivePractices] = useState(selectedPractices);
  const [, setSearchParams] = useSearchParams();

  const [groupedPractices, setGroupedPractices] = useState(null);

  const client = selectedPractices.length > 0
    ? getClientFromPracticesSelected(selectedPractices[0], practices)
    : null;

  const practicesFromClient = client && groupedPractices
    ? groupedPractices[client.clientId].practices.reduce((acc, cur) => [...acc, cur._id], [])
    : [];

  useEffect(() => {
    let obj = groupPractices(practices);

    setGroupedPractices(obj)
    setActivePractices(selectedPractices)

  }, [selectedPractices])

  const handleClickPractice = practice => {
    const exist = activePractices.includes(practice)

    if (exist) {
      const _newActivePractices = activePractices.filter(p => p !== practice)
      setActivePractices(_newActivePractices)
    } else {
      const _activePractices = canUseMultiPractice ? [...activePractices, practice] : [practice]
      setActivePractices(_activePractices)
    }
  }

  const handleCheckAllPractices = () => {
    const allExceptActivesPractices = practicesFromClient.filter(p => !selectedPractices.includes(p))
    setActivePractices([...selectedPractices, ...allExceptActivesPractices])
  }

  const handleUncheckAllPractices = () => {
    setActivePractices([])
    //appContext.set('selectedPractices', [])
   // closeModal()
  }

  const handleClickSetPractices = () => {
    // TODO improve functionality
    if(activePractices?.length <= 0){
      Swal.fire({
        title:"Error",
        icon: 'error',
        text: 'Must select at least one practice.'
      })
      return
    }
    setSearchParams({})
    window.location.reload()
    appContext.set('selectedPractices', [...activePractices])
    appContext.set('showPracticeModal', false)
    //closeModal()
    localStorage.setItem('filters', '')
  }

  const checkIfPracticeIsSelected = (practiceId) => {
    if (activePractices.includes(practiceId)) {
      return 1
    }
    return 0
  }

  const togglePracticeModal = () => {
    appContext.set('showPracticeModal', !showPracticeModal)
  }

  return {
    client,
    practicesFromClient,
    countSelectedPractices: selectedPractices.length,
    groupedPractices,
    selectedPractices,
    canUseMultiPractice,
    handleClickPractice,
    handleCheckAllPractices,
    handleUncheckAllPractices,
    handleClickSetPractices,
    checkIfPracticeIsSelected,
    togglePracticeModal,
    activePractices
  }

}

export default usePracticesByClientSelected;