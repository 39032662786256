export const general = {
  active: true,
  label: "GENERAL",
  items: [
    {
      items: [],
      label: "Connections",
      permission: "",
      path: "/connections",
      isParent: false,
      icon: "svg-connections",
    },
    {
      items: [],
      label: "Tools",
      permission: "",
      path: "/converter",
      isParent: false,
      icon: "svg-tools",
    },
  ],
}

export const modules = []
