import { Col, Row } from "react-bootstrap";
import useMemberPolicy from "./hooks/useMemberPolicy";
import styled from "styled-components";
import moment from "moment";
import { Fragment } from "react";
import UserHelp from "../../../../util/UserHelp";

const MemberPolicy = ({ setReloadData }) => {
  const { linked, linkedData, selectedElement, handleClickItem, handleClick } =
    useMemberPolicy(setReloadData);

  return (
    <div className="panel">
      <div className="panel-body">
        <Row>
          {linked.length === 0 ? (
            <Col>
              Dear user, currently there are no other transactions with this
              member Id
            </Col>
          ) : (
            <>
              <Col md={3}>
                <RecordList
                  lists={linked}
                  handleClickItem={handleClickItem}
                  selectedElement={selectedElement}
                />
              </Col>
              <Col md={9}>
                {selectedElement ? (
                  <Fragment>
                    <div className="my-3">
                      <UserHelp>
                        Dear user, upon saving, the selected record's policy
                        will be loaded into the current transaction
                      </UserHelp>
                    </div>
                    <button
                      className="btn btn-action text-white"
                      onClick={handleClick}
                    >
                      Use this policy
                    </button>
                  </Fragment>
                ) : (
                  <div className='my-3'>
                    <UserHelp>
                      Please select one of the records at the left side
                    </UserHelp>
                  </div>
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Li = styled.li`
  cursor: pointer;
  padding: 0.625rem 0;
  border-bottom: solid 1px #f5f5f5;
  border-right: ${({ active }) => (active ? "4px solid #3e8ef7" : "none")};
  font-weight: ${({active}) => (active ? '500' : 'normal')};

  &:hover {
    font-weight: 500;
    font-size: 2rem;
  }

  & .name {
    font-size: 1.3rem;
  }

  & .date {
    font-size: 1rem;
    color: #ccc;
  }
`;

const RecordListContainer = styled.div`
  //border-right: 1px solid #ccc;
`;

const RecordList = ({ lists, handleClickItem, selectedElement }) => {
  return (
    <RecordListContainer>
      <h2>Records</h2>
      <Ul>
        {lists.map((list) => {
          return (
            <Li
              active={list.lastId === selectedElement}
              key={list._id}
              onClick={() => handleClickItem(list.lastId)}
            >
              <div className="name">{list._id}</div>
              <div className="date">
                {moment(list.lastAppDate).format("MM/DD/YYYY")}
              </div>
            </Li>
          );
        })}
      </Ul>
    </RecordListContainer>
  );
};

export default MemberPolicy;
