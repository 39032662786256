import {GetFlagColorByClient, SaveFlagColor, UpdateFlagColor} from "../FlagColor.service";
import {toast} from "react-toastify";

const useFlagColorForm = (selectedClient, setModal, getFlags) => {

  const initialValues = {
    "_id": "",
    "name": "",
    "hexCode": "",
    "colorPriority": 0,
  }

  const handleSubmit = (values) => {
    GetFlagColorByClient(selectedClient.clientId, {hexCode: values.hexCode}).then(([status, r]) => {
      if (status) {
        if(r.length === 0)
        {
          SaveFlagColor(values, selectedClient.clientId)
            .then(([status, r]) => {
              if (status) {
                getFlags()
                setModal(false)
                toast.success(
                  `Color was saved correctly`
                );
              }
            })
        }
        else {
          console.log("Error al guardar")
          toast.error(
            `This color already exists`
          );
        }
      }
    });

  }

  const handleSubmitEdit = (values) => {
    console.log(values)
    UpdateFlagColor(values, selectedClient.clientId)
      .then(([status, r]) => {
        if (status) {
          getFlags()
          setModal(false)
          toast.success(
            `Color was update correctly`
          );
        }
      });
  }

  return {
    initialValues,
    handleSubmit,
    handleSubmitEdit,
  }
}

export default useFlagColorForm