import {useContext} from "react";
import styled from "styled-components";
import {AiOutlineArrowDown, AiOutlineArrowUp} from "react-icons/all";
import {AppContext} from "../../../../../App";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const Wrapped = styled.div`
  //flex-basis: 40%;
`

const ListContainer = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
`

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  font-size: .9rem;
  font-weight: ${({bold = true}) => bold ? '400' : '300'};
  color: ${({sort}) => sort ? '#8f8f8f' : '#8e8e8f'};

  &:not(:last-child) {
    &::after {
      margin-left: .625rem;
      padding-right: 0.5rem;
      color: #ccc;
      content: "/";
    }
  }
`

const sortFields = {
  carrier: 'Carrier',
  dateToOrder: 'Appointment',
  iv_process_result: 'Verification By',
  patientName: 'Patient Name',
  priorityOrder: 'Eligibility status',
  practiceName: 'Practice',
  provider: 'Provider'
}

const SortConfiguration = () => {
  const context = useContext(AppContext)
  const {transaction = {}} = context.get('sortConfiguration')
  const {priority = [], ...sort} = transaction
  const _priority = [...priority].reverse()
  const fieldsSort = Object.keys(sort)

  return (
    <Wrapped>
      <ListContainer className='breadcrumb1'>
        {fieldsSort.map((f, i) => {
          const showArrow = f !== 'priorityOrder'
          const sortDirection = sort[f]

          if (showArrow) {
            return (
              <StyledListItem sort={true} className='breadcrumb-item1' key={i}>
                {sortFields[f]}
                {sortDirection > 0 ? (<AiOutlineArrowUp size={'.8rem'}/>) : (<AiOutlineArrowDown size={'.8rem'}/>)}
              </StyledListItem>
            )
          } else {
            return (
              <OverlayTrigger
                key={i}
                delay={{show: 250, hide: 100}}
                overlay={
                  <Tooltip id={sortFields[f]}>
                    {_priority.map(a => a.k).join(' / ')}
                  < /Tooltip>
                }>
                <StyledListItem
                  sort={true}
                  className='breadcrumb-item1'
                >
                  <span>
                    {sortFields[f]}
                  </span>
                </StyledListItem>
              </OverlayTrigger>
            )
          }
        })}
      </ListContainer>
    </Wrapped>
  )
}

export default SortConfiguration
