import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Api, decryptApiResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";
import { Loader } from "../../formControls";
import CenteredMessage from "../../helper/CenteredMessage";

// Helper
function getAditionalUrlParameters(match, params) {
  return match.path
    .split("/:")
    .map((x) => x.replace("?", ""))
    .reduce((a, i) => {
      const value = params[i];
      return (a += value ? "/" + value : "");
    }, "");
}

// Service
function verifyUrl(url) {
  return statusAndResponse(Api.get(url), decryptApiResponse);
}

// Hook
const defaultState = {
  invalidUrl: false,
  redirectToLogin: false,
  loaded: false,
};

function useSignedUrlVerifier(serverUrl, currentUrl, signatureField) {
  const [flags, setFlags] = useState(defaultState);
  const [data, setData] = useState(false);

  const tempToken = currentUrl.split(signatureField + "=");
  const token = tempToken.length > 0 && tempToken[1];

  useEffect(() => {
    verifyUrl(serverUrl + currentUrl)
      .then(([status, d]) => {
        if (!status) throw new Error("Invalid url");
        setData(d);
        setFlags({ ...defaultState, loaded: true });
      })
      .catch((r) => {
        setFlags({ ...defaultState, invalidUrl: true });
      });
  }, [currentUrl, serverUrl]);

  return { flags, token, data };
}

export default function withSignedUrlVerifier(
  Component,
  serverUrl,
  signatureField = "signature"
) {
  return function ({ location, match, ...props }) {
    const { params } = match;
    const { search: url } = location;

    const aditionalParameters = getAditionalUrlParameters(match, params);
    const cleanBaseUrl = `${serverUrl}/${aditionalParameters}`.replace(
      "//",
      "/"
    );

    const {
      flags: { invalidUrl, redirectToLogin, loaded },
      token,
      data,
    } = useSignedUrlVerifier(cleanBaseUrl, url, signatureField);

    return (
      <>
        {!loaded && <Loader active />}
        {redirectToLogin && <Navigate to="/" />}
        {invalidUrl && (
          <CenteredMessage>
            Dear user, this URL is invalid or already expired.
          </CenteredMessage>
        )}
        {loaded && !redirectToLogin && !invalidUrl && (
          <Component params={params} data={data} token={token} {...props} />
        )}
      </>
    );
  };
}
