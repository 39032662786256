import {useEffect, useState} from "react";
import {getCity, getState} from "../../../ClientPractice/ClientPractice.service";
import * as services from "../../services";
import {getClientById, getClients, updateClient} from "../../services";
import {displayModalMessage} from "../../../../../util/displayMessage";

export const initialState = {
  "address": "",
  "db": "",
  "email": "",
  "formTitle": "",
  "name": "",
  "phone": "",
  "templateId": "",
  "planTemplateId": "",
  "apiKey": "",
  "hUser": "",
  "secret": "",
  "consumer": "",
  "state": "",
  "city": "",
  "zipCode": ""
};
const useClientsForm = (clientSelect, editing, setShowModalEdit, setShowModal, stateSelect, refreshData) => {
  const [data, setData] = useState(initialState);
  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(!editing);

  const loadCity = async (stateId) => {
    if (stateId) {
      let response = await getCity(stateId);
      response = response[1]['data']['value'].map(item => ({...item, value: item.id, label: item.name}))
      setCity(response)
    }
  }

  useEffect(async () => {
      if (clientSelect) {
        const [dataClient, dataCity] = await Promise.all([
          getClientById(clientSelect),
          loadCity(stateSelect),
        ]);
        console.log("dataClient")
        console.log(dataClient[1])
        setData(dataClient[1])
        setLoading(true)
      }
    }, [clientSelect]
  )

  const handleSubmit = (data) => {
    if (editing) {
      updateClient(data, clientSelect).then(([status, r]) => {
        if (status) {
          displayModalMessage(
            "Client Edited Successfully",
            <span>Client Edited</span>,
            "success",
            () => {
              refreshData()
              setShowModalEdit(false)
            }
          );
        }
      })

    } else {
      services.createClient(data).then(([status, r]) => {
        if (status) {
          displayModalMessage(
            "Practice Saved Successfully",
            <span>Practice Saved</span>,
            "success",
            () => {
              setShowModal(false)
              refreshData()
            }
          );

        }
      })
    }

    // services.createClient(data).then(r => console.log("datos Guardados"));

  }
  return {data, setData, loadCity, city, setCity, handleSubmit, loading}
}

export default useClientsForm
