import moment from "moment";
import styled from "styled-components";

const DetailsWrapper = styled.div`
padding: 0 1rem;
`

const DetailsItem = styled.div`
    border-bottom: 1px solid #ccc;
    padding: .625rem;
    &:last-child {
        border-bottom: none;
    }
`
const DetailsLabel = styled.span`
  font-weight: 500;
    color: #000;
    margin-right: .625rem;
`

const IntelepeerDetails = ({intelepeer}) => {
  if (!intelepeer) return null;

  return (
    <DetailsWrapper>
      <DetailsItem>
        <DetailsLabel>
          Patient Address:
        </DetailsLabel>
        {intelepeer.patientAddress}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Subscriber Name:
        </DetailsLabel>
        {`${intelepeer.subscriberName}`}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Subscriber DOB:
        </DetailsLabel>
        {moment(intelepeer.subscriberDob).format("YYYY-MM-DD")}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Subscriber ID:
        </DetailsLabel>
        {intelepeer.subscriberId}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Subscriber Address:
        </DetailsLabel>
        {intelepeer.subscribeAddress}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Customer Phone:
        </DetailsLabel>
        {intelepeer.customerPhone}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Policy Number:
        </DetailsLabel>
        {intelepeer.policyNumber}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Service Requested:
        </DetailsLabel>
        {intelepeer.serviceRequested}
      </DetailsItem>
      <DetailsItem>
        <DetailsLabel>
          Drop Out Location:
        </DetailsLabel>
        {intelepeer.dropOutLocation}
      </DetailsItem>
    </DetailsWrapper>
  )
}

export default IntelepeerDetails
