import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { Api } from "../../../config/Api";
import { getLoggedInData, updateLoggedInData } from "../../../util/auth";
import { displayModalMessage } from "../../../util/displayMessage";
import { statusAndResponse } from "../../../util/requestHandler";
import { FormInputText } from "../../formControls";
import PhoneNumber from "../../formControls/PhoneNumber";
import { TemplateWrapped } from "../../Layout";
import useEmailReset from "../Login/hooks/useEmailReset";

// Service

export function updateProfile(data) {
  return statusAndResponse(
    Api.post("/api/v2/users/updateProfile", {
      payload: { ...data },
      permission: [{}],
    }),
    (r) => {
      if (r.data.success === false) throw new Error("");
      return r;
    },
  );
}

// Hook

const initialState = {
  firstName: "",
  lastName: "",
  notifyOnLogin: true,
  phone: "",
  originalEmail: "",
  password: "",
  forcePinEmail: false,
};

const initialFlagState = {
  waiting: false,
  updated: false,
  error: false,
  passwordUpdated: false,
};

function useProfile(initialData = {}) {
  const [data, setData] = useState({ ...initialState, ...initialData });
  const [flags, setFlags] = useState(initialFlagState);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const email = useEmailReset();

  function setWaiting(waiting) {
    setFlags((f) => ({ ...f, waiting }));
  }

  // Exposed functions
  function updateProfileData() {
    const { originalEmail, _id, ...savingData } = data;
    if (
      savingData.password !== savingData.confirmPassword &&
      savingData.password.length > 0
    ) {
      setConfirmPasswordError(true);
      return;
    }
    if (savingData.password === "") delete savingData.password;
    delete savingData.confirmPassword;

    setWaiting(true);

    updateProfile(savingData)
      .then(([status, _res]) => {
        // TODO validate x response
        if (status === false) {
          throw new Error("");
        }

        displayModalMessage(
          "Success",
          "Dear user, your data has been updated",
          "success",
          () => {
            const { firstName, lastName, notifyOnLogin } = savingData;

            updateLoggedInData({
              firstName,
              lastName,
              notifyOnLogin,
            });

            const passwordWasUpdate = savingData.password
              ? savingData.password.length > 0
              : false;
            if (passwordWasUpdate) {
              setFlags({ ...initialFlagState, passwordUpdated: true });
            } else {
              setFlags({ ...initialFlagState, updated: true });
            }
          },
        );
      })
      .catch((_e) => {
        setFlags({ ...initialFlagState, error: true });
      });
  }

  function setField(fieldName, value) {
    setData({ ...data, [fieldName]: value });
  }

  function sendEmail() {
    email.setEmail(data.originalEmail);
  }

  return [
    data,
    { ...flags, emailUpdated: email.done && email.success },
    email,
    setField,
    sendEmail,
    updateProfileData,
    confirmPasswordError,
    setConfirmPasswordError,
  ];
}

// Component

export function Presentational({
  data = initialState,
  setData = (name, value) => {
    /**/
  },
  saving = false,
  sendingEmail = false,
  sendEmail = () => {
    /**/
  },
  save = () => {
    /**/
  },
  confirmPasswordError = false,
}) {
  function handleInputChange({ target: { name, value } }) {
    setData(name, value);
  }

  return (
    <div className="p-5 d-flex align-items-center justify-content-center">
      <Container>
        <Row>
          <Col lg={6} className="offset-lg-3 bg-white p-5">
            <h5>General data</h5>
            <hr />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                save();
              }}
            >
              <FormInputText
                pattern=""
                id="firstName"
                label="First Name"
                value={data.firstName}
                onChange={handleInputChange}
                required
              />
              <FormInputText
                pattern=""
                id="lastName"
                label="Last Name"
                value={data.lastName}
                onChange={handleInputChange}
                required
              />
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <PhoneNumber
                  id="phone"
                  className="form-control"
                  value={data.phone}
                  onChange={(value) => {
                    setData("phone", value);
                  }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <FormInputText
                  pattern=""
                  id="password"
                  label="New Password"
                  value={data.password}
                  onChange={handleInputChange}
                  type="password"
                />
              </Form.Group>
              <Form.Group>
                <FormInputText
                  pattern=""
                  id="confirmPassword"
                  label="Confirm New Password"
                  value={data.confirmPassword}
                  onChange={handleInputChange}
                  type="password"
                />
                {confirmPasswordError ? (
                  <span className="text-danger">passwords do not match </span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Row>
                <Form.Group className={"d-inline-block"}>
                  <Form.Label className="mb-0">
                    <Form.Check
                      inline
                      className="mr-0"
                      type="checkbox"
                      checked={data.notifyOnLogin}
                      onChange={({ target: { checked } }) => {
                        setData("notifyOnLogin", checked);
                      }}
                    />
                    Recieve e-mail notifications on every Login
                  </Form.Label>
                </Form.Group>
              </Row>
              <div className="text-center mt-4">
                <Button
                  disabled={saving}
                  type="submit"
                  variant="success btnForm mt-0 mb-3"
                  size="md"
                  block
                >
                  {saving && "Please wait "}
                  {saving && <FontAwesomeIcon icon="spinner" spin />}
                  {!saving && "Save"}
                </Button>
                <button
                  type="button"
                  disabled={sendingEmail}
                  className="btn btn-link"
                  onClick={sendEmail}
                >
                  Reset password{" "}
                  {sendingEmail && <FontAwesomeIcon icon="spinner" spin />}
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default function Profile() {
  const loggedIn = getLoggedInData();
  const {
    firstName,
    lastName,
    name: originalEmail,
    phone,
    notifyOnLogin,
    setting: { forcePinEmail },
  } = getLoggedInData();
  const [
    data,
    flags,
    email,
    setData,
    sendEmail,
    updateProfileData,
    confirmPasswordError,
    setConfirmPasswordError,
  ] = useProfile({
    firstName,
    lastName,
    originalEmail,
    phone,
    notifyOnLogin,
    forcePinEmail,
  });

  useEffect(() => {
    if (flags.emailUpdated) {
      displayModalMessage(
        "Success",
        "Dear user, please check your e-mail",
        "success",
      );
    }
  }, [flags.emailUpdated]);

  useEffect(() => {
    if (flags.error) {
      displayModalMessage("Warning", "Dear user, please try again", "error");
    }
  }, [flags.error]);

  if (!loggedIn) {
    return <Navigate to="/main" />;
  }

  if (flags.passwordUpdated) {
    return <Navigate to="/login" />;
  }

  return (
    <TemplateWrapped title="Profile">
      <Presentational
        data={data}
        setData={setData}
        saving={flags.waiting}
        sendingEmail={email.waiting}
        sendEmail={sendEmail}
        save={updateProfileData}
        confirmPasswordError={confirmPasswordError}
      />
    </TemplateWrapped>
  );
}
