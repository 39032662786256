import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { displayModalConfirmation } from "../../../util/displayMessage";
import CustomModal from "../../CustomModal";
import CustomTable from "../../CustomTable";
import { TemplateWrapped } from "../../Layout";
import { HeaderButton } from "../../RemarkTemplate/Body";
import UserHelp from "../../util/UserHelp";
import BookmarkForm from "./components/BookmarkForm";
import useBookmarks from "./hooks";
import "./styles/index.css";
import {Delete, Edit} from "@material-ui/icons";

export default function Bookmarks() {
  const {
    newEmptyBookmark,
    onDelete,
    setRecord: setModalData,
    dataSource,
    tableReference,
    record: modalData,
    clearRecord: closeModal,
    onSaveModalForm,
  } = useBookmarks();

  return (
    <TemplateWrapped
      title="Bookmarks"
      headerButtons={
        <>
          <HeaderButton
            icon={faPlus}
            message="New Record"
            onClick={newEmptyBookmark}
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              <div className="bookmarks">
                <BookmarkTable
                  deleteRecord={onDelete}
                  setRecord={(r) => {
                    setModalData(r);
                  }}
                  dataSource={dataSource}
                  tableReference={tableReference}
                />
                {modalData && (
                  <CustomModal
                    show
                    title={
                      modalData._id ? "Editing Bookmark" : "Creating Bookmark"
                    }
                    onHide={closeModal}
                  >
                    {modalData.usedBy && modalData.usedBy.length > 0 && (
                      <div className="mb-2">
                        <UserHelp className="mb-1">
                          This bookmark is being used by the following
                          templates:
                        </UserHelp>
                        <ul className="pl-4 m-0">
                          {modalData.usedBy.map((x, i) => (
                            <li key={i}>
                              <small>{x}</small>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <BookmarkForm data={modalData} onSave={onSaveModalForm} />
                  </CustomModal>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
}

function BookmarkIcon({ elementType }) {
  return (
    <div className="bookmark-icon text-white">
      {elementType === "text" && <i className="icon wb-text"></i>}
      {elementType === "checkbox" && (
        <FontAwesomeIcon icon="list-ul"></FontAwesomeIcon>
      )}
    </div>
  );
}

function BookmarkTitle({ label, type }) {
  return (
    <>
      <span className="bookmark-title d-inline-block">{label}</span>{" "}
      <span className="text-muted d-inline-block ml-1">({type})</span>
    </>
  );
}

function BookmarkList({ bookmark, options }) {
  return (
    <ul className="bookmarks">
      {bookmark !== undefined && <li className="active">{bookmark}</li>}
      {options !== undefined &&
        options.length > 0 &&
        options.map((x, i) => (
          <li className="active" key={i}>
            {x.bookmark}
          </li>
        ))}
    </ul>
  );
}

function BookmarkTable({
  setRecord,
  deleteRecord,
  tableReference,
  dataSource,
}) {
  const _columns = [
    {
      render: ({ elementType }) => {
        return <BookmarkIcon elementType={elementType} />;
      },
    },
    {
      render: ({ label, type, bookmark, options }) => {
        return (
          <>
            <BookmarkTitle label={label} type={type} />
            <BookmarkList bookmark={bookmark} options={options} />
          </>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'Bookmarks', render: ({ bookmark, options }) => (
        <BookmarkList
          bookmark={bookmark}
          options={options}
        />
      )
    },
    {
      title: 'Element type', render:({elementType}) => (
      <div className='d-flex align-items-center'>
        <BookmarkIcon elementType={elementType} />
        <span className='ml-1'>{elementType}</span>
      </div>
      )
    },
    {title: 'Label', field: 'label'},
    {title: 'Type', field: 'type'},
    {title: 'Symbol', field: 'symbol'},
  ]
  const actions = [
    {
      icon: () => <Edit color="primary" />,
      tooltip: 'Edit',
      onClick: (event, row) => {
        setRecord(row)
      }
    },
    {
      icon: () => <Delete color="error" />,
      tooltip: 'Delete',
      onClick: (event, row) => {
        displayModalConfirmation(
          'Delete',
          'Dear user, are you sure you want to delete this record?',
          () => {
            deleteRecord(row._id)
          }
        )
      }
    }
  ]

  return (
    <div className="bookmark-table">
      <CustomTable
        tableReference={tableReference}
        asyncDataSource={dataSource}
        columns={columns}
        actions={actions}
        pageSize={25}
      />
    </div>
  );
}
