import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import React, { useContext } from "react";

import UserHelp from "../../../util/UserHelp";
import ServiceIcon from "./ServiceIcon";
import { UserRequestDetailContext } from "..";

const UserRequestDetailPracticeList = ({ practices = [] }) => (
  <div className="practice-list">
    <hr />
    {practices.length > 0 ? (
      practices.map((p) => <Practice key={p.practiceName} {...p} />)
    ) : (
      <UserHelp>This user does not have any practices yet</UserHelp>
    )}
  </div>
);

const Practice = ({ practiceName, orderId, products }) => {
  return (
    <div className="practice">
      <h3>{practiceName}</h3>
      <ProductList products={products} />
      <hr />
    </div>
  );
};

const ProductList = ({ products = [] }) => {
  if (products.length > 0) {
    return (
      <div className="card-group">
        {products.map((s) => (
          <Product key={s._id} {...s} />
        ))}
      </div>
    );
  }
  return <UserHelp>This practice does not have any product yet</UserHelp>;
};

const Product = ({
  _id,
  fileName,
  product,
  date,
  carrier,
  pms,
  remoteConnection,
}) => {
  return (
    <div className="card">
      <div className="card-header-icon">
        <ServiceIcon icon={product} width={50} height={50} color="#333333" />
        <h4 className="card-title">{product}</h4>
      </div>
      <div className="card-block">
        <div className="row">
          <div className="col-md-4">
            <RemoteConnection remoteConnection={remoteConnection} />
          </div>
          <div className="col-md-4">
            <PMS pms={pms} />
          </div>
          <div className="col-md-4">
            <Carriers carrier={carrier} />
          </div>
        </div>
        <Footer productId={_id} date={date} fileName={fileName} />
      </div>
    </div>
  );
};

const RemoteConnection = ({ remoteConnection }) => {
  if (remoteConnection) {
    return (
      <div className="description-block">
        <strong>Remote Connection:</strong>
        <p>
          workStationName: <span>{remoteConnection.workStationName}</span>
        </p>
        <p>
          userName: <span>{remoteConnection.userName}</span>
        </p>

        <p>
          IT Name: <span>{remoteConnection.itName}</span>
        </p>
        <p>
          IT Email: <span>{remoteConnection.itEmail}</span>
        </p>
        <p>
          IT Phone: <span>{remoteConnection.itPhone}</span>
        </p>
      </div>
    );
  }
  return null;
};

const PMS = ({ pms }) => {
  if (pms) {
    return (
      <div className="description-block pms">
        <strong>PMS:</strong>
        <p>
          version: <span>{pms.version}</span>
        </p>
        <p>
          user: <span>{pms.user}</span>
        </p>

        {pms.mySqlUser ? (
          <p>
            mySqlUser: <span>{pms.mySqlUser}</span>
          </p>
        ) : null}
      </div>
    );
  }

  return null;
};

const Carriers = ({ carrier }) => {
  if (carrier && carrier.length > 0)
    return (
      <div className="carriers">
        <strong>Carriers:</strong>
        <ul>
          {carrier.map((c) => (
            <li key={c.name}>{c.name}</li>
          ))}
        </ul>
      </div>
    );
  return null;
};

const Footer = ({ date, fileName, productId }) => {
  const ctx = useContext(UserRequestDetailContext);

  return (
    <div className="mt-2">
      <small className="text-muted adquired">Adquired on {date}</small>
      {fileName ? (
        <div>
          <Button
            variant="link p-0"
            onClick={() => {
              ctx.downloadFile(productId, fileName);
            }}
          >
            <FontAwesomeIcon icon="download" /> {fileName}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default UserRequestDetailPracticeList;
