import { useEffect, useState } from "react";
import * as service from "./services";

export default function useTemplateList() {
  const [data, setData] = useState([]);
  const [displayForm, setDisplayForm] = useState(false);

  async function reloadData() {
    let [status, res] = await service.getList();

    if (displayForm) {
      setDisplayForm(false);
    }

    setData(res);
  }

  useEffect(() => {
    reloadData();
  }, []);

  return { data, displayForm, reloadData, setDisplayForm };
}
