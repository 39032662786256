import styled from "styled-components";
import {ProgressBar} from "react-bootstrap";
import {MdOutlineCancel, MdCheckCircleOutline} from "react-icons/all";
import {STATUS_COLOR} from "../../../../utils/constanst";

const Wrapped = styled.div`
  color: ${({variant}) => STATUS_COLOR[variant]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 1.5rem;
  }
`

const StyledProgressBar = styled.div`
  width: 75%;
  height: 5px;
  margin-top: .18rem;

  .progress {
    height: 4px;
  }

  .progress-bar {
    background-color: ${({variant}) => STATUS_COLOR[variant]};
  }
`

const QuantityLabel = styled.span`
  font-weight: 500;
  font-size: .8em;
`

const MissingFieldsIcon = ({quantity = 100, variant, icon = '', label}) => {
  return(
    <Wrapped variant={variant}>
      {
        icon.length > 0
          ? (<Icon icon={icon} />)
          : (<Quantity label={label} />)
      }
      <StyledProgressBar variant={variant}>
        <ProgressBar now={quantity} />
      </StyledProgressBar>
    </Wrapped>
  )
}

const Icon = ({icon = ''}) => {
  if (icon.length === 0) return null

  return icon === 'check'
    ? <MdCheckCircleOutline size={'1.8rem'} />
  : <MdOutlineCancel size={'1.8rem'} />
}

const Quantity = ({label}) => {
  return <QuantityLabel>{label}</QuantityLabel>
}

export default MissingFieldsIcon
