import { useEffect } from "react";
import { useAsyncTableData } from "../../CustomTable/hooks";
import * as services from "./aircall.services";
import {initialState} from './components/AirCallFilter/useAirCallFilter'

const useAirCall = () => {
  const { dataSource, refreshData, tableReference } = useAsyncTableData(
    services.getList, initialState
  );

  return {
    tableReference,
    dataSource,
    refreshData
  };
};

export default useAirCall;
