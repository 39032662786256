import moment from "moment";
import CustomTable from "../../../../CustomTable";
import useAzureFiles from "../../hooks/useAzureFiles";
import {TemplateWrapped} from "../../../../Layout";
import {HeaderFilterButton} from "../../../../RemarkTemplate/Body";
import AzureFileFilter from "../AzureFileFilter";
import CustomPanel from "../../../../CustomPanel";

const PAGE_SIZE = 10;

const TableFiles = (
  {
    title,
    pageSize = PAGE_SIZE,
    getFiles,
    tableTitle,
    utc = false,
  }) => {

  const {dataSource, refreshData, tableReference} = useAzureFiles(getFiles)

  const columns = [
    {
      title: 'Created',
      field: 'dateCreated',
      render: ({dateCreated}) => {
        const formatDate = utc
          ? moment.utc(new Date(dateCreated)).format('MM/DD/YYYY')
          : moment(new Date(dateCreated)).format('MM/DD/YYYY');

        return <span>{formatDate}</span>
      }
    },
    {
      title: 'Container',
      field: 'container'
    },
    {
      title: 'Folder',
      field: 'folder'
    },
    {
      title: 'File Name',
      field: 'filename'
    },
    {
      title: 'Event',
      field: 'event',
      sorting: false,
      align: 'center',
    },
    {title: 'Lines', field: 'lines'},
    {title: 'Size(MB)', field: 'size'},
  ]

  return(
    <TemplateWrapped
      title={title}
      description="Azure Files"
      headerButtons={
        <HeaderFilterButton
          render={AzureFileFilter}
          componentProps={{
            setFilters: refreshData,
          }}
        />
      }
    >
      <CustomPanel>
        <CustomTable
          search={false}
          title={tableTitle}
          columns={columns}
          asyncDataSource={dataSource}
          tableReference={tableReference}
          pageSize={pageSize}
        />
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default TableFiles;