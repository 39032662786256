import {TemplateWrapped} from "../../../../Layout";
import useDentalMationProduct from "./hooks/useDentalMationProduct";
import DentalMationProductHeader from "./components/DentalMationProductHeader";
import '../../../UserRequestDetail/styles/index.css'
import DentalMationProductInvoice from "./components/DentalMationProductInvoice";
import LogoDM from "../../../../util/LogoDM";

const DentalMationProduct = () => {
  const {
    generalInfo,
    invoiceHistory,
    businessInfo,
    printRef
  } = useDentalMationProduct()

  return (
    <TemplateWrapped
      title='Product Details'
    >
      <div id="user-request-detail">
        <div className="panel">
          <div className="panel-body" ref={printRef}>
            <div className='mb-3'>
              <LogoDM width={200} height={50} />
            </div>
            <DentalMationProductHeader
              generalInfo={generalInfo}
              businessInfo={businessInfo}
            />
            <DentalMationProductInvoice
              invoiceHistory={invoiceHistory}
              description={generalInfo.productName}
            />
          </div>
        </div>
      </div>
    </TemplateWrapped>
  )
}

export default DentalMationProduct
