import { useMemo, useState } from "react";
import { getSuggestedName } from "./TransactionFiles.utils";

const useFileCreator = (data) => {
  const [files, setFiles] = useState([]);
  const [suggestedNameLeft, suggestedNameRight] = useMemo(() => {
    return getSuggestedName(
      data.type,
      data.patientStatus,
      data.patientName,
      data.carrierName
    );
  }, [data]);

  const addFile = (file) => setFiles((f) => [...f, file]);
  const updateFile = (index, newFile) =>
    setFiles((f) =>
      f.map((el, i) => {
        return index !== i ? el : newFile;
      })
    );
  const removeFile = (index) =>
    setFiles((f) => f.filter((x, i) => i !== index));

  const removeAllFiles = () => setFiles([]);

  return {
    files,
    suggestedNameLeft,
    suggestedNameRight,
    addFile,
    updateFile,
    removeFile,
    removeAllFiles
  };
};

export default useFileCreator;
