import React, { useEffect, useState } from "react";
import { HeaderFilterButton } from "../../RemarkTemplate/Body";
import { withoutEmptyKeys } from "../../../util/object";
import AllTransactionsFilter from "../AllTransactions/components/AllTransactionsFilter";
import { TemplateWrapped } from "../../Layout";
import { operations } from "../../../util/operationsForFilters";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";
import useAllTransactions from "../AllTransactions/useAllTransactions";
import useColumnFlag from "../AllTransactions/components/ColumnGrid/ColumnFlag/hooks/useColumnFlag";
import useAllTransactionsTable from "../AllTransactions/components/useAllTransactionsTable";
import TransactionTable from "../../TransactionTable";
import { transactionColorFlagColumn } from "../../../util/transactionColorFlagColumn";
import { transactionsColumnByModule } from "../../../util/transactionsColumnByModule";
import { transactionActionsColumnsByModule } from "../../../util/transactionActionsColumnsByModule";
import CustomModal from "../../CustomModal";
import CallCenterVerification from "./components/call-center-verification/CallCenterVerification";

const CallCenter = ({
  permissions,
  actions = {
    list_transaction: true,
    audit_transaction: true,
    upload_elg: true,
    unaudit: false,
    updateElegibilityStatus: false,
  },
  ...props
}) => {
  const { selectedPractices } = props;
  const MODULE_KEY = "CC";
  const {
    _permissions,
    sortTransaction,
    filters,
    loaded,
    dateFilters,
    ref,
    setFilters,
    handleSetFilters,
    getData,
    refresh,
    key,
    userColumns,
    openModalVerification,
    setShowModal,
    showModal,
    itemVerification,
    setAction,
  } = useAllTransactions(permissions, selectedPractices, MODULE_KEY);
  const { handleIssueButtonClick } = useAllTransactionsTable(ref, "");

  const [filterOperation, setFilterOperation] = useState([]);
  const { flagColors, saveTransactionColor } = useColumnFlag(refresh);
  const { handleVerify, history } = useAllTransactionsTable(ref, "practiceId");

  useEffect(() => {
    // TODO make API call
    setFilterOperation(operations);
  }, []);

  return (
    <>
      <TemplateWrapped
        title="Insurance Verification | Call Center"
        headerButtons={
          <>
            <HeaderFilterButton
              active={
                filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              }
              render={AllTransactionsFilter}
              componentProps={{
                setFilters,
                initialData: filters,
                operations,
              }}
            />
          </>
        }
      >
        <div className="panel">
          <div className="panel-body">
            <div className="row">
              <div className="col-md-12">
                {loaded && (
                  <div className="all-transactions-table">
                    <TransactionTable
                      key={key} // Force a re-render to avoid material's table bug
                      colorFlagColumn={transactionColorFlagColumn(
                        flagColors,
                        saveTransactionColor,
                        _permissions,
                        MODULE_KEY,
                      )}
                      columns={transactionsColumnByModule(MODULE_KEY)}
                      actionsColumns={transactionActionsColumnsByModule(
                        history,
                        actions.unaudit,
                        handleVerify,
                        ref,
                        MODULE_KEY,
                        openModalVerification,
                        handleIssueButtonClick,
                        "practiceId",
                      )}
                      tableReference={ref}
                      asyncDataSource={getData}
                      filters={filters}
                      currentDate={dateFilters.currentDate}
                      sortTransaction={sortTransaction}
                      range={dateFilters.dateRange}
                      handleSetFilters={handleSetFilters}
                      filterOperation={filterOperation}
                      visibleColumns={userColumns}
                      actions={actions}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </TemplateWrapped>
      {showModal && (
        <CustomModal
          size="xl"
          title="Verification Call Center"
          onHide={() => {
            setShowModal(false);
          }}
          show={showModal}
        >
          <CallCenterVerification
            itemVerification={itemVerification}
            permissions={permissions}
            setAction={setAction}
          />
        </CustomModal>
      )}
    </>
  );
};

export default withSelectedPractices(CallCenter);
