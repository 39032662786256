import { Api, decryptApiResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";
import * as file from "../../../util/file";

//Services
export async function doAuthorize(requestId) {
  return statusAndResponse(
    Api.put("/api/userRequest/accept/" + requestId),
    decryptApiResponse
  );
}
export async function doNotAuthorize(requestId) {
  return statusAndResponse(
    Api.put("/api/userRequest/reject/" + requestId),
    decryptApiResponse
  );
}

export const getRequests = async (params) => {
  return statusAndResponse(
    Api.get("/api/onboarding/request", { params }),
    (r) => {
      return decryptApiResponse(r).then((x) => {
        return {
          records: x.records,
          recordsQty: x.total,
        };
      });
    }
  );
};

export const getRequestById = async (requestId) => {
  return statusAndResponse(
    Api.get(`/api/onboarding/order/detail/${requestId}`),
    decryptApiResponse
  );
};

export const downloadFile = async (orderId, productItemId, filename) => {
  Api.post(
    "/api/onboarding/order/downloadFile",
    {
      orderId,
      productItemId,
      filename,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  ).then((r) => {
    file.download(r.data, filename);
  });
};
