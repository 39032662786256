import { useEffect, useState } from "react";
import { emailValidation } from "../../../formControls/InputText";
import * as service from "../services";

const initialState = {
  email: "",
  error: "",
  done: false,
  waiting: false,
  success: false,
};

export default function useEmailReset() {
  const [state, setState] = useState(initialState);

  // Whenever the email changes
  useEffect(() => {
    const { email } = state;

    // Validations
    if (!email) {
      return;
    }

    if (!email.match(emailValidation)) {
      setState((f) => ({
        ...initialState,
        error: "Please provide a valid e-mails",
        done: true,
      }));
      return;
    }

    // Denotate is being processed
    setState((f) => ({
      ...initialState,
      waiting: true,
    }));

    // Execute the service
    service
      .passwordReset(email)
      .then(([status, r]) => {
        if (!status) {
          throw "";
        }
        setState((f) => ({
          ...initialState,
          success: true,
          done: true,
        }));
      })
      .catch((r) => {
        setState((f) => ({ ...initialState, done: true }));
      });
  }, [state]);

  return {
    ...state,
    setEmail: (email) => {
      setState((s) => ({ ...s, email }));
    },
  };
}
