import * as moment from "moment";

export const clearString = (str) => {
  return (str || "").replace(/[^a-zA-Z0-9\s']/g, "").trim();
};

export const getGroupName = (elements) => {
  let name = ''

  if (elements["content"] && elements["content"].length > 0) {
    const tempGroupName = elements["content"][0].items.filter(
      ({ bookmark }) => bookmark === "GroupID"
    );

    if (tempGroupName.length > 0) {
      name = tempGroupName[0]["value"];
    }
  }

  return name
}

export const processName = ({spreedsheet = {}}) => {
  let name = createFileName(spreedsheet);
  const {driveFiles = []} = spreedsheet
  const fbdFiles = driveFiles.filter(({type = ''}) => type.toLowerCase() === 'fbd')

  if (fbdFiles.length > 0) {
    name = fbdFiles[0]?.filename || createFileName(spreedsheet);
  }

  return name
}

const createFileName = spreadsheet => {
  return `Insurance_verification_form_${clearString(
    spreadsheet.CarrierName
  )}_Primary_${clearString(
    spreadsheet.PatientFirstName
  )} ${clearString(spreadsheet.PatientLastName)}_${moment(
    new Date()
  ).format("MMDDyyyy")}.docx`;
}
