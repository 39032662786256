import React from "react";
import CustomTabPanel from "../../CustomTabPanel/index";
import useClientPermissions from "./useClientPermissions";
import { ThreeStateCheckbox } from "../../formControls/index";
import { TemplateWrapped } from "../../Layout";
import CustomTreeView from "../../CustomTreeView";
import "./styles.css";

const element = ({ handleClickHeaderElement }, data) => {
  return (
    <ThreeStateCheckbox
      label={data.displayName}
      onChange={handleClickHeaderElement}
      value={data._id}
      checked={data.checked}
    />
  );
};

const treeView = (data, { headerTreeView, handleClickHeaderElement }) => {
  return (
    <CustomTreeView
      data={data}
      headerElement={headerTreeView}
      handleClickHeaderElement={handleClickHeaderElement}
      recordId="_id"
    />
  );
};

const ClientPermissions = () => {
  const { data, getPermissionByClient, setUpdatePermissionByClient } =
    useClientPermissions();

  const handleClickTab = (e, tab) => {
    getPermissionByClient(tab);
  };

  const handleClickCheckbox = (e) => {
    e.stopPropagation();
    const self = e.target;

    setUpdatePermissionByClient(e.target.value, e.target.checked).then(
      (rst) => {
        if (rst === null) {
          self.checked = false;
          self.indeterminate = true;
        } else {
          self.indeterminate = false;
          self.checked = rst;
        }
      }
    );
  };

  return (
    <TemplateWrapped title="Client Permissions">
      <div className="panel">
        <div className="panel-body">
          {data.length > 0 && (
            <CustomTabPanel
              data={data}
              renderContent={treeView}
              headerTreeView={element}
              recordId="_id"
              defaultActive={data[0]._id}
              contentData="permissions"
              handleClickTab={handleClickTab}
              handleClickHeaderElement={handleClickCheckbox}
            />
          )}
        </div>
      </div>
    </TemplateWrapped>
  );
};

export default ClientPermissions;
