import { ChecksList, SubsectionTitle } from "../components/formControls";
import { Api, decryptApiResponse } from "../config/Api";
import { decryptData } from "../config/encryptData";
import { statusAndResponse } from "../util/requestHandler";

export const updateTransactionNotes = async (transactionId, notesData) => {
  return statusAndResponse(
    Api.put("/api/iv/transaction/spreadsheet", {
      transactionId,
      ...notesData,
    }),
  );
};

export const updateNotes = async (transactionId, note) => {
  return statusAndResponse(
    Api.post("/api/iv/transaction/note/", {
      transactionId,
      note: note.replace(/\n/gi, "-"),
    }),
    (r) => {
      return r;
    },
  );
};

export const updateSpreadsheet = (data) => {
  const { _id, ...sendData } = data;
  const onSuccess = (d) => {
    if (d.data && d.data.success === false) throw new Error("");

    return d;
  };
  return statusAndResponse(
    Api.put("/api/transaction/spreadsheet/" + _id, sendData),
    onSuccess,
  );
};

export const getSpreadsheet = (transactionId, _clientId) => {
  const onSuccess = (resp) => {
    return decryptApiResponse(resp);
  };
  return statusAndResponse(
    Api.get("/api/transaction/spreadsheet/" + transactionId),
    onSuccess,
  );
};

export const getTransaction = (transactionId, practiceId) => {
  return statusAndResponse(
    Api.get(`/api/iv/transaction/${transactionId}`, { params: { practiceId } }),
    decryptApiResponse,
  );
};

export const getDataMerge = (transactionId) => {
  const onSuccess = (resp) => {
    return decryptApiResponse(resp);
  };
  return statusAndResponse(
    Api.post("/api/tmp/merge", {
      dataId: transactionId,
    }),
    onSuccess,
  );
};

export const setDocumentGeneration = (transactionId, status) => {
  return statusAndResponse(
    Api.put("/api/trans/fbd/canUpload", {
      transactionId,
      status: status.toString(),
    }),
  );
};

export const save = (data, transactId, user) => {
  let dataToStore = {};
  for (let item of data) {
    Object.assign(dataToStore, prepareResponse(item));
  }

  dataToStore["user"] = user;
  return statusAndResponse(
    Api.put("api/trans/" + transactId + "/missingCode", {
      bookmarks: dataToStore,
    }),
    (resp) => {
      return resp.data.success;
    },
  );
};

export const downloadRealTimeFile = (file) => {
  return new Promise((resolve, reject) => {
    Api.defaults.headers.get["Content-Type"] = "application/json";
    try {
      Api.get(`/api/realtimeiv/getFile?file=${file}`, {
        responseType: "blob",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const download = (transactionId) => {
  return new Promise((resolve, reject) => {
    Api.defaults.headers.get["Content-Type"] = "application/json";
    try {
      Api.get("api/tmp/data/download/" + transactionId, {
        responseType: "blob",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const prepareResponse = (data) => {
  if (data.props.options) {
    let options = {};
    for (const opt of data.props.options) {
      if (data.control !== ChecksList) {
        Object.assign(options, {
          [opt.bookmark]: opt.bookmark === data.props.value ? "n" : "o",
        });
      } else {
        Object.assign(options, { [opt.bookmark]: opt.value });
      }
    }
    return options;
  }
  return (
    data.control !== SubsectionTitle && {
      [data.props.id]: data.props.value ? data.props.value : "-",
    }
  );
};

export const driveUpload = (transactionId) => {
  return new Promise((resolve, reject) => {
    try {
      Api.post("api/tmp/drive/upload", {
        dataId: transactionId,
      })
        .then((resp) => {
          if (resp.data?.success) {
            const json = JSON.parse(resp.data?.value);
            decryptData(json)
              .then((decryptedData) => {
                resolve(decryptedData);
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            throw resp.data;
          }
        })
        .catch((err) => {
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export async function verifyTransaction(rowData, practiceId, state) {
  function onSuccess(r) {
    if (!r.data.success) throw new Error("");
    return r;
  }

  const { _id: transactionId, type } = rowData;

  if (type === "ELG") {
    return statusAndResponse(
      Api.put(`/api/iv/transaction/audit/elg`, {
        transactionId,
        practiceId,
        state,
      }),
      onSuccess,
    );
  }

  return statusAndResponse(
    Api.put(`/api/iv/transaction/audit/fbd`, {
      transactionId,
      practiceId,
      state,
    }),
    onSuccess,
  );
}

export async function uploadFormat(formData) {
  function onSuccess(r) {
    if (!r.data || r.data.success === false) {
      throw new Error("");
    }
    return r;
  }

  return statusAndResponse(
    Api.post("/api/trans/elg/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
    onSuccess,
  );
}

export async function uploadFormatFromCallCenter(formData) {
  function onSuccess(r) {
    if (!r.data || r.data.success === false) {
      throw new Error("");
    }
    return r;
  }

  return statusAndResponse(
    Api.post("/api/airCall/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
    onSuccess,
  );
}

export async function getPracticeInfoAndBookmarks(practiceId) {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
    /*  if (d.data && d.data.success === false) throw new Error("");

    return d;*/
  };

  return statusAndResponse(
    Api.get(`/api/v2/iv/calls/bookmarks/${practiceId}`),
    onSuccess,
  );
}

export const checkFiles = (clientId, transactionId) => {
  return statusAndResponse(
    Api.get(`/api/iv/transaction/filecheck/${clientId}/${transactionId}`),
    decryptApiResponse,
  );
};

export async function updateFile(formData) {
  return statusAndResponse(
    Api.post("/api/trans/elg/file/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  );
}

export async function downloadFile(transactionId, fileId) {
  return Api.post(
    "/api/trans/elg/download",
    {
      transactionId,
      fileId,
    },
    {
      headers: {
        "content-type": "application/json",
      },
      responseType: "blob",
    },
  );
}

export async function deleteFile(transactionId, fileId) {
  return statusAndResponse(
    Api.post("/api/trans/elg/file/delete", {
      fileId,
      transactionId,
    }),
  );
}

export const refreshSpreadsheet = (transactionId, practiceId, clientId) => {
  return statusAndResponse(
    Api.put(`/api/iv/transaction/spreadsheet/empty`, {
      transactionId: transactionId,
      practiceId: practiceId,
      clientId: clientId,
    }),
    decryptApiResponse,
  );
};
export async function verifyFiles(transactionId, practiceId) {
  return statusAndResponse(
    Api.put("api/iv/transaction/files/clean", {
      // requires clientId, but is being injected through app
      transactionId,
      practiceId,
    }),
  );
}

export function getLinkedTransactions(transactionId) {
  return statusAndResponse(
    Api.get(`/api/transaction/linked?transactionId=${transactionId}`),
    decryptApiResponse,
  );
}

export function getSharedElementsByTemplateId() {
  return statusAndResponse(
    Api.get(`/api/template/sharedElement`),
    decryptApiResponse,
  );
}

export function savedTransactionWithSharedElements(data, transactionId, _user) {
  return statusAndResponse(
    Api.put(`api/trans/${transactionId}/missingCode`, {
      bookmarks: data,
    }),
    decryptApiResponse,
  );
}

export function addCommentToTransaction(comment, clientId, transactionId) {
  return statusAndResponse(
    Api.put(`/api/iv/transaction/comment/${clientId}/${transactionId}`, {
      comment,
    }),
    decryptApiResponse,
  );
}
