import {HeaderButton, HeaderFilterButton} from "../../RemarkTemplate/Body";
import {faPlus} from "@fortawesome/fontawesome-free-solid";
import React from "react";
import {TemplateWrapped} from "../../Layout";
import TransactionTable from "./Component/TransactionTable";
import useTransactionForm from "./useTransactionForm";
import CustomModal from "../../CustomModal";
import TransactionModalForm from "./TransactionModalForm";
import TransactionFormFilter from "./TransactionFormFilter";
import {Row} from "react-bootstrap";
import {Presentational as Connections} from "../Connections";

const TransactionForm = () => {
  const {
    showModalNew,
    setShowModalNew,
    dataSource,
    tableReference,
    refreshData,
    showModalEdit,
    setShowModalEdit,
    transactionSelect,
    setTransactionSelect,
    edit,
    setEdit,
    practiceIds
  } = useTransactionForm()
  return (
    <>
      <TemplateWrapped
        title="Transaction"
        headerButtons={
          <>
            {/*<HeaderFilterButton*/}
            {/*  active={true}*/}
            {/*   render={TransactionFormFilter}*/}
            {/*  componentProps={{*/}
            {/*    setFilters: refreshData,*/}
            {/*    // setClientSelectId: setClientSelectId,*/}
            {/*    // clients: dataClients*/}
            {/*  }}*/}
            {/*/>*/}

            {/*<HeaderButton*/}
            {/*  icon={faPlus}*/}
            {/*  message="Add a new Transaction"*/}
            {/*  onClick={() => {*/}
            {/*    setShowModalNew(true)*/}
            {/*  }}*/}
            {/*/>*/}
          </>
        }
      >
        {practiceIds.length > 0 ? (
            <>
              <TransactionTable
                dataSource={dataSource}
                tableReference={tableReference}
                refreshData={refreshData}
                setTransactionSelect={setTransactionSelect}
                setShowModalEdit={setShowModalEdit}
              ></TransactionTable>

              <CustomModal
                show={showModalNew}
                onHide={() => setShowModalNew(false)}
                title="Create Transaction "
                size="lg">
                <TransactionModalForm
                  edit={false}
                  setModal={setShowModalNew}
                  refreshData={refreshData}
                />
              </CustomModal>

              <CustomModal
                show={showModalEdit}
                onHide={() => setShowModalEdit(false)}
                title="Edit Transaction "
                size="lg">
                <TransactionModalForm
                  edit={true}
                  transactionSelect={transactionSelect}
                  setModal={setShowModalEdit}
                  refreshData={refreshData}
                />
              </CustomModal>
            </>)
          :
          <div className="mb-5 pb-5">
            <Connections title="Please select a connection"/>
          </div>
        }


      </TemplateWrapped>
    </>
  )
}

export default TransactionForm;