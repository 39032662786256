import {useEffect, useState} from "react";
import * as credentialListServices from './../services/credentials.services'

const useCredentialsList = () => {
  const [credentials, setCredentials] = useState([]);

  useEffect(async () => {
    const [status, res] = await credentialListServices.getAllCredentials()
    if (status) {
      setCredentials(res)
    }
  }, [])

  return {
    credentials
  }
}

export default useCredentialsList