import React from "react";
import fakeData from "./data";
import _ from "lodash";
import CustomTreeView from "../../CustomTreeView";
import { InputCheckbox } from "../../formControls/index";

const groupByParentId = (array, parent, tree) => {
  tree = typeof tree !== "undefined" ? tree : [];
  parent = typeof parent !== "undefined" ? parent : { _id: null };

  const children = _.filter(array, (child) => child.parentId === parent._id);

  if (!_.isEmpty(children)) {
    if (parent._id === null) {
      tree = children;
    } else {
      parent["items"] = children;
    }

    _.each(children, (child) => groupByParentId(array, child));
  }

  console.log(tree);
  return tree;
};

function element(label, handleClick) {
  return <InputCheckbox label={label} handleClick={handleClick} />;
}

function handleClickCheckbox(e) {
  e.stopPropagation();
  console.log("click", e);
}

const Tree = () => {
  return (
    <CustomTreeView
      data={groupByParentId(fakeData)}
      headerElement={element}
      handleClickHeaderElement={handleClickCheckbox}
    />
  );
};

export default Tree;
