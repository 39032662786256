import { useState } from "react";
import { getUrlParams } from "../../../../util/browser";
import { getDateAsString, getInitialAndEndDate } from "../../../../util/date";

// Hooks
export const initialState = {
  patientName: "",
  status: "",
  startDate: "",
  endDate: "",
  fieldFilter: "",
};
const urlKeys = Object.keys(initialState);

const useDataQualityFilter = (setFilters) => {
  // Component's
  const [data, _setData] = useState({
    ...initialState,
    ...getUrlParams(urlKeys),
  });

  function setData(name, value) {
    if (name === "startDate") {
      const [startDate, endDate] = getInitialAndEndDate(value, data.endDate);
      _setData({
        ...data,
        startDate: getDateAsString(startDate),
        endDate: getDateAsString(endDate),
      });
      return;
    }

    if (name === "endDate") {
      const [startDate, endDate] = getInitialAndEndDate(data.startDate, value);
      _setData({
        ...data,
        startDate: getDateAsString(startDate),
        endDate: getDateAsString(endDate),
      });
      return;
    }

    _setData({ ...data, [name]: value });
  }

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilters(data);
  };

  const handleClear = () => {
    _setData(initialState);
    setFilters(initialState);
  };

  return {
    data,
    setData,
    handleFilterSubmit,
    handleClear,
  };
};

export default useDataQualityFilter;
