import {findByIdCatalog} from "../../../Permissions/permissions.utils";
import _ from "loadsh";

export function formatPermissions(data) {
  // console.log(JSON.stringify(data, null, 2));

  const _data = !data
    ? []
    : data[0].permission.map((x) => {
      const {actions, ...original} = x;

      return {
        id: x._id,
        label: x.displayName,
        path: x.name,
        pathName: x.displayName,
        original: {
          ...original,
          actions: [],
        },
        items: x.actions ? x.actions.map((y) => {
          const {actions, ...original} = y;
          return {
            id: y._id,
            label: y.displayName,
            path: x.name,
            pathName: x.displayName,
            original,
          };
        }) : [],
      };
    });

  let fD = _data.reduce(
    (a, i) => {
      if (i.items.length === 0) {
        a[0]["items"].push(i);
        return a;
      }
      return [...a, i];
    },
    [
      {
        id: "general",
        label: "General Permission",
        original: {
          _id: "general",
          actions: [],
        },
        items: [],
      },
    ]
  );

  if (fD[0]["items"].length === 0) {
    fD = fD.slice(1);
  }

  return fD;
}

export function formatPractices(p) {
  return p.map((r) => {
    return {
      id: r._id,
      label: r.name,
      items: r.practices.map((_ri) => ({ id: _ri._id, label: _ri.name })),
    };
  });
}

export function getDefaultPath(applicationPaths, permission) {
  return Object.entries(applicationPaths).reduce((a, [key, value]) => {
    if (permission.length > 0 && permission[0]["defaultPath"] === value) {
      a = key;
    }
    return a;
  }, "");
}

export function getOriginalPermissionsFromFormat(
  currentPermisions,
  permissions
) {
  // TODO {frank} mala practica, limpiar (generar desde arriba)
  let res = currentPermisions.reduce((a, item) => {
    const {original} = item;

    const parent = permissions.filter(
      (x) => x.items.filter((y) => y === item).length > 0
    );

    if (parent.length > 0) {
      let p = parent[0]["original"];
      if (a.indexOf(p) === -1) {
        a.push(p);
      }
      p.actions.push(original);
    }

    return a;
  }, []);

  if (res.filter((x) => x._id === "general").length > 0) {
    return [
      ...res.filter((x) => x._id === "general")[0]["actions"],
      ...res.filter((x) => x._id !== "general"),
    ];
  }

  return res;
}

export function mergeCatalogsAndPermission(catalogs, permissions) {
  return permissions.map(permission => {
    const find = findByIdCatalog(permission.systemCatalogId, catalogs)
    let type = ''
    if (find) {
      type = find.type
    }
    return {...permission, type}
  })
}

export function excludeBasePermissions(permissions, clientPermissions) {
  return permissions.filter(permission => {
    return clientPermissions.filter(clientPermission => {
      return clientPermission.systemCatalogId === permission.systemCatalogId
    }).length === 0
  })
}

export function processPermissions(permissions) {
  return permissions.map(permission => {
    const {code} = permission
    const codeSplit = code.split('-')

    return {
      ...permission,
      parentCode: codeSplit.length === 1 ?
        null :
        codeSplit.filter((item, index) => index < codeSplit.length - 1).join('-'),
    }
  })
}

export function groupByParentCode(array, parent, tree){
  tree = typeof tree !== 'undefined' ? tree : []
  parent = typeof parent !== 'undefined' ? parent : {code: null}

  const children = _.filter(array, (child) => child.parentCode === parent.code)

  parent['items'] = []

  if (!_.isEmpty(children)) {
    if (parent.code === null) {
      tree = children
    } else {
      parent['items'] = children;
    }

    _.each(children, (child) => groupByParentCode(array, child))
  }

  return tree
}

export function mergeCurrentPermissionsWithNewPermissions(permissionId, items, currentPermisions) {
  const flat = _.flattenDeep(getPermissionIdFromArray(items))
  return _.uniq([...flat, permissionId, ...currentPermisions])
}

export function getPermissionIdFromArray(array) {
  return array.map(item => {
    if (item.items.length > 0) {
      return [...getPermissionIdFromArray(item.items), item.permissionId]
    } else {
      return item.permissionId
    }
  })
}

export function flattenPermissions(permissions){
  return _.flattenDeep(
    getPermissionIdFromArray(permissions)
  )
}

export function getDefaultPathsFromClientPermissions(clientPermissions) {
  return clientPermissions
    .filter((item) => item.showInMenu)
    .map((item) => {
      return {
        name: item.displayName,
        path: item.url,
        permissionId: item.permissionId,
      }
    })
}
