import { useContext } from "react";
import { AppContext } from "../App";
import { getClientFromPracticesSelected } from "../util/practices";

const usePractices = () => {
  const appContext = useContext(AppContext)
  const selectedPractices = appContext.get('selectedPractices')
  const allPractices = appContext.get('practices')
  const firstSelected = selectedPractices.length > 0 ? selectedPractices[0] : null

  const getSelectedPractice = (practiceId) => {
    const _practice = allPractices.filter(practice => {
      return practice._id === practiceId
    })

    return _practice.length ? _practice[0] : null
  }

  const getClientId = (practiceId) => {
    const {clientId} = getClientFromPracticesSelected(practiceId, allPractices)

    return clientId
  }

  const getClientFromFirsPracticeSelected = () => {
    return getClientId(firstSelected)
  }

  return {
    selectedPractices,
    allPractices,
    firstSelected,
    getSelectedPractice,
    getClientId,
    getClientFromFirsPracticeSelected
  }
}

export default usePractices