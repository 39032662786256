import shortid from "shortid";
import {TemplateWrapped} from "../../Layout";
import CustomPanel from "../../CustomPanel";
import useColumnsConfiguration from "./useColumnsConfiguration";
import CustomTable from "../../CustomTable";
import ColumnState from "./ColumnState";
import {GridText} from "../AllTransactions/components/styles/AllTransactionTable.style";
import '../../../styles/columns-configurations.css'

const ColumnsConfiguration = () => {
  const {data, changeColumnState} = useColumnsConfiguration();

  const columns = [{
    title: "Column Name",
    field: 'name',
    sorting: false,
    render: ({name}) => (<GridText>{name}</GridText>)
  }, {
    title: "Description",
    field: 'description',
    sorting: false,
    render: ({description}) => (<GridText>{description}</GridText>)
  }, {
    title: 'Active',
    field: 'userColumn',
    sorting: false,
    render: ({_id: columnId, userColumn = null, active = false}) =>
      <ColumnState state={active}
                   changeState={() => changeColumnState(active, userColumn, columnId)}/>
  }];

  return (
    <TemplateWrapped
      title='Columns Configuration'
    >
      <CustomPanel>
        <CustomTable
          gridCssClass='columns-configurations'
          key={shortid.generate()}
          columns={columns}
          data={data}
          search={false}
          options={{draggable: false, paging: false}}
          pageSize={10}
        />
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default ColumnsConfiguration
