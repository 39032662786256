import {getSummaryReport} from "./SummaryReport.services";
import {toast} from "react-toastify";

const useSummaryReport = () => {
  const GetSummaryReport = (values) => {
    getSummaryReport(values).then(([status, fileRes]) => {
      if(!status){
        toast.error("Error downloading the file")
        return
      }
      const url = window.URL.createObjectURL(new Blob([fileRes.data], {type:'application/vnd.ms-excel'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'summary_report.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success(
        'The file has been downloaded successfully'
      );
    });
  }
  return {GetSummaryReport}
}

export default useSummaryReport