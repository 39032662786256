import { useState } from "react";
import { getStatisticsInformation } from "../../../services/analitycs.service";

export const useAnalitycsForPractices = (
  constraintDataToGetStatistics,
  practicesList,
) => {
  const [practiceSelected, setPracticeSelected] = useState("");
  const [practiceActive, setPracticeActive] = useState("");
  const [statictics, setStatictics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function handleSetPracticeSelected(practice) {
    setPracticeSelected(practice);
  }

  async function getAnalitycsInformation() {
    if (practiceActive.length > 0 && practiceSelected === practiceActive)
      return;
    setIsLoading(true);
    setPracticeActive(practiceSelected);
    await getStatisticsInformation(
      constraintDataToGetStatistics(
        [
          practicesList.find((practice) => practice.name === practiceSelected)
            ._id,
        ],
        false,
      ),
    )
      .then((response) => {
        setStatictics(response[1]);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  return {
    handleSetPracticeSelected,
    practiceSelected,
    practiceActive,
    getAnalitycsInformation,
    statictics,
    isLoading,
  };
};
