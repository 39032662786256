import CustomSwitch from "../../CustomSwitch";
import {useState} from "react";

const ColumnState = ({state, changeState}) => {

  const [active] = useState(state);

  return (
    <CustomSwitch onChange={changeState} checked={active}
    />
  )
}

export default ColumnState
