import * as file from "../../../util/file";
import { withoutEmptyKeys } from "../../../util/object";
import * as services from "./billing.services";
import React, {useEffect, useState} from "react";
import {userStatus} from "../../../util/general";
import {TextColumnHighlighted} from "../../CustomTable";

const columns = [
  {
    title:'Appointment date',
    field: 'ApptDate',
  },
  {
    title: 'Practice Name',
    field: 'practice'
  },
  {
    sorting: false,
    title: 'Patient Name',
    field: 'PatientFirstName'
  },
  {
    sorting: false,
    title: 'Patient Last Name',
    field: 'PatientLastName'
  },
  {
    sorting: false,
    title: 'Carrier',
    field: 'CarrierName'
  },
  {
    sorting: false,
    title: 'Type',
    field: 'type',
    render: ({type}) => {
      return(<p className='text-uppercase'>{type}</p>)
    }
  },
  {
    sorting: false,
    title: 'Eligibility Status',
    render: ({StatusPatient}) => {
      const status = userStatus(StatusPatient)
      return <TextColumnHighlighted text={StatusPatient} status={status} asDot />
    }
  }
];

const useBilling = () => {
  const [myFilters, setMyFilters] = useState({})
  const [data, setData] = useState([])


  const dowloadRecords = () => {
    const excelName = `report${myFilters.startDate}-${myFilters.endDate}.xlsx`
    services.downloadData(withoutEmptyKeys(myFilters)).then((r) => {
      file.download(r.data, excelName);
    });
  };

  useEffect( async () => {
    if (Object.keys(myFilters).length > 0){
      const [status ,users] = await services.getRecords(myFilters)
      if (status){
        console.log(users)
        setData(users)
      }
    }
  }, [myFilters])

  return {
    dowloadRecords,
    setMyFilters,
    data,
    myFilters,
    columns
  };
};

export default useBilling;
