import {TemplateWrapped} from "../../Layout";
import CustomPanel from "../../CustomPanel";
import IntelepeerTable from "./IntelepeerTable";
import {HeaderFilterButton} from "../../RemarkTemplate/Body";
import IntelepeerFormFilter from "./IntelepeerFormFilter";
import useIntelepeer from "./useIntelepeer";
import CustomModal from "../../CustomModal";
import IntelepeerDetails from "./IntelepeerDetails";
import React from "react";
import {Button} from "react-bootstrap";

const Intelepeer = () => {
  const {
    ref,
    getData,
    selectedRecord,
    filters,
    recordToBeChanged,
    handleClickDetails,
    handleClickResetFilters,
    handleClickApplyFilters,
    handleOnSetRecordToBeChanged,
    handleOnChangeStatus
  } = useIntelepeer();

  return(
    <TemplateWrapped
      title="Intelepeer"
      headerButtons={
        <HeaderFilterButton
          active={Object.keys(filters).length}
          render={IntelepeerFormFilter}
          componentProps={{
            setFilters: handleClickApplyFilters,
            resetFilters: handleClickResetFilters,
            filters: filters
          }}
        />
      }
    >
      <CustomPanel>
        <IntelepeerTable
          tableRef={ref}
          getData={getData}
          handleClickDetails={handleClickDetails}
          handleOnSetRecordToBeChanged={handleOnSetRecordToBeChanged}
          selectedRecord={selectedRecord}
        />
        <CustomModal
          title="Details"
          show={Object.keys(selectedRecord).length > 0}
          onHide={() => handleClickDetails({})}
          backdrop
        >
          <IntelepeerDetails intelepeer={selectedRecord} />
        </CustomModal>
        <CustomModal
          title="Change status"
          show={Object.keys(recordToBeChanged).length > 0}
          onHide={() => handleOnSetRecordToBeChanged({})}
          footer={<div>
            <Button
              variant="primary"
              onClick={() => handleOnChangeStatus(recordToBeChanged.id, !recordToBeChanged.status)}
            >
              Accept
            </Button>
            <Button
              variant="warning"
              onClick={() => handleOnSetRecordToBeChanged({})}
            >
              Cancel
            </Button>
          </div>}
        >
          Are you sure you want to change the status?
        </CustomModal>
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default Intelepeer;
