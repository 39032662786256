import { React, useRef, useEffect } from "react";

const updateInput = (ref, checked) => {
  const input = ref.current;
  if (input) {
    input.checked = checked;
    input.indeterminate = checked == null;
  }
};

const ThreeStateCheckbox = ({ value, label, checked, onChange }) => {
  const inputRef = useRef(null);
  const checkedRef = useRef(checked);
  useEffect(() => {
    checkedRef.current = checked;
    updateInput(inputRef, checked);
  }, [checked]);

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        ref={inputRef}
        type="checkbox"
        value={value}
        onChange={onChange}
      />
      {label && <label className="form-check-label">{label}</label>}
    </div>
  );
};

export default ThreeStateCheckbox;
