import styled from "styled-components"

const StyledIssueHeader = styled.div`
  padding: 0 0.5rem 0.5rem 0.5rem;
  width: 100%;
  
  &:hover {
    background-color: #f3f7f9;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const IssueLabel = styled.span`
  font-size: 1.2rem;
  //font-weight: bold;
  margin-right: 0.5rem;
`

const IssueTitle = styled(IssueLabel)`
  font-weight: bold;
`

const LabelDate = styled(IssueLabel)`
  font-size: 0.8rem;
`

export const IssueStatus = styled.span`
  //font-size: 1.2rem;
  font-weight: 500;
  margin-right: 0.5rem;
  min-width: 60px;
  //display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:before{
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${({isClosed}) => isClosed ? "#f44336" : "#4caf50"};
    border-radius: 50%;
    margin-left: .8rem;
    margin-right: .5rem;
  }
`

const IssueHeader = ({user, title, date, status, userName, onClick}) => (
  <StyledIssueHeader onClick={onClick}>
    <Wrapper>
      {/*<IssueLabel>{userName} ({user})</IssueLabel>*/}
      <LabelDate>Created: {date}</LabelDate>
    </Wrapper>
    <Wrapper>
      <IssueTitle>{title}</IssueTitle>
      <IssueStatus isClosed={status === 'closed'}>{status}</IssueStatus>
    </Wrapper>
  </StyledIssueHeader>
)

export default IssueHeader;