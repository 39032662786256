import './../../../style/credential.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomPopover from "../../../../CustomPopover";
import OptionsPopover from "../../../../CustomPopover/components/OptionsPopover";
import React, {useState} from "react";
import CustomModal from "../../../../CustomModal";
import NewCredential from "../../../NewCredential";
import {displayModalConfirmation} from "../../../../../util/displayMessage";

const CredentialInfo = ({credential, handleEdit, deleteCredential}) => {
  const [showForm, setShowForm] = useState(false)
  const {label, options} = credential

  const transFormCredential = () => {
    return {
      label: credential.label,
      paramLists: credential.options.map(option=>{
        const {id, ...props} = option
        const keyObj = Object.keys(props)[0]
        return {
          key: keyObj,
          value: props[keyObj]
        }
      })
    }
  }

  return (
    <div className=' credential'>
      <div className='d-flex justify-content-between'>
        <h4>{label}</h4>
        <CustomPopover>

            <OptionsPopover
              onClick={() => setShowForm(!showForm)}
            >
              <FontAwesomeIcon className='mr-2' icon='edit' />Edit
            </OptionsPopover>
            <OptionsPopover
              cssclass="text-danger"
              onClick={() => displayModalConfirmation('Delete', <span>Dear user, Are you sure you want to delete the credential?</span>,()=>{
                deleteCredential(credential.id)
              })}
            >
              <FontAwesomeIcon className='mr-2' icon='trash' />Delete
            </OptionsPopover>

        </CustomPopover>
      </div>
      {options.map(option => {
        const {id, ...keyValue} = option
        const s = Object.keys(keyValue)[0]
        return (
          <Credential
            key={id}
            id={id}
            objKey={s}
            value={keyValue[s]}
          />
        )
      })}
      {showForm && (
        <CustomModal
          show={showForm}
          onHide={() => setShowForm(!showForm)}
          title='Edit Credential'
        >
          <NewCredential
            initial={transFormCredential()}
            onSubmit={(v) => {
              setShowForm(!showForm)
              handleEdit(v, credential.id)
            }}
          />
        </CustomModal>
      )}
    </div>
  )
}

const Credential = ({id, objKey, value}) => {
  return (
    <div className='d-flex justify-content-start'>
      <label
        className='text-capitalize text-bold mr-3 mb-0'
        htmlFor={id}
      >
        {objKey}:
      </label>
      <span>{value}</span>
    </div>
  )
}

export default CredentialInfo