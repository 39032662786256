import {Api, decryptApiResponse, fakeResponse} from "../../../../config/Api";
import {statusAndResponse} from "../../../../util/requestHandler";

const subscriptionTest = {
  "_id": "615f08f93215ad6f4d18d392",
  "status": "past_due",
  "currency": "usd",
  "lastPaymentDate": "2021-10-20T14:17:32.000Z",
  "invoiceHistory": [
    {
      "_id": "6170347ba10f2723015a156e",
      "currency": "usd",
      "amountDue": 1000,
      "amountPaid": 40,
      "billingReason": "subscription_cycle",
      "description": "1 × New Patient (at $10.00 / day)",
      "status": "open",
      "finalizedAt": "2021-10-14T16:24:56.000Z",
      "created": "2021-10-14T14:51:02.000Z"
    },
    {
      "_id": "61703fcaa10f2723015a1587",
      "currency": "usd",
      "amountDue": 1000,
      "amountPaid": 0,
      "billingReason": "subscription_cycle",
      "description": "1 × New Patient (at $10.00 / day)",
      "status": "open",
      "finalizedAt": "2021-10-20T16:10:44.000Z",
      "created": "2021-10-20T14:49:39.000Z"
    },
    {
      "_id": "617093d2c42c3091a6a0040a",
      "currency": "usd",
      "amountDue": 1000,
      "amountPaid": 0,
      "billingReason": "subscription_cycle",
      "description": "1 × New Patient (at $10.00 / day)",
      "status": "open",
      "finalizedAt": "2021-10-15T16:08:02.000Z",
      "created": "2021-10-15T14:51:07.000Z"
    },
    {
      "_id": "6176d324c5a5d46d041120e8",
      "currency": "usd",
      "amountDue": 1000,
      "amountPaid": 0,
      "billingReason": "subscription_cycle",
      "description": "1 × New Patient (at $10.00 / day)",
      "status": "open",
      "finalizedAt": "2021-10-25T15:53:08.000Z",
      "created": "2021-10-25T14:50:13.000Z"
    }
  ],
  "user": {
    "name": "Reynaldo Cano",
    "phone": "50557172373",
    "email": "roninjosuecano88@gmail.com"
  },
  "practice": {
    "name": "Practice Name One",
    "address": "Managua"
  },
  "product": {
    "productName": "New Patient",
    "priceSetup": 100,
    "suscriptionPrice": 50,
    "infoProduct": "<p>A patient and a doctor greeting each other are the first step in them building a personal connection. Even when it comes to healthcare, we shouldn’t ignore the power of the human touch. A strong welcome can soothe patients and vastly improve the overall dental care experience. Entering a medical office is daunting for many. In these cases, medical office assistants can help with a personal touch, reassuring patients that their individual wellness is of the utmost importance.</p><p>However, is this process taking longer than expected? Not anymore! Use our robots to automate routing, onboarding, data gathering, and notifications while getting new patients. With our bot; Each time the clinic staff enters a new patient in the PMS, the bot will detect it.</p>",
    "infoPrice": "This fee covers up the initial setup and troubleshooting of any issues that might arise during the installation process. Guarantees you receive priority support with our team. This fee is only paid once, per practice."
  }
}

export function getAllSubscriptions(params) {
  return statusAndResponse(
    Api.get('/api/stripe/subscription/all', {params}),
    async (res) => {
      const c = await decryptApiResponse(res)
      return {
        'records': c.records.map(record => {
          return {
            '_id': record._id,
            'clientName': record.name,
            'clinics': record.practicesObj.map(practice => {
              return {
                'clinicName': practice.name,
                'products': practice.subscriptionObj.map(subs=>{
                  return {
                    '_id': subs._id,
                    'productName': subs.productObj.productName,
                    'adquiredDate': subs.created,
                    'status': subs.status,
                    'lastPayment': subs.lastPaymentDate
                  }
                })
              }
            })
          }
        }),
        'recordsQty': c.recordsQty
      }
    }
  )
}

//stripe/subscription/invoice/{id}
export function getFakeSubscriptionInfo(id){
  return statusAndResponse(
    fakeResponse(subscriptionTest, false, 300),
    res => res.data.value
  )
}

export function getSubscriptionInfo(id){
  return statusAndResponse(
    Api.get(`/api/stripe/subscription/invoice/${id}`),
    decryptApiResponse
  )
}