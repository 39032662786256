import React from "react";
import { UpdateButton } from "../TransactionDetailsControls";
import ElegibilityStatusPicker from "./ElegibilityStatusPicker";
import { ExistingFileList } from "./ExistingFileList";
import FileCreator from "./FileCreator";
import useTransactionFiles from "./useTransactionFiles";

function TransactionFiles({
  data,
  disabled,
  files,
  onSuccess,
  actions,
  shouldShowElegibility = true,
}) {
  const {
    save,
    current,
    setPatientStatus,
    downloadFile,
    updateFile,
    deleteFile,
    fileCreator,
  } = useTransactionFiles(data, onSuccess, shouldShowElegibility);
  let buttonText = "";

  if (actions.files.addFiles && actions.files.updateEligibilityStatus) {
    buttonText = "Update status and upload new files";
  } else if (actions.files.addFiles) {
    buttonText = "Upload new files";
  } else if (actions.files.updateEligibilityStatus) {
    buttonText = "Update status";
  }

  return (
    <>
      {shouldShowElegibility && (
        <div>
          {actions.files.updateEligibilityStatus ? (
            <ElegibilityStatusPicker
              disabled={disabled}
              patientStatus={current.patientStatus}
              setPatientStatus={setPatientStatus}
            />
          ) : (
            <>
              <div>Eligibility Status</div>
              <div>{current.patientStatus}</div>
            </>
          )}{" "}
          <hr className="pb-0 mt-5 mb-2" />
        </div>
      )}
      {actions.files.addFiles && (
        <FileCreator
          canDelete={actions.files.deleteFiles}
          data={data}
          disabled={disabled}
          {...fileCreator}
          onSave={() => {}}
        />
      )}
      <ExistingFileList
        readOnly={disabled}
        downloadFile={downloadFile}
        updateFile={updateFile}
        deleteFile={deleteFile}
        existingFiles={files}
        canDelete={actions.files.deleteFiles}
        canUpdate={actions.files.editFiles}
        canDownload={actions.files.downloadFiles}
      />
      {!disabled ? (
        <div className="text-center mt-3">
          <hr className="pb-0" />
          {(actions.files.addFiles ||
            actions.files.updateEligibilityStatus) && (
            <UpdateButton
              text={buttonText}
              onClick={save}
              disabled={current.saving}
            />
          )}
        </div>
      ) : null}
    </>
  );
}

export default TransactionFiles;
