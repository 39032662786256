import {useState} from "react";
import {getURLParam, getUrlParams, removeUrlParams, setURLParams} from "../../../../../../util/browser";
import {withoutEmptyKeys} from "../../../../../../util/object";

const initialState = {
  user: '',
  product: '',
  practice: ''
}

const urlKeys = Object.keys(initialState)

const useFilterForm = (refreshData) => {
  const [filter, setFilter] = useState({
    ...initialState,
    ...getUrlParams(Object.keys(initialState))
  })

  const handleSetFilter = (filterValues) => {

    const removeEmptyKeys = withoutEmptyKeys(filterValues)

    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys)
      setURLParams(removeEmptyKeys)
    }

    setFilter(filterValues)
    refreshData(filterValues)
  }

  const handleReset = () => {
    console.log('Handle Reset')
    removeUrlParams(urlKeys)
    setFilter(initialState)
    refreshData(initialState)
  }


  return {
    filter,
    handleSetFilter,
    handleReset
  }
}

export default useFilterForm