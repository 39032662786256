import { useState, useContext, useEffect } from "react";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";

import {
  FormSelect as Select,
  FormInputText as Text,
  BasicForm,
} from "../../formControls";
import CustomAccordion from "../../CustomAccordion";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { BookmarkContext } from "../context";
import shortid from "shortid";
import CustomModal from "../../CustomModal";
import {
  getSectionType,
  getBookmarkType,
  getBookmarkValueType,
  getFieldsForm,
  saveCategory,
  saveSubsection,
  bookmarkSave,
} from "../../../services/template";
import BasicList from "../../BasicList";
import { removeBookmark, addBookmark } from "../Utils";
import { Loader } from "../../formControls";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  createCategory,
  createSubcategory,
  createBookmark,
} from "../../Template/Request";

const MySwal = withReactContent(Swal);

const TemplateManager = (props) => {
  const [sectionType, setSectionType] = useState();
  const [valueType, setValueType] = useState();
  const [bookmarkType, setBookmarkType] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalBookmark, setShowModalBookmark] = useState(false);
  const [modalFields, setModalFields] = useState([]);
  const [dataAccordion, setDataAccordion] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [bookmarkButtons, setBookmarkButtons] = useState();
  const [selectedBookmarkType, setSelectedBookmarkType] = useState();
  const [selectedBookmark, setSelectedBookmarks] = useContext(BookmarkContext);
  const [generalFields, setGeneralFields] = useState({
    name: "",
    description: "",
  });
  const [listOptions, setListOptions] = useState({});
  const [templateId, setTemplateId] = useState("60a7c4d9ee59de7530b02fb0");
  const [loaderIsActive, setLoaderIsActive] = useState(false);

  const handleCloseModal = () => setShowModal({ status: false });

  const handleShowModal = (title, type) =>
    setShowModal({ title: title, status: true, type: type });

  const handleCloseModalBookmark = () =>
    setShowModalBookmark({ status: false });

  const handleShowModalBookmark = (title, type) =>
    setShowModalBookmark({ title: title, status: true, type: type });

  const categorySave = (inputsForm) => {
    let dAcc = [];
    let params = {};
    try {
      params = createCategory(inputsForm, generalFields, templateId);
    } catch (ex) {
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please, fill all fields of the form",
      });
      return;
    }
    saveCategory(params)
      .then(([status, resp]) => {
        setSelectedCategory(resp.data.id);
        setSelectedSubCategory(null);
        if (dataAccordion.length > 0) {
          dAcc = [
            ...dataAccordion,
            {
              section: { id: resp.data._id, label: resp.data.categoryName },
              subsection: [],
              bodyContent: [],
            },
          ];
        } else {
          dAcc = [
            {
              section: { id: resp.data._id, label: resp.data.categoryName },
              subsection: [],
              bodyContent: [],
            },
          ];
        }
        props.onSelect({
          category: resp.data._id,
          subCategory: null,
          templateId: templateId,
        });
        setDataAccordion(dAcc);
        handleCloseModal();
      })
      .catch((e) => {
        //TODO: Handle error
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Error",
        });
      });
  };

  const subCategorySave = (inputsForm) => {
    let dAcc = [];
    let params = {};
    dAcc = [...dataAccordion];
    try {
      params = createSubcategory(inputsForm, selectedCategory, templateId);
    } catch (ex) {
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please, fill all fields of the form",
      });
      return;
    }
    saveSubsection(params)
      .then(([status, resp]) => {
        dAcc.map((item) => {
          if (item.section.id === selectedCategory) {
            if (item.subsection.length > 0) {
              item.subsection = [
                ...item.subsection,
                {
                  id: resp.data._id,
                  label: resp.data.code + " - " + resp.data.label,
                },
              ];
            } else {
              item.subsection = [
                {
                  id: resp.data._id,
                  label:
                    params.subsection.code + " - " + params.subsection.label,
                },
              ];
            }
          }
        });
        setDataAccordion(dAcc);
        handleCloseModal();
      })
      .catch((e) => {
        //TODO: Handle error
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Error",
        });
      });
  };

  const handleSubmitModal = (inputsForm) => {
    if (showModal.type == "section") {
      categorySave(inputsForm);
    } else {
      subCategorySave(inputsForm);
    }
  };

  const handleSubmitBookmark = (inputsForm) => {
    let params = {};
    let id = selectedSubCategory ? selectedSubCategory : selectedCategory;
    let type = selectedSubCategory ? "subsection" : "section";
    try {
      params = createBookmark(inputsForm, templateId, id, type);
    } catch (e) {
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please, fill all fields of the form",
      });
      return;
    }
    bookmarkSave(selectedBookmarkType, params)
      .then(([status, resp]) => {
        let bookmark = addBookmark(
          {
            id: resp.data._id,
            bookmark: resp.data.bookmark,
            label: resp.data.label,
            added: true,
            manualAdded: true,
          },
          selectedCategory,
          selectedSubCategory,
          selectedBookmark
        );
        setSelectedBookmarks(bookmark);
        handleCloseModalBookmark();
      })
      .catch((e) => {
        //TODO: Handle Error
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Error",
        });
      });
  };

  const handleClickAddCategory = () => {
    if (generalFields.name === "" && generalFields.description === "") {
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please, fill name and description fields.",
      });
      return;
    }
    setLoaderIsActive(true);
    getFieldsForm("section")
      .then(([status, resp]) => {
        let fields = [];
        for (const field of resp) {
          if (field.parameter !== "sectionType") {
            fields.push({
              control: Text,
              props: {
                key: field.parameter,
                id: field.parameter,
                value: "",
                label: field.label,
              },
            });
          } else {
            fields.push({
              control: Select,
              props: {
                key: field.parameter,
                id: field.parameter,
                value: "",
                label: field.label,
                options: sectionType,
              },
            });
          }
        }
        setModalFields(fields);
        handleShowModal("Add Category", "section");
        setLoaderIsActive(false);
      })
      .catch(() => {
        setLoaderIsActive(false);
      });
  };

  const handleError = () => {};

  const handleChangeInput = (e) => {
    let fields = { ...generalFields };
    fields[e.target.name] = e.target.value;
    setGeneralFields(fields);
  };

  const formatDataToOption = (data) => {
    let options = [];
    data.forEach((t) => {
      options.push({ bookmark: t, label: t });
    });
    return options;
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null);
    props.onSelect({
      category: category,
      subCategory: null,
      templateId: templateId,
    });
  };

  const handleSelectSubCategory = (subcategory) => {
    setSelectedSubCategory(subcategory);
    props.onSelect({
      category: selectedCategory,
      subCategory: subcategory,
      templateId: templateId,
    });
  };

  const handleClickBtnsBookmark = (btn) => {
    let currentBtn = removeBookmark(
      btn,
      selectedBookmark,
      selectedCategory,
      selectedSubCategory
    );
    setSelectedBookmarks(currentBtn);
  };

  const handleClickBtnsHeaderAcc = (name) => {
    setModalFields([]);
    if (name === "subsection") {
      getFieldsForm(name).then(([status, resp]) => {
        let fields = [];
        for (const field of resp) {
          fields.push({
            control: Text,
            props: {
              key: field.parameter,
              id: field.parameter,
              value: "",
              label: field.label,
            },
          });
        }
        setModalFields(fields);
        handleShowModal("Add SubCategory", "subsection");
      });
    } else {
      handleShowModalBookmark("Add Bookmark");
    }
  };

  const handleChangeBookmarkType = (e) => {
    let fields = [];
    setSelectedBookmarkType(e.target.value);
    getFieldsForm(e.target.value)
      .then(([status, resp]) => {
        resp.forEach((field) => {
          if (field["parameter"] === "type") {
            fields.push({
              control: Select,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                value: "",
                label: field["label"],
                options: valueType,
              },
            });
          } else if (field["items"]) {
            fields.push({
              control: BasicList,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                title: field["label"],
                items: field["items"],
                onUpdate: (l) => handleUpdateList(l),
              },
            });
          } else {
            fields.push({
              control: Text,
              props: {
                key: field["parameter"],
                id: field["parameter"],
                value: "",
                label: field["label"],
              },
            });
          }
        });
        setModalFields(fields);
      })
      .catch((ex) => {
        //TODO: Add handler
      });
  };

  const handleUpdateList = (list) => {
    setListOptions(list);
  };

  const hanldeRemoveSection = (id) => {
    let selectedBtns = { ...selectedBookmark };
    let currentData = [...dataAccordion];
    currentData.forEach((section, index) => {
      if (section.section.id === id) {
        currentData.splice(index, 1);
      }
    });
    delete selectedBtns[id];
    setSelectedBookmarks(selectedBtns);
    setDataAccordion(currentData);
    //TODO: Add service to remove
  };

  const handleRemoveSubSection = (id) => {
    let selectedBtns = { ...selectedBookmark };
    let currentData = [...dataAccordion];
    currentData.forEach((section, index) => {
      section.subsection.forEach((subsection, index) => {
        if (subsection.id === id) {
          section.subsection.splice(index, 1);
        }
      });
    });
    delete (selectedBtns[selectedCategory] || {})[id];
    setSelectedBookmarks(selectedBtns);
    setDataAccordion(currentData);
    //TODO: Add service to remove
  };

  useEffect(() => {
    let bookmarks = { ...selectedBookmark };
    setBookmarkButtons(bookmarks);
  }, [selectedBookmark, selectedCategory, selectedSubCategory]);

  useEffect(async () => {
    try {
      let [sct, sectionType] = await getSectionType();
      let valueType = await getBookmarkValueType();
      let bookmarkType = await getBookmarkType();

      setSectionType(formatDataToOption(sectionType));
      setValueType(formatDataToOption(valueType));
      setBookmarkType(formatDataToOption(bookmarkType));
    } catch (ex) {
      //TODO: ADD notification
    }
  }, []);

  return (
    <div>
      <Row>
        <Col md={4}>
          <Text
            label="Name:"
            id="name"
            value={generalFields.name}
            onError={handleError}
            onChange={handleChangeInput}
          />
        </Col>
        <Col md={8}>
          <Text
            label="Description:"
            id="description"
            value={generalFields.description}
            onError={handleError}
            onChange={handleChangeInput}
          />
        </Col>
      </Row>

      <ButtonGroup variant="btnlist" className="btnList">
        <Button
          variant=" optionCategories"
          onClick={(e) => handleClickAddCategory()}
        >
          <AddCircleIcon className="smIcon" />
          <span className="lbl_btnList">Category</span>
        </Button>
      </ButtonGroup>
      <hr />
      <CustomAccordion
        selected={selectedCategory}
        data={dataAccordion}
        bookmarks={bookmarkButtons}
        onSelectSection={handleSelectCategory}
        onSelectSubSection={handleSelectSubCategory}
        onClickBtns={handleClickBtnsBookmark}
        onClickBtnsHeader={handleClickBtnsHeaderAcc}
        onRemoveSection={hanldeRemoveSection}
        onRemoveSubSection={handleRemoveSubSection}
      />
      <hr />

      <CustomModal
        show={showModal.status}
        onHide={handleCloseModal}
        title={showModal.title}
      >
        <BasicForm
          id={showModal.type}
          key={shortid.generate()}
          fields={modalFields}
          onSubmit={handleSubmitModal}
        />
      </CustomModal>

      <CustomModal
        show={showModalBookmark.status}
        onHide={handleCloseModalBookmark}
        title={showModalBookmark.title}
      >
        <Select
          id="slBookmarkType"
          label="Bookmark type:"
          options={bookmarkType}
          value=""
          onChange={handleChangeBookmarkType}
        />
        <BasicForm
          key={shortid.generate()}
          fields={modalFields}
          onSubmit={(e) => handleSubmitBookmark(e, showModalBookmark.type)}
        />
      </CustomModal>
      <Loader active={loaderIsActive} />
    </div>
  );
};
export default TemplateManager;
