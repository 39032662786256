import { getDateAsString } from "../../../util/date";

export function formatProducts(data = []) {
  return data.reduce((acum, el) => {
    const {
      active,
      date,
      practiceName,
      practiceId,
      productId,
      _id,
      productType: product,
      format,
      fileName,
    } = el;

    const p = {
      date: getDateAsString(date, "yyyy-MM-DD"),
      productId,
      _id,
      product,
      active,
      fileName,
      carrier: format.carrier,
      pms: Array.isArray(format.pms) ? null : format.pms,
      remoteConnection: Array.isArray(format.remoteConnection)
        ? null
        : format.remoteConnection,
    };

    const practice = acum.find((x) => x.practiceId === practiceId);

    if (!practice) {
      acum.push({
        practiceId,
        practiceName,
        products: [p],
      });
    } else {
      practice.products.push(p);
    }

    return acum;
  }, []);
}
