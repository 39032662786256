import {useRef} from "react";
import * as Yup from 'yup'
import {ErrorMessage, Form, Formik} from "formik";
import {Col, Row} from "react-bootstrap";
import useSortForm from "./useSortForm";
import ArrangeStatus from "./components/ArrangeStatus";
import UserHelp from "../../../../util/UserHelp";
import {OPTIONS_SORT, SELECT_FIELDS} from "../../utils/constanst";

const ErrorOrderMessage = ({name}) => {
  return (
    <ErrorMessage name={name}>
      {msg => <span
        style={{fontSize: '.75rem'}}
        className='text-center text-center text-danger d-block w-100'>
                  {msg}
                </span>
      }
    </ErrorMessage>
  )
}

export const filterOptionsBasedInTheSelected = (options, currentOption) => {
  const selectKeys = Object.keys(options).filter(key => !key.includes('Order'));
  const objectOptions = {};
  selectKeys.forEach(key => {
    objectOptions[key] = options[key]
  });

  if (objectOptions.hasOwnProperty(currentOption)) {
    delete objectOptions[currentOption]
  }

  return Object.values(objectOptions)
}

const SortForm = () => {
  const {handleSubmit, prevSort, ivStatusArr, onDragEnd} = useSortForm()
  const {transaction: t = {}} = prevSort
  const {priority, ...transaction} = t
  const transactionsKeys = Object.keys(transaction);
  const formRef = useRef(null);

  const initialValues = SELECT_FIELDS.reduce((acc, field, index) => {
    acc[field.name] = transactionsKeys[index] || '';
    acc[field.orderName] = transaction[transactionsKeys[index]] || '';
    return acc
  }, {});

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }

  const validationSchemaObject = {};

  SELECT_FIELDS.forEach(({name, orderName}) => {
    validationSchemaObject[orderName] = Yup.string().when([name], {
      is: (value) => value ? value.length > 0 : false,
      then: Yup.string().required('Field is required'),
      otherwise: Yup.string()
    })
  });

  const validationSchema = Yup.object().shape(validationSchemaObject);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleSubmit(values)}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({values, setFieldValue}) => (
          <Form style={{width: '400px'}}>
            {SELECT_FIELDS.map(field => {
              const excludeValues = filterOptionsBasedInTheSelected(values, field.name);
              return (
                <Row className='mb-2 mt-3' key={field.name}>
                  <Col md={6}>
                    <select
                      className='form-control'
                      id={field.name}
                      name={field.name}
                      value={values[field.name]}
                      onChange={({target}) => {
                        setFieldValue(field.name, target.value)
                        setFieldValue(field.orderName, '')
                      }}
                    >
                      <option value=''>Select...</option>
                      {OPTIONS_SORT.filter((o) => {
                        return !excludeValues.includes(o.value)
                      }).map((o, i) => {
                        return (<option key={i} value={o.value}>{o.label}</option>)
                      })}
                    </select>
                  </Col>
                  <Col md={6}>
                    <select
                      className='form-control'
                      id={field.orderName}
                      name={field.orderName}
                      value={values[field.orderName]}
                      onChange={({target}) => setFieldValue(field.orderName, target.value)}
                    >
                      <option value=''>Select...</option>
                      {OPTIONS_SORT.filter((o) => o.value === values[field.name])
                        .map((o = []) => {
                          const ops = o.options || []
                          return ops.map((_o, _i) => {
                            return (<option key={_i} value={_o.value}>{_o.label}</option>)
                          })
                        })}
                    </select>
                    <ErrorOrderMessage name={field.orderName}/>
                  </Col>
                </Row>
              )
            })}
          </Form>
        )}
      </Formik>
      <Row>
        <Col md={12}>
          <UserHelp>
            Please arrange the status
          </UserHelp>
          <ArrangeStatus
            ivStatusArr={ivStatusArr}
            onDragEnd={onDragEnd}
          />
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 3, span: 6}} style={{marginBottom: '1rem'}}>
          <button type="button" className='btn btn-primary btn-block' onClick={submitForm}>
            Save sort
          </button>
        </Col>
      </Row>
    </>
  )
}

export default SortForm
