import {Form} from 'react-bootstrap';
import {useState} from 'react'
import { useForm } from 'react-hook-form';


const FormInputTextArea = (props) => {
  const [value,setValue] = useState(props.value);
  const { register, formState: { errors } } = useForm({
    mode: "onBlur"
  });  

  const handleChange = (e) =>{
    setValue(e.target.value);  
    props.onChange(e);      
  }

  const handleError = (element) =>{
      props.onError({status:!!errors?.[element],control:element,details:errors?.[element]});
      //console.log(errors?.[element])
      return errors?.[element];      
  }

    return (
      <Form.Group>
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Form.Control name={props.id} type="textarea" id={props.id} 
          placeholder={props.msg} 
          maxLength={props.maxLength}
          {...register(props.id, {pattern:/^[a-zA-Z0-9\s,.'-]*$/i})}                    
          defaultValue={value}
          onChange={handleChange}
          />
        {handleError(props.id) && <span className="text-danger">*Invalid text</span>}      
      </Form.Group>
    );
  }
  
  export default FormInputTextArea;