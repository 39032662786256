import styled from "styled-components";

export const ContainerAnalitycs = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  margin: 1rem 0.25rem;
  background-color: #f8f8f8;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(240 240 240);
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background-color: #dbdedf;
  }
`;

export const AnalitycsInfoItem = styled.div`
  padding: 0.5rem 0.8rem;
  border-left: 1px solid #ccd5db;
  flex: 0 0 auto;
  &:nth-child(1) {
    border: 1px solid #f8f8f8;
  }
  &:nth-last-child(2) {
    border-right: 1px solid #ccd5db;
    margin-right: 3rem;
  }
`;

export const ContainerAnalitycsInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;

export const AnalitycsPercentsAndCounts = styled.div`
  //background-color: ${(props) => (props.primary ? "transparent" : "#3a9edb")};
  padding: 0.35rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  border-radius: 4px;
`;

export const AnalitycsPercentsAndCountsItem = styled.span`
  font-size: 1.1rem;
  //color: ${(props) => (props.primary ? "#627b89;" : "#FFF;")};
  font-weight: ${(props) => (props.primary ? 500 : "bold")};
  //font-weight: bold;
  padding: 0;
`;

export const AnalitycsName = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const PracticesModalIconContainer = styled.button`
  position: absolute;
  right: 0.8rem;
  align-items: center;
  cursor: pointer;
  background-color: #0b96e5;
  padding: 2.7rem 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  &:disabled{
    cursor: not-allowed;
    background-color: rgb(229, 229, 229);
  }
`;
