import useTableAsync from "./useTableAsync";
import * as services from '../claims.services'
import {useContext, useMemo} from "react";
import {getUrlParams} from "../../../../util/browser";
import {AppContext} from "../../../../App";
import {useAsyncTableData} from "../../../CustomTable/hooks";

export const paramsClaims = {
  practiceId: '',
  startDate: '',
  endDate: '',
  patientName: '',
  claimType: 'validate'
}

const useClaims = () => {

  const appContext = useContext(AppContext);
  const practiceId = appContext.get("practiceId");
  const practiceName = appContext.get('practiceName')
  const initial = {...paramsClaims, practiceId}

  const initialParams = {...getUrlParams(Object.keys(initial)), ...paramsClaims, practiceId}
  const {claimType} = {...paramsClaims, ...getUrlParams(Object.keys(initial))}


  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    services.getClaims,
    initialParams
  )


  return {
    claimType,
    practiceName,
    practiceId,
    dataSource,
    tableReference,
    refreshData
  }
}

export default useClaims