import {useEffect, useState} from "react";
import { toast } from "react-toastify";
import { displayModalMessage } from "../../../../util/displayMessage";
import { useAsyncTableData } from "../../../CustomTable/hooks";
import * as services from "../services";

const useBookmarks = () => {
  const [record, _setRecord] = useState(false);

  const { dataSource, tableReference, refreshData } = useAsyncTableData(
    services.getList,
  );

  // Handlers
  function clearRecord() {
    _setRecord(false);
  }

  function onSaveModalForm(data, type) {
    if (!data) {
      toast.error("Dear user, please fill al the fields");
      return;
    }

    let res;
    let successfulResult = "created";

    if (record._id) {
      successfulResult = "updated";
      res = services.updateBookmark(type, record._id, data);
    } else {
      res = services.createBookmark(type, data);
    }

    res.then(([s, r]) => {
      if (!s) return;
      toast.success(`Bookmark successfully ${successfulResult}`);
      refreshData();
      clearRecord();
    });
  }

  function newEmptyBookmark() {
    _setRecord({});
  }

  function setRecord(r) {
    services.getBookmarkState(r._id).then(([s, fieldStates]) => {
      if (!s) return;
      _setRecord({
        ...r,
        fieldStates,
        usedBy: fieldStates.usedBy
          ? fieldStates.usedBy.map((x) => x.templateName)
          : [],
      });
    });
  }

  function onDelete(_id) {
    services.getBookmarkState(_id).then(([s, f]) => {
      if (!s) {
        return;
      }

      if (f.usedBy && f.usedBy.length > 0) {
        const recordsUsingIt = f.usedBy.length;
        const plural = recordsUsingIt > 1 ? "s" : "";
        displayModalMessage(
          "Warning",
          `Dear user, this record cannot be deleted, is being used by (${recordsUsingIt}) template${plural}`
        );

        return;
      }

      services.deleteBookmark(_id).then(([s, r]) => {
        if (!s) return;
        toast.success("Bookmark successfully deleted");
        refreshData();
      });
    });
  }

  return {
    tableReference,
    record,
    newEmptyBookmark,
    onDelete,
    setRecord,
    dataSource,
    clearRecord,
    onSaveModalForm,
  };
};

export default useBookmarks;
