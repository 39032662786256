import { Form, InputGroup, FormControl } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "../styles.css";

const FormInputNumber = ({
  style,
  disabled,
  className,
  noState = false,
  ...props
}) => {
  let clearValue = "";
  try {
    clearValue = props.value
      ? props.value.toString().replace(/[^0-9,.]*/g, "")
      : "";
  } catch (ex) {}
  const [value, setValue] = useState(clearValue);
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const handleChange = (e) => {
    if (noState === false) setValue(e.target.value);
    props.onChange(e);
  };

  const handleError = (element) => {
    props.onError({
      status: !!errors?.[element],
      control: element,
      details: errors?.[element],
    });
    return errors?.[element];
  };

  return (
    <Form.Group style={style} className={className}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <InputGroup className="mb-2">
        {props.symbol && (
          <InputGroup.Prepend>
            <InputGroup.Text>{props.symbol}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormControl
          name={props.id}
          type="text"
          id={props.id}
          disabled={disabled}
          className={`custom-form-control ${
            props.border && !value ? "red-border" : ""
          }`}
          placeholder={props.msg}
          maxLength={props.maxLength}
          {...register(props.id, { pattern: /^[0-9.,$]*$/i })}
          defaultValue={value}
          onBlur={props.onBlur ? props.onBlur : null}
          onChange={handleChange}
        />
      </InputGroup>
      {handleError(props.id) && (
        <span className="text-danger">*Invalid number</span>
      )}
    </Form.Group>
  );
};

export default FormInputNumber;
