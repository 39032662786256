import React from "react";
import styled from "styled-components";

const Tooltip = styled.div`
  position: absolute;
`

const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
  left: calc(50% - 5px);

  &::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background: ${({tooltipColor = '#000'}) => tooltipColor};
    width: 10px;
    height: 10px;
    top: 0;
    left: 0;
  }

  bottom: -4px;
`

const Body = styled.div`
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  font-size: .8rem;
  min-width: 100px;
  border-radius: 3px;
  background-color: ${({tooltipColor = '#000'}) => tooltipColor};
`

const CustomTooltipBody = React.forwardRef(
  ({message, colorTooltip, ...props}, ref) => {
    return (
      <Tooltip ref={ref} {...props}>
        <Arrow tooltipColor={colorTooltip}/>
        <Body tooltipColor={colorTooltip}>
          {message}
        </Body>
      </Tooltip>
    )
  }
)
export default CustomTooltipBody