import {Accordion, Card, useAccordionToggle} from "react-bootstrap";
import {useRef} from "react";
import CredentialHeader from "../CredentialHeader";
import CredentialInfo from "../CredentialInfo";

const CredentialWrapper = (
  {
    credential,
    index,
    addCredential,
    handleEdit,
    deleteCredential,
    handleDeleteCredentials
  }
) => {
  const decoratedOnClick = useAccordionToggle(index + 1, () => {
  });

  const ref = useRef(null)

  return (
    <>
      <Card>
        <Card.Header
          className={`d-flex justify-content-between align-content-center py-3 px-0`}
          key={index + 1}
          onClick={(ev) => {
            //if (true) {
            //decoratedOnClick();
            //}
          }}
        >
          <CredentialHeader
            credential={{id: 0, label: 'Credentials'}}
            clickeableRef={ref}
            addCredential={addCredential}
            handleDeleteCredentials={handleDeleteCredentials}
          />
        </Card.Header>
        <Accordion.Collapse
          eventKey={'0'}
        >
          <Card.Body>
            <div className='d-flex ml-1 flex-wrap'>
              {credential.credentials.map(cred => {
                return (
                  <CredentialInfo
                    key={cred.id}
                    credential={cred}
                    handleEdit={handleEdit}
                    deleteCredential={deleteCredential}
                  />
                )
              })}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  )
}

export default CredentialWrapper