import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React from "react";
import useTransactionModalForm from "./useTransactionModalForm";
import {Formik} from "formik";

const TransactionModalForm = (props) => {
  const {transactionSelect = {}, edit, setModal, refreshData} = props
  const {initialValues, onHandleSubmit, data} = useTransactionModalForm(transactionSelect, edit, refreshData)
  return (
    <>
      <Container className="ml-2">
        <Formik
          initialValues={data}
          enableReinitialize={true}
          onSubmit={(values) => {
            onHandleSubmit(values)
            setModal(false)
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit}
            >
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Type Record:</Form.Label>
                    <Form.Control
                      id="typeRecord"
                      name="typeRecord"
                      as="select"
                      required
                      value={props.values.typeRecord}
                      onChange={({target: {value}}) => {
                        props.setFieldValue('typeRecord', value)
                      }
                      }
                    >
                      <option selected value="" disabled> Please select an option</option>
                      <option value="FBD">FBD</option>
                      <option value="ELG">ELG</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Carrier Name:</Form.Label>
                    <Form.Control
                      id="carrierName"
                      name="carrierName"
                      maxLength="200"
                      value={props.values.carrierName}
                      onChange={(e) => {
                        if (/^[a-zA-Z\s_-]*$/.test(e.target.value)) {
                          props.setFieldValue('carrierName', e.target.value)
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Employer Name:</Form.Label>
                    <Form.Control
                      id="employerName"
                      name="employerName"
                      required
                      value={props.values.employerName}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>apptDate:</Form.Label>
                    <Form.Control
                      id="apptDate"
                      name="apptDate"
                      type="date"
                      required
                      value={props.values.apptDate}
                      onChange={props.handleChange}
                    />
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Relationship:</Form.Label>
                    <Form.Control
                      id="relationship"
                      name="relationship"
                      required
                      value={props.values.relationship}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Member ID:</Form.Label>
                    <Form.Control
                      id="memberId"
                      name="memberId"
                      type="text"
                      required
                      value={props.values.memberId}
                      onChange={(event,) => {
                        if (/^[0-9]+$/.test(event.target.value)) {
                          props.setFieldValue('memberId', event.target.value)
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Patient Id:</Form.Label>
                    <Form.Control
                      id="patientId"
                      name="patientId"
                      type="text"
                      required
                      value={props.values.patientId}
                      onChange={(event,) => {
                        if (/^[0-9]+$/.test(event.target.value)) {
                          props.setFieldValue('patientId', event.target.value)
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Suscriber Id:</Form.Label>
                    <Form.Control
                      id="subscriberId"
                      name="subscriberId"
                      type="text"
                      required
                      value={props.values.subscriberId}
                      // onChange={props.handleChange}
                      onChange={(event,) => {
                        if (/^[0-9]+$/.test(event.target.value)) {
                          props.setFieldValue('subscriberId', event.target.value)
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Patient First Name:</Form.Label>
                    <Form.Control
                      id="patientFirstName"
                      name="patientFirstName"
                      required
                      value={props.values.patientFirstName}
                      onChange={props.handleChange}

                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Suscriber First Name:</Form.Label>
                    <Form.Control
                      id="subscriberFirstName"
                      name="subscriberFirstName"
                      required
                      value={props.values.subscriberFirstName}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Patient Last Name:</Form.Label>
                    <Form.Control
                      id="patientLastName"
                      name="patientLastName"
                      label="Desc"
                      value={props.values.patientLastName}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Suscriber Last Name:</Form.Label>
                    <Form.Control
                      id="subscriberLastName"
                      name="subscriberLastName"
                      required
                      value={props.values.subscriberLastName}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Patient DOB:</Form.Label>
                    <Form.Control
                      id="patientDOB"
                      name="patientDOB"
                      type="date"
                      required
                      value={props.values.patientDOB}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Suscriber DOB:</Form.Label>
                    <Form.Control
                      id="subscriberDOB"
                      name="subscriberDOB"
                      type="date"
                      required
                      value={props.values.subscriberDOB}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>


              <Row className="justify-content-md-center">
                <Col md={4}>
                  <Button
                    className="btn btn-success mt-2"
                    type="submit"
                    size="md"
                    block
                  >
                    Save
                  </Button>
                </Col>
              </Row>


            </form>
          )}
        </Formik>


      </Container>
    </>

  )
    ;
}

export default TransactionModalForm