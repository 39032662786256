import ColumnMissingFields from "../components/pages/AllTransactions/components/ColumnGrid/ColumnMissingFields/ColumnMissingFields";
import ColumnVerificationBy from "../components/pages/AllTransactions/components/ColumnGrid/ColumnVerificationBy";
import { GridText } from "../components/pages/AllTransactions/components/styles/AllTransactionTable.style";
import React from "react";

export const transactionsColumnByModule = (module_key) => {
  const modulesForSeeTransactionColumns = ["AT"];
  const transactionColumns = [
    {
      title: "Missing Fields",
      align: "center",
      sorting: false,
      render: (rowData) => {
        return <ColumnMissingFields rowData={rowData} />;
      },
      permission: "Missing Fields",
    },
    {
      sorting: false,
      title: "Verification By",
      align: "center",
      field: "iv_process_result",
      render: ({ iv_process_result = "", user }) => {
        return (
          <ColumnVerificationBy
            iv_process_result={iv_process_result}
            user={user}
          />
        );
      },
      permission: "Verification By",
    },
    {
      sorting: false,
      title: "Provider",
      align: "center",
      field: "provider",
      render: ({ provider = "" }) => {
        return <GridText>{provider}</GridText>;
      },
      permission: "Provider",
    },
  ];
  return modulesForSeeTransactionColumns.includes(module_key)
    ? transactionColumns
    : [];
};
