import { useEffect, useState } from "react";
import AircallPhone from "aircall-everywhere";
import {toast} from "react-toastify";

const useAirCall = (phone, container) => {
  const [airCallPhone, setAirCallPhone] = useState(null);
  const [showPhone, setShowPhone] = useState(false);

  useEffect(() => {
    setAirCallPhone(new AircallPhone({
      domToLoadPhone: container,
      onLogin: (settings) => {},
      onLogout: () => {},
      integrationToLoad: "zendesk",
      size: "small",
    }));
  }, []);

  const onPhoneCall = () => {
    setShowPhone(_value => true);
    airCallPhone.isLoggedIn((response) => {
      if (response) {
        const payload = {
          phone_number: phone,
        };
        airCallPhone.send("dial_number", payload, (success, data) => {
          if (!success) {
            toast.error("error to load phone.");
          }
        });
      }
    });
  }

  return {
    airCallPhone,
    showPhone,
    onPhoneCall,
    setShowPhone
  }
}

export default useAirCall;
