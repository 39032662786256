import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import { getCarriers } from "../../../../config/insuranceverification";
import { getInitialAndLastDayOfMonth } from "../../../../util/date";
import * as middlewares from "../middlewares";
import * as services from "../services";

const NEXT_YEAR = 12;
const PREVIOUS_YEAR = -1;

const initialState = {
  linear: {},
  donut: {},
  bar: {
    completed: {
      data: {},
      count: 0,
    },
    notCompleted: {
      data: {},
      count: 0,
    },
    notFound: {
      data: {},
      count: 0,
    },
    audited: {
      data: {},
      count: 0,
    },
  },
  table: {},
  recordCount: 0,
  loaded: false,
};

export default function useDashboard() {
  const [carriers, setCarriers] = useState(false);
  const [source, setSource] = useState([]);
  const [data, setData] = useState(initialState);
  const [carrierFilter, setCarrierFilter] = useState("All Carriers");
  const [filter, setFilter] = useState("");
  const appContext = useContext(AppContext);
  const [dateFilter, setDateFilter] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const practiceIds = appContext.get("selectedPractices");

  function addMonth(m) {
    const nextMonth = dateFilter.month + m;

    if (nextMonth === NEXT_YEAR) {
      setDateFilter(({ year: _prevYear }) => ({
        month: 0,
        year: _prevYear + 1,
      }));
      return;
    }

    if (nextMonth === PREVIOUS_YEAR) {
      setDateFilter(({ year: _prevYear }) => ({
        month: 11,
        year: _prevYear - 1,
      }));
      return;
    }

    setDateFilter(({ year: _prevYear }) => ({
      month: nextMonth,
      year: _prevYear,
    }));
  }

  function loadData() {
    if (practiceIds.length === 0) return;

    setData((d) => ({ ...d, loaded: false }));
    const { month, year } = dateFilter;

    const days = getInitialAndLastDayOfMonth(month, year, services.dateFormat);

    services
      .getDashboardData(
        days.startDate,
        days.endDate,
        practiceIds,
        carrierFilter === "All Carriers" ? "" : carrierFilter
      )
      .then(([s, r]) => {
        if (!s) {
          setSource([]);
          return;
        }
        setSource(r);
      });
  }

  useEffect(() => {
    const asTable = middlewares.formatTable(source, filter);
    setData({
      linear: middlewares.formatLinearStatistics(source),
      bar: middlewares.formatBar(source),
      table: asTable,
      donut: middlewares.formatDonut(asTable),
      recordCount: source.length,
      loaded: true,
    });
  }, [carrierFilter, source, filter]);

  useEffect(() => {
    loadData();
  }, [carrierFilter, dateFilter, practiceIds]);

  useEffect(() => {
    getCarriers().then(([s, r]) => {
      if (!s) {
        setCarriers([{ _id: "", text: "All Carriers" }]);
        return;
      }

      setCarriers([{ _id: "", text: "All Carriers" }, ...r]);
    });
  }, []);

  return {
    data: { ...data, filter },
    month: moment.monthsShort(dateFilter.month),
    year: dateFilter.year,
    carriers,
    practiceIds,
    carrierFilter,
    actions: {
      setFilter,
      setCarrierFilter,
      addMonth,
    },
  };
}
