import { useCallback, useEffect, useState } from "react";
import * as clientNSServices from "./clientNS.services";
import * as credentialServices from "../../Credential/services/credential.services";
import { Delete, Edit } from "@material-ui/icons";
import { displayModalConfirmation } from "../../../util/displayMessage";
import { toast } from "react-toastify";

const initialValues = {
  clientId: "",
  key: "",
};

const appendNamesToClient = (resList, resClients) => {
  return resList.map((l) => {
    return {
      ...l,
      name: resClients.filter((cl) => cl._id === l.clientId)[0].name || "",
    };
  });
};

const formatAllClients = (resClients) => {
  return resClients.map((cl) => {
    return {
      label: cl.name,
      value: cl._id,
    };
  });
};

const useClientNS = () => {
  const [clientsNS, setClientsNS] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clientState, setClientState] = useState(initialValues);
  const [allClients, setAllClients] = useState([]);
  const [rawClients, setRawClients] = useState([]);

  useEffect(() => {
    async function getClientsNS() {
      const [clients, list] = await Promise.all([
        credentialServices.getAllClients(),
        clientNSServices.listCredentialKeys(),
      ]);

      const [statusList, resList] = list;
      const [statusClients, resClients] = clients;

      if (statusList) {
        setClientsNS(appendNamesToClient(resList, resClients));
      }

      if (statusClients) {
        setRawClients(resClients);
        setAllClients(formatAllClients(resClients));
      }
    }

    getClientsNS();
  }, []);

  const handleSubmit = async (values) => {
    const { _id = null } = clientState;
    const [status, saved] = await clientNSServices.saveCredentialKey(values);
    if (status) {
      clientNSServices.listCredentialKeys().then(([s, r]) => {
        if (s) {
          console.log(r, rawClients);

          setClientsNS(appendNamesToClient(r, rawClients));
        }
      });

      // if (!_id) {
      //   setClientsNS((clNs) => [
      //     ...clNs,
      //     {
      //       _id: saved.id,
      //       name: allClients.filter((cl) => cl.value === values.clientId)[0]
      //         .label,
      //       key: values.key,
      //       clientId: values.clientId,
      //     },
      //   ]);
      // }
      setClientState(initialValues);
      setShowModal(false);
    }
  };

  const deleteCredentialKey = (id) => {
    displayModalConfirmation(
      "Delete",
      <span>Are you sure of delete this key?</span>,
      async () => {
        const [status, msg] = await clientNSServices.deleteCredentialKey(id);
        if (status) {
          toast.success(msg);
          setClientsNS((cl) => cl.filter((c) => c._id !== id));
        }
      }
    );
  };

  const actions = [
    {
      icon: () => <Delete color="error" />,
      tooltip: "Delete",
      onClick: (event, { _id }) => {
        deleteCredentialKey(_id);
      },
    },
    ({ _id, clientId, key }) => ({
      icon: () => <Edit color="primary" />,
      tooltip: "Edit",
      onClick: () => {
        setClientState({ ...clientState, _id, clientId, key });
        setShowModal(true);
      },
    }),
  ];

  const setInitialValue = () => {
    setClientState(initialValues);
  };

  return {
    clientsNS,
    showModal,
    setShowModal,
    initialValues,
    handleSubmit,
    actions,
    allClients,
    clientState,
    setInitialValue,
  };
};

export default useClientNS;
