import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as services from '../services/index'
import {displayModalMessage} from "../../../../../../util/displayMessage";

const useShareBookmarks = () => {
  const {id: templateId} = useParams()
  const [template, setTemplate] = useState({})
  const [sharedElements, setSharedElements] = useState([])

  useEffect(() => {
    async function getTemplate() {
      const [templatePromise, sharedElementsPromise] = await Promise.all([
        services.getTemplateById(templateId),
        services.getSharedElementsByTemplateId(templateId)
      ])

      const [statusTemplate, resTemplate] = templatePromise
      const [statusSharedElements, resSharedElements] = sharedElementsPromise

      if (statusTemplate && statusSharedElements) {
        setTemplate(resTemplate)
        setSharedElements(resSharedElements.reduce((acc, curr) => {
          return [...acc, ...curr.elements]
        }, []))
      }
    }

    getTemplate()
  }, [templateId])

  const handleOnChange = (isChecked, id) => {
    if (isChecked) {
      setSharedElements([...sharedElements, id])
    } else {
      const deleteId = sharedElements.filter(element => element !== id)
      setSharedElements(deleteId)
    }
  }

  const handleSaveSharedBookmarks = async () => {
    if (sharedElements.length === 0) {
      displayModalMessage('Select', 'Please select at least one Bookmark')
    } else {
      const [status, res] = await services.storeSharedElements({
        templateId,
        elements: sharedElements
      })

      if (status) {
        displayModalMessage('Shared', res, 'success')
      }
    }
  }

  return {
    template,
    templateId,
    sharedElements,
    handleOnChange,
    handleSaveSharedBookmarks
  }
}

export default useShareBookmarks