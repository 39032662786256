import { NO_INSURANCE_COMPANY, EXCLUDE_FILTER_KEYS } from "./utils/constanst";

const CONTAINS = "c";

export const processFilters = (filter) => {
  const arrKeys = Object.keys(filter);

  const newObjFilter = arrKeys
    .map((key) => {
      let obj = {};
      if (EXCLUDE_FILTER_KEYS.includes(key)) {
        obj[key] = filter[key];
        return obj;
      }
      const { operation, value } = filter[key];
      obj[key] = {
        operation,
        value: changeValueByFilter(value, key),
      };
      return operation !== "" ? obj : {};
    })
    .reduce((acc, cur) => {
      return { ...acc, ...cur };
    }, {});

  return processMissingFields(newObjFilter);
};

const changeValueByFilter = (value, filter) => {
  return filter === "carrier" && value.toLowerCase() === NO_INSURANCE_COMPANY
    ? "EMPTY"
    : value;
};

const processMissingFields = (filters) => {
  const { missingFields = null, ...restFilters } = filters;
  const restFiltersCopy = { ...restFilters };

  if (missingFields?.operation) {
    restFiltersCopy["missingFields"] = missingFields.operation === CONTAINS;
  }
  processAuditProperty(restFiltersCopy);
  return restFiltersCopy;
};

export const processAuditProperty = (restFilters) => {
  if (restFilters["audited"]?.operation) {
    restFilters["audited"].value =
      restFilters["audited"].operation === CONTAINS;
    restFilters["audited"].operation = "c";
  }
};
