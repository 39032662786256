export function getClientFromPracticesSelected(practiceId, practices){
  const _practices = practices.filter((p) => p._id === practiceId);

  return _practices.length > 0 ? {
    clientId: _practices[0].clientId,
    clientName: _practices[0].client.length > 0 ? _practices[0].client[0].name : '',
  } : null;
}

export function getAllPracticesFromClient(clientId, practices){
  const _practices = practices.filter((p) => p.clientId === clientId);

  return _practices.length > 0 ? _practices : null;
}

export function groupPractices(practices){
  let obj = {}

  practices.forEach((practice) => {
    if (!obj[practice.clientId]) {
      obj[practice.clientId] = {
        clientId: practice.clientId,
        clientName: practice.client.length > 0 ? practice.client[0].name : '',
        practices: []
      }
    }

    obj[practice.clientId].practices.push(practice)
  })

  return obj

}

export function reducePracticesForGetClient(practices, practiceId){
  return practices.filter(p => p._id === practiceId).reduce((acc, cur) => {
    return {...acc, ...cur}
  }, {})
}

export function filterAllPracticesFromAClient(practices, clientId) {
  return practices.filter(p => p.clientId === clientId)
    .reduce((acc, cur) => {
      acc.push(cur._id)
      return acc
    }, [])
}