import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import { SubsectionTitle } from "../../formControls";
import { DynamicForm } from "../../formControls/BasicForm";
import { TemplateWrapped } from "../../Layout";
import useTransactionPlanDetail from "./useTransactionPlanDetail";
import "./styles/index.css";

function countEmptyFields(data) {
  return data.reduce((a, i) => {
    const {
      control,
      props: { value, options, ...ob },
    } = i;

    //Ignore subTitles
    if (control === SubsectionTitle) return a;

    let invalid = false;

    if (i.props.required) {
      // Check if is a plain text or it has values that user has to pick
      invalid =
        typeof value === "undefined" || value === "-" || value.length === 0;
    }

    if (invalid) {
      a++;
    }
    return a;
  }, 0);
}

const TransactionPlanDetail = () => {
  const { data, loaded, setData, saveSection } = useTransactionPlanDetail();

  if (!loaded)
    return <TemplateWrapped title="Transaction Plan Detail"></TemplateWrapped>;

  function handler(parentI, childI) {
    return function (value) {
      let newData = [...data];
      const control = newData[parentI]["content"][childI];

      if (control.props.value === value) {
        return;
      }

      control["props"] = {
        ...control["props"],
        value,
        border: control["props"].required && value.length === 0,
      };

      setData([...newData]);
    };
  }

  return (
    <TemplateWrapped title="Transaction Plan Detail">
      <div className="panel">
        <div className="panel-body">
          {data ? (
            <TabContainer
              data={data}
              handleSubmit={async (d) => {
                const res = await saveSection(d);
                if (res) {
                  toast.success("The bookmarks were updated successfully");
                }
              }}
              handler={handler}
            />
          ) : (
            <h1>
              Dear user, the record you are trying to view, does not exists
            </h1>
          )}
        </div>
      </div>
    </TemplateWrapped>
  );
};

function StatusBadge({ notFilled }) {
  if (notFilled > 0) {
    return (
      <span className="badge badge-warning d-inline-block mr-1">
        {notFilled}
      </span>
    );
  }

  return (
    <span
      style={{ lineHeight: "21px" }}
      className="badge badge-success d-inline-block mr-1"
    >
      <FontAwesomeIcon icon="check" />
    </span>
  );
}

function SideNav({ data }) {
  return (
    <Nav variant="pills" className="flex-column itmesColOptions h-100">
      {data.map(({ title, content }, index) => {
        const notFilled = countEmptyFields(content);
        return (
          <Nav.Item key={index}>
            <Nav.Link eventKey={index}>
              <strong>{title}</strong>
            </Nav.Link>
            <StatusBadge notFilled={notFilled} />
          </Nav.Item>
        );
      })}
    </Nav>
  );
}

function Content({ data, handler, handleSubmit }) {
  function formatToSections(formFields) {
    const sections = formFields.reduce(
      (a, i, index) => {
        const { props, control } = i;

        function appendSectionToResult() {
          // Saving all the data from previous section if exists
          if (a.currentSection) {
            const missingFields = countEmptyFields(a.currentSection.items);
            if (missingFields) {
              a.currentSection.name += ` (${missingFields})`;
            }
            a.sections.push(a.currentSection);
          }
        }

        function createSection(name, firstChild = false) {
          appendSectionToResult();
          a.currentSection = {
            name,
            items: firstChild ? [firstChild] : [],
          };
        }

        // Initial Load
        if (index === 0) {
          const isTitle = control === SubsectionTitle;

          const firstChild = isTitle
            ? false
            : { ...i, itemIndex: a.itemIndex++ };

          createSection(isTitle ? props.label : "-", firstChild);
          return a;
        }

        // Found a new subSection
        if (control === SubsectionTitle) {
          createSection(props.label);
          return a;
        }

        // A regular Control
        a.currentSection.items.push({ ...i, itemIndex: a.itemIndex++ });

        // The end of the list

        if (index === formFields.length - 1) {
          appendSectionToResult();
        }

        return a;
      },
      {
        currentSection: null,
        sections: [],
        itemIndex: 0,
      }
    );

    return sections.sections;
  }

  const { content } = data[0];

  const accordion = formatToSections(content);

  const formProps = {
    showInColumns: true,
    // numberColums: accordion.length === 1 ? 2 : 4,
    fields: accordion.length === 1 ? accordion[0]["items"] : accordion,
    asAccordion: accordion.length > 1,
    handleChangeByIndex: (controlIndex) => {
      return handler(0, controlIndex);
    },
  };

  return (
    <div className="p-3">
      <DynamicForm
        disabled={false}
        onSubmit={() => {
          handleSubmit(content);
        }}
        {...formProps}
        numberColums={3}
      />
    </div>
  );
}

function TabContainer({ data, handleSubmit, handler, disabled }) {
  return (
    <div id="transaction-plan-detail">
      <Content
        data={data}
        handler={handler}
        disabled={disabled}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default TransactionPlanDetail;
