import styled from "styled-components";

export const TransactionAirCallContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 40rem;
    margin-bottom: 2rem;
    align-items: center;
    flex-wrap: wrap;
    // position: relative;
`

export const TransactionAirCallCarriers=styled.div`
  flex: 1;
`

export const TransactionAirCallPhone=styled.div`
  flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PhoneNumberButton = styled.button`
  font-weight: bold;
    background-color: transparent;
`

export const PhoneWrapper = styled.div`
    transform: translate(218px, 121px);
    background-color: rgb(255, 255, 255);
    cursor: grab;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    display: ${(props) => (props.isVisible ? "block" : "none")};
    
    #phone > iframe {
        padding: 2rem 1rem 1rem;
    }
`

export const PhoneCloseButton = styled.button`
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: transparent;
`
