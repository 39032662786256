const CustomPanel = (props) => {
  return(
    <div className='panel' style={{boxShadow: '0 1px 1px rgb(255 255 255 / 5%)'}}>
      <div className='panel-body'>
        {props.children}
      </div>
    </div>
  )
}

export default CustomPanel