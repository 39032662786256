import styled from "styled-components"
import {useContext} from "react";
import shareBookmarksContext from "../context/ShareBookmarksContext";

const StyledCategory = styled.div`
  margin-bottom: 2rem`

const CategoryName = styled.h4`
  border-bottom: solid 1px #dedede;
  padding-bottom: 15px;
  margin: 15px 0;
  font-size: 1.2rem;
  color: #0080c2 !important;
  font-weight: unset !important;
`

const StyledCategoryContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
`

const Label = styled.label`
  display: block;
  font-size: ${({isSubsection = false}) => isSubsection ? '1.2rem' : '1rem'};
  color: ${({isCheckbox = false}) => isCheckbox ? '#5c5c5c' : '#919191'};
  font-weight: ${({isCheckbox = false}) => isCheckbox ? '500' : 'normal'};
  margin-top: ${({isSubsection = false}) => isSubsection ? '.625rem' : '0'};
  margin-bottom: ${({isSubsection = false}) => isSubsection ? '.625rem' : '0'};
  cursor: pointer;
`

const ElementContent = styled.div`
  width: ${({isSubsection = false, isCheckbox = false}) => isSubsection || isCheckbox ? '100%' : '33.33%'};
  margin-bottom: ${({isSubsection = false}) => !isSubsection ? '.625rem' : '0'};
  margin-left: ${({isSubsection = false}) => !isSubsection ? '.625rem' : '0'};
`

const ElementChild = styled.div`
  flex-basis: 33.33%;
  font-weight: unset;
  font-size: 1rem;
  color: #919191;
  &:last-child {
    margin-bottom: 1rem;
  }
`

const Category = ({categories}) => {
  return (
    <>
      {categories.map((category, index) => {
        return (
          <StyledCategory key={index}>
            <CategoryName>
              {category.categoryName}
            </CategoryName>
            <CategoryContent
              items={category.items}
              categoryId={category._id}
            />
          </StyledCategory>
        )
      })}
    </>
  )
}

const CategoryContent = ({items, categoryId}) => {
  return (
    <StyledCategoryContent>
      {items.map((item, index) => {
        return item.bookmark ?
          <BookmarkText
            key={item.bookmark}
            item={item}
            categoryId={categoryId}
          /> :
          <BookmarkCheck
            key={`${item.label}${index}`}
            item={item}
            categoryId={categoryId}
          />
      })}
    </StyledCategoryContent>
  )
}

const BookmarkText = ({item, categoryId}) => {
  return (
    <BookmarkLabel
      text={item.bookmark || item.label}
      id={item._id}
      categoryId={categoryId}
    />
  )
}

const BookmarkCheck = ({item, categoryId, isChild = false}) => {
  const items = item.elementType === 'checkbox'
    ? item.options
    : item.items;
  return (
    <>
      <BookmarkLabel
        text={item.label}
        id={item._id}
        categoryId={categoryId}
        isCheckbox={true}
        isSubsection={item.elementType === 'subsection'}
      >
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start'
        }}>
          {items.map((i, ind) => {
            if (item.elementType === 'subsection') {
              return i.bookmark ? <BookmarkText key={ind} item={i} categoryId={categoryId} />
                : <BookmarkCheck key={ind} item={i} categoryId={categoryId} />
            } else{
              const label = i.bookmark ? i.bookmark : i.label
              return <ElementChild key={ind}>{label}</ElementChild>
            }
          })}
        </div>
      </BookmarkLabel>
    </>
  )
}

const BookmarkLabel = (
  {
    text,
    id,
    categoryId,
    isCheckbox,
    isSubsection = false,
    ...props
  }
) => {
  const {handleOnChange, sharedElements} = useContext(shareBookmarksContext)
  const isShared = sharedElements.includes(id)

  return (
    <ElementContent
      isCheckbox={isCheckbox}
      isSubsection={isSubsection}
    >
      <Label
        htmlFor={`${categoryId}${id}`}
        isCheckbox={isCheckbox}
        isSubsection={isSubsection}
      >
        {text}
        {!isSubsection && (
          <input
            checked={isShared}
            className='ml-3'
            type="checkbox"
            name={`${categoryId}${id}`}
            id={`${categoryId}${id}`}
            onChange={e => handleOnChange(e.target.checked, id)}
          />
        )}
      </Label>
      <div style={{
        marginLeft: !isSubsection ? '1rem' : '0',
      }}>
        {props.children}
      </div>
    </ElementContent>
  )
}

export default Category