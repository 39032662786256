import {getDateAsString} from "../../../../../../util/date";

const useExtractionLogFilter = (setFilters) => {
  const handleSubmit = ({sheetDate}) => {
    const date = getDateAsString(
      sheetDate,
      'YYYY-MM-DD',
      'YYYY-MM-DD')
    setFilters(date)
  }

  return {
    handleSubmit
  }
}

export default useExtractionLogFilter