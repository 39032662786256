import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import CustomModal from "../../../CustomModal";
import { TemplateContext } from "../TemplateContext";
import BookmarkForm from "./BookmarkForm";

export default function SearchBookmarks({ selectedCategory }) {
  const { associateBookmarks, searchBookmark, createBookmark } =
    useContext(TemplateContext);
  const [selectedValues, setSelectedValues] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const existingBookmarks = useMemo(() => {
    if (selectedCategory && selectedCategory.bookmarks) {
      return selectedCategory.bookmarks;
    }
    return [];
  }, [selectedCategory]);

  const DropdownIndicator = ({ ColourOptions, ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon="search" />
      </components.DropdownIndicator>
    );
  };

  const handleInputChange = (newValue) => {
    setSelectedValues(newValue);
    if (newValue.length === 0) {
      setCurrentData([]);
    }
  };

  const loadOptions = (inputValue, callback) => {
    searchBookmark(inputValue).then((data) => callback(data));
  };

  return (
    <>
      <h4 className="mb-2">Bookmarks</h4>
      <div className="mb-3">
        <button
          className="d-inline-block btn btn-link btn-sm p-0"
          onClick={(ev) => {
            ev.stopPropagation();
            if (setShowModal(true));
          }}
        >
          Create a new bookmark
        </button>
      </div>
      <div className="input-group">
        <AsyncSelect
          className="flex-grow-1"
          placeholder={
            !selectedCategory
              ? "Dear user, to associate bookmarks please select a category or subcategory"
              : `Associate Bookmarks to: ${selectedCategory.name}`
          }
          components={{ DropdownIndicator }}
          isDisabled={!selectedCategory}
          value={selectedValues}
          closeMenuOnSelect={false}
          onChange={handleInputChange}
          getOptionLabel={(e) => (
            <div>
              <p className="mb-0">{e.label}</p>
              <small className="text-muted">{e.bookmark}</small>
            </div>
          )}
          getOptionValue={(e) => e.id}
          defaultOptions={currentData}
          isMulti
          loadOptions={(inputValue, callback) => {
            loadOptions(inputValue, (result) => {
              callback(result);
              setCurrentData(result);
            });
          }}
        />

        {selectedValues.length > 0 && (
          <div className="input-group-prepend" style={{ zIndex: "0" }}>
            <Button
              disabled={saving}
              variant="link"
              onClick={async (ev) => {
                //TODO {frank} validar existentes.
                ev.stopPropagation();
                setSaving(true);
                const wasSuccess = await associateBookmarks(
                  selectedCategory,
                  selectedValues.map((x) => x._id),
                  existingBookmarks
                );
                setSaving(false);

                if (wasSuccess) {
                  setSelectedValues([]);
                  setCurrentData([]);
                }
              }}
            >
              Associate Bookmarks{" "}
              {!saving ? (
                <FontAwesomeIcon icon="check" />
              ) : (
                <FontAwesomeIcon icon="spinner" spin />
              )}
            </Button>
          </div>
        )}
      </div>
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={"Adding new bookmark"}
      >
        <BookmarkForm
          onSave={async (bookmarkData, bookmarkType) => {
            // console.log(bookmarkData, bookmarkType);
            const r = await createBookmark(bookmarkType, bookmarkData);
            if (r) {
              setShowModal(false);
            }
          }}
        />
      </CustomModal>
    </>
  );
}
