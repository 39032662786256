import { useState } from "react";
import * as service from "../services";

const useTemplateForm = (initialData, onSave) => {
  const [saving, setSaving] = useState(false);

  const save = async (name, description) => {
    setSaving(true);
    const [status, res] = !initialData._id
      ? await service.store(name, description)
      : await service.duplicate(initialData._id, description, name);

    setSaving(false);

    if (status) {
      onSave();
    }
  };

  return { saving, save };
};

export default useTemplateForm;
