import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../../../App";
import { getLoggedInData } from "../../../util/auth";
import {
  getUrlParams,
  removeUrlParams,
  setURLParams,
} from "../../../util/browser";
import {
  defaultFormat,
  getDateAsString,
  getInitialAndEndDate,
} from "../../../util/date";
import { withoutEmptyKeys } from "../../../util/object";

// Hooks
const initialState = {
  carrier: "",
  startDate: "",
  endDate: "",
  statusPatient: "",
};
const urlKeys = Object.keys(initialState);

export function useIvFilterNew() {
  const [filter, _setFilter] = useState({
    ...initialState,
    ...getUrlParams(Object.keys(initialState)),
  });

  function setFilter(data) {
    const f = withoutEmptyKeys(data);
    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }
    _setFilter(
      withoutEmptyKeys({
        ...f,
        startDate: getDateAsString(f.startDate),
        endDate: getDateAsString(f.endDate),
      })
    );
  }

  return [filter, setFilter];
}

export function useIvFilter(setFilters, initialData) {
  // Component's
  const [data, _setData] = useState({
    ...initialState,
    ...((d) => {
      const state = {
        ...d,
        startDate: d.startDate && moment(d.startDate, defaultFormat).toDate(),
        endDate: d.endDate && moment(d.endDate, defaultFormat).toDate(),
      };
      return state;
    })(initialData),
  });

  function setData(name, value) {
    if (name === "startDate") {
      const [startDate, endDate] = getInitialAndEndDate(value, data.endDate);
      _setData({ ...data, startDate, endDate });
      return;
    }

    if (name === "endDate") {
      const [startDate, endDate] = getInitialAndEndDate(data.startDate, value);
      _setData({ ...data, startDate, endDate });
      return;
    }

    _setData({ ...data, [name]: value });
  }

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilters(data);
  };

  const handleClear = () => {
    _setData(initialState);
    setFilters(initialState);
  };

  return {
    data,
    setData,
    handleFilterSubmit,
    handleClear,
  };
}

// Components

export default function IvMissingCodeFilter({ setFilters, initialData }) {
  const loading = false;
  const {
    data: { carrier, startDate, endDate, statusPatient },
    setData,
    handleFilterSubmit,
    handleClear,
  } = useIvFilter(setFilters, initialData);

  return (
    <Form>
      <Form.Group className="m-2">
        <Form.Label>Carrier Name</Form.Label>
        <Form.Control
          id="carrier"
          type="text"
          value={carrier}
          onChange={({ target: { value } }) => {
            setData("carrier", value);
          }}
          placeholder="Carrier Name"
        />
      </Form.Group>
      <Form.Group className="m-2">
        <Form.Label>Eligibility Status</Form.Label>
        <Form.Control
          id="statusPatient"
          type="text"
          value={statusPatient}
          onChange={({ target: { value } }) => {
            setData("statusPatient", value);
          }}
          placeholder="Eligibility Status"
        />
      </Form.Group>

      <Form.Group className="m-2">
        <Form.Label>Start Date</Form.Label>
        <InputGroup className="flex-nowrap">
          <DatePicker
            className={`form-control custom-form-control `}
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            placeholderText="MM/DD/YYYY"
            name="startDate"
            id="startDate"
            showMonthDropdown
            showYearDropdown
            todayButton="Today"
            selected={startDate}
            onChange={(d) => {
              setData("startDate", d);
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>

      <Form.Group className="m-2">
        <Form.Label>End date</Form.Label>
        <InputGroup className="flex-nowrap">
          <DatePicker
            className={`form-control custom-form-control `}
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            placeholderText="MM/DD/YYYY"
            name="endDate"
            id="endDate"
            showMonthDropdown
            showYearDropdown
            todayButton="Today"
            selected={endDate}
            onChange={(d) => {
              setData("endDate", d);
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>

      <Form.Group className="m-2 text-center">
        <Button
          className="mt-3"
          variant="primary"
          type="submit"
          disabled={loading}
          onClick={handleFilterSubmit}
        >
          Apply filters
        </Button>

        <Button
          disabled={loading}
          className="mt-3 ml-1"
          variant="warning"
          type="button"
          onClick={handleClear}
        >
          Reset
        </Button>
      </Form.Group>
    </Form>
  );
}
