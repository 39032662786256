import {Accordion} from "react-bootstrap";
import PermissionListItem from "./PermissionListItem";

const PermissionsList = ({list, type, level = []}) => {
  return(
    <Accordion>
      {
        list.length > 0 ?
          (
            list.map((item, index) => (
              <PermissionListItem
                key={item._id}
                level={[...level]}
                index={index}
                type={type}
                element={item}
              />
            ))
          ) :
          null
      }
    </Accordion>
  )
}

export default PermissionsList