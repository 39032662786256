import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  BasicForm,
  FormSelect as Select,
} from "../../../../components/formControls";
import * as middlewares from "../middlewares";
import { getBookmarkType, getFieldsForm } from "../services";

export const removeBookmark = (
  btn,
  selectedBookmark,
  selectedCategory,
  selectedSubCategory
) => {
  let currentBtn = { ...selectedBookmark };
  if (selectedSubCategory) {
    currentBtn[selectedCategory][selectedSubCategory].bookmarks.forEach(
      (b, index) => {
        if (b.id === btn.id) {
          b.added = btn.added;
          currentBtn[selectedCategory][selectedSubCategory].bookmarks.splice(
            index,
            1
          );
        }
      }
    );
  } else if (selectedCategory) {
    currentBtn[selectedCategory].bookmarks.forEach((b, index) => {
      if (b.id === btn.id) {
        b.added = btn.added;
        currentBtn[selectedCategory].bookmarks.splice(index, 1);
      }
    });
  }
  return currentBtn;
};

export const formatBookmarkData = (inputsForm) => {
  let params = {};

  for (const input of inputsForm) {
    if (input.props.value === "" && !input.props.items) {
      throw "Empty fields";
    }
    Object.assign(params, { [input.props.id]: input.props.value });

    if (input.props.items) {
      params["options"] = [];
      params["type"] = "object";
      Object.keys(input.props.values).forEach((k) => {
        params["options"].push({
          bookmark: input.props.values[k].bookmark,
          label: input.props.values[k].label,
          type: "boolean",
        });
      });
    }
  }
  return { bookmark: params };
};

export default function BookmarkForm({ data, onSave }) {
  const [bookmarkType, setBookmarkType] = useState();
  const [modalFields, setModalFields] = useState([]);
  const [loadingModalFields, setLoadingModalFields] = useState(false);
  const [selectedBookmarkType, setSelectedBookmarkType] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    try {
      getBookmarkType().then((b) => {
        setBookmarkType(formatDataToOption(b));
        setLoaded(true);
      });
    } catch (ex) {
      //TODO: ADD notification
    }
  }, []);

  useEffect(() => {
    if (data.elementType) {
      const selected =
        data.elementType === "checkbox" ? "check" : data.elementType;
      handleChangeBookmarkType(selected);
    }
  }, [data]);

  const formatDataToOption = (data) => {
    let options = [];
    data.forEach((t) => {
      options.push({ bookmark: t, label: t });
    });
    return options;
  };

  const handleChangeBookmarkType = (bmType) => {
    setLoadingModalFields(true);
    setSelectedBookmarkType(bmType);
    getFieldsForm(bmType)
      .then(([status, resp]) => {
        const { fieldStates = {} } = data;
        const f = middlewares.formatFormFields(bmType, resp);
        const controls = f.map((x) =>
          middlewares.bmToControl(x, data, fieldStates)
        );
        setModalFields(controls);
      })
      .catch((ex) => {
        setModalFields([]);
      })
      .finally(() => {
        setLoadingModalFields(false);
      });
  };

  const handleSubmitBookmark = (inputsForm) => {
    let params = {};
    try {
      params = formatBookmarkData(inputsForm);
    } catch (e) {
      return;
    }

    let t =
      selectedBookmarkType === "check" ? "checkbox" : selectedBookmarkType;

    onSave(params.bookmark, t);
  };

  if (!loaded)
    return (
      <p className="small text-muted">
        Loading, please wait <FontAwesomeIcon icon="spinner" spin />
      </p>
    );

  return (
    <>
      <Select
        id="slBookmarkType"
        disableFirstOption
        label="Bookmark type:"
        options={bookmarkType}
        disabled={typeof data._id !== "undefined"}
        value={selectedBookmarkType}
        onChange={({ target: { value } }) => {
          handleChangeBookmarkType(value);
        }}
      />
      {loadingModalFields && (
        <p className="small text-muted">
          Loading Bookmark data, please wait{" "}
          <FontAwesomeIcon icon="spinner" spin />
        </p>
      )}
      {modalFields.length > 0 && (
        <BasicForm
          fields={modalFields}
          onSubmit={(e) => handleSubmitBookmark(e)}
        />
      )}
    </>
  );
}
