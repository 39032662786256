import MaskedInput from "react-text-mask";
export default function PhoneNumber({
  value = "",
  className = "form-control",
  onChange = () => {},
  ...props
}) {
  return (
    <MaskedInput
      className={className}
      {...props}
      guide={true}
      placeholder="+1(___)___-____"
      mask={[
        "+",
        /\d/,
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      value={value}
      onChange={({ target }) => {
        onChange(target.value.replace(/\D/g, ""));
      }}
    />
  );
}
