import React from "react";
import {
  SectionContainer,
  SectionTitle,
  SubSectionTitle,
  BookmarksContainer,
  Bookmark,
  BookmarkTitle,
  BookmarkValue,
  SubSection,
  SubSectionsContainer,
  FBDPreviewContainer,
} from "./TemplatePreview.styles";

const BookmarkList = ({ bookmarks }) => (
  <BookmarksContainer>
    {bookmarks.map((b) => (
      <Bookmark key={b.bookmarkTitle}>
        <BookmarkTitle>{b.bookmarkTitle}</BookmarkTitle>
        <BookmarkValue>{b.bookmarkValue}</BookmarkValue>
      </Bookmark>
    ))}
  </BookmarksContainer>
);

const SubSectionList = ({ subSections }) => (
  <SubSectionsContainer>
    {subSections.map((sc) => (
      <SubSection key={sc.subSectionTitle}>
        <SubSectionTitle>{sc.subSectionTitle}</SubSectionTitle>
        {sc.bookmarks.length ? <BookmarkList bookmarks={sc.bookmarks} /> : null}
      </SubSection>
    ))}
  </SubSectionsContainer>
);

const TemplatePreview = ({ data, asTemplate }) => {
  return (
    <FBDPreviewContainer asTemplate={asTemplate}>
      {data.map((d) => (
        <SectionContainer key={d.sectionTitle}>
          <SectionTitle>{d.sectionTitle}</SectionTitle>
          {d.bookmarks.length ? <BookmarkList bookmarks={d.bookmarks} /> : null}
          {d.subSections.length ? (
            <SubSectionList subSections={d.subSections} />
          ) : null}
        </SectionContainer>
      ))}
    </FBDPreviewContainer>
  );
};

export default TemplatePreview;
