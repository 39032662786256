import { Form } from "react-bootstrap";
import { useState } from "react";
import "../styles.css";

const FormSelect = ({
  style,
  disabled,
  className,
  disableFirstOption,
  ...props
}) => {
  const [value, setValue] = useState(props.value);

  const handleSelect = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  };
  return (
    <Form.Group style={style} className={className}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Form.Control
        disabled={disabled}
        as="select"
        className={`custom-form-control ${
          props.border && !value ? "red-border" : ""
        }`}
        name={props.id}
        id={props.id}
        defaultValue={value}
        required={props.required}
        onChange={handleSelect}
      >
        <option value="" disabled={disableFirstOption}>
          Please select an option
        </option>
        {props.options.map((op) => {
          return (
            <option key={op.bookmark} value={op.bookmark}>
              {op.label}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
};

export default FormSelect;
