export function formatData(d) {
  const fbd = d.filter((x) => x.type.toUpperCase() === "FBD").length;
  const elg = d.filter((x) => x.type.toUpperCase() === "ELG").length;

  const missing = d.filter(
    (x) => x.missingRequiredInputQty && x.missingRequiredInputQty > 0
  ).length;

  const noMissing = d.length - missing;

  const audited = d.filter((x) => x.audited).length;

  return {
    fbd,
    elg,
    missing,
    noMissing,
    audited,
  };
}
