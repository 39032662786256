import TableFiles from "./components/TableFiles";
import {getAzureFiles} from "./azureFiles.services";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const AzureFiles = () => {
  return (
    <TableFiles
      title={'Azure Files'}
      tableTitle={'Azure Files'}
      getFiles={getAzureFiles}
    />
  )
}

export default withSelectedPractices(AzureFiles)
