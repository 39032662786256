import { Navigate } from "react-router-dom";
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { AppContext } from "../App";
import TransactionsTable from "../components/helper/TransactionsTable";
import { get_GridRecordsWithMissingCode } from "../config/dataGraph";
import { getSpreadsheet, updateSpreadsheet } from "../services/missingCode";
import { displayModalMessage } from "../util/displayMessage";
import { withoutEmptyKeys } from "../util/object";
import CustomTable from "./CustomTable";
import { Loader } from "./formControls";
import IvMissingCodeFilter, {
  useIvFilterNew,
} from "./formControls/BasicForm/IvMissingCodeFilter";
import { TemplateWrapped } from "./Layout";
import { Presentational as Connections } from "./pages/Connections";
import { InfoModal } from "./pages/MissingCode/components/InfoModal";
import { HeaderFilterButton } from "./RemarkTemplate/Body";
import withSelectedPractices from "./HOCS/withSelectedPractices/withSelectedPractices";

const IvMissingCode = ({
  actions = {
    audit_transaction: false,
    edit_missingcode: false,
    list_missingcode: false,
    update_pms: false,
  },
}) => {
  const [modalData, setModalData] = useState(false);
  const [loadingEditData, setLoadingEditData] = useState(false);
  const [updatingSpreadsheet, setUpdatingSpreadsheet] = useState(false);
  const [filters, _setFilters] = useIvFilterNew();
  const [loaded, setLoaded] = useState(false);

  const ref = createRef(null);

  const appContext = useContext(AppContext);
  const practiceName = appContext.get("practiceName");
  const practiceIds = appContext.get("selectedPractices");


  function setFilters(f) {
    _setFilters(f);
  }

  const getRecords = ({ page, pageSize, _search, orderDirection: sort }) => {
    function response(records = [], quantity = 0) {
      return CustomTable.createTableResponse(records, page, quantity);
    }

    if (practiceIds.length === 0)
      return new Promise((resolve, _reject) => {
        resolve(response());
      });

    return get_GridRecordsWithMissingCode(pageSize, {
      ...filters,
      sort,
      practiceIds,
      offset: page ? pageSize * page : 0,
    }).then(([status, r]) => {
      if (!status) {
        return response([], 0);
      }
      return response(r.records || [], r.recordsQty || 0);
    });
  };

  useEffect(() => {
    if (ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [practiceIds]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
    if (ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [filters]);

  const loadEditData = useCallback((id,practiceId) => {
    setLoadingEditData(true);
    getSpreadsheet(id).then(([status, data]) => {
      if (status) {
        setLoadingEditData(false);
        setModalData({ _id: id, ...data ,practiceId:practiceId});
      }
    });
  }, []);

  function save(_data) {
    const current = ref.current;

    setUpdatingSpreadsheet(true);

    if (Object.entries(_data).filter(([_k, v]) => v === "").length > 0) {
      setUpdatingSpreadsheet(false);
      toast.error("Please complete all the fields");
      return;
    }

    updateSpreadsheet({
      ..._data,
      apptDateType: _data.ApptDate + "T00:00:00.000Z",
    }).then(([s, _res]) => {
      if (!s) {
        setUpdatingSpreadsheet(false);
        toast.error("The record was not found in the storage.");
        return;
      }

      current && current.onQueryChange();
      setModalData(false);
      setUpdatingSpreadsheet(false);
      displayModalMessage(
        "Success",
        "Data was successfully updated",
        "success"
      );
    });
  }

  if (!actions.list_missingcode) {
    return <Navigate to="/" />;
  }

  return (
    <TemplateWrapped
      title="Insurance Verification"
      headerButtons={
        <>
          <HeaderFilterButton
            active={
              filters && Object.keys(withoutEmptyKeys(filters)).length > 0
            }
            render={IvMissingCodeFilter}
            componentProps={{
              setFilters: setFilters,
              initialData: filters,
            }}
          />
        </>
      }
    >
      {practiceIds.length === 0 && (

        <div
          className="alert alert-alt alert-warning"
          role="alert"
          style={{
            padding: "20px 12px",
            fontSize: "14px",
            borderLeft: "3px solid",
          }}
        >
          Dear user, once you select a connection, the corresponding data will
          be displayed
        </div>
      )}
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              {practiceIds.length === 0 ? (
                <>
                  <div className="mb-5 pb-5">
                    <Connections title="Please select a connection" />
                  </div>
                </>
              ) : (
                loaded && (
                  <TransactionsTable
                    // key={shortid.generate()} // Force a re-render to avoid material's table bug
                    audit={actions.audit_transaction}
                    updatePMS={actions.update_pms}
                    fbd={actions.edit_missingcode}
                    practiceName={practiceName}
                    tableReference={ref}
                    showSpreadSheet={true}
                    asyncDataSource={getRecords}
                    loadEditData={loadEditData}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {<Loader active={loadingEditData} />}
      {modalData && (
        <InfoModal
          item={modalData}
          waiting={updatingSpreadsheet}
          showBox={true}
          editable={true}
          onSave={save}
          handleCloseModal={() => setModalData(false)}
          updateData={(name, value) => {
            setModalData({ ...modalData, [name]: value });
          }}
        />
      )}
    </TemplateWrapped>
  );
};

export default withSelectedPractices(IvMissingCode);
