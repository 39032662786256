import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import {
  BasicForm,
  FormInputText as Text,
  FormSelect as Select,
} from "../../../../components/formControls";
import BookmarkAffects from "./BookmarkAffects";

import UserHelp from "../../../util/UserHelp";
import { toast } from "react-toastify";

function buildControls(data) {
  return data.map(({ parameter, label, value, items }) => {
    const defaultValue =
      typeof value !== "undefined" && value !== null ? value.toString() : "";
    // Is a select
    if (typeof items !== "undefined" && items.length > 0) {
      return {
        control: Select,
        props: {
          key: parameter,
          id: parameter,
          value: defaultValue,
          label: label,
          disableFirstOption: true,
          options: items.map((x) => ({
            bookmark: x.toString(),
            label: x.toString(),
          })),
        },
      };
    }

    // Anything else is textbox
    return {
      control: Text,
      props: {
        key: parameter,
        id: parameter,
        value: defaultValue,
        label: label,
      },
    };
  });
}

function EditOptions({ initialItems, items, setCurrentItems }) {
  const [editingItems, setEditingItems] = useState(items.map((x) => false));

  function updateItem(i, itemProps) {
    const newItems = [...items];
    newItems[i] = { ...newItems[i], ...itemProps };
    setCurrentItems(newItems);
  }

  if (items.length === 0) return null;

  return (
    <>
      <label>Customize Options</label>
      <div className="mb-3">
        <UserHelp>
          Dear user, here you determine the visibility and the displayed label
          for every option that is in this bookmark <br />
          <strong>Note:</strong> Changes will only take effect when you press
          the "Save" button.
        </UserHelp>
      </div>
      {items.map(({ id, label, include }, i) => (
        <EditOptionPresentational
          key={i}
          id={id}
          label={label}
          setValue={(value) => {
            updateItem(i, { label: value });
          }}
          editing={editingItems[i]}
          include={include}
          toggleInclude={() => {
            const newState = !include;
            let updateProps = { include: newState };

            if (newState === false) {
              updateProps["label"] = initialItems[i]["label"];
              setEditingItems((prevEditing) => {
                prevEditing[i] = false;
                return [...prevEditing];
              });
            }
            updateItem(i, updateProps);
          }}
          toggleEditing={() => {
            const cancelEdit = editingItems[i] === true;
            if (cancelEdit) {
              updateItem(i, { label: initialItems[i]["label"] });
            }
            setEditingItems((prevEditing) => {
              prevEditing[i] = !editingItems[i];
              return [...prevEditing];
            });
          }}
        />
      ))}
    </>
  );
}

function EditOptionPresentational({
  id,
  label,
  editing = false,
  include = true,
  toggleInclude = () => {},
  setValue = () => {},
  toggleEditing = () => {},
}) {
  const ref = useRef();

  useEffect(() => {
    if (editing && ref.current) {
      ref.current.focus();
    }
  }, [editing, ref]);

  return (
    <div className="d-flex mb-3 align-items-center">
      <InputGroup className="flex-nowrap mr-3">
        <InputGroup.Prepend>
          <InputGroup.Text
            style={{
              minWidth: 150,
              textDecoration: !include ? "line-through" : "",
            }}
            className={
              "d-block " + (!include ? "bg-transparent border-0" : " ")
            }
          >
            {id}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          className={"h-auto " + (!include ? "bg-transparent border-0" : " ")}
          style={{
            textDecoration: !include ? "line-through" : "",
          }}
          ref={ref}
          onChange={({ target: { value } }) => {
            setValue(value);
          }}
          readOnly={!editing}
          value={label}
        />
        {include && (
          <InputGroup.Append>
            <InputGroup.Text>
              <Button type="button" variant="link p-0" onClick={toggleEditing}>
                <FontAwesomeIcon icon={!editing ? "edit" : "undo"} />
              </Button>
            </InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      <Form.Group className="mb-0">
        <Form.Label className="mb-0 d-flex justify-content-center align-bottom">
          <Form.Check
            inline
            className="mr-0"
            checked={include}
            onChange={() => {
              toggleInclude();
            }}
          />
          Include
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default function EditBookmark({
  bookmarkType,
  onSave,
  onSaveConditions,
  general: generalFields = [],
  conditions: conditionFields = [],
  affects,
}) {
  const [requiredFields, setRequiredFields] = useState([]);
  const [nonRequiredFields, setNonRequiredFields] = useState([]);
  const isCheckbox = bookmarkType === "checkbox" || bookmarkType === "multiple";

  const options = generalFields.filter((x) => x.label === "Options");

  const items =
    isCheckbox && options.length > 0 && options[0].items.length > 0
      ? options[0].items.map((x) => ({
          label: x.value,
          id: x.id,
          include: x.selected,
        }))
      : [];
  const [currentItems, setCurrentItems] = useState(items);

  useEffect(async () => {
    setRequiredFields(
      buildControls(generalFields.filter((x) => x.label !== "Options"))
    );
    setNonRequiredFields(buildControls(conditionFields));
  }, []);

  const handleSubmitBookmark = (formData) => {
    let d = formData;

    if (items.length > 0) {
      d["options"] = currentItems
        .filter((x) => x.include === true)
        .map((x) => ({ ...x, value: x.label }));

      if (d.options.length === 0) {
        toast.error("Please include at least one option for this bookmark");
        return;
      }
    }
    onSave(d, bookmarkType === "check" ? "checkbox" : bookmarkType);
  };

  return (
    <Tabs defaultActiveKey="general">
      <Tab
        eventKey="general"
        title="General"
        disabled={requiredFields.length === 0}
      >
        <div className="px-3 pt-4">
          {requiredFields.length > 0 && (
            <BasicForm
              fields={requiredFields}
              onSubmit={(e) => handleSubmitBookmark(e)}
              otherFields={
                isCheckbox && (
                  <EditOptions
                    setCurrentItems={setCurrentItems}
                    initialItems={items}
                    items={currentItems}
                  />
                )
              }
              submitAsDataObject={true}
            />
          )}
        </div>
      </Tab>
      <Tab eventKey="actions" title="Actions">
        <div className="px-3 pt-4">
          <BookmarkAffects {...affects} />
        </div>
      </Tab>
      {isCheckbox === false && (
        <Tab
          eventKey="optionals"
          title="Other fields"
          disabled={nonRequiredFields.length === 0}
        >
          <div className="px-3 pt-4">
            {nonRequiredFields.length > 0 && (
              <BasicForm
                fields={nonRequiredFields}
                submitAsDataObject={true}
                onSubmit={(e) => onSaveConditions(e)}
              />
            )}
          </div>
        </Tab>
      )}
    </Tabs>
  );
}
