import {statusAndResponse} from "../../../util/requestHandler";
import {Api} from "../../../config/Api";

export function generateReport(values) {
  function onSuccess(res) {
    return res;
  }
  return statusAndResponse(
    Api.post('/api/v2/iv/dashboard/report',{
      clientId: values.clientId,
      dateStart: values.startDate,
      dateEnd: values.endDate,
      type: 'cost',
      templateId: '67113f2f3b7e00009c002f22'
    },{
     responseType: 'arraybuffer',
      // headers: {'Content-Type': 'blob'},
  }), onSuccess)
}
