import styled from "styled-components";

export const ButtonBreadcrumbs = styled.button`
  background-color: #fff;

  &:hover {
    color: #0a9ebc;
  }

  .tooltip-inner {
    background-color: orangered !important;
  }
`

export const IconWrapper = styled.span`
  margin-right: 1rem;
  margin-left: 1rem;

  svg {
    polyline, path, line, circle, text, polygon {
      stroke: ${({iconColor}) => iconColor};
      fill: none;
    }

    width: ${({iconWith = '1rem'}) => iconWith};
    height: ${({iconWith = '1rem'}) => iconWith};
  }
`

export const FilterItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PropertyContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .85rem;
  color: #ccc;
  font-weight: 300;
`

export const PropertyName = styled.span``

export const PropertyValue = styled.span`
  margin-top: -5px;
`

export const FiltersWrapped = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Breadcrumbs = styled.ol`
  margin: .5rem 0 0 1rem;
  background-color: #fff;
  padding: 0;
  
  .breadcrumb-item {
    &:before{
      padding: 0;
    }
  }
`