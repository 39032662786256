function fillEmptyFieldsOnLevel(level, permission) {
  const defaultValues = {
    name: "",
    permission,
    action: "",
    submenus: [],
    path: "",
  };
  return { ...defaultValues, ...level };
}

export function getVisibleLevels(
  menu,
  currentPermissions = [],
  permissionName = ""
) {
  let newMenu = [];

  menu.forEach((m) => {
    let objSection = {
      label: m.label,
      submenus: [],
    };

    let tempItems = m.items || m.submenus;
    objSection.submenus = filterItems(
      tempItems,
      currentPermissions,
      permissionName
    );
    newMenu.push(objSection);
  });

  return newMenu;
}

function filterItems(menu, currentPermissions, permissionName) {
  let res = [];
  for (const l of menu) {
    let level = fillEmptyFieldsOnLevel(l, permissionName);
    const visibleSubLevels = filterItems(
      (level.items || []),
      currentPermissions,
      level.permission
    );
    const hasAccess = hasAccessOnLevel(level, currentPermissions);

    level.submenus = visibleSubLevels.filter((x) => x.showInMenu === true);
    level.path = hasAccess ? level.path : "";

    if (hasAccess || level.submenus.length > 0) {
      res.push(level);
    }
  }

  return res;
}

function hasAccessOnLevel(level, currentPermissions) {
  for (let i of currentPermissions) {
    if (level.permission === "" || i.name === level.permission) {
      if (!i.actions) {
        return true;
      }
      return (i.actions || []).map((x) => x.name);
    }
  }
}
