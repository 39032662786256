const InputCheckbox = ({ label, handleClick, isChecked, value }) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value={value}
        onChange={handleClick}
        checked={isChecked}
      />
      {label && <label className="form-check-label">{label}</label>}
    </div>
  );
};
export default InputCheckbox;
