import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { TemplateWrapped } from "../../Layout";
import InfoTable from "./components/InfoTable";
import Notes from "./components/Notes";
import TransactionFiles from "./components/TransactionFiles";
import "./css/index.css";
import SideInfo from "./components/SideInfo";
import useTransactionDetail from "./hooks/useTransactionDetail";
import MissingCodeTab from "../MissingCodeTab";
import UserHelp from "../../util/UserHelp";
import MemberPolicy from "./components/MemberPolicy";
import { displayModalConfirmation } from "../../../util/displayMessage";
import useNotes from "./hooks/useNotes";
import useFiles from "./hooks/useFiles";

const TransactionDetail = ({ permissions }) => {
  const {
    data,
    record,
    loadRecordData,
    updateSpreadsheet,
    audit,
    checkPatientInfo,
    refreshSpreadsheet,
    hasPolicy,
    overridePermissions,
    practiceName,
    id,
    selectedPractice,
    practiceId,
    addComment
  } = useTransactionDetail();

  const {aviableNotes, updateNotes} = useNotes(selectedPractice, id)
  const {files, filesAreVerified, verifyFiles} = useFiles(id, practiceId, loadRecordData, record)

  const [showSideInfo, _setShowSideInfo] = useState(true);
  const [reloadData, _setReloadData] = useState(0);
  const [key, setKey] = useState('patient-info');
  const setReloadData = () => {
    _setReloadData((r) => r + 1);
  };

  if (!id) {
    return <Navigate to='/allTransaction' />
  }

  let _permissions =  {
    missingCode: {},
    transaction: {},
    FBD: {
      updateFBD: false,
      uploadFBD: false,
    },
    patientInfo: {
      updatePatientInfo: false,
    },
    files: {
      updateElegibilityStatus: false,
    },
    notes: {
      updateNotes: false,
    },
    ...permissions,
  };
const {
  FBD = {},
  notes = {},
  files: fl = {},
  patientInfo = {},
  subscriberPolicy = {},
}= permissions;

  if (overridePermissions) {
    _permissions = {
      missingCode: {
        "list_missingcode": true,
        "update_pms": true,
        "audit_transaction": true,
        "edit_missingcode": true
      },
      transaction: {
        "list_transaction": true,
        "upload_elg": true,
        "audit": true
      },
      FBD: {
        updateFBD: true,
        uploadFBD: true,
      },
      patientInfo: {
        updatePatientInfo: true,
      },
      files: {
        "updateEligibilityStatus": true,
        "addFiles": true,
        "editFiles": true,
        "deleteFiles": true
      },
      notes: {
        updateNotes: true,
      },
    };
  }

  // Avoid unnecesary reloads
  const setShowSideInfo = (current) => {

    if (current === 'files' && !filesAreVerified) {
      displayModalConfirmation("Confirmation", "Dear user, if you have made any changes to  this verification, please save them before loading the transaction files.",
        () => {
          verifyFiles().then(s => {
            if (s) {
              setKey(current);
            }
          })
        })
    } else {
      setKey(current);
    }
    _setShowSideInfo((s) => {
      const r = current !== "fbd" && current !== "subsp";
      if (s !== r) {
        return !s;
      }
      return s;
    });
  };

  if (!data || !record) {
    return null;
  }

  return (
    <TemplateWrapped title="Transaction Detail" className="transaction-detail">
      <div className="row">
        {showSideInfo && (
          <SideInfo
            record={record}
            canAudit={_permissions.transaction.audit}
            canAddComments={_permissions.transaction.comment}
            audit={audit}
            practiceName={practiceName}
            addComment={addComment}
          />
        )}
        <div className={showSideInfo ? "col-lg-9" : "col"}>
          <div className="panel details">
            <div className="panel-body">
              {record.audited ? (
                <div className="mb-2">
                  <UserHelp>
                    Dear user, this record is audited, some features are
                    disabled
                  </UserHelp>
                </div>
              ) : null}
              <Tabs activeKey={key} onSelect={setShowSideInfo}>
                <Tab eventKey="patient-info" title="Patient Info">
                  <div className="info-table">
                    <InfoTable
                      hasPolicy={hasPolicy}
                      editable={!record.audited}
                      item={data}
                      onSave={updateSpreadsheet}
                      onRefresh={refreshSpreadsheet}
                      actions={_permissions}
                      showAirCall={_permissions?.airCall?.showPhone}
                    />
                  </div>
                </Tab>
                <Tab eventKey="files" title="Files">
                  {filesAreVerified && (
                    <TransactionFiles
                      data={{
                        id: record._id,
                        type: record.type,
                        patientStatus: record.iv_status,
                        carrierName: record.carrier,
                        patientName: record.patientName,
                      }}
                      files={files}
                      disabled={record.audited}
                      onSuccess={loadRecordData}
                      actions={_permissions}
                    />
                  )
                  }
                </Tab>
                {(aviableNotes.hasAviableNotes) ? (
                  <Tab eventKey="notes" title="Notes">
                    <Notes
                      aviable={aviableNotes.aviableNotes}
                      notes={record.apptNote}
                      history={record.history}
                      elgCodes={record.elgCodes}
                      apptData={record.apptData}
                      onSave={updateNotes}
                      updateNotes={_permissions.notes.updateNotes}
                      isAudited={record.audited}
                    />
                  </Tab>
                ) : null}
                {(record.type === "FBD") && (
                  <Tab className="pt-3" eventKey="fbd" title="FBD">
                    <MissingCodeTab
                      isAudited={record.audited}
                      generatingDoc={record.uploadFBD}
                      permissions={_permissions}
                      transactionId={record._id}
                      notifyChanges={loadRecordData}
                      beforeSave={checkPatientInfo}
                      handlePmsUpdate={audit}
                      reloadData={reloadData}
                    />
                  </Tab>
                )}
                {record.type === "FBD" && !record.audited && (
                  <Tab title="Subscriber policy" eventKey="subsp">
                    <MemberPolicy setReloadData={setReloadData} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
};

export default TransactionDetail;
