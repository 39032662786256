const SIZE = {
  home_width: '24px', home_height:'22px', homeFolded_width: '30px',homeFolded_height:'30px',
  insurance_width: '22px', insurance_height:'22px', insuranceFolded_width: '35px',insuranceFolded_height:'35px',
  dataQuality_width: '25px', dataQuality_height:'25px', dataQualityFolded_width: '36px',dataQualityFolded_height:'36px',
  settings_width: '23px', settings_height:'22px',settingsFolded_width: '35px',settingsFolded_height:'34px',
  template_width: '22px', template_height:'22px',templateFolded_width: '35px',templateFolded_height:'35px',
  users_width: '22px', users_height:'22px',usersFolded_width: '35px',usersFolded_height:'35px',
  connections_width: '22px', connections_height:'22px', connectionsFolded_width: '32px', connectionsFolded_height:'32px',
  tools_width: '22px', tools_height:'22px',toolsFolded_width: '34px', toolsFolded_height:'34px',
  reports_width: '22px', reports_height:'22px',reportsFolded_width: '30px', reportsFolded_height:'30px',
  files_width: '24px', files_height:'25px',filesFolded_width: '41px', filesFolded_height:'42px',
}

export default SIZE;