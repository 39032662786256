import { LocalPhone, Close } from "@material-ui/icons";
import Draggable from "react-draggable";
import useTransactionAirCall from "./useTransactionAirCall";
import {
  PhoneCloseButton,
  PhoneNumberButton, PhoneWrapper,
  TransactionAirCallCarriers,
  TransactionAirCallContainer,
  TransactionAirCallPhone
} from "./TransactionAirCall.styles";
import useAirCall from "./useAirCall";
import Select from "react-select";

const TransactionAirCall = ({show = false}) => {
  const { carriers, setIdCarrierSelected, selectedCarrier } = useTransactionAirCall();
  const phone = selectedCarrier?.profile?.phoneNumber;
  const { onPhoneCall, showPhone, setShowPhone } = useAirCall(phone, '#phone');

  if (!show) return null;

  return (
    <TransactionAirCallContainer>
      {carriers.length > 0 && (
        <>
          <TransactionAirCallCarriers>
            <Select
              options={carriers}
              onChange={(e) => {
                setShowPhone(false);
                setIdCarrierSelected(e.value);
              }}
              searchable
              defaultValue={carriers[0]}
            />
          </TransactionAirCallCarriers>
          <TransactionAirCallPhone>
            <PhoneNumberButton onClick={onPhoneCall}>
              <LocalPhone/>
              {phone}
            </PhoneNumberButton>
          </TransactionAirCallPhone>
        </>
      )}
        <Draggable>
          <PhoneWrapper isVisible={showPhone}>
            <PhoneCloseButton onClick={() => setShowPhone(false)} title="Close Phone">
              <Close />
            </PhoneCloseButton>
            <div id="phone" />
          </PhoneWrapper>
        </Draggable>
    </TransactionAirCallContainer>
  )
}

export default TransactionAirCall
