import React, { createRef, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import {
  getCarrierForm,
  getCarriers,
  getDataInsurranceVerification,
  registerNewIVerification,
} from "../../../config/insuranceverification";
import {
  displayModalConfirmation,
  displayModalMessage,
} from "../../../util/displayMessage";
import CustomTable from "../../CustomTable";
import {
  FormInputDate as Date,
  FormInputNumber as Number,
  FormInputText as Text,
  FormSelect as Select,
} from "../../formControls";
import useIvFilterNew from "./hooks/useIvFilterNew";

const useInsuranceVerification = () => {
  const [carrierList, setCarrierList] = useState([]);
  const [carrierId, setCarrierId] = useState();
  const [practiceSelect, setPracticeSelect] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [carrierForm, setCarrierForm] = useState([]);
  const [showModal, setShowModal] = useState({});
  const [filters, _setFilters] = useIvFilterNew();
  const ref = createRef(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const appContext = useContext(AppContext);
  const practiceId = appContext.get("practiceId");
  const practiceIds = appContext.get("selectedPractices");


  function setFilters(f) {
    setInitialLoad(false);
    _setFilters(f);
  }

  const getRecords = ({ page, pageSize, search }) => {
    return getDataInsurranceVerification(pageSize, {
      ...filters,
      practiceIds,
      // taken: 0,
      offset: page ? pageSize * page : 0,
    }).then(([status, r]) => {
      console.log({ r });

      return CustomTable.createTableResponse(
        r.records || [],
        page,
        r.total || 0
      );
    });
  };

  useEffect(() => {
    if (!initialLoad && ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [ref, filters, initialLoad]);

  useEffect(() => {
    getCarriers().then(([status, resp]) => {
      if (!status) {
        setCarrierList([]);
        return;
      }

      let options = [];
      for (const item of resp) {
        options.push({ bookmark: item.value, label: item.text });
      }

      setCarrierList(options);
    });
  }, []);

  const OpenForm = () => {
    handleShowModal("New Records");
  };

  const formatDataToOption = (data) => {
    let options = [];
    data.forEach((t) => {
      options.push({ bookmark: t.value, label: t.text });
    });
    return options;
  };

  const prepareData = (data) => {
    let fields = [];
    for (const element of data) {
      switch (element["type"] + element["subtype"]) {
        case "select":
          fields.push({
            control: Select,
            props: {
              key: element["name"],
              id: element["name"],
              value: "",
              label: element["label"],
              options: formatDataToOption(element["options"]),
              required: element["required"],
            },
          });
          break;
        case "inputtext":
          fields.push({
            control: Text,
            props: {
              key: element["name"],
              id: element["name"],
              value: "",
              label: element["label"],
              required: element["required"],
              maxLength: (element["cond"] || {}).maxlength || null,
              msg: element["placeholder"] || "",
            },
          });
          break;
        case "inputdate":
          fields.push({
            control: Date,
            props: {
              key: element["name"],
              id: element["name"],
              value: "",
              label: element["label"],
              required: element["required"],
              // maxDate: (element["cond"] || {}).max,
              // minDate: (element["cond"] || {}).min,
              msg: element["placeholder"] || "",
            },
          });
          break;
        case "inputnumber":
          fields.push({
            control: Number,
            props: {
              key: element["name"],
              label: element["label"],
              id: element["name"],
              value: "",
              symbol: "",
            },
          });
          break;
      }
    }
    return fields;
  };

  const OnChangeCarrier = (e) => {
    let fields = [];
    let id = e.target.value;
    if (id !== "") {
      setDisplayForm(false);
      setLoading(true);
      setCarrierId(id);

      getCarrierForm(id).then(([status, resp]) => {
        if (status) {
          fields = prepareData(resp.e);
          setCarrierForm(fields);
        }
        setDisplayForm(true);
        setLoading(false);
      });
    }
  };

  const handleSubmitModalForm = (fields) => {
    if (!carrierId) {
      displayModalMessage("Warning", "Please, select a carrier", "warning");
      return;
    }
    let carrier = {};
    for (const field of fields) {
      if (field.props.required && field.props.value === "") {
        displayModalMessage({
          icon: "warning",
          title: "Warning",
          text: "Please, fill all fields of the form",
        });
        return;
      }
      Object.assign(carrier, { [field.props.id]: field.props.value });
    }
    displayModalConfirmation(
      "Confirmation",
      <p>Please confirm save record?</p>,
      () => {
        const tmpCarrier = carrierList.find((x) => x.bookmark === carrierId);
        if (!carrierList) {
          return;
        }

        Object.assign(carrier, {
          carrierId: carrierId,
          practiceId: practiceSelect,
          carrierName: tmpCarrier.label,
        });
        registerNewIVerification(carrier).then(([status, resp]) => {
          if (status) {
            displayModalMessage("Changes are saved", "", "info");
            handleCloseModal();
          } else {
            displayModalMessage("Dear user, please try again", "", "error");
          }
        });
      }
    );
  };

  const handleCloseModal = () => {
    setCarrierForm([]);
    setCarrierId("");
    setDisplayForm(false);
    setShowModal({ status: false });
  };

  const handleShowModal = (title) =>
    setShowModal({ title: title, status: true });

  return {
    filters,
    setFilters,
    OpenForm,
    showModal,
    ref,
    handleCloseModal,
    getRecords,
    carrierList,
    OnChangeCarrier,
    displayForm,
    carrierForm,
    handleSubmitModalForm,
    carrierId,
    loading,
    setPracticeSelect
  };
};

export default useInsuranceVerification;
