import styled from "styled-components";

const CustomImg = ({img, alt = 'image', width, height}) => {
  return(
    <IconImg
      className='mr-3 site-menu-icon menu-icon'
      src={img}
      alt={alt}
      width={width}
      height={height}
    />
  )
}

export default CustomImg

const IconImg = styled.img`  
  width: ${props => props.width};
  height:  ${props => props.height};
  margin-right: 15px;
`