import styled from "styled-components";

export const SaveButton = styled("button")`
  background-color: #0080c0;
  color: #fff;
  padding: 0.35rem 1.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  &:disabled {
    background-color: #ccc;
    color: #888;
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    background-color: #006fa7 !important;
    transition: 0.5s;
  }
`;
