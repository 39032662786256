export const STATUSES = [
  { id: "Active", name: "Active" },
  { id: "Inactive", name: "Inactive" },
  { id: "Termed", name: "Termed" },
  { id: "Max Out", name: "Max Out" },
  { id: "Not Found", name: "Not Found" },
  { id: "Discount Plan", name: "Discount Plan" },
  { id: "Cancelled/rescheduled", name: "Cancelled/rescheduled" },
  { id: "Fax requested", name: "Fax requested" },
  { id: "Missing Information", name: "Missing Information" },
  { id: "EMPTY", name: "EMPTY" },
  { id: "Crown Delivery", name: "Crown Delivery" },
];
