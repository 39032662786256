import {ClientItem, ClientsList, ClientsSection} from "../styles/ConnectionsStyles";
import ScrollbarWrapper from "../../../../util/Styled/ScrollbarWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ClientsContainer = ({clients, clientIdSelected, handleClientSelected, clientId}) => {
  return (
    <ClientsSection>
      <h5>Client</h5>
      <ScrollbarWrapper padding='80' maxHeight = "90vh">
        <ClientsList>
          {clients.map((client) => {
            return (
              <ClientItem
                key={client.clientId}
                active={client.clientId === clientIdSelected}
                onClick={() => handleClientSelected(client)}
              >
                {client.clientName}
                {client.clientId === clientId && (<FontAwesomeIcon icon="check" />)}
              </ClientItem>
            );
          })}
        </ClientsList>
      </ScrollbarWrapper>
    </ClientsSection>
  );
}

export default ClientsContainer;
