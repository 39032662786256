import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import "../styles.css";

const FormInputDate = ({ disabled, style, className, ...props }) => {
  let dateDefault = props.value ? new Date(props.value) : null;
  const [startDate, setStartDate] = useState(dateDefault);

  const handleChange = (e) => {
    setStartDate(e);
    let val = e ? new Date(e).toLocaleDateString("en-US") : "";
    props.onChange({ name: props.id, target: { value: val } });
  };

  return (
    <Form.Group controlId={props.id} style={style} className={className}>
      <Form.Label>{props.label}</Form.Label>
      <InputGroup>
        <DatePicker
          className={`form-control custom-form-control ${
            props.border && !startDate ? "red-border" : ""
          }`}
          dateFormat="MM/dd/yyyy"
          autoComplete="off"
          placeholderText={props.msg}
          name={props.id}
          id={props.id}
          disabled={disabled}
          showMonthDropdown
          required={props.required}
          showYearDropdown
          todayButton="Hoy"
          selected={startDate}
          onChange={handleChange}
          maxDate={props.maxDate ? new Date(props.maxDate) : null}
          minDate={props.minDate ? new Date(props.minDate) : null}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      <Form.Text id="passwordHelpBlock" muted>
        i.e. 12/31/2021
      </Form.Text>
    </Form.Group>
  );
};

export default FormInputDate;
