import {useState} from "react";
import {withoutEmptyKeys} from "../../../../../../util/object";
import {getUrlParams, removeUrlParams, setURLParams} from "../../../../../../util/browser";

export const initialValues = {
  container: '',
  name: '',
  folder: '',
  createdStart: '',
  createdEnd: ''
}

const urlKeys = Object.keys(initialValues);

const useAzureFilesFilter = (setFilters) => {
  const [filters, _setFilters] = useState({
    ...initialValues,
    ...getUrlParams(Object.keys(initialValues)),
  });

  const handleSubmit = (data) => {
    const f = withoutEmptyKeys(data);

    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }

    _setFilters(data)
    updateFilters(data)
  }

  const updateFilters = (d) => {
    setFilters(withoutEmptyKeys(d));
  };

  const clear = () => {
    removeUrlParams(urlKeys);
    _setFilters(initialValues);
    updateFilters(initialValues);
  }

  return{
    filters,
    initialValues,
    handleSubmit,
    clear
  }
}

export default useAzureFilesFilter