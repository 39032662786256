import React, { useState } from "react";
import styled from "styled-components";
import { faCalendar, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import { InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { UpdateButton, RefreshButton } from "./TransactionDetailsControls";
import { policiesType } from "./policiestype";
import { STATUSES } from "./TransactionFiles/transactionfiles.constants";
import SaveButtonForm from "../../CallCenter/components/call-center-verification/components/save-button/SaveButtonForm";
import TransactionAirCall from "./TransactionAirCall/TransactionAirCall";

const PassWordWrapper = styled.div`
  position: relative;
`;

const EyeIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ccc;

  &:hover {
    color: #000;
  }
`;

function Input({
  name,
  editable,
  type,
  value = "",
  onChange,
  otherFormat,
  blocked,
  options = ["FBD", "ELG"],
  capitalize = true,
  maxLength,
  pattern,
}) {
  const [isPassword, setIsPassword] = useState(type === "password");

  if (type && type === Date) {
    let date = null;

    const validateFormat = otherFormat ? "yyyy-MM-DD" : "MM/DD/yyyy";
    const controlFormat = validateFormat.replace("DD", "dd");

    if (moment(value, validateFormat).isValid()) {
      date = moment(value, validateFormat).toDate();
    }

    let _value = moment(value, validateFormat).isValid()
      ? moment(value).format(validateFormat)
      : null;

    if (!editable) return _value;

    return (
      <InputGroup className="flex-nowrap">
        <ReactDatePicker
          className={`form-control custom-form-control `}
          dateFormat={controlFormat}
          autoComplete="off"
          placeholderText={controlFormat}
          name={name}
          id={name}
          showMonthDropdown
          required
          showYearDropdown
          disabled={blocked}
          selected={date}
          todayButton="Today"
          onChange={(d) => {
            onChange(name, moment(d).format(validateFormat));
          }}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }

  if (!editable) return value || "";

  if (type && type === "select") {
    return (
      <select
        required
        name={name}
        id={name}
        disabled={blocked}
        className="form-control"
        value={capitalize ? value.toUpperCase() : value}
        onChange={({ target }) => {
          onChange(name, target.value);
        }}
      >
        <option value="">Please select...</option>
        {options.map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </select>
    );
  }

  if (type && type === "password") {
    return (
      <PassWordWrapper>
        <input
          type={isPassword ? "password" : "text"}
          required
          name={name}
          id={name}
          disabled={blocked}
          className="form-control"
          value={value}
          onChange={({ target }) => {
            onChange(name, target.value);
          }}
        />
        <EyeIcon>
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => setIsPassword(!isPassword)}
          />
        </EyeIcon>
      </PassWordWrapper>
    );
  }

  return (
    <input
      maxLength={maxLength}
      pattern={pattern}
      type="text"
      required
      name={name}
      id={name}
      disabled={blocked}
      className="form-control"
      value={value}
      onChange={({ target }) => {
        onChange(name, target.value);
      }}
    />
  );
}

function ResumeItem({
  label,
  name,
  value,
  type,
  editable,
  onChange,
  otherFormat,
  blocked,
  options,
  maxLength = null,
  pattern = null,
  capitalize = true,
  ...props
}) {
  return (
    <div className={`item ${!editable ? "readonly" : ""}`}>
      <div className="title">{label}:</div>
      <div className="itemValue">
        <Input
          otherFormat={otherFormat}
          name={name}
          value={value}
          type={type}
          editable={editable}
          onChange={onChange}
          blocked={blocked}
          options={options}
          capitalize={capitalize}
          maxLength={maxLength}
          pattern={pattern}
        ></Input>
        {props.children}
      </div>
    </div>
  );
}

export default function InfoTable({
  editable = false,
  onSave = (_d) => console.log,
  onRefresh = (_d) => console.log,
  item,
  hasPolicy,
  actions,
  module_key = "TD",
  isLoadingButton = false,
  showAirCall = false
}) {
  const [data, _setData] = useState({ ...item, updateSpreadsheetDrive: false });
  const [alpha, setAlpha] = useState(false);
  const modulesForShowElegibility = ["CC"];
  const modulesForShowRefreshBt = ["TD"];
  const updateData = (fieldName, value) => {
    _setData((d) => ({ ...d, [fieldName]: value }));
  };

  const handleRefresh = () => {
    onRefresh(item, data);
  };

  return (
    <>
      <TransactionAirCall show={showAirCall} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave(item, {...data, statusPatient: data.iv_status});
        }}
      >
        <div className="row">
          <div className="col col-md-6">
            <ResumeItem
              options={policiesType}
              blocked={hasPolicy}
              label="Policy Type"
              name="policyType"
              value={data.policyType || ""}
              capitalize={false}
              type="select"
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
          </div>
          {" "}
          {modulesForShowElegibility.includes(module_key) && (
            <div className="col col-md-6">
              <ResumeItem
                options={STATUSES.map((item) => item.name)}
                blocked={false}
                label="Eligibility Status"
                name="iv_status"
                value={data.iv_status || ""}
                capitalize={false}
                type="select"
                editable={editable}
                onChange={(name, value) => {
                  updateData(name, value);
                }}
              />
            </div>
          )}
          <div className="col col-md-12">
            <hr/>
          </div>
          <div className="col col-md-6">
            <ResumeItem
              label={"Carrier Name"}
              name={"CarrierName"}
              value={data.CarrierName}
              editable={editable}
              maxLength={50}
              onChange={(name, value) => {
                if (/^[a-zA-Z0-9\s_-]*$/.test(value)) {
                  setAlpha(false);
                  updateData(name, value);
                } else {
                  setAlpha(true);
                }
              }}
            >
            <span className="text-danger">
              {data.CarrierName.length === 50 && "Maximum 50 characters"}
              {alpha && "You can only enter alphanumeric characters"}
            </span>
            </ResumeItem>
            <ResumeItem
              blocked={true}
              label={"Appointment"}
              name={"ApptDate"}
              value={data.ApptDate}
              type={Date}
              otherFormat={true}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Relationship"}
              name={"Relationship"}
              value={data.Relationship}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Patient Id"}
              name={"PatientId"}
              value={data.PatientId}
              blocked={true}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Patient First Name"}
              name={"PatientFirstName"}
              value={data.PatientFirstName}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Patient Last Name"}
              name={"PatientLastName"}
              value={data.PatientLastName}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Date of birth"}
              name={"PatientDOB"}
              value={data.PatientDOB}
              type={Date}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
          </div>
          <div className="col-md-6">
            <ResumeItem
              label={"Type"}
              name={"type"}
              value={data.type}
              type={"select"}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Employer Name"}
              name={"EmployerName"}
              value={data.EmployerName}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Member ID"}
              name={"MemberID"}
              value={data.MemberID}
              editable={editable}
              type="password"
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />

            <ResumeItem
              blocked={true}
              label={"Suscriber Id"}
              name={"SubscriberId"}
              value={data.SubscriberId}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />

            <ResumeItem
              label={"Suscriber First Name"}
              name={"SubscriberFirstName"}
              value={data.SubscriberFirstName}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
            <ResumeItem
              label={"Suscriber Last Name"}
              name={"SubscriberLastName"}
              value={data.SubscriberLastName}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />

            <ResumeItem
              label={"Suscriber DOB"}
              name={"SubscriberDOB"}
              value={data.SubscriberDOB}
              type={Date}
              editable={editable}
              onChange={(name, value) => {
                updateData(name, value);
              }}
            />
          </div>
        </div>
        {editable && actions.patientInfo.updatePatientInfo ? (
          <div className="row">
            <div className="col text-center">
              <hr className="pb-0"/>
              {modulesForShowElegibility.includes(module_key) ? (
                <div className="d-flex justify-content-center">
                  <SaveButtonForm
                    isLoadingButton={isLoadingButton}
                    label="Save"
                  />
                </div>
              ) : (
                <UpdateButton/>
              )}

              {modulesForShowRefreshBt.includes(module_key) && (
                <RefreshButton onClick={handleRefresh}/>
              )}
            </div>
          </div>
        ) : null}
      </form>
    </>
  );
}
