import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { TemplateContext } from "../TemplateContext";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";
import ReorderModal from "./ReorderModal";

const initialCategoryState = { adding: false, data: {} };

export default function CategoryManager({ data }) {
  const { addCategory, reorderCategory, setReorder } =
    useContext(TemplateContext);
  const [newCategory, setNewCategory] = useState(initialCategoryState);
  const [saving, setSaving] = useState(false);
  const nextOrder = data.length + 1;

  const getModalData = () => {
    return data ? data.map(({ id, name }) => ({ id, name })) : [];
  };

  return (
    <>
      <div className="d-flex mt-5 mb-3">
        <h4 className="m-0">Categories</h4>
      </div>
      <div className="d-flex">
        <button
          className="d-inline-block btn btn-link p-0 btn-sm"
          disabled={newCategory.adding}
          onClick={(ev) => {
            ev.stopPropagation();
            if (setNewCategory({ adding: true, data: {} }));
          }}
        >
          Add Category
        </button>
        <button
          className="d-inline-block btn btn-link p-0 btn-sm ml-3"
          disabled={newCategory.adding}
          onClick={() => {
            setReorder({
              title: "Categories",
              data: getModalData(),
              onReorderItem: (modifiedItem, index) => {
                reorderCategory(index, modifiedItem.id, null);
              },
            });
          }}
        >
          Arrange Categories
        </button>
      </div>

      {newCategory.adding && (
        <CreateCategory
          setNewCategory={setNewCategory}
          saving={saving}
          setSaving={setSaving}
          addCategory={addCategory}
          newCategory={newCategory}
          order={nextOrder}
        />
      )}
      <CategoryList className="mt-3" data={data} canCreateSubcategories />
    </>
  );
}
function CreateCategory({
  setNewCategory,
  saving,
  setSaving,
  addCategory,
  newCategory,
  order,
}) {
  return (
    <div className="mt-3">
      <div className="d-flex ">
        <div className="w-50 d-flex">
          <CategoryForm
            handler={(data) => {
              setNewCategory({ adding: true, data });
            }}
          />
        </div>
        <div className="d-flex ml-3">
          <Button
            disabled={saving}
            size="sm"
            variant="outline-action"
            className="mr-2"
            onClick={() => {
              setSaving(true);
              addCategory({ ...newCategory.data, order }).then((r) => {
                if (r) setNewCategory(initialCategoryState);
                setSaving(false);
              });
            }}
          >
            Save Category
          </Button>
          <Button
            disabled={saving}
            size="sm"
            variant="outline-danger"
            onClick={() => setNewCategory(initialCategoryState)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
