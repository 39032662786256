import {Button, Form} from "react-bootstrap";
import React from "react";
import useClientPracticeFilter from "./useClientPracticeFilter";
import {Formik} from "formik";
import Select from "react-select";

function ClientPracticeFilter({setFilters, setClientSelectId, clients}) {
  const {
    data,
    handleFilterSubmit,
    loadCity,
    city,
    handleReset,
    state,
  } = useClientPracticeFilter(setFilters, setClientSelectId);
  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={(values) => {
        handleFilterSubmit(values)
      }}
    >
      {
        props => (
          <form className="mt-2 p-1" onSubmit={props.handleSubmit} >
            <Form.Group className="mt-4">
              <Form.Label>Select Client</Form.Label>
              <Form.Control
                id="client"
                name="client"
                as="select"
                value={props.values.client}
                onChange={({target: {value}}) => {
                    props.setFieldValue('client', value)
                  }
                }
              >
                <option selected value="" disabled>
                  Please select an option
                </option>
                {
                  clients.map(c => {
                    return (<option key={c._id} value={c._id}>{c.name}</option>)
                  })}
                }
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                id="name"
                name="name"
                label="Name"
                value={props.values.name}
                onChange={props.handleChange}
              />
            </Form.Group>

            <Form.Group className="mt-4">
              <Form.Label>State</Form.Label>
              <Select options={state}
                      onChange={async ({label, value}) => {
                        //await loadCity(label)
                        props.setFieldValue('state', value)
                      }}
                      id="state"
                      name="state"
                      defaultValue={state.filter(
                        (temp) => temp.label === props.values.stateId
                      )}
              />
              {/*<Form.Control*/}
              {/*  id="state"*/}
              {/*  name="state"*/}
              {/*  as="select"*/}
              {/*  value={props.values.state}*/}
              {/*  onChange={ async ({target: {value}}) => {*/}
              {/*      await loadCity(value)*/}
              {/*      //setData("state", target.value);*/}
              {/*      props.setFieldValue('state', value)*/}
              {/*    }*/}
              {/*  }*/}
              {/*>*/}
              {/*  <option selected value="" disabled>*/}
              {/*    Please select an option*/}
              {/*  </option>*/}
              {/*  {*/}
              {/*    state.map((path, index) => {*/}
              {/*      return (*/}
              {/*        <option key={index} value={path.statusFull}>*/}
              {/*          {path.statusFull}*/}
              {/*        </option>*/}
              {/*      );*/}
              {/*    })*/}
              {/*  }*/}
              {/*</Form.Control>*/}
            </Form.Group>

            {/*<Form.Group className="mt-4">*/}
            {/*  <Form.Label>City</Form.Label>*/}
            {/*  <Select options={city}*/}
            {/*          onChange={({label, value}) => {*/}
            {/*            props.setFieldValue('cityId', value)*/}
            {/*            console.log(props.values)*/}
            {/*          }}*/}
            {/*          id="cityId"*/}
            {/*          name="cityId"*/}
            {/*          defaultValue={city.filter(*/}
            {/*            (temp) => temp.value === props.values.cityId*/}
            {/*          )}*/}
            {/*  />*/}
            {/*  /!*<Form.Control*!/*/}
            {/*  /!*  id="city"*!/*/}
            {/*  /!*  name="city"*!/*/}
            {/*  /!*  as="select"*!/*/}
            {/*  /!*  value={props.values.city}*!/*/}
            {/*  /!*  onChange={props.handleChange}*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*  <option value="" disabled>*!/*/}
            {/*  /!*    Please select an option*!/*/}
            {/*  /!*  </option>*!/*/}
            {/*  /!*  {*!/*/}
            {/*  /!*    city.map( (path, index) => {*!/*/}
            {/*  /!*      return (*!/*/}
            {/*  /!*        <option key={index} value={path.city}>*!/*/}
            {/*  /!*          {path.cityAlias}*!/*/}
            {/*  /!*        </option>*!/*/}
            {/*  /!*      );*!/*/}
            {/*  /!*    })*!/*/}
            {/*  /!*  }*!/*/}
            {/*  /!*</Form.Control>*!/*/}
            {/*</Form.Group>*/}

            <Button variant="primary" type="submit">
              Apply filters
            </Button>
            <Button
              //disabled={processing}
              className="ml-1"
              variant="warning"
              type="button"
              onClick={() => {
                handleReset(props.handleReset);
              }}
            >
              Reset
            </Button>
          </form>
        )
      }
    </Formik>
  )
}

export default ClientPracticeFilter