import React from "react";
import { Box } from "@material-ui/core";
import HeaderCallCenterVerification from "./components/header-callcenter-verification/HeaderCallCenterVerification";
import TabsCallCenterVerification from "./components/tabs-callcenter-verification/TabsCallCenterVerification";
import { useCallCenter } from "../../hooks/useCallCenter";

const CallCenterVerification = ({
  itemVerification,
  permissions,
  setAction,
}) => {
  const {
    record,
    data,
    hasPolicy,
    onUpdatePatienInfo,
    loadRecordData,
    audit,
    practiceInfoAndBookmarks,
    handleChangeVerification,
    handleSubmitVerification,
    formVerification,
    setDataDates,
    isLoadingButton,
    shouldUpdateVerification,
    isLoadingAuditButton,
    updateVerificationById,
    handleChangeCarrier,
    selectedPhone,
    selectedCarrier,
    carriers,
    isLoadingPhone,
    handleChangeBookmarks,
  } = useCallCenter(itemVerification, setAction);

  return (
    <Box>
      <HeaderCallCenterVerification
        itemVerification={itemVerification}
        audit={audit}
        practiceInfoAndBookmarks={practiceInfoAndBookmarks}
        isLoadingAuditButon={isLoadingAuditButton}
        selectedPhone={selectedPhone}
        handleChangeCarrier={handleChangeCarrier}
        selectedCarrier={selectedCarrier}
        carriers={carriers}
        isLoadingPhone={isLoadingPhone}
        record={record}
      />

      <TabsCallCenterVerification
        record={record}
        data={data}
        hasPolicy={hasPolicy}
        permissions={permissions}
        onUpdatePatienInfo={onUpdatePatienInfo}
        loadRecordData={loadRecordData}
        itemVerification={itemVerification}
        practiceInfoAndBookmarks={practiceInfoAndBookmarks}
        handleChangeVerification={handleChangeVerification}
        handleSubmitVerification={handleSubmitVerification}
        formVerification={formVerification}
        setDataDates={setDataDates}
        isLoadingButton={isLoadingButton}
        shouldUpdateVerification={shouldUpdateVerification}
        updateVerificationById={updateVerificationById}
        handleChangeBookmarks={handleChangeBookmarks}
      />
    </Box>
  );
};

export default CallCenterVerification;
