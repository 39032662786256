import {
  ChecksList,
  FormInputDate as DateInput,
  FormInputNumber as Number,
  FormInputText as Text, FormSelect as Select,
  SubsectionTitle
} from "../../formControls";
import shortid from "shortid";
import * as moment from "moment";
import * as ActionWrapper from "../MissingCode/components/ActionWrapper";

export function formatToSections(formFields) {
  const sections = formFields.reduce(
    (a, i, index) => {
      const { props, control } = i;

      function appendSectionToResult() {
        // Saving all the data from previous section if exists
        if (a.currentSection) {
          const missingFields = countEmptyFields(a.currentSection.items);
          if (missingFields) {
            a.currentSection.name += ` (${missingFields})`;
          }
          a.sections.push(a.currentSection);
        }
      }

      function createSection(name, firstChild = false) {
        appendSectionToResult();
        a.currentSection = {
          name,
          items: firstChild ? [firstChild] : [],
        };
      }

      // Initial Load
      if (index === 0) {
        const isTitle = control === SubsectionTitle;

        const firstChild = isTitle ? false : { ...i, itemIndex: a.itemIndex++ };

        createSection(isTitle ? props.label : "-", firstChild);
        return a;
      }

      // Found a new subSection
      if (control === SubsectionTitle) {
        createSection(props.label);
        return a;
      }

      // A regular Control
      a.currentSection.items.push({ ...i, itemIndex: a.itemIndex++ });

      // The end of the list

      if (index === formFields.length - 1) {
        appendSectionToResult();
      }

      return a;
    },
    {
      currentSection: null,
      sections: [],
      itemIndex: 0,
    }
  );

  return sections.sections;
}

export function countEmptyFields(data) {
  return data.reduce((a, i) => {
    const {
      control,
      props: { value, },
    } = i;

    //Ignore subTitles
    if (control === SubsectionTitle) return a;

    let invalid = false;

    if (i.props.required) {
      // Check if is a plain text or it has values that user has to pick
      invalid =
        typeof value === "undefined" || value === "-" || value.length === 0;
    }

    if (invalid) {
      a++;
    }
    return a;
  }, 0);
}

export const iterateEveryKey = (obj, callback) => {
  try {
    if (Array.isArray(obj)) {
      return obj.forEach((x) => iterateEveryKey(x, callback));
    }

    for (const [key, value] of Object.entries(obj)) {
      if (value instanceof Object) {
        if (key !== "affectsOn") {
          iterateEveryKey(value, callback);
        }
      }

      callback(obj, key, value);
    }
  } catch (err) {
    console.log(err);
  }
};

export const formattedData = (data) => {
  let sectionsForm = {};
  let currentSection = "";
  let affections = {};
  //
  iterateEveryKey(data, (currentObj, key) => {
    if (key !== "elementType") return;

    const { type, value, elementType, affectsOn, options, categoryName } =
      currentObj;

    let formattedValue = getBookmarkValue(value);
    let formattedType = getBookmarkType(type, elementType);

    if (formattedType === "section_info" || formattedType === "section_code") {
      currentSection = categoryName;
      sectionsForm[categoryName] = {
        props: {
          key: shortid.generate(),
          label: categoryName,
        },
        currentIndex: 0,
        children: [],
      };
      return;
    }

    if (affectsOn) {
      const affection = getBookmarkAffection(
        currentObj["_id"],
        affectsOn,
        options,
        formattedValue
      );

      // Append this parent to the affecters
      affections = { ...affections, ...affection };
    }

    const control = bookmarkToControl(
      currentObj,
      formattedType,
      formattedValue
    );

    sectionsForm[currentSection].children.push({
      ...control,
      index: sectionsForm[currentSection]["currentIndex"]++,
    });
  });

  // Once all the sections has been formed and the affections setted
  // Will subscribe every affected child to the corresponding affection
  // Format as array

  const formattedSections = Object.entries(sectionsForm).map(
    ([title, { children, _notFilled }], index) => {
      children.forEach((x, i) => {
        if (affections[x.affectedId]) {
          setAffectingBehavior(x, affections);
          affections[x.affectedId]["children"].push({
            parent: index,
            child: i,
          });
        }
      });
      return {
        title,
        content: children,
      };
    }
  );

  const formattedAffections = Object.entries(affections).reduce(
    (a, [_key, i]) => {
      a[i.affecter] = { ...i };
      return a;
    },
    {}
  );

  return { formattedSections, formattedAffections };
};

export function getBookmarkType(type, elementType) {
  return type
    ? (elementType || "").toLowerCase() + "_" + (type || "").toLowerCase()
    : (elementType || "").toLowerCase();
}

export function getBookmarkValue(value) {
  return (value === "-" || typeof value === "undefined" || value === null) ? "" : value;
}

export function getBookmarkAffection(affecter, affectsOn, options, formattedValue) {
  let action = affectsOn.actions[0];
  let affectedValue = affectsOn.whenValueContains[0];

  // currentValue === affectedValue
  return affectsOn.destination.reduce((a, i) => {
    a[i.id] = {
      affecter,
      affectWhen: affectedValue,
      type: action,
      children: [],
    };

    let initialValue;

    const isMultipleSelection = options && options.length > 0;
    if (isMultipleSelection) {
      const selected = options.filter((x) => x.value === "n");
      if (selected.length > 0)
        initialValue = selected[0]["bookmark"] === affectedValue;
    } else {
      initialValue = formattedValue === affectedValue;
    }
    a[i.id]["initialState"] = initialValue;

    return a;
  }, {});
}

export function bookmarkToControl(
  { _id, options, affectsOn, bookmark, label, required, code, cond, symbol },
  formattedType,
  formattedValue
) {
  const hasOptions = typeof options !== "undefined";
  const noOptionSelected =
    hasOptions && options.filter((o) => o.value === "n").length === 0;
  const hasValue = typeof formattedValue !== "undefined";
  const noValue = hasValue && formattedValue.length === 0;

  const elementProps = {
    id: bookmark,
    key: shortid.generate(),
    label: label,
    required: required || false,
    affectsOn: affectsOn || {},
    border:
      required && ((hasValue && noValue) || (hasOptions && noOptionSelected)),
  };

  let result = {};

  switch (formattedType) {
    case "subsection":
      result = subsection(code, label, elementProps)
      break;
    case "text_string":
      result = textString(_id, formattedValue, elementProps, cond);
      break;
    case "text_date":
      result = textDate(_id, elementProps, formattedValue);
      break;
    case "text_number":
      result = textNumber(_id, elementProps, formattedValue, cond, symbol);
      break;
    case "checkbox_object":
      let _bookmark = getSelectedBookmark(options);
      result = checkboxObject(_id, elementProps, _bookmark, options, cond);
      break;
    case "checkbox_multiobject":
    case "multiple_object":
      let bookmarkMulti = getSelectedBookmark(options);
      result = checkboxMultiObject(_id, elementProps, cond, options, bookmarkMulti);
      break;
  }
  return result;
}

const subsection = (code, label, elementProps) => {
  return {
    control: SubsectionTitle,
    props: {
      ...elementProps,
      label: code + " - " + label,
    },
  }
}

const textString = (_id, formattedValue, elementProps, cond) => {
  return {
    affectedId: _id,
    control: Text,
    props: {
      ...elementProps,
      value: formattedValue,
      useBlur: true,
      maxLength: (cond || {}).max || "",
    },
  }
}

const textDate = (_id, elementProps, formattedValue) => {
  return {
    affectedId: _id,
    control: DateInput,
    props: {
      ...elementProps,
      value: moment(formattedValue, "MM/DD/yyyy").isValid()
        ? formattedValue
        : "",
    },
  }
}

const textNumber = (_id, elementProps, formattedValue, cond, symbol) => {
  return {
    affectedId: _id,
    control: Number,
    props: {
      ...elementProps,
      value: formattedValue ? formattedValue : "",
      maxLength: (cond || {}).max || "",
      symbol: symbol !== "N/A" ? symbol : "",
      useBlur: true,
    },
  }
}

const checkboxObject = (_id, elementProps, bookmark, options, cond) => {
  return {
    affectedId: _id,
    control: Select,
    props: {
      ...elementProps,
      id: shortid.generate() + "_select",
      value: bookmark,
      options: options,
      cond: cond || {},
    },
  }
}

const checkboxMultiObject = (_id, elementProps, cond, options, bookmarkMulti) => {
  return {
    affectedId: _id,
    control: ChecksList,
    props: {
      ...elementProps,
      id: shortid.generate() + "check",
      value: bookmarkMulti,
      cond: cond || {},
      options: options,
    },
  }
}

export function setAffectingBehavior(child, affectsOn) {
  const affection = affectsOn[child.affectedId];
  let previousControl = child.control;
  const isInitiallyRequired = child.props.required;
  const { wrapper, required, border } = getAffectedControlState(
    affection.initialState,
    isInitiallyRequired,
    affection.type
  );

  child.control = wrapper;
  child.props = {
    ...child.props,
    control: previousControl,
    // subscribe: affection.subscribe, // Reactive behavior
    initialState: affection.initialState,
    required,
    border,
    isInitiallyRequired,
  };
}

export const getSelectedBookmark = (options) => {
  let bookmark = "";
  for (let option of options) {
    if (option.value && option.value !== "o") {
      bookmark = option.bookmark;
    }
  }
  return bookmark;
};

export function getAffectedControlState(value, initialyRequired, affectionType) {
  const lkTable = {
    hide: ActionWrapper.Hide,
    show: ActionWrapper.Show,
    enable: ActionWrapper.Enable,
    disable: ActionWrapper.Disable,
  };

  const wasInitiallyRequired =
    typeof initialyRequired !== "undefined" && initialyRequired === true;

  const isNotAviable =
    (affectionType === "hide" && value === true) ||
    (affectionType === "disable" && value === true) ||
    (affectionType === "show" && !value) ||
    (affectionType === "enable" && !value);

  let required, border;

  if (wasInitiallyRequired) {
    required = !isNotAviable;
    border = !isNotAviable;
  }

  return {
    wrapper: lkTable[affectionType],
    required,
    border,
  };
}

export const reOrderElements = (data) => {
  if (!data) return [];

  const cleanData = data.map((el, i) => ({ orderNumber: i, ...el }));

  return cleanData.sort((a, b) => a.orderNumber - b.orderNumber);
};
