import React from "react";
import { toast } from "react-toastify";
import shortid from "shortid";
import { downloadRealTimeFile } from "../../../services/missingCode";
import { getDateAsString } from "../../../util/date";
import * as files from "../../../util/file";
import CustomTable, { TextColumnHighlighted } from "../../CustomTable";
import ClientSelected from "../../pages/Connections/components/ClientSelected";

const TableInsuranceVerification = ({ asyncDataSource, tableReference }) => {
  const columns = [
    {
      title: "Patient Name",
      render: ({ firstNamePatient = "", lastNamePatient = "" }) => {
        return firstNamePatient + " " + lastNamePatient;
      },
    },
    {
      title: "Carrier",
      field: "carrierName",
    },
    { title: "IV Type", field: "verificationType" },
    {
      title: "Policy Holder",
      render: (rowData) => {
        return (
          rowData.firstNamePolicyholder + " " + rowData.lastNamePolicyholder
        );
      },
    },
    {
      title: "Relationship",
      field: "relationship",
    },
    {
      title: "Bot Status",
      render: ({ stateId }) => (
        <TextColumnHighlighted
          text={stateId}
          status={stateId === "pending" ? "warning" : "success"}
        />
      ),
    },
    {
      title: "Updated",
      render: ({ dateUpdate }) => {
        return getDateAsString(dateUpdate, "yyyy-MM-DD");
      },
    },
  ];

  const showInfo = (fileName) => {
    if (fileName !== undefined) {
      downloadRealTimeFile(fileName).then((response) => {
        files.download(response.data, fileName, () => {
          toast.warning("Sorry, but can't read the document.");
        });
      });
    } else toast.warning("Sorry, but can't read the document.");
  };

  return (
    <div>
      <CustomTable
        key={shortid.generate()}
        title={<ClientSelected />}
        columns={columns}
        tableReference={tableReference}
        asyncDataSource={asyncDataSource}
        actions={[
          (rowData) => {
            return rowData.stateId === "completed"
              ? {
                  icon: "file_download",
                  iconProps: {
                    color: "primary",
                  },
                  tooltip: "Download document",
                  onClick: (event, rowData) => showInfo(rowData.urlFile),
                }
              : null;
          },
        ]}
        search={false}
      />
    </div>
  );
};
export default React.memo(TableInsuranceVerification);
