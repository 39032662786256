import React from "react"
import {TemplateWrapped} from "../../Layout";
import useExtractionSchedule from "./hooks/useExtractionSchedule";
import {Presentational as Connections} from "../Connections";
import CustomTable, {TextColumnHighlighted} from "../../CustomTable";
import {HeaderButton} from "../../RemarkTemplate/Body";
import {faPlus} from "@fortawesome/fontawesome-free-solid";
import CustomModal from "../../CustomModal";
import ExtractionScheduleForm from "./components/ExtractionScheduleForm";
import {getDateAsString} from "../../../util/date";
import './extraction.css'
import ClientSelected from "../Connections/components/ClientSelected";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const ExtractionSchedule = (props) => {
  const {
    selectedPractices,
    extraction,
    showModal,
    setShowModal,
    disabled,
    setDisabled,
    saveNewExtractionSchedule,
    checkDates,
    dates,
    obj,
    setDates,
    restartDates
  } = useExtractionSchedule()
console.log(props)
  const practiceSelected = selectedPractices.length > 0

  const status = {
    0: 'Pending',
    1: 'In process',
    2: 'Indicted'
  }

  const columns = [
    {
      title: 'Created By',
      field: 'firstName',
      render: ({firstName, lastName}) => {
        return (<span>{`${firstName} ${lastName}`}</span>)
      }
    },
    {
      title: 'Created At',
      field: 'dateCreated',
      render: ({dateCreated}) => {
        return getDateAsString(dateCreated, "yyyy-MM-DD");
      }
    },
    {
      title: 'Practice',
      field: 'practice',
      render: ({practice}) => {
        return(<span>{practice}</span>)
      }
    },
    {
      title: 'Type',
      field: 'type',
      render: ({type}) => {
        const typeUppercase = type.toUpperCase()
        return(
          <div style={{width: '100px'}}>
            <TextColumnHighlighted
              text={typeUppercase}
              status={type==='new' ? 'info' : 'success'}
            />
          </div>
        )
      }
    },
    {
      title: 'Dates',
      render: ({dates}) => {
        return (<span>{dates.join(', ')}</span>)
      }
    },
    {
      title: 'Extractions Status',
      field: 'extractionsStatus',
      render: ({extractionStatus}) => {
        let color = {
          0: 'warning',
          1: 'info',
          2: 'success'
        }
        return (
          <div style={{width: '100px'}}>
            <TextColumnHighlighted
              text={status[extractionStatus]}
              status={color[extractionStatus]}
            />
          </div>
        )
      }
    }
  ];

  return (
    <TemplateWrapped
      title="Command center"
      headerButtons={
        <>
          <HeaderButton
            icon={faPlus}
            message="Add a new Extraction Schedule"
            onClick={() => {
              setShowModal(true)
            }}
          />
        </>
      }
    >
      {!practiceSelected && (
        <>
          <div
            className="alert alert-alt alert-warning"
            role="alert"
            style={{
              padding: "20px 12px",
              fontSize: "14px",
              borderLeft: "3px solid",
            }}
          >
            Dear user, once you select a connection, the corresponding data will
            be displayed
          </div>
          <div className="mb-5 pb-5">
            <Connections title="Please select a connection"/>
          </div>
        </>
      )}
      {practiceSelected && (
        <CustomTable
          title={<ClientSelected />}
          data={extraction}
          columns={columns}
          sorting={true}
        />
      )}
      {showModal && (
        <CustomModal

          show={showModal}
          onHide={() => {
            setShowModal(false)
            setDisabled(false)
            setDates([])
          }}
          title={'New Extraction Schedule'}
          size='md'
        >
          <ExtractionScheduleForm
            onSubmit={checkDates}
            setShowModal={setShowModal}
            disabled={disabled}
            dates={dates}
            allDates={obj}
            restartDates={restartDates}
            confirm={saveNewExtractionSchedule}
          />
        </CustomModal>
      )}
    </TemplateWrapped>
  )
}

export default withSelectedPractices(ExtractionSchedule)