import {Formik, Form, Field} from "formik";
import {Col, Row} from "react-bootstrap";
import useAzureFilesFilter from "./hooks/useAzureFilesFilter";
import CustomDatePicker from "../../../../CustomDatePicker";

const AzureFileFilter = ({setFilters}) => {
  const {handleSubmit, filters, clear} = useAzureFilesFilter(setFilters);
  return (
    <Formik
      initialValues={filters}
      onSubmit={values => handleSubmit(values)}
      enableReinitialize={true}
    >
      {({setFieldValue, values, resetForm}) => (
        <Form>
          <Row className='mb-2'>
            <Col>
              <label htmlFor="container">Container</label>
              <Field
                name="container"
                type="text"
                placeholder="Container"
                className="form-control"
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <label htmlFor="name">File Name</label>
              <Field
                name="name"
                type="text"
                placeholder="File Name"
                className="form-control"
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <label htmlFor="folder">Folder</label>
              <Field
                name="folder"
                type="text"
                placeholder="Folder"
                className="form-control"
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <label htmlFor="createdStart">Start Date</label>
              <CustomDatePicker
                name='createdStart'
                onChange={date => setFieldValue('createdStart', date)}
                value={values.createdStart ? new Date(values.createdStart) : null}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col >
              <label htmlFor="createdEnd">End Date</label>
              <CustomDatePicker
                name='createdEnd'
                onChange={date => setFieldValue('createdEnd', date)}
                value={values.createdEnd ? new Date(values.createdEnd) : null}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <button
                type="submit"
                className="btn btn-primary btn-block"
              >
                Filter
              </button>
            </Col>
            <Col lg={6}>
              <button
                type="button"
                className="btn btn-warning btn-block"
                onClick={() => {
                  resetForm();
                  clear();
                }}
              >
                Reset
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default AzureFileFilter