import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Wrapper } from "./Wrapper";

export function LoginForm({
  waiting,
  onSubmit,
  userName,
  setUserName,
  password,
  setPassword,
  onPasswordReset,
}) {
  return (
    <>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit({ username: userName, password });
        }}
      >
        <div className="form-group">
          <label className="sr-only" htmlFor="inputEmail">
            User Name
          </label>
          <input
            type="text"
            className="form-control"
            name="username"
            id="username"
            value={userName}
            onChange={({ target: { value } }) => {
              setUserName(value);
            }}
            placeholder=" Username"
            required={true}
          />
        </div>
        <div className="form-group">
          <label className="sr-only" htmlFor="inputPassword">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            name="password"
            id="password"
            placeholder=" Password"
            value={password}
            onChange={({ target: { value } }) => {
              setPassword(value);
            }}
            required={true}
          />
        </div>
        <button
          className="btn btn-success btn-action w-100 p-2"
          disabled={waiting}
        >
          {!waiting && "Login"}
          {waiting && "Please wait "}
          {waiting && <FontAwesomeIcon icon="spinner" spin />}
        </button>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            disabled={waiting}
            className="btn btn-link"
            onClick={onPasswordReset}
          >
            Reset password
          </button>
        </div>
      </form>
    </>
  );

  return (
    <Wrapper>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit({ username: userName, password });
        }}
      >
        <div className="box-input">
          <input
            type="text"
            className="item-control"
            name="username"
            id="username"
            value={userName}
            onChange={({ target: { value } }) => {
              setUserName(value);
            }}
            placeholder=" Username"
            required={true}
          />
        </div>
        <div className="box-input">
          <input
            type="password"
            className="item-control"
            name="password"
            id="password"
            placeholder=" Password"
            value={password}
            onChange={({ target: { value } }) => {
              setPassword(value);
            }}
            required={true}
          />
        </div>
        <div className="box-btn">
          <button className="btn btn-success btn-action" disabled={waiting}>
            {!waiting && "Login"}
            {waiting && "Please wait "}
            {waiting && <FontAwesomeIcon icon="spinner" spin />}
          </button>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            disabled={waiting}
            className="btn btn-link"
            onClick={onPasswordReset}
          >
            Reset password
          </button>
        </div>
      </form>
    </Wrapper>
  );
}
