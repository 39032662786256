import {TemplateWrapped} from "../../Layout";
import useCredentialEdit from "./hooks/useCredentialEdit";
import CredentialList from "../CredentialManager/components/CredentialList";
import React from "react";
import {Col, Row} from "react-bootstrap";
import CustomModal from "../../CustomModal";
import NewCredential from "../NewCredential";

const CredentialEdit = () => {

  const {
    credentials,
    client,
    practice,
    showForm,
    setShowForm,
    addCredential,
    handleDeleteCredentials,
    handleSaveCredentials,
    handleEdit,
    handleAddCredential,
    deleteCredential
  } = useCredentialEdit()

  return(
    <TemplateWrapped title='Credential Edit'>
      <div className='panel credential-form'>
        <div className="panel-body">
          <Row className='mb-3'>
            <Col md={4} >
              <label
                className='text-bold mr-3 form-label'
                htmlFor="client"
              >
                Client:
              </label>
              <select
                defaultValue={client}
                disabled
                className='form-control'
                name="client" id="client">
                <option value={client} disabled>{client}</option>
              </select>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col md={4}>
              <label className='text-bold mr-3 form-label' htmlFor="practices">Practice:</label>
              <select
                defaultValue={practice}
                disabled
                className='form-control'
                name="practices"
                id="practices"
              >
                <option value={practice} disabled>{practice}</option>
              </select>
            </Col>
          </Row>

          <CredentialList
            credentials={credentials}
            addCredential={addCredential}
            handleEdit={handleEdit}
            deleteCredential={deleteCredential}
            handleDeleteCredentials={handleDeleteCredentials}
          />
          <Row>
            <Col className='text-center mt-5'>
              <button
                className='btn btn-primary'
                onClick={handleSaveCredentials}
              >
                Save
              </button>
            </Col>
          </Row>
          {showForm && (
            <CustomModal
              show={showForm}
              onHide={() => setShowForm(!showForm)}
              title='Add New Credential'
            >
              <NewCredential
                onSubmit={handleAddCredential}
              />
            </CustomModal>
          )}
        </div>
      </div>
    </TemplateWrapped>
  )
}

export default CredentialEdit