import PermissionContext from "./PermissionContext";
import usePermission from "../hooks/usePermission";

const PermissionContextProvider = (props) => {
  return(
    <PermissionContext.Provider value={usePermission()}>
      {props.children}
    </PermissionContext.Provider>
  )
}

export default PermissionContextProvider