import {Container, Nav, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DynamicForm} from "../../../formControls/BasicForm";
import {countEmptyFields, formatToSections} from "../missingCodeTab.middleware";

const TabContainer = (
  {
    data,
    handleSubmit,
    handler,
    disabled,
    hideSubmitButton,
  }
) => {

  if (data.length === 0) {
    return (
      <Container className="p-5 bg-light">
        <h2>No data found</h2>
      </Container>
    );
  }

  return (
    <div id="missingcode-tabs">
      <Tab.Container id="missingcode-tabs" defaultActiveKey={0}>
        <div className="d-flex">
          <div className="nav-container">
            <Nav variant="pills" className="flex-column itmesColOptions h-100">
              {data.map(({ title, content }, index) => {
                const notFilled = countEmptyFields(content);
                return (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index}>
                      <strong>{title}</strong>
                    </Nav.Link>
                    {notFilled > 0 ? (
                      <span className="badge badge-warning d-inline-block mr-1">
                        {notFilled}
                      </span>
                    ) : (
                      <span
                        style={{ lineHeight: "21px" }}
                        className="badge badge-success d-inline-block mr-1"
                      >
                        <FontAwesomeIcon icon="check" />
                      </span>
                    )}
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          <div className="flex-grow-1 h-100 content">
            <Tab.Content className="ivmissingCode">
              {data.map(({ title, content }, index) => {
                const accordion = formatToSections(content);

                const formProps = {
                  title,
                  showInColumns: true,
                  numberColums: accordion.length === 1 ? 2 : 4,
                  fields:
                    accordion.length === 1 ? accordion[0]["items"] : accordion,
                  asAccordion: accordion.length > 1,
                  handleChangeByIndex: (controlIndex) => {
                    return handler(index, controlIndex);
                  },
                };

                return (
                  <Tab.Pane className="formItems" key={index} eventKey={index}>
                    <div className="p-3">
                      <DynamicForm
                        hideSubmitButton={hideSubmitButton}
                        disabled={disabled}
                        onSubmit={() => {
                          handleSubmit(content);
                        }}
                        {...formProps}
                      />
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}

export default TabContainer