import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import PracticesList from "./components/practice-list/PracticesList";
import AnalitycsInfo from "./components/analitycs-info/AnalitycsInfo";
import { useAnalitycsForPractices } from "./hooks/useAnalitycsForPractices";
import NoDataFound from "./components/alternative-data/NoDataFound";

const AnalitycsForPractices = (props) => {
  const {
    selectedPractices,
    practicesList,
    onCloseModal,
    constraintDataToGetStatistics,
  } = props;

  const {
    handleSetPracticeSelected,
    practiceActive,
    practiceSelected,
    getAnalitycsInformation,
    statictics,
    isLoading,
  } = useAnalitycsForPractices(constraintDataToGetStatistics, practicesList);

  return (
    <Row>
      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        <PracticesList
          selectedPractices={selectedPractices}
          practicesList={practicesList}
          handleSetPracticeSelected={handleSetPracticeSelected}
          practiceSelected={practiceSelected}
          practiceActive={practiceActive}
        />
      </Col>
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
        {statictics.length > 0 ? (
          <AnalitycsInfo statictics={statictics} />
        ) : (
          <NoDataFound />
        )}

        <div className="d-flex justify-content-center align-items-center">
          <div
            style={{
              display: "flex",
              gap: "1rem",
              position: "absolute",
              bottom: 0,
            }}
          >
            <Button
              variant="primary"
              disabled={practiceSelected.length <= 0}
              onClick={getAnalitycsInformation}
              className="d-flex align-items-center"
            >
              {isLoading && (
                <Spinner
                  className="mr-2"
                  size="sm"
                  animation="border"
                  variant="light"
                />
              )}
              Search Statictics
            </Button>
            <Button variant="danger" onClick={onCloseModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AnalitycsForPractices;
