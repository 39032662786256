import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import React from "react";
import shortid from "shortid";
import FileCreatorList from "./FileCreatorList";
import { styles } from "./transaction-files.styles";

export function AddButton({ addFile }) {
  return (
    <Tooltip title="Add file">
      <button
        type="button"
        className="btn btn-sm btn-icon btn-inverse btn-round text-primary"
        style={styles.addButton}
        onClick={addFile}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </Tooltip>
  );
}

const FileCreator = ({
  files,
  suggestedNameLeft,
  suggestedNameRight,
  addFile,
  removeFile,
  updateFile,
  disabled,
  canDelete
}) => {
  return (
    <div>
      {!disabled ? (
        <>
          <div className="d-flex justify-content-between mb-2">
            <label>Upload Files</label>
            {!disabled && (
              <AddButton
                addFile={() => {
                  addFile({
                    id: shortid(),
                    name: suggestedNameLeft + "__" + suggestedNameRight,
                    file: null,
                  });
                }}
              />
            )}
          </div>
          <FileCreatorList
            canDelete={canDelete}
            focusPosition={suggestedNameLeft.length + 1}
            files={files}
            updateFile={updateFile}
            removeFile={removeFile}
          />
          <hr className="pb-0 mt-5 mb-2" />
        </>
      ) : null}
    </div>
  );
};

export default FileCreator;
