import React from "react";
import CustomTable from "../CustomTable";
import ClientSelected from "../pages/Connections/components/ClientSelected";

const TableDataQuality = ({ tableReference, getRecords, showInfo }) => {
  const columns = [
      { title: "Patient Name", field: "patient_name" },
      { title: "Date", field: "date" },
      { title: "Status", field: "status" },
      { title: "Created", field: "created" },
      { title: "Updated", field: "updated" },
    ],
    actions = [
      {
        icon: "search",
        tooltip: "Show Information",
        onClick: (event, rowData) => showInfo(rowData.id, rowData.status),
      },
    ];

  return (
    <CustomTable
      title={<ClientSelected />}
      tableReference={tableReference}
      columns={columns}
      asyncDataSource={getRecords}
      actions={actions}
    />
  );
};
export default TableDataQuality;
