import {Col, Row} from "react-bootstrap";
import CustomPanel from "../../../../CustomPanel";
import {TemplateWrapped} from "../../../../Layout";
import useAzureFilesReport from "./hooks/useAzureFilesReport";
import CustomDatePicker from "../../../../CustomDatePicker";
import ReportTable from "./components/ReportTable";
import withSelectedPractices from "../../../../HOCS/withSelectedPractices/withSelectedPractices";

const Report = () => {
  const {
    date,
    data,
    structure,
    setDate,
  } = useAzureFilesReport()

  const hasData = Object.keys(data).length !== 0

  return(
    <TemplateWrapped
      title="Azure Files Report"
    >
      <CustomPanel>
        <Row>
          <Col md={3}>
            <label htmlFor="date">Date:</label>
            <CustomDatePicker
              id="date"
              onChange={(date) => {
                setDate(date)
              }}
              value={date}
            />
          </Col>
        </Row>
        {date && structure && hasData && (
          <ReportTable
            data={data}
            structure={structure}
          />
        )}

        {!date && !structure && (
          <div className='jumbotron mt-2'>Please select a date</div>
        )}

        {!hasData && date && (
          <div className='jumbotron mt-2'>No data available</div>
        )}
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default withSelectedPractices(Report)