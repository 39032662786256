import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import * as middlewares from "../middlewares/metrics";
import * as services from "../services";

const initialState = {
  fbd: 0,
  elg: 0,
  missing: 0,
  noMissing: 0,
};

export default function useMetrics() {
  const [data, setData] = useState(initialState);

  const appContext = useContext(AppContext);
  const practiceIds = appContext.get("selectedPractices");

  // Initial Load
  useEffect(() => {
    if (practiceIds.length === 0) return;
    const startDate = moment().format(services.dateFormat);
    const endDate = moment().add(5, "days").format(services.dateFormat);

    services
      .getDashboardData(startDate, endDate, practiceIds, "")
      .then(([s, r]) => {
        const _data = s ? r : [];

        setData(middlewares.formatData(_data));
      });
  }, [practiceIds]);

  return data;
}
