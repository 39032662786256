import styled, { css } from "styled-components";

const defaultStyle = css`
  width: 35px;
  height: 35px;
  line-height: 17.5px;
  font-size: 0.9rem;
  border-radius: 100%;
  border: solid 1px !important;
`;
const smallSizeStyles = css`
  width: 21px;
  height: 21px;
  line-height: 10.5px;
  font-size: 0.75rem;
  font-weight: 100;
  color: #52a1c8 !important;
`;
export const FileActionButton = styled.button`
  ${({ size }) => (!size ? defaultStyle : smallSizeStyles)}
  color: #808991 !important;
  cursor: pointer;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;
  transition: 0.3s;
  &:hover {
    background: #006fa7;
    border: #006fa7;
    color: white !important;
  }
  &:not(:last-child) {
    margin-right: 12px;
  }
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;
export const ConfirmActionButton = styled.button`
  width: 21px;
  height: 21px;
  line-height: 10.5px;
  font-size: 0.75rem;
  font-weight: 100;
  cursor: pointer;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;
`;
export const ActionReminder = styled.span`
  font-weight: 500;
  margin-top: 1rem;
`;
export const FileName = styled.strong`
  font-weight: 400;
  margin: 0.25rem 0;
  display: block;
  line-height: 150%;
  width: 100%;

  height: 30px;

  word-break: break-all;
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 6em;

  &:before {
    content: "";
    position: absolute; 
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }

  @media screen and (min-width: 1000px) {
    /* height: 20px; */
  }
`;

export const styles = {
  fileInput: {
    opacity: 0,
    width: 0,
    position: "absolute",
  },
  addButton: {
    background: "#ffffff important",
    color: "inherit",
    borderRadius: "100%",
    border: "solid 1px #d1d1d1 important",
    marginRight: "7.5px",
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    padding: 0,
    textAlign: "center",
    outline: "none",
  },
  fileUploadButton: {
    padding: "6px",
    borderRadius: "4px",
    marginLeft: "10px",
    border: "solid 1px #ced4da",
  },
  filePreviewImage: {
    width: "100%",
    height: 100,
    backgroundColor: "#d5e4f1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  filePreviewImageIcon: {
    fontSize: "3rem",
    color: "#000",
    opacity: "0.4",
  },
  fileLabel: {
    padding: "6px",
    borderRadius: "4px",
    marginLeft: "10px",
    border: "solid 1px #ced4da",
  },
};
