import { useContext, useRef, useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import { TemplateContext } from "../TemplateContext";
import Category from "./Category";
import CategoryHeader from "./CategoryHeader";

const initialCategoryState = { adding: false, data: {} };

export default function CategoryWrapper({
  parentCategory,
  category,
  canCreateSubcategories,
  index,
}) {
  const { setActiveCategory } = useContext(TemplateContext);
  const [isBeignDisplayed, setIsBeignDisplayed] = useState(false);
  // Force to re-render the accordion
  const [timesBeingClosed, setTimesBeignClosed] = useState(0);
  const [addingNewCategory, setAddingNewCategory] =
    useState(initialCategoryState);

  const decoratedOnClick = useAccordionToggle(index + 1, () => {
    setActiveCategory(parentCategory, category, !isBeignDisplayed);
    setIsBeignDisplayed(!isBeignDisplayed);
  });

  const ref = useRef(null);

  return (
    <>
      <Card>
        <Card.Header
          className={`d-flex justify-content-between align-content-center py-3 px-0 ${
            isBeignDisplayed
              ? "border-bottom-0 border-left border-primary"
              : "text-muted"
          }`}
          key={index + 1}
          onClick={(ev) => {
            if (ref.current && !ref.current.contains(ev.target)) {
              decoratedOnClick();
            }
          }}
        >
          <CategoryHeader
            clickeableRef={ref}
            canCreateSubcategories={canCreateSubcategories}
            category={category}
            expanded={isBeignDisplayed}
            parentCategory={parentCategory}
            addCategory={() => {
              setAddingNewCategory({ adding: true });
              !isBeignDisplayed && decoratedOnClick();
            }}
          />
        </Card.Header>

        <Accordion.Collapse
          eventKey={index + 1}
          onExit={() => {
            setIsBeignDisplayed(false);
            setTimesBeignClosed(timesBeingClosed + 1);
          }}
        >
          <Card.Body>
            <Category
              timesBeingClosed={timesBeingClosed}
              isBeignDisplayed={isBeignDisplayed}
              parentCategory={parentCategory}
              {...category}
              index={index}
              canCreateSubcategories={canCreateSubcategories}
              addingNewCategory={addingNewCategory}
              setAddingNewCategory={setAddingNewCategory}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}
