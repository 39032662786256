import {
  ModalList,
  ModalMessageHeading,
} from "./TransactionDetailsComponents.styles";

export const MissingFieldsOnAudit = ({ missingFieldsQty }) => (
  <span>
    The current verification has{" "}
    <strong>{missingFieldsQty} missing fields</strong>
  </span>
);

export const NoFilesOnAudit = ({ missingFiles }) => (
  <div>
    <ModalMessageHeading>The following files are missing:</ModalMessageHeading>
    <ModalList>
      {missingFiles.map((f) => (
        <li key={f} className="text-muted">
          <small>{f}</small>
        </li>
      ))}
    </ModalList>
  </div>
);

export const AuditConfirmation = ({ label }) => (
  <div>
    Dear user, are you sure you want to set this record as{" "}
    <strong>{label}</strong>?
  </div>
);

export const AuditQuestion = ({ missingFiles, missingFieldsQty, label }) => {
  return (
    <div className="text-left">
      <AuditConfirmation label={label} />
      {missingFiles.length || missingFieldsQty ? (
        <div>
          <hr />
          {missingFiles.length ? (
            <NoFilesOnAudit missingFiles={missingFiles} />
          ) : null}
          {missingFieldsQty ? (
            <MissingFieldsOnAudit missingFieldsQty={missingFieldsQty} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
