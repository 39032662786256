import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../Logo";

export default function CenteredMessage(props) {
  return (
    <div className="p-5 mt-5 d-flex align-items-center justify-content-center">
      <Container className="mt-5">
        <Row>
          <Col md={6} className=" offset-md-3 p-5">
            <div className="d-flex flex-column">
              <Logo className="align-self-center mb-3" />
              <p className="text-center">{props.children}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
