import { Api, decryptApiResponse } from "../../../../config/Api";
import { statusAndResponse } from "../../../../util/requestHandler";

function _formatType(type) {
  return type === "checkbox" ? "checkbox" : type;
}

export const getBookmarkType = () => {
  return new Promise((resolve, reject) => {
    resolve(["text", "check", "multiple"]);
  });
};

export function getList(params) {
  return statusAndResponse(
    Api.get("/api/bks", { params }),
    decryptApiResponse
  )
}

export const getFieldsForm = (control) => {
  return statusAndResponse(
    Api.get("api/bks/form/" + control),
    decryptApiResponse
  );
};

export async function createBookmark(type, data) {
  const formattedType = _formatType(type);
  return statusAndResponse(
    Api.post(`/api/bks/${formattedType}/store`, data),
    decryptApiResponse
  );
}

export async function updateBookmark(type, _id, data) {
  console.log(data)
  return statusAndResponse(
    Api.put(`/api/bks/${type}/${_id}`, data),
    decryptApiResponse
  );
}

export async function deleteBookmark(_id) {
  return statusAndResponse(Api.delete("/api/bks/" + _id), decryptApiResponse);
}

export async function getBookmarkState(_id) {
  return statusAndResponse(
    Api.get("/api/tmp/bookmark/inuse/" + _id),
    decryptApiResponse
  );
}
