import moment from "moment";
import shortid from "shortid";
import { iterateEveryKey } from "../../../util/object";
import {
  ChecksList,
  FormInputDate,
  FormInputNumber,
  FormInputText,
  FormSelect,
  SubsectionTitle,
} from "../../formControls";

const getSelectedBookmark = (options) => {
  let bookmark = "";
  for (let option of options) {
    if (option.value && option.value !== "o") {
      bookmark = option.bookmark;
    }
  }
  return bookmark;
};

export function bookmarkToControl(
  { _id, options, affectsOn, bookmark, label, required, code, cond, symbol },
  formattedType,
  formattedValue
) {
  const hasOptions = typeof options !== "undefined";
  const noOptionSelected =
    hasOptions && options.filter((o) => o.value === "n").length === 0;
  const hasValue = typeof formattedValue !== "undefined";
  const noValue = hasValue && formattedValue.length === 0;

  const elementProps = {
    id: bookmark,
    key: shortid.generate(),
    label: label,
    required: required || false,
    affectsOn: affectsOn || {},
    border:
      required && ((hasValue && noValue) || (hasOptions && noOptionSelected)),
  };

  let result = {};

  switch (formattedType) {
    case "subsection":
      result = {
        control: SubsectionTitle,
        props: {
          ...elementProps,
          label: code + " - " + label,
        },
      };
      break;
    case "text_string":
      result = {
        affectedId: _id,
        control: FormInputText,
        props: {
          ...elementProps,
          value: formattedValue,
          useBlur: true,
          maxLength: (cond || {}).max || "",
        },
      };
      break;
    case "text_date":
      result = {
        affectedId: _id,
        control: FormInputDate,
        props: {
          ...elementProps,
          // maxDate: (cond || {}).max || "",
          // minDate: (cond || {}).min || "",
          value: moment(formattedValue, "MM/DD/yyyy").isValid()
            ? formattedValue
            : "",
        },
      };
      break;
    case "text_number":
      result = {
        affectedId: _id,
        control: FormInputNumber,
        props: {
          ...elementProps,
          value: formattedValue ? formattedValue : "",
          maxLength: (cond || {}).max || "",
          symbol: symbol !== "N/A" ? symbol : "",
          useBlur: true,
        },
      };
      break;
    case "checkbox_object":
      let bookmark = getSelectedBookmark(options);
      result = {
        affectedId: _id,
        control: FormSelect,
        props: {
          ...elementProps,
          id: shortid.generate() + "_select",
          value: bookmark,
          options: options,
          cond: cond || {},
        },
      };
      break;
    case "checkbox_multiobject":
      let bookmarMulti = getSelectedBookmark(options);
      result = {
        affectedId: _id,
        control: ChecksList,
        props: {
          ...elementProps,
          id: shortid.generate() + "check",
          value: bookmarMulti,
          cond: cond || {},
          options: options,
        },
      };
      break;
  }
  return result;
}

export function getBookmarkType(type, elementType) {
  return type
    ? (elementType || "").toLowerCase() + "_" + (type || "").toLowerCase()
    : (elementType || "").toLowerCase();
}

export function getBookmarkValue(value) {
  return value === "-" || typeof value === "undefined" ? "" : value;
}

export function getBookmarkAffection(
  affecter,
  affectsOn,
  options,
  formattedValue
) {
  let action = affectsOn.actions[0];
  let affectedValue = affectsOn.whenValueContains[0];

  // currentValue === affectedValue
  const affection = affectsOn.destination.reduce((a, i) => {
    a[i.id] = {
      affecter,
      affectWhen: affectedValue,
      type: action,
      children: [],
    };

    let initialValue;

    const isMultipleSelection = options && options.length > 0;
    if (isMultipleSelection) {
      const selected = options.filter((x) => x.value === "n");
      if (selected.length > 0)
        initialValue = selected[0]["bookmark"] === affectedValue;
    } else {
      initialValue = formattedValue === affectedValue;
    }
    a[i.id]["initialState"] = initialValue;

    return a;
  }, {});

  return affection;
}

// export function setAffectingBehavior(child, affectsOn) {
//   const affection = affectsOn[child.affectedId];
//   let previousControl = child.control;
//   const isInitiallyRequired = child.props.required;
//   const { wrapper, required, border } = getAffectedControlState(
//     affection.initialState,
//     isInitiallyRequired,
//     affection.type
//   );

//   child.control = wrapper;
//   child.props = {
//     ...child.props,
//     control: previousControl,
//     // subscribe: affection.subscribe, // Reactive behavior
//     initialState: affection.initialState,
//     required,
//     border,
//     isInitiallyRequired,
//   };
// }

export const formattedData = (data) => {
  let sectionsForm = {};
  let currentSection = "";
  let affections = {};
  //
  iterateEveryKey(
    data,
    (currentObj, key) => {
      if (key !== "elementType") return;

      const { type, value, elementType, affectsOn, options, categoryName } =
        currentObj;

      let formattedValue = getBookmarkValue(value);
      let formattedType = getBookmarkType(type, elementType);

      if (
        formattedType === "section_info" ||
        formattedType === "section_code"
      ) {
        currentSection = categoryName;
        sectionsForm[categoryName] = {
          props: {
            key: shortid.generate(),
            label: categoryName,
          },
          currentIndex: 0,
          children: [],
        };
        return;
      }

      //   if (affectsOn) {
      //     const affection = getBookmarkAffection(
      //       currentObj["_id"],
      //       affectsOn,
      //       options,
      //       formattedValue
      //     );

      //     // Append this parent to the affecters
      //     affections = { ...affections, ...affection };
      //   }

      const control = bookmarkToControl(
        currentObj,
        formattedType,
        formattedValue
      );

      sectionsForm[currentSection].children.push({
        ...control,
        index: sectionsForm[currentSection]["currentIndex"]++,
      });
    },
    ["affectsOn"]
  );

  // Once all the sections has been formed and the affections setted
  // Will subscribe every affected child to the corresponding affection
  // Format as array

  const formattedSections = Object.entries(sectionsForm).map(
    ([title, { children, notFilled }], index) => {
      children.forEach((x, i) => {
        // if (affections[x.affectedId]) {
        //   setAffectingBehavior(x, affections);
        //   affections[x.affectedId]["children"].push({
        //     parent: index,
        //     child: i,
        //   });
        // }
      });
      return {
        title,
        content: children,
      };
    }
  );

  const formattedAffections = Object.entries(affections).reduce(
    (a, [key, i]) => {
      a[i.affecter] = { ...i };
      return a;
    },
    {}
  );

  return { formattedSections, formattedAffections };
};

const prepareResponse = (data) => {
  if (data.props.options) {
    let options = {};
    for (const opt of data.props.options) {
      if (data.control !== ChecksList) {
        Object.assign(options, {
          [opt.bookmark]: opt.bookmark === data.props.value ? "n" : "o",
        });
      } else {
        Object.assign(options, { [opt.bookmark]: opt.value });
      }
    }
    return options;
  }
  return (
    data.control !== SubsectionTitle && {
      [data.props.id]: data.props.value ? data.props.value : "-",
    }
  );
};

export const getValueFromControls = (controls) => {
  return controls.reduce((a, el) => {
    return { ...a, ...prepareResponse(el) };
  }, {});

  // for (let item of data) {
  //   Object.assign(dataToStore, prepareResponse(item));
  // }
};
