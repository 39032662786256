import React from "react";
import CustomTable from "../CustomTable";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MySwal from "sweetalert2";
import {ClientDelete} from "../pages/Clients/services";

const ClientsTable = ({
                        data,
                        canEdit,
                        canEditUser,
                        canDelete,
                        setShowModalEdit,
                        setShowModalUser,
                        setStateSelect,
                        setClientSelect,
                        loadClient,
                        dataSource,
                        tableReference,
                        ...props}) => {
  const columns = [
    {
      title: "Id",
      field: "_id",
      hidden: true,
    },
    {
      title: "Name",
      field: "name",
      hidden: false,
    },
    {
      title: "Address",
      field: "address",
      hidden: false,
    },
    {
      title: "Created",
      field: "created",
      hidden: false,
    },
    {
      title: "state",
      field: "state",
      hidden: true,
    },
  ];

  let actions = [];

  if (canEdit) {
    actions.push((rowData) => ({
      icon: () => <EditIcon color="primary"/>,
      tooltip: "Edit Client",
      onClick: () => {
        setClientSelect(rowData._id)
        setShowModalEdit(true)
        setStateSelect(rowData.state)
      },
    }));
  }

    actions.push((rowData) => ({
      icon: () => <DeleteIcon className="text-danger"/>,
      tooltip: "Delete Client Practice",
      onClick: (event) => deleteClient(rowData._id),
    }));

  const deleteClient = (id) => {
    MySwal.fire({
      title: "Are you sure you want to delete this Client?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ClientDelete(id).then(([status, resp]) => {
          if (status) {
            loadClient();
          }
        });
      }
    });
  };
  return (
    <CustomTable
      actions={actions}
      columns={columns}
      pageSize={25}
      asyncDataSource={dataSource}
      tableReference={tableReference}
      search={false}
    />
  );
}

export default ClientsTable