import {useContext, useMemo, useState} from "react";
import * as services from '../auditTrait.services'
import {getUrlParams} from "../../../../util/browser";
import {useAsyncTableData} from "../../../CustomTable/hooks";
import {AppContext} from "../../../../App";
import moment from "moment";
import {defaultFormat} from "../../../../util/date";

export const initialBotFilter = {
  botName: '',
  code: '',
  startDate: '',
  endDate: '',
  practiceIds: ''
}

const useAuditTrait = () => {
  const appContext = useContext(AppContext);
  const practiceId = appContext.get("practiceId");
  const practiceName = appContext.get('practiceName')
  const practiceIds = appContext.get("selectedPractices");
  const initial = {...initialBotFilter, practiceIds: JSON.stringify(practiceIds)}
  const initialParams = useMemo(() => {
    return {...getUrlParams(Object.keys(initial)), practiceIds: JSON.stringify(practiceIds)}
  }, [initial, practiceIds])
  const [date, setDate] = useState(new Date())

  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    services.getAuditTraitList,
    initialParams
  )

  const handleBackStep = () => {
    const backDate = moment(date, defaultFormat).subtract(1, 'day').toDate()
    setDate(backDate)
    refreshData({...initialParams, startDate: backDate, endDate: backDate})
  }

  const handleForwardStep = () => {
    const forwardDate = moment(date, defaultFormat).add(1, 'day').toDate()
    setDate(forwardDate)
    refreshData({...initialParams, startDate: forwardDate, endDate: forwardDate})
  }

  return {
    practiceIds,
    dataSource,
    tableReference,
    refreshData,
    handleBackStep,
    handleForwardStep,
    date,
    practiceName,
    initialParams
  }
}

export default useAuditTrait