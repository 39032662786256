import React from "react";
import CustomTable from "../../../../CustomTable";
import {Icon, IconButton} from "@material-ui/core";
import {MTableToolbar} from "material-table";

const PracticeTable = (
  {
    data,
    checkIfPracticeIsSelected,
    handleClickPractice,
    perPage,
    paging = true,
    showButtons = false,
    showUncheckAll = false,
    clientIdSelected,
    clientId,
    handleCheckAllPractices,
    handleUnCheckAllPractices
  }) => {

  const columns = [
    {
      title: "Id",
      field: "_id",
      hidden: true,
    },
    {
      title: "Name",
      field: "name",
      hidden: false,
    },
    {
      title: "State",
      field: "state",
    },
    {
      title: "Actions",
      sorting: false,
      align: "right",
      render: (rowData) => {
        const isSelected = checkIfPracticeIsSelected(rowData._id);
        return (
          <>
            <IconButton
              style={isSelected ? { color: "#007bff", height:"15px" } : {color: "#ccc", height:"15px"}}
              component="span"
              onClick={() => {
                handleClickPractice(rowData._id)
              }}
            >
              <Icon>cable</Icon>
            </IconButton>
          </>
        );
      },
    }
  ];

  return (
      <CustomTable
        columns={columns}
        data={data}
        pageSize={perPage}
        options={{paging}}
        components={{
          Toolbar: (props) => (
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '32px'}}>
              {showButtons && (
                <div style={{marginRight: '-2rem'}}>
                  <button className='btn btn-sm mr-2 btn-primary'  onClick={handleCheckAllPractices}>Check All</button>
                  {clientId === clientIdSelected && showUncheckAll && (
                    <button className='btn btn-sm btn-warning' onClick={handleUnCheckAllPractices}>Uncheck All</button>
                  )}
                </div>
              )}
                <div>
                  <MTableToolbar {...props} />
                </div>
            </div>
          ),
        }}
      />
  );
};

export default PracticeTable;