import {createContext} from "react"

const PermissionContext = createContext({
  sections: [],
  createSection: (data) => {},
  updateSection: (id, data) => {},
  deleteSection: (id) => {},
  createElement: (parentLevels, data) => {},
  updateElement: (parentLevels, elementIndex, data) => {},
  deleteElement: (parentLevels, elementIndex) => {},
})

export default PermissionContext