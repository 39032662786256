import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import * as auth from "../util/auth";
import CenteredMessage from "./helper/CenteredMessage";

export default function Main(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { pathname: previousLocation } = props.prevLocation || {};
  const nextLocation = loggedIn ? auth.getDefaultPath() : "/login";

  useEffect(() => {
    setLoggedIn(auth.isLoggedIn());
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  if (previousLocation === nextLocation) {
    return (
      <CenteredMessage>
        Dear user, this content cannot be displayed. <br />
        <span className=" d-block mt-2">
          <Link to="/login">Take me to the login page</Link>
        </span>
      </CenteredMessage>
    );
  }

  return <Navigate to={nextLocation} />;
}
