import React, { useEffect, useRef, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import { AccordionItem } from "../../CustomAccordion";

function compareOptions(a, b) {
  // return a && b && a.id === b.id && a.label === b.label;
  return a === b;
}

function GetOption({
  item,
  inline,
  type,
  selectedValues,
  onChange,
  required,
  reference,
  checked,
  className = "",
}) {
  return (
    <Form.Group
      className={`${inline ? "d-inline-block" : ""} mb-0 ${className}`}
      key={item.id}
    >
      <Form.Label className="mb-0">
        <Form.Check
          required={required}
          inline
          ref={reference}
          className="mr-0"
          checked={
            typeof checked !== "undefined"
              ? checked
              : selectedValues.filter((x) => compareOptions(x, item)).length > 0
          }
          name="options"
          type={type}
          value={item.id}
          onChange={({ target: { checked } }) =>
            onChange && onChange(item, checked)
          }
        />
        {item.label}
      </Form.Label>
    </Form.Group>
  );
}

function OptionsGroupHeader({
  items,
  label,
  selectedData,
  selectedDataHandler,
  className,
}) {
  const ref = useRef({ current: {} });
  const [parentIsSelected, setparentIsSelected] = useState(false);

  useEffect(() => {
    const currentChildren = getSelectedChildren();

    if (currentChildren.length === items.length) {
      setparentIsSelected(true);
      ref.current.indeterminate = false;
    } else if (currentChildren.length === 0) {
      setparentIsSelected(false);
      ref.current.indeterminate = false;
    } else {
      ref.current.indeterminate = true;
    }
  }, [selectedData, ref, items]);

  function getSelectedChildren() {
    return selectedData.filter((value) => items.includes(value));
  }

  function dataWithoutChildren() {
    const currentChildren = getSelectedChildren();
    if (currentChildren.length === 0) return selectedData;
    return currentChildren.reduce((a, i) => {
      a = a.filter((x) => !compareOptions(x, i));
      return a;
    }, selectedData);
  }
  return (
    <GetOption
      item={{
        label,
        id: label,
      }}
      className={className}
      inline={true}
      type="checkbox"
      reference={ref}
      selectedValues={parentIsSelected}
      checked={parentIsSelected}
      onChange={(selected, checked) => {
        const newData = dataWithoutChildren();
        if (!checked) {
          selectedDataHandler(newData);
        } else {
          selectedDataHandler([...newData, ...items]);
        }
      }}
    />
  );
}

function OptionsGrouped({ items, selectedData, selectedDataHandler }) {
  return (
    items && (
      <>
        {items.map((item) => (
          <div key={item.id}>
            <div className="ml-3">
              <GetOption
                item={item}
                inline={false}
                type={"checkbox"}
                selectedValues={selectedData}
                onChange={(selected, checked) => {
                  if (!checked) {
                    selectedDataHandler(
                      selectedData.filter((x) => !compareOptions(x, selected))
                    );
                  } else {
                    selectedDataHandler([...selectedData, selected]);
                  }
                }}
              />
            </div>
          </div>
        ))}
      </>
    )
  );
}

export default function CheckList() {
  return <div></div>;
}

export function AccordionList({
  data = [],
  selected = [],
  setSelected,
  className,
}) {
  if (!setSelected) return <div>Invalid data was given</div>;

  return data.map(({ label, items }) => {
    /* if (items && items.length > 0) */
    // {
    return (
      <Accordion key={label} className={className}>
        <AccordionItem
          eventKey={label}
          renderHeader={(props) => (
            <OptionsGroupHeader
              className="my-2"
              key={label}
              label={label}
              items={items}
              selectedData={selected}
              selectedDataHandler={setSelected}
              {...props}
            />
          )}
          renderBody={(props) => (
            <OptionsGrouped
              items={items}
              selectedData={selected}
              selectedDataHandler={setSelected}
              {...props}
            />
          )}
        />
      </Accordion>
    );
    // }
    /* else {
      return (
        <OptionsGrouped
          items={items}
          selectedData={selected}
          selectedDataHandler={setSelected}
          {...props}
        />
      );
    } */
  });
}
