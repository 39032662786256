import { useEffect, useState } from "react";
import { faEye } from "@fortawesome/fontawesome-free-solid";
import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileDownload from "js-file-download";
import { Container, Nav, Tab } from "react-bootstrap";
import { Navigate } from "react-router";
import Cookies from "universal-cookie";
import { decryptData } from "../../../config/encryptData";
import {
  download,
  driveUpload,
  getDataMerge,
  save,
} from "../../../services/missingCode";
import { useLocation } from "react-router-dom";
import { displayModalMessage } from "../../../util/displayMessage";
import { Loader } from "../../formControls";
import { DynamicForm } from "../../formControls/BasicForm";
import { TemplateWrapped } from "../../Layout";
import { HeaderButton } from "../../RemarkTemplate/Body";
import { InfoResumen } from "./components/InfoResumen";
import "./style.css";
import {
  getAffectedControlState,
  formattedData,
  countEmptyFields,
  formatToSections
} from "../MissingCodeTab/missingCodeTab.middleware";
import {getGroupName, processName} from "../MissingCodeTab/missingCodeUtils";

function TabContainer({
  data,
  handleSubmit,
  handler,
  disabled,
  hideSubmitButton,
}) {
  if (data.length === 0) {
    return (
      <Container className="p-5 bg-light">
        <h2>No data found</h2>
      </Container>
    );
  }

  return (
    <div id="missingcode-tabs">
      <Tab.Container id="missingcode-tabs" defaultActiveKey={0}>
        <div className="d-flex">
          <div className="nav-container">
            <Nav variant="pills" className="flex-column itmesColOptions h-100">
              {data.map(({ title, content }, index) => {
                const notFilled = countEmptyFields(content);
                return (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index}>
                      <strong>{title}</strong>
                    </Nav.Link>
                    {notFilled > 0 ? (
                      <span className="badge badge-warning d-inline-block mr-1">
                        {notFilled}
                      </span>
                    ) : (
                      <span
                        style={{ lineHeight: "21px" }}
                        className="badge badge-success d-inline-block mr-1"
                      >
                        <FontAwesomeIcon icon="check" />
                      </span>
                    )}
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          <div className="flex-grow-1 h-100 content">
            <Tab.Content className="ivmissingCode">
              {data.map(({ title, content }, index) => {
                const accordion = formatToSections(content);

                const formProps = {
                  title,
                  showInColumns: true,
                  numberColums: accordion.length === 1 ? 2 : 4,
                  fields:
                    accordion.length === 1 ? accordion[0]["items"] : accordion,
                  asAccordion: accordion.length > 1,
                  handleChangeByIndex: (controlIndex) => {
                    return handler(index, controlIndex);
                  },
                };

                return (
                  <Tab.Pane className="formItems" key={index} eventKey={index}>
                    <div className="p-3">
                      <DynamicForm
                        hideSubmitButton={hideSubmitButton}
                        disabled={disabled}
                        onSubmit={() => {
                          handleSubmit(content);
                        }}
                        {...formProps}
                      />
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}

// Main component
const MissingCode = ({ permissions, ...props }) => {
  const _permissions = {
    missing_code: {
      audit_transaction: false,
      edit_missingcode: false,
      list_missingcode: false,
      update_pms: false,
    },
    ...permissions,
  };

  const location = useLocation();

  const { transId: transactionId, showSpreadsheet = true } = location.state
    ? location.state
    : "";
  const [fileName, setFileName] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loaderIsActive, setLoaderIsActive] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [Spreedsheet, setSpreedsheet] = useState({});
  const [data, setData] = useState({ affectsOn: {}, data: [] });

  const handleSubmit = (inputsForm) => {
    setLoaderIsActive(true);
    const cookies = new Cookies();
    let othersOptions = JSON.parse(cookies.get("otherOptions"));
    decryptData(othersOptions).then((resp) => {
      save(inputsForm, transactionId, resp.name).then(([s, _r]) => {
        setLoaderIsActive(false);
        if (s)
          displayModalMessage("Success", "Data saved successfully", "success");
      });
    });
  };

  useEffect(() => {
    function setSpreadsheetAndGetName(
      elements,
      groupName
    ) {
      let name = "";
      if (elements.spreedsheet !== undefined) {
        setSpreedsheet({ ...elements.spreedsheet, groupName });
        name = processName(elements)
      } else {
        name = "Insurance_verification.docx";
      }
      return name;
    }

    let mounted = true;
    getDataMerge(transactionId)
      .then(([_status, elements]) => {
        // TODO {appNotes}

        let groupName = getGroupName(elements)

        if (mounted) {
          let { formattedSections, formattedAffections } =
            formattedData(elements);

          setData({
            affectsOn: formattedAffections,
            data: formattedSections,
            apptNote: elements.spreedsheet.apptNote,
            apptData: elements.spreedsheet.apptData,
            savingNote: false,
          });
          const name = setSpreadsheetAndGetName(
            elements,
            groupName
          );
          setFileName(name);
          setLoadingData(false);
        }
      })
      .catch(function (_ex) {console.log(_ex)});
    return () => (mounted = false);
  }, []);

  const handleDownloadFile = (_e) => {
    if (data.data.length === 0) {
      displayModalMessage("Warning", "No data to download", "warning");
      return;
    }
    setLoaderIsActive(true);
    download(transactionId)
      .then((response) => {
        setLoaderIsActive(false);
        fileDownload(response.data, fileName);
      })
      .catch((_err) => {
        setLoaderIsActive(false);
        displayModalMessage(
          "Oops...",
          "An error occurred while trying download the file, please try again.",
          "error"
        );
      });
  };

  const handlePmsUpdate = () => {
    setLoaderIsActive(true);
    driveUpload(transactionId)
      .then((_response) => {
        setLoaderIsActive(false);
        displayModalMessage("Success", "PMS Update Correctly", "success");
      })
      .catch((err) => {
        const errText =
          err.name === "to show"
            ? err.message
            : "An error occurred during the update, please try again later.";
        setLoaderIsActive(false);
        displayModalMessage("Oops...", errText, "error");
      });
  };

  const handleCloseModal = () => {
    setShowBox(false);
  };

  function handler(parentI, childI) {
    return function (value) {
      let newData = [...data.data];
      const control = newData[parentI]["content"][childI];

      if (control.props.value === value) {
        return;
      }

      const affection = data.affectsOn[control.affectedId];
      if (control.affectedId && affection) {
        const state = value === affection["affectWhen"];

        affection["children"].forEach((x) => {
          const { required, border } = getAffectedControlState(
            state,
            newData[x.parent]["content"][x.child]["props"].isInitiallyRequired,
            affection.type
          );

          newData[x.parent]["content"][x.child]["props"] = {
            ...newData[x.parent]["content"][x.child]["props"],
            initialState: state,
            required,
            border,
          };
        });
      }

      control["props"] = {
        ...control["props"],
        value,
        border: control.props.required && value.length === 0,
      };

      setData({ ...data, data: [...newData] });
    };
  }

  if (!_permissions.missing_code.edit_missingcode) {
    return <Navigate to="/" />;
  }

  return (
    <TemplateWrapped
      title="Insurance Verification"
      headerButtons={
        <>
          {_permissions.missing_code.update_pms === true && (
            <HeaderButton
              icon={faEdit}
              message=" Update PMS"
              onClick={handlePmsUpdate}
            />
          )}
          <HeaderButton
            icon={faDownload}
            message="Download template"
            onClick={handleDownloadFile}
          />
          {showSpreadsheet && (
            <HeaderButton
              icon={faEye}
              message="Show details"
              onClick={() => {
                setShowBox(true);
              }}
            />
          )}
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="">
            {Spreedsheet && (
              <InfoResumen
                apptData={data.apptData}
                notes={data.apptNote}
                item={Spreedsheet}
                handleCloseModal={handleCloseModal}
                showBox={showBox}
                transactionId={transactionId}
                onNoteSaved={(apptNote) => {
                  setData((d) => ({ ...d, apptNote }));
                }}
              />
            )}

            {!loadingData && (
              <TabContainer
                handleSubmit={handleSubmit}
                data={data.data || []}
                handler={handler}
              />
            )}
            <Loader active={loaderIsActive || loadingData} />
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
};

export default MissingCode;