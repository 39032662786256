import React from "react";
const BASE_CLASS = `pie-chart`;
export default class Pie extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  static getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  }

  createSlices(slices, colors) {
    let cumulativePercent = 0;
    return slices.map((percent, index) => {
      // destructuring assignment sets the two variables at once
      const [startX, startY] = Pie.getCoordinatesForPercent(cumulativePercent);
      // each slice starts where the last slice ended, so keep a cumulative percent
      cumulativePercent = cumulativePercent + percent;
      const [endX, endY] = Pie.getCoordinatesForPercent(cumulativePercent);
      // if the slice is more than 50%, take the large arc (the long way around)
      const largeArcFlag = percent > 0.5 ? 1 : 0;
      // some SVG path here
      // • M = Move
      // • A = Arc
      // • L = Line
      const pathData = [
        `M ${startX} ${startY}`,
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        `L 0 0`,
      ].join(` `);
      return <path key={index} d={pathData} fill={colors[index]} />;
    });
  }

  render() {
    const { slices, size, colors } = this.props;
    this.cumulativePercent = 0;
    return (
      <svg
        viewBox="-1 -1 2 2"
        className={`${BASE_CLASS}__pie`}
        height={size}
        width={size}
        style={{
          transform: "rotate(-90deg)",
        }}
      >
        <defs>
          <mask id="pie-mask">
            <rect fill="white" x="-1" y="-1" width="2" height="2" />
            <circle fill="black" cx="0" cy="0" r=".65" />
          </mask>
        </defs>
        <g mask="url(#pie-mask)">
          <circle className={`${BASE_CLASS}__pie_bg`} cx="0" cy="0" r="1" />
          {this.createSlices(slices, colors)}
        </g>
      </svg>
    );
  }
}

export class PieAlt extends React.Component {
  render() {
    let {
      value,
      size,
      colors: [toFillColor, filledColor],
    } = this.props;
    const _value = parseFloat(String(value));
    let val = value > 1 ? value : value * 100;
    let dim = size ? parseInt(size, 10) : 32;
    let strokeDasharray = `${val} 100`;
    let containerStyle = {
      display: "inline-block",
      width: dim,
      height: dim,
    };

    return (
      <div style={containerStyle}>
        <svg
          viewBox="0 0 32 32"
          style={{ transform: "rotate(-90deg)", borderRadius: "50%" }}
        >
          <circle
            stroke={toFillColor}
            strokeWidth="8"
            fill="transparent"
            className="bottom"
            r="16"
            cx="16"
            cy="16"
          />
          <circle
            className="top"
            r="16"
            cx="16"
            cy="16"
            style={{ strokeDasharray }}
            stroke={filledColor}
            strokeWidth="8"
            fill="transparent"
          />
        </svg>
      </div>
    );
  }
}
