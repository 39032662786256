import React from "react"
import * as auth from "../../../../util/auth";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {HeaderButton} from "../../../RemarkTemplate/Body";

const LegendRecordsFiltered = ({
                                 filter: {practiceId = false, endDate = false, startDate = false},
                                 numRecords,
                                 dowloadRecords
                               }) => {
  const {practices} = auth.getLoggedInData()
  const hasFilter = practiceId || (endDate && startDate)
  const selectedPractice = practices.filter((practice) => {
    return practice._id === practiceId
  })

  const practiceName = selectedPractice.length ? selectedPractice[0].name : false
  //const practiceName = selectedPractice[0].name || false

  return (
    <div className='legend'>
      {hasFilter && (
        <>
          <div className='legend-filter'>
            {practiceName && (<span className='legend-item'>Practice: <strong>{selectedPractice[0].name}</strong></span>)}
            <span className='legend-item'>Start date: <strong>{startDate}</strong></span>
            <span className='legend-item'>End date: <strong>{endDate}</strong></span>
            <span className='legend-total legend-item'>Total records: <strong>{numRecords}</strong></span>
            <HeaderButton
              icon={faDownload}
              message="Download records"
              onClick={dowloadRecords}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default LegendRecordsFiltered