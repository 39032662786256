import styled from "styled-components";

export const AuditButton = styled("button")`
  background-color: #2ac87c;
  color: #fff;
  padding: 0.35rem 1.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:disabled {
    background-color: #ccc;
    color: #888;
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #1eab67 !important;
    transition: 0.5s;
  }
`;
