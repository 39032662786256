import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Paper } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { TemplateWrapped } from "../../Layout";
import { HeaderButton } from "../../RemarkTemplate/Body";
import TemplateForm from "./components/TemplateForm";
import useTemplateList from "./useTemplateList";

export default function TemplateList(props) {
  const { data, displayForm, reloadData, setDisplayForm } = useTemplateList();
  const history = useNavigate();

  const tableColumns = [
      { title: "Name", field: "name" },
      { title: "Description", field: "description" },
    ],
    tableActions = [
      {
        icon: "content_copy",
        tooltip: "Duplicate",
        onClick: (event, { name, description, _id }) => {
          setDisplayForm({ name, description, _id });
        },
      },
      (rowData) => ({
        icon: () => <Edit color="primary" />,
        tooltip: "Edit",
        onClick: () => {
          goToRecord(rowData._id);
        },
      }),
    ];

  const goToRecord = (id) => {
    history("/template-form", { state: { id } });
  };

  return (
    <>
      <TemplateWrapped
        title="Templates"
        headerButtons={
          <>
            <HeaderButton
              icon={faPlus}
              message="New Record"
              onClick={() => setDisplayForm({})}
            />
          </>
        }
      >
        <div className="panel">
          <div className="panel-body">
            <div className="row ">
              <div className="col-md-12">
                <MaterialTable
                  title=""
                  columns={tableColumns}
                  data={data}
                  actions={tableActions}
                  options={{ actionsColumnIndex: -1 }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </TemplateWrapped>
      {displayForm !== false ? (
        <TemplateForm
          data={displayForm}
          onSave={reloadData}
          onCancel={() => {
            setDisplayForm(false);
          }}
        />
      ) : null}
    </>
  );
}
