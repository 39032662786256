import moment from "moment";

export const defaultFormat = "MM/DD/yyyy";

export function getInitialAndLastDayOfMonth(
  month,
  year,
  dateFormat = defaultFormat
) {
  const dateOfCurrentYear = `${month + 1}/01/${year}`;

  const date = moment(dateOfCurrentYear, defaultFormat);

  const startDate = date.startOf("month").format(dateFormat);
  const endDate = date.endOf("month").format(dateFormat);

  return { startDate, endDate };
}

export function getInitialAndEndDate(
  startDate,
  endDate,
  dateFormat = defaultFormat
) {
  const fini = moment(startDate, dateFormat);
  const ffin = moment(endDate, dateFormat);

  // End date does not exists
  if (startDate && fini.isValid()) {
    if (ffin.isValid() === false) {
      return [startDate, startDate];
    }
  }

  // Start date doest not exists
  if (endDate && ffin.isValid()) {
    if (fini.isValid() === false) {
      return [endDate, endDate];
    }
  }

  // End date is before start date
  if (ffin.diff(fini, "days") < 0) {
    return [startDate, startDate];
  }

  return [startDate, endDate];
}

export function getDateAsString(
  d,
  fromFormat = defaultFormat,
  toFormat = defaultFormat
) {
  return moment(d, fromFormat).format(toFormat).replace("Invalid date", "");
}

export function getAllDatesBetweenTwoDates(startDate, endDate) {
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);
  const now = mStartDate;
  let dates = [];

  while (now.isSameOrBefore(mEndDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }

  return dates;
}
