import moment from "moment/moment";
import {defaultFormat, getDateAsString} from "../../../../../../util/date";
import {FILTER_KEY, FILTERS_ORDER} from "../utils";

const useBreadcrumbsFilters = (filters, currentDate, range, operations, setFilters) => {
  const {filter} = filters
  const {type = null} = filters
  const arrFilters = Object.keys(filter)

  const handleBackwardDate = () => {
    const backDate = moment(currentDate, defaultFormat).subtract(1, 'day').toDate()
    handleNavigateDate(range.startDate, backDate)
  }

  const handleForwardDate = () => {
    const forwardDate = moment(currentDate).add(1, 'days').toDate();
    handleNavigateDate(range.endDate, forwardDate)
  }

  const handleNavigateDate = (date, newDate) => {
    if (Object.keys(filters).length > 0) {
      if (getDateAsString(currentDate) !== getDateAsString(date)) {
        _setFilters(newDate)
      }
    } else {
      _setFilters(newDate)
    }
  }

  const _setFilters = date => {
    setFilters({
      ...filters,
      startDate: moment(date, "yyyy/MM/DD").toDate(),
      endDate: moment(date, "yyyy/MM/DD").toDate()
    })
  }

  const findOperationName = (value) => {
    return operations
      .filter(op => op.value === value)
      .reduce((acc, curr) => {
        return acc + curr.name
      }, '')
  }

  const handleClickFilter = (filter) => {

    const resFilters = Object.keys(FILTERS_ORDER).filter((_key, index) => {
      return index <= FILTERS_ORDER[filter]
    })
    const _obj = {filter: {}}
    const newFilters = resFilters.map((item) => {
      const obj = {
        filter: {}
      }
      if (item === 'dates' && filters.startDate && filters.endDate) {
        obj.startDate = filters.startDate && moment(filters.startDate, "yyyy/MM/DD").toDate()
        obj.endDate = filters.endDate && moment(filters.endDate, "yyyy/MM/DD").toDate()
      } else {
        if (FILTER_KEY.includes(item)) {
          const o = {}
          o[item] = filters.filter[item]
          _obj.filter = {...o, ..._obj.filter}
        } else {
          obj[item] = filters[item]
        }
      }
      return obj
    }).reduce((acc, curr) => {
      return {...acc, ...curr}
    }, {})
    newFilters.filter = _obj.filter
  }

  return {
    handleBackwardDate,
    handleForwardDate,
    findOperationName,
    handleClickFilter,
    arrFilters,
    filter,
    type
  }
}

export default useBreadcrumbsFilters