import {statusAndResponse} from "../../../util/requestHandler";
import {Api, decryptApiResponse} from "../../../config/Api";

export const getTransaction = () => {
  return statusAndResponse(
    Api.get(`api/iv/form`),
    decryptApiResponse
  );
};

export const saveTransaction = (data) => {
  return statusAndResponse(
    Api.post("/api/iv/form", data),
    decryptApiResponse
  );
};

export const updateTransaction = (data, id) => {
  return statusAndResponse(
    Api.put("/api/iv/form/" + id, data),
    decryptApiResponse
  );
};

export function deleteTransaction(transactionId) {
  return statusAndResponse(
    Api.delete(`/api/client/practice/${transactionId}`),
    decryptApiResponse
  )
}