import MissingFieldsIcon from "./components/MissingFieldsIcon";
import {
  validateCarrierName,
  validateNoActionTaken,
  verifiedIvStatus,
  verifyIsELGAndDoneBy,
  verifyRegexForPatientNameInElegibility,
} from "../../../utils/validations";
import { UNCHECKED_VERIFICATION_STATE } from "../../../utils/constanst";

const ColumnMissingFields = ({
  rowData: {
    missingRequiredInputQty = "0",
    totalRequiredFields = "0",
    type,
    iv_process_result,
    iv_status,
    carrier = "",
  },
}) => {
  const convertTotalRequiredFields = parseInt(totalRequiredFields) || 0;
  const convertMissingRequiredInputQty = parseInt(missingRequiredInputQty) || 0;

  const validations = [
    {
      condition: () => validateNoActionTaken(iv_process_result, iv_status),
      variant: 1,
      icon: "check",
    },
    {
      condition: () => validateCarrierName(carrier),
      variant: 1,
      icon: "check",
    },
    {
      condition: () => verifyRegexForPatientNameInElegibility(iv_status),
      variant: 3,
      icon: "cancel",
    },
    {
      condition: () =>
        iv_process_result.toLowerCase() === UNCHECKED_VERIFICATION_STATE,
      variant: 3,
      icon: "cancel",
    },
    {
      condition: () => verifiedIvStatus(iv_status, iv_process_result),
      variant: 3,
      icon: "cancel",
    },
    {
      condition: () => verifyIsELGAndDoneBy(type, iv_process_result),
      variant: 1,
      icon: "check",
    },
    {
      condition: () =>
        convertTotalRequiredFields === 0 &&
        convertMissingRequiredInputQty === 0,
      variant: 1,
      icon: "check",
    },
    {
      condition: () => convertMissingRequiredInputQty === 0,
      variant: 1,
      icon: "check",
    },
  ];

  for (const validation of validations) {
    if (validation.condition()) {
      return (
        <MissingFieldsIcon
          variant={validation.variant}
          icon={validation.icon}
        />
      );
    }
  }

  const completedPercentage =
    convertTotalRequiredFields === 0
      ? 0
      : (convertMissingRequiredInputQty * 100) / convertTotalRequiredFields;

  return (
    <MissingFieldsIcon
      variant={2}
      quantity={completedPercentage}
      label={`${convertMissingRequiredInputQty}/${Math.floor(
        convertTotalRequiredFields,
      )}`}
    />
  );
};

export default ColumnMissingFields;
