const reOrderElements = (data) => {
  const cleanData = data.map((el, i) => ({ orderNumber: i, ...el }));

  return cleanData.sort((a, b) => a.orderNumber - b.orderNumber);
};

export function formatDataForPreview(data) {
  const { categories } = data;

  return categories.map((category) => ({
    sectionTitle: category.name,
    subSections: category.subCategories.map((subcategory) => ({
      subSectionTitle: subcategory.name,
      bookmarks: subcategory.bookmarks.map((sbBookmark) => ({
        bookmarkTitle: sbBookmark.name,
        bookmarkValue: "",
      })),
    })),
    bookmarks: category.bookmarks.map((bookmark) => ({
      bookmarkTitle: bookmark.name,
      bookmarkValue: "",
    })),
  }));

  // return sections.map(({ title, content }) => {
  //   const bookmarks = [];
  //   const { subSections } = content.reduce(
  //     (acum, el) => {
  //       const elementLabel = el.props.label;

  //       // Create a new sub section
  //       if (el.control === SubsectionTitle) {
  //         acum.subSections[elementLabel] = {
  //           subSectionTitle: elementLabel,
  //           bookmarks: [],
  //         };

  //         // Set the reference for the upcoming elements until it changes
  //         acum.currentSubSectionsBm =
  //           acum.subSections[elementLabel]["bookmarks"];
  //       } else {
  //         let bookmarkValue = el.props.value || "-";
  //         if (
  //           typeof el.props.options !== "undefined" &&
  //           el.props.options.length > 0
  //         ) {
  //           const selecteOption = el.props.options.find((o) => o.value === "n");

  //           if (selecteOption) {
  //             bookmarkValue = selecteOption.label;
  //           }
  //         }

  //         acum.currentSubSectionsBm.push({
  //           bookmarkTitle: elementLabel,
  //           bookmarkValue,
  //         });
  //       }

  //       return acum;
  //     },
  //     {
  //       subSections: {},
  //       currentSubSectionsBm: bookmarks,
  //     }
  //   );

  //   return {
  //     sectionTitle: title,
  //     subSections: Object.values(subSections),
  //     bookmarks,
  //   };
  // });

  return data;
}

export function templateAsTree({ _id, name, description, content }) {
  const itemIsCheckbox = (i) => i["label"] && i["options"];

  function buildCategory(id, name, type, categoryType, content) {
    return {
      id,
      name,
      type,
      categoryType,
      ...buildBookmarksAndSubs(content),
    };
  }

  function buildBookmarksAndSubs(fromContent) {
    const orderedContent = reOrderElements(fromContent);

    return orderedContent.reduce(
      (a, i) => {
        // is a bookmark
        if (typeof i["bookmark"] !== "undefined" || itemIsCheckbox(i)) {
          let {
            _id: id,
            bookmark: name,
            label,
            elementType,
            options,
            affectsOn,
            orderNumber,
          } = i;
          if (itemIsCheckbox(i)) name = label;
          a.bookmarks.push({
            id,
            name,
            label,
            elementType,
            options,
            affectsOn,
            orderNumber,
          });
        }

        if (i["elementType"] === "section") {
          const { _id, categoryName, type, elementType, items } = i;
          a.subCategories.push(
            buildCategory(_id, categoryName, type, elementType, items)
          );
        }

        if (i["elementType"] == "subsection") {
          const { _id, code, label, elementType, items } = i;
          a.subCategories.push(
            buildCategory(_id, label, code, elementType, items)
          );
        }

        return a;
      },
      {
        bookmarks: [],
        subCategories: [],
      }
    );
  }

  return {
    id: _id,
    name,
    description,
    categoryType: "section",
    categories: buildBookmarksAndSubs(content)["subCategories"],
  };
}

export function bookMarkToAffect(
  { _id, elementType, bookmark, label, options, ...rest },
  isanOption = false
) {
  // Convention to know what type will be passed to backend when
  // the bookmark has multiple optios, but only one will be sended
  const usingParentAsAffected = "id";
  const usingBookmarkAsAffected = "bookmark";

  if (options) {
    return {
      id: _id,
      label,
      bookmark,
      type: usingParentAsAffected,
      items: options.map((x) => bookMarkToAffect(x, true)),
    };
  }

  if (!isanOption)
    return {
      id: _id,
      label,
      bookmark,
      type: usingParentAsAffected,
    };
  else
    return {
      id: bookmark,
      label: label,
      bookmark: bookmark,
      type: usingBookmarkAsAffected,
    };
}

export function searchBookmarks(values = []) {
  //Remove "options"
  return values.map(({ options, ...i }) => ({
    ...i,
    id: i._id,
    label: !options
      ? i.label
      : i.label +
        ": " +
        options
          .reduce((a, i) => {
            return (a += i.bookmark + " | ");
          }, "")
          .slice(0, -3),
  }));
}
