import React from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import UserHelp from "../../../util/UserHelp";
import {Formik} from "formik";
import useClientsForm from "./hooks/useClientsForm";
import Select from "react-select";
import SkeletonForm from "../../../Skeleton";
import * as Yup from 'yup';

const ClientsForm = (props) => {

  const {
    locateState,
    clientSelect,
    editing,
    setShowModalEdit,
    setShowModal,
    template,
    stateSelect,
    refreshData
  } = props
  const {
    data,
    loadCity,
    city,
    handleSubmit,
    loading
  } = useClientsForm(clientSelect, editing, setShowModalEdit, setShowModal, stateSelect, refreshData);

  const ValidateSchema = Yup.object().shape({
    templateId: Yup.string()
      .required('Select Template'),
    city: Yup.string()
      .required('Select City'),
    state: Yup.string()
      .required('Select State'),
  });

  return (
    <>
      <Container>
        {
          loading ?
            <Formik
              initialValues={data}
              validationSchema={ValidateSchema}
              enableReinitialize
              onSubmit={(values) => {
                handleSubmit(values)
              }}>
              {
                props => (
                  <form className="mt-2 p-1" onSubmit={props.handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <UserHelp className="mb-3">
                          General user's information
                        </UserHelp>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            id="name"
                            name="name"
                            required
                            label="Name"
                            value={props.values.name}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            id="email"
                            name="email"
                            label="Email"
                            required
                            value={props.values.email}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            id="phone"
                            name="phone"
                            label="Phone"
                            required
                            value={props.values.phone}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>ZipCode</Form.Label>
                          <Form.Control
                            id="zipCode"
                            name="zipCode"
                            label="zipCode"
                            required
                            value={props.values.zipCode}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>State</Form.Label>
                          <Select options={locateState}
                                  onChange={async ({label, value}) => {
                                    await loadCity(label)
                                    props.setFieldValue('state', label)
                                    console.log(props.values)
                                  }}
                                  id="state"
                                  name="state"
                                  required
                                  defaultValue={locateState.filter(
                                    (temp) => temp.label === props.values.state
                                  )}
                          />
                          {
                            props.errors.state ? ( <div className="text-danger">{ props.errors.state}</div>) : ''
                          }
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>City</Form.Label>
                          <Select options={city}
                                  onChange={({label, value}) => {
                                    props.setFieldValue('city', label)
                                  }}
                                  id="city"
                                  name="city"
                                  required
                                  defaultValue={city.filter(
                                    (temp) => temp.label === props.values.city
                                  )}
                          />
                          {
                            props.errors.city ? ( <div className="text-danger">{ props.errors.city}</div>) : ''
                          }
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            id="address"
                            name="address"
                            label="Address"
                            required
                            value={props.values.address}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Template</Form.Label>
                          <Select options={template}
                                  onChange={({label, value}) => {
                                    props.setFieldValue("templateId", value);
                                  }}
                                  id="templateId"
                                  name="templateId"
                                  defaultValue={template.filter(
                                    (temp) => temp.value === props.values.templateId
                                  )}
                          />
                          {
                            props.errors.templateId ? ( <div className="text-danger">{ props.errors.templateId}</div>) : ''
                          }
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Plan Template Id</Form.Label>
                          <Form.Control
                            id="planTemplateId"
                            name="planTemplateId"
                            label="plan Template Id"
                            required
                            maxlength={24}
                            value={props.values.planTemplateId}
                            onChange={(e) => {
                              console.log(/^[0-9a-fA-f\s_-]*$/.test(e.target.value))
                              if (/^[0-9a-fA-f\s]*$/.test(e.target.value)) {
                                props.setFieldValue('planTemplateId', e.target.value)
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Data Base</Form.Label>
                          <Form.Control
                            id="db"
                            name="db"
                            label="Data Base"
                            required
                            value={props.values.db}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Api Key</Form.Label>
                          <Form.Control
                            id="apiKey"
                            name="apiKey"
                            label="apiKey"
                            required
                            value={props.values.apiKey}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>H User</Form.Label>
                          <Form.Control
                            id="hUser"
                            name="hUser"
                            label="H User"
                            required
                            value={props.values.hUser}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Secret</Form.Label>
                          <Form.Control
                            id="secret"
                            name="secret"
                            label="secret"
                            required
                            value={props.values.secret}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Consumer</Form.Label>
                          <Form.Control
                            id="consumer"
                            name="consumer"
                            label="Consumer"
                            required
                            value={props.values.consumer}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Form Title</Form.Label>
                          <Form.Control
                            id="formTitle"
                            name="formTitle"
                            label="Form Title"
                            required
                            value={props.values.formTitle}
                            onChange={props.handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col md={4}>
                        <Button
                          className="btn btn-success mt-2"
                          type="submit"
                          size="md"
                          block
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )
              }

            </Formik> : <SkeletonForm size='lg'/>
        }

      </Container>
    </>


  );
}

export default ClientsForm
