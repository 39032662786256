import { Icon } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import CustomTable from "../../CustomTable";
import { TemplateWrapped } from "../../Layout";
import { getFullDate, getTime, millisecondsToStr } from "./aircall.utils";
import useAirCall from "./useAirCall";
import "./css/index.css";
import { HeaderFilterButton } from "../../RemarkTemplate/Body";
import AirCallFilter from "./components/AirCallFilter";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const AirCall = () => {
  const { dataSource, tableReference, refreshData } = useAirCall();
  const history = useNavigate();
  return (
    <TemplateWrapped
      title="Air Call"
      headerButtons={
        <>
          <HeaderFilterButton
            active={
              //   filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              false
            }
            render={AirCallFilter}
            componentProps={{
              setFilters: refreshData,
            }}
          />
        </>
      }
    >
      <CustomTable
        search={false}
        tableReference={tableReference}
        asyncDataSource={dataSource}
        columns={[
          {
            title: "ID",
            field: "id",
            sorting: false,
            align: "center",
          },
          {
            title: "Direction",
            render: (row) => {
              if (row.direction === "inbound") {
                return <Icon className="inboundCall">call_received</Icon>;
              }

              if (row.direction === "outbound") {
                return <Icon className="outboundCall">call_made</Icon>;
              }

              return row.direction;
            },
            sorting: false,
            align: "center",
          },
          {
            title: "Started",
            render: (row) => {
              return getFullDate(row.started_at);
            },
            sorting: false,
            align: "center",
          },
          {
            title: "Answered",
            render: (row) => {
              if (!row.answered_at) {
                return null;
              }
              return (
                <p className="mb-0">
                  {getTime(row.answered_at)}
                  <br />
                  {getTime(row.ended_at)}
                </p>
              );
            },
            sorting: false,
            align: "center",
          },
          {
            title: "Duration / Cost",
            render: (row) => {
              return (
                millisecondsToStr(row.duration * 1000) +
                " / " +
                " $ " +
                row.cost
              );
            },
            sorting: false,
            align: "center",
          },
          {
            title: "Number",
            render: (row) => {
              return row.raw_digits;
            },
            sorting: false,
            align: "center",
          },
          {
            title: "User ID",
            render: (row) => {
              if (!row.user) return null;
              return row.user.email;
            },
            sorting: false,
            align: "center",
          },
          {
            title: "User Name",
            render: (row) => {
              if (!row.user) return null;
              return row.user.name;
            },
            sorting: false,
            align: "center",
          },
        ]}
        actions={[
          {
            icon: "info",
            tooltip: "Show Details",
            onClick: (event, row) => {
              history("/air-call-detail/" + row.id);
            },
            iconProps: { className: "outboundCall" },
          },
        ]}
        pageSize={50}
      />
    </TemplateWrapped>
  );
};

export default withSelectedPractices(AirCall)
