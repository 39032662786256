import React, { useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import InfoTable from "../../../../../TransactionDetail/components/InfoTable";
import "../../../../../TransactionDetail/css/index.css";
import TransactionFiles from "../../../../../TransactionDetail/components/TransactionFiles";
import useFiles from "../../../../../TransactionDetail/hooks/useFiles";
import Verification from "../verification/Verification";

const TabsCallCenterVerification = (props) => {
  const [key, setKey] = useState("patient-info");
  const {
    data,
    hasPolicy,
    record,
    permissions,
    onUpdatePatienInfo,
    refreshSpreadsheet,
    loadRecordData,
    itemVerification,
    practiceInfoAndBookmarks,
    handleChangeVerification,
    handleSubmitVerification,
    formVerification,
    setDataDates,
    isLoadingButton,
    shouldUpdateVerification,
    updateVerificationById,
    handleChangeBookmarks,
  } = props;

  const { files, filesAreVerified, verifyFiles } = useFiles(
    itemVerification._id,
    itemVerification.practiceId,
    loadRecordData,
    record,
    false,
  );

  const handleChange = (current) => {
    if (current === "files" && !filesAreVerified) {
      verifyFiles().then((s) => {
        if (s) {
          setKey(current);
        }
      });
    } else {
      setKey(current);
    }
  };

  return (
    <div className="transaction-detail">
      <div className="panel details">
        <div className="panel-body">
          <Tabs
            activeKey={key}
            onSelect={handleChange}
            style={{ color: "#76838f !important" }}
          >
            <Tab eventKey="patient-info" title="Patient Info">
              <div className="info-table">
                {data && record && (
                  <InfoTable
                    hasPolicy={hasPolicy}
                    editable={!record?.audited}
                    item={{ ...data, iv_status: record.iv_status }}
                    onSave={onUpdatePatienInfo}
                    onRefresh={refreshSpreadsheet}
                    actions={permissions}
                    module_key="CC"
                    isLoadingButton={isLoadingButton}
                  />
                )}
                {!data && !record && (
                  <div
                    style={{
                      padding: "1rem",
                      marginTop: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner animation="border" />
                  </div>
                )}
              </div>
            </Tab>{" "}
            <Tab eventKey="files" title="Files">
              {filesAreVerified && (
                <TransactionFiles
                  data={{
                    id: record._id,
                    type: record.type,
                    patientStatus: record.iv_status,
                    carrierName: record.carrier,
                    patientName: record.patientName,
                  }}
                  files={files}
                  disabled={record.audited}
                  onSuccess={loadRecordData}
                  actions={permissions}
                  shouldShowElegibility={false}
                />
              )}
            </Tab>
            <Tab eventKey="verification" title="Verification">
              <Verification
                practiceInfoAndBookmarks={practiceInfoAndBookmarks}
                handleChangeVerification={handleChangeVerification}
                handleSubmitVerification={handleSubmitVerification}
                formVerification={formVerification}
                setDataDates={setDataDates}
                isLoadingButton={isLoadingButton}
                shouldUpdateVerification={shouldUpdateVerification}
                itemVerification={itemVerification}
                updateVerificationById={updateVerificationById}
                handleChangeBookmarks={handleChangeBookmarks}
                record={record}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabsCallCenterVerification;
