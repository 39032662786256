import styled, { css } from "styled-components";

const COLUMN_WIDTHS = ["30%", "20%", "20%", "20%", "10%"];
const getHeaderStyles = (offset = 0) => {
  return css`
    ${COLUMN_WIDTHS.reduce((a, i, index) => {
      const child = index + offset;
      return (a += `
      &:nth-of-type(${child})
        {
            width:${i} !important
        }`);
    }, "")}
  `;
};
const materialTableFirstColWidth = 45;
const materialCellPadding = 16;

export const ClientName = styled.span`
  font-weight: 400;
`;

export const DentalMationClientsContainer = styled.div`
  thead {
    th {
      ${getHeaderStyles(2)}
    }
  }
`;

export const ProductListContainer = styled.div``;

export const ProductContainer = styled.div`
  span.product-item {
    &:first-of-type {
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 1rem;
      }
      padding-left: 2.5rem;
    }
    display: inline-block;
    color: #595959;
    ${getHeaderStyles(1)}
    padding: 0  ${materialCellPadding}px
  }
  margin-bottom: 0.75rem;
`;

export const ClinicListContainer = styled.div``;

export const ClinicContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  margin-left: ${materialTableFirstColWidth}px;
`;
export const ClinicNameContainer = styled.h6`
  margin-bottom: 0.5rem;
  font-weight: 400;
  /* font-size: 0.95rem; */
  /* color: #2f343a; */
  color: #56595d;
  margin-left: ${materialCellPadding}px;
`;
