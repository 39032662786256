import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

export default function CategoryForm({
  name = "",
  type = "",
  handler,
  isSubCategory,
}) {
  const [temporaryName, setTemporaryName] = useState(name);
  const [temporaryType, setTemporaryType] = useState(type);

  const ref = useRef();

  useEffect(() => notifyChanges(), [temporaryName, temporaryType]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const notifyChanges = () => {
    handler && handler({ name: temporaryName, type: temporaryType });
  };

  return (
    <>
      <Form.Control
        type="text"
        className="mr-2"
        placeholder="Name"
        ref={ref}
        value={temporaryName}
        onClick={(ev) => ev.stopPropagation()}
        onChange={({ target: { value } }) => {
          setTemporaryName(value);
        }}
      />
      {isSubCategory && (
        <Form.Control
          type="text"
          className="mr-2"
          placeholder="Code"
          value={temporaryType}
          onClick={(ev) => ev.stopPropagation()}
          onChange={({ target: { value } }) => {
            setTemporaryType(value);
          }}
        />
      )}
      {!isSubCategory && (
        <Form.Control
          as="select"
          defaultValue={temporaryType}
          onClick={(ev) => ev.stopPropagation()}
          onChange={({ target: { value } }) => {
            setTemporaryType(value);
          }}
        >
          <option value="" disabled={true}>
            Please select a category type
          </option>
          <option value="info">info</option>
          <option value="code">code</option>
        </Form.Control>
      )}
    </>
  );
}
