import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ActionReminder,
  ConfirmActionButton,
  FileActionButton,
  FileName,
  styles,
} from "./transaction-files.styles";
import {
  fileMatchWithAllowedTypes,
  getFileExtension,
} from "./TransactionFiles.utils";

export const FileIcon = ({ fileName }) => {
  const extension = getFileExtension(fileName);

  if (!extension) {
    return null;
  }

  let icon = "file-" + getFileExtension(fileName);

  return (
    <FontAwesomeIcon
      icon={icon}
      style={styles.filePreviewImageIcon}
    ></FontAwesomeIcon>
  );
};

export const ExistingFileListItem = ({
  readOnly,
  filename,
  updateFile,
  deleteFile,
  downloadFile,
  canDelete,
  canUpdate,
  canDownload
}) => {
  const [action, setAction] = useState(false);
  const [newFile, setNewFile] = useState(false);

  const tempFileType = filename.split(".").pop();

  const allowedFileTypes =
    tempFileType === "pdf"
      ? ".pdf"
      : tempFileType.indexOf("doc") > -1
      ? ".doc,.docx"
      : undefined;

  useEffect(() => {
    if (newFile) {
      setAction("update");
    }
  }, [newFile]);

  const setDeleteAction = () => {
    setAction("delete");
  };

  const confirmAction = async () => {
    if (action === "update") {
      const res = await updateFile(newFile);
      if (res) {
        cancelAction();
      }
    } else if (action === "delete") {
      const res = await deleteFile();
      if (res) {
        cancelAction();
      }
    }
  };

  const cancelAction = () => {
    setAction(false);
    setNewFile(false);
  };

  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <div className="mb-2" style={styles.filePreviewImage}>
          <FileIcon fileName={filename} />
        </div>
        <FileName title={filename}>{filename}</FileName>
        <div className="d-flex  align-items-baseline mt-2">
          {!action ? (
            <>
              {canDownload && (
                <FileActionButton onClick={downloadFile}>
                  <i className="icon io-print" aria-hidden="true"></i>
                </FileActionButton>
              )}
              {!readOnly && canUpdate && (
                <FileActionButton as="label">
                  <i className="icon io-pencil" aria-hidden="true"></i>
                  <input
                    type="file"
                    accept={allowedFileTypes}
                    onChange={({ target }) => {
                      const file = target.files[0];
                      if (!file) return;

                      if (
                        !fileMatchWithAllowedTypes(
                          allowedFileTypes.replace(/\./g, "").split(","),
                          file
                        )
                      ) {
                        toast.error("Dear user, please use the same file type");
                        return false;
                      }

                      setNewFile(file);
                    }}
                  />
                </FileActionButton>
              )}
              {!readOnly && canDelete && (
                <FileActionButton onClick={setDeleteAction}>
                  <i className="icon io-trash" aria-hidden="true"></i>
                </FileActionButton>
              )}
            </>
          ) : (
            <>
              <ActionReminder>
                {action === "update" ? "Update file?" : "Delete file?"}
              </ActionReminder>
              <div className="d-flex">
                <ConfirmActionButton
                  onClick={confirmAction}
                  size="sm"
                  className="mr-2 text-info"
                  variant="action"
                >
                  <i className="icon io-check" aria-hidden="true"></i>
                </ConfirmActionButton>
                <ConfirmActionButton
                  onClick={cancelAction}
                  className="text-danger"
                  size="sm"
                >
                  <i className="icon io-x" aria-hidden="true"></i>
                </ConfirmActionButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
