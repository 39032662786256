import {TemplateWrapped} from "../../Layout";
import CustomPanel from "../../CustomPanel";
import SummaryReportForm from "../../Reports/SummaryReportForm";
import {useEffect, useState} from "react";
import {getClients} from "../ClientPermissions/clientPermissions.service";
import {generateReport} from "./clientCostReport.service";
import {toast} from "react-toastify";

const formatClients = (clientResponse) => {
  const {records} = clientResponse;

  return records.map(client => ({
    id: client._id,
    name: client.name,
  })).map((client) => ({
    value: client.id,
    label: client.name,
  }))
}

const ClientCostReport = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
      async function fetchClients() {
        const [status, clients] = await getClients();

        if (status) {
          setClients(formatClients(clients));
        }
      }

      fetchClients();
  }, []);

  const handleSubmit = async (values) => {
    const currentClient = clients.find(client => client.value === values.clientId);
    const [state, files] = await generateReport(values);

    if (!state || !files) {
      toast.error("Error downloading the file")
      return;
    }
    const reportName = `${currentClient.label}_${values.startDate}_${values.endDate}_report.xlsx`;

    const url = window.URL.createObjectURL(new Blob([files.data], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', reportName);
    document.body.appendChild(link);
    link.click();
    toast.success(
      'The file has been downloaded successfully'
    );
  }

  return (
    <TemplateWrapped>
      <CustomPanel>
        <SummaryReportForm
          onSubmit={handleSubmit}
          labelSelection="Client"
          initialValues={{
            clientId: '',
            startDate: '',
            endDate: ''
          }}
          options={clients}
          selectName="clientId"
        />
      </CustomPanel>
    </TemplateWrapped>
  );
}

export default ClientCostReport;
