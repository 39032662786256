import {useState} from "react";
import {getUrlParams, removeUrlParams, setURLParams} from "../../../../../../util/browser";
import {withoutEmptyKeys} from "../../../../../../util/object";
import {initialBotFilter} from "../../../hooks/useAuditTrait";

const urlKeys = Object.keys(initialBotFilter)

const useFilterBots = (refreshData, practiceId) => {
  const [filter, setFilter] = useState({
    ...initialBotFilter,
    ...getUrlParams(Object.keys(initialBotFilter))
  })

  const handleSetFilter = (filterValues) => {
    filterFunctionality(filterValues)
  }

  const handleReset = () => {
    removeUrlParams(urlKeys)
    setFilter({...initialBotFilter, practiceId})
    refreshData({...initialBotFilter, practiceId})
  }

  const filterFunctionality = (filterValues) => {
    const removeEmptyKeys = withoutEmptyKeys(filterValues)
    let _filterValues = {...removeEmptyKeys}

    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys)
      setURLParams(_filterValues)
    }

    setFilter({..._filterValues})
    refreshData({..._filterValues, practiceId})
  }

  return {
    filter,
    handleSetFilter,
    handleReset
  }
}

export default useFilterBots