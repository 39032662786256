import { VERIFICATION_BY_COLOR } from "./constanst";

export const verificationByLookupTable = (verificationBy) => {
  const lookupTable = {
    warning: "warning",
    success: "success",
    Empty: "danger",
    danger: "danger",
  };

  return lookupTable[verificationBy] || "noStatus";
};

export const getVerificationByStatus = (status) => {
  if (status === "") return VERIFICATION_BY_COLOR["danger"];
  const _status = verificationByLookupTable(status);

  return VERIFICATION_BY_COLOR[_status] || VERIFICATION_BY_COLOR["noStatus"];
};

export const getEligibilityStatus = (iv_status) => {
  let status = "danger";

  if (iv_status.toUpperCase().indexOf("INACTIVE") > -1) {
    status = "warning";
  } else if (iv_status.toUpperCase().indexOf("ACTIVE") > -1) {
    status = "success";
  } else if (iv_status.toUpperCase().includes("NO ACTION TAKEN")) {
    status = "success";
  }

  return status;
};

/*export const buildFilterData = (filters) => {
  if (filters.startDate && filters.endDate) {
    filters.startDate =
      typeof filters.startDate === "string"
        ? filters.startDate
        : getDateAsString(filters.startDate);
    filters.endDate =
      typeof filters.endDate === "string"
        ? filters.endDate
        : getDateAsString(filters.endDate);
  }
  const copyFilters = { ...filters };
  const { filter, ...res } = copyFilters;
  res.filter = processFilters(filter);
}*/
