import React from "react"
import DragDropUpload from "../../../../DragDropUpload";
import useLoadExcel from "./hooks/useLoadExcel";
import TableExcel from "../TableExcel";
import CustomModal from "../../../../CustomModal";
import LinkFeeScheduleUpdate from "./components/LinkFeeScheduleUpdate";
import {Col} from "react-bootstrap";
import Issues from "./components/Issues";

const LoadExcel = (
  {
    selectedYear,
    selectedPractice,
    showUpload = true,
    edit = false,
    carriers,
    toUpdate = false
  }
) => {
  const {
    data,
    sheets,
    selectedSheet,
    newName,
    duplicates,
    wrongPrice,
    showModal,
    setNewName,
    handleChange,
    handleSheetChange,
    processSheet,
    setWrongCodes,
    updateData,
    setDuplicateCodes,
    deleteRow,
    arrSelected,
    setRowsData,
    deleteSelected
  } = useLoadExcel(selectedPractice, selectedYear, edit, carriers, toUpdate)

  return (
    <>
      <Col md={{span: 4, offset: 0}} className='mb-3'>
        {showUpload && (
          <DragDropUpload
            fileTypes='.xlsx'
            handleChange={handleChange}
          />
        )}
      </Col>
      {sheets.length > 0 && (
        <>
          <Col md={{span: 4, offset: 0}}>
            <select
              disabled={toUpdate}
              name="sheets"
              id="sheets"
              className='form-control'
              onChange={({target}) => {
                handleSheetChange(target.value)
              }}
            >
              <option value disabled selected>Select a sheet</option>
              {sheets.map(sheet => (
                <option key={sheet} value={sheet}>{sheet}</option>
              ))}
            </select>
          </Col>
          {selectedSheet && !edit && (
            <>
              <Col md={4} className='text-center'>
                <input
                  value={newName}
                  type="text"
                  id='newName'
                  name='newName'
                  className='form-control'
                  onChange={(e) => {
                    const value = e.target.value
                    setNewName(value)
                  }}
                  onBlur={(e) => {
                    const val = e.target.value
                    if (val === '') {
                      setNewName(selectedSheet)
                    }
                  }}
                />
                <label className='text-muted blue-grey-400 font-weight-100 ml-1 pt-2 text-center' htmlFor="newName">FeeSchedule Name</label>
              </Col>
            </>
          )}
        </>
      )}
      <Col md={2}>
        {(!edit && selectedSheet) && (
          <button
            className='btn-md btn-action btn btn-primary btn-block'
            onClick={() => {
              processSheet([])
            }}
          >
            Save
          </button>
        )}
      </Col>

      <Issues
        duplicates={duplicates}
        wrongPrice={wrongPrice}
        setDuplicateCodes={setDuplicateCodes}
        setWrongCodes={setWrongCodes}
        arrSelected={arrSelected}
        edit={edit}
        selectedSheet={selectedSheet}
        setRowsData={setRowsData}
      />

      {data && (
        <TableExcel
          selectedSheet={selectedSheet}
          data={data}
          processSheet={processSheet}
          edit={edit}
          updateData={updateData}
          deleteRow={deleteRow}
          arrSelected={arrSelected}
          deleteSelected={deleteSelected}
        />
      )}
      <CustomModal
        show={showModal}
        title='Fee Schedule'
        footer={true}
      >
        <LinkFeeScheduleUpdate
          carrier={selectedSheet}
          year={selectedYear}
          practice={selectedPractice}
        />
      </CustomModal>
    </>
  )
}

export default LoadExcel