import ShareBookmarksContext from "./ShareBookmarksContext";
import useShareBookmarks from "../ShareBookmarks/hooks/useShareBookmarks";

const ShareBookmarksContextProvider = (props) => {
  return(
    <ShareBookmarksContext.Provider value={useShareBookmarks()}>
      {props.children}
    </ShareBookmarksContext.Provider>
  )
}

export default ShareBookmarksContextProvider