import { createRef, useEffect, useLayoutEffect, useState } from "react";
import CustomTable from "..";
import { withoutEmptyKeys } from "../../../util/object";

const recordsField = "records";
const countField = "recordsQty";

const formatResponse = (page, records = [], quantity = 0) => {
  return CustomTable.createTableResponse(records, page, quantity);
};

const paramsInitialState = {};

export function useAsyncTableData(
  fnRetrieveData = (params) => {},
  initialData = paramsInitialState,
  processOrderBy = null
) {
  const [params, setParams] = useState(initialData);

  const ref = createRef(null);

  useLayoutEffect(() => {
    if (params !== initialData && ref.current != null) {
      ref.current.onQueryChange();
    }
  }, [params]);

  const getRecords = ({
    page,
    pageSize,
    search = "",
    orderDirection: sort,
    ...query
  }) => {
    
    const obj = {...params, sort, search}
    let objFilters = {}

    if (processOrderBy) {
      objFilters = processOrderBy(sort, query)
    }

    return fnRetrieveData({
      ...withoutEmptyKeys({...obj, ...objFilters}),
      limit: pageSize,
      offset: page ? pageSize * page : 0,
    })
      .then(([s, r]) => {
        if (!s) {
          return formatResponse(page, [], 0);
        }
        return formatResponse(page, r[recordsField] || [], r[countField] || 0);
      })
      .catch((r) => {
        return formatResponse(page, [], 0);
      });
  };

  function getParams() {
    return params;
  }

  return {
    tableReference: ref,
    getParams,
    dataSource: getRecords,
    refreshData: (p = { ...params }) => {
      setParams(p);
    },
  };
}
