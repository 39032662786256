import styled from "styled-components";

export const PracticeItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;

  &::-webkit-scrollbar-track {
    background-color: rgb(240 240 240);
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d5d5da;
    border-radius: 4px;
  }
`;

export const PracticeItem = styled.div`
  flex: 0 0 auto;
  padding: 0.75rem 0;
  margin-bottom: 0.25rem;
  border-radius: 4px;
  color: #121212;
  cursor: pointer;
  &:hover {
    background-color: #d9e9ff;
  }
`;
