import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import CustomTable from "../../CustomTable";
import useCredentialsList from "./hooks/useCredentialsList";
import { Edit } from "@material-ui/icons";
import { TemplateWrapped } from "../../Layout";
import { HeaderButton } from "../../RemarkTemplate/Body";

const CredentialsList = () => {
  const history = useNavigate();
  const { credentials } = useCredentialsList();
  const columns = [
    { title: "Client", field: "client" },
    { title: "Practice", field: "practice" },
    {
      title: "Created at",
      field: "dateCreated",
      render: ({ dateCreated }) => (
        <span>{moment(dateCreated).format("MMMM Do YYYY, h:mm:ss a")}</span>
      ),
    },
  ];
  const actions = [
    (rowData) => ({
      icon: () => <Edit color="primary" />,
      tooltip: "Edit",
      onClick: () => {
        goToRecord(rowData);
      },
    }),
  ];

  const goToRecord = (rowData) => {
    const { _id, practice, client } = rowData;
    history("/credential-edit", { state: { id: _id, client, practice } });
  };

  return (
    <TemplateWrapped
      title="Credentials List"
      headerButtons={
        <>
          <HeaderButton
            icon={faPlus}
            message="Add a new credential"
            onClick={() => {
              history("/credential-form");
            }}
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <Row>
            <Col md={12}>
              <CustomTable
                actions={actions}
                columns={columns}
                data={credentials}
              />
            </Col>
          </Row>
        </div>
      </div>
    </TemplateWrapped>
  );
};

export default CredentialsList;
