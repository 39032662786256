import styled from 'styled-components';
import {Accordion, Col, Row} from "react-bootstrap";
import {AccordionItem} from "../../CustomAccordion";
import {IssueStatus} from "./IssueHeader";
import ScrollbarWrapper, {Content} from "../../util/Styled/ScrollbarWrapper";

const WrappedBody = styled.div`
  padding: 2rem;
`

const Wrapped = styled.div`
  //padding: 0.5rem;
`

const User = styled.span`
  font-size: 0.9rem;
  color: #000;
  margin-bottom: 0.5rem;
`
const UserName = styled.span`
  font-weight: 500;
  color: #0080c0;
`

const Data = styled.div`
  flex: 0 1 90%;
`

const DataWrapper = styled(Wrapped)`
  margin-top: .625rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${({noPadding}) => noPadding ? '0' : '0 1rem'};
  margin-bottom: 2rem;
`

const Label = styled.span`
  font-weight: 500;
  color: #000;
`

const StepsToReproduce = styled.div`
  text-align: justify;
  padding: .625rem 0;
`

const WrappedInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 2rem;
`

const DateCreated = styled.div`
  font-size: 0.9rem;
  color: #000;
`

export const WrappedNav = styled.div`
  .nav-link {
    border-radius: unset;
    color: #000;
    font-weight: 500;
    //font-size: 0.9rem;
    &:hover {
      color: #0080c0;
      background-color: #fff;
    }
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-link.active {
    color: #000;
    background-color: #fff;
    border-right: solid 2px #3e8ef7;
    font-size: 1.1rem;
    font-weight: 500;
  }
`

const IssueBody = (
  {
    user,
    username,
    details,
    stepReproduce,
    responses = [],
    setShowModalResponse,
    created,
  }
) => {
  return (
    <WrappedBody>
      <WrappedInfo>
        <Wrapped>
          <UserName>{username}</UserName>
          (<User>{user}</User>)
          <IssueStatus status={'open'}>open</IssueStatus>
        </Wrapped>
        <DateCreated>
          {created}
        </DateCreated>
      </WrappedInfo>
      <DataWrapper>
        <Label>Details:</Label>
        <Data className='text-justify'>{details}</Data>
      </DataWrapper>
      <DataWrapper
        noPadding
      >
        <Accordion>
          <AccordionItem
            headerIsClickeable
            eventKey={1}
            renderHeader={() => <Label>Show Steps to Reproduce</Label>}
            renderBody={() => <StepsToReproduce>{stepReproduce}</StepsToReproduce>}
          />
        </Accordion>
      </DataWrapper>
      <DataWrapper>
        <Label>Responses</Label>
        <Responses
          responses={responses}
          setShowModalResponse={setShowModalResponse}
        />
      </DataWrapper>
    </WrappedBody>
  )
}

const ChatWrapped = styled.div`
  min-height: 100px;
  max-height: 500px;
`

const Chat = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
`

const ChatUser = styled.div`
  font-size: 0.9rem;
  color: #000;
  align-self: ${({side}) => side ? 'flex-end' : 'flex-start'};
`

const ChatMessage = styled.span`
  color: #1b1b1b;
  background-color: #C4E2F0;
  min-width: 300px;
  display: inline-block;
  padding: .625rem;
  border-radius: 5px;
  align-self: ${({side}) => side ? 'flex-end' : 'flex-start'};
  font-size: .9rem;
`

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`

const ChatInfo = styled.div`
  align-self: ${({side}) => side ? 'flex-end' : 'flex-start'};
  text-align: ${({side}) => side ? 'right' : 'left'};
`

const Responses = ({responses, setShowModalResponse}) => {
  return (
    <>
      <ScrollbarWrapper>
        <Content>
          <ChatWrapped>
            {responses.length > 0 ? responses.map((response, index) => {
              return (
                <ChatContent key={index}>
                  <Chat>
                    <ChatInfo side={index % 2 === 0}>
                      <ChatUser>
                        {response.user}
                      </ChatUser>
                      <div>{new Date(response.created).toDateString()}</div>
                    </ChatInfo>
                    <ChatMessage
                      side={index % 2 === 0}
                    >
                      {response.message}
                    </ChatMessage>
                  </Chat>
                </ChatContent>
              )
            }): (<div className='jumbotron text-center'>No response yet</div>)}
          </ChatWrapped>
        </Content>
      </ScrollbarWrapper>
      <Row className='align-items-md-end justify-content-end mt-5'>
        <Col lg={{span: '3', offset: '4'}}>
          <button
            className='btn btn-success btn-block'
            onClick={() => setShowModalResponse(true)}
          >
            Add response
          </button>
        </Col>
      </Row>
    </>
  )
}

export default IssueBody;