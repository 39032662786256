import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateNotes } from "../../../../services/missingCode";
import CustomForm from "../../../CustomForm";

export default function TransactionNote({
  transactionId,
  initialNote = "",
  onSave,
}) {
  const [note, setNote] = useState(initialNote);
  const [editing, setEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setNote(initialNote);
    setEditing(false);
    setIsSaving(false);
  }, [initialNote]);

  const reset = () => {
    setNote(initialNote);
    setEditing(false);
  };

  const beginEdition = () => {
    setEditing(true);
  };

  const updateNoteValue = (v) => {
    setNote(v);
  };

  const saveChanges = () => {
    setIsSaving(true);
    updateNotes(transactionId, note)
      .then(([s, r]) => {
        if (s) {
          toast.success("Note saved successfully");
          onSave(note);
        } else {
          setIsSaving(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  });

  return (
    <div className="d-flex align-items-center">
      <strong className="d-inline-block mr-2">Notes: </strong>
      {!editing ? (
        <>
          <span>{note}</span>
          <Button variant="link" onClick={beginEdition}>
            <FontAwesomeIcon icon="edit" /> {note === "" ? "Add a note" : ""}
          </Button>
        </>
      ) : (
        <div className="d-flex">
          <CustomForm.Text
            className="mb-0"
            placeholder="Transaction's Notes"
            onChange={updateNoteValue}
            value={note}
            controlRef={ref}
          />
          <div className="ml-2">
            {!isSaving ? (
              <>
                <Button variant="link" onClick={saveChanges}>
                  <FontAwesomeIcon icon="check" />
                </Button>
                <Button variant="link text-danger" onClick={reset}>
                  <FontAwesomeIcon icon="times" />
                </Button>
              </>
            ) : (
              <FontAwesomeIcon spin icon="spinner" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
