import { useEffect, useState } from "react";
import { useTemplateWrapped } from "./components/Layout";
import * as auth from "./util/auth";
import * as config from "./App.config";
import { loadMenuAndPermissions } from "./App";
import { addMiddleware, removeMiddleware } from "./config/Api";

// TODO contemplar caso cuando hace un refresh

const notifier = (function () {
  let subscribers = [];

  function notify(d) {
    subscribers.forEach((s) => s(d));
  }

  function subscribe(fn) {
    subscribers.push(fn);
  }

  function unsuscribe(fn) {
    subscribers = subscribers.filter((x) => x !== fn);
  }

  return {
    notify,
    subscribe,
    unsuscribe,
  };
})();

export function useApp() {
  const templateWrappedCtx = useTemplateWrapped();
  const [authLoaded, setAuthLoaded] = useState(false);
  const [mustAuthenticate, setMustAuthenticate] = useState(false);
  const [userData, _setUserData] = useState({
    userId: "",
    practiceId: "",
    practiceName: "",
    practices: [],
    selectedPractices: [],
    showPracticeModal: false,
    sortConfiguration: {}
  });

  const contextHandler = {
    get: (key) => {
      return userData[key] || "";
    },
    set: (key, value) => {
      _setUserData((oldData) => ({
        ...oldData,
        userId: auth.getLoggedInData()["userId"],
        [key]: value,
      }));
    },
  };

  useEffect(() => {
    if (authLoaded) {
      auth.setLocalData(userData);
    }
  }, [userData, authLoaded]);

  useEffect(() => {
    config.handleSRRequests(notifier.notify, setMustAuthenticate);
    config.onInitialLogin(loadMenuAndPermissions, _setUserData, setAuthLoaded);
  }, []);

  useEffect(() => {
    const addClientToRequest = (config) => {
      const practiceId = userData.selectedPractices.length > 0 ? userData.selectedPractices[0] : "";
      const selectedPractice = (userData.practices || []).find(
        (x) => x._id === practiceId
      );

      if (selectedPractice) {
        if (config.method === "get") {
          config["params"] = config["params"] || {};
          config["params"]["clientId"] = config["params"]["clientId"]  || selectedPractice.clientId;
        } else {
          if (config["data"] && config["data"] instanceof FormData) {
            if (!config['data']['clientId']){
              config["data"].append("clientId", selectedPractice.clientId);
            }
          } else {
            if (typeof config["data"] !== "string") {
              config["data"] = config["data"] || {};
              if (!config['data']['clientId']){
                config["data"]["clientId"] = selectedPractice.clientId;
              }
            }
          }
        }
      }
      return config;
    };
    addMiddleware("request", addClientToRequest);

    return () => {
      removeMiddleware("request", addClientToRequest);
    };
  }, [userData.practices, userData.selectedPractices]);

  return {
    authLoaded,
    contextHandler,
    templateWrappedCtx,
    mustAuthenticate,
    setMustAuthenticate,
    ssrSubscriber: notifier,
  };
}
