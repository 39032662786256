import { useState } from "react";

export default function useSelectedCategory() {
  const [selected, setSelected] = useState();

  function _setSelected(parentCategory, category, isBeignSelected) {
    if (isBeignSelected === false) {
      setSelected(parentCategory);
      return;
    }

    setSelected(category);
  }

  return [selected, _setSelected, setSelected];
}
