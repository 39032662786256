import {useEffect, useState} from "react";
import {
  deleteUserColumn,
  getColumnList,
  getUserColumnConfiguration,
  saveUserColumn
} from "./column-configuration.service";
import {transformColumnList} from "./column-configuration.middleware";
import {findIndexByProperty} from "../../../util/arrays";

const CURRENT_GRID = 'iv';

const updateArrayOfColumnsConfiguration = (arr, columnId, replace) => {
  const index = findIndexByProperty(arr, '_id', columnId);
  let newArr = [...arr];
  let currentItem = {...arr[index]}

  if (index > -1) {
    newArr[index] = {...currentItem, ...replace}
  }
  return newArr
}

const useColumnsConfiguration = () => {
  const [data, setData] = useState([]);
  const [currentGrid] = useState(CURRENT_GRID);
  const changeColumnState = async (state, userColumn, columnId) => {
    let updatedColumn;

    if (state) {
      const deleteResponse = await deleteUserColumn(currentGrid, userColumn);
      updatedColumn = deleteResponse ? {active: false, userColumn: null} : null
    } else {
      const newUserColumn = await saveUserColumn(currentGrid, columnId);
      updatedColumn = newUserColumn ? {active: true, userColumn: newUserColumn} : null
    }

    if (updatedColumn) {
      const newArr = updateArrayOfColumnsConfiguration(data, columnId, updatedColumn);
      setData(newArr)
    }
  }

  useEffect(() => {
    async function loadData() {
      const [columnList, userConfig] = await Promise.all([getColumnList(currentGrid), getUserColumnConfiguration(currentGrid)]);
      const merged = transformColumnList(columnList, userConfig)
      setData(merged)
    }

    loadData();
  }, [])

  return {
    data,
    changeColumnState
  }
}

export default useColumnsConfiguration
