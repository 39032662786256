import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";
import { Loader } from "../../../formControls";
import PhoneNumber from "../../../formControls/PhoneNumber";
import { TemplateWrapped } from "../../../Layout";
import { AccordionList } from "../../../util/CheckList";
import UserHelp from "../../../util/UserHelp";
import "./styles/index.css";
import useUserForm, { defaultState } from "./useUserForm";
import PermissionsAccordion from "./components/PermissionsAccordion";
import withSelectedPractices from "../../../HOCS/withSelectedPractices/withSelectedPractices";

export function Presentational({
  data = {
    ...defaultState,
    permissions: [],
    practices: [],
    currentPermisions: [],
    paths: []
  },
  actions = {
    create_users: false,
    remove_users: false,
  },
  userId = null,
  saving = false,
  setCurrentPractices = () => {/**/},
  handleError = () => {/**/},
  handleChange = () => {/**/},
  handleSubmit = () => {/**/},
  handleChangePermission = () => {/**/},
  resetPassword
}) {

  function handleInputChange({ target: { value, name } }) {
    handleChange(name, value);
  }

  function handleErrors({ status, control: name }) {
    handleError(name, status);
  }

  const filterPaths = data.paths.filter(item => data.currentPermisions.includes(item.permissionId));

  return (
    <form
      autoComplete="off"
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit();
      }}
    >
      {actions.create_users && <Button variant="info">Create User</Button>}
      {actions.remove_users && <Button variant="danger">Delete User</Button>}
      <Container>
        <div className="panel panel-line">
          <div className="panel-heading">
            <h3 className="panel-title">General data</h3>
          </div>
          <div className="panel-body">
            <UserHelp className="mb-3">General user's information</UserHelp>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={data.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={data.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneNumber
                    id="phone"
                    className="form-control"
                    value={data.phone}
                    onChange={(value) => {
                      handleChange("phone", value);
                    }}
                    onError={handleErrors}
                    required={!data.forcePinEmail}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Login E-mail</Form.Label>
                  <Form.Control
                    id="user"
                    name="user"
                    label="Email"
                    placeholder="email@example.com"
                    value={data.user}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            {!userId && (
              <Row>
                {data.createPassword && (
                  <>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          id="password"
                          name="password"
                          label="Password"
                          value={data.password}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          label="Confirm password"
                          value={data.confirmPassword}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col md={12}>
                  <label htmlFor="createPassword">
                    <input
                      className='mr-1'
                      type="checkbox"
                      name='createPassword'
                      id='createPassword'
                      onChange={() => {
                        resetPassword()
                      }}
                    />
                    Create Password?
                  </label>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <label htmlFor="forcePinEmail">
                  <input
                    className='mr-1'
                    checked={data.forcePinEmail}
                    type="checkbox"
                    name='forcePinEmail' id='forcePinEmail'
                    onChange={() => {
                      handleChange('forcePinEmail', !data.forcePinEmail)
                    }}
                  />
                  Send PIN to email
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <label htmlFor="canUseMultiPractice">
                  <input
                    className='mr-1'
                    checked={data.canUseMultiPractice}
                    type="checkbox"
                    name='canUseMultiPractice'
                    id='canUseMultiPractice'
                    onChange={() => {
                      handleChange('canUseMultiPractice', !data.canUseMultiPractice)
                    }}
                  />
                  Can use multi practice
                </label>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md={6}>
                <div className="panel panel-info panel-line">
                  <div className="panel-heading">
                    <h3 className="panel-title px-0">
                      Application Permissions
                    </h3>
                  </div>
                  <div className="panel-body px-0">
                    <UserHelp className="mb-3">
                      Expand each individual list to see the assignable
                      permissions
                    </UserHelp>
                    <PermissionsAccordion
                      data={data.permissions}
                      selected={data.currentPermisions}
                      setSelected={handleChangePermission}
                    />
                    <Form.Group className="mt-4">
                      <Form.Label>Default Page</Form.Label>
                      <Form.Control
                        id="defaultPath"
                        name="defaultPath"
                        as="select"
                        value={data.defaultPath}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">
                          Please select an option
                        </option>
                        {
                          filterPaths.map(path => {
                            return (
                              <option key={path.permissionId} value={path.path}>
                                {path.name}
                              </option>
                            );
                          })
                        }
                      </Form.Control>
                      <small className="text-muted">
                        *Based on selected permissions
                      </small>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="panel panel-warning panel-line">
                  <div className="panel-heading">
                    <h3 className="panel-title px-0">Practices</h3>
                  </div>
                  <div className="panel-body px-0">
                    <UserHelp className="mb-3">
                      Expand each individual list to see the asignable practices
                    </UserHelp>
                    <AccordionList
                      data={data.practices}
                      selected={data.currentPractices}
                      setSelected={setCurrentPractices}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Button
                disabled={saving}
                type="submit"
                variant="success btnForm mt-3"
                size="md"
                block
              >
                {saving && "Please wait "}
                {saving && <FontAwesomeIcon icon="spinner" spin />}
                {!saving && "Save"}
              </Button>
            </Row>
          </div>
        </div>
      </Container>
    </form>
  );
}

const UserForm = ({ actions = {} }) => {
  const location = useLocation();

  const id = location.state ? location.state.id : null;

  const {
    loading,
    dataSaved,
    saving,

    data,
    paths,

    permissions,
    currentPermisions,
    practices,
    currentPractices,
    userId,
    handleChangePermission,
    resetPassword,
    ...handlers
  } = useUserForm(id);

  return (
    <TemplateWrapped title="User Form">
      <div className="panel">
        <div className="panel-body">
          {!loading && (
            <Presentational
              data={{
                ...data,
                permissions,
                currentPermisions,
                practices,
                currentPractices,
                paths,
              }}
              userId={userId}
              actions={actions}
              saving={saving}
              handleChangePermission={handleChangePermission}
              resetPassword={resetPassword}
              {...handlers}
            />
          )}
          {dataSaved && <Navigate to="/users" />}
          <Loader active={loading}></Loader>
        </div>
      </div>
    </TemplateWrapped>
  );
}

export default withSelectedPractices(UserForm)