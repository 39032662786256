import React from "react";
import CustomTable from "../CustomTable";
import {
  WrappedFilterPractices,
  WrapperBreadcrumbsFilters,
} from "../pages/AllTransactions/components/styles/filter.styles";
import ClientSelected from "../pages/Connections/components/ClientSelected";
import BreadcrumbsFilters from "../pages/AllTransactions/components/BreadcrumbsFilters/components/BreadcrumbsFilters";
import SortConfiguration from "../pages/AllTransactions/components/SortConfiguration";
import {
  HeaderColumn,
  HeaderColumnTr,
  Title,
} from "../pages/AllTransactions/components/AlltransactionTable.style";
import "../../styles/all-transactions-table.css";
import { GridText } from "../pages/AllTransactions/components/styles/AllTransactionTable.style";
import { getDateAsString } from "../../util/date";

const lookupTableIvStatus = {
  appointment_date: "dateToOrder",
  patientName: "patientName",
  carrier: "carrier",
  iv_process_result: "iv_process_result",
  practiceName: "practiceName",
  eligibility: "priorityOrder",
};

const MAX_COLUMNS = 7;

const filterColumns = (columns, permissionColumns) => {
  return columns.filter((column) =>
    permissionColumns.includes(column.permission),
  );
};

const filterColumnsByPermissions = (columns, permissionColumns) => {
  return columns.filter(
    (column) =>
      !column.permission || permissionColumns.includes(column.permission),
  );
};

const TransactionTable = (props) => {
  const {
    colorFlagColumn,
    columns,
    actionsColumns,
    tableReference,
    asyncDataSource,
    pageSize = 25,
    filters,
    currentDate,
    sortTransaction,
    range,
    handleSetFilters,
    filterOperation,
    visibleColumns,
    actions,
  } = props;

  let columnsV2 = [
    {
      sorting: false,
      title: "Appointment",
      field: "appointment_date",
      align: "center",
      render: ({ appointment_date }) => {
        return (
          <GridText>{getDateAsString(appointment_date, "yyyy-MM-DD")}</GridText>
        );
      },
      permission: "Appointment",
    },
    {
      align: "center",
      field: "patientName",
      permission: "Patient Name",
      render: ({ patientName }) => {
        return <GridText>{patientName}</GridText>;
      },
      sorting: false,
      title: "Patient Name",
    },
    {
      title: "Practice",
      field: "practiceName",
      align: "center",
      render: ({ practiceName }) => {
        return <GridText>{practiceName}</GridText>;
      },
      permission: "Practice",
    },
    {
      sorting: false,
      title: "Type",
      align: "center",
      render: ({ type }) => {
        return <GridText>{type}</GridText>;
      },
      permission: "Type",
    },
    {
      sorting: false,
      title: "Carrier",
      field: "carrier",
      align: "center",
      render: ({ carrier }) => {
        return (
          <GridText>
            {carrier === "" || carrier.toLowerCase() === "empty"
              ? "No Insurance Company"
              : carrier}
          </GridText>
        );
      },
      permission: "Carrier Name",
    },
  ];

  const { priority, ...sort } = sortTransaction ? sortTransaction : {};
  const columnsFiltered = filterColumns(
    columnsV2.concat(columns),
    visibleColumns,
  );

  const actionsColumnsPermissions = filterColumnsByPermissions(
    actionsColumns,
    Object.keys(actions),
  );

  return (
    <>
      <CustomTable
        search={false}
        rowStyle={(rowData) => {
          return {
            backgroundColor: rowData.audited ? "#e8f9ff7a" : "#fff",
          };
        }}
        columns={[
          ...colorFlagColumn,
          ...columnsFiltered,
          ...actionsColumnsPermissions,
        ]}
        tableReference={tableReference}
        asyncDataSource={asyncDataSource}
        gridCssClass={`all-transactions-table width-column-${
          visibleColumns.length === MAX_COLUMNS ? "all" : visibleColumns.length
        }`}
        pageSize={pageSize}
        components={{
          ...CustomTable.Components,
          Pagination: (props) => (
            <CustomTable.Components.Pagination options={[25, 50]} {...props} />
          ),
          Toolbar: (_props) => (
            <WrapperBreadcrumbsFilters>
              <WrappedFilterPractices>
                <ClientSelected />
                <BreadcrumbsFilters
                  filters={filters}
                  setFilters={handleSetFilters}
                  currentDate={currentDate}
                  range={range}
                  operations={filterOperation}
                />
              </WrappedFilterPractices>
              <SortConfiguration />
            </WrapperBreadcrumbsFilters>
          ),
          Header: (props) => {
            const { columns: cols } = props;
            return (
              <thead>
                <HeaderColumnTr>
                  {cols.map((col, ind) => {
                    const i = lookupTableIvStatus[col.field];
                    const sortValue = sort[i];
                    return (
                      <HeaderColumn key={ind}>
                        <Title border={sortValue}>{col.title}</Title>
                      </HeaderColumn>
                    );
                  })}
                </HeaderColumnTr>
              </thead>
            );
          },
        }}
        options={{ draggable: false }}
      />
    </>
  );
};

export default TransactionTable;
