import React from "react";
import DateNavigate from "../../../../AuditTrait/components/DateNavigate";
import { getDateAsString } from "../../../../../../util/date";
import {
  FiltersWrapped,
  Breadcrumbs,
} from "../styles/BreadcrumbsFilters.styles";
import FilterItem from "./FilterItem";
import FilterItemWithIcon from "./FilterItemWithIcon";
import { FILTER_REFERENCE } from "../utils";
import useBreadcrumbsFilters from "../hooks/useBreadcrumbsFilters";

const BreadcrumbsFilters = ({
  filters = {},
  setFilters,
  currentDate,
  range = {},
  operations,
}) => {
  const {
    arrFilters,
    filter,
    type,
    handleBackwardDate,
    handleForwardDate,
    handleClickFilter,
    findOperationName,
  } = useBreadcrumbsFilters(
    filters,
    currentDate,
    range,
    operations,
    setFilters,
  );

  return (
    <FiltersWrapped>
      <DateNavigate
        currentDate={currentDate}
        handleForwardStep={handleForwardDate}
        handleBackStep={handleBackwardDate}
      />
      {filters.hasFilter && (
        <Breadcrumbs className="breadcrumb">
          {range.startDate && range.endDate && (
            <FilterItem
              displayName={`${getDateAsString(
                range.startDate,
              )} - ${getDateAsString(range.endDate)}`}
              filter="dates"
              onClick={handleClickFilter}
            />
          )}

          {type.length > 0 && (
            <FilterItemWithIcon
              iconValue={"e"}
              textOperation={findOperationName("e")}
              property={FILTER_REFERENCE["type"]}
              propertyValue={type}
            />
          )}
          {arrFilters.map((item) => {
            if (!filter[item].operation) return null;

            const textOperation = findOperationName(filter[item].operation);
            const propertyValue = filter[item].value;

            return (
              <FilterItemWithIcon
                key={item}
                iconValue={filter[item].operation}
                textOperation={findOperationName(filter[item].operation)}
                property={FILTER_REFERENCE[item]}
                propertyValue={propertyValue ? propertyValue : textOperation}
              />
            );
          })}
        </Breadcrumbs>
      )}
    </FiltersWrapped>
  );
};

export default React.memo(BreadcrumbsFilters);
