import React from 'react'

const IssuesCodes = ({title, ...props}) => {
  return(
    <div className='p-2 duplicate'>
      <h4>{title}</h4>
      {props.children}
      <hr/>
    </div>
  )
}

export default IssuesCodes