import {Formik, Form, ErrorMessage} from "formik";
import CustomDatePicker from "../../../../CustomDatePicker";
import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import useExtractionLogFilter from "./hooks/useExtractionLogFilter";
import * as Yup from "yup";

const ExtractionLogFilter = ({setFilters}) => {
  const {handleSubmit} = useExtractionLogFilter(setFilters)
  return (
    <Formik
      initialValues={{
        sheetDate: ''
      }}
      validationSchema={Yup.object({
        sheetDate: Yup.date().required('Sheet Date is required'),
      })}
      onSubmit={values => handleSubmit(values)}
    >
      {({values, resetForm, setFieldValue}) => (
        <Form>
          <Row>
            <Col md={12} className='mb-3'>
              <label htmlFor="sheetDate">Sheet Date</label>
              <CustomDatePicker
                name='sheetDate'
                onChange={date => setFieldValue('sheetDate', date)}
                value={values.sheetDate}
                format='yyyy-MM-dd'
              />
              <ErrorMessage
                name='sheetDate'
                component='span'
                className='text-danger mt-2'
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button variant="primary" type="submit">
                Apply filters
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
export default ExtractionLogFilter