import { Modal } from "react-bootstrap";

const CustomModal = ({
  backdrop = "static",
  header = true,
  onHide,
  title,
  show,
  size,
  footer,
  ...props
}) => {
  //const [content, setContent] = useState(props.content);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      backdrop={backdrop}
      keyboard={true}
      centered
      style={{ zIndex: 9999999 }}
    >
      {header && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{props.children}</Modal.Body>
      {typeof footer !== "undefined" && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default CustomModal;
