import {useState} from "react";

export const initialState = {
  firstname: '',
  lastname: '',
  phonenumber: '',
  password: '',
  email: '',
};

const useClientUserAdmin = () => {
  const [user, setUser] = useState(initialState);

  return {user, setUser}
}

export default useClientUserAdmin