import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import UserHelp from "../../../util/UserHelp";
import { TemplateContext } from "../TemplateContext";
import Bookmark from "./Bookmark";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";

const initialCategoryState = { adding: false, data: {} };
export default function Category({
  // Actions
  addingNewCategory,
  setAddingNewCategory,

  // Re-render
  timesBeingClosed,
  isBeignDisplayed,

  // Data
  bookmarks,
  parentCategory,
  subCategories,
  canCreateSubcategories,
  ...category
}) {
  // TODO mover a un solo sitio
  const { addSubCategory } = useContext(TemplateContext);
  const [saving, setSaving] = useState(false);
  const nextOrder = subCategories.length + 1;
  return (
    <>
      <div className="my-2">
        {addingNewCategory.adding && (
          <div className="mt-3">
            <div className="d-flex ">
              <div className="w-50 d-flex">
                <CategoryForm
                  isSubCategory
                  handler={(data) => {
                    setAddingNewCategory({ adding: true, data });
                  }}
                />
              </div>
              <div className="d-flex ml-3">
                <Button
                  disabled={saving}
                  size="sm"
                  variant="outline-action"
                  className="mr-2"
                  onClick={async () => {
                    setSaving(true);
                    addSubCategory(
                      category,
                      addingNewCategory.data,
                      nextOrder
                    ).then((r) => {
                      if (r) setAddingNewCategory(initialCategoryState);
                      setSaving(false);
                    });
                  }}
                >
                  Save Category
                </Button>
                <Button
                  disabled={saving}
                  size="sm"
                  variant="outline-danger"
                  onClick={() => setAddingNewCategory(initialCategoryState)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {bookmarks.length === 0 && (
        <p className="ml-2 text-muted small">
          Dear user, there are no bookmarks associated to this{" "}
          {category.categoryType}.
        </p>
      )}
      <div className="d-flex ml-1 flex-wrap">
        {bookmarks &&
          bookmarks.map((b, i) => (
            <Bookmark
              key={b.id}
              parentCategory={parentCategory}
              category={category}
              bookmark={b}
            />
          ))}
      </div>
      <div className="m-1 mt-4">
        {subCategories && (
          <CategoryList
            keys={timesBeingClosed}
            parentCategory={category}
            data={subCategories}
          />
        )}
      </div>
    </>
  );
}
