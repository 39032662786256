import React from "react"
import {Col, Row} from "react-bootstrap";
import usePracticesYear from "./hooks/usePracticesYear";

const PracticesYear = ({setPractice, setYear, disabled}) => {
  const {
    years,
    formatedPractices,
    setSelectedPractice,
    setSelectedYear,
    selectedPractice,
    selectedYear
  } = usePracticesYear()
  return (
    <>
      <Col md={{span: 4, offset: 0}}>
        <select
          disabled={disabled}
          name="practices"
          id="practices"
          className='form-control mb-4'
          defaultValue={''}
          onChange={({target}) => {
            setSelectedPractice(target.value)
            setPractice(target.value)
          }}
        >
          <option value={''} disabled>Select a practice</option>
          {formatedPractices.map(practice => (
            <option key={practice.id} value={practice.id}>{practice.label}</option>
          ))}
        </select>
      </Col>

      <Col md={{span: 4, offset: 0}}>
        <select
          disabled={disabled}
          name="years"
          id="years"
          className='form-control'
          defaultValue={''}
          onChange={({target}) => {
            setSelectedYear(target.value)
            setYear(target.value)
          }}
        >
          <option value={''} disabled>Select a year</option>
          {years.map(({year, selected}) => (
            <option key={year}  value={year}>{year}</option>
          ))}
        </select>
      </Col>
    </>
  )
}

export default PracticesYear