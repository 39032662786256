import UserHelp from "../../util/UserHelp";
import CustomSwitch from "../../CustomSwitch";
import {setDocumentGeneration} from "../../../services/missingCode";
import {HeaderButton} from "../../RemarkTemplate/Body";
import {faDownload, faEdit} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/fontawesome-free-solid";
import {InfoModal} from "../MissingCode/components/InfoModal";
import TemplatePreview from "../../TemplatePreview";
import {Loader, SubsectionTitle} from "../../formControls";
import useMissingCodeTab from "./hooks/useMissingCodeTab";
import TabContainer from "./components/TabContainer";

function formatForPreview(sections) {
  return sections.map(({ title, content }) => {
    const bookmarks = [];
    const { subSections } = content.reduce(
      (acum, el) => {
        const elementLabel = el.props.label;

        // Create a new sub section
        if (el.control === SubsectionTitle) {
          acum.subSections[elementLabel] = {
            subSectionTitle: elementLabel,
            bookmarks: [],
          };

          // Set the reference for the upcoming elements until it changes
          acum.currentSubSectionsBm =
            acum.subSections[elementLabel]["bookmarks"];
        } else {
          let bookmarkValue = el.props.value || "-";
          if (
            typeof el.props.options !== "undefined" &&
            el.props.options.length > 0
          ) {
            const selecteOption = el.props.options.find((o) => o.value === "n");

            if (selecteOption) {
              bookmarkValue = selecteOption.label;
            }
          }

          acum.currentSubSectionsBm.push({
            bookmarkTitle: elementLabel,
            bookmarkValue,
          });
        }

        return acum;
      },
      {
        subSections: {},
        currentSubSectionsBm: bookmarks,
      }
    );

    return {
      sectionTitle: title,
      subSections: Object.values(subSections),
      bookmarks,
    };
  });
}

const MissingCodeTab = (
  {
    isAudited,
    generatingDoc,
    permissions,
    transactionId,
    notifyChanges,
    handlePmsUpdate,
    beforeSave,
    reloadData,
  }
) => {
  const {
    _permissions,
    Spreedsheet,
    showPreview,
    showBox,
    loadingData,
    loaderIsActive,
    data,
    setShowBox,
    handleDownloadFile,
    handleSubmit,
    togglePreview,
    handler,
    handleCloseModal
  } = useMissingCodeTab({
    transactionId,
    notifyChanges,
    beforeSave,
    reloadData,
    permissions
  })

  return (
    <div className="missing-code">
      {isAudited ? (
        <div>
          <UserHelp>
            Dear user, some features are disabled because this record is audited
          </UserHelp>
        </div>
      ) : null}
      <div className="d-flex justify-content-end  header-buttons align-items-center">
        <div className="togglers">
          {_permissions.FBD.uploadFBD && (
            <CustomSwitch
              label="Upload FBD"
              checked={generatingDoc}
              onChange={() => {
                setDocumentGeneration(transactionId, !generatingDoc).then(
                  ([_s, r]) => {
                    if (r) {
                      notifyChanges();
                    }
                  }
                );
              }}
              disabled={isAudited}
            />
          )}
          <CustomSwitch
            label="Preview"
            checked={showPreview}
            onChange={togglePreview}
          />
        </div>

        <div className="floating-options">
          {_permissions.transaction.audit === true && (
            <HeaderButton
              icon={faEdit}
              message=" Update PMS"
              onClick={handlePmsUpdate}
              disabled={isAudited}
            />
          )}
          <HeaderButton
            icon={faDownload}
            message="Download template"
            onClick={handleDownloadFile}
          />
          {
            <HeaderButton
              icon={faEye}
              message="Show details"
              onClick={() => {
                setShowBox(true);
              }}
            />
          }
        </div>
      </div>
      {showBox && (
        <InfoModal
          showBox
          item={Spreedsheet}
          handleCloseModal={handleCloseModal}
        />
      )}

      <TabContainerWrapper
        loadingData={loadingData}
        showPreview={showPreview}
        handleSubmit={handleSubmit}
        isAudited={isAudited}
        handler={handler}
        hideSubmitButton={_permissions.FBD.updateFBD}
        data={data.data || []}
      />

      <Loader active={loaderIsActive || loadingData} />
    </div>
  );
}

const TabContainerWrapper = (
  {
    loadingData,
    showPreview,
    data,
    isAudited,
    handleSubmit,
    handler,
    hideSubmitButton
  }) => {

  if (loadingData) return null

  if (showPreview) {
    return (
      <TemplatePreview
        data={data ? formatForPreview(data) : []}
      />
    )
  }

  return (
    <TabContainer
      disabled={isAudited}
      handleSubmit={handleSubmit}
      data={data || []}
      handler={handler}
      hideSubmitButton={hideSubmitButton}
    />
  )
}

export default MissingCodeTab
