import {Api, decryptApiResponse} from "../../../config/Api";
import {statusAndResponse} from "../../../util/requestHandler";

export function saveCredentials(credentials) {
  return statusAndResponse(
    Api.post(`/api/client/credentials`, credentials)
  )
}

export function getAllClients() {
  return statusAndResponse(
    Api.get('/api/client'),
    decryptApiResponse
  )
}

export function getPracticesById(clientId) {
  return statusAndResponse(
    Api.get(`/api/security/practice/client/${clientId}`),
    decryptApiResponse
  )
}

export function getCredentialById(id) {
  return statusAndResponse(
    Api.get(`/api/client/credentials/${id}`),
    decryptApiResponse
  )
}

export function deleteCredentials(id){
  return statusAndResponse(
    Api.delete(`/api/client/credentials/${id}`),
    decryptApiResponse
  )
}