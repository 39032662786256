// Hooks
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  getUrlParams,
  removeUrlParams,
  setURLParams,
} from "../../../../util/browser";
import {
  defaultFormat,
  getDateAsString,
  getInitialAndEndDate,
} from "../../../../util/date";
import * as auth from "../../../../util/auth";
import { withoutEmptyKeys } from "../../../../util/object";
import { AppContext } from "../../../../App";

const startOfMonth = moment().startOf("month").format(defaultFormat);
const endOfMonth = moment().endOf("month").format(defaultFormat);

export const initialState = {
  practiceId: "",
  startDate: startOfMonth,
  endDate: endOfMonth,
};

const urlKeys = Object.keys(initialState);

const useBillingFilter = (setFilters) => {
  // Component's
  const [data, _setData] = useState({
    ...initialState,
    ...getUrlParams(urlKeys),
  });

  const ctx = useContext(AppContext);

  const practices = ctx.get("practices");

  const [processing, setProcesing] = useState(false);

  const updateUrl = (newData) => {
    const f = withoutEmptyKeys(newData);
    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }
  };

  function setData(name, value) {
    if (name === "startDate") {
      const [startDate, endDate] = getInitialAndEndDate(value, data.endDate);

      _setData({
        ...data,
        startDate: getDateAsString(startDate),
        endDate: getDateAsString(endDate),
      });
      return;
    }

    if (name === "endDate") {
      const [startDate, endDate] = getInitialAndEndDate(data.startDate, value);

      _setData({
        ...data,
        startDate: getDateAsString(startDate),
        endDate: getDateAsString(endDate),
      });
      return;
    }

    _setData({ ...data, [name]: value });
  }

  const _setFilters = (d) => {
    setFilters(withoutEmptyKeys(d));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    _setFilters(data);
    updateUrl(data);
  };

  const handleClear = () => {
    _setData(initialState);
    _setFilters(initialState);
    updateUrl(initialState);
  };

  return {
    data,
    processing,
    practices,
    setData,
    handleFilterSubmit,
    handleClear,
  };
};

export default useBillingFilter;
