import { Api, decryptApiResponse } from "../../../../../config/Api";
import { statusAndResponse } from "../../../../../util/requestHandler";

export async function getAssignablePermissions() {
  return statusAndResponse(Api.get("/api/authz/get/client/permission"), (r) =>
    decryptApiResponse(r)
  );
}

export async function getPractices() {
  return statusAndResponse(
    Api.get("/api/authz/clients/practices"),
    decryptApiResponse
  );
}

export async function searchUser(search) {
  return statusAndResponse(
    Api.get("/api/v2/users/search/", {
      params: {
        searchString: search,
      },
    }),
    (r) => decryptApiResponse(r)
  );
}

export async function getUserById(userId, practiceId) {
  return statusAndResponse(
    Api.post("/api/v2/users/search/id", { userId, practiceId }),
    (r) => decryptApiResponse(r)
  );
}

export async function updateUser(_id, data, permission, practices, practiceId) {
  return statusAndResponse(
    Api.post("/api/v2/users/update", {
      payload: {
        _id,
        ...data,
        email: data.user,
      },
      practices,
      permission,
      practiceId
    }),
    (r) => decryptApiResponse(r)
  );
}

export async function saveUser(data, permission, practices, practiceId) {
  return statusAndResponse(
    Api.post("/api/v2/users/save", {
      payload: {
        ...data,
        email: data.user,
      },
      practices,
      permission,
      practiceId
    }),
    (r) => decryptApiResponse(r)
  );
}

export async function getClientPermissions() {
  return statusAndResponse(
    Api.get("/api/authz/client/permission"),
    (r) => decryptApiResponse(r)
  );
}

export async function savePermissions(data) {
  return statusAndResponse(
    Api.put(`/api/authz/user/permission`, data),
    decryptApiResponse
  )
}

export async function getUserPermissions(userId, practiceId) {
  return statusAndResponse(
    Api.get(`/api/authz/user/permission/${practiceId}/${userId}`),
    (r) => decryptApiResponse(r)
  )
}
