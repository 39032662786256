import { Button } from "react-bootstrap";

const styles = {
  btn: {
    padding: ".429rem 1rem",
    margin: "0 .5rem",
    // marginTop: "1rem",
  },
};

export function UpdateButton({ disabled, onClick, text = "Update" }) {
  return (
    <Button
      style={styles.btn}
      disabled={disabled}
      onClick={onClick}
      type="submit"
      className="btn-md btn-action"
    >
      {text}
    </Button>
  );
}

export function RefreshButton({
  disabled,
  onClick,
  text = "Retry",
  type = "button",
}) {
  return (
    <Button
      style={styles.btn}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className="btn-md btn-success"
    >
      {text}
    </Button>
  );
}
