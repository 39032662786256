import moment from "moment";
import styled from 'styled-components'
import {TemplateWrapped} from "../../Layout";
import CustomTable from "../../CustomTable";
import useClaims from "./hooks/useClaims";
import {HeaderFilterButton} from "../../RemarkTemplate/Body";
import ClaimFilterForm from "./components/ClaimFilterForm";

const ClaimType = styled.span`
  text-transform: capitalize;
`

const Claims = () => {
  const {
    dataSource,
    refreshData,
    tableReference,
    practiceId,
    claimType,
    practiceName
  } = useClaims()

  const columns = [
    {title: 'Date request', field: 'dateRequest', render: ({dateRequest}) => <span>{moment(dateRequest).format('MM/DD/YYYY')}</span>},
    {title: 'Claim type', render:()=>{
        return <ClaimType>{claimType}</ClaimType>
      }},
    {title: 'Patient name', field: 'patientName'},
    {title: 'Company Name', field: 'companyName'},
  ]
  return(
    <TemplateWrapped
      title='Claims'
      headerButtons={
        <HeaderFilterButton
          render={ClaimFilterForm}
          componentProps={
            {
              setFilters: refreshData,
              practiceId
            }
          }
        />

      }
    >
      <CustomTable
        columns={columns}
        title={`Claims - ${practiceName}`}
        search={false}
        asyncDataSource={dataSource}
        tableReference={tableReference}
      />
    </TemplateWrapped>
  )
}

export default Claims