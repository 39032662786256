import CustomTable from "../../CustomTable";
import CustomSwitch from "../../CustomSwitch";
import SeeDetailsEye from "./SeeDetailsEye";
import "../../../styles/intelepeer-table.css";
import {TableContainer} from "@material-ui/core";

const IntelepeerTable = ({ getData, handleClickDetails, tableRef, handleOnSetRecordToBeChanged }) => {
  const columns = [
    {
      title: "Appointment Date",
      field: "dateTimeAppointment",
    },
    {
      title: "Patient Name",
      field: "patientName",
    },
    {
      title: "Patient DOB",
      field: "patientDOB",
    },
    {
      title: "Patient ID",
      field: "patientId",
    },
    {
      title: "Insurance Carrier",
      field: "insuranceCarrier"
    },
    {
      title: "Doctor",
      field: "doctorsName"
    },
    {
      title: 'Created At',
      field: 'createdAt',
    },
    {
      title: "Status",
      field: "status",
      render: (record) => (
        <CustomSwitch
          checked={record.status}
          onChange={() => handleOnSetRecordToBeChanged(record)}
        />
      )
    }
  ];
  const actions = [
    {
      title: "Actions",
      sorting: false,
      align: "center",
      render: (rowData) => (<SeeDetailsEye record={rowData} handleClickDetails={handleClickDetails} />),
    }
  ];

  return (
    <TableContainer>
      <CustomTable
        gridCssClass="intelepeer-table intelepeer-width-column-all"
        tableReference={tableRef}
        options={{
          sorting: false
        }}
        columns={[...columns, ...actions]}
        search={false}
        asyncDataSource={getData}
      />
    </TableContainer>
  )
}

export default IntelepeerTable;
