import {TemplateWrapped} from "../../Layout";
import CustomTable from "../../CustomTable";
import useExtractionLog from "./hooks/useExtractionLog";
import {HeaderFilterButton} from "../../RemarkTemplate/Body";
import ExtractionLogFilter from "./components/ExtractionLogFilter";
import moment from "moment";

const ExtractionLog = () => {

  const {logs, setFiltersValues} = useExtractionLog()

  const columns = [
    // {title: 'Client', field: 'clientId'},
    // {title: 'Practice', field: 'practiceId'},
    {title: 'Sheet Date', field: 'sheetDate'},
    {title: 'Row', field: 'rowNumber'},
    {title: 'Status', field: 'status', render: ({status}) => status ? 'TRUE': 'FALSE'},
    {title: 'Title / Message', render: ({title, message}) => <span>{title} {message}</span>},
    //{title: 'Date Created', render: ({dateCreated}) => <span>{moment(dateCreated).format('MMMM Do YYYY, h:mm:ss a')}</span>},
    {title: 'Date Created', field: 'dateCreated'}
  ]

  return(
    <TemplateWrapped
      title='Logs'
      headerButtons={
        <>
          <HeaderFilterButton
            active={
              //   filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              false
            }
            render={ExtractionLogFilter}
            componentProps={{
              setFilters: setFiltersValues,
            }}
          />
        </>
      }
    >
      <CustomTable
        data={logs}
        columns={columns}
      />
    </TemplateWrapped>
  )
}

export default ExtractionLog