import {OverlayTrigger, Popover} from "react-bootstrap";
import {ReCallContainer} from "../AlltransactionTable.style";
import {useEffect, useRef, useState} from "react";


function generateRandomNumber(init, end) {
  return Math.floor(Math.random() * (end - init + 1)) + init;
}

const popover = (
  <Popover id='recall'>
    <Popover.Title as="h3">Recall Integration (Beta).</Popover.Title>
    <Popover.Content>
      This feature allows DentalRobot to automatically log-in to any of your recall(s) vendor(s),
      search for specific patients and send a SMS reminder that patient needs to update his/hers personal data
      in order for DentalRobot to process their insurance benefits.
    </Popover.Content>
  </Popover>
)
const ColumnReCall = ({ivProcessResult, ivStatus}) => {
  const _ivProcessResult = ivProcessResult.toUpperCase()
  const _ivStatus = ivStatus.toUpperCase();
  const isGreen = _ivStatus === 'ACTIVE' && _ivProcessResult === 'DONE BY DR';
  const [show, setShow] = useState(false);
  const targetRef = useRef(null);
  const [number, setNumber] = useState(null);

  const handleClickOutside = event => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      setShow(false);
    }
  }

  const handleShow = () => {
    setShow(!show)
  }

  useEffect(() => {
    setNumber(generateRandomNumber(1, 3))
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
    }
  }, [])

  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      overlay={popover}
      show={show}
      onToggle={handleShow}
      rootClose
      rootCloseEvent='mousedown'
      rootCloseEventTarget={targetRef.current}>
      <ReCallContainer isGreen={isGreen}>
        {number}
      </ReCallContainer>
    </OverlayTrigger>
  )
}

export default ColumnReCall
