import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import useIvFilter from "../hooks/useIvFilter";

// Components
export default function IVFilter({ setFilters, initialData }) {
  const areControlsBlocked = false;
  const {
    clear,
    setData,
    data: { botStatus, endDate, startDate, ivType, patientName },
    save,
    catalogs: { botStatuses, ivStatuses, ivTypes },
  } = useIvFilter(setFilters, initialData);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        save();
      }}
    >
      <Form.Group className="m-2">
        <Form.Label>Patient Name</Form.Label>
        <Form.Control
          id="patientName"
          type="text"
          value={patientName}
          onChange={({ target: { value } }) => {
            setData("patientName", value);
          }}
          placeholder="Patient Name"
        />
      </Form.Group>
      <Form.Group className="m-2">
        <Form.Label>Bot Status</Form.Label>
        <Form.Control
          id="botStatus"
          value={botStatus}
          onChange={({ target: { value } }) => {
            setData("botStatus", value);
          }}
        />
      </Form.Group>

      <Form.Group className="m-2">
        <Form.Label>IV Types</Form.Label>
        <Form.Control
          id="ivtype"
          as="select"
          value={ivType}
          onChange={({ target: { value } }) => {
            setData("ivType", value);
          }}
        >
          <option value="">*Not Filtered*</option>
          {ivTypes.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group className="m-2">
        <Form.Label>Start Date</Form.Label>
        <InputGroup className="flex-nowrap">
          <ReactDatePicker
            className={`form-control custom-form-control `}
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            placeholderText="MM/DD/YYYY"
            name="startDate"
            id="startDate"
            showMonthDropdown
            showYearDropdown
            todayButton="Today"
            selected={startDate}
            onChange={(d) => {
              setData("startDate", d);
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>

      <Form.Group className="m-2">
        <Form.Label>End date</Form.Label>
        <InputGroup className="flex-nowrap">
          <ReactDatePicker
            className={`form-control custom-form-control `}
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            placeholderText="MM/DD/YYYY"
            name="endDate"
            id="endDate"
            showMonthDropdown
            showYearDropdown
            todayButton="Today"
            selected={endDate}
            onChange={(d) => {
              setData("endDate", d);
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>

      <Form.Group className="m-2 text-center">
        <Button
          className="mt-3"
          variant="primary"
          type="submit"
          disabled={areControlsBlocked}
        >
          Apply filters
        </Button>

        <Button
          disabled={areControlsBlocked}
          className="mt-3 ml-1"
          variant="warning"
          type="button"
          onClick={() => {
            clear();
          }}
        >
          Reset
        </Button>
      </Form.Group>
    </Form>
  );
}
