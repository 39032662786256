import {useEffect, useRef, useState} from "react";
import {Accordion, Form} from "react-bootstrap";
import {AccordionItem} from "../../../../CustomAccordion";

const PermissionsAccordion = (
  {
    data = [],
    selected = [],
    setSelected,
    parentId = null
  }) => {
  if (!setSelected) return <div>Invalid data was given</div>;

  return data.map(item => {
    return (
      <Accordion
        key={item.permissionId}
      >
        <AccordionItem
          eventKey={item.permissionId}
          renderHeader={(props) => (
            <OptionsGroupHeader
              className="my-2"
              key={item.permissionId}
              displayName={item.displayName}
              selectedData={selected}
              selectedDataHandler={setSelected}
              items={item.items}
              permissionId={item.permissionId}
              parentId={parentId}
              {...props}
            />
          )}
          renderBody={(props) => (
            <>
              {item.items.length > 0 ? (
                <PermissionsAccordion
                  data={item.items}
                  selected={selected}
                  setSelected={setSelected}
                  parentId={item.permissionId}
                  {...props}
                />
              ) : null}
            </>
          )}
        />
      </Accordion>
    )
  })
}

const GetOption = (
  {
    item,
    inline,
    type,
    selectedValues,
    onChange,
    required,
    reference,
    checked,
    className = "",
    permissionId,
  }
) => {
  return (
    <Form.Group
      className={`${inline ? "d-inline-block" : ""} mb-0 ${className}`}
      key={item.id}
    >
      <Form.Label className="mb-0">
        <Form.Check
          required={required}
          inline
          ref={reference}
          className="mr-0"
          checked={
            typeof checked !== "undefined"
              ? checked
              : selectedValues.filter((x) => compareOptions(x, item)).length > 0
          }
          name="options"
          type={type}
          value={item._id}
          onChange={({target: {checked}}) =>
            onChange && onChange(item, checked)
          }
        />
        {item.displayName}
      </Form.Label>
    </Form.Group>
  );
}

const OptionsGroupHeader = (
  {
    items = [],
    displayName,
    selectedData,
    selectedDataHandler,
    className,
    permissionId,
    parentId
  }
) => {

  const ref = useRef({current: {}});
  const [parentIsSelected, setparentIsSelected] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      const reduceItems = items.map(item => item.permissionId);
      const filterInSelected = reduceItems.filter((x) => selectedData.includes(x));

      if (filterInSelected.length === items.length) {
        setparentIsSelected(true);
        ref.current.indeterminate = false;
      } else if (filterInSelected.length > 0 && filterInSelected.length < items.length) {
        ref.current.indeterminate = true;
        setparentIsSelected(false);
      } else {
        setparentIsSelected(false);
        ref.current.indeterminate = false;
      }
    } else {
      const isSelected = selectedData.filter((x) => x === permissionId).length > 0;
      setparentIsSelected(isSelected);
    }
  }, [selectedData, ref, items]);

  return (
    <GetOption
      item={{
        displayName,
        id: displayName,
        permissionId,
      }}
      className={className}
      inline={true}
      type="checkbox"
      reference={ref}
      selectedValues={parentIsSelected}
      checked={parentIsSelected}
      onChange={(selected, checked) => {
        //const {permissionId} = selected;
        selectedDataHandler(permissionId, checked, items, parentId)
      }}
    />
  );
}

function compareOptions(a, b) {
  // return a && b && a.id === b.id && a.label === b.label;
  return a === b;
}

export default PermissionsAccordion