import usePracticesByClientSelected from "./hooks/usePracticesByClientSelected";
import PracticesContainer from "../PracticesContainer";
import {Col, Row} from "react-bootstrap";

const PracticesByClientSelected = ({closeModal}) => {

  const {
    handleClickPractice,
    client,
    groupedPractices,
    selectedPractices,
    canUseMultiPractice,
    handleCheckAllPractices,
    handleUncheckAllPractices,
    handleClickSetPractices,
    checkIfPracticeIsSelected
  } = usePracticesByClientSelected(closeModal);

  return (
    <>
      <PracticesContainer
        practices={client && groupedPractices ? groupedPractices[client.clientId].practices : []}
        handleClickPractice={handleClickPractice}
        handleCheckAllPractices={handleCheckAllPractices}
        handleUnCheckAllPractices={handleUncheckAllPractices}
        selectedPractices={selectedPractices}
        checkIfPracticeIsSelected={checkIfPracticeIsSelected}
        showLegend={true}
        showUncheckAll={true}
        perPage={5}
        showTitle={false}
        showButtons={canUseMultiPractice}
        showScroll={true}
        paging={false}
      />
      <Row className='mt-4'>
        <Col md={{offset: 2, span: 4}}>
          <button
            className='btn btn-primary btn-block'
            onClick={handleClickSetPractices}>Active selected practices</button>
        </Col>
        <Col md={4}>
          <button
            className='btn btn-danger btn-block'
            onClick={() => closeModal()}>Cancel</button>
        </Col>
      </Row>
    </>
  );
}

export default PracticesByClientSelected;