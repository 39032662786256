import React from "react";
import { Spinner } from "react-bootstrap";
import { SaveButton } from "../verification/components/bookmarks-inputs/styles/SaveButton.styles";

const SaveButtonForm = ({ isLoadingButton, label = "Save" }) => {
  return (
    <SaveButton type="submit" disabled={isLoadingButton}>
      {isLoadingButton ? (
        <>
          {" "}
          <Spinner size="sm" animation="border" /> {label}
        </>
      ) : (
        <> {label}</>
      )}
    </SaveButton>
  );
};

export default SaveButtonForm;
