import React, {useState} from "react"
import {useDropzone} from "react-dropzone"
import './css/upload.css'
import {Icon} from "@material-ui/core";

const DragDropUpload = (props) => {
  const [accepted, setAccepted] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [fileName, setFileName] = useState('')

  const {
    fileTypes = 'image/jpeg, image/png',
    maxFiles = 1,
    handleChange,
    text = 'Choose a file',
    showStatus = false
  } = props

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: fileTypes,
    maxFiles: maxFiles,
    onDropAccepted: (fileAccepted) => {
      const file = fileAccepted[0]
      handleChange(file)
      setAccepted(true)
      setRejected(false)
      setFileName(file.name)
    },
    onDropRejected: () => {
      setRejected(true)
      setAccepted(false)
    }
  });

  return (

    <>
      <div className="upload-file" {...getRootProps()}>
        <div className='upload-input'>
          <input {...getInputProps()} />
          <div className='upload-icon'>
            <Icon color={accepted ? "primary" : "inherit"}>file_upload</Icon>
            <span className='upload-text'>
              {fileName.length === 0 ? text : fileName}
            </span>
          </div>
          {showStatus && (
            <span className='upload-status'>
            {accepted && (<span className='text-success'>
              <strong>File accepted</strong>
            </span>)}
              {rejected && (<span className='text-danger'>
              <strong>File not allowed</strong>
            </span>)}
          </span>
          )}
        </div>
      </div>
    </>
  );
}

export default DragDropUpload