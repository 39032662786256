import styled from "styled-components";
import { statusLookup } from "../../../../helper/TransactionsTable";
import CustomTooltip from "../../../../CustomTooltip";
import { getVerificationByStatus } from "../../utils";
import { ROW_FONT_SIZE } from "../../utils/constanst";

export const VerificationStatus = styled.span`
  display: block;
  text-align: center;
  padding: 4px;
  width: ${({ width }) => (width ? width : " 75%")};
  background-color: ${({ status }) => status.bg};
  color: ${({ status }) => status.color};
  border-radius: 0.25rem;
  margin: 0 auto;
  font-weight: ${({ bold = true }) => (bold ? "600" : "400")};
  font-size: ${ROW_FONT_SIZE};
  text-transform: capitalize;
`;

export const Small = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  width: 75%;
  margin: 0 auto;
  padding: 4px;
  display: inline-block;

  ${({ isActive }) =>
    isActive &&
    `
    border-radius: .25rem;
    background-color: #FAE7AF;
    color: #D2B710;
    margin-top: .525rem;
  `}
`;

const ColumnVerificationBy = (props) => {
  const { iv_process_result = "", user } = props;
  const { message, status } = statusLookup(iv_process_result);
  const C = (
    <>
      <VerificationStatus status={getVerificationByStatus(status)}>
        {iv_process_result}
      </VerificationStatus>
      {iv_process_result &&
      iv_process_result.trim().toUpperCase() === "DONE BY CC" &&
      user ? (
        <Small>{user}</Small>
      ) : null}
    </>
  );
  return message ? <CustomTooltip message={message}>{C}</CustomTooltip> : C;
};

export default ColumnVerificationBy;
