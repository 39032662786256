import { Small, VerificationStatus } from "./ColumnVerificationBy";
import { ELIGIBILITY_COLOR, ELIGIBILITY_STATUS } from "../../utils/constanst";
import { getEligibilityStatus } from "../../utils";

const ColumnEligibilityStatus = ({ iv_status }) => {
  const status = getEligibilityStatus(iv_status);
  let [statusString = "", statusDescription = ""] = iv_status.split("/");
  const ivStatusSplited = iv_status.split("/");

  if (!ELIGIBILITY_STATUS.includes(statusString.trim().toLowerCase())) {
    statusString = iv_status;
    statusDescription = null;
  }

  return (
    <>
      <VerificationStatus
        status={
          ELIGIBILITY_COLOR[status]
            ? ELIGIBILITY_COLOR[status]
            : ELIGIBILITY_COLOR["noStatus"]
        }
        bold={false}
      >
        {statusString}
      </VerificationStatus>
      {statusDescription && (
        <Small isActive={statusString.trim().toLowerCase() === "active"}>
          {ivStatusSplited.slice(1).join("/ ")}
        </Small>
      )}
    </>
  );
};

export default ColumnEligibilityStatus;
