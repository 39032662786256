import React from "react"
import '../components/css/styles.css'
import IssuesCodes from "./IssuesCodes";

const DuplicateCodes = ({codes, title,handleClickCode, ...props}) => {
  //const cutArrayCodes = codes.slice(0, ELEMENTS);

  return (
    <IssuesCodes title={title}>
      <span className='text-muted mb-3'>
        {props.children}
      </span>
      <div className='duplicate-list'>
        {codes.map((code, index) => {
          return (
            <button
              key={`${code}${index}`}
              className='duplicate-code'
            >
              {code}
            </button>
          )
        })}
      </div>
    </IssuesCodes>
  )
}

export default DuplicateCodes