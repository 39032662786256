import React from "react"
import {Formik, Form, Field, ErrorMessage} from "formik";
import {Button, Col, Container, Row} from "react-bootstrap";
import CustomDatePicker from "../../../CustomDatePicker";
import * as Yup from 'yup'
import ExtractionScheduleDates from "./ExtractionScheduleDates";
import SelectPractices from "../../../SelectPractices";

const input = {span: 10, offset: 1}
const ExtractionScheduleForm = (
  {
    onSubmit,
    setShowModal,
    disabled,
    dates = [],
    confirm,
    allDates = [],
    restartDates
  }
) => {
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  return (
    <Formik
      initialValues={{
        startDate: new Date(),
        endDate: new Date(),
        type: '',
        practice: ''
      }}
      validationSchema={Yup.object({
        startDate: Yup.date().required('Start Date is required'),
        endDate: Yup.date().min(
          Yup.ref('startDate'),
          "End Date can't be before start date"
        ),
        type: Yup.string().required('Type is required'),
        practice: Yup.string().required('Practice is required')
      })}
      onSubmit={async values => {
        setFormSubmitting(true)
        await onSubmit(values)
        setFormSubmitting(false)
      }}
    >
      {({values, setFieldValue, isSubmitting, errors}) => (

        <Form>
          <Row className='mb-3'>
            <Col md={input}>
              <SelectPractices
                name='practice'
                onChange={({value}) => {
                  setFieldValue('practice', value)
                  restartDates()
                }}
                selected={[values.practice]}
              />
              <ErrorMessage
                name='practice'
                component='span'
                className='text-danger mt-2'
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md={input}>
              <label htmlFor="startDate">Start Date</label>
              <CustomDatePicker
                name='startDate'
                onChange={date => {
                  setFieldValue('startDate', date)
                  restartDates()
                }}
                value={values.startDate}
              />
              <ErrorMessage
                name='startDate'
                component='span'
                className='text-danger mt-2'
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md={input}>
              <label htmlFor="endDate">End Date</label>
              <CustomDatePicker
                name='endDate'
                onChange={date => {
                  setFieldValue('endDate', date)
                  restartDates()
                }}
                value={values.endDate}
              />
              <ErrorMessage
                name='endDate'
                component='span'
                className='text-danger mt-2'
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md={input}>
              <label htmlFor="type">Type</label>
              <Field
                className='form-control mb-3'
                id='type'
                name="type"
                component="select"
                onChange={({target}) => {
                  const {value} = target;
                  setFieldValue('type', value)
                  restartDates()
                }}
              >
                <option value="">Please select one type</option>
                <option value="new">New</option>
                <option value="review">Review</option>
                <option value="retry">Retry</option>
                <option value="upload">Upload</option>
              </Field>
              <ErrorMessage
                name='type'
                component='span'
                className='text-danger mt-2'
              />
            </Col>
          </Row>
          {dates.length > 0 && (
            <Row>
              <Col md={12}>
                <ExtractionScheduleDates
                  dates={dates}
                  allDates={allDates}
                />
              </Col>
            </Row>
          )}
          <Row>
            {dates.length === 0 ?
              (<Col md={{span: 4, offset: 4}}>
                <Button
                  block
                  variant="primary"
                  type="submit"
                  disabled={formSubmitting}
                  className='mt-5'
                >
                  Verify dates
                </Button>
              </Col>) :
              (<Col md={{span: 4, offset: 4}}>
                {dates.length > 0 && (
                  <Button
                    block
                    variant="primary"
                    type="submit"
                    disabled={disabled}
                    className='mt-5'
                    onClick={confirm}
                  >
                    Confirm
                  </Button>
                )}
              </Col>)}
          </Row>
        </Form>

      )}
    </Formik>
  )
}

export default ExtractionScheduleForm