import { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as services from "../UserRequest/userRequest.services";
import * as middlewares from "./userRequest.middlewares";

const useUserRequestDetail = () => {
  const { id } = useParams();

  const [practices, setPractices] = useState([]);
  const [header, setHeader] = useState({});
  const [orderId, setOrderId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // TODO cambiar clientId por id del parametro de URL
    services.getRequestById(id).then(([s, r]) => {
      if (s && Array.isArray(r) && r.length > 0) {
        const { products, generalInfo, businessInfo, _id: orderId } = r[0];
        setOrderId(orderId);
        setHeader({ generalInfo, businessInfo });
        setPractices(middlewares.formatProducts(products));
        setLoaded(true);
      } else {
        setRedirect(true);
      }
    });
  }, [id]);

  const downloadFile = (productId, fileName) => {
    services.downloadFile(orderId, productId, fileName);
  };

  return { practices, loaded, header, redirect, downloadFile };
};

export default useUserRequestDetail;
