import React from "react"
import WrongPrices from "../../TableExcel/components/WrongPrices";
import {Col} from "react-bootstrap";
import '../styles.css'
import DuplicateCodes from "../../TableExcel/components/DuplicateCodes";

const Issues = (
  {
    duplicates,
    wrongPrice,
    setDuplicateCodes,
    setWrongCodes,
    arrSelected,
    edit,
    selectedSheet,
    setRowsData
  }
) => {

  const codes = duplicates.map(arr => {
    return arr.codeService
  }).filter((code) => code)

  const dupl = codes.filter((code, index) => {
    return ((codes.indexOf(code) !== index) && code)
  })

  const hasIssues = duplicates.length > 0 || wrongPrice.length > 0

  return (
    <>
      {hasIssues && (
        <>
          <Col md={12}>
            <hr/>
          </Col>
          <Col md={{span: 8, offset: 2}} className='mt-3'>
            <div className='d-flex align-items-center justify-content-center'>
              <h6 className='text-danger ml-2 mb-0'>
                Some of the codes have issues, please correct them before processing the page
              </h6>
              {dupl.length > 0 && (
                <button
                  className='btn btn-link'
                  onClick={setDuplicateCodes}
                >
                  {`Codes duplicates (${dupl.length}) `}
                </button>
              )}
              {wrongPrice.length > 0 && (
                <button
                  className='btn btn-link'
                  onClick={setWrongCodes}
                >
                  {`Wrong price (${wrongPrice.length}) `}
                </button>
              )}
              {!edit && selectedSheet && (
                <button
                  className='btn btn-link'
                  onClick={setRowsData}
                >
                  Show correct codes
                </button>
              )}
            </div>
          </Col>

          <Col md={12}>
            <hr/>
          </Col>
          <Col md={12}>
            {dupl.length > 0 && arrSelected === 'duplicates' && (
              <DuplicateCodes
                codes={dupl}
                title='Duplicate codes'
                onClick={setDuplicateCodes}
              >
                Dear user the <strong>codes</strong> listed below are duplicated
              </DuplicateCodes>
            )}
            {wrongPrice.length > 0 && arrSelected === 'prices' && (
              <WrongPrices
                codes={wrongPrice}
                title='Wrong prices'
                onClick={setWrongCodes}
              >
                Dear user the <strong>codes</strong> listed below contain wrong price
              </WrongPrices>
            )}
          </Col>
        </>
      )}
    </>
  )
}

export default Issues