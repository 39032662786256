import React from 'react'
import {Formik, Form, Field} from "formik";
import {Button} from "react-bootstrap";
import useAirCallFilter from "./useAirCallFilter";
import CustomDatePicker from "../../../../CustomDatePicker";
import CustomMaskedInput from "../../../../CustomMaskedInput";

const AirCallFilter = ({ setFilters }) => {
  const {handleSubmit} = useAirCallFilter(setFilters)
  return (
    <Formik
      initialValues={{
        direction: '',
        phone_number: '',
        user_id: '',
        from: '',
        to: ''
      }}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {({values, resetForm, setFieldValue}) => (
        <Form>
          <label htmlFor="direction" className='mt-3'>Direction</label>
          <Field className='form-control mb-3' id='direction' name="direction" component="select">
            <option value="">Please select direction</option>
            <option value="inbound">Inbound</option>
            <option value="outbound">Outbound</option>
          </Field>
          <label htmlFor="from">Start Date</label>
          <CustomDatePicker
            name='from'
            onChange={date => setFieldValue('from', date)}
            value={values.from}
          />
          <label htmlFor="to">End Date</label>
          <CustomDatePicker
            name='to'
            onChange={date => setFieldValue('to', date)}
            value={values.to}
          />
          <label htmlFor="phone_number">Phone number</label>

          <CustomMaskedInput
            label='Phone Number'
            placeholder='+_ ___-___-____'
            name='phone_number'
            mask={[
              "+",
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
          />
          <Button variant="primary" type="submit">
            Apply filters
          </Button>
          <Button
            className="ml-1"
            variant="warning"
            type="button"
            onClick={()=>{
              resetForm()
              handleSubmit({})
            }}
          >
            Reset
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default AirCallFilter
