import React from "react"
import CustomTable, {TextColumnHighlighted} from "../../../../CustomTable";
import useTableExcel from "./hooks/useTableExcel";
import CustomModal from "../../../../CustomModal";
import UpdateForm from "./components/UpdateForm";
import {Col} from "react-bootstrap";
import {displayModalConfirmation} from "../../../../../util/displayMessage";

const TableExcel = (
  {
    data,
    selectedSheet,
    edit = false,
    updateData,
    deleteRow,
    arrSelected,
    deleteSelected
  }
) => {

  const {
    rows,
    rowSelected,
    showModal,
    setShowModal,
    handleClickEdit,
    handleUpdate,
    setSelectedRows,
    selectedRows
  } = useTableExcel(data, edit, updateData)

  const columns = [
    {
      title: 'Code',
      field: 'codeService',
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Value',
      field: 'price',
    }
  ]

  const columnsEdit = [
    {
      title: 'Id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Code',
      field: 'serviceCode'
    },
    {
      title: 'Description',
      field: 'serviceDescription',
    },
    {
      title: 'Value',
      field: 'price',
      render: ({price, symbol}) => {
        return (`${symbol}${price}`)
      }
    },
    {
      title: 'Status',
      field: 'status',
      render: ({status}) => {
        let colorStatus = 'primary'
        let textStatus = 'Waiting'

        if (status === 'done') {
          colorStatus = 'success'
          textStatus = 'Done'
        } else if (status === 'updated') {
          colorStatus = 'warning'
          textStatus = 'Updated'
        }

        return <TextColumnHighlighted text={textStatus} status={colorStatus} asDot/>
      }
    }
  ]

  const actionsNew = [
    {
      icon: 'edit',
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        handleClickEdit(rowData)
      },
      iconProps: {className: "text-primary"},
      position: 'row'
    }
  ]

  if (arrSelected === 'duplicates') {
    actionsNew.push({
      icon: 'delete',
      tooltip: 'Delete',
      onClick: () => {
        displayModalConfirmation('Delete', `Are you sure you want to delete ${selectedRows.length} row(s)`, () => {
          deleteSelected(selectedRows)
        })
      },
      iconProps: {className: "text-danger"},
      position: 'toolbarOnSelect'
    })
  } else {
    actionsNew.push({
      icon: 'delete',
      tooltip: 'Delete',
      onClick: (event, rowData) => {
        displayModalConfirmation('Delete', 'Are you sure you want to delete this record', () => {
          deleteRow(rowData)
        })
      },
      iconProps: {className: "text-danger"},
      position: 'row'
    })
  }

  const actionsUpdate = [
    {
      icon: 'edit',
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        const newRowData = {
          index: rowData._id,
          codeService: rowData.serviceCode,
          price: `${rowData.symbol}${rowData.price}`,
          description: rowData.serviceDescription
        }
        handleClickEdit(newRowData)
      },
      iconProps: {className: "text-primary"},
      position: 'row'
    }
  ]

  return (
    <>
      <Col md={12} className='table-excel'>
        <CustomTable
          title={selectedSheet}
          data={rows}
          search={true}
          columns={edit ? columnsEdit : columns}
          pageSize={25}
          actions={edit ? actionsUpdate : actionsNew}
          options={arrSelected === 'duplicates' ? {selection: true, toolbarButtonAlignment: 'left'} : {toolbarButtonAlignment: 'left'}}
          onSelectionChange={(r) =>{
            setSelectedRows(r)
          }}
        />
        {showModal && (
          <CustomModal
            title='Update Row'
            show={showModal}
            backdrop={true}
            onHide={() => {
              setShowModal(false)
            }}
          >
            <UpdateForm
              rowSelected={rowSelected}
              handleUpdate={handleUpdate}
            />
          </CustomModal>
        )}
      </Col>
    </>
  )
}

export default TableExcel