import React from "react";
import { useParams } from "react-router";
import { getFullDate, millisecondsToStr } from "../../aircall.utils";
import useAirCallDetail from "./useAirCallDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalize, Icon } from "@material-ui/core";
import { TemplateWrapped } from "../../../../Layout";
import "../../css/index.css";
import { Link } from "react-router-dom";

function CallCenterIcon() {
  return (
    <div className="d-flex justify-content-center align-items-center mb-3">
      <i className="callcenter-icon icon pe-headphones" aria-hidden="true"></i>
    </div>
  );
}

function UserIcon() {
  return (
    <div className=" d-flex justify-content-center align-items-center mb-3">
      <i className="user-icon icon pe-user" aria-hidden="true"></i>
    </div>
  );
}

const AirCallDetail = () => {
  const params = useParams();

  const [detail] = useAirCallDetail(params.callId);

  console.log({ detail });

  if (!detail) {
    return null;
  }

  return (
    <TemplateWrapped className="air-call">
      <div className="row">
        <div className="col-lg-3">
          <div className="card text-center">
            <div className="card-block user">
              <div className="avatar avatar-lg">
                <span className="d-inline-flexjustify-content-center">
                  <Icon fontSize="large">account_circle</Icon>
                </span>
              </div>
              {detail.user ? (
                <>
                  <h4 className="name">{detail.user.name}</h4>
                  <p className="email">{detail.user.email}</p>
                  <p className="details">
                    {detail.user.language} <br />
                    {detail.user.time_zone}
                  </p>
                </>
              ) : (
                <h4>*Not answered*</h4>
              )}
            </div>
            <div className="card-footer">
              <Link to="/air-call">
                <FontAwesomeIcon icon="angle-left" /> Go to previous page
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="panel details">
            <div className="panel-heading">
              <h3 className="panel-title">CALL DETAILS</h3>
            </div>
            <div className="panel-body">
              <div className="row">
                <div className="col">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1">
                  <CallCenterIcon />
                </div>
                <div className="col-md-5">
                  <p>
                    Started at: <small>{getFullDate(detail.started_at)}</small>
                  </p>
                  <p>
                    Answered at:{" "}
                    <small>
                      {detail.answered_at
                        ? getFullDate(detail.answered_at)
                        : null}
                    </small>
                  </p>
                  <p>
                    Ended at:{" "}
                    <small>
                      {detail.started_at ? getFullDate(detail.ended_at) : null}
                    </small>
                  </p>
                  <p>
                    Duration:{" "}
                    <small>{millisecondsToStr(detail.duration * 1000)}</small>
                  </p>
                </div>
                <div className="col-md-5">
                  <p>
                    Status: <small>{capitalize(detail.status)}</small>
                  </p>
                  <p>
                    Direction:{" "}
                    <small>
                      {capitalize(detail.direction)}{" "}
                      {detail.direction === "inbound" ? (
                        <Icon className="inboundCall">call_received</Icon>
                      ) : detail.direction === "outbound" ? (
                        <Icon className="outboundCall">call_made</Icon>
                      ) : null}
                    </small>
                  </p>
                  <p>
                    Number:{" "}
                    <small>{detail.number ? detail.raw_digits : null}</small>
                  </p>
                  <p>
                    Cost:{" "}
                    <small>{detail.number ? "$ " + detail.cost : null}</small>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1">
                  <UserIcon />
                </div>
                <div className="col-md-5">
                  <p>
                    Name: <small>{detail.number.name}</small>
                  </p>
                  <p>
                    Number: <small>{detail.number.digits}</small>
                  </p>
                </div>
                <div className="col-md-5">
                  <p>
                    Country: <small>{detail.number.country}</small>
                  </p>
                  <p>
                    Time zone: <small>{detail.number.time_zone}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
};

export default AirCallDetail;
