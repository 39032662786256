import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as services from "./../../services/credential.services";
import shortid from "shortid";
import { displayModalMessage } from "../../../../util/displayMessage";
import * as credentialsServices from "../../services/credential.services";

const initialState = {
  clientId: "",
  practiceId: "",
  credentials: [],
};

const useCredentialEdit = () => {
  const {
    state: { id = null, practice, client },
  } = useLocation();
  const [credentials, setCredentials] = useState(initialState);
  const [showForm, setShowForm] = useState(false);
  const history = useNavigate();

  useEffect(async () => {
    if (id) {
      const [status, res] = await services.getCredentialById(id);
      if (status) {
        const { clientId, practiceId, credentials: cred } = res;
        const arrCredentialsType = Object.keys(cred);
        const tempCred = arrCredentialsType.map((type) => {
          const keysOptionsType = Object.keys(cred[type]);
          const optionsType = cred[type];
          return {
            id: shortid.generate(),
            label: type,
            options: keysOptionsType.map((k) => {
              let ob = {
                id: shortid.generate(),
              };
              ob[k] = optionsType[k];
              return ob;
            }),
          };
        });
        setCredentials({
          ...credentials,
          clientId,
          practiceId,
          credentials: tempCred,
        });
      }
    }
  }, []);

  const handleDeleteCredentials = async () => {
    const [status, res] = await services.deleteCredentials(id);
    if (status) {
      displayModalMessage(
        "Deleted",
        <span>All credentials has been deleted</span>,
        "error",
        () => {
          history("/credentials");
        }
      );
    }
  };

  // TODO extract function to a custom hook
  const handleSaveCredentials = async () => {
    let objCredentials = {
      clientId: credentials.clientId,
      practiceId: credentials.practiceId,
      credentials: credentials.credentials
        .map((cred) => {
          let o = {};
          o[cred.label] = cred.options
            .map((a) => {
              const { id, ...p } = a;
              return p;
            })
            .reduce((a, c) => {
              return { ...a, ...c };
            }, {});
          return o;
        })
        .reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {}),
    };

    const [status, res] = await credentialsServices.saveCredentials(
      objCredentials
    );
    if (status) {
      displayModalMessage(
        "Saved",
        <span>Credential was saved successfully</span>,
        "success"
      );
      // TODO delete all credentials from state?
    }
  };

  // TODO extract function to a custom hook
  const handleEdit = (values, id) => {
    const newCredential = {
      id,
      label: values.label,
      options: values.options,
    };
    const allCredentials = credentials.credentials;
    const index = allCredentials.findIndex((cred) => cred.id === id);
    const allCredentialChanged = [
      ...allCredentials.slice(0, index),
      newCredential,
      ...allCredentials.slice(index + 1, allCredentials.length),
    ];

    setCredentials({ ...credentials, credentials: allCredentialChanged });
  };

  // TODO extract function to a custom hook
  const deleteCredential = (id) => {
    const allCredentials = credentials.credentials;
    const filterCredentials = allCredentials.filter(
      (credential) => credential.id !== id
    );
    setCredentials({ ...credentials, credentials: filterCredentials });
  };

  const handleAddCredential = (credential) => {
    //setCredentials([...credentials, credential])
    setCredentials({
      ...credentials,
      credentials: [...credentials.credentials, credential],
    });
    setShowForm(false);
  };

  const addCredential = () => {
    setShowForm(!showForm);
  };

  return {
    credentials,
    practice,
    client,
    showForm,
    setShowForm,
    addCredential,
    handleDeleteCredentials,
    handleSaveCredentials,
    handleAddCredential,
    handleEdit,
    deleteCredential,
  };
};

export default useCredentialEdit;
