import styled from "styled-components";

export const ScrollbarWrapper = styled.div(
  ({ padding = 20, maxHeight = "900px", height = "80vh" }) => ({
    width: "100%",
    height: height,
    maxHeight: maxHeight,
    minWidth: "300px",
    overflowY: "scroll",
    //scrollbarColor: "white blue",
    padding: 0,
    //direction: "rtl",  // if you want to show the scroll bar on the left
    margin: 0,
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-track": {
      boxShadow: "nset 0 0 6px grey",
      borderRadius: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: "15px",
      height: "2px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#888",
      maxHeight: "10px",
    },
  }),
);

export const Content = styled.div(() => ({
  direction: "ltr", // if you want to show the scroll bar on the left
}));

export default ScrollbarWrapper;
