import moment from "moment";
import { defaultFormat } from "../../../util/date";

export function millisecondsToStr(milliseconds) {
  // TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  function numberEnding(number) {
    return number > 1 ? "s" : "";
  }

  var temp = Math.floor(milliseconds / 1000);
  var years = Math.floor(temp / 31536000);
  if (years) {
    return years + " year" + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  var days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + " day" + numberEnding(days);
  }
  var hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + " hour" + numberEnding(hours);
  }
  var minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + " minute" + numberEnding(minutes);
  }
  var seconds = temp % 60;
  if (seconds) {
    return seconds + " second" + numberEnding(seconds);
  }
  return "less than a second"; //'just now' //or other string you like;
}

export function getFullDate(ms) {
  if (!ms) return null;
  return moment(ms * 1000).format(defaultFormat + " hh:mm:ss a");
}

export function getDate(ms) {
  const date = getFullDate(ms);
  if (date) {
    return date.split(" ")[0];
  }
  return null;
}

export function getTime(ms) {
  const date = getFullDate(ms);
  if (date) {
    return date.split(" ").splice(1).join(" ");
  }

  return null;
}
