import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router";
import {getIssueById, getIssuesByTransactionId, addIssueComment} from "../issues.services";
import {getDateAsString} from "../../../../../../util/date";
import {toast} from "react-toastify";

const useIssues = () => {
  const {
    state: {
      id: transactionId,
      practice: practiceId,
      issueId
    },
  } = useLocation();

  const [issues, setIssues] = useState([]);
  const [issueSelected, setIssueSelected] = useState(issueId);
  const [hasIssueSelected, setHasIssueSelected] = useState(false);
  const [showModalResponse, setShowModalResponse] = useState(false);

  const getIssueDetailCallback = useCallback(async (make = false) => {
    console.log('getIssueDetailCallback');
    const index = issues.findIndex(issue => issue._id === issueSelected);

    const _issueSelected = issues[index];
    if (_issueSelected.issueDetails === null || make) {
      const [status, issueDetail] = await getIssueById(issueSelected);
      if (status) {
        const newIssue = {
          ..._issueSelected,
          issueDetails: {
            details: issueDetail.details,
            stepReproduce: issueDetail.stepReproduce,
            user: issueDetail.user,
            username: issueDetail.username,
            responses: issueDetail.responses || [],
          }
        };

        setIssues([...issues.slice(0, index), newIssue, ...issues.slice(index + 1, issues.length)]);
      }
    }
  }, [issueSelected, issues]);

  const getIssue = (arrIssues, id) => {
    const index = arrIssues.findIndex(issue => issue._id === id);
    return {
      index,
      issue: arrIssues[index]
    }
  }

  useEffect(() => {
    async function getIssues() {
      const [[statusIssues, issuesRes], [statusIssueDetail, issueDetail]] = await Promise.all([
        getIssuesByTransactionId({
          transaction: transactionId,
          practiceId,
        }),
        getIssueById(issueId)
      ]);

      if (statusIssues) {
        const {records} = issuesRes;
        const _issues = records.map(record => {
          return {
            _id: record._id,
            created: getDateAsString(new Date(record.created)),
            title: record.title,
            user: record.user || '',
            status: record.status || '',
            issueDetails: null
          }
        })
        //
        if (!hasIssueSelected && issueId) {
          const index = _issues.findIndex(issue => issue._id === issueSelected);
          const _issueSelected = _issues[index];

          if (statusIssueDetail) {
            const newIssue = {
              ..._issueSelected,
              issueDetails: {
                details: issueDetail.details,
                stepReproduce: issueDetail.stepReproduce,
                user: issueDetail.user,
                username: issueDetail.username,
                responses: issueDetail.responses,
              }
            };

            setIssues([..._issues.slice(0, index), newIssue, ..._issues.slice(index + 1, _issues.length)]);
          }
        } else {
          setIssues(_issues);
        }
      }
    }

    getIssues()
  }, []);

  useEffect(() => {
    async function getIssueDetail() {
      if (hasIssueSelected) {
        await getIssueDetailCallback();
      }
    }

    getIssueDetail()
  }, [issueSelected]);

  const handleSubmitResponse = async (res) => {
    const [status, response] = await addIssueComment(res.response, issueSelected);
    await getIssueDetailCallback(true);
    console.log(status, response);
    if (status) {
      setShowModalResponse(false);
      toast.success('Message sent successfully');
      console.log(res)
    }
  }


  return {
    issues,
    setIssueSelected,
    setHasIssueSelected,
    issueId,
    showModalResponse,
    setShowModalResponse,
    handleSubmitResponse,
  }
}

export default useIssues;