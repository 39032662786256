import { useEffect, useState } from "react";

export function Hide({
  initialState = false,
  inverted = false,
  control: Control,
  subscribe = () => {},
  ...props
}) {
  // const [s, setS] = useState(initialState);

  // useEffect(() => {
  //   subscribe(setS);
  // }, []);

  let otherProps = {};
  if (initialState) {
    if (!inverted)
      otherProps = {
        style: { display: "none" },
        border: false,
        required: false,
      };
  } else {
    if (inverted)
      otherProps = {
        style: { display: "none" },
        border: false,
        required: false,
      };
  }

  return <Control {...props} {...otherProps} />;
}

export function Show(props) {
  return <Hide /* initialState={!initialState} */ inverted={true} {...props} />;
}

export function Disable({
  initialState = false,
  inverted = false,
  subscribe = () => {},
  control: Control,
  ...props
}) {
  // const [s, setS] = useState(initialState);

  // useEffect(() => {
  //   subscribe(setS);
  // }, []);

  let otherProps = {};
  if (initialState) {
    if (!inverted)
      otherProps = {
        disabled: initialState,
        required: initialState ? false : props.required,
      };
  } else {
    if (inverted)
      otherProps = {
        disabled: !initialState,
        border: false,
        required: !initialState ? false : props.required,
      };
  }

  return <Control {...props} {...otherProps} />;
}

export function Enable(props) {
  return <Disable inverted={true} {...props} />;
}
