// import { faHome } from "@fortawesome/fontawesome-free-solid";

import {
  faExternalLinkAlt,
  faFile,
  faHome,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-ui/core";
import { Inbox } from "@material-ui/icons";

let menu = [];
export default function getMenu() {
  return menu;
}

export function setMenu(m) {
  menu = m;
}
