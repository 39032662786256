import { useState} from "react";
import {getUrlParams, removeUrlParams, setURLParams} from "../../../../util/browser";
import {withoutEmptyKeys} from "../../../../util/object";
import {getCity} from "../../ClientPractice/ClientPractice.service";
export const initialState = {
  name: '',
  state: '',
  city: '',
};

const urlKeys = Object.keys(initialState);

const useClientFilter = (setFilters) => {
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [data, _setData] = useState({
    ...initialState,
    ...getUrlParams(urlKeys)
  });

  const _setFilters = (d) => {
    setFilters(withoutEmptyKeys(d));
  };

  const loadCity = async (stateId) => {
    if (stateId) {
      let response = await getCity(stateId);
      console.log("Load City")
      console.log(response)
      response = response[1]['data']['value'].map(item => ({...item, value: item.id, label: item.name}))
      console.log("Load City222222222222222222222222")
      console.log(response)
      setCity(response)
    }
  }

  const handleFilterSubmit = (values) => {
    _setFilters(values);
    updateUrl(values);
  };

  const updateUrl = (newData) => {
    const f = withoutEmptyKeys(newData);
    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }
  };

  const handleReset = (reset) => {
    removeUrlParams(urlKeys)
    setFilters(initialState)
    _setData({
      ...initialState,
      ...getUrlParams(urlKeys)
    })
    reset()

  }

  return {
    data,
    handleFilterSubmit,
    loadCity,
    city,
    state,
    setState,
    handleReset
  };

}

export default useClientFilter