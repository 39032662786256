import { Api, decryptApiResponse } from "../../../../config/Api";
import { statusAndResponse } from "../../../../util/requestHandler";

export async function getList() {
  return await statusAndResponse(Api.get("/api/tmp?limit=50&offset=0"), (r) => {
    return decryptApiResponse(r).then((r) => r.data);
  });
}

export async function store(name, description) {
  return await statusAndResponse(
    Api.post("/api/tmp/store", { name, description })
  );
}

export async function duplicate(templateId, description, name) {
  return statusAndResponse(
    Api.post("/api/tmp/duplicate", { templateId, name, description })
  );
}
