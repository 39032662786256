import { Api, decryptApiResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";

export async function authenticate(data) {
  function onSuccess(resp) {
    return resp.data.success ? resp.data : false;
  }
  return statusAndResponse(Api.post("/api/authenticate/pin", data), onSuccess);
}

export async function requirePing(data) {
  const onSuccess = (resp) => {
    if (resp.data && resp.data.code && resp.data.code === 403)
      throw new Error("Blocked");

    if (resp.data && resp.data.code && resp.data.code === 406)
      throw new Error("Not acceptable country");

    return decryptApiResponse(resp).then((r) => {
      return { ...resp.data, result: r };
    });
  };

  return statusAndResponse(Api.post("/api/authenticate", data), onSuccess, (err) => {
  });
}

export async function passwordReset(email) {
  function onSuccess(resp) {
    return resp.data.success ? resp.data : false;
  }

  return statusAndResponse(
    Api.post("/api/authenticate/reset", { email }),
    onSuccess
  );
}
