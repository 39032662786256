import styled from "styled-components";

export  const StyledButton = styled.button`
  background-color: transparent;
  padding-left: 2px;
`;

export const HeaderColumn = styled.th`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  padding: 16px 0;
  text-align: center;
  font-size: 0.875rem;
`

export const HeaderColumnTr = styled.tr`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`

export const Title = styled.span`
  color: ${({border}) => border ? '#0069d9' : '#000'};
`

export const ActionsButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    padding: 6px;
  }
`

export const ReCallContainer = styled.span`
  background-color: ${({isGreen}) => isGreen ? '#45B695' : '#ffc10770'};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({isGreen}) => isGreen ? '#fff':'#c3a204'};
  font-weight: bold;
  cursor: pointer;
`
