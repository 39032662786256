import useFlagColor from "./useFlagColor";
import {Wrapper} from "../Connections/components/styles/ConnectionsStyles";
import ClientsContainer from "../Connections/components/ClientsContainer";
import CustomPanel from "../../CustomPanel";
import {TemplateWrapped} from "../../Layout";
import {HeaderButton} from "../../RemarkTemplate/Body";
import {faPlus} from "@fortawesome/fontawesome-free-solid";
import React from "react";
import CustomModal from "../../CustomModal";
import FlagColorForm from "./FlagColorForm";
import FlagColorTable from "./Component/FlagColorTable";
import {TableSection} from "./FlagColor.styles";

const FlagColor = () => {
  const {
    modalNew,
    setModalNew,
    selectedClient,
    allPractices,
    clientId,
    handleClientSelected,
    Delete,
    modalEdit,
    setModalEdit,
    colorEdit,
    setColorEdit,
    dataSource,
    tableReference,
    refreshData
  } = useFlagColor()

  return (
    <>
      <TemplateWrapped
        title={"Flag Color"}
        headerButtons={
          <HeaderButton
            icon={faPlus}
            message="Add a new Color"
            onClick={() => {
              if (selectedClient) {
                console.log("click", selectedClient.clientId)
              }

              setModalNew(true)
            }}
          />
        }
      >
        <CustomPanel>
          <Wrapper>
            <ClientsContainer
              clientId={clientId}
              clients={allPractices}
              clientIdSelected={selectedClient ? selectedClient.clientId : ''}
              handleClientSelected={handleClientSelected}
            />

            <TableSection>
              <FlagColorTable
                Delete={Delete}
                setColorEdit={setColorEdit}
                setModalEdit={setModalEdit}
                dataSource={dataSource}
                tableReference={tableReference}
                refreshData={refreshData}
              />
            </TableSection>

            <CustomModal
              show={modalNew}
              onHide={() => setModalNew(false)}
              title="Create new Color"
              size="sm">
              <FlagColorForm
                selectedClient={selectedClient}
                setModal={setModalNew}
                getFlags={refreshData}
                edit={false}
              />
            </CustomModal>

            <CustomModal
              show={modalEdit}
              onHide={() => setModalEdit(false)}
              title="Edit Color"
              size="sm">
              <FlagColorForm
                selectedClient={selectedClient}
                setModal={setModalEdit}
                getFlags={refreshData}
                edit={true}
                colorEdit={colorEdit}
              />
            </CustomModal>

          </Wrapper>
        </CustomPanel>

      </TemplateWrapped>

    </>
  )
}

export default FlagColor
