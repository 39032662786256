import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  addIssue,
  getIssuesByTransactionId
} from "../../TransactionDetail/components/TransactionIssueDetail/issues.services";
import {getDateAsString} from "../../../../util/date";
import {toast} from "react-toastify";
import {updateSpreadsheet, verifyFiles} from "../../../../services/missingCode";
import {displayModalMessage} from "../../../../util/displayMessage";

const useAllTransactionsTable = (tableReference, practiceId) => {
  const notesInitialState = {
    id: null,
    notes: null,
    apptData: "",
    saving: false,
  };

  const history = useNavigate();
  const [fMData, setFMData] = useState();
  const [notesModalData, displayNotesModal] = useState(notesInitialState);
  const [modalData, setModalData] = useState(false);
  const [updatingSpreadsheet, setUpdatingSpreadsheet] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState(null);
  const [transactionIdSelected, setTransactionIdSelected] = useState(null);
  const [issues, setIssues] = useState(null);
  const [showIssueModal, setShowIssueModal] = useState(false);

  useEffect(() => {
    async function getIssues() {
      if (transactionIdSelected) {
        const [status, issuesRes] = await getIssuesByTransactionId({
          transaction: transactionIdSelected.transactionId,
          practice: transactionIdSelected.practiceId
        })

        if (status) {
          const {records} = issuesRes
          setIssues(records.map(record => {
            return {
              _id: record._id,
              created: getDateAsString(new Date(record.created)),
              title: record.title,
              user: record.user || '',
            }
          }))
        }
      }
    }
    getIssues()
  }, [transactionIdSelected])

  const handleIssueButtonClick = async (id, pId, pName) => {
    setTransactionIdSelected({
      transactionId: id,
      practiceId: pId,
      practiceName: pName
    })
  }

  const handleSubmitIssue = async (data) => {
    const [status, res] = await addIssue({
        ...data,
        practiceId: transactionIdSelected.practiceId,
        transactionId: transactionIdSelected.transactionId,
        productId: process.env.REACT_APP_PRODUCT_ID,
        status: 'open'
      }
    )

    if (status) {
      const {id: newId} = res
      setIssues([
        ...issues,
        {...data, _id: newId, created: getDateAsString(new Date())}
      ])
      setShowIssueModal(false)
    }
  }

  function save(spData) {
    const tr = tableReference.current;

    setUpdatingSpreadsheet(true);

    if (Object.entries(spData).filter(([k, v]) => v === "").length > 0) {
      setUpdatingSpreadsheet(false);
      toast.error("Please complete all the fields");
      return;
    }

    updateSpreadsheet({
      ...spData,
      practiceId,
      apptDateType: spData.ApptDate + "T00:00:00.000Z",
    }).then(([s, res]) => {
      if (!s) {
        setUpdatingSpreadsheet(false);
        return;
      }

      setModalData(false);
      setUpdatingSpreadsheet(false);
      displayModalMessage(
        "Success",
        "Data was successfully updated",
        "success"
      );
      tr && tr.onQueryChange();
    });
  }

  async function handleVerify(id, practice) {
    const [status, res] = await verifyFiles(id, practice);
    if (status) {
      setFiles(id)
      setShowModal(true)
    }
  }

  return {
    history,
    fMData,
    setFMData,
    notesModalData,
    displayNotesModal,
    modalData, setModalData,
    updatingSpreadsheet,
    setUpdatingSpreadsheet,
    showModal,
    setShowModal,
    files,
    setFiles,
    transactionIdSelected,
    setTransactionIdSelected,
    issues,
    setIssues,
    showIssueModal,
    setShowIssueModal,
    handleIssueButtonClick,
    handleSubmitIssue,
    save,
    handleVerify
  }
}

export default useAllTransactionsTable