import {useCallback, useEffect, useState} from "react";
import Cookies from "universal-cookie";
import fileDownload from "js-file-download";
import {download, getDataMerge, prepareResponse, save} from "../../../../services/missingCode";
import {decryptData} from "../../../../config/encryptData";
import {displayModalMessage} from "../../../../util/displayMessage";
import { formattedData, getAffectedControlState } from "../missingCodeTab.middleware";
import { getGroupName, processName } from "../missingCodeUtils";

const useMissingCodeTab = (
  {
    permissions,
    transactionId,
    notifyChanges,
    beforeSave,
    reloadData,
  }
) => {
  const [fileName, setFileName] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loaderIsActive, setLoaderIsActive] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [Spreedsheet, setSpreedsheet] = useState({});
  const [data, setData] = useState({ affectsOn: {}, data: [] });
  const [showPreview, setShowPreview] = useState(false);

  const _permissions = {
    missingCode: {
      audit_transaction: false,
      edit_missingcode: false,
      list_missingcode: false,
      update_pms: false,
    },
    FBD: {},
    ...permissions,
  };

  const handleSubmit = (inputsForm) => {
    // let the user handle if the info is verified

    const bookmarks = inputsForm.reduce((acum, i) => {
      return {
        ...acum,
        ...prepareResponse(i),
      };
    }, {});

    beforeSave(bookmarks, () => {
      setLoaderIsActive(true);
      const cookies = new Cookies();
      let othersOptions = JSON.parse(cookies.get("otherOptions"));
      decryptData(othersOptions).then((resp) => {
        save(inputsForm, transactionId, resp.name).then(([s, _r]) => {
          setLoaderIsActive(false);
          if (s) {
            displayModalMessage(
              "Success",
              "Data saved successfully",
              "success"
            );
            notifyChanges();
          }
        });
      });
    });
  };

  const fetchData = useCallback(() => {
    function setSpreadsheetAndGetName(
      elements,
      groupName
    ) {
      let name = "";
      if (elements.spreedsheet !== undefined) {
        setSpreedsheet({ ...elements.spreedsheet, groupName });
        name = processName(elements)
      } else {
        name = "Insurance_verification.docx";
      }
      return name;
    }

    getDataMerge(transactionId)
      .then(([_status, elements]) => {
        // TODO {appNotes}

        let groupName = getGroupName(elements)

        let { formattedSections, formattedAffections } =
          formattedData(elements);

        setData({
          affectsOn: formattedAffections,
          data: formattedSections,
          apptNote: elements.spreedsheet.apptNote,
          apptData: elements.spreedsheet.apptData,
          savingNote: false,
        });
        const name = setSpreadsheetAndGetName(
          elements,
          groupName
        );
        setFileName(name);
        setLoadingData(false);
      })
      .catch(function (_ex) {console.log(_ex)});
  }, [reloadData]);

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    if (reloadData) {
      setLoadingData(true);
      fetchData();
    }
  }, [reloadData]);

  const handleDownloadFile = (_e) => {
    if (data.data.length === 0) {
      displayModalMessage("Warning", "No data to download", "warning");
      return;
    }
    setLoaderIsActive(true);
    download(transactionId)
      .then((response) => {
        setLoaderIsActive(false);
        fileDownload(response.data, fileName);
      })
      .catch((_err) => {
        setLoaderIsActive(false);
        displayModalMessage(
          "Oops...",
          "An error occurred while trying download the file, please try again.",
          "error"
        );
      });
  };

  const handleCloseModal = () => {
    setShowBox(false);
  };

  function handler(parentI, childI) {
    return function (value) {
      let newData = [...data.data];
      const control = newData[parentI]["content"][childI];

      if (control.props.value === value) {
        return;
      }

      const affection = data.affectsOn[control.affectedId];
      if (control.affectedId && affection) {
        const state = value === affection["affectWhen"];

        affection["children"].forEach((x) => {
          const { required, border } = getAffectedControlState(
            state,
            newData[x.parent]["content"][x.child]["props"].isInitiallyRequired,
            affection.type
          );

          newData[x.parent]["content"][x.child]["props"] = {
            ...newData[x.parent]["content"][x.child]["props"],
            initialState: state,
            required,
            border,
          };
        });
      }

      control["props"] = {
        ...control["props"],
        value,
        border: control.props.required && value.length === 0,
      };

      setData({ ...data, data: [...newData] });
    };
  }

  const togglePreview = () => {
    setShowPreview((p) => !p);
  };

  return{
    _permissions,
    Spreedsheet,
    showPreview,
    showBox,
    loadingData,
    loaderIsActive,
    data,
    setShowBox,
    handler,
    togglePreview,
    handleDownloadFile,
    handleSubmit,
    handleCloseModal
  }

}

export default useMissingCodeTab
