import { Api } from "../config/Api";
import { statusAndResponse } from "../util/requestHandler";

const doNothing = [() => {}, () => {}, () => {}];

export const convertDocxToPdf = (file, setUploadProgress) => {
  return statusAndResponse(
    Api.post("/api/utils/docxtopdf", file, {
      responseType: "blob",
      onUploadProgress: (data) => {
        setUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
    (r) => r,
    ...doNothing
  );
};

export const convertPdfToTable = (file, setUploadProgress) => {
  return statusAndResponse(
    Api.post("/api/utils/pdftotable", file, {
      responseType: "blob",
      onUploadProgress: (data) => {
        setUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
    }),
    (r) => r,
    ...doNothing
  );
};
