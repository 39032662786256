import fileDownload from "js-file-download";

export const FILE_TYPES = {
  pdf: "application/pdf",
};

const tryToOpenFile = (file) => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error("Problem parsing file"));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(file);
  });
};

export const openInBrowser = async (blob, fileType, onError) => {
  try {
    const f = await tryToOpenFile(blob);
    const r = JSON.parse(f);
    onError && onError(r);
  } catch (er) {
    if (er.message.indexOf("JSON") > -1) {
      const file = new Blob([blob], {
        type: fileType,
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }
};

export const download = async (blob, fileName, onError = null) => {
  try {
    const f = await tryToOpenFile(blob);
    const r = JSON.parse(f);
    onError && onError(r);
    return false;
  } catch (er) {
    if (er.message.indexOf("JSON") > -1) {
      fileDownload(blob, fileName);
      return true;
    }
  }
};
