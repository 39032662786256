import {useContext, useState} from "react";
import { AppContext } from "../../../../App";

const years = [
  {year: 2021, selected: false},
  {year: 2022, selected: false},
  {year: 2023, selected: false},
  {year: 2024, selected: false},
]

const useFeeSchedule = () => {
  const appData = useContext(AppContext);
  const [selectedPractice, setSelectedPractice] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [redirectTo, setRedirectTo] = useState(null)

  const practices = appData.get("practices");
  const formatedPractices = practices.map(practice=>{
    return {
      label: practice.name,
      id: practice._id
    }
  })

  return {
    formatedPractices,
    years,
    setSelectedPractice,
    setSelectedYear,
    selectedPractice,
    selectedYear,
    redirectTo,
    setRedirectTo
  }
}

export default useFeeSchedule