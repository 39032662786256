const MENU = {
  home: require('./../../../assets/images/svg_v2/home_ln.svg').default,
  homeFolded: require('./../../../assets/images/svg_v2/home.svg').default,
  insurance : require('./../../../assets/images/svg_v2/insurance_ln.svg').default,
  insuranceFolded:  require('./../../../assets/images/svg_v2/insurance.svg').default,
  dataQualityFolded : require('./../../../assets/images/svg_v2/dataQuality.svg').default,
  dataQuality: require('./../../../assets/images/svg_v2/dataQuality_ln.svg').default,
  settings: require('./../../../assets/images/svg_v2/settings_ln.svg').default,
  settingsFolded: require('./../../../assets/images/svg_v2/settings.svg').default,
  template: require('./../../../assets/images/svg_v2/template_ln.svg').default,
  templateFolded: require('./../../../assets/images/svg_v2/template.svg').default,
  users: require('./../../../assets/images/svg_v2/users_v2.svg').default,
  usersFolded: require('./../../../assets/images/svg_v2/users_v2.svg').default,
  connections: require('./../../../assets/images/svg_v2/connection_ln.svg').default,
  connectionsFolded: require('./../../../assets/images/svg_v2/connection.svg').default,
  tools: require('./../../../assets/images/svg_v2/tools_ln.svg').default,
  toolsFolded: require('./../../../assets/images/svg_v2/tools.svg').default,
  reports: require('./../../../assets/images/svg_v2/report.svg').default,
  reportsFolded: require('./../../../assets/images/svg_v2/report.svg').default,
  files: require('./../../../assets/images/svg_v2/files_ln.svg').default,
  filesFolded: require('./../../../assets/images/svg_v2/files.svg').default,
  airCall: require('../../../assets/images/svg_v2/aircall_ln.svg').default,
  airCallFolded: require('../../../assets/images/svg_v2/aircall.svg').default
}
export default MENU;
