import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonForm = props => {
  const {size} = props
  if(size === 'lg')
  {
    return (
      <ContentLoader
        speed={2}
        width={800}
        height={600}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="45" y="0" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="15" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="70" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="85" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="140" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="160" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="215" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="230" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="285" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="300" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="355" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="370" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="425" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="440" rx="5" ry="5" width="600" height="45"/>

        <rect x="45" y="495" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="510" rx="5" ry="5" width="600" height="45"/>


      </ContentLoader>
    );

  }
  if(size === 'md')
  {
    return (
      <ContentLoader
        speed={2}
        width={800}
        height={600}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="45" y="0" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="15" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="70" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="85" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="140" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="160" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="215" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="230" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="285" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="300" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="355" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="370" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="425" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="440" rx="5" ry="5" width="400" height="45"/>

        <rect x="45" y="495" rx="5" ry="5" width="130" height="12"/>
        <rect x="45" y="510" rx="5" ry="5" width="400" height="45"/>


      </ContentLoader>
    );

  }

};

export default SkeletonForm;
