import { Form } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "../styles.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const PassWordWrapper = styled.div`
  position: relative;
`

const EyeIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ccc;
  &:hover {
    color: #000;
  }
`

const FormInputText = ({
  id,
  required,
  maxLength,
  disabled,
  readOnly,
  msg,
  label,
  pattern = /^[a-zA-Z0-9\s,./$#%?_&'-()]*$/i,
  value: defaultValue = "",
  onChange,
  onError,
  type = "text",
  style,
  className,
  inputClassName,
  invalidMessage = "*Invalid text",
  noState = false,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isPassword, setIsPassword] = useState(id === 'MemberID');
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const handleChange = (e) => {
    if (noState === false) setValue(e.target.value);

    onChange && onChange(e);
  };

  const handleError = (element) => {
    onError &&
      onError({
        status: !!errors?.[element],
        control: element,
        details: errors?.[element],
      });
    //console.log(errors?.[element])
    return errors?.[element];
  };

  const inputClass =
    (inputClassName ? inputClassName : "custom-form-control ") +
    (props.border && !value ? "red-border" : "");

  return (
    <Form.Group style={style} className={className}>
      {label && <Form.Label>{label}</Form.Label>}
      {id === 'MemberID' ? (
        <PassWordWrapper>
          <Form.Control
            name={id}
            type={isPassword ? "password" : "text"}
            id={id}
            disabled={disabled}
            placeholder={msg}
            maxLength={maxLength}
            className={inputClass}
            {...register(id, { pattern })}
            defaultValue={value}
            onChange={handleChange}
            required={required}
            readOnly={readOnly}
            onBlur={props.onBlur ? props.onBlur : null}
            autoComplete="new-password"
          />
          <EyeIcon>
            <FontAwesomeIcon icon={faEye} onClick={() => setIsPassword(!isPassword)} />
          </EyeIcon>
        </PassWordWrapper>
      ) : (<Form.Control
        name={id}
        type={type}
        id={id}
        disabled={disabled}
        placeholder={msg}
        maxLength={maxLength}
        className={inputClass}
        {...register(id, { pattern })}
        defaultValue={value}
        onChange={handleChange}
        required={required}
        readOnly={readOnly}
        onBlur={props.onBlur ? props.onBlur : null}
      />)}
      {handleError(id) && <span className="text-danger">{invalidMessage}</span>}
    </Form.Group>
  );
};

export default FormInputText;

export const emailValidation =
  /^(?:[a-zA-Z0-9]+\.?)+[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

export const passwordValidation =
  /^(?=.*[A-Z].*)(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/;
