import { useEffect, useState } from "react";
import * as services from "../../aircall.services";

const useAirCallDetail = (id) => {
  const [data, setData] = useState();
  useEffect(() => {
    services.getRecord(id).then(([s, r]) => {
      if (s) setData(r.call);
    });
  }, [id]);

  return [data];
};

export default useAirCallDetail;
