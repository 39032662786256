import React from "react";
import { Col, Row } from "react-bootstrap";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  ContainerAnalitycsInfo,
  ContainerCircularProgress,
} from "./AnalitycsInfo.styles";
import { PracticeItemContainer } from "../practice-list/PracticeItem.styles";

const AnalitycsInfo = ({ statictics }) => {
  return (
    <PracticeItemContainer style={{ marginBottom: "4rem" }}>
      <Row>
        {statictics.map((item) => (
          <Col className="mt-3" xs={4} sm={4} md={4} key={item.name}>
            <ContainerAnalitycsInfo>
              <ContainerCircularProgress>
                <CircularProgressbar
                  value={item.name === "Total" ? 100 : item.percentage}
                  text={item.name === "Total" ? "100%" : `${item.percentage}%`}
                  styles={buildStyles({
                    textColor: "black",
                    pathColor: item.name !== "Total" ? "#45b605" : "#3A9EDB",
                  })}
                />
              </ContainerCircularProgress>
              <span
                style={{
                  marginTop: "1rem",
                  fontSize: "0.9rem",
                  marginLeft: ".4rem",
                  textAlign: "center",
                }}
              >
                {item.name} ({new Intl.NumberFormat("en-En").format(item.count)}
                )
              </span>
            </ContainerAnalitycsInfo>
          </Col>
        ))}
      </Row>
    </PracticeItemContainer>
  );
};

export default AnalitycsInfo;
