import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import withReactContent from "sweetalert2-react-content";
import CustomModal from "../../../CustomModal";
import { TemplateContext } from "../TemplateContext";
import Swal from "sweetalert2";
import EditBookmark from "./EditBookmark";
import * as middleware from "../middlewares/index";

const alert = withReactContent(Swal);

const deleteConfirmation = (callback) => {
  alert
    .fire({
      title: "Delete Bookmark",
      text: "Are you sure you want to delete this bookmark?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    })
    .then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
};

export default function Bookmark({
  bookmark: { name, label, id, ...bookmark },
  category: { id: categoryId, categoryType },
  parentCategory,
}) {
  const [deleting, setDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const {
    deleteBookmark,
    updateAssociatedBookmarkData,
    updateBookmarkConditions,
    getAssociatedBookmarkData,
    getBookmarkActions,
    getBookmarkAffectTypes,
    getBookmarksToAffect,
    saveAffectedBookmarks,
  } = useContext(TemplateContext);

  const sectionId = !parentCategory ? categoryId : parentCategory.id;
  const subSectionId = !parentCategory ? "" : categoryId;

  useEffect(() => {
    if (showModal === false) {
      setModalData(false);
      return;
    }

    async function _fetchData() {
      let [bookmarkData, actions, affectTypes, bookmarks] = await Promise.all([
        getAssociatedBookmarkData(
          categoryId,
          categoryType,
          id,
          bookmark.elementType
        ),
        getBookmarkActions(),
        getBookmarkAffectTypes(),
        getBookmarksToAffect(id, sectionId, subSectionId),
      ]);

      const formatedBookmarks = bookmarks.map((x) => ({
        ...x,
        label: `${x.bookmark ? x.bookmark + "-" : ""} ${x.label}`,
      }));

      let action = "";
      let value = "";
      let affectingBookmarks = [];
      let affecting = [];

      // TODO {franklin} cleanUp
      // Look up for children (plain array to avoid issues)

      const formatedSelfOptions =
        bookmark.elementType === "checkbox" && bookmark.options
          ? bookmark.options.map((x) => middleware.bookMarkToAffect(x, true))
          : [];

      if (bookmark.affectsOn) {
        action = bookmark.affectsOn.actions[0];
        value =
          bookmark.elementType !== "checkbox"
            ? bookmark.affectsOn.whenValueContains[0]
            : formatedSelfOptions.filter(
                (x) => (x.bookmark = bookmark.affectsOn.whenValueContains[0])
              )[0];

        const bookmarkChildren = formatedBookmarks.reduce((a, i) => {
          if (i.items) {
            a = [...a, ...i.items];
          }
          return a;
        }, []);

        affectingBookmarks = [...formatedBookmarks, ...bookmarkChildren].reduce(
          (a, i) => {
            const found = bookmark.affectsOn.destination.filter((x) => {
              return x.id === i.id;
            });
            if (found.length === 1) {
              a.push(i);
            }
            // look iniside children (if exists)
            return a;
          },
          []
        );

        affecting = formatedSelfOptions.reduce((a, i) => {
          const found = bookmark.affectsOn.destination.filter((x) => {
            return x.id === i.id;
          });
          if (found.length === 1) a.push(i);
          return a;
        }, []);
      }

      setModalData({
        ...bookmarkData,
        onSave: async (data) => {
          const status = await updateAssociatedBookmarkData(
            id,
            categoryId,
            categoryType,
            bookmark.elementType,
            data,
            parentCategory ? parentCategory.id : null
          );
          if (status) {
            setShowModal(false);
          }
        },
        onSaveConditions: async (data) => {
          const status = await updateBookmarkConditions(
            id,
            sectionId,
            subSectionId,
            data
          );
          if (status) {
            setShowModal(false);
          }
        },
        affects: {
          action,
          value,
          affecting,
          affectingBookmarks,
          actions,
          bookmarks: formatedBookmarks,
          affectTypes,
          bookmarkType: bookmark.elementType,
          items: formatedSelfOptions,
          onSubmit: async (data) => {
            const status = await saveAffectedBookmarks(
              id,
              data,
              sectionId,
              subSectionId
            );
            if (status) {
              setShowModal(false);
            }
          },
        },
      });
    }
    _fetchData();
  }, [showModal]);

  return (
    <div className={`bookmark d-flex m-1 ${deleting && "jumbotron"}`}>
      {!deleting && (
        <>
          <div>
            <small className="text-muted">
              <strong>Bookmark:</strong> {name}
            </small>
            <p>{label}</p>
          </div>
          <div className="d-flex flex-column ml-2 small">
            <button
              className="text-danger"
              onClick={() => {
                deleteConfirmation(() => {
                  setDeleting(true);
                  deleteBookmark(id, sectionId, subSectionId);
                });
              }}
            >
              <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
            </button>
            <button
              className="text-info"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <FontAwesomeIcon icon="cog"></FontAwesomeIcon>
            </button>
          </div>
        </>
      )}
      {deleting && (
        <small className="text-muted align-self-center">
          Deleting Bookmark <FontAwesomeIcon icon="spinner" pulse />{" "}
        </small>
      )}
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={name}
      >
        {!modalData && (
          <p className="small text-muted">
            Loading Bookmark data, please wait{" "}
            <FontAwesomeIcon icon="spinner" spin />
          </p>
        )}
        {modalData && (
          <EditBookmark bookmarkType={bookmark.elementType} {...modalData} />
        )}
      </CustomModal>
    </div>
  );
}
