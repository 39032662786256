export function withoutEmptyKeys(dictionary) {
  return Object.entries(dictionary).reduce((a, [key, value]) => {
    if (value) a[key] = value;
    return a;
  }, {});
}

export function iterateEveryKey(obj, callback, ignoredKeys = []) {
  try {
    if (Array.isArray(obj)) {
      return obj.forEach((x) => iterateEveryKey(x, callback, ignoredKeys));
    }

    for (const [key, value] of Object.entries(obj)) {
      if (value instanceof Object) {
        if (ignoredKeys.includes(key) === false) {
          iterateEveryKey(value, callback, ignoredKeys);
        }
      }

      callback(obj, key, value);
    }
  } catch (err) {
    console.log(err);
  }
}
