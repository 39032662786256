import { useEffect, useState } from "react";
import {getCarriers, getPhoneFromCarrier} from "../../../CallCenter/services/callCenter.service";

const transformData = carriers => {
  return carriers.map((carrier) => {
    return {
      label: carrier.name,
      value: carrier._id,
    };
  })
}

const useTransactionAirCall = () => {
  const [carriers, setCarriers] = useState([]);
  const [idCarrierSelected, setIdCarrierSelected] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  useEffect(() => {
    async function fetchCarriers() {
      const [success, _carriers] = await getCarriers();
      if (success) {
        setCarriers(transformData(_carriers));
        setIdCarrierSelected(_carriers[0]._id);
      }
    }
    fetchCarriers();
  }, []);

  useEffect(() => {
    async function fetchCarrierById() {
      if (idCarrierSelected) {
        const [success, carrier] = await getPhoneFromCarrier(idCarrierSelected);
        if (success) {
          setSelectedCarrier(carrier);
        }
      }
    }

    fetchCarrierById();
  }, [idCarrierSelected]);

  return {
    carriers,
    setIdCarrierSelected,
    selectedCarrier,
    idCarrierSelected
  }
}

export default useTransactionAirCall;
