import {Api, decryptApiResponse, fakeResponse} from "../../../../config/Api";
import {statusAndResponse} from "../../../../util/requestHandler";

export const getLog = (filters) => {
  function onSuccess(data) {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  }

  return statusAndResponse(
    Api.get(`/api/extractionSchedule/logs?sheetDate=${filters.sheetDate}&limit=${filters.limit}&offset=${filters.offset}&practiceId=${filters.practiceId}`),
    onSuccess,
    () => {},
    () => {}
  );
}

export const getFakeLogs = () => {
  const fakesLogs = [
    {
      _id: (new Date).getTime(),
      clientId: `Test-${(new Date()).getTime()}`,
      practiceId: `Test-${(new Date()).getTime() * Math.random()}`,
      sheetDate: 'sheet',
      rowNumber: 3,
      status: false,
      message: 'List Index Out of Range',
      title: 'Check / error while updating Annual Max',
      dateCreated: 'regdate',
      //data_to_replicate:'date',
    }
  ]
  return fakeResponse(fakesLogs, false, 300)
}