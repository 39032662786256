import { Api, decryptApiResponse, fakeResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";

export function getPermissions(){
  return statusAndResponse(
    Api.get(`/api/authz/system/permission`),
    decryptApiResponse
  )
}

export function getCatalog(){
  return statusAndResponse(
    Api.get(`/api/authz/system/catalog`),
    decryptApiResponse
  )
}

export function getPermissionType(){
  return statusAndResponse(
    Api.get('/api/authz/system/permission/type'),
    decryptApiResponse
  )
}

export function savePermission(data){
  return statusAndResponse(
    Api.post(`/api/authz/system/permission`, {...data}),
    decryptApiResponse
  )
}

export function updatePermission(id, data){
  return statusAndResponse(
    Api.put(`/api/authz/system/permission/${id}`, {...data}),
    decryptApiResponse
  )
}

export function deletePermission(id){
  return statusAndResponse(
    Api.delete(`/api/authz/system/permission/${id}`),
    decryptApiResponse
  )
}