import {useEffect, useState} from "react";
import * as credentialsServices from './../../services/credential.services'
import {displayModalMessage} from "../../../../util/displayMessage";

const initialState = {
  'clientId': '',
  'practiceId': '',
  'credentials': []
}
const useCredentialManager = () => {
  const [showForm, setShowForm] = useState(false);
  const [credentials, setCredentials] = useState(initialState)
  const [client, setClient] = useState(null)
  const [practice, setPractice] = useState(null)
  const [clients, setClients] = useState([])
  const [practices, setPractices] = useState([])

  useEffect(async () => {
    const [status, res] = await credentialsServices.getAllClients()
    if (status) {
      setClients(res.records || [])
    }
  }, [])

  useEffect(async () => {
    if (client) {
      const [status, res] = await credentialsServices.getPracticesById(client)
      if (status) {
        setPractices(res)
      } else {
        setPractices([])
      }
    }
  }, [client])

  const handleAddCategory = () => {
    setShowForm(!showForm)
  }

  const handleAddCredential = (credential) => {
    //setCredentials([...credentials, credential])
    setCredentials({...credentials, 'credentials': [...credentials.credentials, credential]})
    setShowForm(false)
  }

  const deleteCredential = (id) => {
    const allCredentials = credentials.credentials
    const filterCredentials = allCredentials.filter(credential => credential.id !== id)
    setCredentials({...credentials, 'credentials': filterCredentials})
  }

  const handleSaveCredentials = async () => {
    let objCredentials = {
      clientId: client,
      'practiceId': practice,
      "credentials": credentials.credentials.map(cred => {
        let o = {}
        o[cred.label] = cred.options.map(a => {
          const {id, ...p} = a
          return p
        }).reduce((a, c) => {
          return {...a, ...c}
        }, {})
        return o
      }).reduce((acc, curr) => {
        return {...acc, ...curr}
      }, {})
    }

    const [status] = await credentialsServices.saveCredentials(objCredentials)
    if (status){
      displayModalMessage('Saved', <span>Credential was saved successfully</span>, 'success')
    }
  }

  const handleEdit = (values, id) => {
    const newCredential = {
      id,
      label: values.label,
      options: values.options
    }
    const allCredentials = credentials.credentials
    const index = allCredentials.findIndex(cred => cred.id === id)
    const allCredentialChanged = [...allCredentials.slice(0, index), newCredential, ...allCredentials.slice(index + 1, allCredentials.length)]

    setCredentials({...credentials, 'credentials': allCredentialChanged})
  }

  const addCredential = () => {
    setShowForm(!showForm)
  }

  return {
    showForm,
    credentials,
    clients,
    client,
    practice,
    practices,
    setShowForm,
    setClient,
    setPractice,
    addCredential,
    handleAddCredential,
    handleAddCategory,
    handleEdit,
    handleSaveCredentials,
    deleteCredential
  }
}

export default useCredentialManager
