import React from "react";
import { MdStickyNote2 } from "react-icons/all";

const NoDataFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      }}
    >
      <div
        style={{
          width: 150,
          height: 150,
          backgroundColor: "rgba(236,236,236,0.65)",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MdStickyNote2 style={{ width: 100, height: 100, color: "#ccd5db" }} />
      </div>
      <p style={{ textAlign: "center", marginTop: 20 }}>
        You must select a practice and then press the search statistics button
        in order to display information.
      </p>
    </div>
  );
};

export default NoDataFound;
