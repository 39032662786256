import {Col, Row} from "react-bootstrap";

import CustomDatePicker from "../../../CustomDatePicker";
import {TemplateWrapped} from "../../../Layout";
import CustomPanel from "../../../CustomPanel";
import useSftpFileReport from "./hooks/useSftpFileReport";
import ReportTable from "../../AzureFiles/components/Report/components/ReportTable";
import withSelectedPractices from "../../../HOCS/withSelectedPractices/withSelectedPractices";


const SftpFilesReport = () => {
  const {
    date,
    data,
    structure,
    setDate,
  } = useSftpFileReport()

  const hasData = Object.keys(data).length !== 0

  return(
    <TemplateWrapped
      title="Sftp Files Report"
    >
      <CustomPanel>
        <Row>
          <Col md={3}>
            <label htmlFor="date">Date:</label>
            <CustomDatePicker
              id="date"
              onChange={(date) => {
                setDate(date)
              }}
              value={date}
            />
          </Col>
        </Row>
        {date && structure && hasData && (
          <ReportTable
            //key={shortid.generate()}
            data={data}
            structure={structure}
          />
        )}

        {!date && !structure && (
          <div className='jumbotron mt-2'>Please select a date</div>
        )}

        {!hasData && date && (
          <div className='jumbotron mt-2'>No data available</div>
        )}
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default withSelectedPractices(SftpFilesReport);