import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TableInsuranceVerification from "../../helper/TableInsuranceVerification";
import { TemplateWrapped } from "../../Layout";
import { HeaderButton, HeaderFilterButton } from "../../RemarkTemplate/Body";
import InsuranceVerificationForm from "./components/InsuranceVerificationForm";
import IVFilter from "./components/IVFilter";
import useInsuranceVerification from "./useInsuranceVerification";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const Insuranceverification = () => {
  const {
    filters,
    setFilters,
    OpenForm,
    showModal,
    ref,
    handleCloseModal,
    getRecords,
    carrierList,
    OnChangeCarrier,
    displayForm,
    carrierForm,
    handleSubmitModalForm,
    carrierId,
    loading,
    setPracticeSelect
  } = useInsuranceVerification();

  return (
    <TemplateWrapped
      title="Insurance Verification | Real Time"
      headerButtons={
        <>
          <HeaderFilterButton
            active={filters && Object.keys(filters).length > 0}
            render={IVFilter}
            componentProps={{
              setFilters,
              initialData: filters,
            }}
          />
          <HeaderButton icon={faPlus} message="New Record" onClick={OpenForm}  />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              <TableInsuranceVerification
                tableReference={ref}
                asyncDataSource={getRecords}
              />
            </div>
          </div>
        </div>
        {showModal.status ? (
          <InsuranceVerificationForm
            handleCloseModal={handleCloseModal}
            showModal={showModal}
            carrierList={carrierList}
            OnChangeCarrier={OnChangeCarrier}
            displayForm={displayForm}
            carrierForm={carrierForm}
            handleSubmitModalForm={handleSubmitModalForm}
            carrierId={carrierId}
            loading={loading}
            setPracticeSelect={setPracticeSelect}
          />
        ) : null}
      </div>
    </TemplateWrapped>
  );
};

export default withSelectedPractices(Insuranceverification)
