import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Api, decryptApiResponse, fakeResponse } from "../../../config/Api.js";
import * as auth from "../../../util/auth.js";
import { getDateAsString } from "../../../util/date.js";
import { displayModalConfirmation } from "../../../util/displayMessage.js";
import { statusAndResponse } from "../../../util/requestHandler.js";
import CustomTable, { TextColumnHighlighted } from "../../CustomTable/index.js";
import { TemplateWrapped } from "../../Layout/index.js";

// services

export function unblockAccess(accessId) {
  function onSuccess(d) {
    return decryptApiResponse(d);
  }

  return statusAndResponse(
    Api.put("/api/access/set/state", { accessId, state: true }),
    onSuccess
  );
}

export function blockAccess(accessId) {
  function onSuccess(d) {
    return decryptApiResponse(d);
  }

  return statusAndResponse(
    Api.put("/api/access/set/state", { accessId, state: false }),
    onSuccess
  );
}

export function getDataById() {
  function onSuccess(d) {
    return decryptApiResponse(d);
  }

  return statusAndResponse(Api.get("/api/v2/users/access/all"), onSuccess);
}

function useLoginHistory(userId) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (userId)
      getDataById(userId).then(([status, r]) => {
        setData(r);
        setLoading(false);
      });
  }, [userId]);

  return [data, isLoading, setData];
}

const columns = [
  {
    field: "createdAt",
    hidden: true,
    sorting: true,
    defaultSort: "desc",
    type: "date",
  },
  {
    title: "Device",
    field: "deviceType",
  },
  {
    title: "Date",
    render: (row) => {
      return getDateAsString(
        row.createdAt,
        "yyyy-MM-DDTHH:mm:ss",
        "MM/DD/yyyy HH:mm:ss"
      );
    },
  },
  {
    title: "Country",
    field: "country",
  },
  {
    title: "Region",
    field: "city",
  },
  {
    title: "IP",
    field: "ip",
  },
  {
    title: "Browser",
    field: "browser",
  },
  {
    title: "Version",
    field: "browserVersion",
  },
  {
    title: "Status",
    render: (rowData) => {
      return rowData.isValid ? (
        <TextColumnHighlighted text="Active" status="success" />
      ) : (
        <TextColumnHighlighted text="Inactive" status="danger" />
      );
    },
  },
];

export default function LoginHistory() {
  const { userId } = auth.getLoggedInData();
  const [data, isLoading, setData] = useLoginHistory(userId);

  function updateData(index, value) {
    let newData = [...data];
    newData[index] = value;
    setData(newData);
  }

  const actions = [
    (rowData) => {
      const i = data.indexOf(rowData);

      return (
        rowData.isValid && {
          icon: "app_blocking",
          tooltip: "Block",
          iconProps: { color: "secondary" },
          onClick: (event, rowData) =>
            displayModalConfirmation(
              "Block IP",
              "Dear user, would you like to block this IP access?",
              function () {
                blockAccess(rowData._id).then(([status, resp]) => {
                  if (status) {
                    toast.success("IP Access was successfully blocked");
                    updateData(i, { ...rowData, isValid: false });
                  }
                });
              }
            ),
        }
      );
    },
    (rowData) => {
      const i = data.indexOf(rowData);
      return (
        !rowData.isValid && {
          icon: "smartphone",
          tooltip: "Unblock",
          iconProps: { style: { color: "#4caf50" } },
          onClick: (event, rowData) =>
            displayModalConfirmation(
              "Block IP",
              "Dear user, would you like to unblock this IP access?",
              function () {
                unblockAccess(rowData._id).then(([status, resp]) => {
                  if (status) {
                    toast.success("IP Access was successfully unblocked");
                    updateData(i, { ...rowData, isValid: true });
                  }
                });
              }
            ),
        }
      );
    },
  ];

  if (!userId) {
    return <Navigate to="/main" />;
  }

  return (
    <TemplateWrapped title="Login History">
      <CustomTable
        actions={actions}
        pageSize={25}
        columns={columns}
        data={data}
        isLoading={isLoading}
      />
    </TemplateWrapped>
  );
}
