import {Formik, Form, Field} from "formik";
import {FormGroup, FormLabel} from "react-bootstrap";
import {getDateAsString} from "../../../../../util/date";
import React from "react";
import useFilterForm from "./hooks/useFilterForm";

const FiltersForm = ({handleSubmit}) => {
  const {filter, handleSetFilter, handleReset} = useFilterForm(handleSubmit)

  return(
    <Formik
      initialValues={filter}
      onSubmit={values => handleSetFilter(values)}
      enableReinitialize={true}
    >
      <Form>
        <div className="p-3">
          <FormGroup>
            <FormLabel>Client</FormLabel>
            <Field className='form-control' name='user' />
          </FormGroup>
          <FormGroup>
            <FormLabel>Product</FormLabel>
            <Field className='form-control' name='product' />
          </FormGroup>
          <FormGroup>
            <FormLabel>Practice</FormLabel>
            <Field className='form-control' name='practice' />
          </FormGroup>
          <button
            style={{ minWidth: "auto", display: "block", width: "100%" }}
            type="submit"
            className="btn btn-primary btn-arrow mt-3 "
            onClick={() => {
/*              save((f) => ({
                ...f,
                startDate: getDateAsString(f.startDate),
                endDate: getDateAsString(f.endDate),
              }));*/
            }}
          >
            Apply filters
          </button>
          <button
            style={{ minWidth: "auto", display: "block", width: "100%" }}
            type="button"
            className="btn btn-danger  mt-2"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </Form>
    </Formik>
  )
}

export default FiltersForm