import { useEffect, useState } from "react";
import {
  getUrlParams,
  removeUrlParams,
  setURLParams,
} from "../../../../util/browser";
import { getDateAsString } from "../../../../util/date";
import { withoutEmptyKeys } from "../../../../util/object";

const initialState = {
  ivType: "",
  botStatus: "",
  startDate: "",
  endDate: "",
  patientName: "",
};

/* 

taken=0 esta encolado el IV
taken = 1 Completed
taken = 2 Processing

"startDate": "2021-12-01",
"endDate": "2021-12-30",
"botStatus": "pending",
"ivType": "FBD",
*/

const urlKeys = Object.keys(initialState);

export default function useIvFilterNew() {
  const [filter, _setFilter] = useState();

  useEffect(() => {
    setFilter({
      ...initialState,
      ...getUrlParams(urlKeys),
    });
  }, []);

  function setFilter(data) {
    const f = withoutEmptyKeys(data);
    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }

    let filters = {
      ...f,
      startDate: getDateAsString(f.startDate, "MM/DD/yyyy", "yyyy/MM/DD"),
      endDate: getDateAsString(f.endDate, "MM/DD/yyyy", "yyyy/MM/DD"),
    };

    _setFilter(withoutEmptyKeys(filters));
  }

  return [filter, setFilter];
}
