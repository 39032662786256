export const createCategory = (inputsForm, generalFields, templateId) => {
  let params = {};
  let data = {};
  let categoryData = {};
  for (const input of inputsForm) {
    if (input.props.value === "") {
      throw "Empty fields";
    }
    Object.assign(categoryData, { [input.props.id]: input.props.value });
    if (input.props.id == "categoryName") {
      Object.assign(data, { label: input.props.value });
    }
  }
  Object.assign(params, { categories: categoryData });
  Object.assign(params, generalFields);
  Object.assign(params, { templateId: templateId });
  return params;
};

export const createSubcategory = (inputsForm, sectionId, templateId) => {
  let params = {};
  for (const input of inputsForm) {
    if (input.props.value === "") {
      throw "Empty fields";
    }
    Object.assign(params, { [input.props.id]: input.props.value });
  }
  return { templateId: templateId, sectionId: sectionId, subsection: params };
};

export const createBookmark = (inputsForm, templateId, id, type) => {
  let params = {};

  for (const input of inputsForm) {
    if (input.props.value === "" && !input.props.items) {
      throw "Empty fields";
    }
    Object.assign(params, { [input.props.id]: input.props.value });

    if (input.props.items) {
      params["options"] = [];
      params["type"] = "object";
      Object.keys(input.props.values).forEach((k) => {
        params["options"].push({
          bookmark: input.props.values[k].bookmark,
          label: input.props.values[k].label,
          type: "boolean",
        });
      });
    }
  }
  return { templateId: templateId, id: id, type: type, bookmark: params };
};
export const bookmarkAll = (
  btns,
  templateId,
  categoryId,
  sectionType,
  id,
  type
) => {
  return {
    templateId: templateId,
    categoryId: categoryId,
    id: id,
    type: type,
    bookmarks: btns,
    sectionType: sectionType,
  };
};
