import {groupByParentId} from "./util/arrays";
import {general, modules} from "./constants/menu";

export const transformToMenu = (data) => {
  return [
    {
      active: true,
      label: "MODULES",
      items: [...data, ...modules],
    },
    {...general}
  ];
};

export const transformToMenuItem = (data) => {
  const menuStructure = data.map((item) => {
    let actions = data.filter(
      (m) => m.parentId === item._id && m.type === "Action"
    );
    return {
      _id: item._id,
      label: item.displayName,
      permission: item.name,
      icon: item.icon,
      path: item.url,
      parentId: item.parentId,
      type: item.type,
      action: actions,
      showInMenu: item.showInMenu,
    };
  });
  return groupByParentId(
    menuStructure,
    undefined,
    undefined,
    null,
    true,
    "Action"
  );
};

export const transformPermissions = (menu, permissions) => {
  let permissionsOldStructure = [];
  menu.forEach((item) => {
    if (permissions.permission.includes(item._id)) {
      let actions = menu.filter(
        (m) => m.parentId === item._id && m.type === "Action" && permissions.permission.includes(m._id)
      );
      permissionsOldStructure.push({
        _id: item._id,
        displayName: item.displayName,
        name: item.name,
        type: item.type,
        url: "",
        actions: actions,
        showInMenu: item.showInMenu
      });
    }
  });
  return {
    _id: permissions.userId,
    permission: permissionsOldStructure,
    defaultPath: permissions.defaultPath,
    practices: permissions.practice,
  };
};
