import {statusAndResponse} from "../../../util/requestHandler";
import {Api} from "../../../config/Api";
import moment from "moment";
import {withoutEmptyKeys} from "../../../util/object";

export function getIntelepeerRecords(filters, limit = 100){
  const {offset, ...restFilters} = filters;
  const processedFilters = withoutEmptyKeys(restFilters);
  const parsedFilters = {
    ...processedFilters,
    ...(processedFilters.appointmentDateStart && {startDate: moment(processedFilters.appointmentDateStart).format('MM/DD/YYYY')}),
    ...(processedFilters.appointmentDateEnd && {endDate: moment(processedFilters.appointmentDateEnd).format('MM/DD/YYYY')}),
    ...(processedFilters.dob && {dob: moment(processedFilters.dob).format('MM/DD/YYYY')}),
  };

  const {appointmentDateStart, appointmentDateEnd, ...necessaryFilters} = parsedFilters;
  const request = {
    page: parseInt(offset) + 1,
    limit,
    ...necessaryFilters
  }

  return statusAndResponse(
    Api.post('/api/intelepeer', request),
    onSuccess
  )
}

export function changeStatus(id, status) {
  return statusAndResponse(
    Api.patch(`/api/intelepeer/status/${id}`, { status }),
    r => r
  )
}

function processRecords(intelepeerRecords = []) {
  return intelepeerRecords.map(record => {
    return {
      id: record._id,
      customerPhone: record.customerPhone,
      patientId: record.patientId,
      patientName: `${record.patientFirstName} ${record.patientLastName}`,
      patientDOB: moment(record.Dob).utc().format("YYYY-MM-DD"),
      doctorsName: record.doctorsName,
      insuranceCarrier: record.insuranceCarrier,
      policyNumber: record.policyNumber,
      subscriberName: `${record.subscriberFirstName} ${record.subscriberLastName}`,
      subscriberDob: record.subscriberDob ? moment(record.subscriberDob).format("YYYY-MM-DD") : '',
      dateTimeAppointment: moment(record.dateTimeAppointment, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format("YYYY-MM-DD"),
      serviceRequested: record.serviceRequested,
      dropOutLocation: record.dropOutLocation,
      status: record.status,
      createdAt: moment(record.createdAt).format("YYYY-MM-DD"),
    }
  });
}

function onSuccess(res) {
  const { data } = res;
  const { pagination, scheduleAppointment } = data;

  return {
    records: processRecords(scheduleAppointment),
    recordsQty: pagination.totalPatients
  }
}
