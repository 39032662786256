import React from "react";
import useCredentialManager from "./hooks/useCredentialManager";
import CustomModal from "../../CustomModal";
import NewCredential from "../NewCredential";
import CredentialList from "./components/CredentialList";
import {Col, Row} from "react-bootstrap";

const CredentialManager = () => {
  const {
    showForm,
    credentials,
    clients,
    handleAddCredential,
    handleSaveCredentials,
    setShowForm,
    setClient,
    deleteCredential,
    addCredential,
    practices,
    setPractice,
    handleEdit,
    client,
    practice
  } = useCredentialManager()

  return (
    <>
      <div className='mb-5'>
        <Row className='mb-3'>
          <Col md={4} >
            <label
              className='text-bold mr-3 form-label'
              htmlFor="client"
            >
              Client:
            </label>
            <select
              className='form-control'
              name="client" id="client"
              onChange={({target}) => {
                setClient(target.value)
              }}>
              <option value="">Select Client</option>
              {clients.map(c => {
                return (<option key={c._id} value={c._id}>{c.name}</option>)
              })}
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className='text-bold mr-3 form-label' htmlFor="practices">Practice:</label>
            <select
              className='form-control'
              name="practices"
              id="practices"
              onChange={({target}) => {
                setPractice(target.value)
              }}
            >
              <option value="">Select practice</option>
              {practices.map(p => {
                return (<option key={p._id} value={p._id}>{p.name}</option>)
              })}
            </select>
          </Col>
        </Row>
      </div>
      {(client && practice) ?  (
        <>
          <CredentialList
            credentials={credentials}
            addCredential={addCredential}
            handleEdit={handleEdit}
            deleteCredential={deleteCredential}
          />
         <Row className='mt-5'>
           <Col className='text-center'>
             <button
               className='btn btn-primary'
               onClick={handleSaveCredentials}
             >
               Save
             </button>
           </Col>
         </Row>
        </>
      ) : <h5 className='text-center mt-5'>Dear user, please select a client and a practice</h5>}

      {showForm && (
        <CustomModal
          show={showForm}
          onHide={() => setShowForm(!showForm)}
          title='Add New Credential'
        >
          <NewCredential
            onSubmit={handleAddCredential}
          />
        </CustomModal>
      )}
    </>
  )
}

export default CredentialManager