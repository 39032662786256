import {Api, decryptApiResponse} from "../../../config/Api";
import {statusAndResponse} from "../../../util/requestHandler";
import {getDateAsString} from "../../../util/date";

export function getAuditTraitList(params) {
  if (params.startDate) {
    params.startDate = getDateAsString(params.startDate, 'MM/DD/yyyy', 'yyyy-MM-DD')
  }

  if (params.endDate) {
    params.endDate = getDateAsString(params.endDate, 'MM/DD/yyyy', 'yyyy-MM-DD')
  }
  return statusAndResponse(
    Api.get(`/api/bot/status`, {params}),
    decryptApiResponse
  )
}