import React from "react"
import {useField} from "formik"
import MaskedInput from "react-text-mask"

const CustomMaskedInput = (props) => {
  const [field, meta] = useField(props)
  return(
    <div className='input-container mb-4'>
      <MaskedInput
        className='form-control'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <small className="text-danger text-left input-error">
          {meta.error}
        </small>
      ):null}
    </div>
  )
}

export default CustomMaskedInput