import { Switch } from "@material-ui/core";
import React from "react";
import { CustomSwitchContainer } from "./CustomSwitch.styles";

const CustomSwitch = ({ label, checked, disabled, onChange }) => {
  return (
    <CustomSwitchContainer
      control={
        <Switch
          style={{ color: checked ? "#0080c0" : "#dedede" }}
          checked={checked}
          size="small"
          color="primary"
          onClick={onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export default CustomSwitch;
