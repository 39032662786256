/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import * as auth from "../../../util/auth";
import Logo, { LogoSmall } from "../../Logo";

const UserMenuToogle = React.forwardRef(({ children, onClick }, ref) => {
  const { firstName = "", lastName = "", name } = auth.getLoggedInData();
  return (
    <a
      href=""
      ref={ref}
      className="nav-link navbar-avatar text-primary"
      data-toggle="dropdown"
      aria-expanded="false"
      data-animation="scale-up"
      role="button"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <span
        className="d-inline-flex mr-2 justify-content-center"
        style={{ lineHeight: "165%" }}
      >
        <Icon className="pb-1 mr-1">person_outline</Icon>
        {firstName ? firstName + " " + lastName : name}
      </span>
    </a>
  );
});

const TemplateHeader = ({
  showAsideMenu,
  showDropDownUserMenu,
  showDotsMenu,
  setShowAsideMenu,
  showFullSizeLogo,
  setShowDropDownUserMenu,
  setShowDotsMenu,
  ...props
}) => {
  const appContext = useContext(AppContext);
  const practiceName = appContext.get("practiceName");

  return (
    <nav
      className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega"
      role="navigation"
    >
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
          data-toggle="menubar"
          onClick={setShowAsideMenu}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="hamburger-bar"></span>
        </button>
        <button
          type="button"
          className="navbar-toggler collapsed"
          data-target="#site-navbar-collapse"
          data-toggle="collapse"
          onClick={setShowDotsMenu}
        >
          <i className="icon wb-more-horizontal" aria-hidden="true"></i>
        </button>
        <div className="text-center" data-toggle="gridmenu">
          <img
            alt="Logo"
            className="navbar-brand-logo"
            src={showFullSizeLogo ? "../logo2.png" : "../logo.png"}
            title="Dental Robot logo"
          />
        </div>
      </div>

      <div className="navbar-container container-fluid">
        <div
          className={
            showDotsMenu
              ? "collapse navbar-collapse navbar-collapse-toolbar show"
              : "collapse navbar-collapse navbar-collapse-toolbar"
          }
          id="site-navbar-collapse"
        >
          <ul className="nav navbar-toolbar">
            <li className="nav-item hidden-float" id="toggleMenubar">
              <a
                href="#"
                className="nav-link"
                data-toggle="menubar"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowAsideMenu();
                }}
                role="button"
              >
                <i className={"icon hamburger unfolded"}>
                  <span className="sr-only">Toggle menubar</span>
                  <span className="hamburger-bar"></span>
                </i>
              </a>
            </li>
          </ul>

          <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
            <li className="nav-link">
              <Link
                to="/logout"
                className="text-secondary"
                style={{ lineHeight: "165%" }}
                onClick={() => {
                  auth.logout();
                }}
              >
                <i className="icon wb-power" aria-hidden="true"></i> Logout
              </Link>
            </li>
            <li className="mr-1 mt-1">
              <Dropdown>
                <Dropdown.Toggle
                  as={UserMenuToogle}
                  id="dropdown-custom-components"
                ></Dropdown.Toggle>
                <Dropdown.Menu align="right" className="w-50">
                  <Dropdown.Item as={Link} to="/profile">
                    <i className="icon wb-user" aria-hidden="true"></i> Profile
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/login-history">
                    <i className="icon wb-book" aria-hidden="true"></i> Login
                    History
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default TemplateHeader;
