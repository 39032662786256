import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../CustomDatePicker";
import { useAllTransactionsFilter } from "./hooks";
import { getDateAsString } from "../../../../../util/date";
import { useSearchParams } from "react-router-dom";
import Select from "../../../../CustomForm/components/Select";
import { Chip } from "@material-ui/core";

const isNotRequired = ["em", "nem"];
export default function AllTransactionsFilter({
  initialData,
  setFilters,
  operations,
  module_key,
}) {
  const processing = false;
  const hasMissingField = initialData.filter?.missingFields?.operation !== "";
  const [, setSearchParams] = useSearchParams();
  const [disabledInputs, setDisabledInputs] = useState(
    hasMissingField ? ["ivProcessResult", "statusPatient"] : [],
  );

  const {
    data,
    catalogs,
    setData,
    save,
    clear,
    types,
    handleDeleteType,
    handleKeyPress,
    typeInput,
    setTypeInput,
  } = useAllTransactionsFilter(setFilters, initialData);

  const modulesForInputFilters = ["AT"];
  const inputFiltersForModule = [
    {
      filter: "ivProcessResult",
      label: "Verification By",
      showInputValue: true,
      size: 6,
      operations,
    },
    {
      filter: "missingFields",
      label: "Missing Fields",
      showInputValue: false,
      size: 12,
      operations: [
        {
          name: "Missing Fields",
          value: "c",
        },
        {
          name: "No Missing Fields",
          value: "nc",
        },
      ],
    },
    {
      filter: "audited",
      label: "Audited Records",
      showInputValue: false,
      size: 12,
      operations: [
        {
          name: "Audited",
          value: "c",
        },
        {
          name: "Unaudited",
          value: "nc",
        },
      ],
    },
  ];

  const inputFilters = [
    {
      filter: "patientName",
      label: "Patient Name",
      showInputValue: true,
      size: 6,
      operations,
    },
    {
      filter: "carrier",
      label: "Carrier Name",
      showInputValue: true,
      size: 6,
      operations,
    },
    {
      filter: "statusPatient",
      label: "Eligibility Status",
      showInputValue: true,
      size: 6,
      operations,
    },
    {
      filter: "provider",
      label: "Provider",
      showInputValue: true,
      size: 6,
      operations,
    },
  ];

  const clearInput = (input) => {
    setData("value", "", input);
    setData("operation", "", input);
  };

  const { filter } = data;

  const resetCurrentPage = () => {
    setSearchParams({
      page: 0,
    });
  };

  return (
    <Form
      className="w-90"
      onSubmit={(e) => {
        e.preventDefault();
        resetCurrentPage();
        save();
      }}
    >
      <Row className="m-0">
        <Col md={6} className="p-0">
          <Form.Group className="m-2">
            <Form.Label>Record Type</Form.Label>
            <Form.Control
              id="type"
              onKeyDown={handleKeyPress}
              placeholder="write a record type"
              value={typeInput}
              onChange={({ target: { value } }) => {
                setTypeInput(value);
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="p-0 d-flex align-items-center">
          <div
            className="mt-4"
            style={{ width: "100%", display: "inline-flex", flexWrap: "wrap" }}
          >
            {types?.length > 0 &&
              types?.map((type, index) => (
                <Chip
                  sx={{ flex: { xs: 1, md: 0 } }}
                  key={index}
                  label={type}
                  variant="outlined"
                  onDelete={() => handleDeleteType(index)}
                />
              ))}
          </div>
        </Col>
      </Row>
      <Row className="m-0">
        <Col md={6} className="p-0">
          <Form.Group className="ml-2 mr-2">
            <Form.Label>Start Date</Form.Label>
            <CustomDatePicker
              value={
                typeof data.startDate === "string"
                  ? getDateAsString(new Date(data.startDate))
                  : data.startDate
              }
              name="startDate"
              onChange={(d) => {
                setData("startDate", d);
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="p-0">
          <Form.Group className="ml-2 mr-2">
            <Form.Label>End date</Form.Label>
            <CustomDatePicker
              value={
                typeof data.endDate === "string"
                  ? getDateAsString(new Date(data.endDate))
                  : data.endDate
              }
              name="endDate"
              onChange={(d) => {
                setData("endDate", d);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      {inputFilters
        .concat(
          modulesForInputFilters.includes(module_key)
            ? inputFiltersForModule
            : [],
        )
        .map((input) => {
          return (
            <Row className="m-0" key={input.filter}>
              <Col md={input.size} className="p-0">
                <Form.Group className="ml-2 mr-2">
                  <Select
                    disabled={disabledInputs.includes(input.filter)}
                    disableFirstOption={false}
                    options={input.operations}
                    optTextKey="name"
                    optValueKey="value"
                    label={input.label}
                    value={filter[input.filter]?.operation}
                    onChange={(value) => {
                      if (input.filter === "missingFields") {
                        if (value !== "") {
                          setDisabledInputs([
                            "ivProcessResult",
                            "statusPatient",
                          ]);
                          clearInput("ivProcessResult");
                          clearInput("statusPatient");
                        } else {
                          setDisabledInputs([]);
                        }
                        setData("operation", value, input.filter);
                      } else {
                        if (value === "" || isNotRequired.includes(value)) {
                          setData("value", "", input.filter);
                        }

                        setData("operation", value, input.filter);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              {input.showInputValue && (
                <Col md={input.size} className="p-0">
                  <Form.Group className="ml-2 mr-2">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      id={input.filter}
                      name={input.filter}
                      value={filter[input.filter]?.value}
                      onChange={({ target: { value } }) => {
                        setData("value", value, input.filter);
                      }}
                      placeholder={input.label}
                      required={
                        filter[input.filter]?.operation !== "" &&
                        !isNotRequired.includes(filter[input.filter]?.operation)
                      }
                      disabled={
                        isNotRequired.includes(
                          filter[input.filter]?.operation,
                        ) || disabledInputs.includes(input.filter)
                      }
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
          );
        })}

      <Row className="mt-2 mb-2">
        <Col md={{ offset: 2, span: 4 }}>
          <Button
            className="btn-block"
            variant="primary"
            type="button"
            onClick={() => {
              resetCurrentPage();
              save();
            }}
            disabled={processing}
          >
            Apply filters
          </Button>
        </Col>
        <Col md={4}>
          <Button
            className="btn-block"
            disabled={processing}
            variant="warning"
            type="button"
            onClick={() => {
              resetCurrentPage();
              clear();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
