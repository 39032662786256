import React from "react";
import { toast } from "react-toastify";
import { displayModalConfirmation } from "../../../util/displayMessage";
import CustomTable from "../../CustomTable";
import { TemplateWrapped } from "../../Layout";
import Connections from "../Connections";
import useTransactionPlan from "./useTransactionPlan";

const TransactionPlan = () => {
  const {
    dataSource,
    refreshData,
    tableReference,
    practiceId,
    audit,
    goToRecord,
  } = useTransactionPlan();

  const columns = [
    {
      title: "Employer",
      field: "employer",
    },
    {
      title: "Group N°",
      field: "groupNumber",
    },
    {
      title: "Deductible",
      field: "deductible",
    },
    {
      title: "Maximum",
      field: "maximum",
    },
    {
      title: "Plan N°",
      field: "plannum",
    },
    {
      title: "Bot Status",
      field: "botDBStatus",
    },
  ];

  const actions = [
    (rowData) => ({
      icon: "inbox",
      tooltip: "Go to record",
      iconProps: {
        color: "primary",
      },
      onClick: () => {
        goToRecord(rowData._id);
      },
    }),
    (rowData) => ({
      icon: "star",
      tooltip: "Audit",
      iconProps: {
        style: { color: rowData.audit ? "#fdc643" : "#dedede" },
      },
      onClick: () => {
        const nextState = !rowData.audit;
        const actionLabel = nextState ? "verified" : "not verified";

        displayModalConfirmation(
          "Verify",
          `Dear user, are you sure you want to set this record as ${actionLabel}`,
          async () => {
            const [status] = await audit(rowData._id, nextState);
            if (!status) {
              toast.error("Dear user, please try again");
              return;
            }
            refreshData();
            toast.success(`The transaction was set as ${actionLabel}`);
          }
        );
      },
    }),
  ];

  if (!practiceId) {
    return (
      <div className="mb-5 pb-5">
        <Connections title="Please select a connection" />
      </div>
    );
  }

  return (
    <TemplateWrapped title="Transaction Plan">
      <CustomTable
        asyncDataSource={dataSource}
        tableReference={tableReference}
        columns={columns}
        actions={actions}
        search={false}
      />
    </TemplateWrapped>
  );
};

export default TransactionPlan;
