export const transformColumnList = (columnList, userColumnConfig) => {
  return columnList.map(column => {
    const findColumn = userColumnConfig.find(userColumn => userColumn.columnGridId === column._id);
    const obj = {...column, active: false}
    if (findColumn) {
      obj.active = true
      obj.userColumn = findColumn._id
    }
    return obj
  })
}

export const createArrayOfColumnNames = (arr) => {
  return arr.reduce((acc, curr) => {
    acc = [...acc, curr.columnGridName]
    return acc
  },[])
}
