import styled from "styled-components";

export const ActionButtonIcon = styled.span`
  width: 5px;
  height: 5px;
  background-color: #77b4dacc;
  // margin: ${(props) => props.margin};
  margin-bottom: 0.2rem;
`;
export const ActionButtonStyle = styled.span`
  width: 27px;
  height: 25px;
  background-color: #cfecfc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.47rem;
  flex-wrap: wrap;
  cursor: pointer;
`;
