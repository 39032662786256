import { Fragment, useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Row, Col, Button } from "react-bootstrap";

const MySwal = withReactContent(Swal);

const ButtonList = (props) => {
  const [buttons, setButtons] = useState({});
  const [canClick,setCanClick] = useState(false);

  const handleClick = (e, id, index) => {
    if(!canClick)
    {      
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: props.msgFailCondition,
      });
      return;
    }
    let btns = { ...buttons };
    let btnCopy = {};
    Object.keys(btns).forEach((k)=>{
      if(btns[k].id===id)
      {
        btnCopy = {...btns[k]}
        if (btns[k].added) {
          if (props.removeItemsOnDelete) {        
            delete btns[k];
            btnCopy.added = false;
          } else {
            btns[k].added = false; 
            btnCopy =  btns[k];
          }
        } else {
          btns[k].added = true;
          btnCopy =  btns[k];
        }
      }    
    });
    
    setButtons(btns);
    props.onClick(btnCopy);
    
   
  };
  const handleClickAddAll = () => {
    if (buttons) {
      MySwal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please, select a category or subcategory to load bookmarks.",
      });
    }
    //let btnsSelected = { ...buttons };
  };

  useEffect(() => {
    setButtons(props.data);
    setCanClick(props.conditionOnclick);
  },[props.data,props.conditionOnclick]);

  return (
    <Fragment>
      
        
          {buttons &&
            Object.keys(buttons).map((key,index) => {
              if(props.showInactive || buttons[key].added)
              {
                return (
                <div
                  key={key}
                  className={
                    buttons[key].added
                      ? "itemBookmark  boxActive"
                      : "itemBookmark  boxNoActive"
                  }
                  onClick={(e) => handleClick(e, buttons[key].id, index)}
                >
                  <div className="name">{buttons[key].label}</div>
                  <div className="option">
                    {" "}
                    {!buttons[key].added ? (
                      <AddCircleIcon />
                    ) : (
                      <RemoveCircleIcon />
                    )}
                  </div>
                </div>
                );
                
              }
              else {
                return (<span></span>);
              }
              
            })}
        
      
      {props.selectAll && 
      <Row>
        <Col className="text-center">
          <Button
            variant="primary"
            className="mt-4"
            onClick={handleClickAddAll}
          >
            Add all items
          </Button>
        </Col>
      </Row>}
    </Fragment>
  );
};
export default ButtonList;
