const fakeData = [
  {
    _id: "622632aeaa7300001a001328",
    displayName: "Insurance Verification",
    code: "M1",
    parentId: null,
    showInMenu: true,
    restricted: true,
    groupName: "base",
  },
  {
    _id: "622632f7aa7300001a00132b",
    displayName: "Transactions",
    parentId: "622632aeaa7300001a001328",
    code: "M1-S1",
    showInMenu: true,
    restricted: true,
    groupName: "base",
  },
  {
    _id: "62263387aa7300001a00132e",
    displayName: "Transaction List",
    parentId: "622632f7aa7300001a00132b",
    code: "M1-S1-SS1",
    showInMenu: false,
    restricted: true,
    groupName: "base",
  },
  {
    _id: "622633f1aa7300001a001332",
    displayName: "Unaudit",
    parentId: "62263387aa7300001a00132e",
    code: "M1-S1-SS1-A1",
    showInMenu: false,
    restricted: false,
    groupName: "base",
  },
  {
    _id: "622632aeaa7300001a001329",
    displayName: "Data Quality",
    code: "M2",
    parentId: null,
    showInMenu: true,
    restricted: true,
    groupName: "base",
  },
  {
    _id: "622632aeaa7300001a001330",
    displayName: "Template",
    code: "M3",
    parentId: null,
    showInMenu: true,
    restricted: true,
    groupName: "base",
  },
];
export default fakeData;
