import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import useClientPracticeFilter from "../../ClientPractice/components/useClientPracticeFilter";
import useClientFilter from "./useClientFilter";
import React from "react";
import Select from "react-select";

function ClientFilter({setFilters, locateState}) {
  const {
    data,
    handleFilterSubmit,
    loadCity,
    city,
    handleReset,
    state,
  } = useClientFilter(setFilters);

  return (

    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={(values) => {
        handleFilterSubmit(values)
      }}
    >
      {
        props => (
          <form className="mt-2 p-1" onSubmit={props.handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                id="name"
                name="name"
                label="Name"
                value={props.values.name}
                onChange={props.handleChange}
              />
            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Label>State</Form.Label>
              <Select options={locateState}
                      onChange={async ({label, value}) => {
                        await loadCity(label)
                        props.setFieldValue('state', label)
                        console.log(props.values)
                      }}
                      id="state"
                      name="state"
                      defaultValue={locateState.filter(
                        (temp) => temp.label === props.values.state
                      )}
              />

            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Label>City</Form.Label>
              <Select options={city}
                      onChange={({label, value}) => {
                        props.setFieldValue('city', value)
                        console.log(props.values)
                      }}
                      id="city"
                      name="city"
                      defaultValue={city.filter(
                        (temp) => temp.value === props.values.city
                      )}
              />

            </Form.Group>
            <Button variant="primary" type="submit">
              Apply filters
            </Button>
            <Button
              //disabled={processing}
              className="ml-1"
              variant="warning"
              type="button"
              onClick={() => {
                handleReset(props.handleReset);
              }}
            >
              Reset
            </Button>
          </form>
        )
      }
    </Formik>
  )
}

export default ClientFilter