import React from "react";
import { Grid } from "@material-ui/core";
import { LabelInputs } from "./styles/LabelInputs.styles";
import { LabelWithMargin } from "./styles/LabelWithMargin.styles";
import { InputContainer } from "./styles/InputContainer.styles";
import { INITIAL_VALUE_ELEGIBLE } from "../../../../../../constants";

const BookmarksInputs = ({
  practiceInfoAndBookmarks,
  handleChangeVerification,
  formVerification,
  shouldBeReadonly,
  handleChangeBookmarks,
}) => {
  const VALUE_FOR_REQUIRED_INPUT = "E";
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "1.5rem", color: "#121212 !important" }}
      >
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <LabelInputs>{practiceInfoAndBookmarks[1]?.category}</LabelInputs>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <LabelInputs>Elegibles</LabelInputs>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <LabelInputs>Frecuency</LabelInputs>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <LabelInputs>History</LabelInputs>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "1rem" }}>
        {practiceInfoAndBookmarks &&
          practiceInfoAndBookmarks[1]?.items?.map((item) => (
            <React.Fragment key={item.code}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <LabelWithMargin>{item.description}</LabelWithMargin>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <InputContainer>
                  {" "}
                  <select
                    name={item.code}
                    className="form-control"
                    defaultValue="N"
                    value={
                      formVerification.eligible.includes(item.code) ? "E" : "N"
                    }
                    onChange={(e) => {
                      handleChangeVerification(e, 2, item.code);
                    }}
                    disabled={shouldBeReadonly()}
                  >
                    <option value="E">E</option>
                    <option value="N">N</option>
                  </select>
                </InputContainer>
              </Grid>
              {item?.bookmarks?.map((bookmarkItem) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  key={bookmarkItem?.bookmark}
                >
                  <InputContainer>
                    <input
                      type="text"
                      name={bookmarkItem?.bookmark}
                      className="form-control"
                      onChange={handleChangeBookmarks}
                      value={formVerification.bookmarks[bookmarkItem?.bookmark]}
                      readOnly={shouldBeReadonly()}
                      required={formVerification.eligible.includes(item.code)}
                    />
                  </InputContainer>
                </Grid>
              ))}
            </React.Fragment>
          ))}
      </Grid>

      <Grid container style={{ marginTop: "1rem" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <LabelWithMargin>
            {practiceInfoAndBookmarks[2]?.category}
          </LabelWithMargin>
        </Grid>
        {practiceInfoAndBookmarks &&
          practiceInfoAndBookmarks[2]?.items?.map((item) => (
            <React.Fragment key={item.code}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <LabelWithMargin>{item.description}</LabelWithMargin>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} defaultValue="N">
                <InputContainer>
                  {" "}
                  <select
                    name={item.code}
                    className="form-control"
                    onChange={(e) => {
                      handleChangeVerification(e, 2, item.code);
                    }}
                    value={
                      formVerification.eligible.includes(item.code) ? "E" : "N"
                    }
                    disabled={shouldBeReadonly()}
                  >
                    <option value="E">E</option>
                    <option value="N">N</option>
                  </select>
                </InputContainer>
              </Grid>
              {item?.bookmarks?.map((bookmarkItem) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  key={bookmarkItem?.bookmark}
                >
                  <InputContainer>
                    <input
                      type="text"
                      name={bookmarkItem?.bookmark}
                      className="form-control"
                      onChange={handleChangeBookmarks}
                      value={formVerification.bookmarks[bookmarkItem?.bookmark]}
                      readOnly={shouldBeReadonly()}
                      required={formVerification.eligible.includes(item.code)}
                    />
                  </InputContainer>
                </Grid>
              ))}
            </React.Fragment>
          ))}
      </Grid>
      <Grid container style={{ marginTop: "1rem" }}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <LabelWithMargin>Notes</LabelWithMargin>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <InputContainer>
            <textarea
              required
              rows="3"
              name="notes"
              onChange={handleChangeVerification}
              className="form-control"
              value={formVerification.notes}
              readOnly={shouldBeReadonly()}
              style={{ resize: "none" }}
            />
          </InputContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default BookmarksInputs;
