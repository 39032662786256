import React, { useState } from "react";
import CustomModal from "../../../CustomModal";
import DragAndDrop from "../../../DragAndDrop";
import UserHelp from "../../../util/UserHelp";

const ReorderModal = ({ title, data, onReorderItem, onClose }) => {
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <CustomModal
      show
      title={title}
      onHide={() => {
        onClose(hasChanges);
      }}
    >
      <div className="reorder-modal">
        <UserHelp className="mb-2">
          Dear user, you can change the order of any element by dragging and
          dropping it in the list. <br />
          When you close this window, the changes will be reflected in the app.
        </UserHelp>
        <DragAndDrop
          className="dnd"
          direction={"vertical"}
          data={data}
          render={({ id, name }) => <div className="item">{name}</div>}
          onReorderItem={(element, index) => {
            setHasChanges(true);
            onReorderItem(element, index);
          }}
        />
      </div>
    </CustomModal>
  );
};

export default ReorderModal;
