import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Icon, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import InboxIcon from "@material-ui/icons/Inbox";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyTransaction } from "../../services/missingCode";
import { getDateAsString } from "../../util/date";
import { displayModalConfirmation } from "../../util/displayMessage";
import CustomTable, { TextColumnHighlighted } from "../CustomTable";
import { PieAlt } from "../util/Pie";
import ClientSelected from "../pages/Connections/components/ClientSelected";

const lookupTable = [
  {
    text: "Done by DR",
    status: "success",
    message: "Insurance Verification Completed",
  },
  {
    text: "Done By DR",
    status: "success",
    message: "Insurance Verification Completed",
  },
  {
    text: "*Done by DR",
    status: "success",
    message: "Insurance Verification Completed",
  },
  {
    text: "*Done By DR",
    status: "success",
    message: "Insurance Verification Completed",
  },
  {
    text: "Check",
    status: "warning",
    message: "Patient Info not found in the Carrier",
  },
  {
    text: "Unchecked",
    status: "danger",
    message: "Issue in the Carrier Website",
  },
  {
    text: "Done by CC",
    status: "success",
    message: "",
  },
  {
    text: "NO ACTION TAKEN",
    status: "success",
    message: "",
  },
];

export function statusLookup(search) {
  const res = lookupTable.filter(
    (x) => x.text.toString() === search.toString(),
  );
  return res.length > 0 ? res[0] : { status: "", message: "", text: "" };
}

function NoMissingFields() {
  const badgeProps = {
    backgroundColor: "dodgerblue",
    color: "white",
    fontSize: "12px",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    textAlign: "center",
    margin: "0",
    lineHeight: "22px",
  };

  return (
    <div style={{ margin: 0, padding: 0, border: 0, position: "relative" }}>
      <span style={badgeProps} className="badge d-inline-block mr-1">
        <FontAwesomeIcon icon="check" />
      </span>
    </div>
  );
}

const TransactionsTable = ({
  audit,
  fbd,
  tableReference,
  pageSize = 10,
  loading,
  loadEditData,
  asyncDataSource,
}) => {
  const history = useNavigate();

  const columns = [
    {
      title: "Appointment",
      field: "Created",
      // sorting: false,
      defaultSort: "desc",
      render: ({ Created }) => {
        return getDateAsString(Created, "yyyy-MM-DD");
      },
    },

    {
      title: "Patient Name",
      sorting: false,
      field: "PatientName",
    },
    {
      title: "Practice Name",
      sorting: false,
      field: "PracticeName",
    },
    {
      title: "Carrier",
      field: "CarrierName",
      sorting: false,
    },
    {
      title: "Missing Fields",
      align: "center",
      sorting: false,
      render: (rowData) => {
        const { missingRequiredInputQty, totalRequiredFields } = rowData;

        const completedPercentage =
          100 - (missingRequiredInputQty * 100) / totalRequiredFields;

        const missingCodes = missingRequiredInputQty > 0;

        return (
          <OverlayTrigger
            delay={{ show: 250, hide: 100 }}
            overlay={
              <Tooltip>
                {missingCodes
                  ? `${missingRequiredInputQty} empty fields of ${totalRequiredFields}`
                  : "There are no missing fields for this record"}
              </Tooltip>
            }
          >
            <div style={{ position: "relative" }}>
              <PieAlt
                value={completedPercentage}
                size={50}
                colors={[
                  "gainsboro",
                  typeof totalRequiredFields !== "undefined"
                    ? "dodgerblue"
                    : "gainsboro",
                ]}
              />
              <span
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  top: missingCodes ? "36%" : "30%",
                  fontSize: missingCodes ? "10px" : "18px",
                }}
              >
                {missingCodes ? (
                  `${missingRequiredInputQty}${
                    typeof totalRequiredFields !== "undefined"
                      ? "/" + totalRequiredFields
                      : ""
                  }`
                ) : (
                  <FontAwesomeIcon
                    fontSize="25px"
                    style={{ color: "dodgerblue" }}
                    icon="check"
                  />
                )}
              </span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      title: "Verification By",
      sorting: false,
      align: "center",
      render: ({ VerificationType }) => {
        const { message, status } = statusLookup(VerificationType);

        const Content = (
          <div>
            <TextColumnHighlighted text={VerificationType} status={status} />
          </div>
        );

        if (message) {
          return (
            <OverlayTrigger
              delay={{ show: 250, hide: 100 }}
              overlay={<Tooltip>{message}</Tooltip>}
            >
              {Content}
            </OverlayTrigger>
          );
        }

        return Content;
      },
    },
    {
      title: "Eligibility Status",
      sorting: false,
      render: ({ StatusPatient, ..._rest }) => {
        let status = "danger";

        if (StatusPatient.toUpperCase().indexOf("INACTIVE") > -1) {
          status = "warning";
        } else if (StatusPatient.toUpperCase().indexOf("ACTIVE") > -1) {
          status = "success";
        }

        return (
          <TextColumnHighlighted text={StatusPatient} status={status} asDot />
        );
      },
    },

    CustomTable.getSettingsColumn(
      (rowData) => {
        const noContent = noActionAviable(rowData);
        if (noContent) return null;
        return (
          <>
            <div className="w-100">
              <Button
                color="primary"
                className="justify-content-start w-100"
                startIcon={<Search />}
                onClick={() => {
                  loadEditData(rowData._id, rowData.practiceId);
                }}
              >
                Patient Info
              </Button>
            </div>
            {fbd && (
              <div className="mt-2">
                <Button
                  color="primary"
                  className="justify-content-start w-100"
                  startIcon={<InboxIcon />}
                  onClick={() => {
                    goToRecord(rowData._id);
                  }}
                >
                  FBD
                </Button>
              </div>
            )}
          </>
        );
      },
      (rowData) => {
        if (!audit) return null;
        return (
          <IconButton
            render="span"
            onClick={() => {
              const nextState = !rowData.audited;
              const actionLabel = nextState ? "verified" : "not verified";

              displayModalConfirmation(
                "Verify",
                `Dear user, are you sure you want to set this record as ${actionLabel}`,
                () => {
                  if (tableReference.current != null) {
                    verifyTransaction(
                      rowData,
                      rowData.practiceId,
                      nextState,
                    ).then(([status, _r]) => {
                      if (!status) {
                        toast.error("Dear user, please try again");
                        return;
                      }
                      tableReference.current.onQueryChange();
                      toast.success(
                        `The transaction was set as ${actionLabel}`,
                      );
                    });
                  }
                },
              );
            }}
          >
            <Icon style={{ color: rowData.audited ? "#fdc643" : "#dedede" }}>
              star
            </Icon>
          </IconButton>
        );
      },
    ),
  ];

  function noActionAviable(rowData) {
    return (
      rowData.StatusPatient === "No content loaded" &&
      rowData.VerificationType === "Unchecked"
    );
  }

  function goToRecord(id) {
    history("/missingCode", { state: { id: id, overridePermissions: true } });
  }

  return (
    <div className="missing-table">
      <CustomTable
        title={<ClientSelected />}
        columns={columns}
        search={false}
        // data={data}
        tableReference={tableReference}
        asyncDataSource={asyncDataSource}
        isLoading={loading}
        pageSize={pageSize}
        components={{
          ...CustomTable.Components,
          Pagination: (props) => (
            <CustomTable.Components.Pagination
              options={[10, 25, 50]}
              {...props}
            />
          ),
        }}
      />
    </div>
  );
};

export default TransactionsTable;
