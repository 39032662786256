import { Api, decryptApiResponse } from "../../../../config/Api";
import { statusAndResponse } from "../../../../util/requestHandler";

export const getMissingFields = (practiceIds) => {
  return statusAndResponse(
    Api.get("/api/dqa/MissingFields", { params: { practiceIds } }),
    (resp) => {
      return decryptApiResponse(resp).then((dataQA) => {
        console.log("rrr", dataQA);
        if (dataQA) {
          const data = Object.entries(dataQA);
          return data.map(([name, value]) => {
            return { name, value };
          });
        }
      });
    }
  );
};
export const getDataQuality_Grid = (limit, filters) => {
  return statusAndResponse(
    Api.post("/api/dqa/forms/get", {
      limit,
      ...filters,
    }),
    (resp) => {
      return decryptApiResponse(resp).then(
        ({ records: oldRecords, recordsQty }) => {
          const records = oldRecords.map((item) => {
            let info = item.dqaRequest[0],
              dc = new Date(item.dateCreated),
              datec = `${
                dc.getMonth() + 1
              }/${dc.getDate()}/${dc.getFullYear()}`,
              du = new Date(item.dateUpdate),
              dateu = `${
                du.getMonth() + 1
              }/${du.getDate()}/${du.getFullYear()}`,
              label = item.status === 1 ? "Active" : "No Active";

            return {
              patient_name: `${info.firstName} ${info.lastName}`,
              phone: info.phone,
              date: info.dob,
              status: label,
              created: datec,
              updated: dateu,
              id: info._id,
            };
          });

          return { records, recordsQty };
        }
      );
    }
  );
};

export const getSingleData = (id) => {
  return statusAndResponse(
    Api.get("/api/dqa/forms/get/details/" + id),
    (resp) => {
      let data = [],
        info = [];

      return decryptApiResponse(resp).then((dataQA) => {
        info = dataQA;
        let QA = info.dqaRequestLast,
          comment = QA.comment ? QA.comment : "",
          trustedInfo = QA.trustedInfo;
        data = {
          record: [
            [
              {
                label: "Last name",
                pms: info.lastName,
                form: QA.lastName,
              },
            ],
            [
              {
                label: "First name",
                pms: info.firstName,
                form: QA.firstName,
              },
            ],
            [{ label: "Date of birth", pms: info.dob, form: QA.dob }],
            [{ label: "Address", pms: info.address, form: QA.address }],
            [{ label: "zip Code", pms: info.zipCode, form: QA.zipCode }],
            [{ label: "Phone", pms: info.phone, form: QA.phone }],
            [{ label: "E-mail", pms: info.email, form: QA.email }],
          ],
          trustedInfo: trustedInfo,
          comment: comment,
          status: QA.status,
        };
        return { data };
      });
    }
  );
};
export const updateSigleData = (id) => {
  return statusAndResponse(
    Api.post("/api/dqa/confirmUpdate", { requestsId: id }),
    (resp) => {
      return resp.data.success;
    }
  );
};
