import {useContext, useState} from "react";
import { AppContext } from "../../../../../../App";

const years = [
  {year: 2021, selected: false},
  {year: 2022, selected: false},
  {year: 2023, selected: false},
  {year: 2024, selected: false},
]

const usePracticesYear = () => {
  const appData = useContext(AppContext);
  const practices = appData.get("practices");

  const [selectedPractice, setSelectedPractice] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const formatedPractices = practices.map(practice=>{
    return {
      label: practice.name,
      id: practice._id
    }
  })

  return {
    formatedPractices,
    years,
    selectedYear,
    setSelectedYear,
    selectedPractice,
    setSelectedPractice
  }

}

export default usePracticesYear
