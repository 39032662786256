import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import * as services from "../../../../services/missingCode";

const initialAviableNotes = {
  hasAviableNotes: false,
  aviableNotes: {
    elgCodes: false,
    note: false,
    history: false,
  },
};

const useNotes = (selectedPractice, id) => {
  const [aviableNotes, setAviableNotes] = useState(initialAviableNotes);

  useEffect(() => {
    if (selectedPractice && selectedPractice.spreadsheetFields) {
      const fields = selectedPractice.spreadsheetFields;
      const hasAviableNotes = Object.values(fields).filter((x) => x).length > 0;

      setAviableNotes({
        hasAviableNotes,
        aviableNotes: {
          ...initialAviableNotes.aviableNotes,
          ...fields,
        },
      });
    }
  }, [selectedPractice]);

  const updateNotes = async (
    { notes, elgCodes, history },
    { previousHistory, previousElgCodes }
  ) => {
    const cleanNotes = notes.replace(/\n/gi, "-");

    const data =
      (elgCodes === history) === "" && !previousHistory && !previousElgCodes
        ? {
          note: cleanNotes,
        }
        : {
          note: cleanNotes,
          elgCodes,
          history,
        };

    const [status] = await services.updateTransactionNotes(id, data);

    if (status) {
      toast.success("Note saved successfully");
    }
  };

  return {
    aviableNotes,
    updateNotes
  }
}

export default useNotes