import {useContext} from "react";
import {TemplateWrapped} from "../../../../Layout";
import CustomPanel from "../../../../CustomPanel";
import Category from "../Category";
import ShareBookmarksContextProvider from "../context/ShareBookmarksContextProvider";
import ShareBookmarksContext from "../context/ShareBookmarksContext";

const ShareBookmarks = () => {
  return(
    <ShareBookmarksContextProvider>
      <ShareBookmarksContent />
    </ShareBookmarksContextProvider>
  )
}

const ShareBookmarksContent = () => {
  const {template, handleSaveSharedBookmarks} = useContext(ShareBookmarksContext)

  const {
    name = '',
    content = []
  } = template

  return (
    <TemplateWrapped
      title={name}
    >
      <CustomPanel>
        <div className='d-flex justify-content-between'>
          <h4 className='mb-4'>Categories</h4>
          <button
            className='btn btn-primary'
            onClick={handleSaveSharedBookmarks}
          >
            Share Elements
          </button>
        </div>
        <Category categories={content} />
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default ShareBookmarks