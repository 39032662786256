import Chartist from "chartist";
import "chartist-plugin-fill-donut";
import "chartist-plugin-tooltips-updated";
import "chartist-plugin-legend";

export const linearOptions = {
  // high: 300,
  height: "300px",
  chartPadding: {
    right: 50,
  },
  showArea: true,
  showLine: true,
  fullWidth: true,
  axisX: {
    offset: 40,
  },
  axisY: {
    offset: 30,
    // labelInterpolationFnc: function labelInterpolationFnc(value) {
    //   if (value === 0) {
    //     return null;
    //   }

    //   return value / 1000 + "k";
    // },
    // scaleMinSpace: 40,
  },
  plugins: [
    Chartist.plugins.tooltip(),
    Chartist.plugins.legend({
      legendNames: ["ELG", "FBD"],
    }),
  ],
};

export const getDonutOptions = (number, filter) => {
  return {
    donut: true,
    donutWidth: 30,
    donutSolid: true,
    // startAngle: 270,
    showLabel: false,
    chartPadding: 30,
    labelOffset: 30,
    width: "300px",
    height: "300px",
    labelDirection: "explode",
    plugins: [
      Chartist.plugins.fillDonut({
        items: [
          {
            content: filter,
            offsetY: 50,
          },
          {
            content: `<h3>${number}</h3>`,
          },
        ],
      }),
      // Chartist.plugins.legend(),
    ],
  };
};

export const barOptions = {
  // low: 0,
  // high: 10,
  seriesBarDistance: 6,
  fullWidth: true,
  axisX: {
    showLabel: false,
    showGrid: false,
    offset: 0,
  },
  axisY: {
    showLabel: false,
    showGrid: false,
    offset: 0,
  },
  chartPadding: {
    //   top: 20,
    //   right: 115,
    //   bottom: 55,
    left: 30,
  },
};
