import {Icon, IconButton, Tooltip} from "@material-ui/core";
import React from "react";

const ActionButton = ({ tooltip, color, iconName, onClick }) => (
  <Tooltip title={tooltip}>
    <IconButton onClick={onClick}>
      <Icon color={color}>{iconName}</Icon>
    </IconButton>
  </Tooltip>
);

export default ActionButton