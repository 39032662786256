import { faFilter } from "@fortawesome/free-solid-svg-icons";
import React, { createRef, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../../../App";
import TableDataQuality from "../../../components/helper/tableDataQuality";
import { TemplateWrapped } from "../../../components/Layout/index";
import { HeaderButton } from "../../RemarkTemplate/Body";
import {
  displayModalConfirmation,
  displayModalMessage,
} from "../../../util/displayMessage";
import { withoutEmptyKeys } from "../../../util/object";
import CustomModal from "../../CustomModal";
import CustomTable from "../../CustomTable";
import DetailRecordsDataQuality from "../../helper/DetailRecordsDataQuality";
import { Presentational as Connections } from "../Connections";
import DataQualityFilter from "./components/DataQualityFilter";
import { initialState } from "./hooks/useDataQualityFilter";
import * as service from "./services";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const DataQuality = () => {
  const [missingField, setMissingField] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filters, setFilters] = useState(initialState);
  const [modalData, setModalData] = useState(false);

  const ref = createRef(null);

  const appContext = useContext(AppContext);
  const practiceIds = appContext.get("selectedPractices");

  useEffect(() => {
    if (!practiceIds) {
      return;
    }
     service.getMissingFields(practiceIds).then(([_status, resp]) => {
      setMissingField(resp);
    });
  }, [practiceIds]);

  const getRecords = ({ page, pageSize, _search, orderDirection: sort }) => {
    function response(records = [], quantity = 0) {
      return CustomTable.createTableResponse(records, page, quantity);
    }

    if (practiceIds.length === 0)
      return new Promise((resolve, _reject) => {
        resolve(response());
      });

    return service
      .getDataQuality_Grid(pageSize, {
        ...withoutEmptyKeys(filters),
        practiceIds,
        sort,
        offset: page ? pageSize * page : 0,
      })
      .then(([status, r]) => {
        if (!status) {
          return response([], 0);
        }
        return response(r.records || [], r.recordsQty || 0);
      });
  };

  useEffect(() => {
    if (practiceIds && ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [practiceIds]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }

    if (ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [filters]);

  const showInfo = (id, status) => {
    if (status === "Active") {
      service.getSingleData(id).then(([_status, resp]) => {
        setModalData({
          id,
          data: resp,
        });
      });
    } else {
      toast.warning("Dear user, this record is not active");
    }
  };

  const updateSatatus = () => {
    if (modalData) {
      displayModalConfirmation(
        "Confirmation",
        "Dear user, do you want to update this record?",
        () => {
          service.updateSigleData(modalData.id).then(([status, _resp]) => {
            if (status) {
              displayModalMessage("Success", "Changes were saved", "info");
            } else {
              displayModalMessage(
                "Waring",
                "Dear user, your request could not be processed",
                "warning"
              );
            }
          });
        }
      );
    } else toast.warning("Please Wait a few moment.. !");
  };

  if (!practiceIds) {
    return (
      <TemplateWrapped title=" Please select a connection">
        <Connections />
      </TemplateWrapped>
    );
  }

  return (
    <TemplateWrapped
      title=" DataQuality Records"
      headerButtons={
        <>
          <HeaderButton
            icon={faFilter}
            message="Filter"
            active={Object.values(filters).filter((x) => x).length > 0}
            popOverContent={
              <DataQualityFilter
                missingField={missingField}
                currentFilter={filters}
                setCurrentFilter={setFilters}
              />
            }
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              {loaded && (
                <TableDataQuality
                  showInfo={showInfo}
                  getRecords={getRecords}
                  tableReference={ref}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {modalData && (
        <CustomModal
          show
          title="Resume Details"
          onHide={() => {
            setModalData(false);
          }}
          footer={
            <div>
              <button
                type="button"
                className="btn btn-success btn-action"
                onClick={updateSatatus}
              >
                Update
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setModalData(false);
                }}
              >
                Close
              </button>
            </div>
          }
        >
          <DetailRecordsDataQuality
            info={modalData.data}
          ></DetailRecordsDataQuality>
        </CustomModal>
      )}
    </TemplateWrapped>
  );
};
export default withSelectedPractices(DataQuality);
