import React from "react";
import {useNavigate} from "react-router";
import CustomTable from "../../CustomTable";
import BotIcon from "../../Bot/components/BotIcon";
import {
  ProductContainer,
  ProductListContainer,
  ClinicContainer,
  ClinicNameContainer,
  ClinicListContainer,
  DentalMationClientsContainer,
  ClientName,
} from "./DentalMationClients.styles";
import {TemplateWrapped} from "../../Layout";
import useDentalMationClients from "./hooks/useDentalMationClients";
import {Popover} from "@material-ui/core";
import FiltersForm from "./components/FiltersForm/FiltersForm";
import ActionButton from "../../util/ActionButton";


const Product = ({ product, cancelSubscription }) => {
  const history = useNavigate()

  return (
    <ProductContainer>
      <div>
      <span className='product-item'>
        <BotIcon name={product.productName} width={17.5} height={17.5} /> {"  "}{" "}
        {product.productName}
      </span>
        <span className='product-item'>{product.adquiredDate}</span>
        <span className='product-item'>{product.status}</span>
        <span className='product-item'>{product.lastPayment}</span>
        <span className='product-item'>

          <ActionButton
            tooltip="Cancel"
            color="secondary"
            iconName="close"
            onClick={() => {

            }}
          />
        <ActionButton
          tooltip="Details"
          iconName="view_list"
          onClick={() => {
            //goToDetail(rowData);
            history(`/dentalmation-product/${product._id}`)
          }}
        />

      </span>
      </div>
    </ProductContainer>
  );
};

const ProductList = ({ products, cancelSubscription }) => {
  return (
    <ProductListContainer>
      {products.map((product) => (
        <Product key={product._id} product={product} cancelSubscription={cancelSubscription} />
      ))}
    </ProductListContainer>
  );
};

const Clinic = ({ clinic, cancelSubscription }) => {
  return (
    <ClinicContainer>
      <ClinicNameContainer>{clinic.clinicName}</ClinicNameContainer>
      <ProductList products={clinic.products} cancelSubscription={cancelSubscription} />
    </ClinicContainer>
  );
};

const ClinicList = ({ clinics, cancelSubscription }) => {
  return (
    <ClinicListContainer>
      {clinics.map((clinic) => (
        <Clinic clinic={clinic} cancelSubscription={cancelSubscription} />
      ))}
    </ClinicListContainer>
  );
};

const DentalMationClients = () => {
  const {
    dataSource,
    tableReference,
    cancelSubscription,
    handleOpenFilters,
    handleCloseFilters,
    anchor,
    refreshData
  } = useDentalMationClients()

  const open = Boolean(anchor)
  return (
    <TemplateWrapped>
      <div id="user-request">
        <Popover
          open={open}
          anchorEl={anchor}
          onClose={handleCloseFilters}
          style={{ overflow: "inherit" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
        <FiltersForm handleSubmit={refreshData} />
        </Popover>
      </div>
      <DentalMationClientsContainer>
        <CustomTable
          actions={[
            {
              icon: "filter_alt",
              tooltip: "Filters",
              isFreeAction: true,
              onClick: (event) => handleOpenFilters(event),
            },
          ]}
          search={false}
          id="client-list"
          columns={[
            {
              title: "Product",
              // render: (rowData) => <Client data={rowData} />,
              field: "clientName",
              render: (rowData) => <ClientName>{rowData.clientName}</ClientName>,
              sorting: false,
            },
            {
              title: "Adquired Date",
              sorting: false,
            },
            {
              title: "Status",
              sorting: false,
            },
            {
              title: "Last Payment",
              sorting: false,
            },
            {title: ''},
          ]}
          asyncDataSource={dataSource}
          tableReference={tableReference}
          detailPanel={(rowData) => <ClinicList clinics={rowData.clinics} cancelSubscription={cancelSubscription} />}
        />
      </DentalMationClientsContainer>
    </TemplateWrapped>
  );
};

export default DentalMationClients;
