import { useContext, useEffect, useState, createContext } from "react";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getBreadCumb } from "../../routes";
import { TemplateBody } from "../RemarkTemplate";

export const TemplateWrappedContext = createContext();

export function useTemplateWrapped() {
  const [showAsideMenu, setShowAsideMenu] = useState(true);
  const [showDropDownUserMenu, setShowDropDownUserMenu] = useState(false);
  const [showDotsMenu, setShowDotsMenu] = useState(false);
  const { width } = useWindowDimensions(({ width }) => {
    return width <= 768;
  });

  const location = useLocation()

  const { pathname } = location

  useEffect(() => {
    const route = pathname.split('/')
    const routeName = route.length > 1 ? route[1] : ''
    const isAllTransaction = routeName === 'allTransaction'
    if (width <= 768 || isAllTransaction) {
      setShowAsideMenu(false);
    }
  }, [width, pathname]);

  return {
    showAsideMenu,
    setShowAsideMenu,
    showDropDownUserMenu,
    setShowDropDownUserMenu,
    showDotsMenu,
    setShowDotsMenu,
  };
}

export function NoTemplateWrapped({ children }) {
  document.body.classList.add("p-0");
  return <>{children}</>;
}

export function TemplateWrapped({
  title,
  headerButtons = [],
  noWrapped,
  children,
  className,
}) {
  document.body.classList.remove("p-0");
  const location = useLocation();
  let breadCumbs = getBreadCumb(location.pathname);
  const activePage = breadCumbs.pop();
  const context = useContext(TemplateWrappedContext);
  return (
    <TemplateBody
      {...context}
      className={className}
      noWrapped={noWrapped}
      activePage={activePage}
      breadCumbs={breadCumbs}
      title={title}
      headerButtons={headerButtons}
    >
      {children}
    </TemplateBody>
  );
}
