import {Api, decryptApiResponse, fakeResponse} from "../../../../config/Api";
import {statusAndResponse} from "../../../../util/requestHandler";


export const getClients = () => {
  return  statusAndResponse(
    Api.get('/api/client'),
    decryptApiResponse
  )
};
export const getClientById = (clientId) => {
  return statusAndResponse(
    Api.get(`api/client/${clientId}`),
    decryptApiResponse
  );
};

export function createClient(data) {
  return statusAndResponse(Api.post("/api/client", data), decryptApiResponse);
}

export const getClientWithFilter = (params) => {
  return statusAndResponse(
    Api.get(`api/client/`, {params: params}),
    decryptApiResponse
  );
};

export function updateClient(data, clientId) {
  return statusAndResponse(Api.put(`/api/client/${clientId}`, data), decryptApiResponse);
}

export function getClient(id) {
  return statusAndResponse(
    fakeResponse(
      {
        currentPermisions: [],
        name: "Test",
        phone: "50578888888",
        email: "frolp92@gmail.com",
        address: "Test address",
        formTitle: "Test title",
        template: "12345",
        db: "testDb",
        apiKey: "12345",
        hUser: "1234",
        secret: "superSecret",
      },
      false,
      500
    ),
    (d) => d.data.value
  );
}

export function ClientDelete(clientId) {
  return statusAndResponse(
    Api.delete(`/api/client/${clientId}`),
    decryptApiResponse
  )
}
