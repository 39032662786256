import { Api, decryptApiResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";
import {getDateAsString} from "../../../util/date";
import {processFilters} from "./allTransaction.middleware";

export function getRecords(filters, limit = 300) {
  if (filters.startDate && filters.endDate) {
    filters.startDate = typeof filters.startDate === 'string' ? filters.startDate : getDateAsString(filters.startDate);
    filters.endDate = typeof filters.endDate === 'string' ? filters.endDate : getDateAsString(filters.endDate);
  }

  const {filter, ...res} = filters
  res.filter = processFilters(filter)

  function onSuccess(data) {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  }

  return statusAndResponse(
    Api.post("/api/iv/transaction/getAllTransaction", { limit, ...res }),
    onSuccess
  );
}

export function getColorsByClient(clientId){
  return statusAndResponse(
    Api.get(`/api/scheme/color/${clientId}`),
    decryptApiResponse
  )
}

export function setTransactionColor(clientId, transactionId, colorId){
  return statusAndResponse(
    Api.put(`/api/iv/transaction/color/${clientId}/${transactionId}`, {colorSchemeId: colorId}),
    decryptApiResponse
  )
}
