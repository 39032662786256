import CustomModal from "../../../CustomModal";
import { BasicForm, FormInputText } from "../../../formControls";
import useTemplateForm from "./useTemplateForm";

export default function TemplateForm({ onCancel, onSave, data }) {
  const { saving, save } = useTemplateForm(data, onSave);
  return (
    <CustomModal
      show
      onHide={onCancel}
      title={!data._id ? "New Template" : `Duplicating: ${data.name}`}
      size="md"
      render
    >
      <BasicForm
        // showInColumns
        // numberColums={2}
        fields={[
          {
            control: FormInputText,
            props: {
              key: "name",
              id: "name",
              value: "",
              label: "Name",
              required: true,
              maxLength: 40,
              msg: "Template's Name",
            },
          },
          {
            control: FormInputText,
            props: {
              key: "description",
              id: "description",
              value: "",
              label: "Description",
              required: true,
              maxLength: 40,
              msg: "Template's Description",
            },
          },
        ]}
        onSubmit={({ name, description }) => {
          if (saving) return false;
          save(name, description);
        }}
        formValidation={true}
        submitAsDataObject
      />
    </CustomModal>
  );
}
