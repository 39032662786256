import { Button, Divider, Tooltip as MTooltip } from "@material-ui/core";
import { displayModalConfirmation } from "../../../../../util/displayMessage";
import { verifyTransaction } from "../../../../../services/missingCode";
import { toast } from "react-toastify";
import file_warning from "../../assets/svg/warning_file.png";
import icon_details from "../../assets/svg/icon_details.png";
import icon_see_file from "../../assets/svg/icon_see_file.png";
import React from "react";
import CustomPopover from "../../../../CustomPopover";
import { TextButtonActions } from "../styles/AllTransactionTable.style";
import ActionButton from "../../../../ActionButton";

const ColumnActions = (props) => {
  const { rowData, history, audit, handleVerify, tableReference, permissions } =
    props;
  const havePatientInfoPermission =
    permissions &&
    permissions?.patientInfo &&
    Object.keys(permissions?.patientInfo).length > 0;

  function goToDetail(id, practice, pName) {
    history("/transaction-detail", {
      state: {
        id,
        practice,
        practiceName: pName,
      },
    });
  }

  return (
    <>
      <CustomPopover text="Add Items" icon={<ActionButton></ActionButton>}>
        {havePatientInfoPermission && (
          <div className="row w-75">
            <MTooltip title="Details">
              <Button
                style={{
                  marginLeft: 20,
                  marginRight: 0,
                  marginTop: -2,
                  minWidth: 150,
                }}
                color="primary"
                className="justify-content-start w-100"
                startIcon={
                  <img
                    src={icon_details}
                    style={{ width: "22px", height: "22px" }}
                    alt="More details icon"
                  />
                }
                onClick={() => {
                  goToDetail(
                    rowData._id,
                    rowData.practiceId,
                    rowData.practiceName,
                  );
                }}
              >
                <TextButtonActions>More Details</TextButtonActions>
              </Button>
            </MTooltip>
          </div>
        )}
        {havePatientInfoPermission && <Divider />}
        {audit && rowData.audited ? (
          <>
            <div className="row">
              <MTooltip title="Unaudit">
                <Button
                  style={{ marginLeft: 20, marginRight: 0 }}
                  color="primary"
                  className="justify-content-start w-100"
                  startIcon={
                    <img
                      src={file_warning}
                      style={{ width: "24px", height: "24px" }}
                      alt="Unaudit icon"
                    />
                  }
                  onClick={() => {
                    const nextState = !rowData.audited;
                    const actionLabel = nextState ? "verified" : "not verified";

                    displayModalConfirmation(
                      "Verify",
                      `Dear user, are you sure you want to set this record as ${actionLabel}`,
                      () => {
                        if (tableReference.current != null) {
                          const { practiceId: practice, ...data } = rowData;
                          verifyTransaction(data, practice, nextState).then(
                            ([status, _r]) => {
                              if (!status) {
                                return;
                              }
                              tableReference.current.onQueryChange();
                              toast.success(
                                `The transaction was set as ${actionLabel}`,
                              );
                            },
                          );
                        }
                      },
                    );
                  }}
                >
                  <TextButtonActions>Unaudit</TextButtonActions>
                </Button>
              </MTooltip>
            </div>
            <Divider />
          </>
        ) : null}
        <div className="row">
          <MTooltip title="See files">
            <Button
              style={{ marginLeft: 20, marginRight: 0, marginBottom: -3 }}
              color="primary"
              className="justify-content-start w-100"
              startIcon={
                <img
                  src={icon_see_file}
                  style={{ width: "24px", height: "24px" }}
                  alt="See files icon"
                />
              }
              onClick={() => {
                const { _id, practiceId: p } = rowData;
                handleVerify(_id, p);
              }}
            >
              <TextButtonActions>See File</TextButtonActions>
            </Button>
          </MTooltip>
        </div>
      </CustomPopover>
    </>
  );
};

export default ColumnActions;
