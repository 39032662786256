import moment from "moment";
import { useEffect, useState } from "react";
import { getInitialAndEndDate } from "../../../../util/date";
import { STATUSES } from "../../TransactionDetail/components/TransactionFiles/transactionfiles.constants";

export const initialCatalogsState = {
  ivTypes: [
    {
      id: "FBD",
      name: "FBD",
    },
    {
      name: "ELG",
      id: "ELG",
    },
  ],
  botStatuses: [
    {
      name: "Done by DR",
      id: "Done by DR",
    },
    {
      id: "Check",
      name: "Check",
    },
    { id: "Not content loaded", name: "Not content loaded" },
  ],
  ivStatuses: [...STATUSES, { id: "Unchecked", name: "Unchecked" }],
};

const initialState = {
  ivType: "",
  ivStatus: "",
  botStatus: "",
  startDate: "",
  endDate: "",
  patientName: "",
  ivProcessResult: "",
};

export default function useIvFilter(setFilters, initialData) {
  const [data, _setData] = useState(
    ((d) => {
      let state = {
        ...d,
        startDate: d.startDate && moment(d.startDate, "yyyy/MM/DD").toDate(),
        endDate: d.endDate && moment(d.endDate, "yyyy/MM/DD").toDate(),
      };
      return state;
    })(initialData)
  );
  const [catalogs, setCatalogs] = useState(initialCatalogsState);

  useEffect(() => {
    // Load catalogs
    setCatalogs(initialCatalogsState);
  }, []);

  function setData(field, value) {
    if (field === "startDate") {
      return setInitialDate(value);
    }
    if (field === "endDate") {
      return setEndDate(value);
    }
    _setData({ ...data, [field]: value });
  }

  function setInitialDate(date) {
    const [startDate, endDate] = getInitialAndEndDate(date, data.endDate);
    return _setData({ ...data, startDate, endDate });
  }

  function setEndDate(date) {
    const [startDate, endDate] = getInitialAndEndDate(data.startDate, date);
    return _setData({ ...data, startDate, endDate });
  }

  function getAppliedFilters(filters) {
    return Object.entries(filters).reduce((a, [key, value]) => {
      if (value) a[key] = value;

      return a;
    }, {});
  }

  function save() {
    setFilters(data);
  }

  function clear() {
    _setData(initialState);
    setFilters(getAppliedFilters(initialState));
  }

  return {
    catalogs,
    data,
    // actions
    setData,
    clear,
    save,
  };
}
