import {useContext} from "react";
import {AppContext} from "../../../App";
import {
  getAllPracticesFromClient,
  getClientFromPracticesSelected
} from "../../pages/Connections/connections.middleware";

const formatPractices = (practices) => {
  return practices.map((practice) => {
    return {
      value: practice._id,
      label: practice.name
    }
  })
}

const useSelectPractices = () => {
  const appContext = useContext(AppContext)
  const selectedPractices = appContext.get('selectedPractices')
  const allPractices = appContext.get('practices')

  const firstSelected = selectedPractices.length > 0 ? selectedPractices[0] : null

  const client = getClientFromPracticesSelected(firstSelected, allPractices)
  const allPracticesFromClient = getAllPracticesFromClient(client.clientId, allPractices)
  const practices = formatPractices(allPracticesFromClient)

  return{
    practices,
  }

}

export default useSelectPractices