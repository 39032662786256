import {TemplateWrapped} from "../../Layout";
import React from "react";
import useClientPractice from "../ClientPractice/useClientPractice";
import {Col, Row} from "react-bootstrap";
import ClientPracticeTable from "../../helper/ClientPracticeTable";
import {HeaderButton, HeaderFilterButton} from "../../RemarkTemplate/Body";
import {faPlus} from "@fortawesome/fontawesome-free-solid";
import ClientPracticeFilter from "./components/ClientPracticeFilter";
import CustomModal from "../../CustomModal";
import ClientPracticeForm from "./ClientPracticeForm";

const ClientPractice = () => {
  const {
    loadPracticeWithParam,
    clients,
    setMyFilters,
    clientSelectId,
    setClientSelectId,
    practice,
    showModal,
    setShowModal,
    showModalEdit,
    setShowModalEdit,
    practiceIdSelect,
    setPracticeIdSelect,
    dataClients,
    setDataClients,
    dataSource,
    tableReference,
    refreshData
  } = useClientPractice();

  return (
    <TemplateWrapped
      title="Client Practice"
      headerButtons={
        <>
          <HeaderFilterButton
            active={true}
            render={ClientPracticeFilter}
            componentProps={{
              setFilters: refreshData,
              setClientSelectId: setClientSelectId,
              clients: dataClients
            }}
          />

          <HeaderButton
            icon={faPlus}
            message="Add a new Practice"
            onClick={() => {
              setShowModal(true)
            }}
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <CustomModal
            show={showModal}
            onHide={() => setShowModal(false)}
            title="Create new Pratice"
            size="lg">
            <ClientPracticeForm
              clients={dataClients}
              clientSelectId={clientSelectId}
              setShowModal={setShowModal}
              editing={false}
              loadPracticeWithParam={refreshData}
            />
          </CustomModal>

          <CustomModal
            show={showModalEdit}
            onHide={() => setShowModalEdit(false)}
            title="Edit Practice"
            size="lg">
            <ClientPracticeForm
              clients={dataClients}
              clientSelectId={clientSelectId}
              setShowModalEdit={setShowModalEdit}
              editing={true}
              practiceIdSelect={practiceIdSelect}
              loadPracticeWithParam={refreshData}
            />
          </CustomModal>

          <Row className="mt-1">
            <Col className="col-12">
              <ClientPracticeTable
                refresh={refreshData}
                canEdit={true}
                canDelete={true}
                setShowModalEdit={setShowModalEdit}
                SetPracticeIdSelect={setPracticeIdSelect}
                dataSourcePractice={dataSource}
                tableReferencePractice={tableReference}
              />
            </Col>
          </Row>
        </div>
      </div>
    </TemplateWrapped>
  );
}

export default ClientPractice;