import React from "react";
import {
  FieldArray,
  Form,
  Formik,
  Field,
  ErrorMessage
} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Row
} from "react-bootstrap";
import * as Yup from 'yup'
import shortid from "shortid";

const NewCredential = ({onSubmit, initial = {label: '', paramLists: []}}) => {
  const handleSubmit = ({label, paramLists}) => {
    let objCredential = {}
    objCredential['id'] = shortid.generate()
    objCredential['label'] = label
    objCredential['options'] = paramLists.map(param => {
      let obj = {}
      obj['id'] = shortid.generate()
      obj[param.key] = param.value
      return obj
    })

    onSubmit(objCredential)
  }

  return (
    <Formik
      initialValues={initial}
      onSubmit={values => handleSubmit(values)}
      validationSchema={Yup.object().shape({
        label: Yup.string().required('Label is required'),
        paramLists: Yup.array()
          .required('Must have properties')
          .min(1, 'Please provide at least one property')
          .of(
          Yup.object().shape({
            key: Yup.string().required('Field is required'),
            value: Yup.string().required('Field is required')
          })
        )
      })}
    >
      {({values, setFieldValue, errors}) => (
        <Form>
          <div className='form-group'>
            <label htmlFor="category">Service name</label>
            <Field
              className='form-control'
              name='label'
              value={values.label}
              onChange={({target}) => setFieldValue('label', target.value)}
            />
            <ErrorMessage
              name='label'
              component='span'
              className='text-danger mt-2'
            />
          </div>
          <FieldArray
            name='paramLists'
            render={arrayHelpers => {
              const FriendArrayErrors = err => {
                return typeof err.err.paramLists === 'string'
                  ? <div className='text-center text-danger'>{err.err.paramLists}</div>
                  : null
              }
              return(
                <>
                  <div className='d-flex justify-content-between'>
                    <span>Properties</span>
                    <button
                      type='button'
                      onClick={() => arrayHelpers.push({key: "", value: ""})}
                    >
                      <FontAwesomeIcon icon={'plus'}/>
                    </button>
                  </div>
                  <div className='list-group mt-2'>
                    {values.paramLists.length > 0 && values.paramLists.map((paramList, index) => (
                      <div key={index} className='list-group-item'>
                        <Row>
                          {Object.keys(paramList).map(param => (
                            <Col key={`${param}`} md={5}>
                              <Field
                                className='form-control'
                                name={`paramLists.${index}.${param}`}
                                placeholder={`${param}`}
                              />
                              <ErrorMessage
                                name={`paramLists.${index}.${param}`}
                                component='span'
                                className='text-danger mt-2'
                              />
                            </Col>
                          ))}
                          <Col>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={(e) => arrayHelpers.remove(index)}
                            >
                              <FontAwesomeIcon icon="trash"/>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <FriendArrayErrors err={errors} />
                  </div>

                </>
              )
            }}
          />

          <Row>
            <Col md={{offset: 4, span: 4}}>
              <button
                type='submit'
                className='btn btn-primary mt-3 btn-block'
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default NewCredential