import {statusAndResponse} from "../../../util/requestHandler";
import {Api, decryptApiResponse} from "../../../config/Api";

export async function SaveFlagColor(data,clientId) {
  return statusAndResponse(
    Api.post(`/api/scheme/color/${clientId}`, data),
    decryptApiResponse
  );
}

export async function UpdateFlagColor(data,clientId) {
  return statusAndResponse(
    Api.put(`/api/scheme/color/${clientId}/${data._id}`, data),
    decryptApiResponse
  );
}

export const GetFlagColorByClient = (clientId, param) => {
 return statusAndResponse(
    Api.get(`/api/scheme/color/${clientId}`, {params: param}),
    decryptApiResponse
  );
};

export const GetFlagColorByGrid = (clientId, param) => {
  return statusAndResponse(
    Api.get(`/api/scheme/color/grid/${clientId}`, {params: param}),
    decryptApiResponse
  );
};

export function DeleteColor(clientId, colorId) {
  return statusAndResponse(
    Api.delete(`api/scheme/color/${clientId}/${colorId}`),
    decryptApiResponse
  )
}