import { faCalendar } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

export default function CustomDatePicker({
  name,
  value,
  onChange,
  format = "MM/dd/yyyy",
}) {
  return (
    <InputGroup className="flex-nowrap">
      <ReactDatePicker
        className={`form-control custom-form-control `}
        dateFormat={format}
        autoComplete="off"
        placeholderText={format}
        name={name}
        id={name}
        showMonthDropdown
        showYearDropdown
        todayButton="Today"
        selected={value}
        onChange={onChange}
      />
      <InputGroup.Append>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}
