import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import CustomModal from "../../../CustomModal";
import { formSchema } from "../ClientNS.validationSchema";

const ClientNSForm = ({
  initialValues,
  handleSubmit,
  showModal,
  setShowModal,
  allClients,
}) => {
  return (
    <CustomModal
      title={initialValues.clientId ? "Edit NS" : "Create NS"}
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form>
            <FormGroup>
              <FormLabel>Client</FormLabel>
              <Select
                isDisabled={!!initialValues._id}
                name="clientId"
                defaultValue={allClients.filter(
                  (cl) => values.clientId === cl.value
                )}
                options={allClients}
                onChange={({ label, value }) => {
                  setFieldValue("clientId", value);
                }}
              />
              {touched.clientId && errors.clientId ? (
                <span className="text-danger">{errors.clientId}</span>
              ) : null}
            </FormGroup>
            <FormGroup>
              <FormLabel>NS</FormLabel>
              <Field
                value={values.key}
                placeholder="NS"
                className="form-control"
                name="key"
                type="text"
                onChange={(e) => {
                  setFieldValue("key", e.target.value);
                }}
              />
              {touched.key && errors.key ? (
                <span className="text-danger">{errors.key}</span>
              ) : null}
            </FormGroup>
            <Row>
              <Col>
                <div className="text-right mt30 ">
                  <Button
                    type="submit"
                    variant="success btnForm btn-action"
                    size="md"
                    block
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default ClientNSForm;
