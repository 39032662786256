import shortid from "shortid";
import BasicList from "../../../BasicList";
import { FormInputText, FormSelect } from "../../../formControls";

const formatDataToOption = (data) => {
  let options = [];
  data.forEach((t) => {
    options.push({ bookmark: t, label: t });
  });
  return options;
};

export function bmToControl(field, data, fieldStates) {
  const _fieldStates = { ...defaultFieldState, ...fieldStates };

  const commonProps = {
    key: field["parameter"],
    id: field["parameter"],
    label: field["label"],
    value: data[field["parameter"]] || "",
    disabled:
      _fieldStates.editableFields.length > 0 &&
      _fieldStates.editableFields.indexOf(field.parameter) === -1,
  };

  //TODO Hard codded
  if (["symbol", "type"].indexOf(field["parameter"]) > -1) {
    return {
      control: FormSelect,
      props: {
        ...commonProps,
        options: formatDataToOption(field["items"]),
      },
    };
  }

  if (field["items"]) {
    return {
      control: BasicList,
      props: {
        ...commonProps,
        value: undefined,
        title: field["label"],
        items: field["items"],
        values:
          data["options"] && data["options"].length > 0
            ? data["options"].reduce((a, { bookmark, label }) => {
                a[shortid.generate()] = {
                  bookmark,
                  label,
                  nonEditables:
                    _fieldStates.options.notDelete.indexOf(bookmark) > -1
                      ? ["bookmark"]
                      : [],
                  deletable:
                    _fieldStates.options.notDelete.indexOf(bookmark) === -1,
                };
                return a;
              }, {})
            : {},
        onUpdate: (l) => () => {},
      },
    };
  }

  return {
    control: FormInputText,
    props: commonProps,
  };
}

export function controlTobm(input) {
  let result = {};

  if (input.props.value === "" && !input.props.items) {
    throw "Empty fields";
  }
  result[input.props.id] = input.props.value;
  if (input.props.items) {
    result["options"] = [];
    result["type"] = "object";
    Object.keys(input.props.values).forEach((k) => {
      result["options"].push({
        bookmark: input.props.values[k].bookmark,
        label: input.props.values[k].label,
        type: "boolean",
      });
    });
  }

  return result;
}

const defaultFieldState = {
  editableFields: [],
  options: {
    notDelete: [],
    editableFields: [],
  },
  usedBy: [],
};

export function formatFormFields(bmType, fields) {
  function keepOnly(x) {
    return !(bmType === "text" && x["parameter"] === "required");
  }

  return fields.filter(keepOnly);
}
