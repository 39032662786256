import {useRef, useState} from "react";

const useSummaryReportForm = () =>
{
  const initValues = {
    clientId: "",
    practiceId: "",
    startDate: "",
    endDate: ""
  }

  const [data, setData] = useState(initValues);
  const selectRef = useRef(null);
  const clearSelect = () => {
    selectRef.current.select.setValue({label: 'Select Practice', value:'-1'});
  };
  return {data, selectRef, clearSelect}
}

export default useSummaryReportForm