import {
  Formik,
  Form,
  Field
} from "formik";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import CustomDatePicker from "../../../../CustomDatePicker";
import useFilterBots from "./hooks/useFilterBots";
import {defaultFormat} from "../../../../../util/date";

const FilterBots = ({setFilters, practiceId}) => {
  const {
    filter,
    handleSetFilter,
    handleReset,
  } = useFilterBots(setFilters, practiceId)

  return (
    <Formik
      initialValues={filter}
      onSubmit={values => {
        handleSetFilter(values)
      }}
      enableReinitialize={true}
    >
      {({values, setFieldValue}) => (
        <Form className='mt-3'>
          <Row>
            <Col>
              <label htmlFor="botName">Bot Name</label>
              <Field
                className='form-control'
                id='botName'
                name='botName'
                value={values.botName}
                onChange={({target: {value}})=>{
                  setFieldValue('botName', value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="code">Code</label>
              <Field
                className='form-control'
                id='code'
                name='code'
                value={values.code}
                onChange={({target: {value}})=>{
                  setFieldValue('code', value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="startDate">Start date</label>
              <CustomDatePicker
                id='startDate'
                name='startDate'
                onChange={date => {
                  setFieldValue('startDate', date)
                }}
                value={values.startDate ?
                  moment(values.startDate, defaultFormat).toDate() :
                  null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="endDate">End date</label>
              <CustomDatePicker
                id='endDate'
                name='endDate'
                onChange={date => {
                  setFieldValue('endDate', date)
                }}
                value={values.endDate ?
                  moment(values.endDate, defaultFormat).toDate() :
                  null
                }
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={6}>
              <button
                type='submit'
                className='btn btn-primary btn-block'
              >
                Apply
              </button>
            </Col>
            <Col md={6}>
              <button
                type='button'
                className='btn btn-danger btn-block'
                onClick={handleReset}
              >
                Reset
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default FilterBots