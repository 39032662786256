import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../App";
import * as services from "./transaction-plan-detail-services";
import * as middlewares from "./transaction-plan-detail.middlewares";

const useTransactionPlanDetail = () => {
  const { state: { id } = { id: null } } = useLocation();
  const ctx = useContext(AppContext);

  const [data, setData] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const [s, r] = await services.getRecord(id);
      if (s) {
        setData(middlewares.formattedData(r).formattedSections);
      }
      setLoaded(true);
    };

    loadData();
  }, [id]);

  const saveSection = async (s) => {
    const bookmarks = middlewares.getValueFromControls(s);
    const userId = ctx.get("userId");
    const [status] = await services.update(id, bookmarks, userId);

    return status;
  };

  return {
    data,
    loaded,
    setData,
    saveSection,
  };
};

export default useTransactionPlanDetail;
