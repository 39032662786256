import { Api, decryptApiResponse, getFile } from "../../../../config/Api";
import { statusAndResponse } from "../../../../util/requestHandler";

const doNothing = [() => {}, () => {}, () => {}];

export async function getTemplateById(id, displayLoader) {
  const config = displayLoader ? [] : doNothing;

  return await statusAndResponse(
    Api.get(`/api/tmp/data/${id}`),
    decryptApiResponse,
    ...config
  );
}

export async function searchBookmark(bookMarkName) {
  return await statusAndResponse(
    Api.get(`/api/bks/search`, {
      params: {
        value: encodeURIComponent(bookMarkName),
      },
    }),
    (r) => (r.data.success ? decryptApiResponse(r) : []),
    ...doNothing
  );
}

export async function addCategory(
  templateId,
  categoryName,
  sectionType,
  orderNumber
) {
  return await statusAndResponse(
    Api.post("/api/tmp/section/store", {
      templateId,
      categoryName,
      sectionType,
      orderNumber,
    })
  );
}

export async function addSubCategory(
  templateId,
  sectionId,
  label,
  code,
  orderNumber
) {
  return await statusAndResponse(
    Api.post("/api/tmp/subsection/store", {
      templateId,
      sectionId,
      label,
      code,
      orderNumber,
    })
  );
}

export async function associateBookmarks(
  templateId,
  setctionId,
  type,
  bookmarks,
  order
) {
  return await statusAndResponse(
    Api.post("/api/tmp/bookmarks/store", {
      templateId,
      id: setctionId,
      type,
      bookmarks,
      order,
    })
  );
}

export async function updateGeneralData(templateId, name, description) {
  return await statusAndResponse(
    Api.put(`/api/tmp/${templateId}`, {
      name,
      description,
    })
  );
}

export async function deleteCategory(templateId, id) {
  return await statusAndResponse(
    Api.delete(`/api/tmp/section/${id}`, { data: { templateId } })
  );
}

export async function deleteSubCategory(templateId, id, parentId) {
  return await statusAndResponse(
    Api.delete(`/api/tmp/subsection/${id}`, {
      data: { templateId, sectionId: parentId },
    })
  );
}

export async function deleteAssociatedBookmark(
  templateId,
  bookmarkId,
  sectionId,
  subsectionId
) {
  // TODO {frank} lleva sectionId, subsectionId segun corresponda.
  return await statusAndResponse(
    Api.delete(`/api/tmp/bookmark/${bookmarkId}`, {
      data: { templateId, sectionId, subsectionId },
    })
  );
}

export async function downloadTemplate(templateId) {
  return getFile(`/api/tmp/${templateId}`);
}

export async function updateCategory(
  templateId,
  categoryId,
  categoryName,
  sectionType
) {
  return await statusAndResponse(
    Api.put(`/api/tmp/section/${categoryId}`, {
      templateId,
      categoryName,
      sectionType,
    })
  );
}

export async function updateSubCategory(
  templateId,
  categoryId,
  label,
  code,
  parentId
) {
  return await statusAndResponse(
    Api.put(`/api/tmp/subsection/${categoryId}`, {
      templateId,
      label,
      code,
      sectionId: parentId,
    })
  );
}

export async function createBookmark(bookmarkType, bookmarkData) {
  return statusAndResponse(
    Api.post(`/api/bks/${bookmarkType}/store`, bookmarkData)
  );
}

export async function getAssociatedBookmarkData(
  templateId,
  sectionType,
  sectionId,
  bookmarkId,
  bookmarkType
) {
  return statusAndResponse(
    Api.post(`/api/bks/personalize/${bookmarkType}`, {
      templateId,
      id: sectionId,
      type: sectionType,
      bookmarkId,
    }),
    decryptApiResponse
  );
}

export async function updateAssociatedBookmarkCategory(
  templateId,
  bookmarkId,
  data,
  categoryId
) {
  return statusAndResponse(
    Api.put(`/api/tmp/bookmark/${bookmarkId}`, {
      templateId,
      sectionId: categoryId,
      ...data,
    })
  );
}

export async function updateAssociatedBookmarkSubCategory(
  templateId,
  bookmarkId,
  data,
  categoryId,
  parentCategoryId
) {
  return statusAndResponse(
    Api.put(`/api/tmp/bookmark/${bookmarkId}`, {
      templateId,
      subsectionId: categoryId,
      sectionId: parentCategoryId,
      ...data,
    })
  );
}

export async function getBookmarkActions() {
  return statusAndResponse(
    Api.post("/api/bks/get/actions"),
    decryptApiResponse
  );
}

export async function getBookmarkAffectTypes() {
  return statusAndResponse(
    Api.post("/api/bks/get/affectTypes"),
    decryptApiResponse
  );
}

export async function getBookmarksToAffect(
  templateId,
  bookmarkId,
  sectionId,
  subsectionId = ""
) {
  return statusAndResponse(
    Api.post("/api/bks/get/affectsOn", {
      templateId,
      bookmarkId,
      sectionId,
      subsectionId,
    }),
    decryptApiResponse
  );
}

export async function updateAssociatedBookmarkConditions(
  templateId,
  bookmarkId,
  sectionId,
  subsectionId,
  conditions
) {
  return statusAndResponse(
    Api.put(`/api/tmp/bookmark/${bookmarkId}/conditions`, {
      templateId,
      sectionId,
      subsectionId,
      conditions,
    })
  );
}

export async function updateAssociateBookmarksDependants(
  templateId,
  bookmarkId,
  affectsOn,
  sectionId,
  subsectionId
) {
  return statusAndResponse(
    Api.put(`/api/tmp/bookmark/${bookmarkId}/dependency`, {
      templateId,
      sectionId,
      subsectionId,
      affectsOn: affectsOn,
    })
  );
}

export async function reorderBookmark(
  templateId,
  newOrder,
  elementId,
  subsectionId,
  sectionId
) {
  return Api.put("/api/tmp/bookmarks/reorder", {
    templateId,
    newOrder,
    elementId,
    subsectionId: subsectionId || "",
    sectionId,
  });
}

export async function reorderCategory(
  templateId,
  newOrder,
  subsectionId,
  sectionId
) {
  return Api.put("/api/tmp/sections/reorder", {
    templateId,
    newOrder,
    subsectionId: subsectionId || "",
    sectionId,
  });
}
