import * as auth from "../../util/auth";
import CustomModal from "../CustomModal";
import { SimpleLoginForm } from "../pages/Login";

export default function ReAuthenticate({
  setMustAuthenticate,
  loadMenuAndPermissions,
}) {
  return (
    <CustomModal show header={false}>
      <div className="p-3">
        <h5 className="mb-0">
          Dear user. Your session has expired, please login
        </h5>
        <hr />
        <SimpleLoginForm
          onSuccess={(resp) => {
            auth.login(resp.token, resp.value).then(() => {
              loadMenuAndPermissions().then((x) => {
                if (x) {
                  setMustAuthenticate(false);
                }
              });
            });
          }}
        />
      </div>
    </CustomModal>
  );
}
