import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import CustomModal from "../../../CustomModal";
import { sectionSchema } from "../permissions.validationSchema";
import { useState } from "react";

const initial = {
  displayName: "",
  type: "Section",
  name: "",
  restricted: false,
  showInMenu: false,
  url: "",
  icon: "",
};

const TitleForm = styled.h4`
  margin-bottom: 0;
`;

const PermissionForm = ({
  initialValues = initial,
  onSave,
  onCancel,
  edit = false,
}) => {
  const [showPath, setShowPath] = useState(initialValues.showInMenu);
  const [type, setType] = useState(initialValues.type || initial.type);

  const handleShowInMenu = () => {
    setShowPath(!showPath);
  };

  return (
    <CustomModal
      size="sm"
      show
      onHide={onCancel}
      title={
        <TitleForm>
          {edit ? initialValues.displayName : `Create new element`}
        </TitleForm>
      }
    >
      <Formik
        initialValues={{ ...initial, ...initialValues }}
        onSubmit={(values) => onSave(values)}
        validationSchema={sectionSchema}
        enableReinitialize={true}
      >
        {({ setFieldValue }) => (
          <>
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <label htmlFor="type">Type</label>
                  <Field
                    as="select"
                    className="form-control"
                    id="type"
                    name="type"
                    onChange={({ target }) => {
                      setFieldValue("type", target.value);
                      setType(target.value);
                    }}
                  >
                    <option value="Module">Module</option>
                    <option value="Section">Section</option>
                    <option value="Action">Action</option>
                  </Field>
                </Col>
                <Col md={12} className="mb-3">
                  <label htmlFor="displayName">Display Name</label>
                  <Field
                    className="form-control"
                    id="displayName"
                    name="displayName"
                  />
                  <ErrorMessage
                    name="displayName"
                    component="span"
                    className="text-danger"
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="name">{`${type} Name`}</label>
                  <Field className="form-control" id="name" name="name" />
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="text-danger"
                  />
                </Col>
                {type !== "Action" && (
                  <>
                    <Col md={12}>
                      <label htmlFor="restricted" className="mt-3">
                        <Field
                          type="checkbox"
                          id="restricted"
                          name="restricted"
                        />
                        Protected
                      </label>
                      <hr className="w-100" />
                    </Col>
                    <Col md={12}>
                      <label htmlFor="showInMenu" className="mt-2">
                        <Field
                          type="checkbox"
                          id="showInMenu"
                          name="showInMenu"
                          onClick={() => handleShowInMenu()}
                        />
                        Display in menu
                      </label>
                    </Col>
                  </>
                )}
                {showPath && (
                  <>
                    <Col md={12}>
                      <label htmlFor="url">Path / Url:</label>
                      <Field id="url" name="url" className="form-control" />
                      <ErrorMessage
                        name="url"
                        component="span"
                        className="text-danger"
                      />
                    </Col>
                    <Col md={12}>
                      <label htmlFor="icon">Icon:</label>
                      <Field id="icon" name="icon" className="form-control" />
                      <ErrorMessage
                        name="icon"
                        component="span"
                        className="text-danger"
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row className="mt-4">
                <Col md={{ offset: 3, span: 6 }}>
                  <button type="submit" className="btn btn-primary btn-block">
                    Save
                  </button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};

export default PermissionForm;
