import { ReactComponent as Analytics } from "../assets/svg/analytics.svg";
import { ReactComponent as FinancialUpdates } from "../assets/svg/financial-updates.svg";
import { ReactComponent as MissedAppointments } from "../assets/svg/missed-appointments.svg";
import { ReactComponent as NewPatient } from "../assets/svg/new-patient.svg";
import { ReactComponent as PaymentPosting } from "../assets/svg/payment-posting.svg";
import { ReactComponent as Reconciliations } from "../assets/svg/reconciliations.svg";

import {
  ANALYTICS,
  FINANTIAL_UPDATES,
  MISSED_APPOINTMENTS,
  NEW_PATIENT,
  PAYMENT_POSTING,
  POWER_BI,
  RECONCILIATIONS,
  X_RAYS,
} from "../bot.constants";

const BotIcon = ({ name, width, height, color }) => {
  let Render;
  switch (name) {
    // case X_RAYS:
    //   return <XRays width={width} height={height} />;
    case POWER_BI:
      Render = Analytics;
      break;
    case ANALYTICS:
      Render = Analytics;
      break;
    case FINANTIAL_UPDATES:
      Render = FinancialUpdates;
      break;
    case NEW_PATIENT:
      Render = NewPatient;
      break;
    case MISSED_APPOINTMENTS:
      Render = MissedAppointments;
      break;
    case RECONCILIATIONS:
      Render = Reconciliations;
      break;
    case PAYMENT_POSTING:
      Render = PaymentPosting;
      break;
    default:
      Render = <span></span>;
  }

  return <Render style={{ width, height, color }} />;
};

export default BotIcon;
