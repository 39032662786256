import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import useSelectedCategory from "./hooks/useSelectedCategory";
import useTemplate from "./hooks/useTemplate";

export default function useForm(templateId) {
  const location = useLocation();
  console.log("frank", { location });

  const { state: { id } = { id: null } } = location;
  const history = useNavigate();
  const { data, previewData, loadingData, ...calls } = useTemplate(
    templateId || id
  );
  const [selectedCategory, setSelectedCategory, sC] = useSelectedCategory();
  const [reorder, setReorder] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  useEffect(() => {
    if (data && selectedCategory) {
      for (const category of data.categories) {
        if (category === selectedCategory) {
          return;
        }

        if (category.id === selectedCategory.id) {
          sC(category);
          return;
        }

        if (category.subCategories && category.subCategories.length > 0) {
          for (const subCategory of category.subCategories) {
            if (subCategory === selectedCategory) {
              return;
            }

            if (subCategory.id === selectedCategory.id) {
              sC(subCategory);
              return;
            }

            for (const innerSubCategory of subCategory.subCategories) {
              if (innerSubCategory === selectedCategory) {
                return;
              }

              if (innerSubCategory.id === selectedCategory.id) {
                sC(innerSubCategory);
                return;
              }
            }
          }
        }
      }
    }
  }, [data, selectedCategory, sC]);

  return {
    data,
    previewData,
    loadingData,
    selectedCategory,
    setSelectedCategory,
    reorder,
    calls: { ...calls, setReorder },
    goBack: history.goBack,
    previewMode,
    setPreviewMode,
  };
}
