import {Api, decryptApiResponse, fakeResponse} from "../../../../config/Api";
import {statusAndResponse} from "../../../../util/requestHandler";

export const getExtractionScheduleByPractice = (practiceIds) => {
  return statusAndResponse(
    Api.get(`/api/iv/spreadsheet/pages/`, {
      params: {
        practiceIds: JSON.stringify(practiceIds)
      }
    }),
    decryptApiResponse
  )
}

export const getFakeExtraction = (practiceId) => {
  return fakeResponse([
    {
      _id: 'asdasdasd',
      extractionsStatus: 1,
      dates: [
        '10/28/2021',
        '11/28/2021',
        '11/28/2021',
        '11/28/2021',
      ],
      type: 'new',
      dateCreated: '10/28/2021',
      createdBy: 'Reynaldo'
    },
    {
      _id: 'asdasdasd',
      extractionsStatus: 0,
      dates: [
        '10/28/2021',
        '11/28/2021',
      ],
      type: 'new',
      dateCreated: '10/28/2021',
      createdBy: 'Reynaldo'
    },
    {
      _id: 'asdasdasd',
      extractionsStatus: 2,
      dates: [
        '10/28/2021',
        '11/28/2021',
      ],
      type: 'new',
      dateCreated: '10/28/2021',
      createdBy: 'Reynaldo'
    }
  ], false, 300)
}

export const saveExtraction = async (extraction) => {
  const response = await Api.post(`/api/iv/spreadsheet/pages`, extraction)
  if (!response.data.success) {
    return {
      message: response.data.err,
      err: true
    }
  }
  const decrypt = await decryptApiResponse(response)
  return {
    message: decrypt
  }
}

export const checkDates = async (extraction, practiceId, clientId) => {
  const response = await Api.post(`/api/iv/spreadsheet/verify/pages/`, extraction)
  if (!response.data.success) {
    return {
      message: response.data.err,
      err: true
    }
  }

  const decrypt = await decryptApiResponse(response)

  return {
    message: decrypt
  }
}