import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import React from "react";

export default function UserHelp({ children, className, asList }) {
  return (
    <>
      {asList && (
        <>
          <div className={`align-items-center d-flex ${className}`}>
            <FontAwesomeIcon icon={"info-circle"} className="text-info mr-2" />
            <ul className="text-muted small user-help">{children}</ul>
          </div>
        </>
      )}
      {!asList && (
        <small className={`d-inline-flex align-items-center ${className}`}>
          <FontAwesomeIcon icon={"info-circle"} className="text-info mr-2" />
          <span className="text-muted">{children}</span>
        </small>
      )}
    </>
  );
}
