import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Api, decryptApiResponse } from "../../../config/Api";
import * as passwordStrength from "../../../util/passwordStrength";
import { statusAndResponse } from "../../../util/requestHandler";
import { Loader } from "../../formControls";
import CenteredMessage from "../../helper/CenteredMessage";
import { NoTemplateWrapped } from "../../Layout/index";
import UserHelp from "../../util/UserHelp";
import { Wrapper } from "../Login/Wrapper";

const alert = withReactContent(Swal);

function displayMessage(title, html, icon = "error", callback) {
  alert.fire({
    icon,
    title,
    html,
    onClose: () => {
      callback && callback();
    },
  });
}

function verifyUrl(url) {
  return statusAndResponse(
    Api.get("api/authenticate/tokenValidate" + url),
    (r) => decryptApiResponse(r)
  );
}

function changePassword(token, password, userId) {
  return statusAndResponse(
    Api.post("api/authenticate/updatePassword", {
      token,
      password,
      userId,
    })
  );
}

const defaultState = {
  id: null,
  userName: "",
  password: "",
  passwordConfirm: "",
};

const defaultFlagsState = {
  saving: false,
  invalidUrl: false,
  redirectToLogin: false,
  loaded: false,
};

function usePasswordReset(url) {
  const [data, setData] = useState(defaultState);
  const [flags, setFlags] = useState(defaultFlagsState);
  const token =
    url.split("signature=").length > 0 && url.split("signature=")[1];

  useEffect(() => {
    verifyUrl(url)
      .then(([status, { _id: id, userId, email: userName }]) => {
        if (!status || !userId) {
          throw "";
        }

        setData({ ...data, userName, id: userId });
        setFlags({ ...flags, loaded: true });
      })
      .catch((r) => {
        setFlags({ ...flags, invalidUrl: true });
      });
  }, [url]);

  useEffect(() => {
    const { id, password, passwordConfirm } = data;
    const { saving } = flags;

    if (saving) {
      try {
        if (password !== passwordConfirm) {
          throw new Error(
            "Passwords does not match|<p>Dear user, the passwords does not match</p>"
          );
        }

        const [isValid, messages] = passwordStrength.isValid(password);

        if (isValid === false) {
          passwordStrength.defaultError(messages);
        }

        changePassword(token, password, id)
          .then(([status, r]) => {
            if (!status) {
              toast.error("Please try again");
            }
            displayMessage(
              "Success",
              "Your password has ben reset",
              "success",
              () => {
                setFlags({ ...flags, redirectToLogin: true });
              }
            );
          })
          .catch((x) => {
            setSaving(false);
          });
      } catch (e) {
        const [title, message] = e.message.split("|");
        displayMessage(title, message);
        setSaving(false);
      }
    }
  }, [flags.saving]);

  function handleChange(inputName) {
    return function (value) {
      setData({ ...data, [inputName]: value });
    };
  }

  function setSaving(saving) {
    setFlags({ ...flags, saving });
  }

  function save() {
    setSaving(true);
  }

  return [data, flags, handleChange, save];
}

export function Presentational({
  sending = false,
  userName = "",
  password = "",
  passwordConfirmation = "",
  handleChange = () => {
    return function () {};
  },
  onSubmit = () => {},
}) {
  function handleInputChange(elementName) {
    return function ({ target: { value } }) {
      handleChange(elementName)(value);
    };
  }

  return (
    <Wrapper>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit();
        }}
      >
        <div className="box-input">
          <p className="text-center font-weight-100 my-3">
            Password Reset: <strong>{userName}</strong>
          </p>
          <input
            type="password"
            className="item-control"
            name="password"
            id="password"
            placeholder="New Password"
            value={password}
            onChange={handleInputChange("password")}
          />
          <input
            type="password"
            className="item-control"
            name="passwordConfirm"
            id="passwordConfirm"
            placeholder="Password confirmation"
            value={passwordConfirmation}
            onChange={handleInputChange("passwordConfirm")}
          />
          <UserHelp className="mt-4 mb-0 text-justify">
            Dear user, once you have reseted your password, you will be
            redirected to the login page.
          </UserHelp>
        </div>

        <div className="box-btn my-4">
          <button disabled={sending} className="btn btn-success">
            {!sending && "Send"}
            {sending && "Please wait "}
            {sending && <FontAwesomeIcon icon="spinner" spin />}
          </button>
        </div>
      </form>
    </Wrapper>
  );
}

export default function PasswordReset() {
  const l = useLocation();
  let url = l.search;
  const [
    { userName, password, passwordConfirm }, //Data
    { saving, invalidUrl, loaded, redirectToLogin }, // Flags
    handleChange,
    save,
  ] = usePasswordReset(url);

  if (invalidUrl) {
    return (
      <CenteredMessage>
        Dear user, this URL is invalid or already expired.
      </CenteredMessage>
    );
  }

  if (redirectToLogin) {
    return <Navigate to="/" />;
  }

  if (!loaded) {
    return <Loader active={true} />;
  }

  return (
    <NoTemplateWrapped>
      <Presentational
        userName={userName}
        password={password}
        passwordConfirmation={passwordConfirm}
        sending={saving}
        handleChange={handleChange}
        onSubmit={save}
      />
    </NoTemplateWrapped>
  );
}
