import {TemplateWrapped} from "../../Layout";
import useConnections from "./hooks/useConnections";
import {Wrapper} from "./components/styles/ConnectionsStyles";
import CustomPanel from "../../CustomPanel";
import ClientsContainer from "./components/ClientsContainer";
import PracticesContainer from "./components/PracticesContainer";

// Services

export function Presentational({_title = ""}) {

  const {
    selectedClient,
    allPractices,
    selectedPractices,
    clientId,
    canUseMultiPractice,
    handleClientSelected,
    handleClickPractice,
    handleCheckAllPractices,
    handleUnCheckAllPractices,
    checkIfPracticeIsSelected
  } = useConnections();

  return (
    <CustomPanel>
      <Wrapper>
        <ClientsContainer
          clientId={clientId}
          clients={allPractices}
          clientIdSelected={selectedClient ? selectedClient.clientId : ''}
          handleClientSelected={handleClientSelected}
        />
        <PracticesContainer
          practices={selectedClient ? selectedClient.practices : []}
          handleClickPractice={handleClickPractice}
          selectedPractices={selectedPractices}
          clientId={clientId}
          clientIdSelected={selectedClient ? selectedClient.clientId : ''}
          handleCheckAllPractices={handleCheckAllPractices}
          handleUnCheckAllPractices={handleUnCheckAllPractices}
          checkIfPracticeIsSelected={checkIfPracticeIsSelected}
          showButtons={canUseMultiPractice}
        />
      </Wrapper>
    </CustomPanel>
  );
}

export default function Connections({title = "Connections"}) {
  return (
    <TemplateWrapped title={title}>
      <Presentational />
    </TemplateWrapped>
  );
}
