import { useContext } from "react";
import { Form } from "react-bootstrap";
import { CustomFormContext } from "..";

export const commonProps = {
  label: "",
  id: "",
  value: "",
  wrapped: true,
  wrapperProps: {},
  onChange: () => {},
  onBlur: () => {},
};

const useControl = (props) => {
  const {
    label,
    id,
    value,
    wrapped,
    wrapperProps,
    onChange,
    onBlur,
    ...otherProps
  } = { ...commonProps, ...props };
  const ctx = useContext(CustomFormContext);

  const handleChange = ({ target: { name, value } }) => {
    if (ctx) {
      ctx.set(name, value);
    }
    onChange && onChange(value);
  };

  const controlProps = {
    name: id,
    id,
    value: ctx ? ctx.get(id) : value,
    onChange: handleChange,
    ...otherProps,
  };

  return { controlProps };
};

export function ControlWrapper({
  children,
  label,
  wrapped = true,
  wrapperProps,
}) {
  const Component = (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      {children}
    </>
  );

  return wrapped ? (
    <Form.Group {...wrapperProps}>{Component}</Form.Group>
  ) : (
    Component
  );
}

export default useControl;
