import styled from "styled-components"

const buttonsColors = {
  0: {
    color: '#76838f',
    backgroundColor: 'transparent',
    borderColor: '#e4eaec',
    hover: 'rgba(118,131,143,.1)'
  },
  1: {
    color: '#fff',
    backgroundColor: '#11c26d',
    borderColor: '#11c26d',
    hover: '#28d17c'
  },
  2: {
    color: '#000',
    backgroundColor: '#cfe9f5',
    borderColor: '#cfe9f5',
    hover: '#cfe9f5'
  }
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  //justify-content: space-between;
  //height: 70vh;
`

export const ClientsSection = styled.div`
  //height: 70vh;
`

export const ClientsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-right: 1px solid #e6e6e6;
  width: 300px;

  @media (max-width: 1199.98px) {
    width: 200px;
  }
`

export const ClientItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  position: relative;

  background-color: ${({active}) => active ? '#d9e9ff' : 'transparent'};

  :hover {
    background-color: #d9e9ff;
  }

  :after {
    width: ${({active}) => active ? '2px' : '0'};
    position: absolute;
    content: ' ';
    height: 100%;
    right: 0;
    top: 0;
    background-color: #3e8ef7;
  }
`

export const PracticesSection = styled.div`
  width: 100%;
  padding: 0 1rem;

  /*  @media (max-width: 1199.98px) {
      width: 200px;
    }*/
`

export const PracticesList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  padding: 0;
`

export const PracticeItem = styled.li`
  width: calc(100% / 4);
  //margin-right: .1rem;

  @media (max-width: 1399.98px) {
    width: calc(100% / 3);
  }

  @media (max-width: 1199.98px) {
    width: 50%;
  }

  @media (max-width: 991.98px) {
    width: 100%;
    margin-right: 0;
  }
`

export const Button = styled.button`
  width: 90%;
  display: inline-block;
  min-width: 100px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent !important;
  padding: 0.429rem 1rem;
  font-size: 1rem;
  line-height: 1.57142857;
  border-radius: 0.215rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
`

export const PracticeItemButton = styled(Button)`
  color: ${({state}) => buttonsColors[state].color};
  background-color: ${({state}) => buttonsColors[state].backgroundColor};
  border-color: ${({state}) => buttonsColors[state].borderColor} !important;
  margin-bottom: 1rem;
  text-align: left;

  :hover {
    background-color: ${({state}) => buttonsColors[state].hover} !important;
  }
`

export const PracticeName = styled.span`
  display: block;
  //width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  svg {
    margin-right: .625rem;
  }
`

export const PracticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({showScroll}) => showScroll ? '1rem' : 0};
`

export const Legend = styled.div`
  display: flex;
  margin-left: 1rem;
`

export const LegendWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
`

const LegendItem = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: .625rem;
`

export const LegendActive = styled(LegendItem)`
  background-color: #11c26d;
`

export const LegendNew = styled(LegendItem)`
  background-color: #cfe9f5;
`
export const LegendDisabled = styled(LegendItem)`
  background-color: #e4eaec;`

export const ContainerTable = styled.div`
  height: 100%;
  max-height: 90vh;
  overflow: hidden;
`

export const TableConnectionsWrapper = styled.div`
  
  .grid-component{
    .MuiPaper-root{
      box-shadow: unset;
    }
  }
`