import { Icon, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { getDateAsString } from "../../../../util/date";
import { TextColumnHighlighted } from "../../../CustomTable";
import {BiCommentDetail, HiPencilAlt} from "react-icons/all";

const AuditButton = ({ record, audit }) => {
  const [disabled, setDisabled] = useState(false);

  const onAudit = async () => {
    setDisabled(true);
    await audit();
    setDisabled(false);
  };

  if (!record.audited) {
    return (
      <Tooltip title="Set as verified">
        <button className="audit" disabled={disabled} onClick={onAudit}>
          <span>Not audited</span> <Icon>star_border</Icon>
        </button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Set as not verified">
      <button className="audit active" disabled={disabled} onClick={onAudit}>
        <span className="text-info">Audited</span>{" "}
        <Icon className="text-info">star</Icon>
      </button>
    </Tooltip>
  );
};

export default function SideInfo(
  {
    record,
    audit,
    canAudit,
    practiceName,
    canAddComments,
    addComment
  }
) {

  const [comment, setComment] = useState(record.comment || '')

  let status = "danger";
  if (record.iv_status.toUpperCase().indexOf("INACTIVE") > -1) {
    status = "warning";
  } else if (record.iv_status.toUpperCase().indexOf("ACTIVE") > -1) {
    status = "success";
  }

  return (
    <div className="col-lg-3">
      <div className="card user">
        <div className="card-block text-center">
          <div className="icon-container">
            <i className="icon io-list-rich"></i>
          </div>

          <div className="text-center">
            <TextColumnHighlighted
              text={record.iv_status}
              status={status}
              asDot
            />
          </div>

          <ul className="list-group list-group-dividered list-group-full text-left">
            {record.provider && (
              <li className="list-group-item">
                <h6>
                  <i className="icon io-person" aria-hidden="true"></i>
                  Provider:{" "}
                  {record.provider}
                </h6>
              </li>
            )}
            <li className="list-group-item">
              <h6>
                <i className="icon wb-calendar" aria-hidden="true"></i>
                Appointment:{" "}
                {getDateAsString(record.appointment_date, "yyyy-MM-DD")}
              </h6>
            </li>
            <li className="list-group-item">
              <h6>
                <i className="icon io-person"></i> Patient Name:{" "}
                {record.patientName}
              </h6>
            </li>
            <li className="list-group-item">
              <h6>
                <i className="icon io-monitor"></i>Type: {record.type}
              </h6>
            </li>
            <li className="list-group-item">
              <h6>
                <i className="icon io-file"></i>Practice: {practiceName}
              </h6>
            </li>
            <li className="list-group-item">
              <h6>
                <i className="icon io-spreadsheet"></i>Carrier: {record.carrier}
              </h6>{" "}
            </li>
            <li className="list-group-item">
              <h6>
                <i className="icon io-people"></i>Verification By:{" "}
                {record.iv_process_result}
              </h6>
            </li>
            <li className="list-group-item">
              <h6>
                <i className="icon io-task"></i>Eligibility Status:{" "}
                {record.iv_status}
              </h6>
            </li>

            <li className={`list-group-item`}>
              <h6>
                <BiCommentDetail/> Comments
              </h6>
              <textarea
                disabled={!canAddComments}
                className={`form-control ${!canAddComments ? 'mb-5' : ''}`}
                style={{resize: 'none'}}
                value={comment}
                onChange={({target}) => setComment(target.value)}
              />
              {canAddComments && (
                <button className='audit mt-3 mb-5' onClick={() => addComment(comment)}>
                  <HiPencilAlt size={'1.3rem'} />
                  Apply comments
                </button>
              )}
            </li>
          </ul>
          <div>
            {canAudit && (
              <AuditButton record={record} audit={audit} />
            )}
          </div>
        </div>
        <div className="card-footer">
          <div className="row no-space">
            <div className="col-2"></div>
            <div className="col-8 text-center">
              <strong className="missing-fields-count">
                {record.type === "ELG"
                  ? 0
                  : record.missingRequiredInputQty || 0}
              </strong>
              <span className="missing-fields">Missing Fields</span>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
