import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {TemplateWrapped} from "../../Layout";
import {HeaderButton, HeaderFilterButton} from "../../RemarkTemplate/Body";

import ClientsTable from "../../helper/ClientsTable";
import {useClients} from "./useClients";
import CustomModal from "../../CustomModal";
import ClientsForm from "./ClientsForm";
import ClientFilter from "./components/ClientFilter";
import ClientUserAdmin from "./components/ClientUserAdmin";


export default function Clients() {
  const {
    clients,
    showModalEdit,
    setShowModalEdit,
    showModal,
    setShowModal,
    locateState,
    clientSelect,
    setClientSelect,
    setMyFilters,
    showModalUser,
    setShowModalUser,
    template,
    setTemplate,
    stateSelect,
    setStateSelect,
    loadClient,
    dataSource,
    tableReference,
    refreshData
  } = useClients();

  return (
    <TemplateWrapped
      title="Clients"
      headerButtons={
        <>
          <HeaderFilterButton
            active={true}
            render={ClientFilter}
            componentProps={{
              setFilters: refreshData,
              locateState: locateState
            }}
          />
          <HeaderButton icon={faPlus} message="New Record" onClick={() => {
            setShowModal(true)
          }}/>
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              <ClientsTable
                canEdit={true}
                canDelete={true}
                canEditUser={true}
                data={clients}
                setClientSelect={setClientSelect}
                setShowModalEdit={setShowModalEdit}
                setShowModalUser={setShowModalUser}
                setStateSelect={setStateSelect}
                loadClient={refreshData}
                dataSource={dataSource}
                tableReference={tableReference}
              />
              <CustomModal
                show={showModalEdit}
                size='lg'
                locateState={locateState}
                onHide={() => setShowModalEdit(false)}
                title="Edit Client">
                <ClientsForm
                  locateState={locateState}
                  clientSelect={clientSelect}
                  editing={true}
                  setShowModalEdit={setShowModalEdit}
                  template={template}
                  stateSelect = {stateSelect}
                  refreshData={refreshData}
                >
                </ClientsForm>
              </CustomModal>

              <CustomModal
                show={showModal}
                size='lg'
                onHide={() => setShowModal(false)}
                title="New Client">
                <ClientsForm
                  template={template}
                  locateState={locateState}
                  editing={false}
                  setShowModal={setShowModal}>

                </ClientsForm>
              </CustomModal>

              <CustomModal
                show={showModalUser}
                onHide={() => setShowModalUser(false)}
                title="New Client">
                <ClientUserAdmin

                  locateState={locateState}
                  editing={false}
                  setShowModal={setShowModal}>
                </ClientUserAdmin>

              </CustomModal>
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
}


