import {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as services from '../../../../../services/missingCode'
import {FileIcon} from "../../../TransactionDetail/components/TransactionFiles/ExistingFileListItem";
import {FileActionButton} from "../../../TransactionDetail/components/TransactionFiles/transaction-files.styles";
import {getFileExtension} from "../../../TransactionDetail/components/TransactionFiles/TransactionFiles.utils";
import UserHelp from "../../../../util/UserHelp";
import * as fileServices from './filesList.services'
import PDFViewer from "../../../../PDFViewer";
import {convertDocxToPdf} from "../../../../../services/tools";
import {toast} from "react-toastify";
import * as fileUtils from "../../../../../util/file";

const FileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

const FilesList = ({id, practice, canDownloadFiles}) => {
  const [files, setFiles] = useState([]);
  const [verify, setVerify] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [isPdfSelected, setIsPdfSelected] = useState(false);
  const [idSelected, setIdSelected] = useState(null);


  useEffect(() => {
    async function getFiles() {
      const [status, res] = await fileServices.getFiles(id, practice)
      if (status) {
        const {driveFiles = []} = res
        setFiles(driveFiles)
        setVerify(true)
      }
    }

    getFiles()
  }, [id, practice])

  const handleClickClosePdf = () => {
    setPdf(null)
    setIsPdfSelected(false)
    setIdSelected(null)
  }

  const downloadFile = async (fileId, fileName) => {
    const extension = getFileExtension(fileName);
    const blob = await services.downloadFile(id, fileId);


    if (extension === 'word') {
      const file = new File([blob.data], `test.docx`);
      const formData = new FormData();
      formData.append("source", file);
      formData.append("extension", 'docx');

      const [status, res] = await convertDocxToPdf(formData, () => {
      })
      if (status) {
        blobToBase64(res.data, fileId)
      }

    } else {
      blobToBase64(blob.data, fileId)
    }

  }

  const _downloadFile = async (fileId, name) => {
    const blob = await services.downloadFile(id, fileId);

    const extension = getFileExtension(name);
    const onError = (r) => {
      toast.error("Dear user, please try again");
    };

    if (extension === "word") {
      return fileUtils.download(blob.data, name, onError);
    }

    return fileUtils.openInBrowser(blob.data, fileUtils.FILE_TYPES.pdf, onError);
  }

  const blobToBase64 = (blob, fileId) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result
      setPdf(base64data)
      setIsPdfSelected(true)
      setIdSelected(fileId)
    };
  }

  return (
    <>
      {
        files.length > 0 && !isPdfSelected && (files.map(file => {
          return (
            <Item
              file={file}
              downloadFile={downloadFile}
              key={file.id}
              idSelected={idSelected}
              canDownloadFiles={canDownloadFiles}
              _downloadFile={_downloadFile}
            />
          )
        }))
      }

      {
        files.length === 0 && verify && (
          <UserHelp>
            Dear user, currently, this transaction does not have any fields
            attached to it.
          </UserHelp>
        )
      }
      {
        isPdfSelected && (
          <div>
            <button onClick={handleClickClosePdf} className='btn btn-primary'>Close PDF viewer</button>
            <PDFViewer pdf={pdf}/>
          </div>
        )
      }

    </>
  )
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
`;

const ButtonsWrapped = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Item = ({file, downloadFile, canDownloadFiles, _downloadFile}) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleClick = (id, filename) => {
    setIsDownloading(true)
    downloadFile(id, filename).then(r => {
      setIsDownloading(false)
    })
  }

  const handleClickDownload = (id, filename) => {
    setIsDownloading(true)
    _downloadFile(id, filename).then(r => {
      setIsDownloading(false)
    })
  }

  const extension = getFileExtension(file.filename);
  const PrintIcon = extension === 'word' ?
    (<FontAwesomeIcon color='primary' icon='eye'/>) :
    (<i className="icon io-eye" aria-hidden="true"/>)

  const DownloadIcon = extension === 'word' ?
    (<FontAwesomeIcon color='primary' icon='download'/>) :
    (<i className="icon io-print" aria-hidden="true"/>)

  return (
    <FileItem>
      <FileIcon fileName={file.filename}/>
      <FileName name={file.filename}/>
      <div>
        {!isDownloading ? (
          <ButtonsWrapped>
            <FileActionButton
              onClick={() => handleClick(file.id, file.filename)}
            >
              {PrintIcon}
            </FileActionButton>
            {canDownloadFiles && (
              <FileActionButton
                onClick={() => handleClickDownload(file.id, file.filename)}
              >
                {DownloadIcon}
              </FileActionButton>
            )}
          </ButtonsWrapped>
        ) : <Spinner>
          <FontAwesomeIcon color='primary' icon='spinner' size='2x'/>
        </Spinner>}
      </div>
    </FileItem>
  )
}

const Name = styled.div`
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FileName = ({name}) => (
  <Name className='ml-3'>{name}</Name>
)

export default FilesList
