import React from "react";
import { Tab, Nav } from "react-bootstrap";
import "./styles.css";
import { groupByParentId } from "../../util/arrays";

const CustomTabPanel = ({ data, handleClickTab, ...props }) => {
  return (
    <div className="custom-tab">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={props.defaultActive || 0}
      >
        <div className="d-flex">
          <div className="nav-container">
            <Nav variant="pills" className="flex-column">
              {(data || []).map((item) => {
                return (
                  <Nav.Item
                    onClick={(e) => handleClickTab(e, item[props.recordId])}
                    key={item[props.recordId]}
                  >
                    <Nav.Link eventKey={item[props.recordId]}>
                      <strong>{item.name}</strong>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          <div className="flex-grow-1 h-100 content">
            <Tab.Content>
              {data.map((item) => {
                return (
                  <Tab.Pane
                    eventKey={item[props.recordId]}
                    key={item[props.recordId]}
                  >
                    <div className="row">
                      <div className="col-12">
                        {props.renderContent(
                          groupByParentId(item[props.contentData] || []),
                          props
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};
export default CustomTabPanel;
