import React from "react";
import useControl, { commonProps, ControlWrapper } from "./useControl";

const wrappedProps = {
  render: () => <div></div>,
  ...commonProps,
};

export default function WrappedInput({
  render: Component,
  ...props
} = wrappedProps) {
  const { onChange: prevOnchange, ...rest } = props;
  const { controlProps } = useControl(rest);
  const { onChange, ...wrappedControlProps } = controlProps;

  function handleChange(value) {
    const computed = prevOnchange(value);
    onChange({ target: { name: props.id, value: computed } });
  }

  return (
    <ControlWrapper {...props}>
      <Component {...wrappedControlProps} onChange={handleChange} />
    </ControlWrapper>
  );
}
