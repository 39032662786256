import React, { createContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Loader } from "./components/formControls";
import { TemplateWrappedContext } from "./components/Layout";
import ReAuthenticate from "./components/ReAuthenticate";
import { setMenu } from "./menu";
import Routes from "./routes";
import * as authServices from "./services/auth";
import { useApp } from "./useApp";
import * as auth from "./util/auth";
import { getVisibleLevels } from "./util/menuBuilder";
import {transformToMenu,transformToMenuItem,transformPermissions} from "./App.utils";

export const AppContext = createContext({
  get: (key) => {},
  set: (key, value) => {},
  displayLoader: (message) => {},
});

function App() {
  const {
    authLoaded,
    contextHandler,
    templateWrappedCtx,
    mustAuthenticate,
    ssrSubscriber,
    setMustAuthenticate,
  } = useApp();

  if (!authLoaded) {
    return <Loader active />;
  }

  return (
    <>
      <AppContext.Provider value={contextHandler}>
        <TemplateWrappedContext.Provider value={templateWrappedCtx}>
          <Routes></Routes>
        </TemplateWrappedContext.Provider>
      </AppContext.Provider>

      {mustAuthenticate && (
        <ReAuthenticate
          setMustAuthenticate={setMustAuthenticate}
          loadMenuAndPermissions={loadMenuAndPermissions}
        />
      )}
      <ToastContainer limit={1} />
      <RequestProgressLoader subscriber={ssrSubscriber} />
    </>
  );
}

function RequestProgressLoader({ subscriber }) {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    subscriber.subscribe(setDisplay);
    return function () {
      subscriber.unsuscribe(setDisplay);
    };
  }, [subscriber]);

  return (
    <Loader
      active={display !== false}
      text={display !== false || display !== undefined ? display : ""}
    />
  );
}

export async function loadMenuAndPermissions() {
  return Promise.all([
    authServices.getPermissions(),
    authServices.getFullMenu(),
  ]).then(([[permissionStatus, permissions], [menuStatus, menu]]) => {
    if (!menuStatus || !permissionStatus) return false;

    let menuItems = transformToMenuItem(menu);
    let fullMenu = transformToMenu(menuItems);
    const permissionOldStructure = transformPermissions(menu,permissions) ;
    auth.setPermissions(permissionOldStructure.permission);
    auth.setDefaultPath(permissionOldStructure.defaultPath);    
        
    const visibleLevels = getVisibleLevels(fullMenu, permissionOldStructure.permission);
    setMenu(visibleLevels);

    return permissionOldStructure;
  });
}

export default App;
