import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import UserHelp from "../../util/UserHelp";

export function TwoStepForm({
  waiting,
  pin,
  setPin,
  onSubmit,
  resendCode,
  cancel,
}) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return (
    <>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit({ pin });
        }}
      >
        <UserHelp className="mb-3">
          Dear user, a passcode has been sent to you, please type it in the box below. Expires in 10 minutes.
        </UserHelp>
        <div className="form-group">
          <label className="sr-only" htmlFor="inputEmail">
            PIN
          </label>
          <input
            type="text"
            ref={ref}
            className="form-control"
            name="pin"
            value={pin}
            onChange={({ target: { value } }) => {
              setPin(value);
            }}
            id="pin"
            placeholder="Pin"
          />
        </div>

        <button
          className="btn btn-success btn-action  w-100 p-2"
          disabled={waiting}
        >
          {!waiting && "Login"}
          {waiting && "Please wait "}
          {waiting && <FontAwesomeIcon icon="spinner" spin />}
        </button>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            disabled={waiting}
            className="btn btn-link"
            onClick={resendCode}
          >
            Send a new pin
          </button>
          <button
            type="button"
            disabled={waiting}
            className="btn btn-link"
            onClick={cancel}
          >
            Go to previous step
          </button>
        </div>
      </form>
    </>
  );
}
