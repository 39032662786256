import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useEmailReset from "../Login/hooks/useEmailReset";
import { ResetPasswordForm } from "../Login/ResetPasswordForm";

export default function ResetPasswordRequest() {
  const email = useEmailReset();

  useEffect(() => {
    if (email.done && email.success) {
      toast.success("Dear user, please check your e-mail");
    }
  }, [email.done, email.success]);

  return (
    <div className="p-5 mt-5 d-flex align-items-center justify-content-center">
      <Container className="mt-5">
        <Row>
          <Col md={6} className=" offset-md-3 bg-white p-5">
            <div className="d-flex flex-column">
              <ResetPasswordForm
                setShowModal={() => {}}
                onSubmit={email.setEmail}
                waiting={email.waiting}
                cancelButton={false}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
