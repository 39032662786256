import { Api, decryptApiResponse } from "../config/Api";
import { decryptData } from "../config/encryptData";
import { statusAndResponse } from "../util/requestHandler";

export const getTemplateStructure = () => {
  return statusAndResponse(Api.get("/api/tmp/bookmark"), decryptApiResponse);
};

export const saveCategory = (category) => {
  return statusAndResponse(
    Api.post("/api/template/v2/save", category),
    decryptApiResponse
  );
};

export const getPractices = () => {
  return statusAndResponse(Api.get("/api/pract/practices"), decryptApiResponse);
};

//TODO: Add service
export const getBookmarkType = () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(["text", "check"]);
    } catch (e) {
      reject(e);
    }
  });
};

//TODO: Add service
export const getBookmarkValueType = () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(["string", "number", "object", "date"]);
    } catch (e) {
      reject(e);
    }
  });
};

export const getFieldsForm = (control) => {
  return statusAndResponse(
    Api.get("api/bks/form/" + control),
    decryptApiResponse
  );
};

export const getSectionType = () => {
  return statusAndResponse(Api.get("/api/bks/sectionType"), decryptApiResponse);
};

export const saveSubsection = (subsection) => {
  return statusAndResponse(
    Api.post("/api/template/v2/items/subsection/save", subsection),
    decryptApiResponse
  );
};

export const bookmarkSave = (bookmarkType, bookmarkData) => {
  return statusAndResponse(
    Api.post("/api/bookmark/v2/items/" + bookmarkType + "/save", bookmarkData),
    decryptApiResponse
  );
};

export const bookmarkSaveAll = (bookmarkData) => {
  return statusAndResponse(
    Api.post("/api/bookmark/v2/items/save/all", bookmarkData),
    decryptApiResponse
  );
};
export const download = (templateId) => {
  return new Promise((resolve, reject) => {
    Api.defaults.headers.get["Content-Type"] = "application/json";
    try {
      Api.get("/api/tmp/" + templateId, { responseType: "blob" })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });
};
