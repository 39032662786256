import React from "react"
import {useLocation} from "react-router-dom"
import {TemplateWrapped} from "../../../../Layout";
import PracticesYear from "../PracticesYear";
import useUpdateRecords from "./hooks/useUpdateRecords";
import LoadExcel from "../LoadExcel";
import {Row} from "react-bootstrap";

const UpdateRecords = () => {

  let {state} = useLocation()
  let carr = []
  let pract = null
  let yr = null
  let toUpdate = false
  if (state) {
    carr = state.carrier ? [state.carrier] : []
    pract = state.practice || null
    yr = state.year || null
    toUpdate = !!(pract && yr)
  }

  const {
    year,
    practice,
    loadExcel,
    showRecords,
    carriers,
    setPractice,
    setYear
  } = useUpdateRecords(carr, pract, yr, toUpdate)

  return (
    <TemplateWrapped
      title='Update Records'
    >
      <div className="panel p-5">
        <div className="">
          <Row>
            <PracticesYear
              setPractice={setPractice}
              setYear={setYear}
              disabled={toUpdate}
            />
            {carriers.length > 0 && (
              <LoadExcel
                selectedYear={year}
                selectedPractice={practice}
                showUpload={false}
                edit={true}
                carriers={carriers}
                toUpdate={toUpdate}
              />
            )}
          </Row>
        </div>
      </div>
    </TemplateWrapped>
  )
}

export default UpdateRecords