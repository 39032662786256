import moment from "moment";
import {TemplateWrapped} from "../../Layout";
import CustomTable from "../../CustomTable";
import useAuditTrait from "./hooks/useAuditTrait";
import {HeaderFilterButton} from "../../RemarkTemplate/Body";
import FilterBots from "./components/FilterBots/FilterBots";
import DateNavigate from "./components/DateNavigate";

const AuditTrait = () => {
  const columns = [
    {title: 'Bot Name', field: 'botName'},
    {title: 'Code', field: 'code'},
    {title: 'Message', field: 'message'},
    {
      title: 'Created at', field: 'createdAt',
      render: ({createdAt}) => <span>{moment(createdAt).format('MM/DD/YYYY')}</span>
    },
  ]
  const {
    practiceIds,
    tableReference,
    dataSource,
    refreshData,
    handleBackStep,
    handleForwardStep,
    date,
    practiceName
  } = useAuditTrait()


  return (
    <TemplateWrapped
      title='Audit Trait'
      headerButtons={
        <>
          <DateNavigate
            handleBackStep={handleBackStep}
            handleForwardStep={handleForwardStep}
            currentDate={date}
          />

          <HeaderFilterButton
            render={FilterBots}
            componentProps={
              {
                setFilters: refreshData,
                practiceId: practiceIds
              }
            }
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <CustomTable
            title={practiceName}
            tableReference={tableReference}
            asyncDataSource={dataSource}
            search={false}
            columns={columns}
            pageSize={25}
          />
        </div>
      </div>
    </TemplateWrapped>
  )
}

export default AuditTrait