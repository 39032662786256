import {useState} from "react";
import {getUrlParams, removeUrlParams, setURLParams} from "../../../../util/browser";
import {withoutEmptyKeys} from "../../../../util/object";
import {paramsClaims} from "./useClaims";

const urlKeys = Object.keys(paramsClaims)

const useFilter = (refreshData, practiceId) => {
  const [filters, setFilters] = useState({
    ...paramsClaims,
    ...getUrlParams(Object.keys(paramsClaims))
  })



  const handleSetFilters = (filterValues) => {
    const removeEmptyKeys = withoutEmptyKeys(filterValues)
    let _filterValues = {...removeEmptyKeys}

    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys)
      setURLParams(_filterValues)
    }

    setFilters({..._filterValues})
    refreshData({..._filterValues, practiceId})

  }

  const handleResetFilters = () => {
    removeUrlParams(urlKeys)
    setFilters({...paramsClaims, practiceId})
    refreshData({...paramsClaims, practiceId})
  }

  return {
    filters,
    handleSetFilters,
    handleResetFilters
  }
}

export default useFilter