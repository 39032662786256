import {TemplateWrapped} from "../Layout";
import CustomTable from "../CustomTable";

const CustomTableList = (
  {
    title,
    columns,
    data = [],
    actions = [],
    pageSize = 10
  }
) => {
  return(
    <TemplateWrapped
      title={title}
    >
      <CustomTable
        columns={columns}
        data={data}
        actions={actions}
        pageSize={pageSize}
      />
    </TemplateWrapped>
  )
}

export default CustomTableList