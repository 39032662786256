import { Fragment, useContext, useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FormSelect as Select } from "../../formControls";
import SelectList from "../../SelectList";
import ButtonList from "../../ButtonList";
import {
  getSections,
  getSubSections,
  getBookmarks,
  addBookmark,
  removeBookmark,
  getSectionType,
} from "../Utils";
import { bookmarkSaveAll } from "../../../services/template";
import { bookmarkAll as bookmarkAllRequest } from "../../Template/Request";
import { BookmarkContext, TemplateContext } from "../context";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const CategoryManager = (props) => {
  const [selects, setSelects] = useState([]);
  const [categoryCatalog, setCategoryCatalog] = useState({});
  const [bookmarkButtons, setBookmarkButtons] = useState([]);
  const [selectedBookmark, setSelectedBookmarks] = useContext(BookmarkContext);
  const {
    bookmarkTemplate,
    selectedCategory,
    selectedSubCategory,
    templateId,
  } = useContext(TemplateContext);

  const handleChange = (e) => {
    let select = getSubSections(bookmarkTemplate, e.target.value);
    let categoryType = getSectionType(bookmarkTemplate, e.target.value);
    setSelects([select]);
    setCategoryCatalog({ id: e.target.value, type: categoryType });
    loadBookmarks(e.target.value);
  };

  const handleChangeSelectList = (e) => {
    loadBookmarks(e.target.value);
  };

  const handleClickBookmarkButtons = async (btns) => {
    let bookmarks = { ...selectedBookmark };
    try {
      if (btns.added) {
        await saveBookmarkSelected(btns);
        bookmarks = addBookmark(
          btns,
          selectedCategory,
          selectedSubCategory,
          selectedBookmark
        );
      } else {
        await removeBookmarkSelected(btns);
        bookmarks = removeBookmark(
          btns,
          selectedBookmark,
          selectedCategory,
          selectedSubCategory
        );
      }
    } catch (e) {
      restoreStateBtn(btns);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Error",
      });
    } finally {
      setSelectedBookmarks(bookmarks);
    }
  };
  const saveBookmarkSelected = async (btn) => {
    let type = selectedSubCategory ? "subsection" : "section";
    let id = selectedSubCategory ? selectedSubCategory : selectedCategory;
    let params = bookmarkAllRequest(
      [btn.id],
      templateId,
      categoryCatalog.id,
      categoryCatalog.type,
      id,
      type
    );
    await bookmarkSaveAll(params);
  };

  const removeBookmarkSelected = async (btn) => {
    //let params = bookmarkAllRequest([btn.id], templateId, selectedCategory, selectedSubCategory);
    let resp = btn; //TODO: Add service to remove
  };

  const restoreStateBtn = (btn) => {
    let currentBtns = [...bookmarkButtons];
    currentBtns.forEach((b) => {
      if (b.id === btn.id) {
        b.added = !btn.added;
      }
    });
    setBookmarkButtons(currentBtns);
  };
  const handleClickAddAll = () => {
    let selectedBtns = { ...selectedBookmark };
    let btnsToAdd = [];
    bookmarkButtons.forEach((btn) => {
      if (btn.added) return;

      btn.added = true;
      btnsToAdd.push(btn.id);
      if (selectedSubCategory) {
        let currentBtnSection =
          (selectedBtns[selectedCategory] || {}).bookmarks || [];
        let currentBtnSubsection =
          ((selectedBtns[selectedCategory] || {})[selectedSubCategory] || [])
            .bookmarks || [];

        Object.assign(selectedBtns, {
          [selectedCategory]: {
            [selectedSubCategory]: {
              bookmarks: [...currentBtnSubsection, btn],
            },
            bookmarks: currentBtnSection,
          },
        });
      } else if (selectedCategory) {
        let currentBtnSection =
          (selectedBtns[selectedCategory] || {}).bookmarks || [];

        Object.assign(selectedBtns, {
          [selectedCategory]: { bookmarks: [...currentBtnSection, btn] },
        });
      } else {
        MySwal.fire({
          icon: "warning",
          title: "Warning",
          text: "Please, select a category or subcategory to load bookmarks.",
        });
      }
    });

    let params = bookmarkAllRequest(
      btnsToAdd,
      templateId,
      selectedCategory,
      selectedSubCategory
    );
    bookmarkSaveAll(params)
      .then(([status, resp]) => {
        setSelectedBookmarks(selectedBtns);
      })
      .catch((e) => {
        //TODO: Handle Error;
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Error",
        });
      });
  };

  const loadBookmarks = (id) => {
    let btns = getBookmarks(bookmarkTemplate, id);
    if (selectedBookmark[selectedCategory]) {
      selectedBookmark[selectedCategory].bookmarks.map((b, index) => {
        btns.forEach((bOrigen) => {
          if (bOrigen.id === b.id) {
            bOrigen.added = b.added;
          }
        });
      });
    } else if (
      (selectedBookmark[selectedCategory] || {})[selectedSubCategory]
    ) {
      selectedBookmark[selectedCategory][selectedSubCategory].bookmarks.map(
        (b) => {
          btns.map((bOrigen) => {
            if (bOrigen.id === b.id) {
              bOrigen.added = b.added;
            }
          });
        }
      );
    }
    setBookmarkButtons(btns);
  };

  useEffect(() => {
    let currentBtns = [...bookmarkButtons];
    let selectedBtns = { ...selectedBookmark };
    let exist = false;
    currentBtns.map((b) => {
      if (selectedBtns[selectedCategory] && !selectedSubCategory) {
        selectedBtns[selectedCategory].bookmarks.map((sb) => {
          if (sb.id === b.id) {
            exist = true;
          }
        });
        b.added = exist;
      } else if (
        selectedSubCategory &&
        (selectedBtns[selectedCategory] || {})[selectedSubCategory]
      ) {
        selectedBtns[selectedCategory][selectedSubCategory].bookmarks.map(
          (ssb) => {
            if (ssb.id === b.id) {
              exist = true;
            }
          }
        );
        b.added = exist;
      } else {
        b.added = exist;
      }
      exist = false;
    });
    setBookmarkButtons(currentBtns);
  }, [selectedCategory, selectedSubCategory]);

  return (
    <Fragment>
      <Row>
        <Col>
          <Select
            id="category"
            options={getSections(bookmarkTemplate)}
            value=""
            label="Categories"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectList data={selects} onChange={handleChangeSelectList} />
        </Col>
      </Row>

      <Row>
        <Col className="twoColums">
          <ButtonList
            data={bookmarkButtons}
            className="twoColums"
            selectAll={false}
            showInactive={true}
            conditionOnclick={!!selectedCategory}
            msgFailCondition="Please, select a category to load bookmarks."
            onClick={handleClickBookmarkButtons}
            removeItemsOnDelete={false}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Button
            variant="primary"
            className="mt-4"
            onClick={handleClickAddAll}
          >
            Add all items
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CategoryManager;
