import React from "react";
import { PracticeItem, PracticeItemContainer } from "./PracticeItem.styles";
import { Check } from "@material-ui/icons";

const PracticesList = (props) => {
  const {
    selectedPractices,
    practicesList,
    handleSetPracticeSelected,
    practiceSelected,
    practiceActive,
  } = props;
  return (
    <PracticeItemContainer>
      <h4 style={{ marginBottom: 10 }}>Select Practice</h4>
      {selectedPractices.length > 0 &&
        selectedPractices.map((practiceItem) => (
          <PracticeItem
            key={practiceItem}
            style={{
              backgroundColor:
                practiceSelected ===
                  practicesList.find(
                    (practice) => practice._id === practiceItem,
                  ).name && "#d9e9ff",
            }}
            onClick={() => {
              handleSetPracticeSelected(
                practicesList.find((practice) => practice._id === practiceItem)
                  .name,
              );
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 10,
              }}
            >
              {
                practicesList.find((practice) => practice._id === practiceItem)
                  .name
              }{" "}
              {practiceActive ===
                practicesList.find((practice) => practice._id === practiceItem)
                  .name &&
                "#d9e9ff" && <Check style={{ marginRight: 10 }} />}
            </span>
          </PracticeItem>
        ))}
    </PracticeItemContainer>
  );
};

export default PracticesList;
