import moment from "moment";
import {displayModalMessage} from "../../../util/displayMessage";

export const comparePatientInfoData = (fbdData, patientInfoData) => {
  const normalizeFields = (...args) => {
    return args.join("").toLowerCase().replace(/\s/g, "");
  };

  const fbdKeys = Object.keys(fbdData);

  if (
    fbdKeys.indexOf("MemberID") === -1 ||
    fbdKeys.indexOf("PrimaryPolicyholderName") === -1 ||
    fbdKeys.indexOf("PrimaryPolicyholderDOB") === -1 ||
    fbdKeys.indexOf("PatientName") === -1 ||
    fbdKeys.indexOf("PatientDOB") === -1
  ) {
    return [];
  }

  const differentFields = [];

  if (
    moment(patientInfoData.SubscriberDOB).diff(
      moment(fbdData.PrimaryPolicyholderDOB)
    )
  ) {
    differentFields.push("Primary Policyholder DOB");
  }

  if (moment(patientInfoData.PatientDOB).diff(moment(fbdData.PatientDOB))) {
    differentFields.push("Patient DOB");
  }

  const toCompare = [
    ["Member Id", [patientInfoData.MemberID], fbdData.MemberID],
    [
      "Primary Policyholder Name",
      [patientInfoData.SubscriberFirstName, patientInfoData.SubscriberLastName],
      fbdData.PrimaryPolicyholderName,
    ],
    [
      "Patient Name",
      [patientInfoData.PatientFirstName, patientInfoData.PatientLastName],
      fbdData.PatientName,
    ],
  ];

  return toCompare.reduce((acum, el) => {
    const [message, pInfo, fbd] = el;

    if (normalizeFields(...pInfo) !== normalizeFields(fbd)) {
      acum.push(message);
    }

    return acum;
  }, differentFields);
};

export const shouldUpdateSpreadsheetDrive = (oldData, newData) => {
  const differences = Object.entries(oldData).reduce((acum, [key, value]) => {
    // Ignore policyType and new fields
    if (typeof newData[key] === "undefined" || key === "policyType") {
      return acum;
    }

    if (newData[key] !== value) {
      return acum + 1;
    }

    return acum;
  }, 0);

  return differences > 0;
};

export const getRecordType = ({type}) => {
  if (typeof  type !== 'string') return ""

  return type.trim().toUpperCase !== "EMPTY"
    ? type.trim().toUpperCase()
    : ""
}

export const getObjectFromState = (state) => {
  return state ? {...state} : {
    id: null,
    practiceName: '',
    practice: '',
    overridePermissions: false
  };
}

export const requiredFields = (data) => {
  let required = [
    "policyType",
    "CarrierName",
    "PatientFirstName",
    "PatientLastName",
    "type"
  ];

  let labels = [
    "Policy Type",
    "Carrier name",
    "Patient firstName",
    "Patient lastName",
    "Type"
  ];

  const isELG = data.type === 'ELG'

  if (isELG) {
    required.shift()
    labels.shift()
  }

  return required.reduce((a, el, i) => {
    const dataElement = data[el] || '';

    if (!data[el] || dataElement ==='EMPTY') {
      a.push(labels[i]);
    }
    return a;
  }, []);

}

export const validateRequiredFields = (missing, msg, extraValidation = true) => {
  if (missing.length > 0 && extraValidation) {
    displayModalMessage(
      msg,
      <p>
        Dear user, the following{" "}
        {missing.length > 1 ? " fields are " : " field is "} required:{" "}
        <strong>{missing.join(", ")}</strong>
      </p>
    );
    return false
  }

  return true
}
