import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import shortid from "shortid";
import UserHelp from "../../../util/UserHelp";

/* (newData) => {
            // All items are on the data
            const intersectedElements = newData.filter((value) =>
              item.items.includes(value)
            );


            // Remove parent element from the list
            if (intersectedElements.length !== item.items.length) {
              selectedDataHandler(
                newData.filter((x) => !compareOptions(x, item))
              );
            } else {
              // Just update the new data, with the new
              selectedDataHandler([...newData, item]);
            }
          } */

function removeElementsFromArray(array, elementsToRemove) {
  for (var i = elementsToRemove.length - 1; i >= 0; i--)
    array.splice(elementsToRemove[i], 1);

  return array;
}

function compareOptions(a, b) {
  return a && b && a.id === b.id && a.label === b.label;
}

function HeaderText({ currentValue, onChange }) {
  return (
    <>
      <h5>When this value is typed</h5>
      <div className="mb-3">
        <Form.Control
          value={currentValue}
          onChange={({ target: { value } }) => onChange(value)}
        />
      </div>
    </>
  );
}

function GetOption(item, inline, type, selectedValues, onChange, required) {
  return (
    <Form.Group
      className={`${inline ? "d-inline-block" : ""} mr-3 mb-0`}
      key={item.id}
    >
      <Form.Label className="mb-0">
        <Form.Check
          required={required}
          inline
          className="mr-0"
          checked={
            selectedValues.filter((x) => compareOptions(x, item)).length > 0
          }
          name="options"
          type={type}
          value={item.id}
          onChange={({ target: { checked } }) => onChange(item, checked)}
        />
        {item.label}
      </Form.Label>
    </Form.Group>
  );
}

function HeaderSelect({ selectedValues, options, onChange }) {
  return (
    <>
      <h5>When this option is selected</h5>
      <div className="mb-3">
        {options.map((item) =>
          GetOption(item, true, "radio", [selectedValues], onChange, true)
        )}
      </div>
    </>
  );
}

function OptionsGroup({
  source,
  selectedData,
  selectedDataHandler,
  inline = false,
  parentIsSelectable = true,
}) {
  const [onlyParent, setOnlyParent] = useState(parentIsSelectable);

  useEffect(() => {
    // Check if any child is selected, at the initial
    if (source.items) {
      const intersectedElements = selectedData.filter((value) =>
        source.items.includes(value)
      );

      setOnlyParent(intersectedElements.length === 0);
    }
  }, []);

  if (!source.items)
    return GetOption(
      source,
      inline,
      "checkbox",
      selectedData,
      (selected, checked) => {
        if (!checked) {
          selectedDataHandler(
            selectedData.filter((x) => !compareOptions(x, selected))
          );
        } else {
          selectedDataHandler([...selectedData, selected]);
        }
      }
    );

  return (
    <div>
      {onlyParent &&
        GetOption(
          source,
          true,
          "checkbox",
          selectedData,
          (selected, checked) => {
            if (!checked) {
              selectedDataHandler(
                selectedData.filter((x) => !compareOptions(x, selected))
              );
            } else {
              selectedDataHandler([...selectedData, selected]);
            }
          }
        )}
      {!onlyParent && <span key={source.id}>{source.label}</span>}
      <div className="d-inline-block ml-2">
        {/* <Form.Group className="d-inline-block mr-3 mb-0">
          <Form.Label className="mb-0">
            <Form.Check
              required
              inline
              className="mr-0"
              checked={onlyParent}
              name={`parent-${source.id}`}
              type="radio"
              value={onlyParent}
              onChange={
                () => {
                  setOnlyParent(true);
                  const newData = selectedData.reduce((a, i) => {
                    const found = source.items.filter((x) =>
                      compareOptions(x, i)
                    );
                    if (found.length === 0) a.push(i);
                    return a;
                  }, []);
                  selectedDataHandler(newData);
                }
                //onChange(item, checked)
              }
            />
            Control
          </Form.Label>
        </Form.Group> */}
        {/* <Form.Group className="d-inline-block mr-3 mb-0">
          <Form.Label className="mb-0">
            <Form.Check
              required
              inline
              className="mr-0"
              checked={!onlyParent}
              name={`parent-${source.id}`}
              type="radio"
              value={!onlyParent}
              onChange={
                () => {
                  selectedDataHandler(
                    selectedData.filter((x) => !compareOptions(x, source))
                  );
                  setOnlyParent(false);
                }
                //onChange(item, checked)
              }
            />
            Options
          </Form.Label>
        </Form.Group> */}
      </div>
      {!onlyParent &&
        source.items.map((item) => (
          <div key={item.id}>
            <div className="ml-3">
              {GetOption(
                item,
                false,
                "checkbox",
                selectedData,
                (selected, checked) => {
                  if (!checked) {
                    selectedDataHandler(
                      selectedData.filter((x) => !compareOptions(x, selected))
                    );
                  } else {
                    selectedDataHandler([...selectedData, selected]);
                  }
                }
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

export default function BookmarkAffects({
  value,
  action = "",
  affecting = [],
  affectingBookmarks = [],
  actions = [],
  affectTypes = [],
  bookmarkType = "",
  items = [],
  bookmarks = [],
  onSubmit = (data) => {},
}) {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedAction, setSelectedAction] = useState(action);
  const [affectedSelfValues, setAffectedSelfValues] = useState(affecting);
  const [affectedBookmarks, setAffectedBookmarks] =
    useState(affectingBookmarks);

  const isACheckBM = bookmarkType === "checkbox";

  function handleSubmit(ev) {
    ev.preventDefault();
    onSubmit({
      actions: [selectedAction],
      whenValueContains: [
        typeof selectedValue === "string" ? selectedValue : selectedValue["id"],
      ],
      destination: [...affectedSelfValues, ...affectedBookmarks],
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-4 mt-2">
            {isACheckBM ? (
              <HeaderSelect
                selectedValues={selectedValue}
                options={items}
                onChange={(value) => {
                  // Only one value at the time
                  setAffectedSelfValues([]);
                  setSelectedValue(value);
                }}
              />
            ) : (
              <HeaderText
                currentValue={selectedValue}
                onChange={setSelectedValue}
              />
            )}
          </div>
          <hr className="m-0" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex my-4">
            <h5 className="mr-2">I want to</h5>
            <Form.Control
              required
              value={selectedAction}
              className="d-inline-block w-auto"
              as="select"
              onChange={({ target: { value } }) => setSelectedAction(value)}
            >
              <option disabled value="">
                Please select an option...
              </option>
              {actions.map((i, index) => (
                <option key={index} value={i.action}>
                  {i.label}
                </option>
              ))}
            </Form.Control>
          </div>
          <hr className="m-0" />
        </div>
      </div>
      <div className="row">
        {isACheckBM && (
          <div className="col-md-12 d-none">
            <div className="my-3">
              <h5>Those controls</h5>
              <div className="mb-3">
                {!selectedValue && (
                  <UserHelp>
                    Dear user, please select an option at "
                    <strong>When this option is selected</strong>"
                  </UserHelp>
                )}
                {selectedValue &&
                  items
                    .filter((x) => !compareOptions(x, selectedValue))
                    .map((item, i) => (
                      <OptionsGroup
                        key={i}
                        source={item}
                        selectedData={affectedSelfValues}
                        selectedDataHandler={setAffectedSelfValues}
                      />
                    ))}
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="my-3">
            <h5>Those bookmarks</h5>
            <div className="mb-3">
              {bookmarks.map((item, index) => (
                <OptionsGroup
                  key={index}
                  source={item}
                  selectedData={affectedBookmarks}
                  selectedDataHandler={setAffectedBookmarks}
                  inline={false}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Button
          type="submit"
          variant="success btnForm btn-action"
          size="md"
          block
        >
          Save
        </Button>
      </div>
    </form>
  );
}
