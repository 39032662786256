import CustomTable from "../../../CustomTable";

const ClientNSTable = ({data, actions}) => {
  const columns = [
    {title: 'Client', field: 'name'},
    {title: 'NS', field: 'key'},
    {
      title: 'Date created / User Created',
      render:({dateCreated, userCreated}) => {
        return(<span>{dateCreated} - {userCreated}</span>)
      }
    },
    {
      title: 'Date updated / User updated',
      render:({dateUpdated, userUpdated}) => {
        return(<span>{dateUpdated} - {userUpdated}</span>)
      }
    }
  ]
  return(
    <CustomTable
      actions={actions}
      data={data}
      columns={columns}
    />
  )
}

export default ClientNSTable