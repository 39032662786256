import {statusAndResponse} from "../../../../../util/requestHandler";
import {Api, decryptApiResponse} from "../../../../../config/Api";

export function addIssue(data) {
  return statusAndResponse(
    Api.post("/api/ticket/", data),
    decryptApiResponse
  );
}

export function getIssuesByTransactionId(obj) {

  return statusAndResponse(
    Api.get(`/api/ticket/`, {params: obj}),
    decryptApiResponse
  );
}

export function getIssueById(id) {
  return statusAndResponse(
    Api.get(`/api/ticket/${id}`),
    decryptApiResponse
  );
}

export function addIssueComment(message, id) {
  return statusAndResponse(
    Api.post(`/api/ticket/response/${id}`, {message}),
    decryptApiResponse
  );
}