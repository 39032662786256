import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {toast} from "react-toastify";
import {AppContext, loadMenuAndPermissions} from "../../../App";
import * as auth from "../../../util/auth";
import {getBrowserInformation} from "../../../util/browser";
import {displayModalMessage} from "../../../util/displayMessage";
import CustomModal from "../../CustomModal";
import useEmailReset from "./hooks/useEmailReset";
import {LoginForm} from "./LoginForm";
import {ResetPasswordForm} from "./ResetPasswordForm";
import * as service from "./services";
import {TwoStepForm} from "./TwoStepForm";
import {getLoggedInData} from "../../../util/auth";
import CurrentYear from "../../helper/CurrentYear";

const BlockedAccountMessage = (
  {
    emailStatus,
    waiting = false,
    reSendEmail = () => {
    },
  }
  ) => {
  return (
    <>
      <p>
        Dear user, your account is blocked, Please check your e-mail to reset
        your password and unblock your account.
      </p>
      <button
        disabled={waiting}
        type="button"
        className="btn btn-link"
        onClick={reSendEmail}
      >
        Send the e-mail again{" "}
        {waiting && <FontAwesomeIcon icon="spinner" spin/>}
      </button>
    </>
  );
};

function LoginWrapper(props) {
  return (
    <div
      className="page"
      data-animsition-in="fade-in"
      data-animsition-out="fade-out"
    >
      <div className="page-content">
        <div className="page-brand-info">
          <div className="brand">
            <img className="brand-img" src="/logo-big.png" alt="..."/>
          </div>
          <p className="font-size-20"></p>
        </div>

        <div className="page-login-main animation-slide-right animation-duration-1">
          <div className="brand hidden-md-up">
            <img className="brand-img" src="/logo3.png" alt="..."/>
          </div>
          <h3 className="font-size-24">Sign In</h3>
          <p></p>
          {props.children}
          <footer className="page-copyright">
            <p>Dental Robot</p>
            <p><CurrentYear />. All RIGHT RESERVED.</p>
          </footer>
        </div>
      </div>
    </div>
  );
}

function useLogin(onSuccess) {
  const [showModal, setShowModal] = useState(false);

  //Flags
  const [waiting, setWaiting] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");

  const [userData, setUserData] = useState(false);
  const email = useEmailReset();

  function onPasswordReset() {
    setShowModal(true);
  }

  const submitWithPing = ({pin}) => {
    setWaiting(true);
    service.authenticate({...userData, pin}).then(([status, resp]) => {
      if (status) {
        onSuccess(resp);
      } else {
        setWaiting(false);
      }
    });
  };

  const reSendEmail = () => {
    email.setEmail(userName);
  };

  function requirePingSubmit({username, password}) {
    setWaiting(true);

    const {
      browser,
      browserMajorVersion: browserVersion,
      mobile,
      os,
      osVersion,
    } = getBrowserInformation();

    const data = {
      username,
      password,
      browser,
      browserVersion,
      deviceType: mobile ? "Mobile" : "Computer",
      deviceOs: os + " " + osVersion,
    };

    service.requirePing(data).then(([status, r]) => {
      setWaiting(false);
      if (!status) {
        if (r === "Blocked") {
          displayModalMessage(
            "Warning",
            <BlockedAccountMessage
              emailStatus={email}
              reSendEmail={reSendEmail}
            />
          );
          return;
        }

        if (r === "Not acceptable country") {
          displayModalMessage(
            "Warning",
            "Dear user, we are sorry... This application is not available in your country"
          );
          return;
        }

        // Anything else ...
        toast.error("User not found !");
        return;
      }

      if (r.exceeded) {
        displayModalMessage(
          "Advice",
          "Dear user, you have exceded the limit of PIN's request. We have sent you an e-mail with the verification code."
        );
      }
      setUserData({username, password});
    });
  }

  useEffect(() => {
    if (!email.waiting && email.done) {
      if (email.error) {
        toast.error("Please provide a valid e-mail");
        return;
      }
      if (email.success) {
        setShowModal(false);
        toast.success("Dear user, please check your email");
      } else {
        toast.error("Please try again");
      }
    }
  }, [email.done, email.error, email.waiting, email.success]);

  return {
    waiting,
    setPassword,
    setUserName,
    userName,
    password,
    requirePingSubmit,
    onPasswordReset,
    userData,
    showModal,
    setUserData,
    redirectTo,
    pin,
    setPin,
    submitWithPing,
    setShowModal,
    email,
    setRedirectTo,
  };
}

const Login = () => {
  const ctx = useContext(AppContext);
  const {
    waiting,
    setPassword,
    setUserName,
    userName,
    password,
    requirePingSubmit,
    onPasswordReset,
    userData,
    showModal,
    setUserData,
    redirectTo,
    pin,
    setPin,
    submitWithPing,
    setShowModal,
    setRedirectTo,
    email,
  } = useLogin((resp) => {
    auth.login(resp.token, resp.value).then(() => {
      loadMenuAndPermissions().then((x) => {
        if (x) {
          const {setting: {transaction}} = getLoggedInData()
          const sort = transaction ? transaction.sort : {}
          const priority = transaction ? transaction.elgPriority : []

          ctx.set('sortConfiguration', {transaction: {...sort, priority: priority}})
          ctx.set("userId", null);
          ctx.set("practiceId", null);
          ctx.set("practiceName", null);
          ctx.set("selectedPractice", null);
          ctx.set("practices", x.practices);
          setRedirectTo(auth.getDefaultPath());
        }
      });
    });
  });

  useEffect(() => {
    const classes = "animsition page-login-v2 layout-full page-dark p-0".split(
      " "
    );
    classes.forEach((i) => {
      document.body.classList.add(i);
    });
    return function () {
      classes.forEach((i) => {
        document.body.classList.remove(i);
      });
    };
  }, []);

  useEffect(() => {
    auth.logout()
  }, [])

  // Was successfully logged in
  if (redirectTo) {
    return <Navigate to={redirectTo}/>;
  }

  return (
    <LoginWrapper>
      {userData === false && (
        <LoginForm
          waiting={waiting}
          setPassword={setPassword}
          setUserName={setUserName}
          userName={userName}
          password={password}
          onSubmit={requirePingSubmit}
          onPasswordReset={onPasswordReset}
        />
      )}
      {userData && (
        <TwoStepForm
          waiting={waiting}
          pin={pin}
          setPin={setPin}
          onSubmit={submitWithPing}
          cancel={() => {
            setUserData(false);
          }}
          resendCode={() => {
            requirePingSubmit(userData);
          }}
        />
      )}
      {showModal && (
        <CustomModal
          backdrop={true}
          show={showModal}
          onHide={() => setShowModal(false)}
          header={false}
          size="md"
        >
          <ResetPasswordForm
            setShowModal={setShowModal}
            onSubmit={email.setEmail}
            waiting={email.waiting}
          />
        </CustomModal>
      )}
    </LoginWrapper>
  );
};

export function SimpleLoginForm({onSuccess}) {
  const {
    waiting,
    setPassword,
    setUserName,
    userName,
    password,
    requirePingSubmit,
    onPasswordReset,
    userData,
    showModal,
    setUserData,
    redirectTo,
    pin,
    setPin,
    submitWithPing,
    setShowModal,
    email,
  } = useLogin(onSuccess);

  return (
    <>
      {userData === false && (
        <LoginForm
          waiting={waiting}
          setPassword={setPassword}
          setUserName={setUserName}
          userName={userName}
          password={password}
          onSubmit={requirePingSubmit}
          onPasswordReset={onPasswordReset}
        />
      )}
      {userData && (
        <TwoStepForm
          waiting={waiting}
          pin={pin}
          setPin={setPin}
          onSubmit={submitWithPing}
          cancel={() => {
            setUserData(false);
          }}
          resendCode={() => {
            requirePingSubmit(userData);
          }}
        />
      )}
      {showModal && (
        // <CustomModal
        //   backdrop={true}
        //   show={showModal}
        //   onHide={() => setShowModal(false)}
        //   header={false}
        //   size="md"
        // >
        <ResetPasswordForm
          setShowModal={setShowModal}
          onSubmit={email.setEmail}
          waiting={email.waiting}
        />
        // </CustomModal>
      )}
    </>
  );
}

export default Login;
