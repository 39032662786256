import { Accordion } from "react-bootstrap";
import CategoryWrapper from "./CategoryWrapper";

export default function CategoryList({
  keys = 0,
  parentCategory,
  data,
  canCreateSubcategories,
  ...props
}) {
  return (
    <Accordion key={keys} {...props}>
      {data.map((category, index) => (
        <CategoryWrapper
          key={category.id}
          parentCategory={parentCategory}
          canCreateSubcategories={canCreateSubcategories}
          index={index}
          category={category}
        />
      ))}
    </Accordion>
  );
}
