export function getClientFromPracticesSelected(practiceId, practices) {
  const _practices = practices
    .filter((p) => p._id === practiceId)
    .reduce((acc, cur) => {
      return {...acc, ...cur}
    }, {})

  return {
    clientId: _practices.clientId,
    clientName: _practices.client ? _practices.client[0].name : ''
  }
}