import { useCallback, useEffect, useState } from "react";
import * as services from "../../../../services/missingCode";
import Swal from "sweetalert2";
import {
  getRecordType,
  requiredFields,
  shouldUpdateSpreadsheetDrive,
  validateRequiredFields,
} from "../../TransactionDetail/TransactionDetail.utils";
import { toast } from "react-toastify";
import {
  createAndUpdateFbdFile,
  createVerification,
  getCarriers,
  getPhoneFromCarrier,
  getTransactionBookmarks,
  updatePatientInfo,
  updateVerification,
} from "../services/callCenter.service";
import { displayModalConfirmation } from "../../../../util/displayMessage";
import { AuditQuestion } from "../../TransactionDetail/components/ModalMessages";
import usePractices from "../../../../hooks/usePractices";
import moment from "moment/moment";
import { getInitialAndEndDate } from "../../../../util/date";
import withReactContent from "sweetalert2-react-content";

const initialFormVerification = {
  clientId: "",
  bookmarks: {},
  eligible: [],
  coverageDateStart: new Date(),
  coverageDateEnd: new Date(),
  notes: "",
};
const TYPE = "ELG";

export const useCallCenter = (itemVerification, setAction) => {
  const [data, setData] = useState();
  const [record, setRecord] = useState();
  const [hasPolicy, setHasPolicy] = useState(false);
  const [shouldUpdateVerification, setShouldUpdateVerification] =
    useState(false);
  const [practiceInfoAndBookmarks, setPracticeInfoAndBookmarks] =
    useState(null);
  const [formVerification, setFormVerification] = useState({
    ...initialFormVerification,
    ...(d) => {
      return {
        ...d,
        coverageDateStart:
          d.coverageDateStart &&
          moment(d.coverageDateStart, "yyyy/MM/DD").toDate(),
        coverageDateEnd:
          d.coverageDateEnd && moment(d.coverageDateEnd, "yyyy/MM/DD").toDate(),
      };
    },
  });
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingAuditButton, setIsLoadingAuditButton] = useState(false);
  const [isLoadingPhone, setIsLoadingPhone] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const { _id: id, practiceId } = itemVerification;
  const { getClientId } = usePractices();
  const MySwal = withReactContent(Swal);
  const formatsAllowedInCovDate = [
    "yyyy/MM/DD",
    "yyyy-MM-DD",
    "MM/DD/YYYY",
    "MM-DD-YYYY",
    "DD/MM/YYYY",
    "DD-MM-YYYY",
  ];
  let isElgToFbd = false;
  function setInitialDate(date) {
    const [startDate, endDate] = getInitialAndEndDate(
      date,
      formVerification.coverageDateEnd,
    );
    return setFormVerification({
      ...formVerification,
      coverageDateStart: startDate,
      coverageDateEnd: endDate,
    });
  }

  function setEndDate(date) {
    const [startDate, endDate] = getInitialAndEndDate(
      formVerification.coverageDateStart,
      date,
    );
    return setFormVerification({
      ...formVerification,
      coverageDateStart: startDate,
      coverageDateEnd: endDate,
    });
  }

  function setDataDates(field, value) {
    if (field === "coverageDateStart") {
      return setInitialDate(value);
    }
    if (field === "coverageDateEnd") {
      return setEndDate(value);
    }
  }

  const loadRecordData = useCallback(async () => {
    services.getTransaction(id, practiceId).then(([s, r]) => {
      if (s) {
        setRecord({
          ...r,
          type: getRecordType(r),
        });
      }
    });
  }, [id, practiceId]);

  const loadSpreadSheet = useCallback(async () => {
    services.getSpreadsheet(id).then(([status, d]) => {
      if (status) {
        setData({ ...d, type: getRecordType(d) });
        setHasPolicy(!!d.policyType);
      }
    });
  }, [id, practiceId]);

  const loadPracticeInfo = useCallback(async () => {
    services.getPracticeInfoAndBookmarks(practiceId).then(([status, data]) => {
      if (status) {
        setPracticeInfoAndBookmarks(data.bookmarks);
        constraintInitialBookmarkForm(data.bookmarks);
      }
    });
  }, [id, practiceId]);

  const loadBookmarkBaseClient = useCallback(
    async (bookmarksBase) => {
      getTransactionBookmarks(id)
        .then(([status, data]) => {
          if (status && (data.length > 0 || data.hasOwnProperty("bookmarks")))
            setValuesInBookmarkBaseFromBookmarksCliente(bookmarksBase, data);
          else {
            setShouldUpdateVerification(false);
            setIsLoadingButton(false);
          }
        })
        .catch((error) => {
          setShouldUpdateVerification(false);
          setIsLoadingButton(false);
        });
    },
    [id, practiceId],
  );

  function setValuesInBookmarkBaseFromBookmarksCliente(data, bookmarksClient) {
    for (let key in data) {
      if (bookmarksClient.bookmarks.hasOwnProperty(key)) {
        data[key] = bookmarksClient.bookmarks[key];
      }
    }

    setValueToCoverageDate(bookmarksClient);

    setFormVerification({
      ...formVerification,
      bookmarks: data,
      coverageDateStart: formVerification.coverageDateStart,
      coverageDateEnd: formVerification.coverageDateEnd,
      eligible: bookmarksClient.eligible ?? [],
      notes: bookmarksClient.notes ?? "",
    });
  }

  function setValueToCoverageDate(bookmarksClient) {
    if (bookmarksClient.bookmarks.hasOwnProperty("BenefitPeriod")) {
      const benefitPeriodSplited =
        bookmarksClient.bookmarks.BenefitPeriod.split(" - ");

      if (
        benefitPeriodSplited.length > 0 &&
        verifyAllowedFormatDate(benefitPeriodSplited[0])
      )
        formVerification.coverageDateStart = moment(
          benefitPeriodSplited[0],
          "YYYY-MM-DD HH:mm:ss",
        )
          .set({
            hour: moment().hours(),
            minute: moment().minutes(),
            second: moment().seconds(),
          })
          .toDate();

      if (
        benefitPeriodSplited.length > 1 &&
        verifyAllowedFormatDate(benefitPeriodSplited[1])
      )
        formVerification.coverageDateStart = moment(
          benefitPeriodSplited[1],
          "YYYY-MM-DD HH:mm:ss",
        )
          .set({
            hour: moment().hours(),
            minute: moment().minutes(),
            second: moment().seconds(),
          })
          .toDate();
    }
  }

  function verifyAllowedFormatDate(date) {
    let isValid = false;
    formatsAllowedInCovDate.forEach((format) => {
      let isValidDate = moment(date, format);
      if (isValidDate.isValid()) {
        isValid = true;
        return true;
      }
      return false;
    });
    return isValid;
  }

  async function constraintInitialBookmarkForm(data) {
    const initialValuesFromResponse = {};
    data.forEach((itemCategory) => {
      itemCategory.items.forEach((item) => {
        item.bookmarks.forEach((itemBookmark) => {
          initialValuesFromResponse[itemBookmark.bookmark] = "";
        });
      });
    });

    setFormVerification({
      ...formVerification,
      bookmarks: initialValuesFromResponse,
    });

    await loadBookmarkBaseClient(initialValuesFromResponse);
  }

  useEffect(() => {
    async function getCarriersList() {
      await getCarriers().then(([success, value]) => {
        if (success) {
          setCarriers(value);
          setSelectedCarrier(value[0]._id);
          updatePhoneState(value[0]._id);
          return;
        }
        toast.error("Carriers list not found.");
      });
    }
    getCarriersList();
  }, []);

  useEffect(() => {
    if (id) {
      loadRecordData();
      loadSpreadSheet();
      loadPracticeInfo();
    }
  }, [id, loadRecordData]);

  async function onUpdatePatienInfo(oldData, data) {
    setIsLoadingButton(true);
    const auxData = {
      policyType: null,
      ...data,
      updateSpreadsheetDrive: shouldUpdateSpreadsheetDrive(oldData, data),
    };

    const missing = requiredFields(auxData);

    if (validateRequiredFields(missing, "Cannot update")) {
      updatePatientInfo({
        ...auxData,
        practiceId,
        apptDateType: data.ApptDate + "T00:00:00.000Z",
      }).then(([status, _r]) => {
        if (!status) {
          setIsLoadingButton(false);
          return;
        }
        setHasPolicy(true);
        loadRecordData();
        loadSpreadSheet();
        setAction(true);
        setIsLoadingButton(false);
        toast.success("Spreadsheet saved");
      });
    }
  }

  const getMissingFiles = async () => {
    const [status, missingFilesObj] = await services.checkFiles(
      getClientId(practiceId),
      id,
    );

    if (!status) throw new Error("Could not check files");

    return Object.entries(missingFilesObj).reduce((acum, [key, fileExists]) => {
      if (!fileExists) {
        acum.push(key);
      }
      return acum;
    }, []);
  };

  const performAudit = (doAudit, label) => {
    services
      .verifyTransaction(record, practiceId, doAudit)
      .then(([status, _r]) => {
        if (!status) {
          setIsLoadingAuditButton(false);
          return;
        }
        loadRecordData();
        setIsLoadingAuditButton(false);
        toast.success(`The transaction was set as ${label}`);
        setAction(true);
      });
  };

  const audit = async () => {
    const { audited = false } = record;

    const missing = requiredFields(data);

    if (validateRequiredFields(missing, "Cannot Audit", !audited)) {
      setIsLoadingAuditButton(true);
      const doAudit = !record.audited;
      const label = doAudit ? "verified" : "not verified";

      let missingFiles = [];
      const missingFields =
        doAudit && record.type === "FBD" && record.uploadFBD
          ? record.missingRequiredInputQty
          : 0;

      if (doAudit) {
        try {
          missingFiles = await getMissingFiles();
        } catch (error) {
          setIsLoadingAuditButton(false);
          return;
        }
      }

      displayModalConfirmation(
        "Confirmation",
        <AuditQuestion
          missingFiles={missingFiles}
          missingFieldsQty={missingFields}
          label={label}
        />,
        () => {
          setIsLoadingAuditButton(true);
          performAudit(doAudit, label);
        },
        () => {
          setIsLoadingAuditButton(false);
        },
      );
    }
  };

  function handleChangeVerification({ target }, type = 1, code) {
    let value = target.value;
    if (type === 2) {
      let eligibles = [...formVerification.eligible];
      if (value === "E") eligibles.push(code);
      else eligibles = eligibles.filter((item) => item !== code);

      setFormVerification({ ...formVerification, eligible: eligibles });
      return;
    }
    setFormVerification({ ...formVerification, [target.name]: value });
  }

  function handleChangeBookmarks({ target }) {
    const { bookmarks } = formVerification;
    bookmarks[target.name] = target.value;

    setFormVerification({ ...formVerification, bookmarks });
  }

  async function handleSubmitVerification(e) {
    e.preventDefault();
    if (record?.type === TYPE) {
      await confirmUpdateElgToFbd();
      return;
    }
    await sendVerification();
  }

  async function sendVerification() {
    setIsLoadingButton(true);
    await createVerification(
      contraintRequestSaveVerification(),
      itemVerification._id,
    )
      .then(([status, data]) => {
        if (status && data) {
          create_update_fbd_file();
        }
      })
      .catch(() => {
        setIsLoadingButton(false);
      });
  }

  async function create_update_fbd_file() {
    await createAndUpdateFbdFile(
      { clientId: getClientId(practiceId) },
      itemVerification._id,
    )
      .then(([status, data]) => {
        if (status) {
          setIsLoadingButton(false);
          toast.success(data.message);
        } else setIsLoadingButton(false);

        if (status && isElgToFbd) {
          loadRecordData();
          setAction(true);
        }
      })
      .catch(() => {
        setIsLoadingButton(false);
      });
  }

  async function confirmUpdateElgToFbd() {
    MySwal.fire({
      title:
        "A change of type from ELG to FBD is going to be made, you want to continue?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        isElgToFbd = true;
        sendVerification();
      }
    });
  }

  function contraintRequestSaveVerification() {
    const { coverageDateStart, coverageDateEnd } = formVerification;
    const copyFormVerification = JSON.parse(JSON.stringify(formVerification));
    copyFormVerification.bookmarks.BenefitPeriod =
      moment(coverageDateStart).format("YYYY/MM/DD") +
      " - " +
      moment(coverageDateEnd).format("YYYY/MM/DD");
    copyFormVerification.clientId = getClientId(practiceId);
    deleteEmptyKeysInObject(copyFormVerification.bookmarks);
    deleteDatesPropertysInObject(copyFormVerification);
    return copyFormVerification;
  }

  function deleteEmptyKeysInObject(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key) && data[key] === "") {
        delete data[key];
      }
    }
  }

  function deleteDatesPropertysInObject(data) {
    delete data.coverageDateStart;
    delete data.coverageDateEnd;
  }

  function constraintRequestVerification() {
    const {
      userCreated,
      annual,
      remaining,
      referenceNumber,
      coverageDateEnd,
      coverageDateStart,
      notes,
    } = formVerification;

    return {
      transactionId: itemVerification._id,
      userCreated,
      annual,
      remaining,
      referenceNumber,
      coverageDateEnd: moment(coverageDateEnd).format("YYYY-MM-DD"),
      coverageDateStart: moment(coverageDateStart).format("YYYY-MM-DD"),
      notes,
      items: constraintItemsProperty(),
    };
  }

  function constraintItemsProperty() {
    const itemsProperty = [];
    let bookmarksProperty = [];

    practiceInfoAndBookmarks?.bookmarks?.forEach((bookmark) => {
      bookmarksProperty = [];
      bookmark?.items?.forEach((item) => {
        bookmarksProperty.push(constraintBookmarksProperty(item));
      });
      itemsProperty.push({
        categoryId: bookmark.category,
        bookmarks: bookmarksProperty,
      });
    });
    return itemsProperty;
  }

  function constraintBookmarksProperty(item) {
    return {
      code: item.code,
      elegible: formVerification["elegible_" + item.code],
      frecuency: formVerification["frecuency_" + item.code],
      history: formVerification["history_" + item.code],
    };
  }

  async function updateVerificationById(e) {
    e.preventDefault();
    setIsLoadingButton(true);
    await updateVerification(verificationId, {
      ...constraintRequestVerification(),
      userUpdate: formVerification.userCreated,
    })
      .then((response) => {
        toast.success(response[1]);
        setIsLoadingButton(false);
      })
      .catch(() => {
        setIsLoadingButton(false);
      });
  }

  async function handleChangeCarrier(value) {
    setSelectedCarrier(value);
    await updatePhoneState(value);
  }

  async function updatePhoneState(carrierId) {
    setIsLoadingPhone(true);
    await getPhoneFromCarrier(carrierId).then(([success, value]) => {
      if (success) {
        const { profile } = value;
        setSelectedPhone(profile?.phoneNumber);
        setIsLoadingPhone(false);
        return;
      }
      toast.error("Phone number not found, please try again.");
      setIsLoadingPhone(false);
    });
  }

  return {
    data,
    record,
    hasPolicy,
    onUpdatePatienInfo,
    loadRecordData,
    audit,
    practiceInfoAndBookmarks,
    handleChangeVerification,
    handleSubmitVerification,
    formVerification,
    setDataDates,
    isLoadingButton,
    shouldUpdateVerification,
    isLoadingAuditButton,
    updateVerificationById,
    selectedPhone,
    handleChangeCarrier,
    selectedCarrier,
    carriers,
    isLoadingPhone,
    handleChangeBookmarks,
  };
};
