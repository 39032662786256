import _ from 'lodash';

export function chunk(data, chunkSize) {
  let result = [];
  for (let i = 0; i < data.length; i += chunkSize)
    result.push(data.slice(i, i + chunkSize));
  return result;
}


export const groupByParentId = (array, parent, tree, key = null, indicateParent = false, excludeNode = null) => {
  tree = typeof tree !== 'undefined' ? tree : []
  parent = typeof parent !== 'undefined' ? parent : {_id: null}

  const children = _.filter(array, (child) => child.parentId === parent._id)

  if (!_.isEmpty(children)) {
    if (parent._id === null) {
      tree = children
    } else {
      if (excludeNode) {
        parent['items'] = children.filter(x => x.type !== excludeNode)
      } else {
        parent['items'] = children
      }
      if (key) {
        parent[key] = children
      }
      if (indicateParent && parent.items.filter(p => p.type !== 'Action' && p.showInMenu).length > 0) {
        parent['isParent'] = true
      }
    }

    _.each(children, (child) => groupByParentId(array, child, tree, key, indicateParent, excludeNode))
  }

  return tree
}

export const groupByKey = (array, key) => {
  return _.groupBy(array, key)
}

export const findIndexByProperty = (arrayOfObjects, propertyToSearch, valueToSearch) => {
  return arrayOfObjects.findIndex(item => item[propertyToSearch] === valueToSearch)
}

export const sortArrayOfObjectsByStringKey = (arr, key) => {
  return arr.sort((a, b) => {
    const firstValue = a[key].toLowerCase();
    const secondValue = b[key].toLowerCase();

    if (firstValue < secondValue) return -1;
    if (firstValue > secondValue) return 1;

    return 0
  })
}
