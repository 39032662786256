export function LogoSmall({
  width = 50,
  heigth = 50,
  fill = "#8d8d8d",
  className,
}) {
  return (
    <svg
      className={className}
      version="1.1"
      fill={fill}
      height={50}
      width={300}
      x="0px"
      y="0px"
      viewBox="0 0 307 38"
    >
      <path
        class="st0"
        d="M 17 4.7 C 16.6 5.2 16.4 5.9 16.4 6.5 L 16.4 11.2 C 16.8 11.1 17.3 11.1 17.8 11.1 C 26.9 11.1 34.3 18.3 34.3 27.3 C 34.3 30.7 33.3 33.8 31.5 36.4 C 29.1 36.8 26.6 37.2 24 37.4 C 24.4 36.3 24.6 35.1 24.6 33.9 C 24.6 32.3 24.4 31.4 24.3 30.7 C 24.3 30.3 24.2 29.9 24.2 29.6 C 24.2 29.3 24.5 28.9 24.8 28.3 C 25.5 27.1 26.5 25.2 26.6 22.8 L 26.6 22.3 C 26.5 20.1 24.6 18.3 22.4 18.3 L 22.2 18.3 C 20.7 18.3 19.9 18.6 19.3 18.9 C 18.8 19.1 18.4 19.3 17.8 19.3 C 17.2 19.3 16.9 19.2 16.4 19 C 15.7 18.7 15 18.4 13.6 18.4 L 13.2 18.4 C 11 18.4 9.1 20.2 9.1 22.4 L 9.1 22.6 C 9.1 25.2 10.2 27.1 10.9 28.4 C 11.3 29 11.5 29.4 11.5 29.7 C 11.5 30 11.4 30.4 11.3 30.8 C 11.1 31.5 10.9 32.4 10.9 34 C 10.9 35.2 11.1 36.4 11.5 37.5 C 8.9 37.3 6.3 37 3.9 36.5 C 2.1 33.9 1 30.8 1 27.4 C 1 20 6.1 13.7 13 11.8 L 13 6.5 C 13 5.8 12.8 5.2 12.4 4.7 C 11.9 4 11.6 3.4 11.6 2.9 C 11.6 1.3 12.9 0 14.6 0 C 16.3 0 17.6 1.3 17.6 2.9 C 17.6 3.5 17.3 4.1 17 4.7 M 19.6 37.8 C 18.9 37.8 18.1 37.8 17.4 37.8 C 16.8 37.8 16.3 37.8 15.7 37.8 C 15.7 37.6 15.7 36.7 15.7 36.4 C 15.7 34.3 16.4 33.6 17.6 33.6 C 18.8 33.6 19.6 34.2 19.6 36.2 C 19.6 36.4 19.6 37.6 19.6 37.8"
      />
    </svg>
  );
}

export default function Logo({
  width = 300,
  height = 50,
  fill = "#8d8d8d",
  className,
}) {
  return (
    <svg
      className={className}
      version="1.1"
      fill={fill}
      height={height}
      width={width}
      x="0px"
      y="0px"
      viewBox="0 0 307 38"
    >
      <path
        className="st0"
        d="M209.8,24c0-8.9-6.2-13.8-15-13.8s-15.1,4.9-15.1,13.8s6.2,13.8,15.1,13.8S209.8,32.9,209.8,24z M255.6,36.2
c0-2-0.8-2.6-2-2.6c-1.2,0-1.9,0.7-1.9,2.8l0,0.6c0,0.3,0,0.6,0,0.8c0.6,0,1.1,0,1.7,0c0.7,0,1.5,0,2.2,0c0-0.2,0-0.3,0-0.5
L255.6,36.2z M253.6,2.9c0-1.6-1.3-2.9-3-2.9c-1.7,0-3,1.3-3,2.9c0,0.5,0.3,1.1,0.8,1.8c0.4,0.5,0.6,1.1,0.6,1.8l0,5.3
c-6.9,1.9-12,8.2-12,15.6c0,3.4,1.1,6.5,2.9,9.1c2.4,0.5,5,0.8,7.6,1c-0.4-1.1-0.6-2.3-0.6-3.5c0-1.6,0.2-2.5,0.4-3.2
c0.1-0.4,0.2-0.8,0.2-1.1c0-0.3-0.2-0.7-0.6-1.3c-0.7-1.3-1.8-3.2-1.8-5.8l0-0.2c0-2.2,1.9-4,4.1-4l0.4,0c1.4,0,2.1,0.3,2.8,0.6
c0.5,0.2,0.8,0.3,1.4,0.3c0.6,0,1-0.2,1.5-0.4c0.6-0.3,1.4-0.6,2.9-0.6l0.2,0c2.2,0,4.1,1.8,4.2,4v0.2l0,0.3
c-0.1,2.4-1.1,4.3-1.8,5.5c-0.3,0.6-0.6,1-0.6,1.3c0,0.3,0.1,0.7,0.1,1.1c0.1,0.7,0.3,1.6,0.3,3.2c0,1.2-0.2,2.4-0.6,3.5
c2.6-0.2,5.1-0.6,7.5-1c1.8-2.6,2.8-5.7,2.8-9.1c0-9-7.4-16.2-16.5-16.2c-0.5,0-1,0-1.4,0.1V6.5c0-0.6,0.2-1.3,0.6-1.8
C253.3,4.1,253.6,3.5,253.6,2.9L253.6,2.9z M28,24c0-8.2-4.7-13.3-13-13.3H0v19.2c0,4,2.5,7.4,7.7,7.4H15C23.3,37.3,28,32.2,28,24z
M50,17.1l0.9-6.5H31.3v19.8c0,4.3,3.5,6.9,7.6,6.9H50l1-6.5h-9.4c-1.7,0-2.3-1.2-2.3-2.7v-1.1h8.9l0.9-6.3h-9.9v-3.7H50z
M72.1,25.6l-8.8-14.9h-9.1v26.7H61V22l9.4,15.4h8.4V10.7h-6.8V25.6z M104.6,17.4l0.9-6.7H81.3v6.7h8v19.9h8V17.4H104.6z
M129.6,37.3l-8-26.7h-10.3l-8.2,26.7h7.3l1.6-5.4h7.8l1.5,5.4H129.6z M139.1,28V10.7h-8v19.8c0,4.3,3.5,6.9,7.6,6.9h11.5l1-6.7
h-9.8C139.7,30.7,139.1,29.4,139.1,28L139.1,28z M178.6,18.8c0-3.8-2.8-8.1-8.7-8.1h-16.4v26.7h8V26.5c0,0,0,0.1,0.1,0.1l0.1,0.1
l0,0c0.4,0.9,0.8,1.9,3,1.9h0.7c1.9,0,2.5,1.7,3.4,4.5c0,0.1,0.1,0.2,0.1,0.2c0.5,1.5,1.5,3.2,2.2,3.9h9.2c-0.8-1.1-1.9-3-2.5-4.5
c-0.1-0.3-0.2-0.6-0.4-0.9c-1.2-2.9-1.7-4.2-5.1-5.5C175.7,26.1,178.6,22.9,178.6,18.8L178.6,18.8z M233.8,17.3
c0-4.6-2.9-6.6-8.7-6.6h-13.7v19.2c0,4,2.5,7.4,7.7,7.4h6.9c5.6,0,9.4-1.9,9.4-7.4c0-3.7-2-6.7-6.6-6.9
C231.8,22.8,233.8,20.5,233.8,17.3z M292.1,17.4l0.9-6.7h-24.2v6.7h8v19.9h8V17.4H292.1z M8,17.1h4.8c3.8,0,6.3,3,6.3,6.9
s-2.5,6.9-6.3,6.9h-2.2C9.1,30.9,8,30.4,8,29L8,17.1z M219.4,28.9v-4.6c0.3,1.8,1,2.1,2.8,2.2l2,0c1.5,0,2.6,0.5,2.6,2.2
c0,1.5-0.9,2.2-2.4,2.2H222C220.6,30.9,219.4,30.3,219.4,28.9z M188.6,24c0-3.7,2.5-6.5,6.2-6.5s6.2,2.8,6.2,6.5s-2.4,6.5-6.2,6.5
S188.6,27.8,188.6,24z M113.7,25.8l2.1-9l2,9L113.7,25.8z M161.6,17.1h5.4c2,0,3.1,0.6,3.1,2.7c0,1.4-0.6,2.8-3.1,2.8h-2.4
c-2.2,0-3-0.5-3-1.9L161.6,17.1z M219.4,17.2h3.3c1.5,0,2.5,0.5,2.5,2c0,1.5-0.8,2.2-2.3,2.2h-1.9c-1.2,0-1.6-0.6-1.6-1.9V17.2z
M295,17c0-3.3,2.7-6,6-6s6,2.7,6,6s-2.7,6-6,6S295,20.3,295,17z M296.2,17c0,2.7,2.2,4.8,4.8,4.8c2.7,0,4.8-2.2,4.8-4.8
c0-2.7-2.2-4.8-4.8-4.8C298.3,12.2,296.2,14.3,296.2,17z M302.4,17.6c0.1,0.1,0.1,0.2,1.3,2.3c0.1,0.2,0,0.4-0.3,0.4h-1
c-0.1,0-0.2-0.1-0.3-0.2L301,18h-0.8v2c0,0.2-0.1,0.3-0.3,0.3H299c-0.2,0-0.3-0.1-0.3-0.3v-6c0-0.2,0.1-0.3,0.3-0.3h1.9
c1.7,0,2.5,0.8,2.5,2.1C303.3,16.5,303,17.2,302.4,17.6C302.4,17.5,302.4,17.6,302.4,17.6L302.4,17.6z M300.2,16.6h0.8
c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.2-0.8-0.9-0.8h-0.7L300.2,16.6z"
      />
    </svg>
  );
}
