import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as middleware from "./middlewares";
import * as service from "./services";
import usePractices from "../../../../hooks/usePractices";

const alert = withReactContent(Swal);

const emailValidation =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function displayMessage(title, html, icon = "error", onClose = () => {/**/}) {
  alert.fire({
    icon,
    title,
    html,
    onClose,
  });
}

//forcePinEmail
export const defaultState = {
  firstName: "",
  lastName: "",
  user: "",
  phone: "",
  defaultPath: "",
  password: "",
  confirmPassword: "",
  forcePinEmail: false,
  createPassword: false,
  canUseMultiPractice: false,
};

const validate = (data, currentPractices) => {
  if (data.phone.length < 11) {
    displayMessage(
      "Warning",
      "Dear user, please provide a valid phone number"
    );
    return false;
  }

  if (!data.user.match(emailValidation)) {
    displayMessage("Warning", "Dear user, please provide a valid e-mail");
    return false;
  }

  if (currentPractices.length === 0) {
    displayMessage(
      "Warning",
      "Dear user, please assign at least one practice"
    );
    return false;
  }

  if (data.createPassword && data.password !== data.confirmPassword) {
    displayMessage('Warning', 'Dear user, the password must match')
    return false;
  }

  return true;
};

export default function useUserForm(userId = null) {
  // Catalog
  const [permissions, setPermissions] = useState([]);
  const [practices, setPractices] = useState([]);
  const [paths, setPaths] = useState([]);

  // Data
  const [data, setData] = useState(defaultState);
  const [currentPermisions, setCurrentPermissions] = useState([]);
  const [currentPractices, setCurrentPractices] = useState([]);

  // Flags
  const [loading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);

  const {firstSelected: practiceId} = usePractices()

  // Initial Load
  useEffect(() => {
    async function getAllData() {
      const [clientPermissionPromise, practicesPromise] = await Promise.all([
        service.getClientPermissions(),
        service.getPractices()
      ]);

      const [statusClientPermissions, clientPermissionList] = clientPermissionPromise;
      const [statusPractices, practicesList] = practicesPromise;

      const fP = middleware.formatPractices(practicesList);

      if (statusClientPermissions && statusPractices) {
        const r = middleware.processPermissions(clientPermissionList)
        const groupedPermissions = middleware.groupByParentCode(r);
        setPermissions(groupedPermissions);
        setPractices(fP);
        setPaths(middleware.getDefaultPathsFromClientPermissions(clientPermissionList))
      }

      if (userId) {
        service.getUserById(userId, practiceId).then(([_status, r]) => {
          const {
            firstName,
            lastName,
            user,
            phone,
            defaultPath = '',
            permission: {
              permission = [],
              practice = []
            },
            setting: {
              canUseMultiPractice,
              forcePinEmail
            }
          } = r;

          setData({
            firstName,
            lastName,
            user,
            phone,
            canUseMultiPractice,
            forcePinEmail,
            defaultPath
          })
          setCurrentPermissions(permission);

          setCurrentPractices(
            fP
              .reduce((a, i) => {
                return [...a, ...i.items];
              }, [])
              .filter(({ id }) => practice.indexOf(id) > -1)
          );
        });
      }
    }

    getAllData()
  }, []);

  // Managed by UI
  const handleChange = (fieldName, value) => {
    if (data[fieldName] !== value) {
      setData({
        ...data,
        [fieldName]: value,
      });
    }
  };

  const handleChangePermission = (permissionId, checked, items, parentId) => {
    const all = middleware.mergeCurrentPermissionsWithNewPermissions(permissionId, items, currentPermisions)

    setData({
      ...data,
      defaultPath: ''
    })

    if (checked) {
      setCurrentPermissions([...all, parentId]);
    } else {
      if (items.length) {
        const i = middleware.flattenPermissions(items)
        const p = [...i, permissionId]
        setCurrentPermissions(currentPermisions.filter(x => !p.includes(x)));
      } else {
        setCurrentPermissions(currentPermisions.filter(x => x !== permissionId));
      }
    }
  }

  const handleSubmit = async () => {
    const {createPassword, confirmPassword, ...d} = data
    let _data = {...d}

    if (!createPassword) {
      const {password, ...otherData} = d
      _data = {...otherData}
    }

   if (!validate(data, currentPractices)) {
      return;
    }

    const savingData = {
      ..._data,
    };

    //return;
    setSaving(true);

    const practicesArray = currentPractices.map(x => x.id);

    const [status] = !userId
      ? await service.saveUser(savingData, currentPermisions, practicesArray, practiceId)
      : await service.updateUser(userId, savingData, currentPermisions, practicesArray, practiceId);

    setSaving(false);
    if (status) {
      displayMessage("Success", "User succesfully saved", "success", () => {
        setDataSaved(true);
      });
    }
  };

  const resetPassword = () => {
    setData({
      ...data,
      createPassword: !data.createPassword,
      confirmPassword: '',
      password: ''
    })
  }

  return {
    loading,
    saving,
    dataSaved,

    data,
    paths,
    permissions,
    currentPermisions,
    handleChangePermission,
    currentPractices,
    userId,
    practices,
    setCurrentPractices,
    setCurrentPermissions,
    resetPassword,
    handleChange,
    handleSubmit
  };
}
