import React from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import useClientPracticeForm from "./useClientPracticeForm";
import Select from "react-select";
import Skeleton from "../../../Skeleton";

const ClientPracticeForm = (props) => {

  const {
    clients,
    practiceIdSelect,
    clientSelectId,
    setShowModal,
    editing,
    setShowModalEdit,
    loadPracticeWithParam,

  } = props;

  const {
    state,
    city,
    loadCity,
    handleSubmit,
    data,
    loading,

  } = useClientPracticeForm(clientSelectId, setShowModal, practiceIdSelect, editing, setShowModalEdit, loadPracticeWithParam);

  return (
    <>
      {
        loading ?
          <Formik
            initialValues={data}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {props => (

              <form onSubmit={props.handleSubmit}>
                <Row className="justify-content-md-center">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Select Client</Form.Label>
                      <Form.Control
                        id="clientId"
                        name="clientId"
                        as="select"
                        required
                        value={props.values.clientId}
                        onChange={({target: {value}}) => {
                          props.setFieldValue('clientId', value)
                        }
                        }
                      >
                        <option selected value="" disabled>
                          Please select an option
                        </option>
                        {
                          clients.map(c => {
                            return (<option key={c._id} value={c._id}>{c.name}</option>)
                            // return (<option key={c._id} value={c.name}>{c.name}</option>)
                          })}
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        id="desc"
                        name="desc"
                        required
                        label="Desc"
                        value={props.values.desc}
                        onChange={props.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="justify-content-md-center">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Folder ID</Form.Label>
                      <Form.Control
                        id="folderId"
                        name="folderId"
                        label="Folder Id"
                        required
                        value={props.values.folderId}
                        onChange={props.handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        id="name"
                        name="name"
                        label="Name"
                        required
                        value={props.values.name}
                        // onChange={props.handleChange}
                        onChange={(e) => {
                          if(/^[a-zA-Z\s_-]*$/.test(e.target.value)) {
                            props.setFieldValue('name', e.target.value)
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>

                </Row>

                <Row className="justify-content-md-center">
                  <Col md={6}>
                    <Form.Group className="">
                      <Form.Label>State</Form.Label>
                      <Select options={state}
                              onChange={async ({label, value}) => {
                                await loadCity(label)
                                props.setFieldValue('stateId', value)
                              }}
                              id="stateId"
                              name="stateId"
                              required
                              defaultValue={state.filter(
                                (temp) => temp.value === props.values.stateId
                              )}
                      />

                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mt-1">
                      <Form.Label>City</Form.Label>

                      <Select options={city}
                              onChange={({label, value}) => {
                                props.setFieldValue('cityId', value)

                              }}
                              id="cityId"
                              name="cityId"
                              required
                              defaultValue={city.filter(
                                (temp) => temp.label === props.values.city
                              )}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Spreadsheet Id</Form.Label>
                      <Form.Control
                        id="spreadsheetId"
                        name="spreadsheetId"
                        label="Spreadsheet Id"
                        required
                        value={props.values.spreadsheetId}
                        onChange={props.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        id="address"
                        name="address"
                        label="Address"
                        required
                        value={props.values.address}
                        onChange={props.handleChange}
                      />
                    </Form.Group>
                  </Col>

                </Row>



                <Card className="mt-2">
                  <Card.Body>
                    <Row className="justify-content-md-center">
                      <Card.Title className="mt-1 ml-5 mb-4">Spread sheet Fields</Card.Title>
                    </Row>

                    <Row className="justify-content-md-center">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Confirmation</Form.Label>
                          <Form.Control
                            id="confirmation"
                            name="confirmation"
                            label="Confirmation"
                            value={props.values.spreadsheetFields
                              ? props.values.spreadsheetFields.confirmation
                              : ''}
                            onChange={
                              ({target: {value}}) => {
                                props.setFieldValue('spreadsheetFields.confirmation', value)
                              }
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Drive Url</Form.Label>
                          <Form.Control
                            id="driveUrl"
                            name="driveUrl"
                            label="Drive Url"
                            value={props.values.spreadsheetFields
                              ? props.values.spreadsheetFields.driveUrl
                              : ''}
                            onChange={
                              ({target: {value}}) => {
                                props.setFieldValue('spreadsheetFields.driveUrl', value)
                              }
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Note</Form.Label>
                          <Form.Control
                            id="note"
                            name="note"
                            label="Note"
                            value={props.values.spreadsheetFields
                              ? props.values.spreadsheetFields.note
                              : ''}
                            onChange={
                              ({target: {value}}) => {
                                props.setFieldValue('spreadsheetFields.note', value)
                              }
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>File Name</Form.Label>
                          <Form.Control
                            id="fileName"
                            name="fileName"
                            label="File Name"
                            value={props.values.spreadsheetFields
                              ? props.values.spreadsheetFields.fileName
                              : ''}
                            onChange={
                              ({target: {value}}) => {
                                props.setFieldValue('spreadsheetFields.fileName', value)
                              }
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>History</Form.Label>
                          <Form.Control
                            id="history"
                            name="history"
                            label="History"
                            value={props.values.spreadsheetFields
                              ? props.values.spreadsheetFields.history
                              : ''}
                            onChange={
                              ({target: {value}}) => {
                                props.setFieldValue('spreadsheetFields.history', value)
                              }
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>ELG Codes</Form.Label>
                          <Form.Control
                            id="elgCodes"
                            name="elgCodes"
                            label="elgCodes"
                            value={props.values.spreadsheetFields
                              ? props.values.spreadsheetFields.elgCodes
                              : ''}
                            onChange={
                              ({target: {value}}) => {
                                props.setFieldValue('spreadsheetFields.elgCodes', value)
                              }
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Row className="justify-content-md-center">
                  <Col md={4}>
                    <Button
                      className="btn btn-success mt-2"
                      type="submit"
                      size="md"
                      block
                    >
                      Save
                    </Button>
                  </Col>
                </Row>


              </form>
            )}

          </Formik>
          : <Skeleton size='md'/>
      }


    </>

  );
}

export default ClientPracticeForm