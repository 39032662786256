import {createRef, useCallback, useEffect, useState} from "react";
import CustomTable from "../../CustomTable";
import { getIntelepeerRecords, changeStatus } from "./intelepeer.service";

const useIntelepeer = () => {
  const [data, _] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [recordToBeChanged, setRecordToBeChanged] = useState({});
  const [filters, setFilters] = useState({});
  const [statusChanged, setStatusChanged] = useState(false);
  const ref = createRef(null);

  useEffect(() => {
    /*if (!loaded) {
      setLoaded(true);
    }*/
    if (ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [filters]);

  useEffect(() => {
    if (ref.current != null && statusChanged) {
      ref.current.onQueryChange();
    }
  }, [statusChanged])

  const getData = useCallback(
    ({ page, pageSize }) => {
      function response(records = [], quantity = 0) {
        return CustomTable.createTableResponse(records, page, quantity);
      }

      return getIntelepeerRecords({
        offset: page,
        ...filters
      }, pageSize).then(([status, res]) => {
        if (!status) {
          return response([], 0);
        }

        return response(res.records, res.recordsQty || 0);
      })
    },
    [filters],
  );

  const handleClickDetails = (selected) => {
    setSelectedRecord(selected)
  }

  const handleClickApplyFilters = (newFilters) => {
    setFilters({...filters, ...newFilters});
  }

  const handleClickResetFilters = () => {
    setFilters({});
  }

  const handleOnChangeStatus = async (id, status) => {
    try {
      setRecordToBeChanged({});
      await changeStatus(id, status);
      setStatusChanged(true);
    } catch (err) {
      console.log(err);
    }
  }

  const handleOnSetRecordToBeChanged = (record) => {
    setStatusChanged(false);
    setRecordToBeChanged(record);
  }

  return {
    data,
    ref,
    getData,
    selectedRecord,
    filters,
    recordToBeChanged,
    handleClickDetails,
    handleClickApplyFilters,
    handleClickResetFilters,
    handleOnChangeStatus,
    handleOnSetRecordToBeChanged
  }
}

export default useIntelepeer;
