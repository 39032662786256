import { useState, useEffect, useCallback } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions(callback) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [userLockedDown, setUserLockedDown] = useState(false);

  const handleResize = useCallback(() => {
    if (callback) {
      if (userLockedDown === false && callback(getWindowDimensions())) {
        setWindowDimensions(getWindowDimensions());
        setUserLockedDown(true);
        return;
      }

      if (
        callback(getWindowDimensions()) === false &&
        userLockedDown === true
      ) {
        setUserLockedDown(false);
      }

      return;
    }

    setWindowDimensions(getWindowDimensions());
  }, [callback, userLockedDown]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return windowDimensions;
}
