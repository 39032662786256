import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import CustomModal from "../../../CustomModal";

// function DatePickerAlt()
// 3

function Input({
  name,
  editable,
  type,
  value,
  onChange,
  otherFormat,
  blocked,
}) {
  if (type && type === Date) {
    let date = null;

    const validateFormat = otherFormat ? "yyyy-MM-DD" : "MM/DD/yyyy";
    const controlFormat = validateFormat.replace("DD", "dd");

    if (moment(value, validateFormat).isValid()) {
      date = moment(value, validateFormat).toDate();
    }

    let _value = moment(value, validateFormat).isValid()
      ? moment(value).format(validateFormat)
      : null;

    if (!editable) return _value;

    return (
      <InputGroup className="flex-nowrap">
        <ReactDatePicker
          className={`form-control custom-form-control `}
          dateFormat={controlFormat}
          autoComplete="off"
          placeholderText={controlFormat}
          name={name}
          id={name}
          showMonthDropdown
          required
          showYearDropdown
          disabled={blocked}
          selected={date}
          todayButton="Today"
          onChange={(d) => {
            onChange(name, moment(d).format(validateFormat));
          }}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }

  if (!editable) return value || "";

  if (type && type === "select") {
    return (
      <select
        required
        name={name}
        id={name}
        disabled={blocked}
        className="form-control"
        value={value.toUpperCase()}
        onChange={({ target }) => {
          onChange(name, target.value);
        }}
      >
        <option value="" disabled>
          Please select...
        </option>
        {["FBD", "ELG"].map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      type="text"
      required
      name={name}
      id={name}
      disabled={blocked}
      className="form-control"
      value={value}
      onChange={({ target }) => {
        onChange(name, target.value);
      }}
    />
  );
}

function ResumeItem({
  label,
  name,
  value,
  type,
  editable,
  onChange,
  otherFormat,
  blocked,
}) {
  return (
    <div className="item">
      <div className="title">{label}: </div>
      <div className="itemValue">
        <Input
          otherFormat={otherFormat}
          name={name}
          value={value}
          type={type}
          editable={editable}
          onChange={onChange}
          blocked={blocked}
        ></Input>
      </div>
    </div>
  );
}

export function InfoModal({
  showBox,
  handleCloseModal,
  editable = false,
  onSave = (d) => console.log,
  waiting,
  updateData = (name, value) => console.log,
  item,
}) {
  const [data, setData] = useState(item);

  const fields = [
    {
      label: "Carrier Name",
      name: "CarrierName",
      value: data.CarrierName,
    },
    {
      label: "Type",
      name: "type",
      value: data.type,
      type: "select",
    },
    { label: "Employer Name", name: "EmployerName", value: data.EmployerName },
    { label: "Member ID", name: "MemberID", value: data.MemberID },
    {
      label: "Patient First Name",
      name: "PatientFirstName",
      value: data.PatientFirstName,
    },
    {
      label: "Patient Last Name",
      name: "PatientLastName",
      value: data.PatientLastName,
    },
    {
      label: "Date of birth",
      name: "PatientDOB",
      value: data.PatientDOB,
      type: Date,
    },
    {
      label: "Patient Id",
      name: "PatientId",
      value: data.PatientId,
      blocked: true,
    },
    { label: "Relationship", name: "Relationship", value: data.Relationship },
    {
      label: "Suscriber DOB",
      name: "SubscriberDOB",
      value: data.SubscriberDOB,
      type: Date,
    },
    {
      label: "Suscriber First Name",
      name: "SubscriberFirstName",
      value: data.SubscriberFirstName,
    },
    {
      label: "Suscriber Last Name",
      name: "SubscriberLastName",
      value: data.SubscriberLastName,
    },
    {
      blocked: true,
      label: " Suscriber Id",
      name: "SubscriberId",
      value: data.SubscriberId,
    },
    {
      blocked: true,
      label: "Appointment",
      name: "ApptDate",
      value: data.ApptDate,
      type: Date,
      otherFormat: true,
    },
  ];

  return (
    <CustomModal
      show={showBox}
      onHide={handleCloseModal}
      centered
      title="Resume Details"
      footer={
        <>
          {editable && (
            <button
              disabled={waiting}
              className="btn btn-success btn-action"
              onClick={() => {
                onSave(data);
              }}
            >
              Update
            </button>
          )}
          <button
            disabled={waiting}
            type="button"
            className="btn btn-danger"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </>
      }
    >
      <div className="bodyInfoResumen">
        <div className="row">
          <div className="col col-md-6">
            {fields.slice(0, 7).map((item, index) => (
              <ResumeItem
                key={index}
                {...item}
                editable={editable}
                onChange={(name, value) => {
                  setData((d) => ({ ...d, [name]: value }));
                }}
              />
            ))}
          </div>
          <div className="col col-md-6">
            {fields.slice(7).map((item, index) => (
              <ResumeItem
                key={index}
                {...item}
                editable={editable}
                onChange={(name, value) => {
                  setData((d) => ({ ...d, [name]: value }));
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
