import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {deletePractice} from "../pages/ClientPractice/ClientPractice.service"
import CustomTable from "../CustomTable";

const ClientPracticeTable = ({
                               SetPracticeIdSelect,
                               setShowModalEdit,
                               data, canEdit, canDelete, refresh,
                               dataSourcePractice,tableReferencePractice, ...props}) => {

  const MySwal = withReactContent(Swal);

  const columns = [
    {
      title: "Id",
      field: "_id",
      hidden: true,
    },
    {
      title: "clientId",
      field: "clientId",
      hidden: true,
    },
    {
      title: "Client Name",
      field: "clientName",
      sorting: true,
      defaultSort: "desc",
    },
    {
      title: "Name",
      field: "name",
      sorting: true,
      defaultSort: "desc",
    },
    {
      title: "City",
      field: "city",
      sorting: true,
      defaultSort: "desc",
    },

    {
      title: "State",
      field: "state",
      sorting: true,
      defaultSort: "desc",
    },
    {
      title: "Date",
      field: "date",
      sorting: true,
      defaultSort: "desc",
    },
    {
      title: "Desc",
      field: "desc",
      sorting: true,
      defaultSort: "desc",
    },
  ];

  let actions = [];
  if (canEdit) {
    actions.push((rowData) => ({
      icon: () => <EditIcon color="primary"/>,
      tooltip: "Edit Client Practice",
      onClick: () => {
        SetPracticeIdSelect(rowData._id)
        setShowModalEdit(true)
      },
    }));
  }

  if (canDelete) {
    actions.push((rowData) => ({
      icon: () => <DeleteIcon className="text-danger"/>,
      tooltip: "Delete Client Practice",
      onClick: (event) => deleteClientPractice(rowData._id),
    }));
  }

  const deleteClientPractice = (id) => {
    MySwal.fire({
      title: "Are you sure you want to delete this Practice?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePractice(id).then(([status, resp]) => {
          if (status) {
            refresh();
          }
        });
      }
    });
  };

  return (
    <div>
      <CustomTable
        columns={columns}
        pageSize={25}
        asyncDataSource={dataSourcePractice}
        tableReference={tableReferencePractice}
        actions={actions}
        search={false}
      />
    </div>
  );
};

export default ClientPracticeTable;
