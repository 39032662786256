export const getFileElipsis = (fileName = "", characters = 15) => {
  return fileName.length > characters
    ? fileName.substring(0, characters) + "..."
    : fileName;
};

export const getSuggestedName = (
  type,
  patientStatus,
  patientName,
  carrierName
) => {
  const left = `Eligibility_${patientStatus || ""}_${
    carrierName || ""
  }`.replace(/\s/g, "");
  const right = (patientName || "").replace(" ", "");

  return [left, right];
};

export const getFileExtension = (fileName) => {
  const fileExtension =
    typeof fileName === "string" ? fileName.split(".").pop().toLowerCase() : "";

  if (fileExtension.indexOf("doc") > -1) {
    return "word";
  } else if (fileExtension === "pdf") {
    return "pdf";
  }

  return "";
};

export const fileMatchWithAllowedTypes = (allowedFileTypes, file) => {
  return (
    allowedFileTypes.find((x) => x === file.name.split(".").pop()) !== undefined
  );
};
