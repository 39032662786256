import {useContext, useEffect, useState} from "react"
import {AppContext} from "../../../../App"
import * as services from './../services/extractionSchedule.services'
import {getAllDatesBetweenTwoDates, getDateAsString} from "../../../../util/date";
import {toast} from "react-toastify";

const useExtractionSchedule = () => {
  const [extraction, setExtraction] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [dates, setDates] = useState([]);
  const [obj, setObj] = useState({});
  const appContext = useContext(AppContext);
  const selectedPractices = appContext.get('selectedPractices')

  useEffect(async () => {
    if (selectedPractices.length > 0) {
      const [stat, res] = await services.getExtractionScheduleByPractice(selectedPractices)
      if (stat) {
        setExtraction(res)
      }
    }
  }, [selectedPractices])

  const saveNewExtractionSchedule = async (extractionObj) => {
    setDisabled(true)

    const newObj = {...obj, dates: [...dates]}
    const save = await services.saveExtraction(newObj)

    const {err = false, message} = save

    if (err) {
      toast.error(message)
    } else {
      toast.success(message)
      setShowModal(false)
      const [stat, res] = await services.getExtractionScheduleByPractice(selectedPractices)
      if (stat) {
        setDates(null)
        setObj({})
        setExtraction(res)
      }
    }
    setDisabled(false)
  }

  const checkDates = async (extractionObj) => {
    const startDate = getDateAsString(
      extractionObj.startDate,
      'YYYY-MM-DD',
      'YYYY-MM-DD')

    const endDate = getDateAsString(
      extractionObj.endDate,
      'YYYY-MM-DD',
      'YYYY-MM-DD')

    const o = {
      practiceId: extractionObj.practice,
      type: extractionObj.type,
      dates: [
        ...getAllDatesBetweenTwoDates(startDate, endDate)
      ]
    }
    setObj(o)
    const verifyDates = await services.checkDates(o)
    const {err = false, message} = verifyDates
    if (!err) {
      setDates(verifyDates.message)
    } else {
      toast.error(message)
    }
  }

  const restartDates = () => {
    setDates([])
    setObj({})
  }

  return {
    selectedPractices,
    extraction,
    showModal,
    disabled,
    dates,
    obj,
    setDisabled,
    setShowModal,
    saveNewExtractionSchedule,
    setDates,
    checkDates,
    restartDates
  }

}

export default useExtractionSchedule