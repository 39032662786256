import React, { useContext, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@material-ui/core";
import PermissionContext from "../context/PermissionContext";
import { displayModalConfirmation } from "../../../../util/displayMessage";
import { AccordionItem } from "../../../CustomAccordion";
import CustomPopover from "../../../CustomPopover";
import OptionsPopover from "../../../CustomPopover/components/OptionsPopover";
import PermissionsList from "./PermissionsList";
import PermissionForm from "./PermissionForm";

const StyledOptionsPopover = styled(OptionsPopover)`
  width: 100%;
  background-color: transparent;
  display: flex !important;
  padding: 0 !important;
  text-align: left;

  & > * {
    display: block;
  }

  svg {
    margin-right: 1rem;
  }
`;

const SectionName = styled.h4`
  font-size: 1.4rem;
  margin-bottom: 0;
`;

const SectionNameWrapped = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderWrapped = styled.div`
  //width: 200px;
`;

const Subsections = styled.span`
  color: #ccc;
  font-size: 1.2rem;
`;

const ElementsSize = styled.p`
  margin-bottom: 0;
`;

const Section = ({ element: section, level, index }) => {
  const { createElement, updateSection, deleteSection } =
    useContext(PermissionContext);

  const {
    displayName,
    name,
    items = [],
    actions = [],
    modules = [],
    sections = [],
    showInMenu,
    restricted,
    type,
    _id,
    icon,
    url,
  } = section;

  const totalItems = [...actions, ...modules, ...sections];

  const [editElement, setEditElement] = useState(false);
  const [creatingElement, setCreatingElement] = useState(false);
  const toggleEdition = () => setEditElement((c) => !c);
  const toggleElementCreation = () => setCreatingElement((c) => !c);

  const askForDelete = () => {
    displayModalConfirmation(
      "Delete section",
      <p>Dear user, are you sure you want to delete this section?</p>,
      () => {
        deleteSection(_id);
      }
    );
  };

  return (
    <>
      <AccordionItem
        paddingHeader=""
        customDropDownIcon={
          <>
            <CustomPopover>
              <StyledOptionsPopover onClick={toggleEdition}>
                <FontAwesomeIcon icon="edit" />
                <span>Edit</span>
              </StyledOptionsPopover>
              <StyledOptionsPopover onClick={askForDelete}>
                <FontAwesomeIcon icon="trash" className="text-danger" />
                <span>Delete</span>
              </StyledOptionsPopover>
              <hr />
              <StyledOptionsPopover onClick={() => setCreatingElement(true)}>
                <FontAwesomeIcon icon="plus" />
                <span>Create element</span>
              </StyledOptionsPopover>
            </CustomPopover>
          </>
        }
        hover={false}
        headerIsClickeable
        containerClassName="p-2"
        headerProps={{ style: { padding: 5 } }}
        eventKey={name}
        renderHeader={() => (
          <HeaderWrapped>
            <SectionNameWrapped>
              <SectionName>{displayName}</SectionName>
              <span style={{ fontSize: "0.8rem" }}>
                {restricted ? (
                  <FontAwesomeIcon className="ml-2" color="#ccc" icon="lock" />
                ) : (
                  <FontAwesomeIcon
                    className="ml-2"
                    color="#198754"
                    icon="unlock"
                  />
                )}
              </span>
            </SectionNameWrapped>
            {!showInMenu && (
              <div className="sub-title">
                <small className="text-muted">
                  <ElementsSize>{totalItems.length} elements</ElementsSize>
                </small>
              </div>
            )}
          </HeaderWrapped>
        )}
        renderBody={() => (
          <>
            {actions.length > 0 ? <Actions actions={actions} /> : null}
            {sections.length > 0 ? (
              <>
                <Subsections>Sections</Subsections>
                <PermissionsList
                  list={sections || []}
                  type="element"
                  level={[index]}
                />
              </>
            ) : null}
            {modules.length > 0 ? (
              <>
                <Subsections>Modules</Subsections>
                <PermissionsList
                  list={modules || []}
                  type="element"
                  level={[index]}
                />
              </>
            ) : null}
            {totalItems.length === 0 && (
              <div>Dear user, currently this section has no elements.</div>
            )}
          </>
        )}
      />
      {creatingElement && (
        <PermissionForm
          initialValues={{
            parentId: _id,
          }}
          onCancel={() => setCreatingElement(false)}
          onSave={(data) =>
            createElement([index], data).then((r) => {
              setCreatingElement(false);
            })
          }
        />
      )}
      {editElement && (
        <PermissionForm
          edit
          initialValues={{
            displayName,
            showInMenu,
            restricted,
            name,
            type,
            icon,
            url,
          }}
          onCancel={() => setEditElement(false)}
          onSave={(data) =>
            updateSection(_id, data).then((r) => {
              setEditElement(false);
            })
          }
        />
      )}
    </>
  );
};

const ActionWrapped = styled.div`
  width: 300px;
  display: flex;
  border-top: 1px solid #ccc;
  padding: 1rem;
  flex-wrap: wrap;
`;
const ActionHeader = styled.h5`
  font-size: 1.3em;
`;

const Actions = ({ actions }) => {
  return (
    <>
      <Subsections>Actions</Subsections>
      <ActionWrapped>
        {actions.map((action) => {
          return <ActionsItems key={action._id} item={action} />;
        })}
      </ActionWrapped>
    </>
  );
};

const StyledActionItem = styled.div`
  width: 120px;
  background-color: #fff;
  color: #0080c0;
  border: 1px solid #0080c0;
  border-radius: 5px;
  padding: 0.2rem 0.425rem;
  cursor: pointer;
  margin-right: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: none;
  }

  &:hover {
    svg {
      display: block;
    }
  }

  &:hover {
    background-color: #0080c0;
    color: #fff;
  }
`;

const ActionName = styled.div`
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  font-size: 0.9rem;
`;

const ActionIcon = styled.div`
  display: inline-block;
`;

const ActionLabel = styled.span`
  color: ${({ variant }) => variant};
`;

const ActionsItems = ({ item }) => {
  const { updateSection, deleteSection } = useContext(PermissionContext);
  const [hover, setHover] = useState(false);
  const [editElement, setEditElement] = useState(false);
  const toggleEdition = () => setEditElement((c) => !c);

  const { displayName, showInMenu, restricted, name, type, url, icon, _id } =
    item;

  const askForDelete = () => {
    displayModalConfirmation(
      "Delete section",
      <p>Dear user, are you sure you want to delete this section?</p>,
      () => {
        deleteSection(_id);
      }
    );
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ev) => {
    ev.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <StyledActionItem
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        <ActionName>{item.displayName}</ActionName>
        <ActionIcon>
          <FontAwesomeIcon icon="cog" color={hover ? "#fff" : "#0080C0"} />
        </ActionIcon>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          style={{ overflow: "inherit" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ padding: "1rem" }}>
            <StyledOptionsPopover onClick={toggleEdition}>
              <FontAwesomeIcon icon="edit" className="text-primary" />
              <ActionLabel variant={"#0080C0"}>Edit</ActionLabel>
            </StyledOptionsPopover>
            <StyledOptionsPopover onClick={askForDelete}>
              <FontAwesomeIcon icon="trash" className="text-danger" />
              <ActionLabel variant={"#dc3545"}>Delete</ActionLabel>
            </StyledOptionsPopover>
          </div>
        </Popover>
      </StyledActionItem>
      {editElement && (
        <PermissionForm
          edit
          initialValues={{
            displayName,
            showInMenu,
            restricted,
            name,
            type,
            url,
            icon,
          }}
          onCancel={() => setEditElement(false)}
          onSave={(data) =>
            updateSection(_id, data).then((r) => setEditElement(false))
          }
        />
      )}
    </>
  );
};

export default Section;
