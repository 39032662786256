import {Api, decryptApiResponse} from "../../../config/Api";
import {statusAndResponse} from "../../../util/requestHandler";

export function listCredentialKeys(){
  return statusAndResponse(
    Api.get('/api/config/key/list'),
    decryptApiResponse
  )
}

export function saveCredentialKey(data){
  return statusAndResponse(
    Api.post(`/api/config/key`, data),
    decryptApiResponse
  )
}

export function deleteCredentialKey(id){
  return statusAndResponse(
    Api.delete(`/api/config/key/${id}`),
    decryptApiResponse
  )
}