import {useState} from "react";
import {getUrlParams, removeUrlParams, setURLParams} from "../../../../util/browser";
import {withoutEmptyKeys} from "../../../../util/object";

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: ''
};

const urlKeys = Object.keys(initialState)

const useUserFilters = (updateFilters) => {
  const [filters, setFilters] = useState({
    ...initialState,
    ...getUrlParams(urlKeys)
  })

  const setFilter = (name, value) => {
    setFilters({...filters, [name]: value})
  }

  const handleFilterSubmit = () => {
    const f = withoutEmptyKeys(filters)

    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys)
      setURLParams(f)
    }

    updateFilters(filters)
  }

  const handleReset = () => {
    setURLParams({})
    setFilters({...initialState})
    updateFilters({...initialState})
  }

  return {
    filters,
    setFilter,
    handleFilterSubmit,
    handleReset
  }
}

export default useUserFilters