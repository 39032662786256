import SummaryReportForm from "./SummaryReportForm";
import {TemplateWrapped} from "../../Layout";
import useSummaryReport from "./useSummaryReport";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const SummaryReport = () => {
  const {GetSummaryReport} = useSummaryReport()
  return (
    <>
      <TemplateWrapped
        title="Summary Report">
        <div className="panel">
          <div className="panel-body">
            <SummaryReportForm GetSummaryReport={GetSummaryReport}/>
          </div>
        </div>
      </TemplateWrapped>
    </>
  )
}

export default withSelectedPractices(SummaryReport)