import { useEffect, useState } from "react";
import moment from "moment";

import { removeUrlParams, setURLParams } from "../../../../../../util/browser";
import {
  getDateAsString,
  getInitialAndEndDate,
} from "../../../../../../util/date";
import { withoutEmptyKeys } from "../../../../../../util/object";

const initialState = {
  type: [],
  practiceId: "",
  startDate: new Date(),
  endDate: new Date(),
  hasFilter: false,
  form: false,
  range: false,
  filter: {
    patientName: {
      operation: "",
      value: "",
    },
    carrier: {
      operation: "",
      value: "",
    },
    statusPatient: {
      operation: "",
      value: "",
    },
    ivProcessResult: {
      operation: "",
      value: "",
    },
    missingFields: {
      operation: "",
      value: "",
    },
    provider: {
      operation: "",
      value: "",
    },
    audited: {
      operation: "",
      value: "",
    },
  },
};

const initialCatalogsState = {
  types: [
    {
      id: "FBD",
      name: "FBD",
    },
    {
      id: "ELG",
      name: "ELG",
    },
  ],
  results: [
    {
      name: "Done by DR",
      id: "Done by DR",
    },
    {
      id: "Check",
      name: "Check",
    },
    { id: "Not content loaded", name: "Not content loaded" },
  ],
};

const urlKeys = Object.keys(initialState);

export function useAllTransactionsFilterNew() {
  const storedFilter = localStorage.getItem("filters");
  let otherFilters = { ...initialState };
  if (storedFilter) {
    const f = JSON.parse(storedFilter);
    if (f.startDate && f.endDate) {
      f.startDate = new Date(f.startDate);
      f.endDate = new Date(f.endDate);
    }
    f.filter = { ...initialState.filter, ...f.filter };
    otherFilters = { ...otherFilters, ...f };
  }
  const [filter, _setFilter] = useState({
    ...otherFilters,
  });

  function setFilter(data) {
    let filters = {
      ...data,
      startDate: getDateAsString(data.startDate, "MM/DD/yyyy", "yyyy/MM/DD"),
      endDate: getDateAsString(data.endDate, "MM/DD/yyyy", "yyyy/MM/DD"),
    };

    const f = withoutEmptyKeys(filters);
    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }

    _setFilter(f);
  }

  return [filter, setFilter];
}

export function useAllTransactionsFilter(setFilters, initialData) {
  const [data, _setData] = useState({
    ...initialState,
    ...((d) => {
      return {
        ...d,
        startDate: d.startDate && moment(d.startDate, "yyyy/MM/DD").toDate(),
        endDate: d.endDate && moment(d.endDate, "yyyy/MM/DD").toDate(),
      };
    })(initialData),
  });

  const [catalogs] = useState(initialCatalogsState);
  const [typeInput, setTypeInput] = useState("");
  const [types, setTypes] = useState(data.type);

  useEffect(() => {}, [types]);

  const handleDeleteType = (index) => {
    const newType = [...data.type];
    newType.splice(index, 1);
    setTypes(newType);
    setData("type", newType);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const isAdded = types.find(
        (typeItem) => typeItem === typeInput.toUpperCase(),
      );
      if (isAdded) return;
      setTypes([...types, typeInput.toUpperCase()]);
      setData("type", [...types, typeInput.toUpperCase()]);
      setTypeInput("");
    }
  };

  function setData(field, value, filterKey = "") {
    if (field === "startDate") {
      return setInitialDate(value);
    }
    if (field === "endDate") {
      return setEndDate(value);
    }

    if (filterKey !== "") {
      const oldFilter = { ...data.filter };
      oldFilter[filterKey][field] = value;
      _setData({ ...data, ["filter"]: { ...oldFilter } });
    } else {
      _setData({ ...data, [field]: value });
    }
  }

  function setInitialDate(date) {
    const [startDate, endDate] = getInitialAndEndDate(date, data.endDate);
    return _setData({ ...data, startDate, endDate });
  }

  function setEndDate(date) {
    const [startDate, endDate] = getInitialAndEndDate(data.startDate, date);
    return _setData({ ...data, startDate, endDate });
  }

  function save(extraFilter = {}) {
    const filters = {
      ...data,
      hasFilter: true,
      form: true,
      range: true,
    };
    filters.filter = { ...filters.filter, ...extraFilter };
    localStorage.setItem("filters", JSON.stringify(filters));
    setFilters(filters);
    setTypeInput("");
  }

  function clear() {
    const clean = {
      type: "",
      practiceId: "",
      startDate: new Date(),
      endDate: new Date(),
      hasFilter: false,
      form: false,
      range: false,
      filter: {
        patientName: {
          operation: "",
          value: "",
        },
        carrier: {
          operation: "",
          value: "",
        },
        statusPatient: {
          operation: "",
          value: "",
        },
        ivProcessResult: {
          operation: "",
          value: "",
        },
        missingFields: {
          operation: "",
          value: "",
        },
        provider: {
          operation: "",
          value: "",
        },
        audited: {
          operation: "",
          value: "",
        },
      },
    };
    let f = { ...clean, hasFilter: false, form: false, range: false };
    setFilters(f);
    _setData({ ...clean, range: false });
    localStorage.setItem("filters", "");
  }

  return {
    data,
    catalogs,
    setData,
    clear,
    save,
    types,
    handleDeleteType,
    handleKeyPress,
    setTypeInput,
    typeInput,
  };
}
