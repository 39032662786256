import styled from "styled-components";

export const TableSection = styled.div`
  width: 100%;
  padding: 0 1rem;
`
export const HexCodeContainer = styled.div`
  margin-left: 30%;
  background-color: #FFF;
  border: solid 2px;
  border-color: ${props => props.color} ;
  border-radius: 5px;
  padding: 2px;
  width: 85px;
  margin-right: 5px;
`

export const TextHexCode = styled.span`  
  color: ${props => props.color};
`