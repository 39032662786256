import { Api, decryptApiResponse, getFile } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";
import * as date from "../../../util/date";
import moment from "moment";

const _formatedParameters = (p) => {
  return {
    ...p,
    startDate: moment(p.startDate, date.defaultFormat).format("yyyy-MM-DD"),
    endDate: moment(p.endDate, date.defaultFormat).format("yyyy-MM-DD"),
  };
};

export function downloadData(params) {
  return getFile(
    "/api/reports/transactions?toExcel=true",
    _formatedParameters(params)
  );
}

// toExcel

export function getRecords(filter) {
  return statusAndResponse(
    Api.get("/api/reports/transactions", {
      params: {..._formatedParameters(filter), toExcel: false},
    }),
    ({data}) => {
      const records = data.map(d => {
        return {...d.spreedsheet, ...d}
      })
      return {
        records: records || [],
        recordsQty: records.length
      }
    }
    // decryptApiResponse
  );
}
