import moment from "moment";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { defaultFormat } from "../../../../util/date";
import CustomDatePicker from "../../../CustomDatePicker";
import useBillingFilter from "./useBillingFilter";

function BillingFilter({ setFilters }) {
  const {
    data,
    processing,
    handleClear,
    handleFilterSubmit,
    setData,
    practices,
  } = useBillingFilter(setFilters);

  return (
    <form className="mt-2 p-1" onSubmit={handleFilterSubmit}>
      <Form.Group>
        <Form.Label>Start Date</Form.Label>
        <CustomDatePicker
          value={
            data.startDate
              ? moment(data.startDate, defaultFormat).toDate()
              : null
          }
          name="startDate"
          onChange={(d) => {
            setData("startDate", d);
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>End date</Form.Label>
        <CustomDatePicker
          value={
            data.endDate ? moment(data.endDate, defaultFormat).toDate() : null
          }
          name="endDate"
          onChange={(d) => {
            setData("endDate", d);
          }}
        />
      </Form.Group>

      <div className="text-center mb-2">
        <Button variant="primary" type="submit" disabled={processing}>
          Apply filters
        </Button>

        <Button
          disabled={processing}
          className="ml-1"
          variant="warning"
          type="button"
          onClick={() => {
            handleClear();
          }}
        >
          Reset
        </Button>
      </div>
    </form>
  );
}

export default BillingFilter;
