import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Dropdown, DropdownButton, Row } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import shortid from "shortid";
import "../../../../node_modules/chartist/dist/chartist.min.css";
import { Loader } from "../../formControls";
import { TemplateWrapped } from "../../Layout";
import { Presentational as Connections } from "../Connections";
import * as config from "./config";
import useDashboard from "./hooks/useDashboard";
import useMetrics from "./hooks/useMetrics";
import "./styles/index.css";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

// Main Component

function Dashboard() {
  const {
    data: { donut, linear, table, recordCount, loaded, filter, bar },
    carriers,
    practiceIds,
    month,
    year,
    carrierFilter,
    actions: { setFilter, setCarrierFilter, addMonth },
  } = useDashboard();

  const metricsData = useMetrics();

  return (
    <TemplateWrapped>
      {practiceIds.length === 0  && (
        <div
          className="alert alert-alt alert-warning"
          role="alert"
          style={{
            padding: "20px 12px",
            fontSize: "14px",
            borderLeft: "3px solid",
          }}
        >
          Dear user, once you select a connection, the corresponding data will
          be displayed
        </div>
      )}
      {practiceIds.length === 0 && (
        <div className="panel">
          <div className="panel-body">
            <div className="row mt30">
              <div className="col-md-12">
                <Connections title="Please select a connection" />
              </div>
            </div>
          </div>
        </div>
      )}
      {practiceIds.length > 0 && (
        <div className="dashboard">
          <div className="container-fluid">
            <Row>
              <Metrics data={metricsData} />
            </Row>
            <div className="card card-shadow card-md my-4">
              <Row className="">
                <div className="col-md-12">
                  <LinearStatistics
                    data={linear}
                    carriers={carriers}
                    addMonth={addMonth}
                    month={month}
                    year={year}
                    selectedCarrier={carrierFilter}
                    setCarrier={setCarrierFilter}
                    options={config.linearOptions}
                    recordCount={recordCount}
                  />
                </div>
              </Row>
              <BarGraph data={bar} />
              <Row>
                <div className="col-xl-7 col-12 mb-4">
                  <StatusTable
                    data={table}
                    filter={filter}
                    setFilter={setFilter}
                  />
                </div>
                <div className="col-xl-5 col-12 mb-4">
                  <StatusDonut
                    key={shortid.generate()}
                    data={donut}
                    options={config.getDonutOptions(
                      table.count,
                      filter || "All Types"
                    )}
                  />
                </div>
              </Row>
            </div>
          </div>
          {loaded === false && <Loader active />}
        </div>
      )}
    </TemplateWrapped>
  );
}

function BarItem({ label, count, data, id }) {
  return (
    <div className="counter">
      <div className="counter-label">{label}</div>
      <div className="counter-number-group text-truncate">
        <span className="counter-number">{count}</span>
      </div>
      <div className="ct-chart" data-counter-type={id}>
        <ChartistGraph data={data} options={config.barOptions} type="Bar" />
      </div>
    </div>
  );
}

// Components

function Metrics({ data }) {
  return (
    <>
      <div className="col-xl-3 col-md-6 info-panel">
        <div className="card card-shadow">
          <div className="card-block bg-white p-20">
            <button
              style={{ borderRadius: "100%" }}
              type="button"
              className="btn btn-floating btn-sm btn-warning"
            >
              FBD
            </button>
            <span className="ml-15 font-weight-400">Full Break Down</span>
            <div className="content-text text-center mb-0">
              <span className="font-size-40 font-weight-100">{data.fbd}</span>
              <p className="blue-grey-400 font-weight-100 m-0">
                In the next five days
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6  info-panel">
        <div className="card card-shadow">
          <div className="card-block bg-white p-20">
            <button
              style={{ borderRadius: "100%" }}
              type="button"
              className="btn btn-floating btn-sm btn-info"
            >
              ELG
            </button>
            <span className="ml-15 font-weight-400">Eligibility</span>
            <div className="content-text text-center mb-0">
              <span className="font-size-40 font-weight-100">{data.elg}</span>
              <p className="blue-grey-400 font-weight-100 m-0">
                In the next five days
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6 info-panel">
        <div className="card card-shadow">
          <div className="card-block bg-white p-20">
            <button
              style={{ borderRadius: "100%" }}
              type="button"
              className="btn btn-floating btn-sm btn-danger"
            >
              <FontAwesomeIcon icon="times" />
            </button>
            <span className="ml-15 font-weight-400">Missing Code</span>
            <div className="content-text text-center mb-0">
              <span className="font-size-40 font-weight-100">
                {data.missing}
              </span>
              <p className="blue-grey-400 font-weight-100 m-0">
                In the next five days
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6 info-panel">
        <div className="card card-shadow">
          <div className="card-block bg-white p-20">
            <button
              style={{ borderRadius: "100%" }}
              type="button"
              className="btn btn-floating btn-sm btn-success"
            >
              <FontAwesomeIcon icon="check" />
            </button>
            <span className="ml-15 font-weight-400">Audited</span>
            <div className="content-text text-center mb-0">
              <span className="font-size-40 font-weight-100">
                {data.audited}
              </span>
              <p className="blue-grey-400 font-weight-100 m-0">
                In the next five days
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function StatusDonut({ data, options }) {
  if (Object.keys(data).length === 0) {
    return null;
  }
  return (
    <div id="donut" className="card card-shadow card-md">
      <div className="card-header card-header-transparent pb-15">
        <p className="font-size-14 blue-grey-700 mb-0"></p>
      </div>
      <div className="card-block px-30">
        <div className="text-center">
          <ChartistGraph data={data} options={options} type="Pie" />
        </div>
      </div>
    </div>
  );
}

function StatusTable({ data, filter, setFilter }) {
  const types = [
    {
      value: "",
      label: "All Types",
    },
    {
      value: "ELG",
      label: "ELG",
    },
    {
      value: "FBD",
      label: "FBD",
    },
  ];

  function getPercentage(c) {
    return c === 0
      ? "0 %"
      : Math.round(((c * 100) / data.count) * 100) / 100 + "%";
  }

  return (
    <div id="statusTable" className="card card-shadow card-md">
      <div className="card-header card-header-transparent pb-15 d-flex">
        <DropdownButton variant="link" title="Filter by type">
          {types.map(({ label, value }) => {
            return (
              <Dropdown.Item
                key={value}
                onClick={() => {
                  setFilter(value);
                }}
              >
                <div className="d-flex justify-content-between pr-3">
                  {label}{" "}
                  {filter === value && (
                    <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                  )}
                </div>
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </div>
      <div className="card-block px-30 pt-0">
        <h4 className="pl-2">
          {types.filter(({ label, value }) => filter === value)[0]["label"]}:{" "}
          {data.count} records
        </h4>
        <div className="table-responsive">
          <table className="table table-analytics mb-0 text-nowrap">
            <tbody>
              <tr>
                <td>
                  <span className="legend"></span> Active
                </td>
                <td className="text-center">{data.active}</td>
                <td className="text-right">{getPercentage(data.active)}</td>
              </tr>
              <tr>
                <td>
                  <span className="legend"></span>Active / Pending
                </td>
                <td className="text-center">{data.pending}</td>
                <td className="text-right">{getPercentage(data.pending)}</td>
              </tr>
              <tr>
                <td>
                  <span className="legend"></span>No Missing Fields
                </td>
                <td className="text-center">{data.noMissingCode}</td>
                <td className="text-right">
                  {getPercentage(data.noMissingCode)}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="legend"></span>Has Missing Fields
                </td>
                <td className="text-center">{data.hasMissingCodes}</td>
                <td className="text-right">
                  {getPercentage(data.hasMissingCodes)}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="legend"></span>Audited
                </td>
                <td className="text-center">{data.audited}</td>
                <td className="text-right">{getPercentage(data.audited)}</td>
              </tr>
              <tr>
                <td>
                  <span className="legend"></span>Not Found
                </td>
                <td className="text-center">{data.notFound}</td>
                <td className="text-right">{getPercentage(data.notFound)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function LinearStatistics({
  data,
  carriers,
  selectedCarrier,
  options,
  month,
  year,
  setCarrier,
  addMonth,
  recordCount,
}) {
  return (
    <div id="transactions">
      <div className="card-header pb-3 card-header-transparent d-flex justify-content-between">
        <DropdownButton
          className="carrier-filter"
          variant="link"
          title="Filter by Carrier"
        >
          {carriers &&
            carriers.map(({ text: name }) => {
              return (
                <Dropdown.Item
                  key={name}
                  onClick={() => {
                    setCarrier(name);
                  }}
                >
                  <div className="d-flex justify-content-between pr-3">
                    {name}{" "}
                    {name === selectedCarrier && (
                      <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                    )}
                  </div>
                </Dropdown.Item>
              );
            })}
        </DropdownButton>
        <div>
          <ul className="nav nav-pills nav-pills-rounded product-filters">
            <li className="nav-item">
              <Button
                onClick={() => {
                  addMonth(-1);
                }}
                variant="link"
                data-toggle="tab"
              >
                <FontAwesomeIcon icon="arrow-left" />
              </Button>
            </li>
            <li className="nav-item pt-1">
              {month} {year}
            </li>
            <li className="nav-item">
              <Button
                onClick={() => {
                  addMonth(1);
                }}
                variant="link"
                data-toggle="tab"
              >
                <FontAwesomeIcon icon="arrow-right" />
              </Button>
            </li>
          </ul>
        </div>
      </div>
      <h4 className="ml-5 mb-3">
        {selectedCarrier}: {recordCount} records
      </h4>
      <div className="card-block px-30 pt-0">
        <ChartistGraph data={data} options={options} type="Line" />
      </div>
    </div>
  );
}

function BarGraph({ data }) {
  return (
    <div id="productOptionsData" className="text-center">
      <Row>
        <div className="col-xl-3 col-md-6">
          <BarItem
            label="Completed"
            data={data.completed.data}
            count={data.completed.count}
            id="productVist"
          />
        </div>

        <div className="col-xl-3 col-md-6">
          <BarItem
            label="Not Completed"
            data={data.notCompleted.data}
            count={data.notCompleted.count}
            id="productVistors"
          />
        </div>
        <div className="col-xl-3 col-md-6">
          <BarItem
            label="Not Found"
            data={data.notFound.data}
            count={data.notFound.count}
            id="productPageViews"
          />
        </div>
        <div className="col-xl-3 col-md-6">
          <BarItem
            label="Audited"
            data={data.audited.data}
            count={data.audited.count}
            id="productBounceRate"
          />
        </div>
      </Row>
    </div>
  );
}

export default withSelectedPractices(Dashboard)