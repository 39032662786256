import ColumnFlag from "../components/pages/AllTransactions/components/ColumnGrid/ColumnFlag";
import React from "react";

export const transactionColorFlagColumn = (
  flagColors,
  saveTransactionColor,
  _permissions,
  module_key,
) => {
  const modulesForSeeFlags = ["AT"];

  const colorFlagColumn = [
    {
      title: "",
      sorting: false,
      render: ({ _id, color }) => (
        <ColumnFlag
          transactionId={_id}
          hexCode={color}
          flagColors={flagColors}
          saveTransactionColor={saveTransactionColor}
          canChangeColor={_permissions.transaction?.changeColor ?? false}
        />
      ),
    },
  ];
  return modulesForSeeFlags.includes(module_key) ? colorFlagColumn : [];
};
