import {useEffect, useState} from "react";
import {getUrlParams, removeUrlParams, setURLParams} from "../../../../util/browser";
import {withoutEmptyKeys} from "../../../../util/object";
import {getCity, getStateV2} from "../ClientPractice.service";

export const initialState = {
  name: '',
  client: '',
  address: '',
  state: '',
  limit: 50,
  offset: 0
};

const urlKeys = Object.keys(initialState);

const useClientPracticeFilter = (setFilters, setClientSelectId) => {
  const [processing, setProcesing] = useState(false);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [data, _setData] = useState({
    ...initialState,
    ...getUrlParams(urlKeys)
  });
  useEffect(async () => {
    await loadState();
  }, []);

  function setData(name, value) {
    _setData({...data, [name]: value});
  }

  const _setFilters = (d) => {
    setFilters(withoutEmptyKeys(d));
  };

  const loadCity = async (stateId) => {
    if (stateId) {
      let response = await getCity(stateId);
      response = response[1]['data']['value'].map(item => ({...item, value: item.id, label: item.name}))
      setCity(response)
    }
  }

  const loadState = async () => {
    let response = await getStateV2();
    response = response[1]['data']['value'].map(v => ({...v, value: v.id, label: v.name}))
    setState(response)
  }

  const handleReset = (reset) => {
    removeUrlParams(urlKeys)
    setFilters(initialState)
    _setData({
      ...initialState,
      ...getUrlParams(urlKeys)
    })
    reset()

  }

  const handleFilterSubmit = (data) => {
    const f = withoutEmptyKeys(data);

    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }

    _setFilters(data)
    updateFilters(data)
  }

  const updateFilters = (d) => {
    setFilters(withoutEmptyKeys(d));
  };

  const updateUrl = (newData) => {
    const f = withoutEmptyKeys(newData);
    if (urlKeys.length > 0) {
      removeUrlParams(urlKeys);
      setURLParams(f);
    }
  };

  return {
    data,
    processing,
    handleFilterSubmit,
    loadCity,
    city,
    handleReset,
    state,
    setState
  };
}

export default useClientPracticeFilter

