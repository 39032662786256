import moment from "moment";

export function formatLinearStatistics(data) {
  function formatData(d) {
    return d.reduce(
      (acum, i) => {
        if (!i.spreedsheet || !i.spreedsheet.apptDateType) {
          throw new Error("invalid record");
        }

        const date = i.spreedsheet.apptDateType.split("T")[0];
        const numberOfWeekInMonth = Math.ceil(
          moment(date, "yyyy-MM-DD").date() / 7
        );

        acum[numberOfWeekInMonth - 1]++;

        return acum;
      },
      [0, 0, 0, 0, 0]
    );
  }

  return {
    labels: ["WEEK-1", "WEEK-2", "WEEK-3", "WEEK-4", "WEEK-5"],
    series: [
      formatData(data.filter(({type = ''}) => type.toUpperCase() === "ELG")),
      formatData(data.filter(({type = ''}) => type.toUpperCase() === "FBD")),
    ],
  };
}

export function formatTable(data, filter) {
  return data
    .filter(({type = ''}) => filter === "" || type.toUpperCase() === filter)
    .reduce(
      (acum, i) => {
        const {
          StatusVerification = '',
          StatusPatient = '',
          missingRequiredInputQty = 0,
          audited,
          spreedsheet: {CarrierName = ""}
        } = i;

        const _statusPatient = StatusPatient.toUpperCase();
        const _statusVerification = StatusVerification.toUpperCase();
        const carrierName = CarrierName.toUpperCase();

        if (
          _statusVerification === "DONE BY DR" &&
          /^ACTIVE/.test(_statusPatient)
        ) {
          acum.active++;
        }

        if (
          (_statusVerification === "DONE BY DR" &&
            _statusPatient !== "ACTIVE" &&
            _statusPatient.trim().length > 6)
        ) {
          acum.pending++;
        }

        if (missingRequiredInputQty > 0) {
          acum.hasMissingCodes++;
        } else {
          acum.noMissingCode++;
        }

        if ((carrierName !== 'EMPTY' || carrierName !== 'NO INSURANCE COMPANY')
          && ((_statusVerification !== 'EMPTY' && !_statusVerification.includes('DONE BY'))
            || _statusPatient.includes('NOT FOUND'))) {
          acum.notFound++;
        }

        if (audited) {
          acum.audited++;
        }

        acum.count++;
        return acum;
      },
      {
        active: 0,
        pending: 0,
        noMissingCode: 0,
        hasMissingCodes: 0,
        audited: 0,
        notFound: 0,
        count: 0,
      }
    );
}

export function formatBar(data) {
  const d = data.reduce(
    (acum, i) => {
      const {StatusVerification = '', StatusPatient = '', audited, spreedsheet: {CarrierName = ""}} = i;
      const _statusPatient = StatusPatient.toUpperCase();
      const carrierName = CarrierName.toUpperCase();

      const _statusVerification = StatusVerification.toUpperCase();

      if (audited) {
        acum.audited.push(i);
      }

      if (_statusVerification.indexOf('DONE BY') > -1) {
        acum.completed.push(i);
      }

      if (
        _statusVerification === "EMPTY"
      ) {
        acum.notCompleted.push(i);
      }

      if ((carrierName !== 'EMPTY' || carrierName !== 'NO INSURANCE COMPANY')
        && ((_statusVerification !== 'EMPTY' && !_statusVerification.includes('DONE BY'))
          || _statusPatient.includes('NOT FOUND'))) {
        acum.notFound.push(i);
      }


      return acum;
    },
    {
      completed: [],
      notCompleted: [],
      notFound: [],
      audited: [],
    }
  );

  return {
    completed: {
      data: formatLinearStatistics(d.completed),
      count: d.completed.length,
    },
    notCompleted: {
      data: formatLinearStatistics(d.notCompleted),
      count: d.notCompleted.length,
    },
    notFound: {
      data: formatLinearStatistics(d.notFound),
      count: d.notFound.length,
    },
    audited: {
      data: formatLinearStatistics(d.audited),
      count: d.audited.length,
    },
  };
}

export function formatDonut(formatedAsTableData) {
  return {
    labels: [
      "Active",
      "Pending",
      "No Missing Fields",
      "Has Missing Fields",
      "Audited",
      "Not Found",
    ],
    series: Object.values(formatedAsTableData),
  };
}
