import React from "react";
import {
  AnalitycsItem,
  ContainerAnalitycs,
  PracticesModalIconContainer,
} from "./components";
import { useAnalitycs } from "./hooks/useAnalitycs";
import PinDropIcon from "../../../../../assets/images/svg_v2/pin_drop_icon.svg";
import CustomModal from "../../../../CustomModal";
import AnalitycsForPractices from "./components/analitycs-for-practices/AnalitycsForPractices";

const Analitycs = ({ filters }) => {
  const {
    statistics,
    showModal,
    setShowModal,
    handleShowModal,
    selectedPractices,
    practicesList,
    constraintDataToGetStatistics,
  } = useAnalitycs(filters);
  return (
    <>
      <ContainerAnalitycs>
        {statistics.length > 0 && (
          <>
            {statistics.map(({ name, percentage, count }) => (
              <AnalitycsItem
                key={name}
                name={name}
                percentage={percentage}
                count={count}
              />
            ))}

            <PracticesModalIconContainer
              onClick={handleShowModal}
              disabled={selectedPractices.length === 1}
            >
              <img
                src={PinDropIcon}
                alt="Icon select practices"
                width={30}
                height={30}
              />
            </PracticesModalIconContainer>
          </>
        )}
      </ContainerAnalitycs>

      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Analitycs Information From Practices"
        size="lg"
      >
        <AnalitycsForPractices
          selectedPractices={selectedPractices}
          practicesList={practicesList}
          onCloseModal={() => {
            setShowModal(false);
          }}
          constraintDataToGetStatistics={constraintDataToGetStatistics}
        />
      </CustomModal>
    </>
  );
};
export default Analitycs;
