import React from "react";
import { Form } from "react-bootstrap";
import useControl, { commonProps, ControlWrapper } from "./useControl";

const selectProps = {
  ...commonProps,
  nonSelectedValue: "",
  options: [],
  optValueKey: "value",
  optTextKey: "text",
  disableFirstOption: true,
};

export default function Select(props = selectProps) {
  const {
    options,
    optValueKey,
    optTextKey,
    disableFirstOption,
    nonSelectedValue: defaultValue,
    ...rest
  } = {
    ...selectProps,
    ...props,
  };

  const { controlProps } = useControl(rest);

  return (
    <ControlWrapper {...props}>
      <Form.Control {...controlProps} as="select">
        <option value={defaultValue} disabled={disableFirstOption}>
          Please select an option
        </option>
        {options.map((item, i) => {
          const text =
            typeof item[optTextKey] !== "undefined"
              ? item[optTextKey]
              : item[optValueKey];
          const value = item[optValueKey];
          return (
            <option key={i} value={value}>
              {text}
            </option>
          );
        })}
      </Form.Control>
    </ControlWrapper>
  );
}
