import {TemplateWrapped} from "../../Layout";
import CustomTable from "../../CustomTable";
import {BlurryText, GridText} from "../AllTransactions/components/styles/AllTransactionTable.style";
import {getDateAsString} from "../../../util/date";
import React from "react";
import {VerificationStatus} from "../AllTransactions/components/ColumnGrid/ColumnVerificationBy";
import {VERIFICATION_BY_COLOR} from "../AllTransactions/utils/constanst";

const data = [
  {
    appointment_date: '07/07/2023',
    patientName: 'Martina',
    patientLastName: 'Saenz',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Date of Birth',
    received: 'Yes'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Toeram',
    patientLastName: 'Sehgal',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Social Security',
    received: 'No'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Sehgal',
    patientLastName: 'Sehgal',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Toeram',
    patientLastName: 'Sehgal',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Ziem',
    patientLastName: 'Sehgal',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Martina',
    patientLastName: 'Saenz',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Member ID',
    received: 'Yes'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Mia',
    patientLastName: 'Echeverri',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Augusto',
    patientLastName: 'Santilli',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Francisca',
    patientLastName: 'Santilli',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Zip Code',
    received: 'No'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Leeland',
    patientLastName: 'Stewart',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Lorenzo',
    patientLastName: 'Volcanes',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Mia',
    patientLastName: 'Echeverri',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Augusto',
    patientLastName: 'Santilli',
    practice: 'Coral',
    patientContacted: false,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Francisca',
    patientLastName: 'Santilli',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Member ID',
    received: 'Yes'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Leeland',
    patientLastName: 'Stewart',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Date of Birth',
    received: 'Yes'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Lorenzo',
    patientLastName: 'Volcanes',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Carrier Name',
    received: 'Yes'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Gianna',
    patientLastName: 'Mayor Carrera',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Oscar',
    patientLastName: 'Burnett',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Gianna',
    patientLastName: 'Mayor Carrera',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: true,
    missingItem: 'Carrier Address',
    received: 'No'
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Oscar',
    patientLastName: 'Burnett',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Sophia',
    patientLastName: 'Caluian',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Sebastian',
    patientLastName: 'Crespo',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
  {
    appointment_date: '07/07/2023',
    patientName: 'Sophia',
    patientLastName: 'Hoppe',
    practice: 'Coral',
    patientContacted: true,
    patientResponded: false,
    missingItem: 'Pending Response',
    received: ''
  },
]
const columns = [
  {
    sorting: false,
    title: "Appointment",
    field: "appointment_date",
    render: ({appointment_date}) => {
      return <GridText>{getDateAsString(appointment_date, "yyyy-MM-DD")}</GridText>
    },
  },
  {
    sorting: false,
    title: "Patient Name",
    field: "patientName",
    render: ({patientName, patientLastName}) => {
      return (<><GridText>{patientName}</GridText> <BlurryText>{patientLastName}</BlurryText></>)
    },
  },
  {
    sorting: false,
    title: "Practice",
    field: "practice",
    render: ({practice}) => {
      return <GridText>{practice}</GridText>
    },
  },
  {
    sorting: false,
    title: "Patient Contacted",
    field: "patientContacted",
    align: "center",
    render: ({patientContacted}) => {
      const text = patientContacted ? 'Yes' : 'No';
      let status = VERIFICATION_BY_COLOR['danger'];
      if (patientContacted)
        status = VERIFICATION_BY_COLOR['success'];

      return (<VerificationStatus status={status} width='40px'>{text}</VerificationStatus>)
    },
  },
  {
    sorting: false,
    title: "Patient Responded",
    field: "patientResponded",
    align: "center",
    render: ({patientResponded}) => {
      const text = patientResponded ? 'Yes' : 'No';
      let status = VERIFICATION_BY_COLOR['danger'];
      if (patientResponded)
        status = VERIFICATION_BY_COLOR['success'];

      return (<VerificationStatus status={status} width='40px'>{text}</VerificationStatus>)
    },
  },
  {
    sorting: false,
    title: "Missing Item",
    field: "missingItem",
    render: ({missingItem}) => {
      return <GridText>{missingItem}</GridText>
    },
  },
  {
    sorting: false,
    title: "Received",
    field: "received",
    align: "center",
    render: ({received}) => {
      let status = VERIFICATION_BY_COLOR['success'];
      if (received === 'No')
        status = VERIFICATION_BY_COLOR['danger'];

      if (!received) return null;

      return (<VerificationStatus status={status} width='40px'>{received}</VerificationStatus>)
    },
  }
]

const ReCallTransactions = () => {
  return (
    <TemplateWrapped
      title='Recall  | Transactions'
    >
      <CustomTable columns={columns} data={data} options={{search: false, draggable: false}} pageSize={10}/>
    </TemplateWrapped>
  )
}

export default ReCallTransactions
