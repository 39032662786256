const OptionsPopover = ({ handleClose, ...props }) => {
  return (
    <button
      className={`d-block btn btn-link  btn-sm ${props.cssclass}`}
      {...props}
      onClick={(ev) => {
        ev.stopPropagation();
        handleClose && handleClose(ev);
        props.onClick && props.onClick(ev);
      }}
    />
  );
};

export default OptionsPopover;
