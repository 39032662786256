import * as Yup from "yup";

export const sectionSchema = (type = 'Module') => {
  return Yup.object().shape({
    displayName: Yup.string().required(`${type} is required`),
    name: Yup.string().required('Permission name is required'),
    //url: Yup.string().required('Url is required'),
    //icon: Yup.string().required('icon is required'),
    url: Yup.string().when('showInMenu', {
      is: true,
      then: Yup.string().required('Url is required')
    }),
    //showInMenu: Yup.boolean,
  });
}