import React, { useContext } from "react";
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useState } from "react";
import { TemplateContext } from "../TemplateContext";

export default function GeneralForm({
  name: initialName,
  description: initialDescription,
  discardChanges,
}) {
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const { updateGeneralData } = useContext(TemplateContext);

  return (
    <div className="w-100">
      <FormGroup>
        <FormLabel>Name</FormLabel>
        <FormControl
          type="text"
          className="mr-1"
          placeholder="Name"
          name="name"
          value={name}
          onClick={(ev) => ev.stopPropagation()}
          onChange={({ target: { value } }) => {
            setName(value);
          }}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Description</FormLabel>
        <FormControl
          type="text"
          name="description"
          className="mr-1"
          placeholder="Name"
          value={description}
          onClick={(ev) => ev.stopPropagation()}
          onChange={({ target: { value } }) => {
            setDescription(value);
          }}
        />
      </FormGroup>
      <div className="text-right">
        <Button
          variant="outline-action"
          onClick={async () => {
            await updateGeneralData(name, description);
            discardChanges();
          }}
        >
          Update
        </Button>
        <Button variant="outline-danger" onClick={discardChanges}>
          Discard
        </Button>
      </div>
    </div>
  );
}
