import { Button, Col, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../CustomDatePicker";
import {useState} from "react";

const IntelepeerFormFilter = ({ setFilters, resetFilters, filters }) => {
  const [appointmentDateStart, setAppointmentDateStart] = useState(filters.appointmentDateStart);
  const [appointmentDateEnd, setAppointmentDateEnd] = useState(filters.appointmentDateEnd);
  const [patientFirstName, setPatientFirstName] = useState(filters.patientFirstName || "");
  const [patientLastName, setPatientLastName] = useState(filters.patientLastName || "");
  const [patientDOB, setPatientDOB] = useState(filters.dob || "");
  const [patientId, setPatientId] = useState(filters.patientId || "");
  const [insurance, setInsurance] = useState(filters.insurance|| "");
  const [doctor, setDoctor] = useState(filters.doctor || "");

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        console.log(e);
      }}
    >
      <Row className="mb-1 mt-3">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="appointmentDateStart">Appointment date start</Form.Label>
            <CustomDatePicker
              id="appointmentDateStart"
              value={appointmentDateStart}
              name="appointmentDateStart"
              onChange={(d) => {
                setAppointmentDateStart(d);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1 mt-3">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="appointmentDateEnd">Appointment date end</Form.Label>
            <CustomDatePicker
              id="appointmentDateEnd"
              value={appointmentDateEnd}
              name="appointmentDateEnd"
              onChange={(d) => {
                setAppointmentDateEnd(d);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="patientFirstName">Patient firstname</Form.Label>
            <Form.Control
              id="patientFirstName"
              name="patientFirstName"
              className="mr-1"
              value={patientFirstName}
              onChange={(e) => {
                setPatientFirstName(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="patientFirstName">Patient lastname</Form.Label>
            <Form.Control
              id="patientLastName"
              name="patientLastName"
              className="mr-1"
              value={patientLastName}
              onChange={(e) => {
                setPatientLastName(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="patientDOB">Patient DOB</Form.Label>
            <CustomDatePicker
              id="patientDOB"
              value={patientDOB}
              name="patientDOB"
              onChange={(d) => {
                setPatientDOB(d);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="patientId">Patient ID</Form.Label>
            <Form.Control
              id="patientId"
              name="patientId"
              className="mr-1"
              value={patientId}
              onChange={(e) => {
                setPatientId(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="insurance">Insurance</Form.Label>
            <Form.Control
              id="insurance"
              name="insurance"
              className="mr-1"
              value={insurance}
              onChange={(e) => {
                setInsurance(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <Form.Group>
            <Form.Label htmlFor="doctor">Doctor</Form.Label>
            <Form.Control
              id="doctor"
              name="doctor"
              className="mr-1"
              value={doctor}
              onChange={(e) => {
                setDoctor(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="d-flex justify-content-around">
          <Button
            style={{width: '100px'}}
            variant="primary"
            type="button"
            onClick={() => {
              setFilters({
                appointmentDateStart,
                appointmentDateEnd,
                patientFirstName,
                patientLastName,
                dob: patientDOB,
                patientId,
                insuranceCarrier: insurance,
                doctorsName: doctor
              })
            }}
          >
            Apply filters
          </Button>
          <Button
            style={{width: '100px'}}
            variant="warning"
            type="button"
            onClick={() => {
              resetFilters({});
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default IntelepeerFormFilter
