import fileDownload from "js-file-download";
import { React, useEffect, useState } from "react";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { convertDocxToPdf, convertPdfToTable } from "../../../services/tools";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TemplateWrapped } from "../../Layout";
import UserHelp from "../../util/UserHelp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayModalMessage } from "../../../util/displayMessage";
import { TrendingUpOutlined } from "@material-ui/icons";
import { download } from "../../../util/file";

const MySwal = withReactContent(Swal);

export default function FileConverter() {
  const MAX_FILE_SIZE = 20480000;
  const options = {
    pdf: "Docx - PDF",
    xlsx: "PDF - Xlsx",
    csv: "PDF - CSV",
  };
  const accepts = {
    pdf: ".docx",
    xlsx: ".pdf",
    csv: ".pdf",
  };
  const [file, setFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(Object.keys(options)[0]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [processing, setProcessing] = useState(false);

  const resetConvertInfo = () => {
    setSelectedOption(Object.keys(options)[0]);
    setUploadProgress(null);
    setFile(null);
    document.querySelector("form").reset();
  };

  const changeFileHandler = (e) => {
    const tempFile = e.target.files[0];
    if (!tempFile) return;

    if (`.${tempFile.name.split(".")[1]}` !== accepts[selectedOption]) {
      MySwal.fire({
        icon: "error",
        title: "Wrong File Extension",
        text: `File extension for ${options[selectedOption]} must be ${accepts[selectedOption]}.`,
      });
      return;
    }

    if (tempFile.size > MAX_FILE_SIZE) {
      MySwal.fire({
        icon: "error",
        title: "File Size Exceeded",
        text: "File size must less than 20MB.",
      });
      return;
    }

    // setFile({selectedFile: new File([tempFile], tempFile.name, {type: tempFile.type})});
    setFile({ selectedFile: tempFile });
  };

  const changeOptionsHandler = (e) => {
    setSelectedOption(e.target.value);
    if (file != null) {
      const fileExtension = `.${file.selectedFile.name.split(".")[1]}`;
      if (accepts[e.target.value] != fileExtension) {
        document.querySelector("input[type='file']").value = "";
        setFile(null);
      }
    }
  };

  const processHandler = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const formData = new FormData();
    formData.append("source", file.selectedFile);
    formData.append("extension", selectedOption);

    const call =
      selectedOption === "pdf" ? convertDocxToPdf : convertPdfToTable;

    const fileName = `${
      file.selectedFile.name.split(".")[0]
    }.${selectedOption}`;

    call(formData, setUploadProgress, setProcessing)
      .then(([status, fileRes]) => {
        if (!status) {
          displayModalMessage(
            "Warning",
            "Dear user, there was a problem uploading your file, please try again"
          );
          return;
        }

        download(fileRes.data, fileName, () => {
          displayModalMessage(
            "Warning",
            "Dear user, there was a problem uploading your file, please try again"
          );
        }).then((r) => {
          if (r) {
            displayModalMessage(
              "Success",
              "File converted correctly",
              "success"
            );
          }
        });
      })
      .finally(() => {
        resetConvertInfo();
        setProcessing(false);
      });
  };

  return (
    <TemplateWrapped title="File Converter">
      <div className="container">
        <Form onSubmit={processHandler}>
          <div className="row align-items-end">
            <div className="col-5">
              <Form.Group>
                <Form.Label>Convertion Options</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOption}
                  onChange={changeOptionsHandler}
                  custom="true"
                >
                  {Object.keys(options).map((key) => (
                    <option key={key} value={key}>
                      {options[key]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>

            <div className="col-5">
              <Form.Group>
                <Form.Label>File Selector (Max 20MB)</Form.Label>
                <Form.File
                  type="file"
                  label={!file ? "Select File" : file.selectedFile.name}
                  onChange={changeFileHandler}
                  accept={accepts[selectedOption]}
                  lang="en"
                  disabled={selectedOption == ""}
                  custom={TrendingUpOutlined}
                />
              </Form.Group>
            </div>

            <div className="col-2">
              <Form.Group>
                <Button
                  type="submit"
                  as="input"
                  disabled={selectedOption == "" || file == null || processing}
                  value="Process..."
                  custom="true"
                />
              </Form.Group>
            </div>
          </div>
        </Form>

        <div className="row">
          <div className="col-10">
            {uploadProgress ? (
              <>
                <div className="text-center mt-3">
                  {uploadProgress === 100 ? (
                    <UserHelp>
                      Dear user, we are uploading your file, please wait{" "}
                      <FontAwesomeIcon spin icon="spinner" />
                    </UserHelp>
                  ) : (
                    <ProgressBar
                      now={uploadProgress}
                      label={`${uploadProgress}%`}
                    />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
}
