import React from "react";
import CustomTable from "../../CustomTable";
import { TemplateWrapped } from "../../Layout";
import { HeaderFilterButton } from "../../RemarkTemplate/Body";
import BillingFilter from "./components/BillingFilter";
import useBilling from "./useBilling";
import LegendRecordsFiltered from "./components/LegendRecordsFiltered";
import './css/index.css'
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const  Billing = () => {
  const {
    dowloadRecords,
    setMyFilters,
    data,
    myFilters,
    columns
  } = useBilling();

  return (
    <TemplateWrapped
      headerButtons={
        <>
          <HeaderFilterButton
            active={
              //   filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              false
            }
            render={BillingFilter}
            componentProps={{
              setFilters: setMyFilters,
            }}
          />
        </>
      }
    >
      {data.records && ( <CustomTable
        title={<LegendRecordsFiltered
          filter={myFilters}
          numRecords={data.recordsQty}
          dowloadRecords={dowloadRecords}
        />}
        search={true}
        columns={columns}
        data={data.records}
        pageSize={25}
        components={{
          ...CustomTable.Components,
          Pagination: (props) => (
            <CustomTable.Components.Pagination options={[25, 50]} {...props} />
          ),
        }}
      />)}
      {!data.records && (
        <div className='panel'>
          <div className='panel-body'>
            <h3 className='panel-title'>Dear user, select the filters to display the data</h3>
          </div>
        </div>
      )}
    </TemplateWrapped>
  );
}

export default withSelectedPractices(Billing)
