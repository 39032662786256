import {useEffect, useState} from "react";
import {displayModalMessage} from "../../../../../../util/displayMessage";
import * as feeScheduleService from "../../../services/feeSchedule.services";

const useTableExcel = (
  data,
  edit,
  updateData
) => {
  const [rowSelected, setRowSelected] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [rows, setRows] = useState([])
  const [selectedRows, setSelectedRows] = useState([])


  useEffect(() => {
    setRows(data)
  }, [data])

  const handleClickEdit = (row) => {
    const {tableData, ...rowData} = row
    setShowModal(true)
    setRowSelected(rowData)
  }

  const handleUpdate = (obj) => {
    try {
      if (edit) {
        // TODO implement duplicate codes function
        feeScheduleService.updateSchedule(obj).then(([status, res]) => {
          if (status) {
            updateRowsEdition(obj)
          }
        })
      } else {
        verifyDuplicateCode(obj.codeService, obj.index)

        if (obj.codeService !== rowSelected.codeService || obj.text !== rowSelected.description || obj.price !== rowSelected.price) {
          updateRows(obj)
        }
      }

    } catch (e) {
      displayModalMessage('Duplicate', e.message)
    }
  }

  const updateRows = (newRow) => {
    const _rows = [...rows]
    const index = _rows.findIndex(row => row.index === newRow.index)


    setShowModal(false)
    _rows[index] = {...newRow}
    setRowSelected(null)
    updateData(_rows)
    //setRows(_rows)
  }

  const updateRowsEdition = (newRow) => {
    const _rows = [...rows]
    const index = _rows.findIndex(row => row._id === newRow.index)

    const newObj = {
      price: newRow.price.substring(1),
      serviceCode: newRow.codeService,
      serviceDescription: newRow.description
    }

    const actualRow = _rows[index]

    _rows[index] = {...actualRow, ...newObj}
    setRows(_rows)
    setShowModal(false)
  }

  const verifyDuplicateCode = (newCode, index) => {
    const codeExist = rows.filter((row) => {
      return row.codeService === newCode && row.index !== index
    })

    if (codeExist.length > 0) {
      throw new Error(`${newCode} code already exists!`)
    }
  }

  return {
    rowSelected,
    showModal,
    rows,
    setShowModal,
    setRows,
    setRowSelected,
    handleClickEdit,
    handleUpdate,
    setSelectedRows,
    selectedRows
  }
}

export default useTableExcel