import {useEffect, useState} from "react"
import * as feeScheduleServices from "../../../services/feeSchedule.services";
import {displayModalMessage} from "../../../../../../util/displayMessage";

const useUpdateRecords = (
  locationCarrier = [],
  locationPractice = null,
  locationYear = null,
  toUpdate = false
) => {
  const [practice, setPractice] = useState(locationPractice)
  const [year, setYear] = useState(locationYear)
  const [data, setData] = useState(null)
  const [loadExcel, setLoadExcel] = useState(false)
  const [showRecords, setShowRecords] = useState(false)
  const [carriers, setCarriers] = useState(locationCarrier)

  useEffect(() => {
    if (practice && year && !toUpdate) {
      feeScheduleServices.searchCarrierByYear(practice, year)
        .then(([status, res]) => {
          if (status) {
            setCarriers(res)
            if (res.length === 0) {
              displayModalMessage('No records', <div>
                <span>No records were found, please provide an excel with the information</span>
              </div>)
            }
          }
          // setLoadExcel(true)
          setShowRecords(true)
        })
    }
  }, [practice, year])

  return {
    practice,
    year,
    data,
    carriers,
    loadExcel,
    showRecords,
    setYear,
    setPractice
  }
}

export default useUpdateRecords