import {
  deletePractice,
  getPracticeByClient,
  getPracticeWithFilter,
  getClients, getPracticeAll
} from "./ClientPractice.service"
import {useEffect, useState} from "react";
import {useAsyncTableData} from "../../CustomTable/hooks";
import {getUrlParams} from "../../../util/browser";

export const initialValues = {
  "_id": "",
  "date": "",
  "desc": "",
  "name": "",
  "clientId": "",
  "clientName": "",
  "state": "",
  "city": ""
}


const useClientPractice = () => {
  const [clients, setClients] = useState([]);
  const [dataClients, setDataClients] = useState([]);
  const [myFilters, setMyFilters] = useState({})
  const [clientSelectId, setClientSelectId] = useState("");
  const [practice, setPractice] = useState([]);
  const [practiceIdSelect, setPracticeIdSelect] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    getPracticeWithFilter,
    {
      ...initialValues,
      ...getUrlParams(Object.keys(initialValues))
    },  null
  )

  useEffect(async () => {
    const [clients] = await loadData();
    setDataClients(clients[1]["records"])
  }, []);

  useEffect(async () => {
      await loadPracticeWithParam()
    }, [myFilters]
  )

  const loadPractice = async () => {
    const [status, response] = await getPracticeByClient(clientSelectId);
    if (status) {
      setPractice(response['records'])
    }
  }

  const deletePracticeById = async (practiceId) => {
    return await deletePractice(practiceId)
  }

  const loadPracticeWithParam = async () => {
    const practices = await getPracticeWithFilter(myFilters);
    setPractice(practices[1]['records'])
  }

  const loadData = async () => {
    const [ dataClients] = await Promise.all([
      // getClientsAll(),getClients()
      getClients()
    ]);

    return [dataClients];
  }

  return {
    loadPractice,
    deletePracticeById,
    loadPracticeWithParam,
    clients,
    setClients,
    myFilters,
    setMyFilters,
    clientSelectId,
    setClientSelectId,
    practice,
    setPractice,
    showModal,
    setShowModal,
    showModalEdit,
    setShowModalEdit,
    practiceIdSelect,
    setPracticeIdSelect,
    dataClients,
    setDataClients,
    dataSource,
    tableReference,
    refreshData
  }
}

export default useClientPractice;