import React from "react";
import { Link } from "react-router-dom";

const LinkFeeScheduleUpdate = ({ carrier, year, practice }) => {
  return (
    <div>
      <p>Fee Schedule already exists, you can update in the next link</p>
      <Link
        to={
          ("/feeschedule/update",
          {
            state: { carrier, year, practice },
          })
        }
        className="btn btn-link"
      >
        Go to update
      </Link>
    </div>
  );
};

export default LinkFeeScheduleUpdate;
