import {createRef, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as services from '../../../services/dentalmation.services'

const useDentalMationProduct = () => {
  const {id} = useParams()
  const [subscriptionState, setSubscriptionState] = useState({})
  const printRef = createRef()

  useEffect(() => {
    async function getSubscription() {
      const [status, res] = await services.getSubscriptionInfo(id)
      if (status) {
        setSubscriptionState(res)
      }
    }

    getSubscription()
  }, [])

  return {
    generalInfo: {
      ...subscriptionState.user,
      ...subscriptionState.product
    },
    businessInfo: {
      ...subscriptionState.product,
      lastPaymentDate: subscriptionState.lastPaymentDate
    },
    invoiceHistory: subscriptionState.invoiceHistory || [],
    printRef
  }
}

export default useDentalMationProduct