import React from 'react'

const DetailRecordsDataQuality = ({ info }) => {
   console.log(info);
   const trustedInfo = (info.data.trustedInfo)? "True":"False",
         Comment = info.data.comment;

   return (
      <div className="dqaDetails">
      
             {
               info.data.record.map((i, idx)=>{
                        // console.log(i, idx);
                  return <div key={idx} className="grid3">
                           <div className="col text-left"> {i[0].label}</div>
                           <div className="col"> {i[0].pms}</div>
                           <div className="col"> {i[0].form}</div>
                        </div>
                    
                })
               }
               
               <div className="mt20">
                  <span className="text-bold">Trusted info:</span> {trustedInfo}
               </div>
               <div className="mt10">
                  <span className="text-bold">Comment:</span> {Comment}
               </div>
                 
      </div>
   )
}

export default DetailRecordsDataQuality
