import React from "react";
import {ErrorMessage, Formik, Form as FormikForm} from "formik";
import {Col, Row, Form, Button} from "react-bootstrap";
import Select from "react-select";
import * as Yup from 'yup';

const createValidationSchema = (name) => Yup.object().shape({
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().min(Yup.ref('startDate'), 'End date cannot be before start date').required('End Date is required'),
  [name]: Yup.string().required('Select One option'),
});

const SummaryReportForm = ({initialValues, onSubmit, labelSelection, selectName, options}) => {
  return(
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={createValidationSchema(selectName)}
    >
      {props => (
        <FormikForm>
          <Row className="justify-content-md-center">
            <Col md={3}>
              <Form.Group>
                <Form.Label htmlFor={selectName}>
                  {labelSelection}
                </Form.Label>
                <Select
                  name={selectName}
                  options={options}
                  onChange={event => {
                    props.setFieldValue(selectName, event.value)
                  }}
                />
                <ErrorMessage name={selectName} component="div" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={props.values.startDate}
                  required
                  onChange={event => {
                    props.setFieldValue('startDate', event.target.value)
                  }}
                />
                <ErrorMessage name='startDate' component="div" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={props.values.endDate}
                  required
                  onChange={event => {
                    props.setFieldValue('endDate', event.target.value)
                  }}
                />
                <ErrorMessage name='endDate' component="div" />
              </Form.Group>
            </Col>
            <Col md={3} className="align-content-center mt-4 pt-2">
              <Form.Group>
                <Button
                  type='submit'
                  className='btn btn-primary btn-block'
                >
                  Generate
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </FormikForm>
      )}
    </Formik>
  )
}

export default SummaryReportForm
