import { Api, decryptApiResponse } from "./Api";
import fileDownload from "js-file-download";
import { decryptData } from "../config/encryptData";
import { statusAndResponse } from "../util/requestHandler";
export const getDataInsurranceVerification = (limit, filters) => {
  function onSuccess(resp) {
    if (resp.data.success) {
      // console.log("Encrypted Respuesta",resp.data.value);
      return decryptData(JSON.parse(resp.data.value));
    }

    throw new Error("");
  }

  return statusAndResponse(
    Api.post("/api/iv/processflow/get", { limit, ...filters }),
    onSuccess
  );
};

export const getFile = (url, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      Api.defaults.headers.get["Content-Type"] = "application/json";
      Api.get(url, { responseType: "blob" })
        .then((resp) => {
          // console.log(resp);
          fileDownload(resp.data, fileName);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCarriers = () => {
  return statusAndResponse(Api.get("/api/iv/carrier"), (resp) => {
    return decryptApiResponse(resp).then((dataCarrier) => {
      return dataCarrier.map((data) => {
        return {
          value: data._id,
          text: data.name,
        };
      });
    });
  });
};

export const getCarrierForm = (id) => {
  return statusAndResponse(
    Api.post("api/iv/carrier/form", { carrierId: id, typeForm: "iv" }),
    decryptApiResponse
  );
};

export const registerNewIVerification = (data) => {
  return statusAndResponse(Api.post("api/iv/carrier/store", data));
};
