import styled from "styled-components";
import usePracticesByClientSelected from "../PracticesByClientSelected/hooks/usePracticesByClientSelected";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const ClientSelected = ({showButton = true}) => {
  const {client, countSelectedPractices, togglePracticeModal} = usePracticesByClientSelected();

  return (
    client ? (
        <Wrapper>
          <strong>{client.clientName} - practices selected: {countSelectedPractices}</strong>
          {showButton && (
            <button className='btn btn-sm btn-primary ml-3' onClick={togglePracticeModal}>
              Show
            </button>
          )}
        </Wrapper>
      ) : (
        "*No Practice Selected*"
      )
  )
}

export default ClientSelected;