import {

  getCity,
  saveClientPractice,
  getPracticeById,
  updateClientPractice,
  getStateV2
} from "../ClientPractice.service"
import {useEffect, useState} from "react";
import {displayModalMessage} from "../../../../util/displayMessage";

export const initialState = {
  "desc": "",
  "dqaSMS": "",
  "folderId": "",
  "name": "",
  "spreadsheetId": "",
  "clientId": "",
  "address": "",
  "cityId": "",
  "stateId": "",
  "spreadsheetFields": {
    "confirmation": "",
    "driveUrl": "",
    "note": "",
    "fileName": "",
    "history": "",
    "elgCodes": "",
  },
};
const useClientPracticeForm = (clientSelectId, setShowModal, practiceIdSelect, editing, setShowModalEdit,loadPracticeWithParam) => {

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(!editing)
  const [stateIdSelect, setStateIdSelect] = useState("");
  const [city, setCity] = useState([]);
  const [data, setData] = useState(initialState);

  useEffect(async () => {
      await loadState()
    }, [stateIdSelect]
  )

  useEffect(async () => {
      if (practiceIdSelect) {
        await loadData(practiceIdSelect)
        setLoading(true)
      }
    }, [practiceIdSelect]
  )

  const loadState = async () => {
    let response = await getStateV2();
    response = response[1]['data']['value'].map(v => ({...v, value: v.id, label: v.name}))
    setState(response)
  }

  const loadData = async (practiceIdSelect) => {
    const [s, response] = await getPracticeById(practiceIdSelect);
    setData(response)
    await loadCity(response.state)
  }
  const loadCity = async (stateId) => {
    if(stateId)
    {
      let response = await getCity(stateId);
      response = response[1]['data']['value'].map(item => ({...item, value: item.id, label: item.name}))
      setCity(response)
    }
  }

  const handleSubmit = (data) => {
    if (editing) {
      updateClientPractice(data, practiceIdSelect).then(([status, r]) => {
        if (status) {
          displayModalMessage(
            "Practice Edited Successfully",
            <span>Practice Edited</span>,
            "success",
            () => {
              setShowModalEdit(false)
              loadPracticeWithParam()
            }
          );
        }
      })
    } else {
      saveClientPractice(data).then((status , response) => {
        if (status) {
          displayModalMessage(
            "Practice Saved Successfully",
            <span>Practice Saved</span>,
            "success",
            () => {
              setShowModal(false)
              loadPracticeWithParam()
            }
          );

        }
      })
    }

  }

  return {
    state,
    setState,
    city,
    setCity,
    setStateIdSelect,
    loadCity,
    handleSubmit,
    data,
    loading
  }
}

export default useClientPracticeForm;