import React, { createContext, useEffect, useState } from "react";
import Select from "./components/Select";
import Text from "./components/Text";
import WrappedInput from "./components/WrappedInput";

const defaultFormData = {};

function useForm(data) {
  const [_data, _setData] = useState(data);
  useEffect(() => {
    _setData(data);
  }, [data]);

  return {
    get: (key) => {
      if (!key) return _data;
      return _data[key] || "";
    },
    set: (name, value) => {
      _setData((d) => ({ ...d, [name]: value }));
    },
  };
}

export const CustomFormContext = createContext(null);

export default function CustomForm({
  formRef,
  onSubmit,
  data = defaultFormData,
  ...props
}) {
  const formData = useForm(data);

  if (typeof formRef !== "undefined") formRef.current = formData;

  return (
    <CustomFormContext.Provider value={formData}>
      <form
        {...props}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit && onSubmit(formData.get());
        }}
      >
        {props.children}
      </form>
    </CustomFormContext.Provider>
  );
}

CustomForm.Select = Select;
CustomForm.Text = Text;
CustomForm.WrappedInput = WrappedInput;
