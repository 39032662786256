import {useEffect, useState} from "react";
import XLSX from 'xlsx'
import {displayModalConfirmation, displayModalMessage} from "../../../../../../util/displayMessage";
import * as feeScheduleServices from '../../../services/feeSchedule.services'

const useLoadExcel = (
  selectedPractice,
  selectedYear,
  edit = false,
  carriers = [],
  toUpdate = false
) => {
  const [excel, setExcel] = useState(null)
  const [sheets, setSheets] = useState(carriers)
  const [data, setData] = useState(null)
  const [selectedSheet, setSelectedSheet] = useState(null)
  const [newName, setNewName] = useState('')
  const [duplicates, setDuplicates] = useState([])
  const [wrongPrice, setWrongPrice] = useState([])
  const [rows, setRows] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [arrSelected, setArrSelected] = useState('rows')

  useEffect(() => {
    if (toUpdate)
      handleSheetChange(carriers[0])
  }, [])

  const handleChange = (file) => {
    readFile(file)
  }

  const handleSheetChange = (sheet) => {
    setSelectedSheet(sheet)
    setNewName(sheet)

    if (edit) {
      feeScheduleServices.getCarrierData(sheet, selectedPractice, selectedYear).then(([status, res]) => {
        if (status) {
          setData(res)
        }
      })
    } else {
      readSheetData(sheet)
    }
  }

  const readFile = (file) => {
    const reader = new FileReader()
    let arrBook = {}
    reader.onload = ({target: {result}}) => {
      const book = XLSX.read(result, {type: 'array'})
      book.SheetNames.map(sheet => {
        const dataSheet = XLSX.utils.sheet_to_json(book.Sheets[sheet], {header: 1})
        arrBook[sheet] = dataSheet.filter((d, i) => {
          return d.length === 3 && i > 0
        }).map((d, index) => {
          return {
            index,
            codeService: d[0],
            description: d[1],
            price: d[2]
          }
        })
      })
      setExcel(arrBook)
      setSheets(book.SheetNames)
    }

    reader.readAsArrayBuffer(file)
  }

  const readSheetData = (sheet) => {
    verifyDuplicates(excel[sheet])
  }

  const verifyDuplicates = (arrData) => {
    let _rows = [...arrData]
    let dupli = []

    const codes = arrData.map(arr => {
      return arr.codeService
    }).filter((code) => code)


    const unique = Array.from(new Set(codes))

    if (codes.length !== unique.length) {
      displayModalMessage('Duplicates', 'Some of the codes are duplicates')
      const dupliCodes = getDuplicates(codes)

      _rows = _rows.filter((row) => {
        return !dupliCodes.includes(row.codeService)
      })

      dupli = arrData.filter((arr) => {
        return dupliCodes.includes(arr.codeService)
      })
    } else {
      setDuplicates([])
    }
    let wrong = getWrongPrices(arrData)
    _rows = _rows.filter((r) => {
      return !wrong.some((w) => {
        return w.codeService === r.codeService
      })
    })

    setData(_rows)
    setRows(_rows)
    setDuplicates(dupli)
    setWrongPrice(wrong)
    setArrSelected('rows')
  }

  const processSheet = () => {
    const sheetData = [...rows, ...wrongPrice, ...duplicates]

    displayModalConfirmation('Proccess', 'Are you sure to continue?', () => {
      const wrongCodes = verifyCodeInEveryRow(sheetData)
      if (wrongCodes.length > 0) {
        displayModalMessage('Price', `${wrongCodes.join(' ')} has wrong price`)
        return;
      }

      feeScheduleServices.processSheet({
        practiceId: selectedPractice,
        year: selectedYear,
        carrierName: selectedSheet,
        description: newName,
        services: removeKeys(sheetData)
      }).then(([status, res]) => {
        if (status) {
          displayModalMessage('Saved', res, 'success')
          setData(null)
          setDuplicates([])
          setWrongPrice([])
          setSelectedSheet(null)
        } else {
          setShowModal(true)
        }
      })
    })
  }

  const removeKeys = (sheetData) => {
    return sheetData.map(({codeService, description, price}) => {
      return {
        serviceCode: codeService,
        serviceDescription: description,
        price
      }
    })
  }

  const verifyCodeInEveryRow = (sheetData) => {
    const codes = sheetData.filter(r => {
      return !/^\$[1-9]?[0-9]+(\.\d{1,2})?$/.test(r.price)
    })

    return codes.reduce((curr, acc) => {
      return [...curr, acc.codeService]
    }, [])
  }

  const getDuplicates = (codes) => {
    return codes.filter((code, index) => {
      return ((codes.indexOf(code) !== index) && code)
    })
  }

  const getWrongPrices = (sheetRows) => {
    return sheetRows.filter(r => {
      return !invalidPrice(r.price)
    })
  }

  const invalidPrice = (price) => {
    const SYMBOL = '$'
    const priceString = price.toString()

    const firstChar = priceString.charAt(0)
    const restChar = priceString.slice(1).replace(',', '')

    if (restChar.length === 0) return false

    return firstChar === SYMBOL && !isNaN(Number(restChar))

  }

  const setWrongCodes = () => {
    setArrSelected('prices')
    setData(wrongPrice)
  }

  const setDuplicateCodes = () => {
    setArrSelected('duplicates')
    setData(duplicates)
  }

  const updateData = (newRows) => {
    if (arrSelected === 'rows') {
      setRows(newRows)
    }

    if (arrSelected === 'prices') {
      setWrongPrice(newRows)
    }

    if (arrSelected === 'duplicates') {
      setDuplicates(newRows)
    }

    setData(newRows)
  }

  const deleteRow = (rowToDelete) => {
    const _duplicates = duplicates.filter((d) => {
      return d.index !== rowToDelete.index
    })
    setDuplicates(_duplicates)
    setData(_duplicates)
  }

  const setRowsData = () => {
    setArrSelected('rows')
    setData(rows)
  }

  const deleteSelected = (rowsSelected) => {
    const d = duplicates.filter(row => !rowsSelected.includes(row))
    console.log(d)
    setDuplicates([...d])
    setData(d)
  }

  return {
    excel,
    sheets,
    data,
    newName,
    duplicates,
    wrongPrice,
    selectedSheet,
    showModal,
    setData,
    setExcel,
    setNewName,
    handleChange,
    handleSheetChange,
    processSheet,
    setWrongCodes,
    updateData,
    setDuplicateCodes,
    deleteRow,
    arrSelected,
    setRowsData,
    deleteSelected
  }
}

export default useLoadExcel