import {useAsyncTableData} from "../../../CustomTable/hooks";
import * as services from '../services/dentalmation.services';
import {useMemo, useState} from "react";
import {getUrlParams} from "../../../../util/browser";

const initialState = {
  user: "",
  product: "",
  practice: "",
};

const useDentalMationClients = () => {
  const initialParams = useMemo(() => {
    return getUrlParams(Object.keys(initialState));
  }, []);

  const [anchor, setAnchor] = useState(null);

  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    services.getAllSubscriptions,
    initialParams
  )

  const cancelSubscription = (id = 0) => {
    console.log('id')
  }

  const handleOpenFilters = (e) => {
    setAnchor(e.currentTarget)
  }

  const handleCloseFilters = () => {
    setAnchor(null)
  }

  return {
    dataSource,
    tableReference,
    refreshData,
    cancelSubscription,
    handleOpenFilters,
    anchor,
    handleCloseFilters
  }
}

export default useDentalMationClients