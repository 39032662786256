import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Icon as MaterialIcon} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import getMenu from "../../../menu";
import * as auth from "../../../util/auth";
import {getVisibleLevels} from "../../../util/menuBuilder";
import MENU from "./icons";

import './menu.css'
import CustomImg from "../../CustomImg";
import SIZE from "./size";

function SideItem(
  {
    item,
    index,
    isActive,
    activeChild,
    subMenuIsOpen,
    isHovered,
    handler,
    handleMouseEnter,
    handleMouseLeave,
    isFolded,
  }) {
  if (item.isParent && item.submenus.length === 0) return null;

  const Icon = item.icon ? getIcon(item.icon, isFolded) : null;

  if (!item.submenus || item.submenus.length === 0) {
    return (
      <li className={`site-menu-item ${isActive ? "active" : ""}`}>
        <Link to={item.path} id={"item-" + index}>
          {Icon}
          <span className="site-menu-title">{item.label}</span>
        </Link>
      </li>
    );
  }

  return (
    <li
      className={
        subMenuIsOpen || (isHovered && isFolded)
          ? "site-menu-item active hover open"
          : "site-menu-item "
      }
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <a
        href="#"
        id={"item-" + index}
        onClick={(e) => {
          e.preventDefault();
          handler(index);
        }}
      >
        {Icon} <span className="site-menu-title">{item.label}</span>
        <span className="site-menu-arrow"></span>
      </a>
      <ul className="site-menu-sub" style={{maxHeight: 600}}>
        {item.submenus.map((subItem, subIndex) => {
          return (
            <SideItem
              key={subIndex}
              isActive={activeChild === subItem.path}
              item={subItem}
              index={subIndex}
            />
          );
        })}
      </ul>
    </li>
  );
}

const TemplateMenu = ({menuIsCollapsed, activeItem}) => {
  const [menuUser, setMenuUser] = useState([]);
  const [menuItemSOpen, setMenuItemOpen] = useState([]);
  const [menuItemSHover, setMenuItemHover] = useState([]);

  useEffect(() => {
    if (menuIsCollapsed) return;
    const activeItemIsOnSubMenu =
      menuUser.filter((x) => x.submenus.length === 0 && x.path === activeItem)
        .length === 0;

    if (activeItemIsOnSubMenu) {
      const s = menuUser.reduce((a, i) => {
        a.push(
          i.submenus.filter((x) => x.path === activeItem).length === 0
            ? null
            : true
        );
        return a;
      }, []);
      setMenuItemOpen(s);
    }
  }, [activeItem, menuIsCollapsed, menuUser]);

  // Close all sub menue's when the sidebar is collapsed
  useEffect(() => {
    if (menuIsCollapsed) {
      setMenuItemOpen([]);
    }
  }, [menuIsCollapsed]);

  useEffect(() => {
    let cleaningUp = false;
    setMenuUser(getVisibleLevels(getMenu(), auth.getPermissions()));
    const number = auth.subscribe(() => {
      if (!cleaningUp)
        setMenuUser(getVisibleLevels(getMenu(), auth.getPermissions()));
    });

    return function cleanup() {
      cleaningUp = true;
      auth.unsuscribe(number);
    };
  }, []);

  const handleClickMenuItem = (index) => {
    let menu = [...menuItemSOpen];
    if (menu[index]) {
      menu[index] = false;
    } else {
      menu[index] = true;
    }
    setMenuItemOpen(menu);
  };

  const handleMouseEnterItemMenu = (index) => {
    let menu = [];
    menu[index] = "hover";

    setMenuItemHover(menu);
  };

  const handleMouseLeaveItemMenu = (index) => {
    let menu = [...menuItemSHover];

    menu[index] = "";

    setMenuItemHover(menu);
  };

  return (
    <div className="site-menubar">
      <div className="scrollable scrollable-inverse scrollable-vertical site-menubar-body is-disabled">
        <div>
          <div>
            <ul className="site-menu" data-plugin="menu">
              {menuUser.map((m, key) => {
                if (m.submenus.length === 0) return null
                return (
                  <React.Fragment key={key}>
                    <CategoryDivider>{m.label}</CategoryDivider>
                    <UserMenu
                      menuUser={m.submenus}
                      handleClickMenuItem={handleClickMenuItem}
                      activeItem={activeItem}
                      menuItemSHover={menuItemSHover}
                      menuItemSOpen={menuItemSOpen}
                      handleMouseEnterItemMenu={handleMouseEnterItemMenu}
                      handleMouseLeaveItemMenu={handleMouseLeaveItemMenu}
                      menuIsCollapsed={menuIsCollapsed}
                    />
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

function UserMenu(
  {
    menuUser,
    handleClickMenuItem,
    activeItem,
    menuItemSHover,
    menuItemSOpen,
    handleMouseEnterItemMenu,
    handleMouseLeaveItemMenu,
    menuIsCollapsed,
  }
  ) {
  return menuUser.map((item, index) => {
    return (
      <SideItem
        key={index}
        handler={() => {
          handleClickMenuItem(index);
        }}
        activeChild={activeItem}
        isActive={item.path === activeItem}
        isHovered={menuItemSHover[index]}
        subMenuIsOpen={menuItemSOpen[index]}
        item={item}
        index={index}
        handleMouseEnter={handleMouseEnterItemMenu}
        handleMouseLeave={handleMouseLeaveItemMenu}
        isFolded={menuIsCollapsed}
      />
    );
  });
}

function CategoryDivider(props) {
  return <li className={`site-menu-category`} {...props}></li>;
}

function getIcon(icon, isFolded) {
  if (icon === "default") {
    return (
      <FontAwesomeIcon
        className="mr-3 site-menu-icon"
        icon={"external-link-alt"}
      />
    );
  }

  const [prefix, iconName] = icon.split("-");
  if (icon === "") return null;

  if (prefix === "fa") {
    return <FontAwesomeIcon className="mr-3 site-menu-icon" icon={iconName}/>;
  }

  if (prefix === "mi") {
    return <MaterialIcon className="site-menu-icon">{iconName}</MaterialIcon>;
  }

  if (prefix === "svg") {
    return getIconByName(isFolded, iconName)
  }
}

const getIconByName = (isFolded, iconName) => {
  const ICONS = {
    home: <CustomImg img={isFolded ? MENU.homeFolded : MENU.home} alt='Home' width={isFolded ? SIZE.homeFolded_width : SIZE.home_width}  height={isFolded ? SIZE.homeFolded_height : SIZE.home_height} />,
    insurance: <CustomImg img={isFolded ? MENU.insuranceFolded : MENU.insurance} alt='Insurance Verification'  width={isFolded ? SIZE.insuranceFolded_width : SIZE.insurance_width} height={isFolded ? SIZE.insuranceFolded_height : SIZE.insurance_height}/>,
    dataquality: <CustomImg img={isFolded ? MENU.dataQualityFolded : MENU.dataQuality} alt='Data quality' width={isFolded ? SIZE.dataQualityFolded_width : SIZE.dataQuality_width} height={isFolded ? SIZE.dataQualityFolded_height : SIZE.dataQuality_height}/>,
    suscription: <CustomImg img={isFolded ? MENU.subscriptionFolded : MENU.subscription} alt='Customer Subscriptions'  />,
    settings: <CustomImg img={isFolded ? MENU.settingsFolded : MENU.settings} alt='Settings' width={isFolded ? SIZE.settingsFolded_width : SIZE.settings_width} height={isFolded ? SIZE.settingsFolded_height : SIZE.settings_height} />,
    template: <CustomImg img={isFolded ? MENU.templateFolded : MENU.template} alt='Templates' width={isFolded ? SIZE.templateFolded_width : SIZE.template_width}  height={isFolded ? SIZE.templateFolded_height : SIZE.template_height}/>,
    users: <CustomImg img={isFolded ? MENU.usersFolded : MENU.users} alt='Users' width={isFolded ? SIZE.usersFolded_width : SIZE.users_width}  height={isFolded ? SIZE.usersFolded_height : SIZE.users_height}/>,
    connections: <CustomImg img={isFolded ? MENU.connectionsFolded : MENU.connections} alt='Connections' width={isFolded ? SIZE.connectionsFolded_width : SIZE.connections_width}  height={isFolded ? SIZE.connectionsFolded_height : SIZE.connections_height}/>,
    tools: <CustomImg img={isFolded ? MENU.toolsFolded : MENU.tools} alt='Tools' width={isFolded ? SIZE.toolsFolded_width : SIZE.tools_width} height={isFolded ? SIZE.toolsFolded_height : SIZE.tools_height}/>,
    files: <CustomImg img={isFolded ? MENU.filesFolded : MENU.files} alt='File Report' width={isFolded ? SIZE.filesFolded_width : SIZE.files_width} height={isFolded ? SIZE.filesFolded_height : SIZE.files_height}/>,
    reports: <CustomImg img={isFolded ? MENU.reportsFolded : MENU.reports} alt='Reports' width={isFolded ? SIZE.reportsFolded_width : SIZE.reports_width}  height={isFolded ? SIZE.reportsFolded_height : SIZE.reports_height}/>,
    aircall: <CustomImg img={isFolded ? MENU.airCallFolded : MENU.airCall} alt='Recall' width={isFolded ? SIZE.reportsFolded_width : SIZE.reports_width}  height={isFolded ? SIZE.reportsFolded_height : SIZE.reports_height}/>,
  }

  return(ICONS[iconName])
}

export default TemplateMenu;
