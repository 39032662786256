import { Icon, IconButton, Popover, Tooltip } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  getUrlParams,
  removeUrlParams,
  setURLParams,
} from "../../../util/browser";
import { getDateAsString } from "../../../util/date";
import { displayModalConfirmation } from "../../../util/displayMessage";
import { withoutEmptyKeys } from "../../../util/object";
import CustomTable, { TextColumnHighlighted } from "../../CustomTable";
import { useAsyncTableData } from "../../CustomTable/hooks";
import DateRange from "../../DatePicker/DateRange";

import * as services from "./userRequest.services";

import "./styles/index.css";
import { TemplateWrapped } from "../../Layout";

const useUserRequest = () => {
  const history = useNavigate();

  const initialParams = useMemo(() => {
    return getUrlParams(Object.keys(initialState));
  }, []);

  const { dataSource, tableReference, refreshData } = useAsyncTableData(
    services.getRequests,
    initialParams
  );

  const approve = async ({ _id }) => {
    const [s] = await services.doAuthorize(_id);
    if (s) {
      toast.success("The request was approved successfully");
      refreshData();
    }
  };
  const deny = async ({ _id }) => {
    const [s] = await services.doNotAuthorize(_id);
    if (s) {
      toast.success("The request was denied successfully");
      refreshData();
    }
  };
  const goToDetail = ({ _id }) => {
    history(`/user-request-detail/${_id}`);
  };

  return {
    approve,
    deny,
    goToDetail,
    dataSource,
    refreshData,
    tableReference,
  };
};

const STATUSES = {
  pending: "Pending",
  approved: "Approved",
  denied: "Denied",
};

const isPending = (d) => d === STATUSES.pending;
const isApproved = (d) => d === STATUSES.approved;
const isDenied = (d) => d === STATUSES.denied;

const confirmDeny = (onConfirm) => {
  displayModalConfirmation(
    "Deny user request",
    <p className="confirmation-modal">
      Dear user, are you sure you want to <span className="deny">deny</span>{" "}
      this request?
    </p>,
    onConfirm
  );
};

const confirmApproval = (onConfirm) => {
  displayModalConfirmation(
    "Approve user request",
    <p className="confirmation-modal">
      Dear user, are you sure you want to{" "}
      <span className="approve">approve</span> this request?
    </p>,
    onConfirm
  );
};

const StatusColumn = ({ status }) => {
  let s = "";
  if (isPending(status)) {
    s = "warning";
  } else if (isDenied(status)) {
    s = "danger";
  } else if (isApproved(status)) {
    s = "success";
  }
  return (
    <div className="d-inline-block">
      <TextColumnHighlighted status={s} text={status} asDot />
    </div>
  );
};

const ActionButton = ({ tooltip, color, iconName, onClick }) => (
  <Tooltip title={tooltip}>
    <IconButton onClick={onClick}>
      <Icon color={color}>{iconName}</Icon>
    </IconButton>
  </Tooltip>
);

const UserRequest = () => {
  const { approve, deny, goToDetail, dataSource, tableReference, refreshData } =
    useUserRequest();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TemplateWrapped title="User Request">
      <div id="user-request">
        <Popover
          className="user-request"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          style={{ overflow: "inherit" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <UserRequestFilters apply={refreshData} />
        </Popover>
        <CustomTable
          pageSize={10}
          search={false}
          columns={[
            {
              title: "Names",
              field: "name",
              sorting: false,
            },
            {
              title: "Phone",
              align: "center",
              sorting: false,
              field: "phone",
            },
            {
              title: "City/State",
              render: ({ city = "", state = "" }) => {
                const cityAndState = city && state ? city + " " + state : "";
                return cityAndState;
              },
            },
            {
              title: "Request Date",
              align: "center",
              sorting: false,
              render: ({ createdAt }) => {
                return getDateAsString(createdAt, "yyyy-MM-DD");
              },
            },
            {
              title: "Status",
              render: (rowData) => <StatusColumn status={rowData.status} />,
              sorting: false,
              align: "center",
            },
            {
              title: "Actions",
              align: "center",
              sorting: false,
              render: (rowData) => {
                if (!isApproved(rowData.status)) {
                  return (
                    <>
                      <ActionButton
                        tooltip="Approve"
                        color="primary"
                        iconName="check"
                        onClick={() => {
                          confirmApproval(() => {
                            approve(rowData);
                          });
                        }}
                      />
                      <ActionButton
                        tooltip="Deny"
                        color="secondary"
                        iconName="close"
                        onClick={() => {
                          confirmDeny(() => {
                            deny(rowData);
                          });
                        }}
                      />
                    </>
                  );
                }

                return (
                  <ActionButton
                    tooltip="Details"
                    iconName="view_list"
                    onClick={() => {
                      goToDetail(rowData);
                    }}
                  />
                );
              },
            },
          ]}
          asyncDataSource={dataSource}
          tableReference={tableReference}
          actions={[
            {
              icon: "filter_alt",
              tooltip: "Filters",
              isFreeAction: true,
              onClick: (event) => handleClick(event),
            },
          ]}
        />
      </div>
    </TemplateWrapped>
  );
};

export default UserRequest;

const useURLParams = (initialState, onApply) => {
  const keys = useMemo(() => Object.keys(initialState), [initialState]);

  const [data, setData] = useState({
    ...initialState,
    ...getUrlParams(keys),
  });

  const params = withoutEmptyKeys(data);

  const reset = () => {
    removeUrlParams(keys);
    setData(initialState);
    onApply(withoutEmptyKeys(initialState));
  };

  const save = (middleware = (f) => f) => {
    if (keys.length > 0) {
      removeUrlParams(keys);
      setURLParams(params);
    }

    onApply(middleware(params));
  };

  return {
    data,
    params,
    setData,
    reset,
    save,
  };
};

const initialState = {
  name: "",
  status: "",
  startDate: null,
  endDate: null,
};

const UserRequestFilters = ({ apply }) => {
  const { data, setData, reset, save } = useURLParams(initialState, apply);

  const setDates = ([startDate, endDate]) => {
    setData((f) => ({ ...f, startDate, endDate }));
  };

  const _setData =
    (name) =>
    ({ target: { value } }) => {
      setData((f) => ({ ...f, [name]: value }));
    };

  return (
    <div className="p-3">
      {/* <h3>Filters</h3> */}
      <Form.Group>
        <Form.Label>Names</Form.Label>
        <Form.Control
          value={data.name}
          onChange={_setData("name")}
        ></Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Status</Form.Label>
        <Form.Control
          id="status"
          as="select"
          value={data.status}
          onChange={_setData("status")}
        >
          <option value="">*Not Filtered*</option>
          {Object.values(STATUSES).map((v) => (
            <option key={v}>{v}</option>
          ))}
        </Form.Control>
      </Form.Group>

      <DateRange
        onChange={setDates}
        startDateValue={data.startDate}
        endDateValue={data.endDate}
      />

      <button
        style={{ minWidth: "auto", display: "block", width: "100%" }}
        type="submit"
        className="btn btn-primary btn-arrow mt-3 "
        onClick={() => {
          save((f) => ({
            ...f,
            startDate: getDateAsString(f.startDate),
            endDate: getDateAsString(f.endDate),
          }));
        }}
      >
        Apply filters
      </button>
      <button
        style={{ minWidth: "auto", display: "block", width: "100%" }}
        type="button"
        className="btn btn-danger  mt-2"
        onClick={reset}
      >
        Reset
      </button>
    </div>
  );
};
