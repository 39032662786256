import { statusAndResponse } from "../../../../util/requestHandler";
import { Api, decryptApiResponse } from "../../../../config/Api";

export const updatePatientInfo = (data) => {
  const { _id, ...sendData } = data;
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };
  return statusAndResponse(
    Api.put("/api/airCall/patientInfo/" + _id, sendData),
    onSuccess,
  );
};

export const createVerification = (data, transactionId) => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };

  return statusAndResponse(
    Api.put(`/api/iv/transactions/${transactionId}/bookmarks`, data),
    onSuccess,
  );
};

export const createAndUpdateFbdFile = (data, transactionId) => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };

  return statusAndResponse(
    Api.post(`/api/iv/transactions/${transactionId}/bookmarks/file`, data),
    onSuccess,
  );
};

export const getTransactionBookmarks = (transactionId) => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };

  return statusAndResponse(
    Api.get(`/api/iv/transactions/${transactionId}/bookmarks`),
    onSuccess,
  );
};

export const getVerificationById = (verificationId) => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };
  return statusAndResponse(
    Api.get("/api/v2/iv/calls/verifications/" + verificationId),
    onSuccess,
  );
};

export const updateVerification = (verificationId, data) => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };

  return statusAndResponse(
    Api.put("/api/v2/iv/calls/verifications/" + verificationId, data),
    onSuccess,
  );
};

export const getCarriers = () => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };

  return statusAndResponse(Api.get("/api/v2/appsettings/carriers"), onSuccess);
};

export const getPhoneFromCarrier = (carrierId) => {
  const onSuccess = (data) => {
    return decryptApiResponse(data).then((r) => {
      return r;
    });
  };

  return statusAndResponse(
    Api.get(`/api/v2/appsettings/carriers/${carrierId}`),
    onSuccess,
  );
};
