import React from "react";
import {
  AnalitycsInfoItem,
  AnalitycsName,
  AnalitycsPercentsAndCounts,
  ContainerAnalitycsInfoItem,
} from "./ContainerAnalitycs.styles";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

const AnalitycsItem = (props) => {
  const { name, percentage, count } = props;
  return (
    <AnalitycsInfoItem key={name}>
      <ContainerAnalitycsInfoItem>
          <AnalitycsPercentsAndCounts
            primary
            style={{ color: count > 0 ? "#EE7A69" : "#ccc" }}
          >
            <div style={{width:'60px', height:'60px'}}><CircularProgressbar
              value={name === "Total" ? 100 : percentage}
              text={name === "Total" ? "100%" : `${percentage}%`}
              styles={buildStyles({
                textColor: "black",
                textSize: "1.7rem",
                pathColor: name !== "Total" ? "#45b605" : "#3A9EDB",
              })}
            /></div>
          </AnalitycsPercentsAndCounts>

    <AnalitycsName>{name + ` (${count})`} </AnalitycsName>
      </ContainerAnalitycsInfoItem>
    </AnalitycsInfoItem>
  );
};

export default AnalitycsItem;
