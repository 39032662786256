import {useContext, useEffect, useState} from "react";
import * as logServices from './../services/log.services'
import {AppContext} from "../../../../App";

const objFilters = {
  limit: 10,
  offset: 0,
  practiceId: null,
  sheetDate: null
}

const useExtractionLog = () => {
  const appContext = useContext(AppContext);
  const practiceId = appContext.get("practiceId");
  const [logs, setLogs] = useState([]);
  const [filters, setFilters] = useState({...objFilters, practiceId})
  const [recordsQty, setRecordsQty] = useState(0)

  useEffect(() => {
    const timer = setInterval(async () => {
      if (filters.sheetDate) {
        const [state, res] = await logServices.getLog(filters)
        if (state) {
            setLogs(lgs => [...res.records, ...lgs])
            setFilters({...filters, offset: filters.offset + 10})
        }
      }
    }, 15000)

    return () => clearInterval(timer)
  }, [filters])

  const setFiltersValues = (sheetDate) => {
    console.log(sheetDate)
    setFilters({...filters, sheetDate, offset: 0})
    setLogs([])
  }

  return {
    logs,
    setFiltersValues
  }
}

export default useExtractionLog