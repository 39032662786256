import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import { useAsyncTableData } from "../../CustomTable/hooks";
import * as services from "./transaction-plan.services";

const useTransactionPlan = () => {
  const appContext = useContext(AppContext);
  const practiceId = appContext.get("practiceId");
  const history = useNavigate();

  const { dataSource, getParams, refreshData, tableReference } =
    useAsyncTableData(services.getList, { practiceId });

  const audit = async (_id, nextState) => {
    return services.audit(_id, nextState);
  };

  const goToRecord = (_id) => {
    history("/transaction-plan-detail", { state: { id: _id } });
  };

  useEffect(() => {
    refreshData({ practiceId });
  }, [practiceId]);

  return {
    dataSource,
    tableReference,
    refreshData,
    audit,
    goToRecord,
    practiceId,
  };
};

export default useTransactionPlan;
