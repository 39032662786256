import CustomTable from "../../../../../CustomTable";
import moment from "moment";
import {formatter} from "../../../../../../util/formatter";

const ONEHUNDREDCENTS = 100
const DateFormat = ({date}) => (<span>{moment(date).format('yyyy-MM-D')}</span>)

const DentalMationProductInvoice = ({invoiceHistory, description}) => {
  const columns = [
    {title: 'Amount paid', field: 'amountPaid', render: ({amountPaid}) => <span>{formatter.format(amountPaid / ONEHUNDREDCENTS)}</span>},
    {title: 'Billing Reason', field: 'billingReason'},
    {title: 'Description', render: () => <span>{description}</span>},
    {title: 'Scheduled payment date', render: ({created}) => <DateFormat date={created} />},
    {title: 'Payment made', render: ({finalizedAt}) => <DateFormat date={finalizedAt} /> },
    {title: 'Status', field: 'status'},
  ]

  return(
    <CustomTable
      columns={columns}
      title='Invoice History'
      data={invoiceHistory}
      search={false}
      pageSize={10}
    />
  )
}

export default DentalMationProductInvoice