import React from "react";
import DatePicker from ".";
import { getInitialAndEndDate } from "../../util/date";

const DateRange = ({
  startDateLabel = "Start Date",
  endDateLabel = "End Date",
  startDateValue = null,
  endDateValue = null,
  onChange,
}) => {
  const setStartDate = (value) => {
    const [start, end] = getInitialAndEndDate(value, endDateValue);
    onChange && onChange([start, end]);
  };

  const setEndDate = (value) => {
    const [start, end] = getInitialAndEndDate(startDateValue, value);
    onChange && onChange([start, end]);
  };

  return (
    <>
      <DatePicker
        label={startDateLabel}
        value={startDateValue}
        onChange={setStartDate}
      />
      <DatePicker
        label={endDateLabel}
        value={endDateValue}
        onChange={setEndDate}
      />
    </>
  );
};

export default DateRange;
