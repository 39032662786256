import React from "react"
import IssuesCodes from "./IssuesCodes";

const ELEMENTS = 25

const WrongPrices = ({codes, title, onClick, ...props}) => {
  const cutArrayCodes = codes.slice(0, ELEMENTS);

  return (
    <IssuesCodes
      title={title}
    >
    <span className='text-muted mb-3'>
            {props.children}
          </span>
      <div className='duplicate-list'>
        {cutArrayCodes.map(({codeService}, index) => {
          return (
            <button
              key={`${codeService}${index}`}
              className='duplicate-code'
            >
              {codeService}
            </button>
          )
        })}
      </div>
    </IssuesCodes>
  )
}

export default WrongPrices