import React from "react";
import { Grid } from "@material-ui/core";
import { getDateAsString } from "../../../../../../../../../util/date";
import CustomDatePicker from "../../../../../../../../CustomDatePicker";
import { LABEL_COVERAGE_DATE } from "../../../../../../constants";

const VerificationHeaderInputs = ({
  formVerification,
  setDataDates,
  shouldBeReadonly,
  practiceInfoAndBookmarks,
  handleChangeBookmarks,
  record,
}) => {
  return (
    <Grid container spacing={2}>
      {practiceInfoAndBookmarks &&
        practiceInfoAndBookmarks[0]?.items?.map((item) => (
          <React.Fragment key={item.description}>
            {item?.description === LABEL_COVERAGE_DATE ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={item.code}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span
                    className="mr-2"
                    style={{
                      color: "#000",
                      fontWeight: 500,
                      marginBottom: "0 !important",
                    }}
                  >
                    Coverage Date
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {!record?.audited ? (
                        <CustomDatePicker
                          value={
                            typeof formVerification.coverageDateStart ===
                            "string"
                              ? getDateAsString(
                                  new Date(formVerification.coverageDateStart),
                                )
                              : formVerification.coverageDateStart
                          }
                          name="coverageDateStart"
                          onChange={(d) => {
                            setDataDates("coverageDateStart", d);
                          }}
                        />
                      ) : (
                        <input
                          value={getDateAsString(
                            new Date(formVerification.coverageDateStart),
                          )}
                          className="form-control"
                          readOnly
                        />
                      )}
                    </Grid>{" "}
                    <Grid item md={6} lg={6} xl={6}>
                      {!record?.audited ? (
                        <CustomDatePicker
                          value={
                            typeof formVerification.coverageDateEnd === "string"
                              ? getDateAsString(
                                  new Date(formVerification.coverageDateEnd),
                                )
                              : formVerification.coverageDateEnd
                          }
                          name="coverageDateEnd"
                          onChange={(d) => {
                            setDataDates("coverageDateEnd", d);
                          }}
                        />
                      ) : (
                        <input
                          value={getDateAsString(
                            new Date(formVerification.coverageDateEnd),
                          )}
                          className="form-control"
                          readOnly
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                key={item.description}
              >
                <Grid container style={{ alignItems: "center" }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span
                      className="mr-2"
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        marginBottom: "0 !important",
                      }}
                    >
                      {item.description}
                    </span>
                  </Grid>{" "}
                  <Grid item xs={8} sm={8} md={9} lg={8} xl={8}>
                    <input
                      onChange={handleChangeBookmarks}
                      name={item?.bookmarks[0]?.bookmark ?? ""}
                      type="text"
                      readOnly={shouldBeReadonly()}
                      className="form-control"
                      value={
                        formVerification.bookmarks[item?.bookmarks[0]?.bookmark]
                      }
                      //required
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        ))}

      {/* <Grid container style={{ marginTop: "1rem", alignItems: "center" }}>
          <Grid item md={3} lg={3} xl={3}>
            <span
              className="mr-2"
              style={{
                color: "#000",
                fontWeight: 500,
                marginBottom: "0 !important",
              }}
            >
              Remaining
            </span>
          </Grid>
          <Grid item md={8} lg={8} xl={8}>
            <input
              //onChange={handleChangeVerification}
              name={
                practiceInfoAndBookmarks[0]?.items[1]?.bookmarks[0]?.bookmark ??
                ""
              }
              type="text"
              className="form-control"
              readOnly={shouldBeReadonly()}
              //value={formVerification.remaining}
              required
            />
          </Grid>
        </Grid>*/}

      {/* <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item md={4} lg={4} xl={4}>
            <span
              className="mr-2"
              style={{
                color: "#000",
                fontWeight: 500,
                marginBottom: "0 !important",
              }}
            >
              Reference Number
            </span>
          </Grid>
          <Grid item md={8} lg={8} xl={8}>
            <input
              readOnly={shouldBeReadonly()}
              //onChange={handleChangeVerification}
              name={
                practiceInfoAndBookmarks[0]?.items[2]?.bookmarks[0]?.bookmark ??
                ""
              }
              type="text"
              className="form-control"
              //value={formVerification.referenceNumber}
              required
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1rem", alignItems: "center" }}>
          <Grid item md={4} lg={4} xl={4}>
            <span
              className="mr-2"
              style={{
                color: "#000",
                fontWeight: 500,
                marginBottom: "0 !important",
              }}
            >
              Coverage Date
            </span>
          </Grid>

        </Grid>
      </Grid>*/}
    </Grid>
  );
};

export default VerificationHeaderInputs;
