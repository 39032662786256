import { InfoModal } from "./InfoModal";
import TransactionNote from "./TransactionNote";

// 2

const initialData = {
  CarrierName: "",
  PatientFirstName: "",
  PatientLastName: "",
  MemberID: "",
  EmployerName: "",
  PatientDOB: "",
  PatientId: "",
  Relationship: "",
  SubscriberDOB: "",
  SubscriberFirstName: "",
  SubscriberLastName: "",
  SubscriberId: "",
  ApptDate: "",
};

//Components
export function InfoResumen({
  item: {
    CarrierName,
    groupName,
    PatientFirstName,
    PatientLastName,
    MemberID,
    EmployerName,
    PatientDOB,
    PatientId,
    Relationship,
    SubscriberDOB,
    SubscriberFirstName,
    SubscriberLastName,
    SubscriberId,
    ApptDate,
  } = initialData,
  apptData,
  notes,
  transactionId,
  onNoteSaved = () => {},
  handleCloseModal = () => {},
  showBox = true,
}) {
  const cleanAppData =
    typeof apptData === "string" &&
    (apptData.trim().toUpperCase().indexOf("EMPTY") !== 0 ||
      apptData.trim() === "-") &&
    apptData.trim().length > 0
      ? apptData
      : "";

  const cleanNote = (typeof notes === "string" ? notes : "").trim();

  return (
    <>
      <div className="mb-3">
        <div className="text-muted">
          <p>
            <strong>Appointment Info:</strong> {cleanAppData}
          </p>
          <TransactionNote
            transactionId={transactionId}
            initialNote={cleanNote}
            onSave={onNoteSaved}
          />
          <hr />
        </div>

        <h5 className="mb-4">Summary Information</h5>
        <div className="row text-center">
          <div className="col col-md-3">
            <strong>Carrier Name:</strong>
            <div>{CarrierName}</div>
          </div>
          <div className="col col-md-3">
            <strong>Group #:</strong>
            <div>{groupName}</div>
          </div>
          <div className="col col-md-3">
            <strong>Patient Name:</strong>
            <div>
              {PatientFirstName} {PatientLastName}
            </div>
          </div>
          <div className="col col-md-3">
            <strong>Member ID:</strong>
            <div>{MemberID}</div>
          </div>
        </div>
      </div>
      {showBox && (
        <InfoModal
          showBox={showBox}
          handleCloseModal={handleCloseModal}
          item={{
            CarrierName,
            EmployerName,
            MemberID,
            PatientFirstName,
            PatientLastName,
            PatientDOB,
            PatientId,
            Relationship,
            SubscriberDOB,
            SubscriberFirstName,
            SubscriberLastName,
            SubscriberId,
            ApptDate,
          }}
        />
      )}
    </>
  );
}
