import React from "react";
import VerificationHeaderInputs from "./components/header-inputs/VerificationHeaderInputs";
import BookmarksInputs from "./components/bookmarks-inputs/BookmarksInputs";
import { Box } from "@material-ui/core";
import SaveButtonForm from "../save-button/SaveButtonForm";

const Verification = (props) => {
  const {
    practiceInfoAndBookmarks,
    handleChangeVerification,
    handleSubmitVerification,
    formVerification,
    setDataDates,
    isLoadingButton,
    shouldUpdateVerification,
    itemVerification,
    updateVerificationById,
    handleChangeBookmarks,
    record,
  } = props;

  function shouldBeReadonly() {
    return record && record?.audited;
  }

  const labelButton = shouldUpdateVerification ? "Update" : "Save";

  return (
    <>
      <Box
        component="form"
        onSubmit={
          shouldUpdateVerification
            ? updateVerificationById
            : handleSubmitVerification
        }
      >
        <VerificationHeaderInputs
          formVerification={formVerification}
          setDataDates={setDataDates}
          shouldBeReadonly={shouldBeReadonly}
          practiceInfoAndBookmarks={practiceInfoAndBookmarks}
          handleChangeBookmarks={handleChangeBookmarks}
          record={record}
        />
        {practiceInfoAndBookmarks && (
          <BookmarksInputs
            practiceInfoAndBookmarks={practiceInfoAndBookmarks}
            handleChangeVerification={handleChangeVerification}
            formVerification={formVerification}
            shouldBeReadonly={shouldBeReadonly}
            handleChangeBookmarks={handleChangeBookmarks}
            record={record}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          {!itemVerification.audited && (
            <SaveButtonForm
              isLoadingButton={isLoadingButton}
              label={labelButton}
            />
          )}
        </div>
      </Box>
    </>
  );
};

export default Verification;
