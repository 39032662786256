import React from 'react';

const ExtractionScheduleDates = ({dates = [], allDates = {}}) => {

  const disableDates = allDates.dates ? allDates.dates.filter(date => !dates.includes(date)) : []

  return (
    <>
      <div className='extraction-legend'>
        <div className='extraction-enabled'>Enabled dates</div>
        <div className='extraction-disabled'>Disabled dates</div>
      </div>
      <div className='extraction-tabs'>
        <ul className='extraction-dates'>
          <Dates dates={dates}/>
          <Dates dates={disableDates} disable={true}/>
        </ul>
      </div>
    </>
  );
};

const Dates = ({dates, disable = false}) => {
  const badgeType = disable ? 'btn-outline-warning' : 'btn-outline-success'

  return (
    <>
      {dates.map(date => {
        return <li key={date} className='extraction-item'><span className={`btn btn-sm ${badgeType}`}>{date}</span></li>
      })}
    </>
  )
}

export default ExtractionScheduleDates;
