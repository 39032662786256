import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useClientNS from "./useClientNS";
import { TemplateWrapped } from "../../Layout";
import ClientNSTable from "./components/ClientNSTable";
import { HeaderButton } from "../../RemarkTemplate/Body";
import ClientNSForm from "./components/ClientNSForm";
import { useMemo } from "react";

const ClientNS = () => {
  const {
    clientsNS,
    showModal,
    setShowModal,
    handleSubmit,
    actions,
    allClients,
    clientState,
    setInitialValue,
  } = useClientNS();

  const nonExistingClients = useMemo(() => {
    const clientsIds = clientsNS.map((x) => x.clientId);

    return allClients.filter(
      (x) =>
        !clientsIds.find(
          (y) => y === x.value && x.value !== clientState.clientId
        )
    );
  }, [allClients, clientsNS, clientState]);

  return (
    <TemplateWrapped
      title="Clients NS"
      headerButtons={
        <>
          <HeaderButton
            icon={faPlus}
            message="New key"
            onClick={() => {
              setShowModal(!showModal);
              setInitialValue();
            }}
          />
        </>
      }
    >
      <ClientNSTable data={clientsNS} actions={actions} />
      <ClientNSForm
        initialValues={clientState}
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        allClients={nonExistingClients}
      />
    </TemplateWrapped>
  );
};

export default ClientNS;
