let minimunLength = 8;

const validations = [
  {
    message: "1 special character",
    validation: /[^\w]/,
  },
  {
    message: "1 number",
    validation: /[0-9]/,
  },
  {
    message: "1 lowercase letter",
    validation: /[a-z]/,
  },
  {
    message: "1 uppercase letter",
    validation: /[A-Z]/,
  },
];

export function setMinimunLength(n) {
  minimunLength = n;
}

export function getMinimunLength() {
  return minimunLength;
}

export function isValid(password) {
  let messages = [];

  for (const { message, validation } of validations) {
    if (!password.match(validation)) {
      messages.push(message);
    }
  }

  if (messages.length > 0 || password.length < minimunLength) {
    return [false, messages];
  }

  return [true, []];
}

export function defaultError(messages) {
  const list = messages.reduce((a, i) => (a += "<li>" + i + "</li>"), "");
  throw new Error(
    `Password Strength|
    <p>Dear user, the password should be at least ${getMinimunLength()} characters long and contain ast least:</p>
    <ul className="ml-5 pl-5 text-left">
    ${list}
    <ul>`
  );
}
