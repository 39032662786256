import {statusAndResponse} from "../../../util/requestHandler";
import {Api, decryptApiResponse} from "../../../config/Api";

export const getPracticeByClient = (clientId) => {
  return statusAndResponse(
    Api.get(`api/client/practice`, {params: {client: clientId}}),
    decryptApiResponse
  );
};
export const getPracticeById = (practiceId) => {
  return statusAndResponse(
    Api.get(`api/client/practice/${practiceId}`),
    decryptApiResponse
  );
};

export const getState = () => {
  return statusAndResponse(

    Api.get(`/api/shared/city/state`),
  );
};

export const getStateV2 = () => {
  return statusAndResponse(
    Api.get(`api/shared/state`, {params: {country: "United States"}}),
  );
};

export const getCity = (stateId) => {
  return statusAndResponse(
    Api.get(`/api/shared/city`, {params: {state: stateId,country: "United States"}}),
  );
};

export async function saveClientPractice(data) {
  return statusAndResponse(
    Api.post("/api/client/practice", data),
    decryptApiResponse
  );
}

export async function updateClientPractice(data, praticeIdSelect) {
  return statusAndResponse(
    Api.put(`/api/client/practice/${praticeIdSelect}`, data),
    decryptApiResponse
  );
}

export function deletePractice(practiceId) {
  return statusAndResponse(
    Api.delete(`/api/client/practice/${practiceId}`),
    decryptApiResponse
  )
}

export const getClients = () => {
  return statusAndResponse(
    Api.get('/api/client'),
    decryptApiResponse
  )
};

export const getPracticeWithFilter = (params) => {
  return statusAndResponse(
    Api.get(`api/client/all/practice`, {params: params}),
    decryptApiResponse
  );

  // const data =  statusAndResponse(
  //   Api.get(`api/client/all/practice`, {params: params}),
  //   decryptApiResponse
  // );
  // console.log("DATA practice")
  // console.log(data)
  // return data
};

export const getPracticeAll = () => {
  return statusAndResponse(
    Api.get('/api/client/all/practice'),
    decryptApiResponse
  )

};

