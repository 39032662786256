import {Api, decryptApiResponse} from "../../../../../../config/Api";
import {statusAndResponse} from "../../../../../../util/requestHandler";

export function getTemplateById(id) {
  return statusAndResponse(
    Api.get(`/api/tmp/data/${id}`),
    decryptApiResponse
  )
}

export function getSharedElementsByTemplateId(id) {
  return statusAndResponse(
    Api.get(`/api/template/sharedElement?templateId=${id}`),
    decryptApiResponse
  )
}

export function storeSharedElements(data) {
  return statusAndResponse(
    Api.post(`/api/template/sharedElement`, data),
    decryptApiResponse
  )
}