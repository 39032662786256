import { Api } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";


export function getList({ limit, offset, ...params}) {
  let apiCallcenter = Object.keys(params).length === 0 ?'/api/callcenter/calls' : '/api/callcenter/call/search';

  const per_page = limit;
  const page = (offset > 0 ? offset / limit : 0) + 1;

  const a =params.to || null
  const b = params.from || null
  if (a && b) {
    const to = parseInt((new Date(params.to).getTime() / 1000).toFixed(0))
    const from = parseInt((new Date(params.from).getTime() / 1000).toFixed(0))
    params = {...params, to: to, from: from}
  }

  const p = {...params}

  return statusAndResponse(
    Api.get(apiCallcenter, {
      params: {
        per_page,
        page,
        ...p
      },
    }),
    ({ data }) => {
      const { calls, meta } = data;
      return {
        records: calls,
        recordsQty: meta.total,
      };
    }
  );
}

export function getRecord(id) {
  return statusAndResponse(
    Api.get("/api/callcenter/calls/" + id),
    ({ data }) => data
  );
}

export function search() {
  // /api/callcenter/call/search
}
