import {useAsyncTableData} from "../../../CustomTable/hooks";
import {initialValues} from "../components/AzureFileFilter/hooks/useAzureFilesFilter";
import {getUrlParams} from "../../../../util/browser";

const useAzureFiles = (getFiles) => {

  const processOrderBy = (order, query = null) => {
    const {orderBy} = query
    const direction = order === 'asc' ? 1 : -1
    if (!orderBy) return {}
    const {field = ''} = orderBy
    switch (field) {
      case 'dateCreated':
        return {
          sortDate: direction
        }
      case 'filename':
        return {
          sortFilename: direction
        }
      case 'container':
        return {
          sortContainer: direction
        }
      case 'folder':
        return {
          sortFolder: direction
        }
    }
    return {}
  }

  const {
    dataSource,
    refreshData,
    tableReference
  } = useAsyncTableData(
    getFiles,
    {
      ...initialValues,
      ...getUrlParams(Object.keys(initialValues))
    },
    processOrderBy
  )

  return {
    dataSource,
    refreshData,
    tableReference
  }
}

export default useAzureFiles