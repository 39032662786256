import usePractices from "../../../hooks/usePractices";
import {TemplateWrapped} from "../../Layout";
import {Presentational as Connections} from "../../pages/Connections";

const withSelectedPractices = (Component) => (props) => {
  const { selectedPractices } = usePractices()

  return selectedPractices.length > 0
    ? <Component selectedPractices={selectedPractices} {...props} />
    : <ShowConnections />
}

const ShowConnections = () => {
  return(
    <TemplateWrapped>
      <div
        className="alert alert-alt alert-warning"
        role="alert"
        style={{
          padding: "20px 12px",
          fontSize: "14px",
          borderLeft: "3px solid",
        }}
      >
        Dear user, once you select a connection, the corresponding data will
        be displayed
      </div>
      <div className="mb-5 pb-5">
        <Connections title="Please select a connection"/>
      </div>
    </TemplateWrapped>
  )
}

export default withSelectedPractices