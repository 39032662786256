import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import CustomModal from "../../../CustomModal";
import {BasicForm, FormSelect as Select} from "../../../formControls";
import SelectPractices from "../../../SelectPractices";
import {Col, Row} from "react-bootstrap";

export default function InsuranceVerificationForm(
  {
    handleCloseModal,
    showModal,
    carrierList,
    OnChangeCarrier,
    displayForm,
    carrierForm,
    handleSubmitModalForm,
    carrierId,
    loading,
    setPracticeSelect
  }) {
  return (
    <CustomModal
      show
      onHide={handleCloseModal}
      title={showModal.title}
      size="lg"
    >
      <Select
        id="carriers"
        label="Carriers:"
        options={carrierList}
        disableFirstOption={true}
        value=""
        onChange={OnChangeCarrier}
      />
      {displayForm ? (
        carrierForm.length > 0 ? (
          <>
            <Row>
              <Col className="mb-3">
                <SelectPractices
                  id='practiceId'
                  name='practiceId'
                  onChange={({value}) => {
                    setPracticeSelect(value)
                  }}
                />
              </Col>
            </Row>
            <BasicForm
              showInColumns
              numberColums={2}
              fields={carrierForm}
              onSubmit={handleSubmitModalForm}
              formValidation={true}
            />
          </>
        ) : (
          <p className="small text-muted">
            <FontAwesomeIcon icon="info-circle" className="text-danger mr-2"/>
            Sorry, data was not found, please try again.
          </p>
        )
      ) : null}

      {!carrierId && (
        <p className="small text-muted">
          <FontAwesomeIcon icon="info-circle" className="text-info mr-2"/>
          Please select a Carrier, corresponding data will be displayed bellow.
        </p>
      )}

      {loading && (
        <p className="small text-muted">
          Loading carrier's data, please wait.
          <FontAwesomeIcon icon="spinner" spin/>
        </p>
      )}
    </CustomModal>
  );
}
