import React from "react"
import {Formik, Form, Field, ErrorMessage} from "formik";
import {Col, Row} from "react-bootstrap";
import * as Yup from "yup"

const UpdateForm = ({rowSelected, handleUpdate}) => {
  return (
    <Formik
      initialValues={rowSelected}
      validationSchema={Yup.object().shape({
        codeService: Yup.string().required('Code Services is required'),
        description: Yup.string().required('Description is required'),
        price: Yup.string()
          .required('Price is required')
          .trim()
          .matches(/^\$[1-9]?[0-9]+(\.\d{1,2})?$/, 'Price is incorrect')
      })}
      onSubmit={values => {
        handleUpdate(values)
      }}
    >
      <Row>
        <Col md={{span: 8, offset: 2}}>
          <Form className='form'>
            <label className='text-bold text-dark' htmlFor="codeService">Code</label>
            <Field className='form-control' name="codeService" type="text"/>
            <ErrorMessage name="codeService">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>

            <label htmlFor="description" className='mt-2 text-bold text-dark'>Description</label>
            <Field className='form-control' name="description" type="text"/>
            <ErrorMessage name="description">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>

            <label htmlFor="price" className='mt-2 text-bold text-dark'>Price</label>
            <Field placeholder='$533' className='form-control' name="price" type="text"/>
            <ErrorMessage name="price">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>

            <button
              type='submit'
              className='btn btn-primary btn-block mt-3'
            >
              Update
            </button>
          </Form>
        </Col>
      </Row>
    </Formik>
  )
}

export default UpdateForm