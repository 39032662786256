import {createContext} from "react"

const ShareBookmarksContext = createContext({
  templateId: '',
  template: {},
  sharedElements: [],
  handleOnChange: () => {},
  handleSaveSharedBookmarks: () => {}
})

export default ShareBookmarksContext