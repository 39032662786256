import { Icon, IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { styles } from "./transaction-files.styles";
import {
  fileMatchWithAllowedTypes,
  getFileElipsis,
} from "./TransactionFiles.utils";

const allowedFileTypes = ".pdf,.doc,.docx";

export const DeleteButton = ({ onClick }) => {
  return (
    <Tooltip title="Delete file">
      <IconButton type="button" onClick={onClick}>
        <Icon color="secondary">delete</Icon>
      </IconButton>
    </Tooltip>
  );
};

const FileCreatorListItem = ({
  focusPosition,
  removeFileData,
  setName,
  setFile,
  fileData,
  canDelete
}) => {
  // Suggested names for file
  const [touched, setTouched] = useState(false);
  const inputRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const focus = (event) => {
    event.preventDefault();
    if (!touched && inputRef.current !== null) {
      inputRef.current.setSelectionRange(focusPosition, focusPosition);
      setTimeout(() => {
        if (!touched) {
          setTouched(true);
        }
      }, 500);
    }
  };

  return (
    <form ref={formRef}>
      <div className="d-flex align-items-center mb-2">
        <input
          ref={inputRef}
          className="form-control w-50"
          placeholder="File Name"
          type="text"
          value={fileData.name}
          onClick={focus}
          onFocus={focus}
          onChange={({ target: { value } }) => {
            setName(value);
          }}
        />
        <Tooltip title="Choose a file">
          <label className="mb-0 w-50 d-block" style={styles.fileLabel}>
            <IconButton component="span" className="p-0">
              <Icon color={fileData.file ? "primary" : "inherit"}>
                file_upload
              </Icon>
            </IconButton>
            <input
              style={styles.fileInput}
              name="file"
              type="file"
              accept={allowedFileTypes}
              onChange={(r) => {
                const file = r.target.files[0];
                if (!file) return;
                if (
                  !fileMatchWithAllowedTypes(
                    allowedFileTypes.replace(/\./g, "").split(","),
                    file
                  )
                ) {
                  toast.error(
                    "Dear user, the allowed files are: Documents and PDF's"
                  );
                  return false;
                }
                setFile(file);
              }}
            />
            <span className="d-inline-block text-muted flex-grow-1">
              {!fileData.file
                ? "Choose a file"
                : getFileElipsis(fileData.file.name)}
            </span>
          </label>
        </Tooltip>
        {canDelete && (
          <DeleteButton onClick={() => removeFileData()} />
        )}
      </div>
    </form>
  );
};

export default FileCreatorListItem;
