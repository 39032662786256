import { Api, decryptApiResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";

export const getList = async (params = {}) => {
  return statusAndResponse(
    Api.get("/api/transactionPlan", { params }),
    decryptApiResponse
  );
};

export const audit = async (_id, nextState) => {
  return statusAndResponse(
    Api.put(`/api/transactionPlan/${_id}/audit`, {
      audit: nextState.toString(),
    })
  );
};
