import styled from "styled-components";

const CodesContainer = styled.div`
  padding: 0px 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid #f5f5f5;
    &:not(:first-child) {
        margin-top: 1rem;
    }

    .codes-name {
        font-weight: 500;
        padding-left: 0rem;
    }
`

const Codes = ({ children }) => {
  return (
    <CodesContainer>
      {children}
    </CodesContainer>
  )
}

export default Codes
