import {statusAndResponse} from "../../../../../util/requestHandler";
import {Api, decryptApiResponse} from "../../../../../config/Api";

export const saveSort = obj => {
  return statusAndResponse(
    Api.put('/api/users/transaction/sort', {sort: obj}),
    decryptApiResponse
  )
}

export const savePriority = obj => {
  return statusAndResponse(
    Api.put('/api/users/transaction/elg/priority', {priority: obj}),
    decryptApiResponse
  )
}