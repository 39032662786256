export const STATUS_COLOR = {
  1: '#0D75BB',
  2: '#22AF8B',
  3: '#E54103'
}

const whiteColor = '#fff';

export const VERIFICATION_BY_COLOR = {
  success:{
    bg: '#4ab69538',
    color: '#4ab695'
  },
  warning: {
    bg: '#ffc10770',
    color: '#c3a204'
  },
  danger: {
    bg: '#FBE6E5',
    color: '#E54103'
  },
  '': {bg:'',color:''},
  noStatus: {bg: 'transparent', color: '#000'}
}

export const ELIGIBILITY_COLOR = {
  success:{
    bg: '#45B695',
    color: whiteColor
  },
  warning: {
    bg: '#ffc10770',
    color: '#c3a204'
  },
  danger: {
    bg: '#EF7B6A',
    color: whiteColor
  },
  '': {bg:'',color:''},
  noStatus: {bg: 'transparent', color: '#000'}
}

export const BAD_STATUS = [
  'empty',
  'not verified',
  'no action taken, cash account',
  'max out',
  'inactive',
  'not found'
]

export const ELIGIBILITY_STATUS = [
  'not found',
  'inactive',
  'empty',
  'max out',
  'active',
  'check'
]

export const ROW_FONT_SIZE = '.9rem'

export const NO_INSURANCE_COMPANY = 'no insurance company';

export const UNCHECKED_VERIFICATION_STATE = 'unchecked'

export const NO_ACTION_TAKEN = 'no action taken';

export const EXCLUDE_FILTER_KEYS = ['requiredFields']

export const EMPTY = 'empty';

export const OPTIONS_SORT =  [
  {
    label: 'Appointment',
    value: 'dateToOrder',
    options: [{
      label: 'Ascending',
      value: 1
    }, {
      label: 'Descending',
      value: -1
    }]
  }, {
    label: 'Patient Name',
    value: 'patientName',
    options: [{
      label: 'Ascending',
      value: 1
    }, {
      label: 'Descending',
      value: -1
    }]
  }, {
    label: 'Practice',
    value: 'practiceName',
    options: [{
      label: 'Ascending',
      value: 1
    }, {
      label: 'Descending',
      value: -1
    }]
  }, {
    label: 'Carrier',
    value: 'carrier',
    options: [{
      label: 'Ascending',
      value: 1
    }, {
      label: 'Descending',
      value: -1
    }]
  }, {
    label: 'Verification By',
    value: 'iv_process_result',
    options: [{
      label: 'Ascending',
      value: 1
    }, {
      label: 'Descending',
      value: -1
    }]
  }, {
    label: 'Eligibility status',
    value: 'priorityOrder',
    options: [{
      label: 'Active',
      value: -1
    }]
  },
  {
    label: 'Provider',
    value: 'provider',
    options: [{
      label: 'Ascending',
      value: 1
    }, {
      label: 'Descending',
      value: -1
    }]
  }
];


export const SELECT_FIELDS = [
  {name: 'select1', orderName: 'selectOrder1'},
  {name: 'select2', orderName: 'selectOrder2'},
  {name: 'select3', orderName: 'selectOrder3'},
  {name: 'select4', orderName: 'selectOrder4'},
  {name: 'select5', orderName: 'selectOrder5'},
  {name: 'select6', orderName: 'selectOrder6'},
  {name: 'select7', orderName: 'selectOrder7'},
];
