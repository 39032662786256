import { Api, decryptApiResponse, fakeResponse } from "../../../config/Api";
import { statusAndResponse } from "../../../util/requestHandler";

export const getRecord = async (id) => {
  return statusAndResponse(
    Api.get(`/api/transactionPlan/${id}/bookmarks`),
    decryptApiResponse
  );
};

export const update = async (id, bookmarks, userId) => {
  return statusAndResponse(
    Api.put(`/api/transactionPlan/${id}/bookmark`, { bookmarks, userId }),
    decryptApiResponse
  );
};
