import { Form } from "react-bootstrap";
import {useState, useEffect} from 'react'

const ChecksList = (props) => {

  const [options,setOptions] = useState([]);

  const handleChange = (e) => {
    let ops = [...options];
    let selectedOptions = {};
    for(let op of ops)
    {
      if(e.target.id === op.bookmark)
      {
        op.value = op.value==="n" ? "o":"n";
        selectedOptions = op;
      }
    }
    setOptions(ops);
    props.onChange(e);
  };
  useEffect(()=>{
    setOptions(props.options);
  },[]);
  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <div>
        {options.map((op, index) => (
           <Form.Check
            key={"op"+index}
            inline
            label={op.label}
            value={op.value}
            type="checkbox"
            id={op.bookmark}
            checked={op.value === "n"}
            onChange={handleChange}
          />
        ))}
      </div>
    </Form.Group>
  );
};

export default ChecksList;
