import {Api, decryptApiResponse, fakeResponse} from "../../../../config/Api";
import {statusAndResponse} from "../../../../util/requestHandler";

export function processSheet(obj) {
  return statusAndResponse(
    Api.post(`/api/feeSchedule`, obj),
    decryptApiResponse,
    (e) => {
      return [false, []]
    }
  )
}

export function searchCarrierByYear(carrier, year) {
  return statusAndResponse(
    Api.get(`/api/feeSchedule/carriers?practiceId=${carrier}&year=${year}`),
    decryptApiResponse
  )
}

export function getCarrierData(sheet, practice, year) {
  return statusAndResponse(
    Api.get(`/api/feeSchedule/ByCarrier?carrierName=${sheet}&practiceId=${practice}&year=${year}`),
    decryptApiResponse
  )
}

export function updateSchedule(obj){
  const {index, ...other} = obj;

  return statusAndResponse(
    Api.put(`/api/feeSchedule/${index}`, {
      serviceCode: other.codeService,
      serviceDescription: other.description,
      price: other.price
    }),
    decryptApiResponse
  )
}