import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomTooltipBody from "../CustomTooltipBody/CustomTooltipBody";

export default function CustomTooltip({ message,colorTooltip, customTooltip= false, ...props }) {
  return (
    <OverlayTrigger
      delay={{show: 250, hide: 100}}
      placement={props.placement || "top"}
      overlay={customTooltip
        ? <CustomTooltipBody message={message} colorTooltip={colorTooltip}/>
        : <Tooltip>{message}</Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}
