import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomTable from "../../../CustomTable";
import {HexCodeContainer, TextHexCode} from "../FlagColor.styles";
import ActionButton from "../../../ActionButton";
import CustomPopover from "../../../CustomPopover";
import ActionButtonItem from "../../../ActionButtonItem";
import icon_delete from "../asset/icon_delete.png"
import icon_edit from "../asset/icon_edit.png"
import {Divider} from "@material-ui/core";

const FlagColorTable = (props) => {
  const {
    // data,
    Delete,
    setColorEdit,
    setModalEdit,
    dataSource,
    tableReference,
    refreshData
  } = props
  const MySwal = withReactContent(Swal);

  const columns = [
    {
      title: "Id",
      field: "_id",
      hidden: true,

    },
    {
      title: "Name",
      field: "name",
      width: 300,
      align: "center",
    },
    {
      title: "Code Color",
      field: "hexCode",
      align: "center",
      render: ({hexCode}) => <> <HexCodeContainer color={hexCode}>
        <TextHexCode color={hexCode}>{hexCode}</TextHexCode>
      </HexCodeContainer>
      </>
    },
    {
      title: "Color Priority",
      field: "colorPriority",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      render: (rowData) => (
        <CustomPopover
          text="Add Items"
          icon={
            <ActionButton/>
          }>
          <ActionButtonItem
            Icon={<img src={icon_edit} style={{width: "22px", height: "22px"}} alt='Edit'/>}

            TextTooltip={"Edit"}
            TextButton={"Edit"}
            OnClick={(e) => {
              setColorEdit(rowData)
              setModalEdit(true)

            }
            }/>
          <Divider/>
          <ActionButtonItem
            Icon={<img src={icon_delete} style={{width: "22px", height: "22px", paddingTop: "2px"}} alt='Edit'/>}
            TextTooltip={"Delete"}
            TextButton={"Delete"}
            OnClick={(e) => {
              DeleteFlagColor(rowData._id)
            }
            }/>
        </CustomPopover>
      )
    }
  ];

  const DeleteFlagColor = (id) => {
    MySwal.fire({
      title: "Are you sure you want to delete this Color?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Delete(id);
      }
    });
  };

  return (
    <CustomTable
      columns={columns}
      pageSize={25}
      asyncDataSource={dataSource}
      tableReference={tableReference}
      search={false}
      options={{toolbar: false}}
    />
  );
};

export default FlagColorTable;
