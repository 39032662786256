import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const alert = withReactContent(Swal);

export function displayModalMessage(
  title,
  html,
  icon = "error",
  willClose = () => {}
) {
  alert.fire({
    icon,
    title,
    html,
    willClose,
  });
}

export function displayModalConfirmation(title, html, onConfirm, onCancel=null) {
  alert
    .fire({
      title,
      html,
      icon: "question",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    })
    .then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      }
      else{
        onCancel && onCancel();
      }
    });
}
