import CustomDatePicker from "../../../CustomDatePicker";
import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useCallback, useRef, useState} from "react";
import SelectPractices from "../../../SelectPractices";
import {Formik} from "formik";
import useSummaryReportForm from "./useSummaryReportForm";

const SummaryReportForm = ({GetSummaryReport}) => {
  const  {data, selectRef, clearSelect} = useSummaryReportForm()

  return (
    <>
      <Formik
        initialValues={data}
        enableReinitialize={true}
        onSubmit={(values, {resetForm}) => {
          GetSummaryReport(values)
          resetForm()
          clearSelect()
        }}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Row className="justify-content-md-center">
              <Col md={3}>
                <Form.Group>
                  <SelectPractices
                    selectRef={selectRef}
                    selected={props.values.practiceId}
                    onChange={({value}) => {
                      props.setFieldValue('practiceId', value)
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={props.values.startDate}
                    required
                    onChange={event => {
                      props.setFieldValue('startDate', event.target.value)
                    }
                    }
                  >

                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={props.values.endDate}
                    required
                    onChange={event => {
                      props.setFieldValue('endDate', event.target.value)
                    }
                    }
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3} className="align-content-center mt-4 pt-2">
                <Form.Group>
                  <Button
                    type='submit'
                    className='btn btn-primary btn-block'
                  >
                    Generate
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  )
}

export default SummaryReportForm
