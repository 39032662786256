import {IconButton, Paper, TablePagination} from "@material-ui/core";
import {Settings} from "@material-ui/icons";
import MaterialTable from "material-table";
import {useEffect, useState} from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {getURLParam, setURLParam} from "../../util/browser";
import '../../styles/standard-grid-stlyes.css'
import CustomPanel from "../CustomPanel";

function Wrapper(props) {
  return (
    <CustomPanel>
      <div className="row">
        <div className="col-md-12">{props.children}</div>
      </div>
    </CustomPanel>
  );
}

function CustomTable(
  {
    title = "",
    tableReference,
    columns = [],
    data,
    asyncDataSource = false,
    actions = [],
    components,
    isLoading = false,
    search = true,
    pageSize = 0,
    rowStyle,
    wrapperComponent = Wrapper,
    options = {},
    gridCssClass = 'grid-component',
    ...props
  }
) {
  useEffect(() => {
  }, []);

  const _pageSize = Number(getURLParam("rowsPerPage")) || pageSize;

  let _options = {
    actionsColumnIndex: -1,
    search,
    rowStyle,
    loadingType: null,
    ...options,
    // thirdSortClick: false,
  };

  if (_pageSize) {
    _options["pageSize"] = _pageSize;
  }

  const Component = (
    <div className={gridCssClass}>
      <MaterialTable
        tableRef={tableReference}
        isLoading={isLoading}
        localization={{
          body: {
            emptyDataSourceMessage: isLoading ? "" : "No records to display or there are no appointments scheduled",
          },
        }}
        title={title}
        columns={columns}
        data={
          asyncDataSource
            ? (query) =>
              new Promise((resolve, reject) => {
                // common: page, search, pageSize, orderDirection, orderBy

                const page = Number(getURLParam("page")) || query.page;
                asyncDataSource({
                  ...query,
                  page: Number(page),
                  // pageSize: Number(pageSize),
                }).then((r) => {
                  const {pageSize: pS = 5} = _options;

                  if (r.totalCount === 0) {
                    resolve({
                      page: 0,
                      totalCount: 0,
                      data: [],
                    });
                    return;
                  }

                  if (pS * page > r.totalCount) {
                    resolve({
                      ...r,
                      page: Math.round(r.totalCount / pS) - 1,
                    });
                    return;
                  }

                  resolve(r);
                });
              })
            : data
        }
        actions={actions}
        // options={{ tableLayout: "fixed" }}
        options={_options}
        components={
          components || {
            ...CustomTable.Components,
          }
        }
        {...props}
      />
    </div>
  );

  if (wrapperComponent) {
    return (
      <Wrapper>
        {/* <button onClick={lolx}>lol</button> */}
        {Component}
      </Wrapper>
    );
  }

  return Component;
}

function Pagination({options = [5, 10, 25, 50], ...props}) {
  const [otherProps, setOtherProps] = useState({
    page: props.page,
    rowsPerPage: props.rowsPerPage,
  });
  const { count } = props;

  useEffect(() => {
    setOtherProps({
      page: props.page,
      rowsPerPage: props.rowsPerPage,
    });
  }, [props.page, props.rowsPerPage]);

  useEffect(() => {
    let urlFilters = {};
    const rowsPerPage = getURLParam("rowsPerPage");
    if (rowsPerPage !== "") {
      urlFilters.rowsPerPage = Number(rowsPerPage);
    }

    if (Object.keys(urlFilters).length > 0) {
      setOtherProps({...urlFilters});
    }
  }, []);

  const displayableRPPO = options.reduce(
    (a, i) => {
      if (a.skip) return a;

      if (count <= i) {
        a.skip = true;
      }
      return {...a, options: [...a.options, i]};
    },
    {
      skip: false,
      options: [],
    }
  )["options"];

  let tbProps = {...props};

  if (props.count > 0) {
    tbProps = {...tbProps, ...otherProps};
  }

  return (
    <TablePagination
      {...tbProps}
      rowsPerPageOptions={displayableRPPO}
      onChangeRowsPerPage={(event, rowsPerPage) => {
        setURLParam("rowsPerPage", rowsPerPage.props.value);
        setURLParam("page", 0);
        props.onChangeRowsPerPage(event, rowsPerPage);
      }}
      onChangePage={(event, page) => {
        setURLParam("page", page);
        props.onChangePage(event, page);
      }}
    />
  );
}

function Container(props) {
  return <Paper {...props} elevation={0}/>;
}

export function TextColumnHighlighted({text, status = "", asDot = false}) {
  if (asDot) {
    return (
      <div className="d-flex align-items-center text-highlight">
        <span
          className={`d-inline-block mr-1 bg-${status}`}
          style={{width: 8, height: 8, flex: "0 0 8px", borderRadius: "100%"}}
        ></span>
        <span>{text}</span>
      </div>
    );
  }

  return (
    <span className={`column-highlighted badge text-wrap badge-${status}`}>
      {text}
    </span>
  );
}

function GoToRoute(pathame, state) {
  const history = useNavigate();
  history({pathame, state});
}

function GoToRecord(pathname, id) {
  const history = useNavigate();
  history(pathname, {state: {id}});
}

function getActionColumn(
  icon,
  tooltip,
  onClick,
  goToPath = null,
  idField = null
) {
  return (rowData) => ({
    icon,
    tooltip,
    onClick: () => {
      if (onClick) {
        onClick(rowData);
      }

      if (goToPath && idField) {
        GoToRecord(goToPath, idField);
      }
    },
  });
}

function getSettingsColumn(popOverContentCallback, otherContentCallback) {
  return {
    title: "Actions",
    sorting: false,
    align: "center",
    render: (rowData) => {
      const popoverContent = popOverContentCallback(rowData);
      if (!popoverContent) return null;

      const overlayOptions = {
        trigger: "click",
        placement: "bottom-end",
        rootClose: true,
        overlay: (
          <Popover style={{maxWidth: "500px"}} id="popover-basic">
            <Popover.Title as="h3">Actions</Popover.Title>
            <Popover.Content
              className="p-3"
              onClick={() => {
                document.body.click();
              }}
            >
              {popoverContent}
            </Popover.Content>
          </Popover>
        ),
      };

      return (
        <>
          <OverlayTrigger delay={{show: 250, hide: 100}} {...overlayOptions}>
            <IconButton color="primary" component="span">
              <Settings/>
            </IconButton>
          </OverlayTrigger>
          {otherContentCallback && otherContentCallback(rowData)}
        </>
      );
    },
  };
}

function createTableResponse(data, page, totalCount) {
  return {
    data,
    page,
    totalCount,
  };
}

CustomTable.Components = {
  Pagination,
  Container,
};

CustomTable.goToRecord = GoToRecord;
CustomTable.goToRoute = GoToRoute;

CustomTable.getActionColumn = getActionColumn;
CustomTable.getSettingsColumn = getSettingsColumn;
CustomTable.createTableResponse = createTableResponse;

export default CustomTable;
