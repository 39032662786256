import React from "react";
import { Form } from "react-bootstrap";
import useControl, { commonProps, ControlWrapper } from "./useControl";

const textProps = {
  ...commonProps,
};

export default function Text(props = textProps) {
  const { controlProps } = useControl(props);

  const { controlRef, ...restProps } = controlProps;

  return (
    <ControlWrapper {...props}>
      <Form.Control {...restProps} ref={controlRef} />
    </ControlWrapper>
  );
}
