import {Tab, Nav, Row, Col} from "react-bootstrap";
import {TemplateWrapped} from "../../../../Layout";
import CustomPanel from "../../../../CustomPanel";
import useIssues from "./hooks/useIssues";
import IssueBody, {WrappedNav} from "../../../../Issues/components/IssueBody";
import CustomModal from "../../../../CustomModal";
import ResponseForm from "./ResponseForm";

const TransactionIssueDetail = () => {
  const {
    issues,
    issueId,
    setIssueSelected,
    setHasIssueSelected,
    setShowModalResponse,
    showModalResponse,
    handleSubmitResponse,
  } = useIssues();

  return (
    <TemplateWrapped
      title="Transaction Issue Detail"
      description="Transaction Issue Detail"
    >
      <CustomPanel>
        <Row>
          <Col>
            <Tab.Container
              defaultActiveKey={issueId}
            >
              <Row>
                <Col md={4}>
                  <WrappedNav>
                    <Nav variant="pills" className="flex-column">
                      {issues.map((issue, index) => (
                        <Nav.Item
                          key={issue._id}
                          eventKey={issue._id}
                          onClick={() => {
                            setIssueSelected(issue._id);
                            setHasIssueSelected(true);
                          }}
                        >
                          <Nav.Link eventKey={issue._id}>
                            {issue.title}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </WrappedNav>
                </Col>
                <Col md={7}>
                  <Tab.Content>
                    {issues.map((issue, index) => (
                      <Tab.Pane
                        key={issue._id}
                        eventKey={issue._id}
                      >
                        <>
                          {issue.issueDetails ? (
                            <IssueBody
                              created={issue.created}
                              stepReproduce={issue.issueDetails.stepReproduce}
                              details={issue.issueDetails.details}
                              user={issue.issueDetails.user}
                              username={issue.issueDetails.username}
                              setShowModalResponse={setShowModalResponse}
                              responses={issue.issueDetails.responses}

                            />
                          ) : null}
                        </>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
        {showModalResponse && (
          <CustomModal
            show={showModalResponse}
            title='Add response'
            onHide={() => setShowModalResponse(false)}
          >
            <ResponseForm submit={handleSubmitResponse} />
          </CustomModal>
        )}
      </CustomPanel>
    </TemplateWrapped>
  )
}

export default TransactionIssueDetail