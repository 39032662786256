import { faPlus } from "@fortawesome/fontawesome-free-solid";
import TableUsers from "../../helper/tableUsers";
import { TemplateWrapped } from "../../Layout";
import {HeaderButton, HeaderFilterButton} from "../../RemarkTemplate/Body";
import {Presentational as Connections} from "../Connections";
import useUsers from "./useUsers";
import UserFilters from "./UserFilters/UserFilters";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const Users = ({
  actions = {
    create_user: true,
    delete_user: true,
    update_user: true,
  },
}) => {
  actions = {
    create_user: true,
    delete_user: true,
    update_user: true,
  };

  const {
    selectedPractices,
    history,
    tableReference,
    dataSource,
    refreshData
  } = useUsers()

  return (
    <TemplateWrapped
      title="Users"
      headerButtons={
        <>
          <HeaderFilterButton
            active={true}
            render={UserFilters}
            componentProps={{
              setFilters: refreshData,
            }}
          />
          <HeaderButton
            icon={faPlus}
            message="Add a new User"
            onClick={() => {
              history({ pathname: "/user-form" });
            }}
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              {selectedPractices.length >0 ?(
                <TableUsers
                  tableReference={tableReference}
                  refresh={refreshData}
                  canEdit={actions.update_user}
                  canDelete={actions.delete_user}
                  data={dataSource}
                />
              ): (
                <div className="mb-5 pb-5">
                  <Connections title="Please select a connection" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapped>
  );
};

export default withSelectedPractices(Users)
