import {Formik, Form, Field} from "formik";
import moment from "moment";
import {Col, FormGroup, Row} from "react-bootstrap";
import useFilter from "../hooks/useFilter";
import CustomDatePicker from "../../../CustomDatePicker";
import {defaultFormat} from "../../../../util/date";

const ClaimFilterForm = ({setFilters, practiceId}) => {
  const {
    filters,
    handleResetFilters,
    handleSetFilters
  } = useFilter(setFilters, practiceId)

  return (
    <Formik
      initialValues={filters}
      onSubmit={values => handleSetFilters(values)}
      enableReinitialize={true}
    >
      {({values, setFieldValue}) => (
        <Form>
          <FormGroup>
            <label htmlFor="patientName">Patient Name:</label>
            <Field
              type='text'
              name='patientName'
              className='form-control'
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="claimType">Claim type:</label>
            <Field
              name='claimType'
              as='select'
              className='form-control'
            >
              <option value="validate">Validate</option>
              <option value="submit">Submit</option>
            </Field>
          </FormGroup>
          <FormGroup>
            <label htmlFor="startDate">Start date:</label>
            <CustomDatePicker
              id='startDate'
              name='startDate'
              onChange={date => {
                setFieldValue('startDate', date)
              }}
              value={
                values.startDate ?
                  moment(values.startDate, defaultFormat).toDate()
                  : null
              }
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="endDate">End date:</label>
            <CustomDatePicker
              id='endDate'
              name='endDate'
              onChange={date => {
                setFieldValue('endDate', date)
              }}
              value={
                values.endDate ?
                  moment(values.endDate, defaultFormat).toDate()
                  : null
              }
            />
          </FormGroup>
          <Row>
            <Col>
              <button type='submit' className='btn btn-primary btn-block'>Apply</button>
            </Col>
            <Col>
              <button
                type='button'
                className='btn btn-danger btn-block'
                onClick={handleResetFilters}
              >
                Reset
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default ClaimFilterForm