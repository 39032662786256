import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { defaultFormat } from "../../../../util/date";
import UserHelp from "../../../util/UserHelp";
import useDataQualityFilter from "../hooks/useDataQualityFilter";

export default function DataQualityFilter({
  missingField,
  currentFilter,
  setCurrentFilter,
}) {
  const loading = false;

  const {
    data: { patientName, startDate, endDate, status, fieldFilter },
    setData,
    handleFilterSubmit,
    handleClear,
  } = useDataQualityFilter(setCurrentFilter);

  const _startDate = startDate
    ? moment(startDate, defaultFormat).toDate()
    : null;
  const _endDate = endDate ? moment(endDate, defaultFormat).toDate() : null;

  return (
    <div className="p-1">
      {missingField && (
        <>
          <UserHelp className="">
            Dear user, you can dismiss this window <br />
            by clicking outside of it.
          </UserHelp>
          <hr className="mb-3" />
          <Form.Group className="m-2">
            <Form.Label>Patient Name</Form.Label>
            <Form.Control
              id="patientName"
              type="text"
              value={patientName}
              onChange={({ target: { value } }) => {
                setData("patientName", value);
              }}
              placeholder="Patient Name"
            />
          </Form.Group>
          {/* <Form.Group className="m-2">
            <Form.Label>Status</Form.Label>
            <Form.Control
              id="status"
              type="text"
              value={status}
              onChange={({ target: { value } }) => {
                setData("status", value);
              }}
              placeholder="Status"
            />
          </Form.Group> */}
          {/* <Form.Group className="m-2">
            <Form.Label>Start Date</Form.Label>
            <InputGroup className="flex-nowrap">
              <ReactDatePicker
                className={`form-control custom-form-control `}
                dateFormat="MM/dd/yyyy"
                autoComplete="off"
                placeholderText="MM/DD/YYYY"
                name="startDate"
                id="startDate"
                showMonthDropdown
                showYearDropdown
                todayButton="Today"
                selected={_startDate}
                onChange={(d) => {
                  setData("startDate", d);
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>

          <Form.Group className="m-2">
            <Form.Label>End date</Form.Label>
            <InputGroup className="flex-nowrap">
              <ReactDatePicker
                className={`form-control custom-form-control `}
                dateFormat="MM/dd/yyyy"
                autoComplete="off"
                placeholderText="MM/DD/YYYY"
                name="endDate"
                id="endDate"
                showMonthDropdown
                showYearDropdown
                todayButton="Today"
                selected={_endDate}
                onChange={(d) => {
                  setData("endDate", d);
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group> */}
          <Form.Group className="m-2">
            <Form.Label>Missing Field</Form.Label>
            <Form.Control
              id="fieldFilter"
              as="select"
              value={fieldFilter}
              onChange={({ target: { value } }) => {
                setData("fieldFilter", value);
              }}
            >
              <option value="">*Not Filtered*</option>
              {missingField.map(({ name, value: count }) => (
                <option key={name} value={name}>
                  {name} ({count})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="m-2 text-center">
            <Button
              className="mt-3"
              variant="primary"
              type="submit"
              disabled={loading}
              onClick={handleFilterSubmit}
            >
              Apply filters
            </Button>

            <Button
              disabled={loading}
              className="mt-3 ml-1"
              variant="warning"
              type="button"
              onClick={handleClear}
            >
              Reset
            </Button>
          </Form.Group>
        </>
      )}
    </div>
  );
}
