import { useState } from "react";
import { toast } from "react-toastify";
import * as services from "../../../../../services/missingCode";
import {
  uploadFormat,
  uploadFormatFromCallCenter,
} from "../../../../../services/missingCode";
import { displayModalConfirmation } from "../../../../../util/displayMessage";
import * as fileUtils from "../../../../../util/file";
import { getFileExtension } from "./TransactionFiles.utils";
import useFileCreator from "./useFileCreator";

function renameFile(file, name) {
  return new File([file], name, {
    type: file.type,
  });
}

const initialState = {
  id: null,
  patientStatus: "",
  files: [],
  saving: false,
};

const useTransactionFiles = (data, onSuccess, shouldShowElegibility) => {
  const [current, setCurrent] = useState({
    ...initialState,
    ...data,
  });

  const fileCreator = useFileCreator(data);

  // const files = useFiles(canAppend, transactionFiles);

  function setPatientStatus(patientStatus) {
    setCurrent((c) => ({ ...c, patientStatus }));
  }

  const getFileExtensionToReplace = (file) => {
    return file.name.split(".").pop();
  };

  function save() {
    displayModalConfirmation(
      "Confirmation",
      "Dear user you will apply changes to this transaction, please confirm this message to proceed",
      () => {
        const newFiles = fileCreator.files.filter((x) => x.file);
        setCurrent((c) => ({ ...c, saving: true }));
        var formData = new FormData();
        const emptyFileNames = newFiles.filter((x) => !x.name);

        if (emptyFileNames.length > 0) {
          toast.error("Dear user, please give a name to all the new files");
          return;
        }

        newFiles.forEach((_f) => {
          const fileExtension = getFileExtensionToReplace(_f.file);
          const newFileName = _f.name + "." + fileExtension;

          formData.append("files[]", renameFile(_f.file, newFileName));
        });

        shouldShowElegibility &&
          formData.append("statusPatient", current.patientStatus);

        formData.append("transactionId", current.id);

        shouldShowElegibility
          ? uploadFormat(formData).then(([status, r]) => {
              setCurrent((c) => ({ ...c, saving: false }));
              if (!status) {
                return;
              }
              toast.success("Successfully uploaded");
              fileCreator.removeAllFiles();
              onSuccess();
            })
          : uploadFormatFromCallCenter(formData).then(([status, r]) => {
              setCurrent((c) => ({ ...c, saving: false }));
              if (!status) {
                return;
              }
              toast.success("Successfully uploaded");
              fileCreator.removeAllFiles();
              onSuccess();
            });
      },
    );
  }

  async function downloadFile(fileId, name) {
    const blob = await services.downloadFile(data.id, fileId);
    const extension = getFileExtension(name);
    const onError = (r) => {
      toast.error("Dear user, please try again");
    };

    if (extension === "word") {
      fileUtils.download(blob.data, name, onError);
      return;
    }

    fileUtils.openInBrowser(blob.data, fileUtils.FILE_TYPES.pdf, onError);
  }

  const updateFile = async (fileId, file) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("fileId", fileId);
    formData.append("transactionId", current.id);

    const [s, r] = await services.updateFile(formData);

    setCurrent((c) => ({ ...c, saving: false }));
    if (s) {
      toast.success("The file was successfully uploaded");
      return true;
    }

    return false;
  };

  const deleteFile = async (fileId) => {
    const [s, r] = await services.deleteFile(data.id, fileId);

    if (s) {
      toast.success("The file was successfully deleted");
      onSuccess();
      return true;
    }

    return false;
  };

  return {
    setPatientStatus,
    save,
    downloadFile,
    current,
    fileCreator,
    updateFile,
    deleteFile,
    // files,
  };
};

export default useTransactionFiles;
