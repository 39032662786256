import styled from "styled-components"

export const ReportContainer = styled.div`
  thead {
    th {
      //padding: 0 0 1rem 1rem;

      &:nth-child(1) {
        width: 10% !important;
      }
    }
  }

  .MuiToolbar-root {
    display: none;
  }
`;

export const PmsRow = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-left: 10px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({tam}) => `11% repeat(${tam + 1}, 1fr)`};
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  padding: 0.5rem 0;
`

export const SumRow = styled.span`
  font-weight: bold;
  width: 32px;
  height: 32px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const HeaderLocation = styled.div`
  display: grid;
  //grid-template-columns: 200px 1fr;
  grid-template-columns: ${({tam}) => `11% repeat(${tam + 1}, 1fr)`};
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  padding: 0.5rem 0;
`

export const HeaderValue = styled.span`
  font-weight: bold;
  min-width: 100px;
  text-align: center;
`;

export const SumCol = styled(SumRow)`
`;

export const NoDataIcon = styled.span`
  height: 15px;
  width: 15px;
  border: 1px solid #cccccc;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: space-between;
  padding: .15rem;
  font-size: .5rem;
  //margin-right: -2px;
`;

export const Dot = styled.span`
  height: 1.2px;
  width: 1.2px;
  border: 1px solid #cccccc;
  display: block;
  border-radius: 50%;
`;

export const PracticesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: flex-start;
`

export const PracticeWrapped = styled.div`
  position: relative;
  padding: 15px;
  background-color: #f3f7fa;
  border: 1px solid #eee;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  min-width: 303px;
  height: 50px;
  margin: 1rem 1rem 0;
  display: flex;
  justify-content: flex-end;
`

export const PracticeNameWrapped = styled.div`
  margin-bottom: .5rem;
  background-color: #3e8ef7;
  padding: .2rem .5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: -5px;
  top: 10px;
  min-width: 180px;
`

export const PracticeName = styled.div`
  font-size: .9rem;
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const PracticeSum = styled.span`
  font-weight: bold;
  font-size: 1rem;
  background-color: #3e8ef7;
  display: inline-block;
  color: #fff;
  margin-left: .625rem;
`

export const ButtonPlus = styled.button`
  font-size: 0.75rem;
  cursor: pointer;
  background-color: #0000;
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  right: 12px;
`

export const ContainerDetail = styled.div`
  background-color: #f3f7fa;
  border: 1px solid #eee;
  border-top: none;
  display: ${({show}) => show ? 'flex' : 'none'};
  flex-direction: column;
  padding: 0 1rem;
  //justify-content: flex-start;
  margin: 0 1rem;
  visibility: ${({show}) => show ? 'visible' : 'hidden'};
  min-width: 300px;
`

export const ContainerItem = styled.div`
  display: flex;
  padding: .3rem;
  min-width: 250px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  align-items: center;

  svg {
    width: .8rem;
    margin-right: 1px;
  }
`

export const ContainerData = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const IconData = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 70px;
  font-size: 11px;
  padding-left: 10px;
`

export const ItemWrapped = styled.div`
  position: relative;
  transition: all 3s ease;
  min-width: 300px;
`