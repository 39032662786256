import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {formatter} from "../../../../../../util/formatter";
import {useState} from "react";

const DentalMationProductHeader = ({generalInfo, businessInfo}) => {
  return (
    <div className='head m-0'>
      <GeneralInfo generalInfo={{...generalInfo}}/>
      <ProductInfo productInfo={businessInfo}/>
    </div>
  )
}

const GeneralInfo = ({generalInfo}) => {
  const {name, email, phone, productName} = generalInfo;

  return (
    <div className="general">
      <div>
        <span className='mr-2'>Client Name:</span> {name} <br/>
        <span className='mr-2'>E-mail:</span> {email} <br/>
        <span className='mr-2'>Phone:</span> {phone}<br/>
        <span className='mr-2'>Product:</span> {productName}<br/>
        <span className='mr-2'>Practice:</span> {name}
      </div>
    </div>
  );
}

const ProductInfo = ({productInfo}) => {
  const {lastPaymentDate, suscriptionPrice} = productInfo;

  const styleObj = {
    //width: '70%',
    display: 'flex',
    alignItems: 'start',
    //justifyContent: 'space-between'
  }

  return (
    <div className="business" style={{
      paddingLeft: '1rem',
      borderLeft: '1px solid #eee'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
        <div style={styleObj}>
          <span className='mr-2'>
            Next Billing Date:
          </span>
          <span className='mr-2'>
             {moment(lastPaymentDate).add(1, 'M').format('yyyy-MM-D')}
          </span>
        </div>
        <div style={styleObj}>
          <span className='mr-2'>Amount:</span>
          <span>{formatter.format(suscriptionPrice)}</span>
        </div>
        <div style={styleObj}>
          <span className='mr-2'>Status:</span>
          <span>Scheduled</span>
        </div>
        <div style={styleObj} className='mt-4'>
          <ButtonAction icon='file-pdf' text='Download' cls='primary' />
          <ButtonAction icon='envelope' text='Resend Invoice' cls='success' />
        </div>
      </div>
    </div>
  );
};

const ButtonAction = ({icon, cls, text}) => {
  const [cssClass, setCssClass] = useState('secondary')

  const handleHover = () => {
    setCssClass(cls)
  }

  const handleLeave = () => {
    setCssClass('secondary')
  }

  return(
    <button
      className={`btn btn-outline-${cssClass} d-flex flex-column align-items-center`}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <FontAwesomeIcon icon={icon}/>
      <span style={{fontSize: '.8rem'}}>{text}</span>
    </button>
  )
}

export default DentalMationProductHeader