import { faEnvelope } from "@fortawesome/fontawesome-free-regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export function ResetPasswordForm({
  setShowModal,
  waiting,
  onSubmit,
  cancelButton = true,
}) {
  const [email, setEmail] = useState("");
  return (
    <div className="pt-3 pb-2 px-3" style={{ color: "#37474f" }}>
      <div className="text-center">
        <FontAwesomeIcon size="4x" icon={faEnvelope} className="text-info" />
      </div>
      <div className="text-justify my-4">
        <p>
          Dear user, please type your e-mail in the box below, you will recieve
          a message with an URL to set your new password.
        </p>
      </div>
      <form
        style={{ width: "100%" }}
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit(email);
        }}
      >
        <div className="d-flex">
          <InputGroup className="mb-2">
            <FormControl
              type="text"
              disabled={waiting}
              className="form-control"
              name="email"
              id="email"
              placeholder="E-mail"
              value={email}
              onChange={({ target: { value } }) => {
                setEmail(value);
              }}
            />
            <InputGroup.Append>
              <button className="btn-info btn" disabled={waiting}>
                {!waiting && "Send "}
                {!waiting && <FontAwesomeIcon icon="paper-plane" />}
                {waiting && <FontAwesomeIcon icon="spinner" spin />}
              </button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div className="text-center mt-3">
          {cancelButton && (
            <button
              type="button"
              className="btn btn-link text-danger"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          )}
          {!cancelButton && <Link to="/login">Go to the Login page</Link>}
        </div>
      </form>
    </div>
  );
}
