import "./styles/index.css";
import React, { createContext, useLayoutEffect } from "react";
import UserRequestDetailHeader from "./components/UserRequestDetailHeader";
import useUserRequestDetail from "./useUserRequestDetail";
import UserRequestDetailPracticeList from "./components/UserRequestDetailPracticeList";
import { useNavigate } from "react-router-dom";
import { TemplateWrapped } from "../../Layout";

export const UserRequestDetailContext = createContext({
  orderId: null,
  downloadFile: (productId, fileName) => {},
});

const UserRequestDetail = () => {
  const { practices, loaded, header, downloadFile, redirect } =
    useUserRequestDetail();
  const history = useNavigate();

  useLayoutEffect(() => {
    if (redirect) {
      history(-1);
    }
  }, [redirect, history]);

  if (loaded) {
    return (
      <UserRequestDetailContext.Provider value={{ downloadFile }}>
        <TemplateWrapped title="Request Details">
          <div id="user-request-detail">
            <div className="panel">
              <div className="panel-body">
                <UserRequestDetailHeader data={header} />
                <UserRequestDetailPracticeList practices={practices} />
              </div>
            </div>
          </div>
        </TemplateWrapped>
      </UserRequestDetailContext.Provider>
    );
  }

  return null;
};

export default UserRequestDetail;
