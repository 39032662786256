import {useContext} from "react";
import {AppContext} from "../../../App";
import CustomModal from "../../CustomModal";
import usePracticesByClientSelected
  from "../../pages/Connections/components/PracticesByClientSelected/hooks/usePracticesByClientSelected";
import PracticesByClientSelected from "../../pages/Connections/components/PracticesByClientSelected";
import CurrentYear from "../../helper/CurrentYear";

export default function TemplateFooter() {
  const appContext = useContext(AppContext);
  const showPracticeModal = appContext.get("showPracticeModal");

  const {client, countSelectedPractices} = usePracticesByClientSelected()

  return (
    <footer className="site-footer">
      <div className="site-footer-legal"><CurrentYear /> DentalRobot</div>
      <div className="site-footer-right">
        {client ? (
          <>
            <strong>{client.clientName} - practices selected: {countSelectedPractices}</strong>
            <button className='btn btn-sm btn-primary ml-3' onClick={() => appContext.set('showPracticeModal', true)}>
              Show
            </button>
          </>
        ) : (
          "*No Practice Selected*"
        )}
      </div>
      {showPracticeModal && (
        <CustomModal
          size="lg"
          title="Select Practices"
          show={showPracticeModal}
          onHide={() => appContext.set('showPracticeModal', false)}
        >
          <PracticesByClientSelected
            closeModal={() => appContext.set('showPracticeModal', false)}
          />
        </CustomModal>
      )}
    </footer>
  );
}
