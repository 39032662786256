import {Form} from "react-bootstrap";
import React from "react";
import {Formik} from "formik";
import useClientUserAdmin from "./useClientUserAdmin";

const ClientUserAdmin = () => {

  const {user, setUser} = useClientUserAdmin()

  return(
    <>
      <Formik
        initialValues={user}
        enableReinitialize
        onSubmit={(values) => {
          //handleFilterSubmit(values)
        }}
      >
        {
          props => (
            <form className="mt-2 p-1" onSubmit={props.handleSubmit} >
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  id="firstname"
                  name="firstname"
                  label="firstname"
                  value={props.values.firstname}
                  onChange={props.handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  value={props.values.lastname}
                  onChange={props.handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  id="phonenumber"
                  name="phonenumber"
                  label="Phone Number"
                  value={props.values.phonenumber}
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  label="Email"
                  value={props.values.email}
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  id="password"
                  name="password"
                  label="Password"
                  value={props.values.password}
                  onChange={props.handleChange}
                />
              </Form.Group>
            </form>
          )
        }
      </Formik>

    </>
  )

}

export default ClientUserAdmin