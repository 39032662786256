import { Form, Button, Col, Row, Accordion } from "react-bootstrap";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import shortid from "shortid";
import { SubsectionTitle, ChecksList } from "../../formControls";
import { AccordionItem } from "../../CustomAccordion";
import Codes from "../Codes";

const MySwal = withReactContent(Swal);

function chunk(data, chunkSize) {
  var result = [];
  for (var i = 0; i < data.length; i += chunkSize)
    result.push(data.slice(i, i + chunkSize));
  return result;
}

const BasicForm = ({
  asAccordion,
  renderOnce,
  title,
  otherFields = [],
  submitAsDataObject,
  ...props
}) => {
  const [formFields, setformFields] = useState([]);
  const [listFields, setListFields] = useState({});

  const [errors, setErrors] = useState([]);
  const [created, setCreated] = useState(false);

  useEffect(() => {
    if (asAccordion) {
      setformFields(
        props.fields.reduce((a, i) => {
          return [...a, ...i.items];
        }, [])
      );
    } else {
      setformFields(props.fields);
    }
  }, [props.fields]);

  useEffect(() => {
    if (renderOnce) {
      if (props.showInColumns) {
        setCreated(renderInColumns(props.numberColums));
      } else {
        setCreated(render());
      }
    }

    const flds = formFields.reduce((a, d, i) => {
      if (
        d.props.items &&
        typeof d.props.values === "object" &&
        Object.keys(d.props.values).length > 0
      ) {
        a = d.props.values;
      }
      return a;
    }, {});
    setListFields(flds);
  }, [formFields]);

  const handleChange = (e, index, notifyOnChange) => {
    if (e.remove) {
      // console.log(index);
      let newList = { ...listFields };
      delete newList[e.item];
      formFields[index].props["values"] = newList;
      setListFields(newList);
      return;
    }

    let fields = formFields;
    let list = { ...listFields };
    if (fields[index].props.items) {
      console.log("por aca");

      if (list[e.indexKey]) {
        Object.assign(list[e.indexKey], { [e.target.name]: e.target.value });
      } else {
        Object.assign(list, {
          [e.indexKey]: { [e.target.name]: e.target.value },
        });
      }

      fields[index].props["values"] = list;
      setListFields(list);
    } else {
      if (fields[index].control === ChecksList) {
        fields[index].props.options.forEach((option) => {
          if (option.bookmark === e.bookmark) {
            option.value = e.value;
          }
        });
      } else {
        if (notifyOnChange) {
          notifyOnChange(e.target.value);
        }
        fields[index].props.value = e.target.value;
      }
      setformFields(fields);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "There are fields with invalid data.",
      });
    } else {
      if (!submitAsDataObject) props.onSubmit(formFields);
      else
        props.onSubmit(
          formFields.reduce((acum, { props: { key, value } }) => {
            acum[key] = value;
            return acum;
          }, {})
        );
    }
  };

  const handleError = (error) => {
    let errs = errors;
    if (error.status) {
      errs[error.control] = error.details;
      setErrors(errs);
    } else if (!error.status && errs[error.control]) {
      delete errs[error.control];
      setErrors(errs);
    }
  };

  const createElement = ({ control: Tag, props }, index) => (
    <Tag
      {...props}
      onChange={(e) => {
        handleChange(e, index, props.notifyOnChange);
      }}
      onError={(e) => {
        handleError(e);
      }}
    />
  );

  const render = () => {
    return formFields.map((elem, i) => createElement(elem, i));
  };

  const renderInColumns = (numberColums) => {
    let data = [];
    let row = "";
    let count = 0;

    return formFields.map((elem, i) => {
      const isLastItem = i === formFields.length - 1;
      const isPreviousToLast = count == numberColums - 1;
      const nextItemIsSection =
        (formFields[i + 1] || {}).control === SubsectionTitle;

      if (elem.control === SubsectionTitle)
        return (
          <Row key={shortid.generate()}>
            <Col md={12} key={shortid.generate()}>
              {createElement(elem, i)}
            </Col>
          </Row>
        );

      // Append the element in the corresponding part
      data.push(
        <Col md={6} key={shortid.generate()}>
          {createElement(elem, i)}
        </Col>
      );

      if (isPreviousToLast || isLastItem || nextItemIsSection) {
        row = (
          <Row className="boxFormItems" key={shortid.generate()}>
            {data}
          </Row>
        );
        data = [];
        count = 0;
        return row;
      }

      count++;
    });
  };

  function ContentInRowColumns(columnWidth, items) {
    const numberOfColumns = 12 / columnWidth;
    const itemsPerColumn =
      items.length > 0 ? Math.round(items.length / numberOfColumns) : 1;
    let result = [];

    let columnData = chunk(items, itemsPerColumn);

    if (columnData.length > numberOfColumns) {
      const lastItem = columnData.pop();
      if (columnData.length > 0) {
        columnData.push([...columnData.pop(), ...lastItem]);
      }
    }

    for (let i = 0; i < columnData.length; i++) {
      const elements = columnData[i];

      result.push(
        <Col key={i} md={columnWidth}>
          {elements.map((x) => createElement(x, x.itemIndex))}
        </Col>
      );
    }

    return result;
  }

  if (asAccordion) {
    return (
      <>
        {props.title && <h2>{props.title}</h2>}
        <Form onSubmit={handleSubmit} className="formMissingCode">
          <Accordion>
            {props.fields.map((x) => {
              return (
                <AccordionItem
                  headerIsClickeable
                  containerClassName="p-2"
                  headerProps={{ style: { padding: 5 } }}
                  eventKey={shortid.generate()}
                  key={shortid.generate()}
                  renderHeader={() => <strong>{x.name}</strong>}
                  renderBody={() => (
                    <Row>{ContentInRowColumns(6, x.items)}</Row>
                  )}
                />
              );
            })}
          </Accordion>
          <div className="text-right mt30 ">
            <Button
              type="submit"
              variant="success btnForm btn-action"
              size="md"
              block
            >
              Save
            </Button>
          </div>
        </Form>
      </>
    );
  }

  if (renderOnce) {
    return (
      <>
        {props.title && <h2>{props.title}</h2>}
        <Form onSubmit={handleSubmit} className="formMissingCode">
          {created &&
            created.map((e, i) => {
              return e;
            })}
          <div className="text-right mt30 ">
            <Button
              type="submit"
              variant="success btnForm btn-action"
              size="md"
              block
            >
              Save
            </Button>
          </div>
        </Form>
      </>
    );
  }

  return (
    <>
      {props.title && <h2>{props.title}</h2>}
      <Form onSubmit={handleSubmit} className="formMissingCode">
        {props.showInColumns && renderInColumns(props.numberColums)}
        {!props.showInColumns && render()}
        {otherFields}
        <div className="text-right mt30 ">
          <Button
            type="submit"
            variant="success btnForm btn-action"
            size="md"
            block
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BasicForm;

export function DynamicForm({
  handleChangeByIndex,
  handleChange,
  handleError,
  asAccordion,
  title,
  onSubmit,
  fields,
  showInColumns,
  numberColums,
  otherFields,
  disabled,
  hideSubmitButton = false
}) {
  const createElement = ({ control: Tag, props, index }) => (
    <Tag
      {...props}
      key={index}
      disabled={
        typeof disabled !== "undefined" && disabled === true
          ? true
          : props.disabled
      }
      onChange={({ target: { value } }) => {
        if (props.useBlur) {
          return;
        }
        const handler = handleChangeByIndex(index);
        handler(value);
      }}
      onBlur={({ target: { value } }) => {
        if (!props.useBlur) {
          return;
        }
        const handler = handleChangeByIndex(index);
        handler(value);
      }}
      onError={(e) => {}}
    />
  );

  const render = (formFields) => {
    return formFields.map((elem, i) => createElement(elem, i));
  };

  const renderInColumns = (numberColums, formFields) => {
    let data = [];
    let row = "";
    let count = 0;
    const colSize = numberColums > 0 ? parseInt(12 / numberColums) : 6;

    return formFields.map((elem, i) => {
      const isLastItem = i === formFields.length - 1;
      const isPreviousToLast = count == numberColums - 1;
      const nextItemIsSection =
        (formFields[i + 1] || {}).control === SubsectionTitle;

      if (elem.control === SubsectionTitle)
        return (
          <Row key={i}>
            <Col md={12}>{createElement(elem, i)}</Col>
          </Row>
        );

      // Append the element in the corresponding part
      data.push(
        <Col md={colSize} key={i}>
          {createElement(elem, i)}
        </Col>
      );

      if (isPreviousToLast || isLastItem || nextItemIsSection) {
        row = (
          <Row className="boxFormItems" key={i}>
            {data}
          </Row>
        );
        data = [];
        count = 0;
        return row;
      }

      count++;
    });
  };

  function ContentInRowColumns(columnWidth, items) {
    const numberOfColumns = 12 / columnWidth;
    const itemsPerColumn =
      items.length > 0 ? Math.round(items.length / numberOfColumns) : 1;
    let result = [];

    let columnData = chunk(items, itemsPerColumn);

    if (columnData.length > numberOfColumns) {
      const lastItem = columnData.pop();
      if (columnData.length > 0) {
        columnData.push([...columnData.pop(), ...lastItem]);
      }
    }

    for (let i = 0; i < columnData.length; i++) {
      const elements = columnData[i];

      result.push(
        <Col key={i} md={columnWidth}>
          {elements.map((x) => createElement(x, x.itemIndex))}
        </Col>
      );
    }

    return result;
  }

  if (asAccordion) {
    return (
      <>
        {title && <h2>{title}</h2>}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="formMissingCode"
        >
          <Accordion>
            {fields.map((x, i) => {
              return (
                <Codes>
                  <span className='codes-name'>{x.name}</span>
                  <Row>{ContentInRowColumns(6, x.items)}</Row>
                </Codes>
              );
            })}
          </Accordion>
          {!disabled && hideSubmitButton ? (
            <div className="text-right mt30 ">
              <Button
                type="submit"
                variant="success btnForm btn-action"
                size="md"
                block
              >
                Save
              </Button>
            </div>
          ) : null}
        </Form>
      </>
    );
  }

  return (
    <>
      {title && <h2>{title}</h2>}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="formMissingCode"
      >
        {showInColumns && renderInColumns(numberColums, fields)}
        {!showInColumns && render(fields)}
        {otherFields}
        {!disabled && hideSubmitButton ? (
          <div className="text-right mt30 ">
            <Button
              type="submit"
              variant="success btnForm btn-action"
              size="md"
              block
            >
              Save
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
}
