import {useState} from "react";
import {withoutEmptyKeys} from "../../../../../util/object";

export const initialState = {
  direction: '',
  phone_number: '',
  user_id: ''
};

const useAirCallFilter = (setFilters) => {
  const [filters, setFilter] = useState(initialState)

  const handleSubmit = (data) => {
    console.log(data, 'DATA')
    setFilter(data)
    _setFilters(data)
  }

  const _setFilters = (d) => {
    setFilters(withoutEmptyKeys(d));
  };

  return {
    initialState,
    handleSubmit
  }
}

export default useAirCallFilter