import {Accordion} from "react-bootstrap";
import CredentialWrapper from "../CredentialWrapper";

const CredentialList = (
  {
    credentials,
    addCredential,
    handleEdit,
    deleteCredential,
    handleDeleteCredentials = null
  }
) => {
  return (
    <Accordion
    defaultActiveKey={'0'}
      keys={0}>
      <CredentialWrapper
        credential={credentials}
        index={0}
        addCredential={addCredential}
        handleEdit={handleEdit}
        deleteCredential={deleteCredential}
        handleDeleteCredentials={handleDeleteCredentials}
      />
    </Accordion>
  )
}

export default CredentialList