import { Accordion, Card, Button, useAccordionToggle } from "react-bootstrap";
import { useState, Fragment, useEffect, useRef } from "react";
import ButtonList from "../ButtonList";
import { AddCircle, RemoveCircle, Edit } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/fontawesome-free-solid";

const CustomAccordion = (props) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    let d = [...props.data];
    if (props.bookmarks) {
      d.forEach((category) => {
        category.bodyContent =
          (props.bookmarks[selectedCategory] || []).bookmarks || [];
        if (selectedSubCategory) {
          category.subsection.forEach((subsection) => {
            subsection.bodyContent =
              (
                (props.bookmarks[selectedCategory] || {})[
                  selectedSubCategory
                ] || []
              ).bookmarks || [];
          });
        }
      });
    }
    setData(d);
  }, [props.data, props.bookmarks, selectedCategory, selectedSubCategory]);

  useEffect(() => {
    setSelectedCategory(props.selected);
  }, [props.selected]);

  const handleSelectCategory = (e) => {
    setSelectedCategory(e);
    setSelectedSubCategory(null);
    props.onSelectSection(e);
  };

  const handleSelectSubCategory = (e) => {
    setSelectedSubCategory(e);
    props.onSelectSubSection(e);
  };

  const handleClickSubcategory = (e, id) => {
    //Check if clicked button belongs to selected category
    if (id === selectedCategory) {
      props.onClickBtnsHeader("subsection");
    } else {
      handleSelectCategory(id);
      props.onClickBtnsHeader("subsection");
    }
  };

  const handleClickBookmark = (e, type, id) => {
    //Check if clicked button belongs to selected category
    if (type === "section") {
      setSelectedCategory(id);
      props.onSelectSection(id);
      props.onClickBtnsHeader("bookmark", type);
    } else {
      setSelectedSubCategory(id);
      props.onSelectSubSection(id);
      props.onClickBtnsHeader("bookmark", type);
    }
    // if(id === selectedCategory && type==="section")
    // {
    //   setSelectedCategory(id);
    //   props.onSelectSection(id);
    //   props.onClickBtnsHeader("bookmark",type);
    // }
    // else if(){
    //   setSelectedSubCategory(id);
    //   props.onSelectSubSection(id);
    //   props.onClickBtnsHeader("bookmark",type);
    // }
  };

  const handleClickBookmarkButtons = (btn) => {
    props.onClickBtns(btn);
  };

  const handleClickEdit = () => {
    console.log("Click");
  };
  const handleClickRemoveCategory = (id) => {
    props.onRemoveSection(id);
  };

  const handelClickRemoveSubCategory = (id) => {
    props.onRemoveSubSection(id);
  };
  return (
    <Fragment>
      {props.title && <h3>{props.title}</h3>}
      <Accordion activeKey={selectedCategory} onSelect={handleSelectCategory}>
        {data.length > 0 &&
          data.map((item) => {
            return (
              <Card className="acordeon__contenedor" key={item.section["id"]}>
                <Card.Header className="acordeon__titulo">
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={item.section["id"]}
                  >
                    {item.section["label"]} <Edit onClick={handleClickEdit} />
                  </Accordion.Toggle>
                  {item.section && (
                    <Fragment>
                      {" "}
                      <Button
                        variant=" optionCategories"
                        onClick={(e) =>
                          handleClickBookmark(e, "section", item.section["id"])
                        }
                        value="bookmark"
                      >
                        <AddCircle className="smIcon" />
                        <span className="lbl_btnList">Add Bookmark</span>
                      </Button>{" "}
                      <Button
                        variant=" optionCategories"
                        onClick={(e) =>
                          handleClickSubcategory(e, item.section["id"])
                        }
                        value="subsection"
                      >
                        <AddCircle className="smIcon" />
                        <span className="lbl_btnList">Add SubCategory</span>
                      </Button>{" "}
                      <Button
                        variant=" optionCategories"
                        onClick={(e) =>
                          handleClickRemoveCategory(item.section["id"])
                        }
                      >
                        <RemoveCircle className="smIcon" />
                        <span className="lbl_btnList">Remove</span>
                      </Button>{" "}
                    </Fragment>
                  )}
                </Card.Header>
                <Accordion.Collapse eventKey={item.section["id"]}>
                  <Card.Body>
                    {item.subsection && (
                      <Accordion
                        activeKey={selectedSubCategory}
                        onSelect={handleSelectSubCategory}
                      >
                        {item.subsection.map((itemSub) => {
                          return (
                            <Card key={itemSub["id"]}>
                              <Card.Header className="acordeon__titulo">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={itemSub["id"]}
                                >
                                  {itemSub["label"]}
                                </Accordion.Toggle>
                                <Button
                                  variant=" optionCategories"
                                  onClick={(e) =>
                                    handleClickBookmark(
                                      e,
                                      "subsection",
                                      itemSub["id"]
                                    )
                                  }
                                  value="bookmark"
                                  size="sm"
                                >
                                  <AddCircle className="smIcon" />
                                  <span className="lbl_btnList">
                                    Add Bookmark
                                  </span>
                                </Button>
                                <Button
                                  variant=" optionCategories"
                                  onClick={(e) =>
                                    handelClickRemoveSubCategory(itemSub["id"])
                                  }
                                >
                                  <RemoveCircle className="smIcon" />
                                  <span className="lbl_btnList">Remove</span>
                                </Button>{" "}
                              </Card.Header>
                              <Accordion.Collapse eventKey={itemSub["id"]}>
                                <Card.Body>
                                  <div className="threeColums">
                                    <ButtonList
                                      data={itemSub.bodyContent}
                                      className="threeColums"
                                      selectAll={false}
                                      conditionOnclick={true}
                                      showInactive={true}
                                      msgFailCondition="Please, select a category to load bookmarks."
                                      onClick={handleClickBookmarkButtons}
                                      removeItemsOnDelete={true}
                                    />
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })}
                      </Accordion>
                    )}
                    <div className="itemlistAccordeon">
                      <ButtonList
                        data={item.bodyContent}
                        className="threeColums"
                        selectAll={false}
                        showInactive={true}
                        conditionOnclick={true}
                        msgFailCondition="Please, select a category to load bookmarks."
                        onClick={handleClickBookmarkButtons}
                        removeItemsOnDelete={true}
                      />
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
      </Accordion>
    </Fragment>
  );
};
export default CustomAccordion;

export function AccordionItem({
  containerClassName,
  renderHeader,
  renderBody,
  headerIsClickeable,
  headerProps,
  eventKey,
  empty = false,
  customDropDownIcon,
  fullWidth = false,
  ...props
}) {
  const ref = useRef(null);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});
  return (
    <Card className="px-3">
      <Card.Header
        onClick={
          props.handleClick
            ? props.handleClick
            : (ev) => {
                // Prevent toggling when cliking our header component
                if (
                  headerIsClickeable ||
                  (ref.current && !ref.current.contains(ev.target))
                ) {
                  decoratedOnClick();
                }
              }
        }
        className={`d-flex justify-content-between ${containerClassName}`}
      >
        <div className={`d-inline no-pointer ${fullWidth ? 'w-100' : ''}`} {...headerProps} ref={ref}>
          {renderHeader && renderHeader()}
        </div>
        {!customDropDownIcon ? (
          <button className="btn" type="button">
            <FontAwesomeIcon className="text-muted" icon={faBars} size="xs" />
          </button>
        ) : (
          customDropDownIcon
        )}
      </Card.Header>
      {!empty ? (
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body> {renderBody && renderBody()}</Card.Body>
        </Accordion.Collapse>
      ) : null}
    </Card>
  );
}
