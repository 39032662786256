import {TemplateWrapped} from "../../../Layout";
import {HeaderButton} from "../../../RemarkTemplate/Body";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useContext, useState} from "react";
import PermissionContext from "../context/PermissionContext";
import PermissionForm from "./PermissionForm";
import PermissionsList from "./PermissionsList";
import UserHelp from "../../../util/UserHelp";

const PermissionsWrapped = () => {
  const {createSection, sections} = useContext(PermissionContext)

  const [showModal, setShowModal] = useState(false);
  return (
    <TemplateWrapped
      title='Permissions'
      headerButtons={
        <HeaderButton
          icon={faPlus}
          message='Add section'
          onClick={() => {
            setShowModal(true)
          }}
        />
      }
    >
      {
        sections.length > 0 ? (
          <PermissionsList list={sections} type={'section'} />
        ) : (
          <div>Dear user, there is no permissions yet, please try to create one</div>
        )
      }

      {showModal ?
        (<PermissionForm
          initialValues={{
            type: 'Module',
          }}
          onCancel={() => setShowModal(false)}
          onSave={(values)=> createSection(values).then((r) => setShowModal(false))}
        />) : null
      }
    </TemplateWrapped>
  )
}

export default PermissionsWrapped