import {useCallback, useEffect, useState} from "react";
import * as services from '../permissions.services'
import {findByIdCatalog, groupByParentId} from "../permissions.utils";
import {toast} from "react-toastify";

const usePermission = () => {
  const [sections, setSections] = useState([])

  const loadPermissions = useCallback(async () => {
    const [permissionPromise, catalogPromise, permissionTypePromise] = await Promise.all([
      services.getPermissions(),
      services.getCatalog(),
      services.getPermissionType()
    ])

    const [statusPermission, resPermission] = permissionPromise
    const [statusCatalog, resCatalog] = catalogPromise
    const [statusPermissionType, resPermissionType] = permissionTypePromise

    if (statusPermission && statusCatalog && resPermission.length && resCatalog.length) {
      const merge = resPermission.map(permission => {
        const {_id, ...find} = findByIdCatalog(permission.systemCatalogId, resCatalog)
        return {...permission, ...find}
      })
      setSections(groupByParentId(merge))
    }
  }, [])

  useEffect(() => {
    loadPermissions()
  }, [loadPermissions])

  const reloadOnSuccess = useCallback(
    ([s, r], message) => {
      if (s) {
        toast.success(message)
        loadPermissions()
      }
    }
  )

  const createSection = (data) => {
    return services.savePermission(data).then((r) => {
      reloadOnSuccess(r, 'Created')
    })
  }

  const updateSection = (id, data) => {
    return services.updatePermission(id, data).then((r)=>{
      reloadOnSuccess(r, 'Updated successful')
    })
  }

  const deleteSection = (id) => {
    services.deletePermission(id).then((r)=>{
      reloadOnSuccess(r, 'Deleted')
    })
  }

  const createElement = (parentLevel, data) => {
    return services.savePermission(data).then((r) => {
      reloadOnSuccess(r, 'Created')
    })
  }

  const updateElement = (parentLevels, elementIndex, data) => {

  }

  const deleteElement = (parentLevels, elementIndex) => {
    console.log(elementIndex)
  }

  return {
    sections,
    createSection,
    updateSection,
    deleteSection,
    createElement,
    updateElement,
    deleteElement,
  }
}

export default usePermission