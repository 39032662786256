import {ReactComponent as ContainSvg} from "../../assets/svg/contain.svg";
import {ReactComponent as NotContainSvg} from "../../assets/svg/not_contain.svg";
import {ReactComponent as EqualSvg} from "../../assets/svg/equal.svg";
import {ReactComponent as NotEqualSvg} from "../../assets/svg/not_equal.svg";
import {ReactComponent as StartWithSvg} from "../../assets/svg/start_with.svg";
import {ReactComponent as EndWithSvg} from "../../assets/svg/end_with.svg";
import {ReactComponent as EmptySvg} from "../../assets/svg/empty.svg";
import {ReactComponent as NotEmptySvg} from "../../assets/svg/not_empty.svg";

export const COLORS = {
  c: '#6aa549',
  nc: '#507737',
  e: '#71469e',
  ne: '#4b2f68',
  sw: '#4274b9',
  ew: '#2f5a8f',
  em: '#b03e28',
  nem: '#dba710'
}

export const ICONS = {
  c: ContainSvg,
  nc: NotContainSvg,
  e: EqualSvg,
  ne: NotEqualSvg,
  sw: StartWithSvg,
  ew: EndWithSvg,
  em: EmptySvg,
  nem: NotEmptySvg
}

export const FILTER_KEY = ['patientName', 'carrier', 'statusPatient', 'ivProcessResult', 'provider']

export const FILTER_REFERENCE = {
  carrier: 'Carrier Name',
  ivProcessResult: 'Verification By',
  patientName: 'Patient Name',
  statusPatient: 'Eligibility Status',
  type: 'Record Type',
  provider: 'Provider'
}

export const FILTERS_ORDER = {
  dates: 0,
  patientName: 1,
  type: 2,
  ivProcessResult: 3,
  carrier: 4,
  statusPatient: 5,
  provider: 6
}
