import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import * as services from "../../../../../../services/missingCode";
import { displayModalConfirmation } from "../../../../../../util/displayMessage";
import Cookies from "universal-cookie";
import { decryptData } from "../../../../../../config/encryptData";
import { toast } from "react-toastify";

const useMemberPolicy = (setReloadData) => {
  const {
    state: { id },
  } = useLocation();

  const [linked, setLinked] = useState([]);
  const [linkedData, setLinkedData] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [sharedElements, setSharedElements] = useState([]);

  const linkedDataRecursive = content => {
    const arrShared = [];

    content.forEach((category) => {
      const { _id, items } = category;

      if (_id && sharedElements.includes(_id)) {
        arrShared.push(category);
      }

      if (items && Array.isArray(items)) {
        const subItemsResult = linkedDataRecursive(items);
        arrShared.push(...subItemsResult);
      }
    });

    return arrShared;
  }

  useEffect(() => {
    async function getLinked() {
      const [linkedPromise, templatePromise] = await Promise.all([
        services.getLinkedTransactions(id),
        services.getSharedElementsByTemplateId(),
      ]);

      const [statusLinked, resLinked] = linkedPromise;
      const [statusTemplate, resTemplate] = templatePromise;

      if (statusLinked && statusTemplate) {
        const { elements } = resTemplate[0] || [];
        setLinked(resLinked);
        setSharedElements(elements);
      }
    }

    getLinked();
  }, [id]);

  useEffect(() => {
    async function getData() {
      const [status, data] = await services.getDataMerge(selectedElement);
      if (status) {
        const { content } = data;
        const linkedArrData = linkedDataRecursive(content);
        setLinkedData(linkedArrData);
      }
    }

    if (selectedElement) getData();
  }, [selectedElement]);

  const handleClickItem = (selectedId) => {
    setSelectedElement(selectedId);
  };

  const handleClick = async () => {
    const other = await getOtherOptions();
    displayModalConfirmation(
      "Confirmation",
      "Dear user, are you sure you want to continue?",
      () => {
        decryptData(other).then(async (res) => {
          const { name } = res;
          const dataBookmarks = linkedData
            .map((data) => {
              if (data.bookmark) {
                let obj = {};
                const bookmarkName = data.bookmark;
                const bookmarkValue = data.value;
                obj[bookmarkName] = bookmarkValue;
                return obj;
              }
              const items = data.options || data.items;
              return items
                .map((item) => {
                  let obj = {};
                  const bookmarkName = item.bookmark;
                  const bookmarkValue = item.value;
                  obj[bookmarkName] = bookmarkValue;
                  return obj;
                })
                .reduce((acc, curr) => {
                  return { ...acc, ...curr };
                }, {});
            })
            .reduce((acc, curr) => {
              return { ...acc, ...curr };
            }, {});
          dataBookmarks["user"] = name;
          const [status] =
            await services.savedTransactionWithSharedElements(
              dataBookmarks,
              id,
              name
            );
          if (status) {
            toast.success("The transaction was updated successfully");
            setReloadData(true);
          }
        });
      }
    );
  };

  const getOtherOptions = async () => {
    const cookies = new Cookies();
    return JSON.parse(cookies.get("otherOptions") || "{}");
  };

  return {
    linked,
    linkedData,
    selectedElement,
    handleClickItem,
    handleClick,
  };
};

export default useMemberPolicy;
