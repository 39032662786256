import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useState } from "react";
import { Button } from "react-bootstrap";
import CustomSwitch from "../../CustomSwitch";
import { TemplateWrapped } from "../../Layout";
import TemplatePreview from "../../TemplatePreview";
import CategoryManager from "./components/CategoryManager";
import GeneralForm from "./components/GeneralForm";
import ReorderModal from "./components/ReorderModal";
import SearchBookmarks from "./components/SearchBookmarks";
import "./styles.css";
import { TemplateContext } from "./TemplateContext";
import useForm from "./useForm";

function DownloadButton({ downloadTemplate }) {
  const [downloading, setDownloading] = useState(false);

  return (
    <Button
      variant="text-action btn-link p-0 text-nowrap"
      disabled={downloading}
      onClick={async () => {
        setDownloading(true);
        await downloadTemplate();
        setDownloading(false);
      }}
    >
      <FontAwesomeIcon
        icon={downloading ? "spinner" : "download"}
        spin={downloading}
      />{" "}
      Download
    </Button>
  );
}

function FormHeader({
  downloading,
  setDownloading,
  name,
  description,
  downloadTemplate,
  setPreviewMode,
  previewMode,
}) {
  const [editing, setEditing] = useState(false);

  return (
    <div className="d-flex justify-content-between">
      {!editing ? (
        <>
          <div>
            <div className="d-flex">
              <h3 className="d-inline-block m-0 mr-2">{name}</h3>
              <Button
                className="px-0"
                variant="link"
                onClick={(ev) => {
                  setEditing(true);
                }}
              >
                <FontAwesomeIcon icon="edit" />
              </Button>
            </div>
            <small className="text-muted d-inline-block mt-1 w-75">
              {description}
            </small>
          </div>
          <div>
            <CustomSwitch
              label="Preview"
              checked={previewMode}
              onChange={() => {
                setPreviewMode(!previewMode);
              }}
            />
            <DownloadButton
              downloading={downloading}
              setDownloading={setDownloading}
              downloadTemplate={downloadTemplate}
            />
          </div>
        </>
      ) : (
        <GeneralForm
          name={name}
          description={description}
          discardChanges={() => {
            setEditing(false);
          }}
        />
      )}
    </div>
  );
}

export default function Form(props) {
  const { calls, ...rest } = useForm(props.id);

  const {
    data,
    previewData,
    previewMode,
    loadingData,
    downloading,
    selectedCategory,
    setDownloading,
    setSelectedCategory,
    setPreviewMode,
    reorder,
  } = rest;

  return (
    <TemplateContext.Provider
      value={{
        ...calls,
        ...rest,
        setActiveCategory: setSelectedCategory,
      }}
    >
      <TemplateWrapped title="Template">
        {!loadingData && data ? (
          <div className="panel">
            <div className="panel-body pb-5">
              <div className="container-fluid template-form">
                <div className="row">
                  <div className="col">
                    <FormHeader
                      description={data.description}
                      name={data.name}
                      downloadTemplate={calls.downloadTemplate}
                      downloading={downloading}
                      setDownloading={setDownloading}
                      setPreviewMode={setPreviewMode}
                      previewMode={previewMode}
                    />
                    <hr className="mt-2" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {!previewMode ? (
                      <div className="my-2">
                        <SearchBookmarks selectedCategory={selectedCategory} />
                        <CategoryManager data={data.categories || []} />
                        {reorder ? (
                          <ReorderModal
                            data={reorder.data}
                            title={reorder.title}
                            onReorderItem={reorder.onReorderItem}
                            onClose={(hasChanges) => {
                              calls.setReorder(false);
                              if (hasChanges) calls.loadDataFromId(true);
                            }}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <TemplatePreview data={previewData} asTemplate />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center p-5 m-5 text-muted">
            Loading data, please wait... <FontAwesomeIcon spin icon="spinner" />
          </div>
        )}
      </TemplateWrapped>
    </TemplateContext.Provider>
  );
}
