import { faFilter } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/site.min.css";
import "../../../assets/css/site.override.css";
import "../../../assets/fonts/web-icons/web-icons.min.css";
import "../../../assets/fonts/7-stroke/7-stroke.min.css";
import "../../../assets/fonts/open-iconic/open-iconic.min.css";
import { TemplateHeader, TemplateMenu } from "../../RemarkTemplate";
import UserHelp from "../../util/UserHelp";
import TemplateFooter from "../Footer";

export function HeaderFilterButton({
  active,
  icon = faFilter,
  message = "Filter",
  render: Component = () => {
    <div></div>;
  },
  componentProps = {},
  children,
}) {
  return (
    <HeaderButton
      icon={icon}
      message={message}
      active={active}
      popOverContent={
        <>
          <UserHelp>
            Dear user, you can dismiss this window <br />
            by clicking outside of it.
          </UserHelp>
          {children || <Component {...componentProps} />}
        </>
      }
    />
  );
}

export function HeaderButton({
  icon,
  message,
  active = false,
  popOverContent = null,
  disabled = false,
  children,
  onClick = () => {},
}) {
  const overlayOptions = popOverContent
    ? {
        trigger: "click",
        placement: "bottom-end",
        rootClose: true,
        overlay: (
          <Popover style={{ maxWidth: "500px" }} id="popover-basic">
            <Popover.Title as="h3">{message}</Popover.Title>
            <Popover.Content>{popOverContent}</Popover.Content>
          </Popover>
        ),
      }
    : {
        placement: "top",
        overlay: <Tooltip>{message}</Tooltip>,
      };

  const button = (
    <button
      type="button"
      className={`btn btn-sm btn-icon btn-inverse btn-round ${
        active ? "btn-action text-white" : ""
      }`}
      data-toggle="tooltip"
      data-original-title={message}
      disabled={disabled}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : children}
    </button>
  );

  return !disabled ? (
    <OverlayTrigger delay={{ show: 250, hide: 100 }} {...overlayOptions}>
      {button}
    </OverlayTrigger>
  ) : (
    button
  );
}

function BreadCumb({ path, displayName, active }) {
  return (
    <li className={"breadcrumb-item " + (active ? "active" : "")}>
      {active && displayName}
      {!active && <Link to={path}>{displayName}</Link>}
    </li>
  );
}

const TemplateBody = ({
  noWrapped,
  showAsideMenu,
  setShowAsideMenu,
  showDropDownUserMenu,
  setShowDropDownUserMenu,
  showDotsMenu,
  setShowDotsMenu,
  activePage,
  breadCumbs = [],
  headerButtons = [],
  className,
  ...props
}) => {
  return (
    <section
      className={`animsition site-menubar-keep ${
        showAsideMenu
          ? "site-menubar-unfold site-menubar-open"
          : "site-menubar-fold site-menubar-hide"
      } ${className}`}
    >
      <TemplateHeader
        showDropDownUserMenu={showDropDownUserMenu}
        showDotsMenu={showDotsMenu}
        showAsideMenu={showAsideMenu}
        setShowAsideMenu={() => {
          if (!showAsideMenu) {
            setShowDotsMenu(false);
          }
          setShowAsideMenu(!showAsideMenu);
        }}
        showFullSizeLogo={!showAsideMenu}
        setShowDropDownUserMenu={() => {
          setShowDropDownUserMenu(!showDropDownUserMenu);
        }}
        setShowDotsMenu={() => {
          if (!showDotsMenu) {
            setShowAsideMenu(false);
          }
          setShowDotsMenu(!showDotsMenu);
        }}
      />
      <TemplateMenu
        isDesktop={true}
        activeItem={activePage && activePage.path}
        isMobile={false}
        show={showAsideMenu}
        menuIsCollapsed={!showAsideMenu}
      />
      <div className="page">
        <div className="page-header">
          <h1 className="page-title">{props.title}</h1>
          <ol className="breadcrumb mt-3 mb-0">
            {breadCumbs.map((x) => (
              <BreadCumb key={x.path} {...x} />
            ))}
            {activePage && <BreadCumb {...activePage} active />}
          </ol>
          <div className="page-header-actions">{headerButtons}</div>
        </div>

        <div className="page-content">{props.children}</div>
      </div>
      <TemplateFooter />
    </section>
  );
};
export default TemplateBody;
