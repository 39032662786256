import { Icon } from "@material-ui/core";
import React from "react";

const ColumnAudited = (props) => {
  const { audited } = props;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon style={{ color: audited ? "#fdc643" : "#dedede" }}>star</Icon>
      </div>
    </>
  );
};

export default ColumnAudited;
