import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomTable from "../../../CustomTable";
import EditIcon from "@material-ui/icons/Edit";

const TransactionTable = (props) => {
  const {
    data = [],
    dataSource,
    tableReference,
    refreshData,
    setTransactionSelect,
    setShowModalEdit
  } = props;

  const MySwal = withReactContent(Swal);

  const columns = [
    {
      title: "Id",
      field: "_id",
      hidden: true,
    },
    {
      title: "Patient First Name",
      field: "patientFirstName",
      sorting: true,
      defaultSort: "desc",
    },
    {
      title: "Patient Last Name",
      field: "patientLastName",
      sorting: true,
      defaultSort: "desc",
    },
    {
      title: "Carrier Name",
      field: "carrierName",
      sorting: true,
      defaultSort: "desc",
    },
    // {
    //   title: "Practice",
    //   field: "practice",
    //   sorting: true,
    //   defaultSort: "desc",
    // },
    {
      title: "Type Record",
      field: "typeRecord",
      sorting: true,
      defaultSort: "desc",
    },

    {
      title: "Appointment",
      field: "apptDate",
      sorting: true,
      defaultSort: "desc",
    },
    // {
    //   title: "Relationship",
    //   field: "relationship",
    //   sorting: true,
    //   defaultSort: "desc",
    // },
    // {
    //   title: "Patient First Name",
    //   field: "patientFirstName",
    //   sorting: true,
    //   defaultSort: "desc",
    // },
  ];

  let actions = [];

  actions.push((rowData) => ({
    icon: () => <EditIcon color="primary"/>,
    tooltip: "Edit Transaction",
    onClick: () => {
      setTransactionSelect(rowData)
      setShowModalEdit(true)
    },
  }));

  return (
    <div>
      <CustomTable
        columns={columns}
        // actions={actions}
        search={false}
        pageSize={25}
        asyncDataSource={dataSource}
        tableReference={tableReference}
      />
    </div>
  );
};

export default TransactionTable;
