import {
  BAD_STATUS,
  EMPTY,
  NO_ACTION_TAKEN,
  NO_INSURANCE_COMPANY,
} from "./constanst";

export const setIconVerificationStatus = (ivStatus) => {
  if (BAD_STATUS.includes(ivStatus)) return true;
  // Max out
  // Inactive
  // Not found
  // Empty
  for (const status of BAD_STATUS) {
    const regex = new RegExp(`^${status}`);
    if (regex.test(ivStatus)) return true;
  }

  return false;
};

export const verifiedIvStatus = (ivStatus, ivProcessResult) => {
  return !!(
    setIconVerificationStatus(ivStatus.toLowerCase()) ||
    ivProcessResult.toLowerCase() === "empty"
  );
};

export const verifyIsELGAndDoneBy = (type, ivProcessResult) => {
  return !!(type === "ELG" && ivProcessResult.indexOf("Done by") > -1);
};

export const validateCarrierName = (carrier = "") => {
  return (
    carrier.toLowerCase() === EMPTY ||
    carrier.toLowerCase() === NO_INSURANCE_COMPANY
  );
};

export const validateNoActionTaken = (ivProcessResult = "", ivStatus = "") => {
  return (
    ivProcessResult.toLowerCase() === NO_ACTION_TAKEN ||
    ivStatus.toLowerCase() === NO_ACTION_TAKEN
  );
};

export const verifyRegexForPatientNameInElegibility = (iv_status) => {
  const pattern = /patient doesnt have insurance info available/;
  return pattern.test(iv_status.toLowerCase());
};
