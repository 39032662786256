import { createContext } from "react";

export const TemplateContext = createContext({
  associateBookmarks: () => {},
  addCategory: () => {},
  addSubCategory: () => {},
  removeBookmark: () => {},
  searchBookmark: () => {},
  getBookmarkById: () => {},
  updateGeneralData: () => {},
  deleteCategory: () => {},
  deleteBookmark: () => {},
  downloadTemplate: () => {},
  updateCategory: () => {},
  createBookmark: () => {},
  getAssociatedBookmarkData: () => {},
  updateAssociatedBookmarkData: () => {},
  getBookmarkActions: () => {},
  getBookmarkAffectTypes: () => {},
  getBookmarksToAffect: () => {},
  saveAffectedBookmarks: () => {},
  updateBookmarkConditions: () => {},
  setReorder: () => {},
  reorderCategory: (order, categoryId, subCategoryId) => {},
  reorderBookmark: (order, bookmarkId, categoryOfbm, parentCategoryOfbm) => {},
  selectedCategory: null,
  previewMode: false,
  setPreviewMode: () => {},
});
