import {Api, decryptApiResponse} from "../../../../../config/Api"
import {statusAndResponse} from "../../../../../util/requestHandler";

export function verifyFiles(transactionId, practiceId){
  return statusAndResponse(
    Api.put('/api/iv/transaction/files/clean', {
      transactionId,
      practiceId,
    }),
    decryptApiResponse
  )
}

export function getFiles(transactionId, practiceId){
  return statusAndResponse(
    Api.get(`/api/iv/transaction/${transactionId}`, {
      practiceId,
    }),
    decryptApiResponse
  )
}