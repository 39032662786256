import styled from "styled-components";

export const NotesContainer = styled.div``;

export const NoteContainer = styled.div`
  margin: 2rem 0 3rem;

  &:first-of-type {
    margin-top: 0;
  }

  span {
    color: #959da5;
    font-weight: 100;
    display: inline-block;
    margin-left: 2;
  }
`;

export const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 7.5px;
  font-weight: 300;
  font-size: 1rem;
  display: block;
  color: #2e353b;
`;

export const TextArea = styled.textarea.attrs({
  className: "form-control",
})`
  color: #2e353b;
`;

export const ModalMessageHeading = styled.strong`
  font-size: 1rem;
  display: block;
  font-weight: 500;
  margin-top: 0.5rem;
  &:before {
    content: "-";
  }
`;

export const ModalList = styled.ul`
  /* padding-left: 20px; */
`;
