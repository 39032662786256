import React from "react";
import { Row, Col } from "react-bootstrap";
import { TemplateWrapped } from "../../Layout";
import useFeeSchedule from "./hooks/useFeeSchedule";
import LoadExcel from "./components/LoadExcel";
import { HeaderButton } from "../../RemarkTemplate/Body";
import { faExternalLinkAlt } from "@fortawesome/fontawesome-free-solid";
import { Navigate } from "react-router-dom";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";

const FeeSchedule = (props) => {
  const {
    formatedPractices,
    years,
    setSelectedPractice,
    setSelectedYear,
    selectedPractice,
    selectedYear,
    redirectTo,
    setRedirectTo,
  } = useFeeSchedule();
console.log(props)
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }
  return (
    <TemplateWrapped
      title="Fee Schedule"
      headerButtons={
        <>
          <HeaderButton
            icon={faExternalLinkAlt}
            message="Update Records"
            onClick={() => {
              setRedirectTo("feeschedule/update");
            }}
          />
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <Row className="p-4">
            <Col md={{ span: 4, offset: 0 }}>
              <select
                name="practices"
                id="practices"
                className="form-control"
                defaultValue={""}
                onChange={({ target }) => {
                  setSelectedPractice(target.value);
                }}
              >
                <option value={""} disabled>
                  Select a practice
                </option>
                {formatedPractices.map((practice) => (
                  <option key={practice.id} value={practice.id}>
                    {practice.label}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={{ span: 4, offset: 0 }}>
              <select
                name="years"
                id="years"
                className="form-control"
                defaultValue={""}
                onChange={({ target }) => {
                  setSelectedYear(target.value);
                }}
              >
                <option value={""} disabled>
                  Select a year
                </option>
                {years.map(({ year, _selected }) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </Col>
            {selectedYear && selectedPractice && (
              <LoadExcel
                selectedPractice={selectedPractice}
                selectedYear={selectedYear}
              />
            )}
          </Row>
        </div>
      </div>
    </TemplateWrapped>
  );
};

const Test = withSelectedPractices(FeeSchedule)

export default Test
