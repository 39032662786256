import {useContext, useState} from "react";
import {savePriority, saveSort} from "./sortServices";
import {AppContext} from "../../../../../App";
import {displayModalMessage} from "../../../../../util/displayMessage";

const clean = obj => {
  const _obj = {...obj}
  for(let propName in _obj){
    if (_obj[propName] === null || _obj[propName] === undefined || _obj[propName] === "" || propName === ""){
      delete _obj[propName]
    }
  }
  return _obj
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const transformIvStatus = (priority) => {
  let arr = [
    {name: 'Active', index: 1},
    {name: 'Max out', index: 2},
    {name: 'Inactive', index: 3},
    {name: 'Not Found', index: 4},
    {name: 'Empty', index: 5},
    {name: 'Check', index: 6},
  ]

  return priority.length === 0 ? arr : [...priority].reverse().map((o, i) => {
    return {
      name: o.k,
      index: i + 1
    }
  })
}

const useSortForm = () => {
  const [sortData, setSortData] = useState({})
  const appContext = useContext(AppContext)
  const prevSort = appContext.get('sortConfiguration')
  const {transaction = {}} = prevSort
  const {priority = []} = transaction

  const [ivStatusArr, setIvStatusArr] = useState(transformIvStatus(priority))

  const handleSubmit = async (values) => {
    const _values = createSortObject(values)
    const [status, res] = await saveObjectSort(_values)
    if (status) {
      await savePriorityStatus(_values)
    }
  }

  const savePriorityStatus = async (val) => {
    const _ivStatus = [...ivStatusArr]
      .reverse()
      .map((st, index) => {
        return {k: st.name, v: index + 1}
      })
    const [status, res] = await savePriority(_ivStatus)

    if (status){
      appContext.set('sortConfiguration', {...prevSort, transaction: {...val, priority: _ivStatus}})
    }
  }

  const saveObjectSort = async (values) => {
    if (Object.keys(values).length === 0) {
      displayModalMessage('Sort', 'Please select at least one of the options')
      return;
    }

    return  saveSort(values)
  }

  const createSortObject = values => {
    const _values = clean(values)
    const arr = Object.keys(_values)
    let _obj = {}
    for (let i = 0; i < arr.length; i += 2) {
      _obj[_values[arr[i]]] = +_values[arr[i + 1]]
    }
    return clean(_obj)
  }

  const onDragEnd = result => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const _status = reorder(ivStatusArr, result.source.index, result.destination.index)

    setIvStatusArr(_status)
  }

  return {
    sortData,
    setSortData,
    prevSort,
    ivStatusArr,
    handleSubmit,
    onDragEnd
  }
}

export default useSortForm