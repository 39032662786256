import {Button, Tooltip as MTooltip} from "@material-ui/core";
import icon_details from "../pages/AllTransactions/assets/svg/icon_details.png";
import {TextButtonActions} from "../pages/AllTransactions/components/styles/AllTransactionTable.style";
import React from "react";

const ActionButtonItem = (props) => {
  const {TextTooltip, TextButton, Icon, OnClick, handleClose} = props

  return (
    <>
      <div className="row w-75">
        <MTooltip title={TextTooltip} >
          <Button
            style={{marginLeft: 20, marginRight: 0, marginTop: 0, minWidth:150}}
            color="primary"
            className="justify-content-start w-100"
            startIcon={Icon}
            onClick={(e) => {
              OnClick()
              handleClose(e)
            }}>
            <TextButtonActions>
              {TextButton}
            </TextButtonActions>
          </Button>
        </MTooltip>
      </div>
    </>
  )
}

export default ActionButtonItem