import {useContext, useEffect, useState} from "react";
import {getTransaction} from "./TransactionForm.service";
import {useAsyncTableData} from "../../CustomTable/hooks";
import {getUrlParams} from "../../../util/browser";
import {AppContext} from "../../../App";

const useTransactionForm = () => {
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dataTransaction, setDataTransaction] = useState([]);
  const [transactionSelect, setTransactionSelect] = useState({});
  const initialValues = {
    "carrierName": "",
    "apptDate": "",
    "employerName": "",
    "relationship": "",
    "memberId": "",
    "patientId": "",
    "subscriberId": "",
    "patientFirstName": "",
    "patientLastName": "",
    "patientDOB": "",
    "subscriberFirstName": "",
    "subscriberLastName": "",
    "subscriberDOB": "",
    "typeRecord": "",
    "practiceId": ""
  }
  const appContext = useContext(AppContext);
  const practiceIds = appContext.get("selectedPractices");
  const {
    dataSource,
    tableReference,
    refreshData
  } = useAsyncTableData(
    getTransaction,
    {
      ...initialValues,
      ...getUrlParams(Object.keys(initialValues))
    }, null
  )

  useEffect(async () => {
      const [data] = await Promise.all([
        getTransaction()
      ]);
      setDataTransaction(data)
    }, []
  )
  return {
    showModalNew,
    setShowModalNew,
    dataSource,
    tableReference,
    refreshData,
    showModalEdit,
    setShowModalEdit,
    transactionSelect,
    setTransactionSelect,
    edit,
    setEdit,
    practiceIds
  }
}

export default useTransactionForm;