import {useState} from "react"
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import styled from "styled-components";
import {Col, Row} from "react-bootstrap";

const WrappedPDFViewer = styled.div
`display: flex;
flex-direction: column;
align-items: center;
`

const PDFViewer = ({pdf}) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({numPages: num}) => {
    setNumPages(num)
    setPageNumber(1)
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const previousPage = () => {
    changePage(-1)
  }

  const nextPage = () => {
    changePage(1)
  }

  return (
    <>
      <WrappedPDFViewer>
        <Document
          file={pdf}
          options={{workerSrc: 'pdf.worker.js'}}
          onLoadSuccess={onDocumentLoadSuccess}
          onError={console.error}
        >
          <Page pageNumber={pageNumber} size="A4" scale={1.5}/>
        </Document>
        <div>
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
        </div>
      </WrappedPDFViewer>
      {numPages > 1 && (
        <Row>
          <Col md={{offset: 3, span: 3}}>
            <button
              onClick={previousPage}
              disabled={pageNumber <= 1}
              className='btn btn-primary btn-block'
            >
              Previous
            </button>
          </Col>
          <Col md={3}>
            <button
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              className='btn btn-primary btn-block'
            >
              Next
            </button>
          </Col>
        </Row>
      )}
    </>
  )

}

export default PDFViewer;