import {faChevronLeft, faChevronRight} from "@fortawesome/fontawesome-free-solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components"
import moment from "moment";
import {Tooltip} from "@material-ui/core";

const Button = styled.button`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background-color: #fff;
  color: #76838f;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({hide}) => hide ? 'hidden' : 'visible'};
`

const Span = styled.span`
  font-weight: 400;
`

const WrappedButton = styled.div`
  button:first-child {
    border-left: 1px solid #ccc !important;
  }

  button:last-child {
    border-right: 1px solid #ccc !important;
  }
`

const DateNavigate = (
  {
    handleBackStep,
    handleForwardStep,
    currentDate,
    hideBackDate = false,
    hideForwardDate = false
  }
) => {
  return (
    <WrappedButton>
      <DateButton
        onClick={handleBackStep}
        icon={faChevronLeft}
        text='Back'
        cls='mr-2'
        hide={hideBackDate}
      />

      <Span>
        {
          typeof currentDate === 'string'
          ? currentDate :
          moment(currentDate).format('MM/DD/yyyy')
        }
      </Span>

      <DateButton
        onClick={handleForwardStep}
        icon={faChevronRight}
        text='Forward'
        cls='ml-2'
        hide={hideForwardDate}
      />
    </WrappedButton>
  )
}

const DateButton = ({onClick, icon, text, cls, hide = false}) => {
  return (
    <Tooltip title={text}>
      <Button
        className={`d-inline ${cls}`}
        onClick={onClick}
        hide={hide}
      >
        <FontAwesomeIcon icon={icon}/>
      </Button>
    </Tooltip>
  )
}

export default DateNavigate